import ObjectHeading from "@/components/ObjectHeading";
import PageTitle from "@/components/PageTitle";

export default function AuditLogHeading({
  section,
  title
}: {
  section?: { name: string; legacyId: string };
  title: string;
}) {
  return (
    <>
      <PageTitle
        pattern={`/sections/:sectionId/audit-log`}
        title={`Sections | Section Details | Audit Log | ${section?.name}`}
      />
      <h1 className="text-headline-small py-8" data-testid="nav-header">
        {title}
      </h1>
      {section && <ObjectHeading title={section.name} subText={`PGA ID: ${section.legacyId}`} />}
      <div className="mt-8 flex flex-col sm:flex-row items-center justify-between">
        <div className="flex-col sm:w-3/4 w-full">
          <p className="text-body-medium mt-1">
            A history of all data changes associated with this section can be found below.
          </p>
        </div>
      </div>
    </>
  );
}
