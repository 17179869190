import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import Icon from "@/components/Icon";
import Tag from "@/components/Tag";
import TextField from "@/components/TextField";
import { SOME_USERS_QUERY } from "@/pages/people/queries";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PrivateInteractionBanner } from "./PrivateInteractionBanner";
import { Interaction } from "../types";
import { User } from "@auth0/auth0-react";

export default function ShareInteractionModal({
  disabled,
  isPrivate,
  open,
  setOpen,
  setShareVariables,
  shareInteraction,
  shareVariables
}: {
  disabled: boolean;
  isPrivate: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  setShareVariables: (value: any) => void;
  shareInteraction: (interaction?: Interaction) => void;
  shareVariables: any;
}) {
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const { data: userData, loading: usersLoading } = useQuery(SOME_USERS_QUERY, {
    variables: { search: userSearchTerm },
    skip: Object.keys(userSearchTerm).length === 0 || userSearchTerm === ""
  });

  const { control, handleSubmit, setValue } = useForm();

  useEffect(() => {
    const handler = setTimeout(() => {
      setUserSearchTerm(debouncedSearchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchTerm, setUserSearchTerm]);

  useEffect(() => {
    if (!open) {
      setValue("shareRecipients", "");
      setValue("shareNotes", "");
      setShareVariables({ recipients: [], notes: "" });
    }
  }, [open, setValue, setShareVariables]);

  return (
    <Dialog
      open={open}
      onClosed={() => {
        setOpen(false);
      }}
      headline="Share Interaction"
      className="w-[520px] p-6 pr-2 max-h-[80vh]"
      actions={
        <>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
              setShareVariables({ recipients: [], notes: "" });
              setValue("shareRecipients", "");
              setValue("shareNotes", "");
            }}
            disabled={disabled}
          >
            Cancel
          </Button>

          <Button
            trailingIcon={false}
            icon="ios_share"
            onClick={handleSubmit((data) => shareInteraction(data as Interaction))}
            disabled={disabled}
          >
            Share
          </Button>
        </>
      }
    >
      <div className="overflow-auto -mt-2 space-y-6">
        <div>
          Sharing an interaction sends an email to the selected recipient(s) that includes the interaction contents. You
          may also include an optional note for context.
        </div>

        {isPrivate && <PrivateInteractionBanner />}

        <div className="space-y-6">
          <Controller
            name="shareRecipients"
            control={control}
            rules={{
              validate: {
                recipientsNotEmpty: () => shareVariables.recipients.length > 0 || "At least one recipient is required."
              }
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <Autocomplete
                label="Person"
                required
                query={value}
                loadingResults={usersLoading}
                data={userData?.backofficeUsers || []}
                onChangeText={(string) => {
                  onChange(string);
                  setDebouncedSearchTerm(string);
                }}
                reset={() => {
                  onChange("");
                }}
                onSelect={(value: User) => {
                  onChange(value.name);
                  setShareVariables({
                    ...shareVariables,
                    recipients: shareVariables.recipients.some((recipient: User) => recipient.id === value.id)
                      ? shareVariables.recipients
                      : [...shareVariables.recipients, value]
                  });
                  onChange("");
                }}
                renderItem={(data: User) => <AvatarDetails title={`${data.name}`} text="" icon="person" />}
                error={fieldState.invalid}
                errorText={fieldState.error?.message}
              />
            )}
          />

          <div className="flex !mt-[10px]">
            <div className="h-fit mt-[18px]">To:</div>
            <div className="w-full flex flex-wrap space-x-3">
              {shareVariables.recipients.map((recipient: User, index: number) => (
                <div key={index} className="flex items-center space-x-2 ml-3 mt-3">
                  <Tag color="outline" size="large">
                    <div className="flex space-x-1">
                      <div className="h-fit my-auto">{`${recipient.name}`}</div>
                      <div
                        className="h-[18px] my-auto cursor-pointer"
                        onClick={() => {
                          setShareVariables({
                            ...shareVariables,
                            recipients: shareVariables.recipients.filter((_: User, i: number) => i !== index)
                          });
                        }}
                      >
                        <Icon name="close" size={18} />
                      </div>
                    </div>
                  </Tag>
                </div>
              ))}
            </div>
          </div>

          <Controller
            name="shareNotes"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                onChangeText={(text) => {
                  onChange(text);
                  setShareVariables({ ...shareVariables, notes: text });
                }}
                value={value}
                label="Recipient Note"
                type="textarea"
                rows={6}
                className="w-full"
                error={fieldState.invalid}
                errorText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}
