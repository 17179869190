import Select from "@/components/Select";
import { formatStage } from "../_utils";
import { ChangeRequestStatus } from "../queries";

export type Props = {
  onChange(stage?: ChangeRequestStatus): void;
  stage?: ChangeRequestStatus;
};

export default function StageSelect({ onChange, stage }: Props) {
  return (
    <Select
      value={stage || ""}
      label="Stage"
      optionListMaxHeight="none"
      onSelect={(stage) => {
        onChange(stage === "" ? undefined : (stage as ChangeRequestStatus));
      }}
      options={[
        { value: "", displayText: "Show All", border: true },
        ...Object.keys(ChangeRequestStatus).map((stage) => ({
          value: stage,
          displayText: formatStage(stage as ChangeRequestStatus)
        }))
      ]}
    />
  );
}
