import { useNavigate } from "@/router";
import { useEffect } from "react";

export default function Index() {
  const navigate = useNavigate();

  useEffect(() => {
    // using `navigate` instead of `Loader->redirect` to prevent issue with infinite loop of redirects for unauthenticated users
    navigate("/people", { replace: true });
  });

  return null;
}
