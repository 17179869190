import React from "react";
import TopAppBar from "./TopAppBar";
import { Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import { SnackbarProvider } from "notistack";
import ScrollToTop from "@/components/ScrollToTop";

type SinglePageLayoutProps<P extends Path> = {
  backLink?: LinkProps<P, Params>;
  cancelButton?: React.ReactNode;
  actions?: React.ReactNode;
  extendedActions?: React.ReactNode;
  renderBanner?: () => React.ReactNode;
};

export default function SinglePageLayout<P extends Path>({
  children,
  backLink,
  cancelButton,
  actions,
  extendedActions = [],
  renderBanner
}: React.PropsWithChildren<SinglePageLayoutProps<P>>) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
    >
      <main className="flex min-h-screen">
        <ScrollToTop />
        <div className="w-full">
          <TopAppBar
            backLink={backLink}
            actions={actions}
            cancelButton={cancelButton}
            extendedActions={extendedActions}
          />
          {renderBanner && renderBanner()}
          <div className="mx-6 tablet:mx-12 mb-24">{children}</div>
        </div>
      </main>
    </SnackbarProvider>
  );
}
