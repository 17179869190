import react from "react";
import { createComponent } from "@lit/react";
import { MdCircularProgress } from "@material/web/progress/circular-progress";
import { MdLinearProgress } from "@material/web/progress/linear-progress";

export const LitCircularProgress = createComponent({
  tagName: "md-circular-progress",
  elementClass: MdCircularProgress,
  react
});

export const LitLinearProgress = createComponent({
  tagName: "md-linear-progress",
  elementClass: MdLinearProgress,
  react
});
