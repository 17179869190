import DetailsCard from "@/components/DetailsCard/DetailsCard";
import { useState } from "react";
import EditDetailsModal from "./EditDetailsModal";

interface ExamDetailsCardProps {
  conditions: string;
  problems: string;
  onSave: (details: { conditions: string; problems: string }) => void;
  className?: string;
}

export default function ExamDetailsCard({ conditions, problems, onSave, className }: ExamDetailsCardProps) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <DetailsCard
        className={className}
        heading="Exam Details"
        options={[
          {
            overline: "Course Conditions",
            headline: conditions,
            iconName: "golf_course"
          },
          {
            overline: "Problems or Issues",
            headline: problems,
            iconName: "warning"
          }
        ]}
        onEdit={() => setIsEditing(true)}
      />
      {isEditing && (
        <EditDetailsModal
          open={isEditing}
          onClose={() => setIsEditing(false)}
          initialConditions={conditions}
          initialProblems={problems}
          onSave={(newConditions, newProblems) => {
            onSave({ conditions: newConditions, problems: newProblems });
            setIsEditing(false);
          }}
        />
      )}
    </>
  );
}
