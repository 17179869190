import { useNavigate, useParams } from "@/router";
import { useEffect } from "react";

export default function EducationIndex() {
  const { sectionId } = useParams("/sections/:sectionId/billing");
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/sections/:sectionId/billing/dues-rates", { params: { sectionId }, replace: true });
  });

  return null;
}
