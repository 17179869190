import { DateTime } from "luxon";
import { z } from "zod";
import { FormValues, ProductSchema } from "./validation/schema";
import {
  EventFormData,
  PATEventAttributesFormData,
  PATEventAttributesInputData,
  RegistrationType,
  SessionFormData,
  SessionInputData
} from "./types";

export const BACKOFFICE_TIMEZONE = "America/Chicago";

export const TERMS_CONTENT =
  "I have read and agree to the PGA of America [Terms and Privacy Policy](https://www.pga.com/pga-of-america/privacy-policy)";

export const CUSTOM_FIELDS: Record<string, string> = {
  date_of_birth: "Date of Birth",
  gender: "Gender",
  additional_guests: "Additional Guests (Max 10)",
  disclaimer: "Terms and Conditions"
};

export function toNumber(value: any) {
  return value ? Number(value) : undefined;
}

export function convertToUTCDateTime(date: string, time: string, timezone: string = BACKOFFICE_TIMEZONE): string {
  const dateTimeString = `${date} ${time}`;
  const format = "yyyy-MM-dd h:mm a";

  const parsedDateTime = DateTime.fromFormat(dateTimeString, format, { zone: timezone });

  return parsedDateTime.isValid ? parsedDateTime.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'") : "";
}

export function getDefaultSession(
  previousSession?: SessionFormData,
  timezone: string = BACKOFFICE_TIMEZONE
): SessionFormData {
  const now = DateTime.now().setZone(timezone);
  const hasPreviousSession = previousSession?.date.startDate;

  const sessionDate = hasPreviousSession
    ? DateTime.fromFormat(previousSession.date.startDate, "yyyy-MM-dd", { zone: timezone }).plus({ days: 1 })
    : now.plus({ months: 1 });

  const normalizedSessionDate = sessionDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return {
    date: {
      startDate: normalizedSessionDate.toFormat("yyyy-MM-dd"),
      endDate: normalizedSessionDate.toFormat("yyyy-MM-dd")
    },
    startTime: previousSession?.startTime ?? "8:00 AM",
    endTime: previousSession?.endTime ?? "9:00 AM",
    maxAttendance: ""
  };
}

export function convertToDateTime(timeString: string): Date | null {
  const parsedTime = DateTime.fromFormat(timeString.trim(), "h:mm a");

  if (!parsedTime.isValid) {
    return null;
  }

  const today = DateTime.now().startOf("day");
  const dateTime = today.set({
    hour: parsedTime.hour,
    minute: parsedTime.minute
  });

  return dateTime.toJSDate();
}

export function getDisplayTimezone(geocodeResult: string | null, referenceDate?: string) {
  const timezone = geocodeResult ?? BACKOFFICE_TIMEZONE;
  const now = referenceDate ? DateTime.fromISO(referenceDate).setZone(timezone) : DateTime.now().setZone(timezone);
  return now.toFormat("ZZZZ");
}

export function transformSessionsToInput(sessions: FormValues["sessions"], timezone: string): SessionInputData[] {
  return sessions.map((session) => ({
    id: session.id,
    startsAt: convertToUTCDateTime(session.date.startDate, session.startTime, timezone),
    endsAt: convertToUTCDateTime(session.date.endDate, session.endTime, timezone),
    maxAttendance: session.maxAttendance ? Number(session.maxAttendance) : null
  }));
}

export function getDefaultRegistrationOpeningDate(): any {
  const registrationOpenDate = DateTime.now().setZone(BACKOFFICE_TIMEZONE).toFormat("yyyy-MM-dd");
  return {
    startDate: registrationOpenDate,
    endDate: registrationOpenDate
  };
}

export function getDefaultRegistrationClosingDate(sessions: SessionFormData[]): any {
  const startDates = sessions.map(({ date }) => DateTime.fromISO(date.startDate, { zone: BACKOFFICE_TIMEZONE }));
  const earliestDate = startDates.reduce((min, curr) => (curr < min ? curr : min));

  const registrationCloseDate = earliestDate.minus({ days: 1 }).toFormat("yyyy-MM-dd");

  return {
    startDate: registrationCloseDate,
    endDate: registrationCloseDate
  };
}

export function setToDayBoundary(
  isoString: string,
  boundary: "start" | "end",
  timezone: string = BACKOFFICE_TIMEZONE
): string {
  const dateTime = DateTime.fromISO(isoString, { zone: timezone });
  const boundaryDateTime = boundary === "start" ? dateTime.startOf("day") : dateTime.endOf("day");
  return <string>boundaryDateTime.toUTC().toISO();
}

type Product = z.infer<typeof ProductSchema>;

export function getSelectedProducts(products: Product[]) {
  return products?.filter((product) => product.productId && product.variantId);
}

export function transformDateString(
  dateString: string | undefined,
  timezone: string = BACKOFFICE_TIMEZONE
): { startDate: string; endDate: string } | null {
  if (!dateString) return null;

  const date = DateTime.fromISO(dateString).setZone(timezone).startOf("day");

  if (!date.isValid) {
    return null;
  }

  const formattedDate = date.toFormat("yyyy-MM-dd");
  return {
    startDate: formattedDate,
    endDate: formattedDate
  };
}

export function transformSessions(
  sessions: SessionInputData[],
  timezone: string = BACKOFFICE_TIMEZONE,
  isCopy: boolean = false
): SessionFormData[] {
  if (!sessions?.length) {
    return [getDefaultSession(undefined, timezone)];
  }

  const formattedSessions = sessions.map((session) => {
    const startDateTime = DateTime.fromISO(session.startsAt).setZone(timezone);
    const endDateTime = DateTime.fromISO(session.endsAt).setZone(timezone);

    return {
      ...(!isCopy && { id: session.id }),
      date: {
        startDate: startDateTime.toFormat("yyyy-MM-dd"),
        endDate: endDateTime.toFormat("yyyy-MM-dd")
      },
      startTime: startDateTime.toFormat("h:mm a"),
      endTime: endDateTime.toFormat("h:mm a"),
      maxAttendance: session.maxAttendance?.toString() || ""
    };
  });

  return formattedSessions;
}

export function getEarliestSession(sessions: SessionFormData[], timezone: string) {
  return sessions.reduce((earliest, current) => {
    const earliestDateTime = DateTime.fromFormat(
      `${earliest.date.startDate} ${earliest.startTime}`,
      "yyyy-MM-dd h:mm a",
      { zone: timezone }
    );

    const currentDateTime = DateTime.fromFormat(`${current.date.startDate} ${current.startTime}`, "yyyy-MM-dd h:mm a", {
      zone: timezone
    });

    return currentDateTime < earliestDateTime ? current : earliest;
  }, sessions[0]);
}

export function toSessionDateTime(date: string, time: string, timezone: string) {
  const dateTime = DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd h:mm a", { zone: timezone });
  if (!dateTime.isValid) {
    return null;
  }

  const tzAbbr = getDisplayTimezone(timezone, dateTime.toISO());
  const displayDateTime = dateTime.toFormat("LLL dd, yyyy h:mm a");
  return `${displayDateTime} (${tzAbbr})`;
}

const DEFAULT_BANNER_IMAGE_URL =
  "https://res.cloudinary.com/pgahq/image/upload/v1729016664/member-platform/mypga-event-banner.jpg";

export function transformPATAttributes(inputData?: PATEventAttributesInputData): PATEventAttributesFormData {
  const defaultPATAttributes: PATEventAttributesFormData = {
    yardageMale: "",
    courseRatingMale: "",
    yardageMaleFiftyPlus: "",
    yardageFemale: "",
    courseRatingFemale: "",
    yardageFemaleFiftyPlus: "",
    hostProfessional: { id: "", profilePhoto: "", name: "" },
    examiner: { id: "", profilePhoto: "", name: "" },
    courseConditions: "",
    problemsIssues: ""
  };

  if (!inputData) {
    return defaultPATAttributes;
  }

  return {
    yardageMale: inputData.yardageMale?.toString() ?? "",
    courseRatingMale: inputData.courseRatingMale?.toString() ?? "",
    yardageMaleFiftyPlus: inputData.yardageMaleFiftyPlus?.toString() ?? "",
    yardageFemale: inputData.yardageFemale?.toString() ?? "",
    courseRatingFemale: inputData.courseRatingFemale?.toString() ?? "",
    yardageFemaleFiftyPlus: inputData.yardageFemaleFiftyPlus?.toString() ?? "",
    hostProfessional: {
      id: inputData.hostProfessionalId ?? "",
      profilePhoto: "",
      name: ""
    },
    examiner: {
      id: inputData.examinerId ?? "",
      profilePhoto: "",
      name: ""
    },
    courseConditions: inputData.courseConditions ?? "",
    problemsIssues: inputData.problemsIssues ?? ""
  };
}

export function getDefaultRegistrationType(event?: EventFormData): RegistrationType {
  return event?.eventType?.name === "PAT" ? "all" : "individual";
}

export function getDefaultFormValues(event?: EventFormData, isCopy?: boolean): FormValues {
  const defaultSession = getDefaultSession();

  if (!event) {
    return {
      eventName: "",
      eventType: { id: "", name: "" },
      section: "",
      sectionId: "",
      legacyId: "",
      locationType: "onsite",
      facility: "",
      facilityId: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      timezone: BACKOFFICE_TIMEZONE,
      location: "",
      description: "",
      sessions: [defaultSession],
      registrationType: getDefaultRegistrationType(),
      bannerImageUrl: DEFAULT_BANNER_IMAGE_URL,
      registrationOpenDate: getDefaultRegistrationOpeningDate(),
      registrationCloseDate: getDefaultRegistrationClosingDate([defaultSession]),
      waitlistType: "disabled",
      customFields: [],
      termsContent: "",
      products: [{ productId: "", description: "", variantId: "", variantName: "", variantPrice: 0, collection: "" }],
      collectsAttendance: false,
      patEventAttributes: transformPATAttributes()
    };
  }

  return {
    eventName: isCopy ? `${event.title} (Copy)` : event.title ?? "",
    eventType: {
      id: event.eventType?.id ?? "",
      name: event.eventType?.name ?? ""
    },
    section: event.section?.name ?? "",
    sectionId: event.section?.id ?? "",
    legacyId: event.section?.legacyId ?? "",
    locationType: event.locationType?.toLowerCase() === "virtual" ? "virtual" : "onsite",
    facility: event.facility?.name ?? "",
    facilityId: event.facility?.id ?? "",
    address1: event.address1 ?? "",
    address2: event.address2 ?? "",
    city: event.city ?? "",
    state: event.state ?? "",
    zipcode: event.zip ?? "",
    timezone: event.timezone ?? BACKOFFICE_TIMEZONE,
    location: event.location ?? "",
    description: event.description ?? "",
    sessions: transformSessions(event.sessions ?? [], event.timezone, isCopy),
    registrationType: getDefaultRegistrationType(event),
    bannerImageUrl: event.bannerImageUrl ?? DEFAULT_BANNER_IMAGE_URL,
    registrationOpenDate:
      transformDateString(event.registrationOpenAt, event.timezone) ?? getDefaultRegistrationOpeningDate(),
    registrationCloseDate:
      transformDateString(event.registrationCloseAt, event.timezone) ??
      getDefaultRegistrationClosingDate([defaultSession]),
    waitlistType: event.hasWaitlist ? "notification" : "disabled",
    customFields: event.customFields ?? [],
    termsContent: event?.termsContent ?? "",
    products: event.products ?? [
      {
        productId: "",
        description: "",
        variantId: "",
        variantName: "",
        variantPrice: 0,
        collection: ""
      }
    ],
    collectsAttendance: event.collectsAttendance ?? false,
    patEventAttributes: transformPATAttributes(event.patEventAttributes)
  };
}
