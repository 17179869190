import DetailsCard from "@/components/DetailsCard";
import MultiSectionDetailsCard from "@/components/MultiSectionDetailsCard";
import { Person } from "@/types";
import {
  addressOptions,
  emailOptions,
  facilityOptions,
  officialDetailsOptions,
  personalOptions,
  phoneOptions,
  sectionOptions
} from "./utils";

function DetailsColumn({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col gap-y-10">{children}</div>;
}

export function ContactDetails({ person }: { person: Person }) {
  return (
    <div className="flex flex-col">
      <h3 className="pl-0 sm:pl-4 pt-4 pb-1 sm:pt-0 text-title-medium">Personal and Contact Information</h3>
      <p className="pl-0 sm:pl-4 pb-4 text-body-medium">View contact information and other personal details below</p>
      <div className="flex flex-col md:flex-row gap-x-10">
        <div id="contact-details-column-1" className="basis-1/3 pb-10">
          <DetailsColumn>
            <MultiSectionDetailsCard
              sections={[
                {
                  heading: "Phone",
                  options: phoneOptions(person.phoneNumbers)
                },
                {
                  heading: "Email",
                  options: emailOptions(person.email)
                }
              ]}
            />
            <MultiSectionDetailsCard
              sections={[
                {
                  heading: "Personal",
                  options: personalOptions(person)
                },
                {
                  heading: "Official",
                  options: officialDetailsOptions(person)
                }
              ]}
            />
          </DetailsColumn>
        </div>
        <div id="contact-details-column-2" className="basis-2/3">
          <DetailsColumn>
            <MultiSectionDetailsCard
              sections={[
                {
                  heading: "Address",
                  options: addressOptions(person.addresses, person.contactAddressId)
                },
                {
                  heading: "Facility",
                  options: facilityOptions(person.employments, person.contactAddressId)
                }
              ]}
            />
            {person.primarySection && <DetailsCard heading="Section" options={sectionOptions(person)} />}
            {person.militaryService && (
              <DetailsCard
                heading="Military"
                options={[
                  {
                    iconName: "military_tech",
                    overline: "Status",
                    headline: person.militaryService.status
                  }
                ]}
              />
            )}
          </DetailsColumn>
        </div>
      </div>
    </div>
  );
}
