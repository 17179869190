import usePageTitle from "@/hooks/usePageTitle";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import Tabs from "@/components/Tabs";
import { useNavigate, Path } from "@/router";
import { useLocation } from "react-router-dom";
import useActionMenu from "@/hooks/useActionMenu";
import useFacilitiesQuery from "./_hooks/useFacilitiesQuery";
import FacilitiesTable from "./_components/FacilitiesTable";
import Banner from "@/components/Banner";
import { isDev } from "@/lib/urlUtils";

export default function FacilitiesPage() {
  usePageTitle("/facilities", "Facilities");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tableProps = useFacilitiesQuery();
  const { Menu, setMenu } = useActionMenu();

  const ccmsUrl = isDev()
    ? `https://ccms-pgastage.dev.pga.org/prod/AssociationAnywhere`
    : `https://sms.pgalinks.com/prod/AssociationAnywhere`;

  return (
    <AppLayout
      BannerComponent={
        <Banner
          description="While Facility pages are being finalized, please continue to use CCMS for the most up-to-date facility data."
          variant="warn"
          primaryAction={{ label: "View in CCMS", link: ccmsUrl }}
        />
      }
    >
      <PageHeading title="Facilities" />

      <Tabs
        className="mb-8 mt-4 sm:mt-8"
        tabItems={[{ label: "Facilities", to: "/facilities" as Path }].map(({ label, to }) => ({
          label,
          onClick: () => navigate(to),
          active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
        }))}
      />

      <Menu />
      <div className="w-full">
        <FacilitiesTable {...tableProps} setMenu={setMenu} />
      </div>
    </AppLayout>
  );
}
