import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import SessionFields from "../components/SessionFields";
import MaxAttendanceField from "../components/MaxAttendanceField";
import Button from "@/components/Button";
import Radio from "@/components/Radio";
import Divider from "@/components/Divider";
import { getDefaultSession, getDisplayTimezone } from "../utils";
import { useEffect } from "react";

const REGISTRATION_TYPES = [
  { value: "individual", label: "Registrant can select individual sessions" },
  { value: "all", label: "Registration includes ALL sessions" }
];

const SessionDetailsStep = () => {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "sessions"
  });

  const hasMultipleSessions = fields.length > 1;

  const sessions = watch("sessions");
  const registrationType = watch("registrationType");
  const timezone = watch("timezone");
  const eventType = watch("eventType");

  useEffect(() => {
    if (eventType.name === "PAT" && fields.length > 1) {
      setValue("sessions", fields.slice(0, 1));
    }
  }, [eventType, fields, setValue]);

  const addSession = () => {
    const prevSession = sessions[sessions.length - 1];
    const newSessionDefaults = getDefaultSession(prevSession);
    append(newSessionDefaults);
  };

  return (
    <div>
      <div className="flex flex-col gap-6 mb-6">
        <p className="text-title-large mb-2"> When will this event be hosted?</p>
        <p className="text-body-medium">
          Note: If session dates and/or times are changed after registrations have been received, any applicable
          cancellation or refund restrictions will be waived. Registrants will be notified about any session changes.
        </p>
        <div className="flex flex-col gap-6">
          {fields.map((field, index) => (
            <SessionFields
              key={field.id}
              index={index}
              timezone={getDisplayTimezone(timezone)}
              hasMultipleSessions={hasMultipleSessions}
              onRemove={() => {
                if (hasMultipleSessions) {
                  remove(index);
                }
              }}
            />
          ))}
        </div>
        {eventType.name !== "PAT" && (
          <div>
            <Button variant="filledTonal" icon="add" theme="info" onClick={addSession}>
              Add Session
            </Button>
          </div>
        )}
      </div>

      {eventType.name !== "PAT" && (
        <>
          <div className="py-3">
            <Divider />
          </div>
          {hasMultipleSessions && (
            <div className="mt-6">
              <p className="text-title-medium mb-2">Multiple Sessions</p>
              <p className="text-body-medium mb-3">
                Select session registration handling for a single event that is being held over multiple dates/times.
              </p>
              <Controller
                control={control}
                name="registrationType"
                data-testid="registration-type-radio-group"
                render={({ field }) => (
                  <div className="flex flex-col">
                    {REGISTRATION_TYPES.map((option, i) => (
                      <div className="py-3" key={i}>
                        <Radio
                          name={`registrationType-${option.value}`}
                          label={option.label}
                          checked={field.value === option.value}
                          onChange={() => {
                            field.onChange(option.value);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              />
              {registrationType === "all" && (
                <div className="w-[280px] h-[56px] my-6">
                  <MaxAttendanceField index={0} />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SessionDetailsStep;
