import AuthorizationProvider from "./AuthorizationProvider";
import DataDogProvider from "./DatadogProvider";
import FeatureFlagProvider from "./FeatureFlagProvider";
import GraphqlProvider from "./GraphqlProvider";
import { InitializeRaygunUser } from "./RaygunProvider";
import UserProvider from "./UserProvider";

type Props = {
  children: React.ReactNode;
};

export default function Providers({ children }: Props): React.ReactNode {
  return (
    <UserProvider redirect_uri={window.location.origin}>
      <GraphqlProvider>
        <AuthorizationProvider>
          <DataDogProvider>
            <FeatureFlagProvider>
              <InitializeRaygunUser />
              {children}
            </FeatureFlagProvider>
          </DataDogProvider>
        </AuthorizationProvider>
      </GraphqlProvider>
    </UserProvider>
  );
}
