import { DateType } from "react-tailwindcss-datepicker";

export enum InteractionMode {
  PHONE = "PHONE_CALL",
  VIDEO = "VIDEO_CHAT",
  SMS = "TEXT",
  EMAIL = "EMAIL",
  IN_PERSON = "IN_PERSON",
  OTHER = "OTHER",
  MEMBER_FEEDBACK = "MEMBER_FEEDBACK"
}

export type Message = {
  content: string;
  createdAt: Date;
  fromOwner: boolean;
  fromProfilePhoto: string;
  isPrivate: boolean;
  haveAccess: boolean;
};

export type Interaction = {
  id: string;
  mode: InteractionMode;
  owner: String;
  relatedTo: String;
  participants: any[];
  categories: string[];
  messagesCount: number;
  lastMessage: Message;
  messages: Message[];
  summary: String;
  interactionDate: Date;
  lastModifiedDate: Date | string;
  createdAt: Date | string;
};

export type InteractionStats = {
  totalInteractions: number;
  totalPhoneInteractions: number;
  totalVideoInteractions: number;
  totalSmsInteractions: number;
  totalEmailInteractions: number;
  totalInPersonInteractions: number;
};

export type InteractionInput = {
  participantId?: string | null;
  interactionDate: string | null | DateType;
  mode: string | undefined | null;
  activityType?: string | null;
  description: string | null;
  participantIds?: string[] | null;
  privacy_restricted?: boolean;
  memberFeedback?: boolean;
};

export type SummaryPayload = {
  mode: string | null;
  subject: string | null;
  comments: string | null;
};

export type AiResponse = {
  message: string;
  loading: boolean;
  error: any;
};
