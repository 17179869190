import { useRef, useMemo, ReactNode, ComponentProps } from "react";
import { LitMenu, MdMenu } from "../Menu/LitMenu";
import { sysColors } from "@/assets/theme/colors";
import { ReactWebComponent } from "@lit/react";

type LitMenuProps = Pick<ComponentProps<ReactWebComponent<MdMenu, {}>>, "positioning" | "anchorCorner" | "menuCorner">;

export type MenuProps = LitMenuProps & {
  AnchorComponent: ReactNode;
  content: ReactNode;
  variant: "rich" | "plain";
  color: "white" | "black";
  anchorClassName?: string;
};

const sizeClasses = {
  plain: "px-2 whitespace-nowrap",
  rich: "p-4 w-[312px]"
};

const bgColors = {
  white: "sys-white",
  black: "sys-brand-inverse-surface"
};

const textColorClasses = {
  white: "text-sys-dark-inverse-on-surface",
  black: "text-sys-brand-inverse-on-surface"
};

const randomNum = () => (Math.random() * 10000000).toFixed(0);

export default function MenuTooltip({
  content,
  AnchorComponent,
  variant,
  color,
  anchorCorner = "end-end",
  menuCorner = "start-end",
  positioning = "absolute",
  anchorClassName
}: MenuProps) {
  const menuRef = useRef<MdMenu>(null);
  const showTooltip = () => menuRef.current?.show();
  const hideTooltip = () => menuRef.current?.close();
  const anchorId = useMemo(() => `menu-tooltip-anchor-${randomNum()}`, []);

  const style = {
    "--md-menu-container-shape": variant === "plain" ? "4px" : "12px",
    "--md-menu-container-color": sysColors[bgColors[color]],
    minWidth: "60px"
  } as React.CSSProperties;

  return (
    <div className="relative" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-testid="menu-tooltip">
      {/* Adding the spacing to allow for larger hover area */}
      <div id={anchorId} role="button" className={`${anchorClassName || "tablet:-mx-10 tablet:px-10"} `}>
        {!!AnchorComponent && AnchorComponent}
      </div>
      <LitMenu quick anchor={anchorId} ref={menuRef} {...{ style, anchorCorner, menuCorner, positioning }}>
        <div role="tooltip" className={`${sizeClasses[variant]} ${textColorClasses[color]}`}>
          {content}
        </div>
      </LitMenu>
    </div>
  );
}
