import { $isTextNode, EditorState, LexicalEditor, TextNode } from "lexical";
import {
  $convertToMarkdownString,
  $convertFromMarkdownString,
  TextMatchTransformer,
  TRANSFORMERS
} from "@lexical/markdown";

const underlineTransformer: TextMatchTransformer = {
  dependencies: [],
  export: (node) => {
    if (!$isTextNode(node)) return null;
    if (node.hasFormat("underline")) {
      let formattedText = `<u>${node.getTextContent()}</u>`;
      if (node.hasFormat("bold")) {
        formattedText = `**${formattedText}**`;
      }
      if (node.hasFormat("italic")) {
        formattedText = `*${formattedText}*`;
      }
      return formattedText;
    }
    return null;
  },
  importRegExp: /<u>([^<]+)<\/u>/,
  replace: (node: TextNode, match: RegExpMatchArray) => {
    const [_, content] = match;
    node.setTextContent(content);
    node.setFormat(node.getFormat() | 8);
  },
  regExp: /./g,
  trigger: "<",
  type: "text-match" as const
};

export function handleInitialState(editor: LexicalEditor, markdown?: string) {
  if (markdown) {
    const cleanedMarkdown = markdown.replace(/ {2}\n/g, "\n").trim();
    editor.update(() => {
      $convertFromMarkdownString(cleanedMarkdown, [...TRANSFORMERS, underlineTransformer], undefined, true, false);
    });
  }
}

export function handleEditorChange(editorState: EditorState, onChange: (content: string) => void) {
  editorState.read(() => {
    const markdown = $convertToMarkdownString([...TRANSFORMERS, underlineTransformer], undefined, true);
    const cleanedMarkdown = markdown.replace(/\n/g, "  \n");
    onChange?.(cleanedMarkdown);
  });
}
