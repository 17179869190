import classNames from "classnames";
import Icon from "@/components/Icon";
import { MaterialNames } from "@/components/material_symbol_names";
import Button, { ButtonProps } from "@/components/Button";
import { Link, Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";

interface SharedActionProps extends Omit<ButtonProps, "theme" | "variant" | "onClick"> {}

type ActionButtonProps = SharedActionProps & { onClick: Required<ButtonProps>["onClick"] };

type ActionLinkProps = SharedActionProps & LinkProps<Path, Params> & { onClick?: undefined };

type ActionProps = ActionButtonProps | ActionLinkProps;

export interface EmptyStateProps {
  iconName: MaterialNames;
  title: string;
  caption?: string;
  className?: string;
  action?: ActionProps;
}

const IconComp = ({ iconName }: { iconName: MaterialNames }) => (
  <div className="object-cover rounded-full w-[70px] h-[70px] flex items-center justify-center bg-sys-brand-tertiary">
    <Icon name={iconName} size={40} className="text-sys-brand-on-tertiary" />
  </div>
);

const ActionButton = (props: ActionButtonProps) => (
  <div className="mt-6">
    <Button variant="filledTonal" {...props} />
  </div>
);

const ActionLink = ({ children, icon, ...rest }: ActionLinkProps) => (
  <Link {...rest} className="mt-6">
    <Button variant="filledTonal" icon={icon}>
      {children}
    </Button>
  </Link>
);

function Action(props: ActionProps) {
  return props.onClick ? <ActionButton {...props} /> : <ActionLink {...props} />;
}

const EmptyState = ({ iconName, title, caption, className, action }: EmptyStateProps) => {
  return (
    <div className="flex flex-col items-center py-20 text-center" data-testid="empty-state">
      <IconComp iconName={iconName} />
      <div className={classNames("flex flex-col pt-8 px-12 gap-2 max-w-[412px]", className)}>
        <div className="text-title-medium">{title}</div>
        {caption && <div className="text-body-medium">{caption}</div>}
        {action && <Action {...action} />}
      </div>
    </div>
  );
};

export default EmptyState;
