import { PageInfo } from "@/components/Pagination";
import { TypedDocumentNode, gql } from "@apollo/client";
import { PERSON_FRAGMENT, PERSON_DETAILS_FRAGMENT, MEMBERSHIP_BAND_FRAGMENT } from "@/fragments";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { OrderNode } from "@/pages/billing/types";

type Query = {
  commerceOrders: {
    totalCount: number;
    nodes: OrderNode[];
    pageInfo: PageInfo;
  };
};

export const ORDERS_QUERY: TypedDocumentNode<Query> = gql`
  query GetCommerceOrders(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
    $ids: [String!]
    $statuses: [String!]
  ) {
    commerceOrders(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: { search: $query, ids: $ids, status: $statuses }
    ) {
      nodes {
        id
        stage
        paymentStatus
        balance
        dueDate
        payable
        updatedAt
        description
        person {
          id
          firstName
          lastName
          profilePhoto
          pgaId
          mainProgramType {
            id
            name
          }
        }
        lineItems {
          id
          name
          quantity
          priceCents
          totalPrice
          fee
        }
      }
      ...PageInfo
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;

export const GET_PAYORS = gql`
  query GetPayors($input: BackofficeCRMPeopleInput!) {
    people(input: $input) {
      nodes {
        ...PersonFragment
      }
    }
  }
  ${PERSON_FRAGMENT}
`;

export const GET_TEMPLATES = gql`
  query GetTemplates {
    commerceOrderTemplates {
      id
      name
      type
      lookupKey
    }
  }
`;

export const CREATE_DRAFT_ORDER_MUTATION = gql`
  mutation CreateDraftOrder($input: CommerceCreateDraftOrderInput!) {
    commerceCreateDraftOrder(input: $input) {
      order {
        id
        status
      }

      message
      success
    }
  }
`;

export const PERSON_DRAFT_DUES_ORDERS_QUERY = gql`
  query GetPersonsOrders($filters: BackofficeCommerceOrderInput!) {
    commerceOrders(filters: $filters) {
      totalCount
      nodes {
        id
        status
        type
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const PERSON_DETAILS_QUERY = gql`
  query GetPersonDetails($pgaId: ID!) {
    person(pgaId: $pgaId) {
      ...PersonDetailsFragment
      displayName
      bands {
        ...MembershipBandFragment
      }
    }
  }
  ${PERSON_DETAILS_FRAGMENT}
  ${MEMBERSHIP_BAND_FRAGMENT}
`;
