import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useNavigate } from "@/router";

export default function DuplicateOrderDialog({
  onDismiss,
  existingOrderId,
  onCreateDraftOrder
}: {
  onDismiss: () => void;
  onCreateDraftOrder: () => void;
  existingOrderId: string;
}) {
  const navigate = useNavigate();

  function viewExistingOrder() {
    navigate(`/billing/orders/:orderId`, { params: { orderId: existingOrderId } });
  }

  const actions = (
    <div className="w-full">
      <div className="w-fit mx-auto">
        <Button variant="filledTonal" onClick={viewExistingOrder}>
          View Existing Draft
        </Button>
      </div>
      <div className="mt-6 ml-auto w-fit space-x-2">
        <Button variant="text" onClick={onDismiss}>
          Cancel
        </Button>
        <Button onClick={onCreateDraftOrder}>Replace Draft</Button>
      </div>
    </div>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline="Duplicate Order Detected">
      <div className="max-w-[423px] space-y-6">
        <p>
          There is already a draft dues order for this payor. You can replace the existing draft with a new one, or view
          the existing draft without making changes.
        </p>
      </div>
    </Dialog>
  );
}
