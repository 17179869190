import react from "react";
import { createComponent } from "@lit/react";
import { MdOutlinedButton } from "@material/web/button/outlined-button";
import { MdFilledButton } from "@material/web/button/filled-button";
import { MdElevatedButton } from "@material/web/button/elevated-button";
import { MdFilledTonalButton } from "@material/web/button/filled-tonal-button";
import { MdTextButton } from "@material/web/button/text-button";

export const LitOutlinedButton = createComponent({
  tagName: "md-outlined-button",
  elementClass: MdOutlinedButton,
  react
});

export const LitFilledButton = createComponent({
  tagName: "md-filled-button",
  elementClass: MdFilledButton,
  react
});

export const LitElevatedButton = createComponent({
  tagName: "md-elevated-button",
  elementClass: MdElevatedButton,
  react
});

export const LitFilledTonalButton = createComponent({
  tagName: "md-filled-tonal-button",
  elementClass: MdFilledTonalButton,
  react
});

export const LitTextButton = createComponent({
  tagName: "md-text-button",
  elementClass: MdTextButton,
  react
});
