import Tabs from "@/components/Tabs";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Path, useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const flags = useFlags();
  const { pgaId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const currentUrl = pathname.replace(/\/$/, "");
  const navigate = useNavigate();

  if (!pgaId) return null;

  return (
    <>
      <div className="mt-4 sm:mt-8">
        <Tabs
          tabItems={[
            ...(flags["backoffice-person-profile-education-learning"]
              ? [
                  {
                    label: "Professional Development",
                    to: "/people/:pgaId/education/professional-development" as Path,
                    active: currentUrl === `/people/${pgaId}/education/professional-development`
                  },
                  {
                    label: "PGA Learning",
                    to: "/people/:pgaId/education/learning" as Path,
                    active: currentUrl === `/people/${pgaId}/education/learning`
                  }
                ]
              : []),
            ...(flags["backoffice-person-profile-education-learning"]
              ? [
                  {
                    label: "PGM Program",
                    to: "/people/:pgaId/education/program" as Path,
                    active: currentUrl === `/people/${pgaId}/education/program`
                  }
                ]
              : [])
          ].map(({ label, to, active }) => ({
            label,
            onClick: () => navigate(to, { params: { pgaId } }),
            active
          }))}
        />
      </div>
      <div className="flex flex-col" data-testid="tab-content">
        {children}
      </div>
    </>
  );
}
