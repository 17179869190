import Avatar from "../Avatar";
import { MaterialNames } from "../material_symbol_names";

interface Props {
  picture?: string;
  title: string;
  text: string;
  secondaryText?: string;
  icon?: MaterialNames;
}

export default function AvatarDetails({ picture, title, text, secondaryText, icon = "person" }: Props) {
  return (
    <div className="flex items-center">
      {<Avatar picture={picture} iconName={icon} className="min-w-[3rem]" />}
      <div className="ml-2">
        {secondaryText && <div className="text-body-small text-sys-brand-on-surface-variant">{secondaryText}</div>}
        <div className="text-body-medium">{title}</div>
        <div className="text-body-small text-sys-brand-on-surface-variant">{text}</div>
      </div>
    </div>
  );
}
