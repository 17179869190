import { MaterialNames } from "@/components/material_symbol_names";
import { toFormat } from "@/lib/dateHelpers";
import { Interaction, InteractionMode, Message } from "./interactions/types";
const SUMMARY_MAX_LENGTH = 250;

export const getIcon = (mode: InteractionMode): MaterialNames => {
  switch (mode) {
    case InteractionMode.PHONE:
      return "call";
    case InteractionMode.VIDEO:
      return "video_camera_front";
    case InteractionMode.EMAIL:
      return "mail";
    case InteractionMode.IN_PERSON:
      return "record_voice_over";
    case InteractionMode.SMS:
      return "stream_apps";
    case InteractionMode.MEMBER_FEEDBACK:
      return "feedback";
    case InteractionMode.OTHER:
      return "record_voice_over";
    default:
      return "record_voice_over";
  }
};

export const getLinkDescription = (mode: InteractionMode, usedAsTitle: boolean = false): string => {
  switch (mode) {
    case InteractionMode.PHONE:
      return usedAsTitle ? "Phone call with" : "had a phone call with";
    case InteractionMode.VIDEO:
      return usedAsTitle ? "Video conferenced with" : "video conferenced with";
    case InteractionMode.EMAIL:
      return usedAsTitle ? "Emailed with" : "emailed with";
    case InteractionMode.IN_PERSON:
      return usedAsTitle ? "Met in-person with" : "met in-person with";
    case InteractionMode.SMS:
      return usedAsTitle ? "Texted with" : "texted with";
    case InteractionMode.MEMBER_FEEDBACK:
      return usedAsTitle ? "Feedback captured by" : "captured feedback from";
    case InteractionMode.OTHER:
      return usedAsTitle ? "Interacted with" : "interacted with";
    default:
      return usedAsTitle ? "Interacted with" : "interacted with";
  }
};

type ApiMode = "Phone Call" | "Video Chat" | "Email" | "In-Person" | "Text Message" | "Member Feedback" | "Other";

export const convertMode = {
  toAPI: (mode: InteractionMode | null): ApiMode | null => {
    if (!mode) return null;

    const apiModes: Record<InteractionMode, ApiMode> = {
      [InteractionMode.IN_PERSON]: "In-Person",
      [InteractionMode.PHONE]: "Phone Call",
      [InteractionMode.VIDEO]: "Video Chat",
      [InteractionMode.EMAIL]: "Email",
      [InteractionMode.SMS]: "Text Message",
      [InteractionMode.MEMBER_FEEDBACK]: "Member Feedback",
      [InteractionMode.OTHER]: "Other"
    };

    return apiModes[mode];
  },
  fromAPI: (apiMode: string | null): InteractionMode | null => {
    if (!apiMode) return null;

    // Handle direct enum string values first
    switch (apiMode) {
      case "PHONE_CALL":
        return InteractionMode.PHONE;
      case "VIDEO_CHAT":
        return InteractionMode.VIDEO;
      case "TEXT":
        return InteractionMode.SMS;
      case "EMAIL":
        return InteractionMode.EMAIL;
      case "IN_PERSON":
        return InteractionMode.IN_PERSON;
      case "MEMBER_FEEDBACK":
        return InteractionMode.MEMBER_FEEDBACK;
    }

    // Then handle API format values
    const normalized = apiMode.toLowerCase().replace(/-/g, " ");
    switch (normalized) {
      case "in person":
      case "in-person":
        return InteractionMode.IN_PERSON;
      case "phone":
      case "phone call":
        return InteractionMode.PHONE;
      case "video":
      case "video chat":
        return InteractionMode.VIDEO;
      case "email":
        return InteractionMode.EMAIL;
      case "text":
      case "text message":
        return InteractionMode.SMS;
      case "member feedback":
        return InteractionMode.MEMBER_FEEDBACK;
      default:
        return InteractionMode.OTHER;
    }
  }
};

type InteractionsMap = { [key: string]: Interaction[] };

export const groupInteractions = (interactions: Interaction[]): InteractionsMap => {
  return interactions.reduce((acc: InteractionsMap, interaction) => {
    const interactionDate = new Date(interaction.interactionDate);
    const key = toFormat(interactionDate.toISOString().split("T")[0], "MMMM yyyy") || "";
    const list: Interaction[] = acc[key] || [];
    return { ...acc, [key]: [...list, interaction] };
  }, {});
};

type MessagesMap = { [key: string]: Message[] };

export const groupMessages = (messages: Message[], date: Date): MessagesMap => {
  return messages.reduce((acc: MessagesMap, message) => {
    const key = toFormat(date.toISOString(), "MMMM yyyy") || "";
    const list: Message[] = acc[key] || [];
    return { ...acc, [key]: [...list, message] };
  }, {});
};

export const filterInteractionsForSummary = (interactions: Interaction[], depthInMonths: number = 6) => {
  // Early exit conditions
  if (!interactions?.length) return [];

  const accessibleInteractions = filterAndSortInteractions(interactions, depthInMonths);
  if (accessibleInteractions.length <= 2) return [];

  const groupedInteractions = groupByTwoDayPeriods(accessibleInteractions);
  const processedInteractions = processGroups(groupedInteractions);

  return transformInteractions(processedInteractions.slice(0, 5));
};

// Helper functions

//  calculate the date for the depth in months
const filterAndSortInteractions = (interactions: Interaction[], depthInMonths: number) => {
  const depthInMonthsAgo = new Date();
  depthInMonthsAgo.setMonth(depthInMonthsAgo.getMonth() - depthInMonths);

  const seenIds = new Set<string>();
  return interactions
    .filter((interaction) => {
      const interactionDate = new Date(interaction.interactionDate);
      if (!interaction.lastMessage.haveAccess || interactionDate < depthInMonthsAgo || seenIds.has(interaction.id)) {
        return false;
      }
      seenIds.add(interaction.id);
      return true;
    })
    .sort((a, b) => new Date(b.interactionDate).getTime() - new Date(a.interactionDate).getTime());
};

const groupByTwoDayPeriods = (interactions: Interaction[]) => {
  return interactions.reduce(
    (acc, interaction) => {
      const bucketDate = getTwoDayPeriod(new Date(interaction.interactionDate));
      if (!acc[bucketDate]) acc[bucketDate] = [];
      acc[bucketDate].push(interaction);
      return acc;
    },
    {} as Record<string, Interaction[]>
  );
};

const transformInteractions = (interactions: Interaction[]) => {
  return interactions.map((interaction: Interaction) => ({
    id: interaction.id,
    date: new Date(interaction.interactionDate).toISOString(),
    mode: interaction.mode as string,
    description: interaction.summary || "",
    participants: interaction.participants.map((p) => `${p.firstName} ${p.lastName}`),
    summary: interaction.summary || "",
    comments: interaction.lastMessage?.content || "",
    topics: interaction.categories || []
  }));
};

export const formatAiSummary = (text: string): string => {
  if (!text) return "";
  return text.length > SUMMARY_MAX_LENGTH ? text.slice(0, SUMMARY_MAX_LENGTH) + "..." : text;
};

export const guardAiResponse = (text: string, topicData: string[]): string => {
  if (!text || !topicData || !topicData.includes(text)) {
    return "Contact Relationship Development";
  }
  return text;
};

const processGroups = (groupedInteractions: Record<string, Interaction[]>) => {
  const processedIds = new Set<string>();
  const processedInteractions: Interaction[] = [];

  Object.values(groupedInteractions).forEach((periodInteractions) => {
    const uniquePeriodInteractions = periodInteractions.filter((interaction) => {
      if (processedIds.has(interaction.id)) return false;
      processedIds.add(interaction.id);
      return true;
    });

    if (uniquePeriodInteractions.length >= 5) {
      // For periods with 5+ interactions, only take the most recent one
      processedInteractions.push(uniquePeriodInteractions[0]);
    } else {
      // For periods with fewer interactions, include all
      processedInteractions.push(...uniquePeriodInteractions);
    }
  });

  return processedInteractions.sort(
    (a, b) => new Date(b.interactionDate).getTime() - new Date(a.interactionDate).getTime()
  );
};

const getTwoDayPeriod = (date: Date): string => {
  const startDay = Math.floor((date.getDate() - 1) / 2) * 2 + 1;
  return new Date(date.getFullYear(), date.getMonth(), startDay).toISOString();
};
