import { useState, useEffect } from "react";
import Checkbox from "@/components/Checkbox";
import DateSelect from "@/components/DateSelect";
import TextField from "@/components/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { PaymentType } from "../../../[orderId]/payment/[paymentId]/types";
import { PaymentMethod } from "@/pages/billing/types";
import { FormValues } from "../index";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import Icon from "@/components/Icon";
import { renderPaymentType } from "@/pages/billing/_utils";
import { OrderStage } from "@/pages/billing/types";
import { sumBy } from "lodash";
import pluralize from "@/lib/pluralize";
import { OrderPayment } from "../../../[orderId]/payment/[paymentId]/types";

export default function RefundSettings({
  payment,
  totalRefund,
  order
}: {
  payment: PaymentType;
  totalRefund: number;
  order?: OrderPayment | null;
}) {
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const { control, watch, setValue } = useFormContext<FormValues>();
  const { type, checkNumber, payer, bypass } = payment;
  const refundAllocations = watch("refundAllocations");
  const reopenOrder = watch("reopenOrder");

  const nonRefundedLineItems = order?.lineItemsOriginal?.filter(
    (item) => !refundAllocations.some((allocation) => allocation.id === item.id)
  );
  const nonRefundedLineItemsBalance = sumBy(nonRefundedLineItems, "balance");
  const isSomeOrderOpen =
    order?.stage === OrderStage.OPEN ||
    (!order && payment.orderPayments.some((order) => order.order.stage === OrderStage.OPEN));

  const allowOpenOrder = reopenOrder || (isSomeOrderOpen && nonRefundedLineItemsBalance > 0);

  useEffect(() => {
    if (!allowOpenOrder) {
      setValue("notifyPayor", false);
    }
  }, [allowOpenOrder, setValue]);

  return (
    <div className={`${isDateSelectorOpen ? "mt-4 sm:mt-36" : ""}`}>
      <h5 className="text-title-medium">Payment Source(s) to Refund</h5>
      <div className="flex items-start py-3">
        <div className="flex justify-between w-full">
          <div className={`flex space-x-2 w-full justify-between`}>
            <div className="flex space-x-2">
              <div className="flex flex-col justify-start">
                <Icon name="monetization_on" size={24} className="flex mr-2 text-sys-brand-on-surface-variant" />
              </div>
              <div className="flex flex-col items-start">
                <div className="text-label-medium text-sys-brand-on-surface-variant">
                  {type === PaymentMethod.FINANCIAL_ADJUSTMENT ? "Financial Adjustment" : "Payment"}
                </div>
                <div className="text-body-large text-sys-brand-on-surface word-break">
                  {type === PaymentMethod.FINANCIAL_ADJUSTMENT ? payer.name : renderPaymentType(type)}
                  {type === PaymentMethod.CHECK ? ` #${checkNumber}` : ""}
                </div>
                <p className="text-body-medium text-sys-brand-on-surface-variant">
                  {type === PaymentMethod.CARD &&
                    !bypass &&
                    "The refund will be issued via Stripe and returned to the original payment method. Expect a processing time of 7-10 days."}
                  {type === PaymentMethod.US_BANK_ACCOUNT &&
                    !bypass &&
                    "The refund will be issued via Stripe and returned to the original payment method. Expect a processing time of 7-10 days."}
                  {type === PaymentMethod.CHECK &&
                    "You must submit a check processing request for the payor(s) to receive the refund."}
                  {type === PaymentMethod.FINANCIAL_ADJUSTMENT && `Adjustments will be reflected immediately.`}
                  {type === PaymentMethod.CARD &&
                    bypass &&
                    `This payment was collected via Credit Card but could not be refunded through Stripe. You must submit a check processing request for the payor(s) to receive the refund.`}
                  {type === PaymentMethod.US_BANK_ACCOUNT &&
                    bypass &&
                    `This payment was collected via ACH but could not be refunded through Stripe. You must submit a check processing request for the payor(s) to receive the refund.`}
                </p>
              </div>
            </div>
            <div className="text-label-small text-sys-brand-on-surface-variant">
              {centsToDollarsFormatted(totalRefund)}
            </div>
          </div>
        </div>
      </div>

      <h5 className="text-title-medium mt-6 mb-2">Refund Settings</h5>
      <div className="flex items-center py-2">
        <Controller
          render={({ field }) => {
            return (
              <Checkbox
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                name={`reopenOrder`}
              />
            );
          }}
          control={control}
          name={`reopenOrder`}
        />
        <div className="pl-4">
          {isSomeOrderOpen ? (
            <>
              <h6 className="text-body-large">Balance(s) of Refunded Product(s) are Payable</h6>
              <h6 className="text-body-medium">
                If checked, product(s) will remain open for payment. If unchecked, no additional payment will be
                collected
              </h6>
            </>
          ) : (
            <>
              <h6 className="text-body-large">Reopen Order with Balance</h6>
              <h6 className="text-body-medium">
                Reopen{" "}
                {order
                  ? "order"
                  : `${payment.orderPayments.length} ${pluralize(payment.orderPayments.length, "order")}`}{" "}
                and set the refund amount as a payable balance
              </h6>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center py-2">
        <Controller
          render={({ field }) => {
            return (
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                name={`notifyPayor`}
                disabled={!allowOpenOrder}
              />
            );
          }}
          control={control}
          name={`notifyPayor`}
        />
        <div className="pl-4">
          <h6 className="text-body-large">Notify Payor of Balance</h6>
          <h6 className="text-body-medium">Send a notification to the order payor about the open balance due</h6>
        </div>
      </div>
      <div className="py-6">
        {allowOpenOrder && (
          <div className="mb-6">
            <Controller
              name="dueDate"
              render={({ field }) => (
                <DateSelect
                  useSingleDate
                  value={field.value}
                  label="Order Due Date*"
                  onChange={field.onChange}
                  setIsDateSelectorOpen={setIsDateSelectorOpen}
                />
              )}
            />
          </div>
        )}
        <Controller
          render={({ field }) => (
            <TextField
              disabled={false}
              type="text"
              className="w-full"
              value={field.value}
              label="Note"
              onChangeText={field.onChange}
            />
          )}
          control={control}
          name="note"
        />
      </div>
    </div>
  );
}
