import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { NumberFormatBase } from "react-number-format";
import { currencyFormatterWithPrefix } from "@/lib/currencyHelpers";

interface EditableCellProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  icon?: React.ReactNode;
  isEditingCell?: boolean;
  setEditableRowIndex?: () => void;
  editableInputType?: "currency" | "default";
}

export function EditableCell({
  value,
  onChange,
  className,
  icon,
  isEditingCell,
  setEditableRowIndex,
  editableInputType = "default"
}: EditableCellProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsEditing(isEditingCell ?? false);
  }, [isEditingCell]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setEditableRowIndex?.();
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing, setEditableRowIndex]);

  const handleSave = () => {
    setIsEditing(false);
    if (editValue !== value) {
      onChange(editValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      setIsEditing(false);
      setEditValue(value);
    }
  };

  const props = {
    value: editValue,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEditValue(e.target.value),
    onBlur: handleSave,
    onKeyDown: handleKeyDown,
    onFocus: () => setIsEditing(true),
    className: classNames(
      "w-full px-2 py-3 outline-none",
      "border-2 bg-transparent",
      "hover:bg-sys-brand-surface-container-high rounded-md",
      className,
      isEditing ? "border-sys-brand-primary" : "border-transparent cursor-pointer",
      icon ? "pl-8" : ""
    )
  };

  return (
    <div className="flex flex-row gap-1 items-center relative">
      {icon && <div className="absolute left-1 top-3">{icon}</div>}
      {editableInputType === "currency" ? (
        <NumberFormatBase getInputRef={inputRef} format={currencyFormatterWithPrefix} {...props} />
      ) : (
        <input ref={inputRef} type="text" {...props} />
      )}
    </div>
  );
}
