import { Person } from "@/types";

export enum OrderStage {
  DRAFT = "draft",
  OPEN = "open",
  PENDING = "pending",
  PAID = "paid",
  REFUNDED = "refunded",
  FAILED = "failed",
  CANCELED = "canceled",
  OVERDUE = "overdue"
}

export enum OrderPaymentStatus {
  OVERDUE = "overdue",
  FAILED = "payment-failed",
  REFUNDED = "payment-refunded"
}

export enum PaymentStatus {
  PAID = "paid",
  PENDING = "pending",
  FAILED = "failed",
  REFUNDED = "refunded",
  PARTIALLY_REFUNDED = "partially-refunded"
}
export enum PaymentMethod {
  CARD = "card",
  CHECK = "check",
  US_BANK_ACCOUNT = "us_bank_account",
  FINANCIAL_ADJUSTMENT = "financial_adjustment"
}

export enum paymentSourceTypes {
  self = "self",
  facility = "facility",
  other = "other"
}

export interface OrderRow {
  id: string;
  stage: OrderStage;
  paymentStatus: OrderPaymentStatus;
  description: string;
  person: Person;

  pgaId?: string | undefined;
  payable?: boolean;
  lineItems?: {
    id: string;
    name: string;
    priceCents: number;
    quantity: number;
    totalPrice: number;
  }[];
}

export interface OrderNode {
  id: string;
  stage: OrderStage;
  balance: number;
  dueDate?: string;
  paymentStatus: OrderPaymentStatus;
  payable: boolean;
  updatedAt: string;
  description: string;
  lineItems: {
    id: string;
    name: string;
    priceCents: number;
    quantity: number;
    totalPrice: number;
  }[];
  person: Person;
}
