import { Link } from "@/router";
import IconButton from "@/components/IconButton";
import type { Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import Menu, { MenuItem } from "@/components/Menu";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";

export default function TopAppBar<P extends Path>({
  backLink,
  cancelButton,
  actions,
  extendedActions
}: {
  backLink?: LinkProps<P, Params>;
  cancelButton?: React.ReactNode;
  actions?: React.ReactNode;
  extendedActions?: React.ReactNode;
}) {
  const menuItems: MenuItem[] = [];
  const extendedMenuItems: MenuItem[] = [];

  const isMobileDisplay = isMobile(useWindowSize().width);

  if (Array.isArray(actions)) {
    actions.forEach((action) => {
      menuItems.unshift({ label: action.props.children, onClick: action.props.onClick });
    });
  }

  if (Array.isArray(extendedActions)) {
    extendedActions.forEach((action) => {
      extendedMenuItems.unshift({ label: action.props.children, onClick: action.props.onClick });
    });
  }

  return (
    <div className="px-3 border-b tablet:px-12 pt-7 pb-2" data-testid="top-app-bar">
      <div className="w-full flex items-center h-[70px] justify-between">
        {backLink && !cancelButton && (
          <Link {...backLink}>
            <div className="flex items-center">
              <IconButton name="arrow_back" data-testid="back" iconClassName="text-sys-brand-on-surface" />
              <span className="text-title-large">{backLink.title || "Back"}</span>
            </div>
          </Link>
        )}
        {cancelButton && cancelButton}
        {isMobileDisplay && menuItems.length > 1 ? (
          <Menu AnchorComponent={<IconButton name="pending" />} menuItems={menuItems.concat(extendedMenuItems)} />
        ) : (
          <div className="flex items-center">
            <div className="flex items-center gap-2">{actions}</div>
            {extendedMenuItems.length > 0 && (
              <Menu AnchorComponent={<IconButton name="pending" />} menuItems={extendedMenuItems} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
