import usePageTitle from "@/hooks/usePageTitle";
import TabLayout from "../_TabLayout";
import PeopleTable from "../_components/PeopleTable";

export default function TerminationsPage() {
  usePageTitle(
    "/billing/dues-management/reporting/terminations",
    "Billing | Dues Management | Reporting | Terminations"
  );
  return (
    <TabLayout>
      <PeopleTable defaultVariables={{ terminated: true }} />
    </TabLayout>
  );
}
