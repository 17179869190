import { useEffect, useState } from "react";
import usePageTitle from "@/hooks/usePageTitle";
import EmptyState from "@/components/EmptyState";
import Table, { TableDetails, type Row } from "@/components/Table";
import TabLayout from "../_TabLayout";
import { useQuery } from "@apollo/client";
import { VIDEOS_QUERY } from "./queries";
import { Variables, VideoQuery, VideoNode } from "./types";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import useActionMenu from "@/hooks/useActionMenu";
import { useSearch } from "@/hooks/useSearch";
import { useNavigate, Path } from "@/router";
import SearchInput from "@/components/SearchInput";
import { Controller, useForm } from "react-hook-form";
import { ButtonWithLink } from "@/components/withLink";
import { cond, matches } from "lodash";
import Tag from "@/components/Tag";
import usePagination from "@/hooks/usePagination";
import MultiSelect from "@/components/MultiSelect";
import { snakeCase } from "lodash";
import { ColumnSort } from "@tanstack/react-table";
import ExpandedFilters from "@/components/ExpandedFilters";
import { FormProvider } from "react-hook-form";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";
import Button from "@/components/Button";
import { useFlags } from "@/hooks/useFeatureFlags";
import useCapabilities, { PERMISSION } from "@/hooks/useCapabilities";
import getActiveFilters from "@/lib/filterHelpers";
import { useParams } from "@/hooks/useSearchParams";

interface VideoRow extends VideoNode {}

const renderStatus = cond([
  [
    matches("Active"),
    () => (
      <Tag color="primary" testId="active">
        Active
      </Tag>
    )
  ],
  [
    matches("Upcoming"),
    () => (
      <Tag color="tertiaryDim" testId="upcoming">
        Upcoming
      </Tag>
    )
  ],
  [
    matches("Expired"),
    () => (
      <Tag color="secondary" testId="expired">
        Expired
      </Tag>
    )
  ]
]);

type ExtendedVariables = Variables & {
  stages?: string[];
  creditCategories?: string[];
  orderBy?: { field: string; direction: string };
};

export default function ContentPage() {
  usePageTitle("/education/videos", "Education | Videos");
  const searchField = "title";
  const { params, addSearchParam, deleteSearchParams, searchParams } = useParams();
  const { paginationVariables, renderFooter, resetPagination } = usePagination();

  const [variables, setVariables] = useState<ExtendedVariables>(params);
  const { searchValue, control: controlSearch } = useSearch(searchField, params.searchTerm || "");
  const [selectedStages, setSelectedStages] = useState<string[]>(params.stages || []);
  const [selectedCreditCategories, setSelectedCreditCategories] = useState<string[]>(params.creditCategories || []);
  const [orderBy, setOrderBy] = useState<ColumnSort[]>([]);
  const { hasPermission } = useCapabilities();
  const readOnly = !hasPermission(PERMISSION.manageVideos);

  const { Menu } = useActionMenu();
  const navigate = useNavigate();
  function onClickRow(row: Row<VideoRow>) {
    navigate(`/education/videos/${row.original.id}?return_to=/education/videos&${searchParams}` as Path, {
      state: { video: row.original }
    });
  }

  const form = useForm();
  const { control, reset } = form;

  const flags = useFlags();
  const flagAddVideo = flags["backoffice-pdr-videos"];

  useEffect(() => {
    resetPagination();
    setVariables({
      searchTerm: searchValue || undefined,
      stages: selectedStages.length > 0 ? selectedStages : undefined,
      creditCategories: selectedCreditCategories.length > 0 ? selectedCreditCategories : undefined,
      orderBy: orderBy[0]
        ? { field: snakeCase(orderBy[0]?.id).toUpperCase(), direction: orderBy[0]?.desc ? "DESC" : "ASC" }
        : undefined
    });
  }, [searchValue, selectedStages, selectedCreditCategories, orderBy, resetPagination]);

  const { data, loading } = useQuery<VideoQuery>(VIDEOS_QUERY, {
    variables: { ...variables, ...paginationVariables }
  });

  const videoNodeData: VideoNode[] = data?.membershipVideos.nodes || [];

  const videoData = videoNodeData.map((video: VideoNode) => ({
    ...video,
    publishedAt: video.publishedAt
      ? new Date(video.publishedAt).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
      : "",
    expiredAt: video.expiredAt
      ? new Date(video.expiredAt).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
      : ""
  }));

  const columnHeaders = {
    title: "Video Title",
    creditCategory: "Credit Type",
    series: "Series",
    publishedAt: "Publish Date",
    expiredAt: "Expiration Date",
    stage: "Stage"
  };

  const convertCreditCategory = (category: string) => {
    switch (category) {
      case "MEETINGS":
        return "Meetings";
      case "NATIONAL_SURVEYS":
        return "National Surveys";
      case "EDUCATION":
        return "Education";
      case "PLAYER_ENGAGEMENT":
        return "Player Engagement";
      case "OTHER":
        return "Other";
      default:
        return category;
    }
  };

  const getVideoStage = (video: VideoNode) => {
    const now = new Date();
    const publishedAt = new Date(video.publishedAt);
    const expiredAt = video.expiredAt ? new Date(video.expiredAt) : null;

    if (now >= publishedAt && (!expiredAt || now < expiredAt)) {
      return "Active";
    } else if (now < publishedAt) {
      return "Upcoming";
    } else if (expiredAt && now >= expiredAt) {
      return "Expired";
    }
    return "";
  };

  const handleSort = (updaterOrValue: any) => {
    const sortState = typeof updaterOrValue === "function" ? updaterOrValue([]) : updaterOrValue;
    const { id, desc } = sortState[0] || {};
    setOrderBy([{ id, desc }]);
  };

  const isMobileDisplay = isMobile(useWindowSize().width);

  const filters = {
    stages: selectedStages.length > 0,
    creditCategories: selectedCreditCategories.length > 0
  };

  const { isActive, activeFilters } = getActiveFilters(filters, filters, isMobileDisplay);

  const createVideoButton = () => (
    <ButtonWithLink link={{ to: "/education/videos/publish-video" }} variant="filled" icon="add">
      Add Video
    </ButtonWithLink>
  );
  let buttonComps: (() => JSX.Element)[] = [];
  if (flagAddVideo && !readOnly) {
    buttonComps.push(createVideoButton);
  }

  const baseColumns = [
    {
      accessorKey: "title",
      header: columnHeaders["title"],
      enableSorting: true,
      meta: {
        className: getStickyColumnClasses(!!videoData?.length)
      }
    }
  ];

  const desktopColumns = [
    ...baseColumns,
    {
      accessorKey: "creditCategory",
      header: columnHeaders["creditCategory"],
      enableSorting: true,
      cell: ({ row }: { row: Row<VideoRow> }) => convertCreditCategory(row.original.creditCategory)
    },
    {
      accessorKey: "series",
      header: columnHeaders["series"],
      enableSorting: true
    },
    {
      accessorKey: "publishedAt",
      header: columnHeaders["publishedAt"],
      enableSorting: true
    },
    {
      accessorKey: "expiredAt",
      header: columnHeaders["expiredAt"],
      enableSorting: true
    },
    {
      accessorKey: "stage",
      header: columnHeaders["stage"],
      enableSorting: false,
      cell: ({ row }: { row: Row<VideoRow> }) => renderStatus(getVideoStage(row.original))
    }
  ];

  return (
    <TabLayout>
      <Menu />
      <Table
        data={videoData || []}
        loading={loading}
        onClickRow={onClickRow as (row: Row<VideoRow>) => void}
        onSortingChange={handleSort}
        sortingState={orderBy}
        serverSideSorting={true}
        renderDetails={() => (
          <TableDetails
            heading="Online Education Self-Paced Videos"
            body="Add videos and assessments for the Professional Development Requirement Policy"
            ButtonComps={buttonComps}
          />
        )}
        renderHeader={() => (
          <div className="flex md:items-center flex-col md:flex-row justify-between">
            <div className="flex flex-row justify-between items-center pr-2 md:pr-0">
              <div className="flex flex-row justify-between gap-4 md:w-auto focus:outline-none">
                <FormProvider {...form}>
                  <div className="w-full sm:max-w-[260px] flex-shrink-1 md:flex-shrink-0 focus:outline-none">
                    <Controller
                      name="title"
                      control={controlSearch}
                      render={({ field }) => (
                        <SearchInput
                          placeholder="Search Video"
                          query={field.value}
                          param={columnHeaders[searchField]}
                          onClear={() => {
                            deleteSearchParams("searchTerm");
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            setVariables((prevValue) => ({ ...prevValue, searchTerm: e }));
                            resetPagination();
                            addSearchParam("searchTerm", e);
                          }}
                        />
                      )}
                    />
                  </div>
                  {!isMobileDisplay && (
                    <>
                      <Controller
                        name="stages"
                        control={control}
                        render={() => (
                          <MultiSelect
                            label="Stage"
                            options={[
                              { value: "Active", label: "Active" },
                              { value: "Expired", label: "Expired" },
                              { value: "Upcoming", label: "Upcoming" }
                            ]}
                            className="w-full"
                            selectedValues={selectedStages}
                            onSelect={(value, checked) => {
                              const status = checked
                                ? [...selectedStages, value]
                                : selectedStages.filter((v) => v !== value);
                              resetPagination();
                              setSelectedStages(status);
                              addSearchParam("stages[]", status.join(","));
                            }}
                            onClear={() => {
                              setSelectedStages([]);
                              deleteSearchParams("stages[]");
                            }}
                            required
                          />
                        )}
                      />
                      <Controller
                        name="creditCategories"
                        control={control}
                        render={() => (
                          <MultiSelect
                            label="Credit Type"
                            options={[
                              { value: "EDUCATION", label: "Education" },
                              { value: "MEETINGS", label: "Meetings" },
                              { value: "NATIONAL_SURVEYS", label: "National Surveys" },
                              { value: "PLAYER_ENGAGEMENT", label: "Player Engagement" },
                              { value: "OTHER", label: "Other" }
                            ]}
                            className="w-full"
                            selectedValues={selectedCreditCategories}
                            onSelect={(value, checked) => {
                              const status = checked
                                ? [...selectedCreditCategories, value]
                                : selectedCreditCategories.filter((v) => v !== value);
                              resetPagination();
                              setSelectedCreditCategories(status);
                              addSearchParam("creditCategories[]", status.join(","));
                            }}
                            onClear={() => {
                              setSelectedCreditCategories([]);
                              deleteSearchParams("creditCategories[]");
                            }}
                            required
                          />
                        )}
                      />
                    </>
                  )}
                  {isMobileDisplay && (
                    <ExpandedFilters
                      activeFilters={activeFilters}
                      items={[
                        {
                          component: (
                            <Controller
                              name="stages"
                              control={control}
                              render={() => (
                                <MultiSelect
                                  label="Stage"
                                  options={[
                                    { value: "Active", label: "Active" },
                                    { value: "Expired", label: "Expired" },
                                    { value: "Upcoming", label: "Upcoming" }
                                  ]}
                                  className="w-full rounded-lg mx-auto"
                                  selectedValues={selectedStages}
                                  onSelect={(value, checked) => {
                                    const status = checked
                                      ? [...selectedStages, value]
                                      : selectedStages.filter((v) => v !== value);
                                    setSelectedStages(status);
                                    addSearchParam("stages[]", status.join(","));
                                  }}
                                  onClear={() => {
                                    setSelectedStages([]);
                                    deleteSearchParams("stages[]");
                                  }}
                                  required
                                />
                              )}
                            />
                          ),
                          onlyMobile: true
                        },
                        {
                          component: (
                            <Controller
                              name="creditCategories"
                              control={control}
                              render={() => (
                                <MultiSelect
                                  label="Credit Type"
                                  options={[
                                    { value: "EDUCATION", label: "Education" },
                                    { value: "MEETINGS", label: "Meetings" },
                                    { value: "NATIONAL_SURVEYS", label: "National Surveys" },
                                    { value: "PLAYER_ENGAGEMENT", label: "Player Engagement" },
                                    { value: "OTHER", label: "Other" }
                                  ]}
                                  className="w-full rounded-lg mx-auto"
                                  selectedValues={selectedCreditCategories}
                                  onSelect={(value, checked) => {
                                    const status = checked
                                      ? [...selectedCreditCategories, value]
                                      : selectedCreditCategories.filter((v) => v !== value);
                                    setSelectedCreditCategories(status);
                                    addSearchParam("creditCategories[]", status.join(","));
                                  }}
                                  onClear={() => {
                                    setSelectedCreditCategories([]);
                                    deleteSearchParams("creditCategories[]");
                                  }}
                                  required
                                />
                              )}
                            />
                          ),
                          onlyMobile: true
                        },
                        {
                          component: (
                            <>
                              {isActive && (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    setSelectedStages([]);
                                    setSelectedCreditCategories([]);
                                    deleteSearchParams(["stages[]", "creditCategories[]"]);
                                    reset();
                                  }}
                                >
                                  Clear All
                                </Button>
                              )}
                            </>
                          ),
                          onlyMobile: true
                        }
                      ]}
                    />
                  )}
                  {isActive && (
                    <div className="hidden my-2 sm:block" data-testid="clear-all-button">
                      <Button
                        variant="text"
                        onClick={() => {
                          setSelectedStages([]);
                          setSelectedCreditCategories([]);
                          deleteSearchParams(["stages[]", "creditCategories[]"]);
                          reset();
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  )}
                </FormProvider>
              </div>
            </div>
            <div className="mx-2">
              {renderFooter(data?.membershipVideos, {
                totalCount: data?.membershipVideos?.totalCount || 0,
                pageCount: videoData.length || 0,
                variant: "compact"
              })()}
            </div>
          </div>
        )}
        columns={isMobileDisplay ? baseColumns : desktopColumns}
        renderEmptyState={() => (
          <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="movie" />
        )}
        renderFooter={renderFooter(data?.membershipVideos, {
          totalCount: data?.membershipVideos?.totalCount || 0,
          pageCount: videoData.length || 0
        })}
      />
    </TabLayout>
  );
}
