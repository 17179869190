import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Node, NOTE_CHANGE_REQUEST_MUTATION } from "../queries";
import Button from "@/components/Button";
import Progress from "@/components/Progress";
import TextField from "@/components/TextField";
import useModal from "@/hooks/useModal";

export default function NoteModal({
  changeRequest: { id: changeRequestId },
  onClosed
}: {
  changeRequest: Node;
  onClosed(): void;
}) {
  const { save, Modal } = useModal();
  const [message, setMessage] = useState("");
  const [send, { loading }] = useMutation(NOTE_CHANGE_REQUEST_MUTATION);
  const submit = () =>
    save(
      () => send({ variables: { id: changeRequestId, message } }),
      (data) => data.noteChangeRequest,
      "Note added"
    );
  return (
    <Modal
      onClosed={onClosed}
      action={
        <Button disabled={loading || !message} onClick={submit} icon="check">
          Save
        </Button>
      }
      headline="Add Internal Note"
    >
      {loading ? (
        <Progress variant="linear" />
      ) : (
        <TextField
          className="w-full md:w-80 h-48 mt-6 mb-3"
          variant="outlined"
          type="textarea"
          label="Internal Note"
          value={message}
          onChangeText={setMessage}
        />
      )}
    </Modal>
  );
}
