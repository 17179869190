import { gql } from "@apollo/client";

export const UPDATE_MEMBERSHIP_VIDEO_MUTATION = gql`
  mutation UpdateMembershipVideo($input: BackofficeUpdateMembershipVideoInput!) {
    updateMembershipVideo(videoInput: $input) {
      message
      success
    }
  }
`;

export const GET_MEMBERSHIP_VIDEO_QUERY = gql`
  query GetMembershipVideo($id: ID) {
    membershipVideos(id: $id) {
      id
      title
      url
      description
      series
      publishedAt
      expiredAt
      creditCategory
      durationInSeconds
    }
  }
`;
