import { useSidebar } from "@/hooks/useSidebar";
import { useState, PropsWithChildren } from "react";
import IconButton from "@/components/IconButton";
import NavigationRail from "./NavigationRail";
import { useWindowSize } from "@/hooks/useWindowSize";
import NavigationDrawerModal from "@/components/NavigationDrawerModal";
import NavLogo from "@/components/TopActionBar/NavLogo";
import { isMobile, isTablet } from "@/assets/theme/sizes";
import { useLocation } from "react-router-dom";
import { MaterialNames } from "@/components/material_symbol_names";
import renderNavLinks from "./renderNavLinks";
import { Params, Path } from "@/router";
import useCapabilities, { DOMAIN } from "@/hooks/useCapabilities";
import { LinkProps } from "@generouted/react-router/client";
import BottomTabs from "@/components/BottomBar";

type NavigationProps = {
  toggleSidebar: () => void;
};

type OpenNavigationProps = PropsWithChildren<NavigationProps> & {
  isHovering: boolean;
};

const OpenNavigation = ({ children, toggleSidebar, isHovering }: PropsWithChildren<OpenNavigationProps>) => {
  return (
    <NavigationRail>
      <div className="flex flex-col items-start self-stretch w-full pt-4">
        <div className="flex items-center self-stretch pl-5 pr-3">
          <NavLogo />
          {isHovering && (
            <IconButton
              name="menu_open"
              ariaLabel="minimize_sidebar"
              onClick={toggleSidebar}
              className={`ml-auto mr-1`}
            />
          )}
        </div>

        <div className="flex flex-col items-start">
          <div className="flex w-[360px] p-3 flex-col items-start">{children}</div>
        </div>
      </div>
    </NavigationRail>
  );
};

const ClosedNavigation = ({ children, toggleSidebar }: PropsWithChildren<NavigationProps>) => {
  return (
    <NavigationRail isOpen={false}>
      <div className="flex flex-col items-center">
        <NavLogo className="mt-4 mb-2" />
        <IconButton name="menu_open" className="transform rotate-180 mb-14" onClick={toggleSidebar} />
        {children}
      </div>
    </NavigationRail>
  );
};

export type NavLink<P extends Path> = {
  label: string;
  link: LinkProps<P, Params>;
  icon: MaterialNames;
  domain: DOMAIN;
};

const defaultNavLinks: Record<string, NavLink<Path>[]> = {
  Organization: [
    { domain: DOMAIN.EVERYONE, label: "People", link: { to: "/people" }, icon: "group" },
    { domain: DOMAIN.SECTIONS, label: "Sections", link: { to: "/sections" }, icon: "domain" },
    { domain: DOMAIN.FACILITIES, label: "Facilities", link: { to: "/facilities" }, icon: "store" }
  ],
  Manage: [
    {
      domain: DOMAIN.BENEFIT_PROGRAMS,
      label: "Benefit Programs",
      link: { to: "/benefit-programs" },
      icon: "volunteer_activism"
    },
    {
      domain: DOMAIN.EVERYONE,
      label: "Events",
      link: { to: "/events" },
      icon: "today"
    },
    {
      domain: DOMAIN.COMMERCE,
      label: "Billing",
      link: { to: "/billing" },
      icon: "card_membership"
    },
    {
      domain: DOMAIN.EVERYONE,
      label: "Education",
      link: { to: "/education/videos" },
      icon: "school"
    }
  ]
};

interface SidebarProps<P extends Path> {
  interior?: boolean;
  navLinks?: Record<string, NavLink<P>[]> | NavLink<P>[];
  bottomBar?: boolean;
}

export default function Sidebar<P extends Path>({
  navLinks = defaultNavLinks as Record<string, NavLink<P>[]>,
  interior = false,
  bottomBar = false
}: PropsWithChildren<SidebarProps<P>>) {
  const windowSize = useWindowSize();
  const { isSidebarOpen, setSidebarOpen } = useSidebar();
  const location = useLocation();
  const { capabilities } = useCapabilities();
  const sidebarColor = interior ? "bg-sys-brand-surface-container" : "bg-sys-brand-surface";
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseHover(event: React.MouseEvent) {
    setIsHovering(event.type === "mouseenter");
  }
  function toggleSidebar() {
    setSidebarOpen(!isSidebarOpen);
  }

  const renderedNavLinks = Array.isArray(navLinks)
    ? renderNavLinks({
        navLinks,
        domains: capabilities.domains,
        isLastGroup: true,
        location,
        isSidebarOpen
      })
    : Object.entries(navLinks).map(([groupTitle, links], index, array) =>
        renderNavLinks({
          domains: capabilities.domains,
          navLinks: links,
          groupTitle,
          isLastGroup: index === array.length - 1,
          location,
          isSidebarOpen
        })
      );

  const renderedDefaultNavLinks = Object.entries(defaultNavLinks).map(([groupTitle, links], index, array) =>
    renderNavLinks({
      domains: capabilities.domains,
      navLinks: links,
      groupTitle,
      isLastGroup: index === array.length - 1,
      location,
      isSidebarOpen
    })
  );

  const secondaryNavLinks = Object.entries(navLinks).map(([, links]) => links)[0];

  if (isMobile(windowSize.width)) {
    return (
      <>
        <NavigationDrawerModal isOpen={isSidebarOpen} onChange={setSidebarOpen} data-testid="drawer">
          <div className="tablet:hidden flex items-center pt-4 pb-2 px-3">
            <IconButton name={"close"} onClick={() => setSidebarOpen(!isSidebarOpen)} />
            <NavLogo className="w-12 h-12" />
          </div>
          <div className={`flex flex-col items-start`}>
            <div className="flex w-full p-3 flex-col items-start">{renderedDefaultNavLinks}</div>
          </div>
        </NavigationDrawerModal>
        {bottomBar && <BottomTabs tabItems={secondaryNavLinks} domains={capabilities.domains} />}
      </>
    );
  } else if (isTablet(windowSize.width)) {
    return (
      <div className={`flex items-start min-h-screen ${sidebarColor}`} data-testid="sidebar">
        <NavigationDrawerModal isOpen={isSidebarOpen} onChange={setSidebarOpen} data-testid="drawer">
          <div className="flex items-center justify-between p-4 mb-4">
            <NavLogo />
            <IconButton name="menu_open" onClick={toggleSidebar} />
          </div>

          {isSidebarOpen ? <div className="flex w-full p-3 flex-col items-start">{renderedNavLinks}</div> : null}
        </NavigationDrawerModal>

        <ClosedNavigation toggleSidebar={toggleSidebar}>{isSidebarOpen ? null : renderedNavLinks}</ClosedNavigation>
      </div>
    );
  } else {
    return (
      <div
        className={`flex items-start min-h-screen ${sidebarColor}`}
        data-testid="sidebar"
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseHover}
      >
        {isSidebarOpen ? (
          <OpenNavigation toggleSidebar={toggleSidebar} isHovering={isHovering}>
            {renderedNavLinks}
          </OpenNavigation>
        ) : (
          <ClosedNavigation toggleSidebar={toggleSidebar}>{renderedNavLinks}</ClosedNavigation>
        )}
      </div>
    );
  }
}
