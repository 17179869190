import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import ProductFields from "../components/ProductFields";
import PaymentSummary from "../components/PaymentSummary";

const PricingStep = ({ hasRegistrations }: { hasRegistrations: boolean }) => {
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "products"
  });

  const addProduct = () => {
    append({
      productId: "",
      description: "",
      variantId: "",
      variantName: "",
      variantPrice: 0,
      collection: ""
    });
  };

  const hasSelectedProduct = !!watch("products.0.productId");
  const hasMultipleProducts = fields.length > 1;

  return (
    <div className="flex flex-col gap-6 mb-6">
      <p className="text-title-large mb-2"> How much should be charged for this event?</p>
      <p className="text-body-medium">
        Choose existing product(s) or leave blank if the event is free. Payment can be collected during registration or
        at the event location if applicable. All registered sessions are included in the price. Note: Changes can be
        made until the first registration has been submitted.
      </p>
      <div className="flex flex-col gap-6">
        {fields.map((field, index) => (
          <ProductFields
            key={field.id}
            index={index}
            hasRegistrations={hasRegistrations}
            hasMultipleProducts={hasMultipleProducts}
            onRemove={() => {
              if (!hasRegistrations && hasMultipleProducts) {
                remove(index);
              }
            }}
          />
        ))}
      </div>
      {hasSelectedProduct && (
        <>
          <div>
            <Button variant="filledTonal" icon="add" theme="info" onClick={addProduct} disabled={hasRegistrations}>
              Add Product
            </Button>
          </div>
          <div className="py-3">
            <Divider />
          </div>
          <div>
            <PaymentSummary />
          </div>
        </>
      )}
    </div>
  );
};

export default PricingStep;
