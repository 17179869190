import Icon from "../Icon";
import { MaterialNames } from "../material_symbol_names";

const sharedClassName = "object-cover rounded-full";

const sizes = {
  small: { className: "w-6 h-6", size: 16 },
  medium: { className: "w-12 h-12", size: 28 },
  large: { className: "w-[70px] h-[70px]", size: 40 }
};

interface Props {
  picture?: string;
  size?: keyof typeof sizes;
  className?: string;
  iconClassName?: string;
  iconName?: MaterialNames;
}

export default function Avatar({ picture, size = "medium", className, iconClassName, iconName = "person" }: Props) {
  const sizeData = sizes[size];

  return picture ? (
    <img
      src={picture}
      className={`${sharedClassName} ${sizeData.className} ${className}`}
      alt="Rounded avatar"
      data-testid="avatar-image"
    />
  ) : (
    <div
      className={`${sharedClassName} ${sizeData.className} bg-sys-brand-secondary-container flex items-center justify-center ${className}`}
      data-testid="avatar-person-icon"
    >
      <Icon name={iconName} size={sizeData.size} className={iconClassName} />
    </div>
  );
}
