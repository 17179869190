import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import JobsTableLayout from "@/pages/_layouts/jobs/JobsTableLayout";
import { useParams } from "react-router-dom";

export default function JobsList() {
  useRequireCapability({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewJobPosts });

  const { sectionId } = useParams();

  return (
    <JobsTableLayout
      tableHeading="PGA Job Board - Section Jobs"
      tableDescription="View and manage all jobs posted for Section facilities on the PGA job board"
      columnHeaders={{
        title: true,
        facility: true,
        serviceLevel: true,
        employer: true,
        owner: false,
        postedOn: true,
        submissionDeadline: true,
        applicantCount: true,
        postingStage: true,
        jobStatus: true
      }}
      filters={{ postingStage: true, jobStatus: true, serviceLevel: true, postedDateRange: true }}
      defaultValues={{ sectionId: sectionId }}
      defaultSort={[{ id: "createdAt", desc: true }]}
    />
  );
}
