import { gql } from "@apollo/client";

export const EVENT_REGISTRATION_QUERY = gql`
  query GetEventRegistration($id: ID!) {
    eventRegistration(id: $id) {
      id
      canceledAt
      event {
        eventType
        title
        waitlistEnabled
        customFields
      }
      person {
        pgaId
        firstName
        lastName
        profilePhoto
        email
        mainProgramType {
          name
        }
        addresses {
          addressType
          address1
          address2
          city
          state
          postalCode
        }
        phoneNumbers {
          phoneNumber
          phoneFormatted
          phoneType
          phoneCountry
        }
      }
      sessions {
        duration
        endsAt
        startsAt
        title
      }
      customFields {
        adaAccomodations
        additionalGuests
        dateOfBirth
        disclaimer
        gender
      }
      order {
        id
        updatedAt
        status
        totalPaid
      }
    }
  }
`;
