import usePageTitle from "@/hooks/usePageTitle";
import Button from "@/components/Button";
import { useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import PGALogo from "@/assets/images/pga-logo-reversed.svg";
import { showDevBanner } from "@/lib/urlUtils";
import classNames from "classnames";

const pattern = "/";

export default function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.key === "default");

  usePageTitle(pattern, "404");

  return (
    <div className="bg-[#00205B] bg-[url('assets/images/topography-bg.svg')] min-h-[100vh]">
      <div className="absolute top-6 left-6 right-6 md:left-[240px] md:top-[120px] 2xl:left-[240px]">
        <div
          className={classNames("border-l-[20px] border-sys-brand-primary pl-[40px] translate-x-[-60px]", {
            "mt-[60px] sm:mt-0": showDevBanner
          })}
        >
          <img src={PGALogo} />
          <div className="md:text-[100px] text-[60px] font-bold text-sys-brand-primary leading-none mb-12">
            4-0-Fore
          </div>
        </div>
        <div className="text-headline-large font-bold text-white">
          Sorry, we can’t find the page you were looking for
        </div>
        <div className="text-title-large text-white mt-2">It may have been moved, deleted, or does not exist</div>
        <div className="flex flex-col items-start md:flex-row gap-4 mt-8">
          <Button onClick={() => navigate(pattern)}>Back to Home</Button>
          {location.key !== "default" && (
            <Button onClick={() => navigate(-1)} theme="pgaAssistant">
              Back to Previous Page
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
