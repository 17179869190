import Button from "@/components/Button";
import Dialog, { Ref } from "@/components/Dialog";
import { useRef } from "react";

export default function EditsRequiredModal({ onClosed }: { onClosed(): void }) {
  const ref = useRef<Ref>(null);

  function close() {
    ref.current?.close();
  }

  return (
    <Dialog
      open
      ref={ref}
      actions={<Button onClick={close}>Close</Button>}
      headline="Updates Needed Before Approval"
      onClosed={onClosed}
    >
      In order to approve this change request, data must first be finalized. Use the edit button on the change row
      needing attention to finalize the data.
    </Dialog>
  );
}
