import * as LitComps from "./LitIconButton";
import Icon, { SlotIconName } from "../Icon";
import type { MaterialNames } from "../material_symbol_names";
import { SlotIcon } from "../Icon";
import classNames from "classnames";
import { useRef, useEffect } from "react";
import { updateFocusRing } from "@/lib/styleHelpers";

const variants = {
  outlined: LitComps.LitOutlinedIconButton,
  filled: LitComps.LitFilledIconButton,
  filledTonal: LitComps.LitFilledTonalIconButton,
  default: LitComps.LitIconButton
};

export interface Props {
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  name: MaterialNames;
  ariaLabel?: string;
  onClick?: (e: any) => void;
  selected?: boolean;
  selectedIcon?: SlotIconName;
  skipMargin?: boolean;
  title?: string;
  variant?: keyof typeof variants;
  "data-testid"?: string;
  id?: string;
  tabIndex?: number;
}

// IconButtons are supposed to be 48x48px on Figma. The material web library renders it as 40x40px with an extra 8x8px touchable area which is not computed during render.
// Adding m-2 to the IconButton to make it 48x48px.
const defaultMargin = "m-2";

const customStyles = {
  "--md-focus-ring-width": "-10"
} as React.CSSProperties;

export default function IconButton({
  className,
  iconClassName,
  onClick,
  disabled,
  selected,
  name,
  title = name,
  ariaLabel = title,
  selectedIcon,
  skipMargin,
  variant = "default",
  "data-testid": testId,
  id,
  tabIndex = 0
}: Props) {
  const ButtonComponent = variants[variant];

  const iconButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => updateFocusRing(iconButtonRef, "md-icon-button"), 100);
  }, []);

  return (
    <div className="inline-block" ref={iconButtonRef}>
      <ButtonComponent
        {...{ title, disabled, onClick, selected, id, tabIndex }}
        className={classNames(className, { [defaultMargin]: !skipMargin })}
        aria-label={ariaLabel}
        data-testid={testId}
        style={customStyles}
      >
        <Icon name={name} className={classNames(iconClassName, name === "more_vert" && "font-extrabold")} />
        {selectedIcon && <SlotIcon name={selectedIcon} slot="selected" />}
      </ButtonComponent>
    </div>
  );
}
