import MultiStepForm from "@/components/MultiStepForm";
import { DetailsStep } from "../videoDetails";
import { useForm } from "react-hook-form";
import { CREATE_MEMBERSHIP_VIDEO_MUTATION } from "./queries";
import { useMutation } from "@apollo/client";
import { showSnackbar } from "@/lib/snackbarUtils";
import { useNavigate } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import { Params, Path } from "@/router";
import AssessmentDetails from "../assessmentDetails";
import { useFlags } from "@/hooks/useFeatureFlags";
import { useEffect } from "react";
import usePageTitle from "@/hooks/usePageTitle";

export default function CreateVideoPage() {
  usePageTitle("/education/videos/publish-video", "Education | Videos | Publish Video");
  const form = useForm({
    defaultValues: {
      publishedAt: {
        startDate: new Date(),
        endDate: new Date()
      },
      expiredAt: null,
      title: "",
      description: "",
      url: "",
      series: "",
      creditCategory: "",
      durationInSeconds: 0
    }
  });

  const [createMembershipVideo] = useMutation(CREATE_MEMBERSHIP_VIDEO_MUTATION);
  const navigate = useNavigate();
  const homePath: LinkProps<Path, Params> = { to: "/education/videos" };
  const backLink = {
    to: homePath.to,
    title: "Cancel"
  };

  const flags = useFlags();
  const flagAddVideo = flags["backoffice-pdr-videos"];

  useEffect(() => {
    if (!flagAddVideo) {
      navigate(homePath.to);
    }
  }, [flagAddVideo, navigate, homePath.to]);

  async function handleSubmit(data: any) {
    const isValid = await form.trigger();
    if (isValid) {
      try {
        const input = {
          ...data,
          publishedAt: data.publishedAt.startDate,
          expiredAt: data.expiredAt?.startDate,
          assessment: {
            id: data.assessment.id,
            questions: data.assessment.questions.map((question: any, index: number) => ({
              id: question.id,
              content: question.content,
              position: index + 1,
              options: question.options.map((option: any, optionIndex: number) => ({
                id: option.id,
                content: option.content,
                answer: option.answer,
                position: optionIndex + 1
              }))
            }))
          }
        };

        const response = await createMembershipVideo({ variables: { input } });

        if (response.data.createMembershipVideo.success) {
          navigate(homePath.to);
          showSnackbar("Video Published");
        } else {
          showSnackbar(response.data.createMembershipVideo.message);
        }
      } catch (e) {
        showSnackbar("Error publishing video");
      }
    }
  }

  const steps = [
    {
      headline: "Details",
      supportingText: "Step 1",
      content: <DetailsStep form={form} />
    },
    {
      headline: "Assessment",
      supportingText: "Step 2",
      content: <AssessmentDetails form={form} />
    }
  ];

  if (!flagAddVideo) {
    return null;
  }

  return (
    <MultiStepForm
      form={form}
      steps={steps}
      renderStepper={true}
      onSubmit={handleSubmit}
      backLink={backLink}
      modelName="Video"
      enableSubmitOnLastStep={true}
      fullWidth={true}
    />
  );
}
