import { useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Controller } from "react-hook-form";
import { useSearch } from "@/hooks/useSearch";
import Table, { TableDetails } from "@/components/Table";
import Button from "@/components/Button";
import SearchInput from "@/components/SearchInput";
import useActionMenu from "@/hooks/useActionMenu";
import usePagination from "@/hooks/usePagination";
import { Variables, AttendanceTableProps } from "../types";
import { SessionRegistrationRow } from "../types";
import { SortingState } from "@tanstack/react-table";
import { getColumns } from "./TableColumns";
import EmptyState from "@/components/EmptyState";
import Chip from "@/components/Chip";
import { UPDATE_EVENT_SESSIONS_REGISTRATION_ATTENDANCES_MUTATION, EVENT_QUERY } from "../queries";
import { EventWrapper, BackofficeEventsUpdateEventSessionsRegistrationAttendanceInput } from "../types";
import { useMutation } from "@apollo/client";
import SessionStepper from "./SessionStepper";
import { showSnackbar } from "@/lib/snackbarUtils";
import pluralize from "@/lib/pluralize";

export default function AttendanceTable({ eventSlug, multiSession }: AttendanceTableProps) {
  const { renderFooter, resetPagination } = usePagination();
  const searchField = "search";

  const { searchValue, control, reset } = useSearch(searchField);
  const [variables, setVariables] = useState<Variables>({ eventSlug });
  const filterActive = variables.search;

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);

  const { Menu } = useActionMenu();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "select",
      desc: false
    }
  ]);

  const { data: eventDataResponse, loading: eventLoading } = useQuery<EventWrapper>(EVENT_QUERY, {
    variables: { slug: eventSlug }
  });
  const eventData = eventDataResponse?.event;

  useEffect(() => {
    resetPagination();
    setVariables((prev) => ({
      ...(searchValue ? { ...prev, search: searchValue } : { eventSlug })
    }));
  }, [searchValue, eventSlug, resetPagination]);

  const [sessionRegistrations, setSessionRegistrations] = useState<Record<string, SessionRegistrationRow[]>>({});

  useEffect(() => {
    if (eventData?.sessions) {
      setSessionRegistrations(
        eventData.sessions.reduce(
          (acc, session) => {
            acc[session.id] = session.registrations.map(
              ({ id, attended, registration: { id: registrationId, acceptedTerms, registeredAt, event, person } }) => ({
                id,
                attended,
                registration: {
                  id: registrationId,
                  acceptedTerms,
                  registeredAt,
                  event,
                  person
                }
              })
            );
            return acc;
          },
          {} as Record<string, SessionRegistrationRow[]>
        )
      );
    }
  }, [eventData]);

  const sessions = useMemo(() => {
    return eventData?.sessions.map((session) => ({
      id: session.id,
      startsAt: session.startsAt,
      endsAt: session.endsAt,
      registrations: session.registrations
    }));
  }, [eventData]);

  const sessionDates = useMemo(() => {
    return sessions?.map((session) => ({
      id: session.id,
      startsAt: session.startsAt
    }));
  }, [sessions]);

  const [activeSession, setActiveSession] = useState<number>(0);
  const currentSessionDate = sessionDates?.[activeSession]?.startsAt;
  const formattedSessionDate = currentSessionDate
    ? new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric" }).format(
        new Date(currentSessionDate)
      )
    : "";
  const hasRegistrations = sessionRegistrations && Object.keys(sessionRegistrations).length > 0;

  const handleExportCurrentList = () => {
    const headers = ["PGA ID", "Registrant Name"];
    const rows =
      sessions?.[activeSession]?.registrations.map(
        ({
          registration: {
            person: { pgaId, displayName }
          }
        }) => [pgaId, displayName]
      ) || [];
    const csvContent = [headers, ...rows].map((row) => row.join(",")).join("\n");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([csvContent], { type: "text/csv;charset=utf-8;" }));
    link.download = "registrants-list.csv";
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleBulkAttendanceUpdate = async (present: boolean) => {
    const selectedIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    if (selectedIds.length > 0) {
      const previousRegistrations = { ...sessionRegistrations };

      const currentSession = sessions?.[activeSession];
      if (currentSession) {
        setSessionRegistrations((prev) => ({
          ...prev,
          [currentSession.id]: prev[currentSession.id].map((reg) =>
            selectedIds.includes(reg.id) ? { ...reg, attended: present } : reg
          )
        }));
      }

      try {
        await updateEventSessionsRegistrationAttendances({
          variables: {
            attendances: selectedIds.map((id) => ({
              eventSessionRegistrationId: id,
              attended: present
            }))
          },
          refetchQueries: [
            {
              query: EVENT_QUERY,
              variables: { slug: eventSlug }
            }
          ]
        });
        showSnackbar("Bulk attendance updated successfully.");
      } catch (error) {
        setSessionRegistrations(previousRegistrations);
        showSnackbar("Failed to update bulk attendance.");
      }
    }
  };

  const [updateEventSessionsRegistrationAttendances] =
    useMutation<BackofficeEventsUpdateEventSessionsRegistrationAttendanceInput>(
      UPDATE_EVENT_SESSIONS_REGISTRATION_ATTENDANCES_MUTATION
    );

  const handleAttendanceUpdate = async (id: string, value: string | null) => {
    const currentSession = sessions?.[activeSession];
    if (!currentSession) return;

    const previousRegistrations = { ...sessionRegistrations };

    const currentRegistration = sessionRegistrations[currentSession.id]?.find((reg) => reg.id === id);
    if (!currentRegistration) return;

    const attended = value === "true" ? true : value === "false" ? false : null;

    setSessionRegistrations((prev) => ({
      ...prev,
      [currentSession.id]: prev[currentSession.id].map((reg) => (reg.id === id ? { ...reg, attended } : reg))
    }));

    try {
      await updateEventSessionsRegistrationAttendances({
        variables: {
          attendances: [
            {
              eventSessionRegistrationId: id,
              attended
            }
          ]
        },
        refetchQueries: [
          {
            query: EVENT_QUERY,
            variables: { slug: eventSlug }
          }
        ]
      });
      showSnackbar("Attendance updated successfully.");
    } catch (error) {
      setSessionRegistrations(previousRegistrations);
      showSnackbar("Failed to update attendance.");
    }
  };

  const columns = getColumns({
    hasRegistrations,
    onAttendanceUpdate: handleAttendanceUpdate,
    updateEventSessionsRegistrationAttendances,
    setIsSelectedOnly
  });

  const activeSessionRegistrations = useMemo(() => {
    const currentSession = sessions?.[activeSession];
    return currentSession ? sessionRegistrations[currentSession.id] ?? [] : [];
  }, [activeSession, sessionRegistrations, sessions]);

  const filteredData =
    activeSessionRegistrations?.filter((registrant) => {
      const searchValue = variables.search?.toLowerCase() || "";
      return (
        registrant.registration.person.displayName.toLowerCase().includes(searchValue) ||
        registrant.registration.person.pgaId.toLowerCase().includes(searchValue)
      );
    }) || [];

  const displayedData = isSelectedOnly
    ? filteredData.filter((registrant) => rowSelection[registrant.id])
    : filteredData.length > 0
    ? filteredData
    : activeSessionRegistrations;

  return (
    <div className="flex flex-col md:flex-row">
      {multiSession && (
        <div className="md:pt-8">
          <SessionStepper
            sessionDates={sessionDates || []}
            activeSession={activeSession}
            onSessionChange={setActiveSession}
          />
        </div>
      )}
      <div className="flex-1">
        <Menu />
        <Table
          data={displayedData}
          loading={eventLoading}
          enableRowSelection={true}
          rowSelection={rowSelection}
          onRowSelectionChange={setRowSelection}
          renderHeader={() => (
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-row w-full items-center gap-2">
                <div className="w-full md:w-1/4">
                  <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                      <SearchInput
                        placeholder="Search Registrants"
                        query={field.value}
                        param="Name, PGA ID"
                        onChange={(value) => {
                          field.onChange(value);
                          setVariables((prev) => ({
                            ...prev,
                            search: value
                          }));
                        }}
                      />
                    )}
                  />
                </div>
                {Object.keys(rowSelection).length > 0 && (
                  <div className="flex gap-3 pl-4">
                    <Button icon="person_check" variant="outlined" onClick={() => handleBulkAttendanceUpdate(true)}>
                      Mark as Present
                    </Button>
                    <Button icon="person_cancel" variant="outlined" onClick={() => handleBulkAttendanceUpdate(false)}>
                      Mark as Absent
                    </Button>
                  </div>
                )}
              </div>
              {Object.keys(rowSelection).length > 0 && (
                <div className="flex items-center gap-4 w-full justify-start">
                  <p className="text-sm">
                    {Object.keys(rowSelection).length} {Object.keys(rowSelection).length === 1 ? "row" : "rows"}{" "}
                    selected
                  </p>
                  <Chip
                    onClick={() => {
                      setIsSelectedOnly(!isSelectedOnly);
                    }}
                    label="Show Selected Only"
                    selected={isSelectedOnly}
                  />
                  <Button
                    variant="text"
                    onClick={() => {
                      const allSelected = activeSessionRegistrations.reduce(
                        (acc, registrant) => {
                          acc[registrant.id] = true;
                          return acc;
                        },
                        {} as Record<string, boolean>
                      );
                      setRowSelection(allSelected);
                      resetPagination();
                    }}
                  >
                    Select All {activeSessionRegistrations.length} {pluralize(activeSessionRegistrations.length, "Row")}
                  </Button>
                  {filterActive && (
                    <div className="ml-1 flex items-center" data-testid="clear-all-button">
                      <Button
                        variant="text"
                        onClick={() => {
                          resetPagination();
                          setVariables({ eventSlug });
                          reset({ search: "" });
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div className="flex justify-between items-center mb-4 md:hidden">
                {renderFooter(
                  {
                    edges: displayedData.map((item) => ({ node: item })),
                    totalCount: displayedData.length,
                    pageInfo: {
                      hasNextPage: false,
                      hasPreviousPage: false
                    }
                  },
                  { variant: "compact" }
                )()}
              </div>
            </div>
          )}
          columns={columns}
          renderDetails={() =>
            hasRegistrations ? (
              <TableDetails
                heading={`Session Attendance for ${formattedSessionDate}`}
                body="Track each registrant's attendance for this session"
                ButtonComps={[
                  () => (
                    <Button variant="text" icon="download" onClick={() => handleExportCurrentList()}>
                      Export Current List
                    </Button>
                  )
                ]}
              />
            ) : (
              <TableDetails heading="PAT Attendance" body="Registrants will be listed here when available." />
            )
          }
          renderEmptyState={() => (
            <EmptyState title="No Registrants" caption="This event does not have any registrants." iconName="person" />
          )}
          renderFooter={renderFooter({
            edges: displayedData.map((item) => ({ node: item })),
            pageInfo: {
              hasNextPage: false,
              hasPreviousPage: false
            },
            totalCount: displayedData.length
          })}
          sortingState={sorting}
          onSortingChange={setSorting}
        />
      </div>
    </div>
  );
}
