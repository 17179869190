import { sysColors } from "@/assets/theme/colors";
import LitSwitch from "./LitSwitch";

const style = {
  "--md-switch-handle-color": sysColors["sys-brand-outline"],
  "--md-switch-track-color": sysColors["sys-brand-surface-container-highest"]
} as React.CSSProperties;

export type Props = {
  disabled?: boolean;
  selected?: boolean;
  required?: boolean;
  onChange?(e: Event): void;
};

const Switch = (props: Props) => <LitSwitch style={style} {...props} />;

export default Switch;
