import { useMutation, useQuery } from "@apollo/client";
import { JOB_CLASSIFICATIONS_QUERY, UPDATE_CHANGE_REQUEST_MUTATION, Node } from "../queries";
import Button from "@/components/Button";
import Progress from "@/components/Progress";
import useModal from "@/hooks/useModal";
import { useState } from "react";
import Select from "@/components/Select";

export default function EditModal({ changeRequest, onClosed }: { changeRequest: Node; onClosed(): void }) {
  const { Modal, save } = useModal();
  const { data, loading } = useQuery(JOB_CLASSIFICATIONS_QUERY, { variables: { personId: changeRequest.person.id } });
  const [selectedClassification, selectClassification] = useState(changeRequest.change.classification || "");

  const [update, { loading: updating }] = useMutation(UPDATE_CHANGE_REQUEST_MUTATION);

  const { availableJobClassifications = [] } = data?.person || {};

  const options = availableJobClassifications.map((value) => ({ value }));

  const onSave = () =>
    save(
      () => update({ variables: { id: changeRequest.id, classification: selectedClassification } }),
      (data) => data.updateChangeRequest,
      "Requested value updated"
    );

  return (
    <Modal
      displayOverflow
      action={
        <Button disabled={!selectedClassification || updating} onClick={onSave} icon="check">
          Save
        </Button>
      }
      headline="Edit Requested Value"
      onClosed={onClosed}
    >
      <>
        {loading ? (
          <Progress variant="linear" />
        ) : (
          <Select
            label="Classification"
            className="w-full"
            onSelect={selectClassification}
            options={options}
            value={selectedClassification}
          />
        )}
      </>
    </Modal>
  );
}
