import react from "react";
import { createComponent } from "@lit/react";
import { MdNavigationDrawerModal } from "@material/web/labs/navigationdrawer/navigation-drawer-modal";

const events = {
  onChange: "navigation-drawer-changed"
};

export const LitNavigationDrawerModal = createComponent({
  tagName: "md-navigation-drawer-modal",
  elementClass: MdNavigationDrawerModal,
  react,
  events
});
