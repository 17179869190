import react from "react";
import { createComponent } from "@lit/react";
import { MdRadio } from "@material/web/radio/radio";

export const LitRadio = createComponent({
  tagName: "md-radio",
  elementClass: MdRadio,
  react,
  events: {
    onChange: "change"
  }
});
