import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";

const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
const audience = import.meta.env.VITE_AUTH0_AUDIENCE;

export default function UserProvider({ redirect_uri, children }: { redirect_uri: string; children: ReactNode }) {
  const authorizationParams = { redirect_uri, audience };

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.returnTo) {
      window.location.assign(appState.returnTo);
    }
  };

  return domain && clientId ? (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  ) : (
    <p>Please configure auth</p>
  );
}
