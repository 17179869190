import { useState } from "react";
import Button from "../Button";
import Icon from "../Icon";
import { variants, BannerProps } from "./shared";
import SecondaryAction from "./SecondaryAction";

export default function Banner({
  description,
  secondaryAction,
  primaryAction,
  variant = "info",
  closable,
  onClose,
  inline
}: BannerProps) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  if (!isVisible) {
    return null;
  }

  const handlePrimaryAction = () => {
    if (primaryAction?.link) {
      window.open(primaryAction.link, "_blank");
    } else if (onClose) {
      onClose();
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div className={variants[variant]} data-testid="banner">
      <div className={`px-4 ${!inline && "tablet:px-12"}`}>
        <div className="flex flex-col sm:flex-row items-center justify-between py-3">
          <div className="flex items-center text-center sm:text-left">
            <div className="flex items-center">
              <Icon name={variant === "info" ? "info" : "error"} size={24} data-testid="icon" />
            </div>
            <h3 className="text-label-large ml-4 text-left">{description}</h3>
          </div>
          <div className="flex items-center space-x-4 flex-shrink-0 mt-4 sm:mt-0">
            <SecondaryAction secondaryAction={secondaryAction} />

            {(primaryAction || closable || onClose) && (
              <Button theme={variant} variant="outlined" onClick={handlePrimaryAction}>
                {primaryAction?.label || "Dismiss"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
