import PersonCard from "./PersonCard";
import ExamDetailsCard from "./ExamDetailsCard";
import { EventState } from "../index";

interface EventInformationSectionProps {
  eventState: EventState;
  onStateUpdate: (update: Partial<EventState>) => void;
}

export const EventInformationSection = ({ eventState, onStateUpdate }: EventInformationSectionProps) => {
  const handleExamDetailsUpdate = async (details: { conditions: string; problems: string }) => {
    onStateUpdate({
      patEventAttributes: {
        ...eventState.patEventAttributes,
        courseConditions: details.conditions,
        problemsIssues: details.problems
      }
    });
  };

  return (
    <>
      <div className="w-full pb-4 md:w-auto flex flex-col md:flex-row md:items-center justify-between">
        <p className="text-title-medium pb-1">Event Information</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 items-stretch">
        <PersonCard
          title="Event Host"
          pgaId={eventState.patEventAttributes.hostProfessional.pgaId}
          name={eventState.patEventAttributes.hostProfessional.displayName}
          onSave={(newHost) => {
            onStateUpdate({
              patEventAttributes: {
                ...eventState.patEventAttributes,
                hostProfessional: {
                  id: newHost.id,
                  pgaId: newHost.pgaId,
                  displayName: newHost.displayName
                }
              }
            });
          }}
        />
        <PersonCard
          title="PAT Examiner"
          pgaId={eventState.patEventAttributes.examiner.pgaId}
          name={eventState.patEventAttributes.examiner.displayName}
          onSave={(newExaminer) => {
            onStateUpdate({
              patEventAttributes: {
                ...eventState.patEventAttributes,
                examiner: { id: newExaminer.id, pgaId: newExaminer.pgaId, displayName: newExaminer.displayName }
              }
            });
          }}
        />
        <ExamDetailsCard
          conditions={eventState.patEventAttributes.courseConditions}
          problems={eventState.patEventAttributes.problemsIssues}
          onSave={handleExamDetailsUpdate}
        />
      </div>
    </>
  );
};
