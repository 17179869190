import {
  ColumnDef,
  OnChangeFn,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  Table as TableComponent,
  TableOptions,
  Cell,
  RowSelection,
  RowSelectionState,
  VisibilityState
} from "@tanstack/react-table";
import classNames from "classnames";
import { Fragment, useEffect } from "react";
import Icon from "../Icon";
import { MaterialNames } from "../material_symbol_names";
import { isNull } from "lodash";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import { EditableCell } from "./EditableCell";

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends unknown, TValue> {
    editable?: boolean;
    onEdit?: (value: string, cell: Cell<TData, TValue>) => void;
    editableClassName?: string;
    editableIcon?: (cell: Cell<TData, TValue>) => React.ReactNode;
    editableInputType?: "currency" | "default";
  }

  interface TableMeta<TData extends unknown> {
    editableRowIndex?: number | null;
    setEditableRowIndex?: (index: number | null) => void;
  }
}

export type { Row };

type SharedProps<T> = {
  renderExpandedRow?: (props: { row: Row<T> }) => React.ReactElement;
  onClickRow?: (row: Row<T>) => void;
};

export interface Props<T> extends SharedProps<T> {
  className?: string;
  loading: boolean;
  data: T[];
  columns: ColumnDef<T, any>[];
  renderDetails?: () => React.ReactElement;
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  serverSideSorting?: boolean;
  sortingState?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  renderEmptyState: () => React.ReactElement;
  enableRowSelection?: TableOptions<T>["enableRowSelection"];
  rowSelection?: typeof RowSelection;
  onRowSelectionChange?: TableOptions<T>["onRowSelectionChange"];
  setSelectedData?: any;
  getRowId?: (row: T) => string;
  onHorizontalScroll?(): void;
  ["data-testid"]?: string;
  getRowClasses?: (row: Row<T>) => string;
  columnVisibility?: VisibilityState;
  editableRowIndex?: number | null;
  setEditableRowIndex?: (index: number | null) => void;
}

interface TableDetailsProps {
  heading?: string;
  body?: string;
  ButtonComps?: (() => JSX.Element)[];
}

interface TableBodyProps<T> extends SharedProps<T> {
  table: TableComponent<T>;
  onClickBodyRow: (row: Row<T>) => () => void;
  getRowClasses?: (row: Row<T>) => string;
}

function renderColumnValue<T>(cell: Cell<T, unknown>) {
  const { column, getValue } = cell;
  const cellValue = getValue();
  if (isNull(cellValue)) {
    return <div className="opacity-20">--</div>;
  }

  const meta = cell.getContext().table.options.meta;
  if (column.columnDef.meta?.editable) {
    return (
      <EditableCell
        value={String(cellValue)}
        onChange={(value) => column.columnDef.meta?.onEdit?.(value, cell)}
        className={column.columnDef.meta?.editableClassName}
        icon={column.columnDef.meta?.editableIcon?.(cell)}
        isEditingCell={meta?.editableRowIndex === cell.row.index}
        setEditableRowIndex={() => meta?.setEditableRowIndex?.(cell.row.index)}
        editableInputType={column.columnDef.meta?.editableInputType}
      />
    );
  }

  return flexRender(column.columnDef.cell, cell.getContext());
}

export function TableDetails({ heading, body, ButtonComps }: TableDetailsProps) {
  return (
    <div data-testid="table-details" className="w-full pl-0 sm:pl-4 pt-8 pb-6">
      <div className="w-full md:w-auto flex flex-col md:flex-row md:items-center justify-between">
        <div className="w-full md:w-auto flex flex-col items-start">
          {heading && <h2 className="text-title-medium pb-1">{heading}</h2>}
          {body && <p className="text-body-medium">{body}</p>}
        </div>
        {ButtonComps && (
          <div
            className={classNames(
              "w-full md:w-auto flex flex-wrap gap-3 md:flex-nowrap justify-start md:justify-end items-center md:py-0 md:pl-10",
              {
                "pt-6 sm:ml-20": heading || body
              }
            )}
          >
            {ButtonComps.map((ButtonComp, index) => (
              <ButtonComp key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function chunkBy<T, K>(arr: T[], getKey: (a: T) => K): T[][] {
  const pushHead = ([head = [], ...tail]: T[][], curr: T) => [[curr, ...head], ...tail];

  return arr
    .reduce<{ chunks: T[][]; key?: K }>(
      ({ chunks: acc, key: prevKey }, curr) => {
        const key = getKey(curr);
        return { chunks: key === prevKey ? pushHead(acc, curr) : [[curr], ...acc], key };
      },
      { chunks: [] }
    )
    .chunks.map((chunk) => chunk.reverse())
    .reverse();
}

function TableBody<T>({ table, onClickBodyRow, renderExpandedRow, onClickRow, getRowClasses }: TableBodyProps<T>) {
  const renderExpanded = (row: Row<T>) => !!renderExpandedRow && row.getIsExpanded();

  // put contiguous collapsed rows in a single `tbody`, but
  // put expanded rows in a separate `tbody` with their expanded content
  return chunkBy(table.getRowModel().rows, (row) => (renderExpanded(row) ? row.id : false)).map((chunk) => (
    <tbody role="rowgroup" key={chunk[0]?.id}>
      {chunk.map((row) => (
        <Fragment key={row.id}>
          <tr
            className={classNames("border-t hover:bg-sys-brand-surface-container group", {
              "cursor-pointer": !!onClickRow
            })}
            onClick={onClickBodyRow(row)}
            data-testid="body-row"
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={classNames(
                  "text-body-medium text-left px-4 py-2 h-12",
                  cell.column.columnDef.meta?.className,
                  getRowClasses?.(row)
                )}
              >
                {renderColumnValue(cell)}
              </td>
            ))}
          </tr>
          {renderExpanded(row) && (
            <tr data-testid="body-row">
              <td className="border-t" colSpan={row.getVisibleCells().length}>
                {renderExpandedRow?.({ row })}
              </td>
            </tr>
          )}
        </Fragment>
      ))}
    </tbody>
  ));
}

function SkeletonLoader<T>({ columns }: { columns: ColumnDef<T>[] }) {
  return (
    <>
      {Array.from({ length: 10 }).map((_, rowIndex) => (
        <tr key={rowIndex} data-testid="loading-row">
          {columns.map((_, cellIndex) => {
            const width = `${Math.random() * 50 + 50}%`;
            return (
              <td key={cellIndex} className="px-2 py-4" data-testid="loading-cell">
                <div
                  className="animate-pulse h-4 rounded-full bg-gradient-to-r from-gray-200 to-gray-50"
                  style={{ width }}
                />
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
}
export interface RowType {
  id: string;
}

export default function Table<T extends RowType>({
  className,
  data,
  loading,
  columns,
  sortingState,
  rowSelection,
  serverSideSorting,
  onSortingChange,
  renderExpandedRow,
  renderDetails,
  renderHeader,
  renderFooter,
  onClickRow,
  renderEmptyState,
  enableRowSelection,
  onRowSelectionChange,
  setSelectedData,
  onHorizontalScroll,
  columnVisibility,
  ["data-testid"]: testId,
  getRowClasses: getRowClasses$,
  editableRowIndex,
  setEditableRowIndex
}: Props<T>) {
  const isEmptyState = !data.length && !loading;
  const { width: windowWidth } = useWindowSize();

  const table = useReactTable<T>({
    data,
    columns: isEmptyState && isMobile(windowWidth) ? columns.slice(0, 3) : columns,
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    state: { sorting: sortingState, rowSelection: rowSelection as RowSelectionState, columnVisibility },
    onSortingChange,
    onRowSelectionChange,
    manualSorting: serverSideSorting,
    getSortedRowModel: serverSideSorting ? undefined : getSortedRowModel(),
    enableSortingRemoval: false,
    enableRowSelection,
    getRowId: (row: T) => row.id,
    meta: {
      editableRowIndex,
      setEditableRowIndex
    }
  });

  // if there isn't a footer the last row should have a bottom padding
  const getRowClasses = (row: Row<T>) =>
    classNames(getRowClasses$?.(row), { ["group-last:pb-5 group-last:h-[60px]"]: !renderFooter });

  const onClickBodyRow = (row: Row<T>) => () => {
    onClickRow?.(row);
  };

  useEffect(() => {
    if (setSelectedData) {
      setSelectedData(table.getSelectedRowModel().rows.map((row) => row.original));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  return (
    <>
      {renderDetails && renderDetails()}
      <div className="max-w-full shadow-web-1 rounded-2xl">
        <div className={classNames("w-full whitespace-nowrap", className)}>
          {renderHeader && (
            <div data-testid="header-row" className="text-left font-normal p-4">
              {renderHeader()}
            </div>
          )}
        </div>
        <div
          onScroll={onHorizontalScroll}
          className={classNames(
            "max-w-full overflow-x-auto",
            { "rounded-t-2xl": !renderHeader },
            { "rounded-b-2xl": !renderFooter }
          )}
        >
          <table data-testid={testId} className={classNames("w-full", className)}>
            <thead className="whitespace-nowrap">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="h-[50px] bg-sys-brand-surface-container" data-testid="head-row">
                  {headerGroup.headers.map((header, index) => {
                    const headerClasses = header.column.columnDef.meta?.headerClassName || "";
                    return (
                      <th
                        className={classNames("text-label-large text-left text-sys-brand-on-surface px-4", {
                          "cursor-pointer hover:bg-sys-brand-surface-container-high group": header.column.getCanSort(),
                          "sticky left-0 bg-sys-brand-surface-container": data.length && index === 0
                        })}
                        style={{ width: header.column.getSize() }}
                        onClick={
                          header.column.getCanSort()
                            ? () => header.column.toggleSorting(header.column.getIsSorted() === "asc")
                            : undefined
                        }
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        <div className={classNames("flex items-center gap-1", headerClasses)}>
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          {header.column.getCanSort() && (
                            <Icon
                              name={sortIcon(header.column.getIsSorted() as string)}
                              size={18}
                              className={!header.column.getIsSorted() ? "invisible group-hover:visible" : ""}
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            {data.length ? (
              <TableBody {...{ table, onClickBodyRow, renderExpandedRow, onClickRow, getRowClasses }} />
            ) : (
              <tbody>
                {isEmptyState ? (
                  <tr data-testid="empty-state-row">
                    <td colSpan={columns.length}>{renderEmptyState()}</td>
                  </tr>
                ) : (
                  <SkeletonLoader columns={columns} />
                )}
              </tbody>
            )}
          </table>
        </div>

        {!isEmptyState && renderFooter && (
          <div className={classNames("w-full whitespace-nowrap", className)}>
            <div data-testid="footer-row" className="border-t p-3">
              {renderFooter()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function getRowCanExpand() {
  return true;
}

function sortIcon(sort: string): MaterialNames {
  return sort === "desc" ? "arrow_upward" : "arrow_downward";
}
