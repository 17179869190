import Table, { type Row } from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import { useNavigate, Path } from "@/router";
import { ColumnDef } from "@tanstack/react-table";
import { Control, useController } from "react-hook-form";
import IconButton from "@/components/IconButton";
import SearchInput from "@/components/SearchInput";
import { RenderFooter } from "@/hooks/usePagination";
import { PropsWithChildren } from "react";
import { FacilitySearchResult, FacilitiesSearchQueryResponse } from "../queries";
import { generatePath } from "react-router-dom";
import { actionId, Menu } from "@/hooks/useActionMenu";
import { getStickyColumnClasses } from "@/lib/styleHelpers";

function buildColumns(
  facilities: FacilitySearchResult[],
  onClickRow: (row: Row<FacilitySearchResult>) => void,
  setMenu: (menu: Menu) => void
): ColumnDef<FacilitySearchResult, any>[] {
  return [
    {
      accessorKey: "name",
      header: "Name",
      id: "name",
      enableSorting: false,
      meta: {
        className: getStickyColumnClasses(!!facilities?.length)
      }
    },
    {
      accessorFn: (row) => `${row.mainAddress?.address1 || ""} ${row.mainAddress?.address2 || ""}`.trim(),
      header: "Primary Address",
      id: "mainAddress.address1",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.mainAddress?.city,
      header: "City",
      id: "mainAddress.city",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.mainAddress?.state,
      header: "State",
      id: "mainAddress.state",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.mainAddress?.postalCode,
      header: "Zip",
      id: "mainAddress.postalCode",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.phone?.phoneFormatted,
      header: "Phone",
      id: "phone",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.parentCompany?.name,
      header: "Parent Company",
      id: "parentCompany.name",
      enableSorting: false
    },
    {
      accessorFn: (row) => row.highestRankedEmployment?.person?.displayName,
      header: "Highest Ranking Member",
      id: "highestRankedEmployment.person.displayName",
      enableSorting: false
    },
    {
      cell({ row, row: { id: rowId } }) {
        const items = [
          {
            label: "View Facility",
            onClick: () => onClickRow(row)
          }
        ];

        function onClick() {
          setMenu({ rowId, items });
        }

        return (
          <div
            className="flex flex-row justify-end"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconButton
              id={actionId(rowId)}
              onClick={onClick}
              data-testid="actions"
              title="actions"
              name="more_vert"
              iconClassName="font-extrabold"
              className="!m-0 h-10"
            />
          </div>
        );
      },
      id: "actions",
      size: 100
    }
  ];
}

export const Header = ({
  children,
  control,
  addSearchParam,
  deleteSearchParams
}: PropsWithChildren<{
  control: Control;
  addSearchParam: (key: string, value: string) => void;
  deleteSearchParams: (key: string) => void;
}>) => {
  const { field } = useController({ control, name: "name" });

  return (
    <div>
      <div className="flex md:items-center flex-col md:flex-row justify-between gap-3">
        <div className="max-w-[320px]">
          <SearchInput
            placeholder="Search Facilities"
            query={field.value}
            ref={field.ref}
            param="Name"
            onChange={(e) => {
              field.onChange(e);
              addSearchParam("search", e);
            }}
            onClear={() => {
              deleteSearchParams("search");
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

function buildFacilityPath(id: string, searchParamsUrl: string) {
  return generatePath(`/facilities/:id/details/contact?return_to=/facilities&${searchParamsUrl}`, { id }) as Path;
}

export default function FacilitiesTable({
  loading,
  data,
  renderFooter,
  control,
  addSearchParam,
  deleteSearchParams,
  searchParamsUrl,
  setMenu
}: {
  loading: boolean;
  data?: FacilitiesSearchQueryResponse;
  renderFooter: RenderFooter<FacilitySearchResult>;
  control: Control;
  addSearchParam: (key: string, value: string) => void;
  deleteSearchParams: (key: string) => void;
  searchParamsUrl: string;
  setMenu: (menu: Menu) => void;
}) {
  const navigate = useNavigate();

  const facilities = data?.facilities.nodes || [];

  function onClickRow(row: Row<FacilitySearchResult>) {
    navigate(buildFacilityPath(row.original.id!, searchParamsUrl));
  }

  return (
    <Table
      data={facilities}
      loading={loading}
      onClickRow={onClickRow}
      renderHeader={() => (
        <Header control={control} addSearchParam={addSearchParam} deleteSearchParams={deleteSearchParams}>
          {renderFooter(data?.facilities, { variant: "compact" })()}
        </Header>
      )}
      columns={buildColumns(facilities, onClickRow, setMenu)}
      renderEmptyState={() => (
        <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="store" />
      )}
      renderFooter={renderFooter(data?.facilities)}
    />
  );
}
