import DateSelect from "@/components/DateSelect";
import TimePicker from "@/components/TimePicker";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";
import Icon from "@/components/Icon";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";
import MaxAttendanceField from "./MaxAttendanceField";
import Divider from "@/components/Divider";
import { useRef } from "react";
import { Date as EventDate } from "../types.ts";

type SessionFieldName = "date" | "startTime" | "endTime";

interface SessionFieldsProps {
  hasMultipleSessions: boolean;
  index: number;
  timezone: string;
  onRemove: () => void;
}

interface SessionFormValues {
  sessions: {
    date: EventDate;
    startTime: string;
    endTime: string;
    maxAttendance?: number | null;
  }[];
}

const isFieldEmpty = (field: SessionFieldName, value: string | EventDate | null) => {
  if (field === "date") {
    return !value || !(value as unknown as EventDate).startDate;
  }
  return !value;
};

const SessionFields = ({ hasMultipleSessions, index, onRemove, timezone }: SessionFieldsProps) => {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext<SessionFormValues>();
  const prevValues = useRef<{
    date: EventDate;
    startTime: string;
    endTime: string;
  }>({ date: { startDate: "", endDate: "" }, startTime: "", endTime: "" });
  const windowSize = useWindowSize();
  const isMobileView = isMobile(windowSize.width);

  const handleFocus = (field: SessionFieldName) => {
    const currentValue = watch(`sessions.${index}.${field}`);

    if (isFieldEmpty(field, currentValue)) {
      return;
    }

    if (field === "date") {
      const dateValue = currentValue as EventDate;
      if (dateValue.startDate) {
        prevValues.current.date = dateValue;
      }
    } else {
      prevValues.current[field] = currentValue as string;
    }
  };

  const handleBlur = (field: SessionFieldName) => {
    const currentValue = watch(`sessions.${index}.${field}`);
    if (isFieldEmpty(field, currentValue)) {
      setValue(`sessions.${index}.${field}`, prevValues.current[field]);
    }

    if (field === "startTime" || field === "endTime") {
      trigger();
    }
  };

  const handleChange = (field: SessionFieldName, value: any) => {
    setValue(`sessions.${index}.${field}`, value);
  };

  const renderMobileLayout = () => (
    <>
      {index > 0 && (
        <div className="py-3">
          <Divider />
        </div>
      )}
      <div className="flex flex-col gap-6 w-full">
        <div className="flex items-start justify-between">
          <div className="flex-grow">
            <Controller
              name={`sessions.${index}.date`}
              control={control}
              render={({ field }) => (
                <DateSelect
                  label="Date"
                  placeholder="Select Date"
                  displayFormat="MMM DD, YYYY"
                  value={field.value}
                  onChange={field.onChange}
                  useSingleDate
                  popoverDirection="down"
                  minDate={new Date()}
                />
              )}
            />
          </div>
          {hasMultipleSessions && (
            <div className="h-[56px] flex items-center cursor-pointer p-2 ml-2" onClick={onRemove}>
              <Icon name="delete" />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            name={`sessions.${index}.startTime`}
            control={control}
            render={({ field }) => (
              <TimePicker
                label={`Start Time (${timezone})`}
                value={field.value || ""}
                onChange={(newTime) => {
                  field.onChange(newTime);
                  handleBlur("startTime");
                }}
                onFocus={() => handleFocus("startTime")}
                onBlur={() => handleBlur("startTime")}
                error={!!errors.sessions?.[index]?.startTime}
                errorMessage={errors.sessions?.[index]?.startTime?.message}
              />
            )}
          />
          <Controller
            name={`sessions.${index}.endTime`}
            control={control}
            render={({ field }) => (
              <TimePicker
                label={`End Time (${timezone})`}
                value={field.value || ""}
                onChange={field.onChange}
                onFocus={() => handleFocus("endTime")}
                onBlur={() => handleBlur("endTime")}
                error={!!errors.sessions?.[index]?.endTime}
                errorMessage={errors.sessions?.[index]?.endTime?.message}
              />
            )}
          />
        </div>
        <MaxAttendanceField index={index} />
      </div>
    </>
  );

  const renderDesktopLayout = () => (
    <div className={classNames("relative flex lg:min-w-[680px]")}>
      <div className="grid gap-x-2 gap-y-6 w-full sm:grid-cols-2 lg:grid-cols-[repeat(3,minmax(160px,1fr))_minmax(180px,1.5fr)]">
        <Controller
          name={`sessions.${index}.date`}
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Date"
              placeholder="Select Date"
              displayFormat="MMM DD, YYYY"
              value={field.value}
              onChange={(value) => handleChange("date", value)}
              onFocus={() => handleFocus("date")}
              onBlur={() => handleBlur("date")}
              useSingleDate
              popoverDirection="down"
              minDate={new Date()}
            />
          )}
        />
        <Controller
          name={`sessions.${index}.startTime`}
          control={control}
          render={({ field }) => (
            <TimePicker
              label={`Start Time (${timezone})`}
              value={field.value || ""}
              onChange={(newTime) => {
                field.onChange(newTime);
                handleBlur("startTime");
              }}
              onFocus={() => handleFocus("startTime")}
              onBlur={() => handleBlur("startTime")}
              error={!!errors.sessions?.[index]?.startTime}
              errorMessage={errors.sessions?.[index]?.startTime?.message}
            />
          )}
        />
        <Controller
          name={`sessions.${index}.endTime`}
          control={control}
          render={({ field }) => (
            <TimePicker
              label={`End Time (${timezone})`}
              value={field.value || ""}
              onChange={field.onChange}
              onFocus={() => handleFocus("endTime")}
              onBlur={() => handleBlur("endTime")}
              error={!!errors.sessions?.[index]?.endTime}
              errorMessage={errors.sessions?.[index]?.endTime?.message}
            />
          )}
        />
        <MaxAttendanceField index={index} />
      </div>
      {hasMultipleSessions && (
        <div className="absolute -right-10 flex items-center md:h-[50px]">
          <div className="cursor-pointer w-6 h-6" onClick={onRemove}>
            <Icon name="delete" />
          </div>
        </div>
      )}
    </div>
  );

  return isMobileView ? renderMobileLayout() : renderDesktopLayout();
};

export default SessionFields;
