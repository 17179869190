import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { PaymentType } from "../../[orderId]/payment/[paymentId]/types";
import { useActions } from "../../[orderId]/_hooks";
import { useQuery } from "@apollo/client";
import { PAYMENT_QUERY } from "./queries";
import Progress from "@/components/Progress";
import EmptyState from "@/components/EmptyState";
import { useRequireCapability, DOMAIN, PERMISSION } from "@/hooks/useCapabilities";
import PaymentWrapper from "../../_components/PaymentWrapper";
import type { Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import usePageTitle from "@/hooks/usePageTitle";
import { useReturnTo } from "@/hooks/useSearchParams";

const Payment = () => {
  usePageTitle("/billing/orders/payment/:paymentId", "Billing | Orders | Payment Details");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewPayments });
  const { paymentId } = useParams();

  const { loading, error, data, refetch } = useQuery(PAYMENT_QUERY, { variables: { paymentId } });
  const { setBackLink, setActions } = useActions();
  const returnTo = useReturnTo();

  useEffect(() => {
    setActions([]);
    setBackLink({
      to: returnTo || "/billing/orders",
      title: returnTo ? "Back" : "Back to Orders"
    } as unknown as LinkProps<Path, Params>);
  }, [setBackLink, setActions, returnTo]);

  if (loading)
    return (
      <div className="p-10 flex items-center justify-center">
        <Progress />
      </div>
    );
  if (error) return <EmptyState title="Error" caption="An error occurred while loading this order." iconName="error" />;
  return (
    <PaymentWrapper payment={get(data, "commercePayment") as PaymentType} refetch={refetch} setActions={setActions} />
  );
};

export default Payment;
