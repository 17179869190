import { PageInfo } from "./components/Pagination";
import { AuditEntry } from "./pages/sections/types";
import { Interaction } from "./pages/people/[pgaId]/interactions/interactions/types";

export type AddressType = "main" | "mailing" | "home" | "secondary";

export interface Address {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  addressType: AddressType;
  isVerified: boolean;
}

export interface Section {
  id: string;
  name: string;
  legacyId?: string;
  auditLog?: AuditEntry[];
  auditLogPagination?: {
    nodes: AuditEntry[];
    pageInfo: PageInfo;
    totalCount: number;
  };
}

export interface Facility {
  id: string;
  name: string;
  section: Section;
  addresses: Address[];
  mainAddress: Address;
  phone?: { id: string; phoneFormatted: string };
}

export interface Employment {
  id: string;
  title: string;
  startedOn: string;
  endedOn: string | null;
  isPrimary: boolean;
  facility: Facility;
  jobClassification: string;
  sectionApprovalDate?: string;
}

export interface PhoneNumber {
  id: string;
  phoneType: "home" | "mobile" | "facility_phone" | "fax" | "golf_shop_phone";
  phoneNumber: string;
  phoneFormatted: string;
  phoneCountry: string;
}

export enum ProgramStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED"
}

export interface Band {
  id: string;
  expiresOn: string;
  startedOn: string;
  terminatesOn?: string;
  classification: Classification;
  sectionAffiliation?: SectionAffiliation;
  programType: ProgramType;
  createdReason?: string;
  section?: Section;
}

interface Program {
  id: string;
  status: ProgramStatus;
  programType: ProgramType;
  band?: Band;
}

interface StatusWithDates {
  status?: string;
  startDate?: string | null;
  endDate?: string | null;
  expirationDate?: string | null;
}

export interface BackgroundCheck extends StatusWithDates {}
export interface QualifyingLevel extends StatusWithDates {}
export interface LatestAssociateApplication extends StatusWithDates {}
export interface EligibleEmployment extends StatusWithDates {
  id: string;
}

export interface PlayingAbilityTest extends StatusWithDates {
  score: number;
  targetScore: number;
}

interface MembershipInterestForm {
  id: string;
  startedAt: string;
  reasonForBecomingMember: string;
  howTheyHeard: string;
}

export interface ProspectProgram {
  backgroundCheck: BackgroundCheck;
  playingAbilityTest: PlayingAbilityTest[];
  qualifyingLevel: QualifyingLevel;
  membershipInterestForm: MembershipInterestForm;
  latestAssociateApplication: LatestAssociateApplication;
  eligibleEmployment: EligibleEmployment;
}

export interface CommitteeAssignment {
  committeeName: string;
  position: string;
  startDate: string;
  endDate?: string;
}

export interface EducationCertification {
  startDate: string;
  endDate?: string | null;
  programName: string;
  programDescription: string;
  careerPath: string;
  curriculumVersion: string;
  subscription?: {
    startDate: string;
    endDate?: string | null;
    productId: string;
    expired: boolean;
  };
}

export interface EducationPgmProgram {
  curriculumVersion: string;
  startDate: string;
  endDate?: string | null;
  level: number;
  levelCode: string;
  careerPath?: string | null;
  programType: string;
  completed: boolean;
  pgmProgramCompleted?: boolean;
  pgmProgramExpirationDate?: string | null;
}

export interface Person {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  suffixName?: string;
  email?: string;
  profilePhoto?: string;
  pgaId?: string;
  membershipSince?: string;
  duesBalance?: number;
  mainProgramType?: ProgramType;
  employments: Employment[];
  section?: Section;
  primarySection?: Section;
  militaryService?: {
    id: string;
    status: string;
  };
  primarySectionAffiliation?: {
    id: string;
    startAt: string;
    section: Section;
  };
  sectionAffiliations?: SectionAffiliation[];
  gender?: string;
  ssn?: {
    last4?: string;
    full?: string;
  };
  dob?: {
    day?: number;
    month?: number;
    year?: number;
  };
  age?: number;
  informalName?: string;
  phoneNumbers?: PhoneNumber[];
  contactAddressId?: string;
  addresses?: Address[];
  salesforceExternalId?: string;
  interactions?: {
    pageInfo: PageInfo;
    nodes: Interaction[];
    totalCount: number;
  };
  latestProgram?: Program;
  bands?: Band[];
  prospectProgram?: ProspectProgram;
  accountCreatedAt?: string;
  classification?: Classification;
  committeeHistory?: CommitteeAssignment[];
  educationLatestCertification?: EducationCertification;
  educationCertificationHistory?: EducationCertification[];
  educationLatestPgmProgram?: EducationPgmProgram;
  educationPgmProgramHistory?: EducationPgmProgram[];
}

export interface Insights {
  person: Person;
}

export enum ProgramTypeName {
  Member = "Member",
  Associate = "Associate",
  Affiliate = "Affiliate",
  University = "University",
  Prospect = "Prospect"
}

interface ProgramType {
  id: string;
  name: ProgramTypeName;
}

export interface Classification {
  id: string;
  name: string;
  description: string;
  programType: ProgramType;
}

export interface SectionAffiliation {
  id: string;
  primary: boolean;
  startAt: string;
  endAt: string;
  section: Section;
}

export interface MembershipProgramClassification {
  id: string;
  startsOn: string;
  endsOn: string;
  classification: Classification;
  sectionAffiliation: SectionAffiliation;
}

export interface DuesRates {
  classification: string;
  classificationDescription: string;
  id: string;
  lookupKey: string;
  memberGrade: string;
  name: string;
  previousPricing: PreviousPricing[];
  price: string;
  priceCents: number;
  productName: string;
  updatedAt: string;
}

export interface PreviousPricing {
  price: string;
  priceCents: number;
  year: string;
}

export interface PDRHistory {
  id: string;
  activityCode: string;
  category: string;
  creditType: string;
  creditsAccepted: number;
  creditsAppliedFor: number;
  description: string;
  startedOn: string;
  submittedOn: string;
}
