import { useMutation } from "@apollo/client";
import { showSnackbar } from "@/lib/snackbarUtils";
import { PROMOTE_FROM_WAITLIST_MUTATION } from "../queries";
import Button from "@/components/Button";
import useModal from "@/hooks/useModal";
interface OverrideModalProps {
  onClosed: () => void;
  onError: (errorMessage: string) => void;
  eventId: string;
  personId: string;
}

export default function OverrideModal({ onClosed, onError, eventId, personId }: OverrideModalProps) {
  const { close, Modal } = useModal();

  const [promoteFromWaitlist, { loading: mutationLoading }] = useMutation(PROMOTE_FROM_WAITLIST_MUTATION);
  async function handlePromote() {
    try {
      const { data } = await promoteFromWaitlist({ variables: { eventId, personId } });
      const { success, message } = data.promoteFromWaitlist;
      const errorMessage = `The registration override could not be sent. Error: ${message}`;
      if (success) {
        showSnackbar("Registrant sent a registration override link", { close: true });
      } else {
        onError(errorMessage);
      }
    } catch (e) {
      onError((e as Error).message);
    } finally {
      await close();
    }
  }

  const action = (
    <Button disabled={mutationLoading} onClick={handlePromote}>
      Send Registration Override
    </Button>
  );

  return (
    <Modal action={action} onClosed={onClosed} headline="Send Registration Override" data-testid="override-modal">
      <div className="flex items-center text-sys-brand-on-surface-variant pb-6">
        <p className="text-body-medium font-semibold">
          Are you sure you want to email this person a registration link that allows them to bypass this event’s
          attendance limits? Once they register, they will be moved from the Waitlist onto the Registrations list. This
          cannot be undone.
        </p>
      </div>
    </Modal>
  );
}
