import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SUSPEND_PROGRAMS_MUTATION, ORDERS_QUERY } from "../queries";
import { showSnackbar } from "@/lib/snackbarUtils";
import { DateTime } from "luxon";
import { ChangeDueDateModalProps } from "./ChangeDueDateModal";

interface SuspendModalProps extends ChangeDueDateModalProps {
  suspendAll: boolean;
  totalPeople: number | null;
}

export const SuspendModal = ({
  onDismiss,
  orders,
  refetch,
  setRowSelection,
  suspendAll,
  totalPeople,
  setStats,
  resetPagination
}: SuspendModalProps) => {
  const { control, watch } = useForm();
  const headline = `Suspend People`;
  const orderIds = Object.keys(orders);
  const { data } = useQuery(ORDERS_QUERY, { variables: { ids: orderIds } });

  const [suspendProgramsProgramsMutation, { loading }] = useMutation(SUSPEND_PROGRAMS_MUTATION);
  const [errorContent, setErrorContent] = useState("");

  const currentDate = DateTime.now().toISO();

  const suspensionDateRange = watch("suspensionDate", {
    startDate: currentDate,
    endDate: currentDate
  });

  const personIds = data?.currentDuesCycle.orders.nodes.map((order: any) => order.person.id) || null;
  const variables = {
    ...(!suspendAll && { personIds }),
    ...(suspendAll && { suspendAll }),
    suspensionDate: suspensionDateRange.startDate
  };

  async function suspendPrograms() {
    const { data } = await suspendProgramsProgramsMutation({
      variables: { input: variables }
    });

    if (data.duesBulkSuspendPrograms.success) {
      const successfulPersonIds = data.duesBulkSuspendPrograms.successfulPersonIds.length;
      showSnackbar(`${successfulPersonIds} people have been suspended`);

      setStats({
        totalCount: null,
        failedPayments: null
      });
      resetPagination();
      refetch();
      setRowSelection([]);
      if (data.duesBulkSuspendPrograms.failedPersonIds.length > 0) {
        setErrorContent(data.duesBulkSuspendPrograms.message);
        resetPagination();
        refetch();
        setRowSelection([]);
      } else {
        onDismiss();
      }
    } else {
      setErrorContent(data.duesBulkSuspendPrograms.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={suspendPrograms} disabled={loading || !suspensionDateRange.startDate}>
        Suspend {suspendAll ? totalPeople : personIds?.length} People
      </Button>
    </>
  );

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline={headline}
      className="w-[423px] mt-[160px]"
      displayOverflow
    >
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      <p className="mb-3 text-label-large">
        Total People to be Suspended: {suspendAll ? totalPeople : personIds?.length}
      </p>
      <p className="my-3">
        Are you sure you wish to suspend {suspendAll ? totalPeople : personIds?.length} people from their PGA program?
        They will still be able to pay their Dues until they are formally terminated.
      </p>
      <p>Suspended people will be sent an email notification.</p>
      <div className="mt-6">
        <input className="mt-4 ml-1 absolute"></input>

        <Controller
          name="suspensionDate"
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Suspension Effective Date *"
              value={suspensionDateRange}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              showFooter={false}
              popoverDirection="up"
              supportingText="MM/DD/YYYY"
            />
          )}
        />
      </div>
    </Dialog>
  );
};
