import { useEffect, useContext, useCallback, useMemo, startTransition } from "react";
import { AuthorizationContext, PERMISSION, DOMAIN } from "@/providers/AuthorizationProvider";
import { useNavigate } from "@/router";
export { DOMAIN, PERMISSION } from "@/providers/AuthorizationProvider";

export interface Capability {
  domains: DOMAIN[];
  permissions: PERMISSION[];
}

const useCapabilities = () => useContext(AuthorizationContext);

/**
 * Hook to deny authorization and redirect to home page.
 * Extracting so we can react to unauthorized routes consistently.
 */
export function useDenyAuthorization() {
  const navigate = useNavigate();

  return useCallback(() => {
    // Not sure how, but this relieves a 'Maximum update depth exceeded' warning.
    startTransition(() => {
      // redirect user to home. At some point we will need a modal explaining the issue.
      navigate("/");
    });
  }, [navigate]);
}

/**
 * Utility function to check if a capability is present.
 */
const hasCapability = (capabilities: Capability, domain: DOMAIN, permission: PERMISSION): boolean => {
  const { domains, permissions } = capabilities;
  return domains.includes(domain) && permissions.includes(permission);
};

export const useRequireCapability = ({ domain, permission }: { domain: DOMAIN; permission: PERMISSION }) => {
  const { loaded, capabilities } = useCapabilities();
  const denyAuthorization = useDenyAuthorization();

  useEffect(() => {
    const { domains, permissions } = capabilities;
    if (loaded && (domains.length === 0 || !domains.includes(domain) || !permissions.includes(permission))) {
      denyAuthorization();
    }
  }, [loaded, capabilities, domain, permission, denyAuthorization]);
};

/**
 * Hook for checking multiple permissions
 *
 * Example usage:
 * const canView = useRequireCapabilityBool({ domain: DOMAIN.PEOPLE, permission: PERMISSION.viewPeople });
 */
export const useHasCapabilityBool = ({ domain, permission }: { domain: DOMAIN; permission: PERMISSION }): boolean => {
  const { loaded, capabilities } = useCapabilities();

  return useMemo(() => {
    return loaded && hasCapability(capabilities, domain, permission);
  }, [loaded, capabilities, domain, permission]);
};

/**
 * Predefined permission check hooks.
 */
export const useCanViewInteractionsBool = (): boolean =>
  useHasCapabilityBool({ domain: DOMAIN.PEOPLE, permission: PERMISSION.viewPeopleInteractions });

export const useCanManageInteractionsBool = (): boolean =>
  useHasCapabilityBool({ domain: DOMAIN.PEOPLE, permission: PERMISSION.managePeopleInteractions });

export default useCapabilities;
