import { Link, Path } from "@/router";
import classNames from "classnames";
import type { NavigationItemProps } from "./types";

export default function OpenNavigationItem<P extends Path>({
  active = false,
  linkClass,
  link,
  label,
  icon
}: NavigationItemProps<P>) {
  return (
    <Link
      {...link}
      className={classNames(
        "hover:!bg-gray-200 rounded-full w-full",
        { "bg-sys-brand-secondary-container": active },
        linkClass
      )}
    >
      <div className="flex py-4 pl-4 pr-6 space-x-3 items-center">
        <div className="leading-3">{icon}</div>
        <div className="text-label-large">{label}</div>
      </div>
    </Link>
  );
}
