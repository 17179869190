import { Navigate, useLocation } from "react-router-dom";
import usePageTitle from "@/hooks/usePageTitle";
import { DOMAIN, PERMISSION, useHasCapabilityBool } from "@/hooks/useCapabilities";
import { useFlags } from "@/hooks/useFeatureFlags";

export default function SectionIndexPage() {
  usePageTitle("/sections/:sectionId", "Sections | Section Details");
  const flags = useFlags();
  const flagJobPostList = !!flags["backoffice-job-post-section-list"];

  const hasJobPermissions = useHasCapabilityBool({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewJobPosts });
  const destination = hasJobPermissions && flagJobPostList ? "jobs" : "change-requests";

  const location = useLocation();
  return <Navigate to={`${location.pathname}/${destination}`} replace />;
}
