import { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { EVENT_REGISTRANTS_QUERY, GENERATE_REGISTRATION_EXPORT_MUTATION } from "../../queries";
import { EventRegistrantNode, EventRegistrants, Variables, RegistrationStatus } from "../../types";
import { renderStatus, onActionMenuItemClick } from "@/pages/events/_utils";
import { toLocaleDate } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { Controller } from "react-hook-form";
import { useSearch } from "@/hooks/useSearch";
import { useFlags } from "@/hooks/useFeatureFlags";
import { type CellContext } from "@tanstack/react-table";
import Table, { TableDetails, type Row } from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import Button from "@/components/Button";
import AvatarDetails from "@/components/AvatarDetails";
import SearchInput from "@/components/SearchInput";
import { useNavigate } from "@/router";
import useActionMenu, { actionId, MenuItem } from "@/hooks/useActionMenu";
import IconButton from "@/components/IconButton";
import usePagination from "@/hooks/usePagination";
import CancelModal from "./_components/CancelModal";
import { OrderStatus, RegistrantRow } from "../../types";
import { EventOutletContext } from "../_layout";

export default function RegistrationsPage() {
  const { eventSlug = "" } = useParams();
  const { eventData, setErrorMessage } = useOutletContext<EventOutletContext>();
  const flags = useFlags();

  const { paginationVariables, renderFooter, resetPagination } = usePagination();
  const [selectedRegistration, setSelectedRegistration] = useState<RegistrantRow | null>(null);
  const searchField = "search";
  const { searchValue, control, reset } = useSearch(searchField);
  const [variables, setVariables] = useState<Variables>({ eventSlug });
  const filterActive = variables.search;

  useEffect(() => {
    resetPagination();
    setVariables((prevVariables) => ({
      ...(searchValue ? { ...prevVariables, search: searchValue } : { eventSlug })
    }));
  }, [searchValue, eventSlug, resetPagination]);

  const {
    data,
    loading: queryLoading,
    refetch
  } = useQuery<EventRegistrants>(EVENT_REGISTRANTS_QUERY, {
    variables: { status: RegistrationStatus.REGISTERED, ...variables, ...paginationVariables }
  });
  const [generateRegistrationExport, { loading: mutationLoading }] = useMutation(GENERATE_REGISTRATION_EXPORT_MUTATION);

  const handleCsvExport = async (eventSlug: string) => {
    try {
      const { data } = await generateRegistrationExport({
        variables: {
          input: {
            eventSlug
          }
        }
      });
      const downloadUrl = data.generateRegistrationExport.url;
      if (!downloadUrl) {
        throw new Error("Download URL not found.");
      }
      window.location.assign(downloadUrl);
    } catch (error) {
      alert("Unable to export registrations");
      console.error(error);
    }
  };

  const registrationsData: RegistrantRow[] = (data?.eventRegistrants.edges || []).map(
    ({ node: { registeredAt, order, ...registrant } }: EventRegistrantNode) => ({
      ...registrant,
      registeredAt: toLocaleDate(registeredAt),
      orderStatus: order?.status as OrderStatus,
      additionalGuests: registrant.customFields?.additional_guests || 0
    })
  );
  const hasRegistrations = registrationsData && registrationsData.length > 0;
  const hasAdditionalGuests = !!eventData?.customFields?.includes("additional_guests");

  const { Menu, setMenu } = useActionMenu();
  const navigate = useNavigate();
  const navigateToRegistration = (row: Row<RegistrantRow>) =>
    navigate("/events/:eventSlug/registrations/:registrationId", {
      params: { eventSlug, registrationId: row.original.id }
    });

  const columns = [
    {
      cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
        const { profilePhoto, registrantName, programType } = cellProps.row.original;
        return <AvatarDetails picture={profilePhoto} title={registrantName} text={programType} />;
      },
      id: "name",
      header: "Name",
      size: 500,
      meta: {
        className: getStickyColumnClasses(!!registrationsData?.length)
      }
    },
    { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
    ...(hasAdditionalGuests ? [{ accessorKey: "additionalGuests", header: "Guests", enableSorting: false }] : []),
    { accessorKey: "registeredAt", header: "Reg. Date", enableSorting: false },
    { accessorKey: "sessionCount", header: "Sessions", enableSorting: false },
    ...(flags["backoffice-events-order-status"] && registrationsData.some((row) => row.orderStatus)
      ? [
          {
            cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
              const { orderStatus } = cellProps.row.original;
              return renderStatus(orderStatus);
            },
            id: "orderStatus",
            header: "Payment Status"
          }
        ]
      : []),
    {
      cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
        const items: MenuItem[] = [
          {
            label: "View Registration",
            itemClassName: "w-[240px]",
            onClick: () => navigateToRegistration(cellProps.row)
          }
        ];

        if (flags["backoffice-events-cancel-registration"]) {
          items.push({
            label: "Cancel Registration",
            itemClassName: "w-[240px]",
            onClick: () => {
              setSelectedRegistration(cellProps.row.original);
            }
          });
        }

        return (
          items.length > 0 && (
            <div
              className="text-right flex flex-row justify-end"
              data-testid="menu-icon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <IconButton
                id={actionId(cellProps.row.id)}
                onClick={() => onActionMenuItemClick({ setMenu, rowId: cellProps.row.id, items })}
                data-testid="actions"
                title="actions"
                name="more_vert"
                iconClassName="font-extrabold"
                skipMargin
              />
            </div>
          )
        );
      },
      id: "actions",
      size: 100
    }
  ];

  return (
    <>
      {!!selectedRegistration && (
        <CancelModal
          event={eventData || {}}
          eventRegistrationId={selectedRegistration.id}
          onClosed={() => {
            setSelectedRegistration(null);
          }}
          onError={(message: string) => {
            setErrorMessage(message);
          }}
          order={{ status: selectedRegistration.orderStatus }}
          refetch={refetch}
        />
      )}
      <Menu />
      {eventData?.cancelledAt && eventData.status === "Canceled" && (
        <div className="pt-4 pb-8">
          <p className="text-sm">Event Cancellation Date</p>
          <p className="text-[28px] font-normal leading-9">
            {toLocaleDate(eventData.cancelledAt, { year: "numeric", month: "short", day: "numeric" })}
          </p>
        </div>
      )}
      <Table
        data={registrationsData || []}
        loading={queryLoading}
        onClickRow={navigateToRegistration}
        renderHeader={() => (
          <div className="flex flex-row justify-between items-center pr-4 md:pr-0">
            <div className="flex flex-row justify-between gap-4 md:w-auto">
              <div className="w-full sm:max-w-[260px]">
                <Controller
                  name="search"
                  control={control}
                  render={({ field }) => (
                    <SearchInput
                      placeholder="Search Registrants"
                      query={field.value}
                      param="Name, PGA ID"
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              {filterActive && (
                <div className="ml-1 flex items-center" data-testid="clear-all-button">
                  <Button
                    variant="text"
                    onClick={() => {
                      resetPagination();
                      setVariables({ eventSlug });
                      reset({ search: "" });
                    }}
                  >
                    Clear All
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        columns={columns}
        renderDetails={() =>
          hasRegistrations ? (
            <TableDetails
              ButtonComps={[
                () => (
                  <Button
                    variant="text"
                    icon="download"
                    disabled={mutationLoading}
                    onClick={() => {
                      handleCsvExport(eventSlug);
                    }}
                  >
                    Export Current List
                  </Button>
                )
              ]}
            />
          ) : (
            <></>
          )
        }
        renderEmptyState={() => (
          <EmptyState title="No Registrations" caption="This event does not have any registrations" iconName="person" />
        )}
        renderFooter={renderFooter(data?.eventRegistrants, { pageCount: registrationsData.length })}
      />
    </>
  );
}
