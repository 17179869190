import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import AmplitudeProvider from "@/providers/AmplitudeProvider/AmplitudeProvider";

const onRedirecting = () => <div>Redirecting you to the login page...</div>;

// Updated AuthenticatedApp component
const App = withAuthenticationRequired(
  () => (
    <AmplitudeProvider>
      <Outlet />
    </AmplitudeProvider>
  ),
  {
    onRedirecting
  }
);

export default App;
