import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { gql, TypedDocumentNode } from "@apollo/client";
import { AuditLogVariables } from "./types";
import { AuditEntry } from "../../types";
import { PageInfo } from "@/components/Pagination";

interface Variables extends AuditLogVariables {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

type Result = {
  section: {
    id: string;
    name: string;
    legacyId: string;
    auditLogPagination: {
      totalCount: number;
      pageInfo: PageInfo;
      nodes: AuditEntry[];
    };
  };
};

export const AUDIT_LOG_PAGINATION_QUERY: TypedDocumentNode<Result, Variables> = gql`
  query sectionAuditLog(
    $sectionId: ID!
    $startDateTime: ISO8601DateTime!
    $endDateTime: ISO8601DateTime!
    $searchQuery: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    section(id: $sectionId) {
      id
      name
      legacyId
      auditLogPagination(
        startDateTime: $startDateTime
        endDateTime: $endDateTime
        searchQuery: $searchQuery
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        nodes {
          id
          changedAt
          description
          pgaId
          profilePhotoUrl
          itemName
          changeset {
            id
            typeOfData
            oldValue
            newValue
          }
        }
        ...PageInfo
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;
