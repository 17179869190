import React from "react";
import classNames from "classnames";
import CheckmarkIcon from "@/assets/images/checkmark-icon.svg";

export interface SegmentedButtonOption {
  label: string;
  value: string;
}

export interface SegmentedButtonProps {
  options: SegmentedButtonOption[];
  value?: string | null;
  onChange: (value: string | null) => void;
  vertical?: boolean;
  backgroundColor?: string;
  selectedColor?: string;
  textColor?: string;
  showCheckmark?: boolean;
  maxButtonWidth?: number;
  allowDeselect?: boolean;
  disabled?: boolean;
}

function SegmentedButton({
  options,
  value,
  onChange,
  vertical = false,
  backgroundColor = vertical ? "bg-sys-brand-surface-container-high" : "bg-sys-brand-surface",
  selectedColor = vertical ? "bg-sys-brand-primary-container" : "bg-sys-brand-secondary-container",
  textColor = vertical ? "text-sys-brand-on-primary-container" : "text-sys-brand-on-secondary-container",
  showCheckmark = false,
  maxButtonWidth = 200,
  allowDeselect = false,
  disabled = false
}: SegmentedButtonProps) {
  return (
    <div
      className={classNames("inline-flex border border-sys-brand-outline overflow-hidden", {
        "flex-col rounded-lg h-full": vertical,
        "rounded-full": !vertical,
        "opacity-50": disabled
      })}
    >
      {options.map((option, index) => (
        <React.Fragment key={option.value}>
          {index > 0 && (
            <div
              className={classNames("bg-sys-brand-outline flex-shrink-0", {
                "h-px w-full": vertical,
                "w-px": !vertical
              })}
            />
          )}
          <button
            className={classNames(
              "cursor-pointer font-sans text-sm font-medium leading-5 tracking-[0.001em]",
              "transition-all duration-300 flex items-center justify-center",
              "focus:outline-none focus:ring-0 focus:bg-state-layers-brand-on-secondary-container-opacity-0-12 active:outline-none active:ring-0 active:brightness-95",
              {
                "w-full min-h-[30px] flex-1": vertical,
                "w-full min-h-[40px] py-2.5": !vertical,
                [selectedColor]: option.value === value,
                [backgroundColor]: option.value !== value
              }
            )}
            style={{
              maxWidth: vertical ? "100%" : maxButtonWidth,
              outline: "none"
            }}
            onClick={() => {
              if (disabled) return;
              if (allowDeselect && option.value === value) {
                onChange(null);
              } else {
                onChange(option.value);
              }
            }}
          >
            <div
              className={classNames("flex items-center justify-center w-full", {
                "px-3": vertical,
                "px-4": !vertical
              })}
            >
              {showCheckmark && (
                <span
                  className={classNames(
                    "w-[18px] h-[18px] flex items-center justify-center transition-all duration-300",
                    option.value === value ? "opacity-100 max-w-[18px] mr-2" : "opacity-0 max-w-0"
                  )}
                >
                  <img src={CheckmarkIcon} alt="Checkmark" />
                </span>
              )}
              <span className={classNames("flex-1 text-label-large text-center truncate", textColor)}>
                {option.label}
              </span>
            </div>
          </button>
        </React.Fragment>
      ))}
    </div>
  );
}

export default SegmentedButton;
