import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useNavigate } from "@/router";
import { EVENT_REGISTRATION_QUERY } from "./queries";
import { EventRegistration, Session, Address } from "./types";
import usePageTitle from "@/hooks/usePageTitle";
import { useFlags } from "@/hooks/useFeatureFlags";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import ObjectHeading from "@/components/ObjectHeading";
import Table from "@/components/Table";
import IconButton from "@/components/IconButton";
import Menu from "@/components/Menu";
import { renderStatus } from "@/pages/events/_utils";
import { getHours, getTime, toFormat, toDateTimeFormat, getAge } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import DetailsCard from "@/components/DetailsCard";
import ListItem from "@/components/ListItem";
import Button from "@/components/Button";
import CancelModal from "../_components/CancelModal";
import { EventOutletContext } from "../../_layout";

function formattedAddress(address: Address) {
  return `${address.address1}${address.address2 ? `, ${address.address2}` : ""}`;
}

const booleanToYesNo = (bool: boolean) => (bool ? "Yes" : "No");

function CustomFieldsDetail({ eventRegistration }: { eventRegistration: EventRegistration }) {
  if (!eventRegistration.customFields) return null;
  const { adaAccomodations, additionalGuests, dateOfBirth = "", gender } = eventRegistration.customFields;

  const formattedDateOfBirth = (dateOfBirth: string) =>
    toFormat(dateOfBirth) ? `${toFormat(dateOfBirth)} (${getAge(dateOfBirth)?.years} yrs)` : null;
  const dobHeadline = formattedDateOfBirth(dateOfBirth);

  const formattedAdditionalGuests = (additionalGuests ?? 0).toString();

  return (
    <div className="-mx-2" data-testid="pat-registration-detail">
      {dobHeadline && (
        <div className="mb-3">
          <ListItem headline={dobHeadline} iconName="cake" overline="Date of Birth" />
        </div>
      )}
      {gender && (
        <div className="mb-3">
          <ListItem headline={gender} iconName="person" overline="Gender" />
        </div>
      )}
      {additionalGuests && (
        <div className="mb-3">
          <ListItem headline={formattedAdditionalGuests} iconName="group_add" overline="Additional Guests" />
        </div>
      )}
      {typeof adaAccomodations === "boolean" && (
        <div className="mb-3">
          <ListItem headline={booleanToYesNo(adaAccomodations)} iconName="accessible_forward" overline="ADA" />
        </div>
      )}
    </div>
  );
}

function RegistrationDetail({ eventRegistration }: { eventRegistration: EventRegistration }) {
  const hasCustomFields = eventRegistration.event.customFields.length > 0;

  return (
    <div className="mt-6 text-sys-brand-on-surface">
      <h2 className="mb-1 text-title-medium">Registration Details</h2>
      <p className="mb-3 text-body-medium">{eventRegistration?.event?.title}</p>
      {hasCustomFields && <CustomFieldsDetail eventRegistration={eventRegistration} />}
    </div>
  );
}

export default function RegistrationDetailsPage() {
  usePageTitle(
    "/events/:eventSlug/registrations/:registrationId",
    "Events | Event Details | Registrations | Registration Details"
  );
  const flags = useFlags();
  const { eventSlug = "", registrationId = "" } = useParams();
  const navigate = useNavigate();
  const { eventData, setErrorMessage } = useOutletContext<EventOutletContext>();
  const [openCancelRegistrationModal, setOpenCancelRegistrationModal] = useState(false);

  const { data, loading, refetch } = useQuery<{ eventRegistration: EventRegistration }>(EVENT_REGISTRATION_QUERY, {
    variables: {
      id: registrationId
    }
  });

  const eventRegistration = data?.eventRegistration;
  const { firstName, lastName, profilePhoto, pgaId, mainProgramType } = eventRegistration?.person || {};
  const sessionData = eventRegistration?.sessions.map((session: Session) => ({
    ...session,
    id: session.id,
    startsAt: toFormat(session.startsAt),
    endsAt: toFormat(session.endsAt),
    duration: getHours(session.duration),
    startTime: getTime(session.startsAt, undefined, eventData?.timezone),
    endTime: getTime(session.endsAt, undefined, eventData?.timezone)
  }));
  const personData = eventRegistration?.person;
  const address = personData?.addresses?.find((address) => address.addressType === "home");
  const phone = personData?.phoneNumbers.find((phone) => phone.phoneType === "mobile");
  const hasContactInfo = personData?.email || address || phone?.phoneNumber;
  const order = eventRegistration?.order;
  const showCancelAction = flags["backoffice-events-cancel-registration"] && !eventRegistration?.canceledAt;

  return (
    <SinglePageLayout
      backLink={{ to: `/events/:eventSlug/registrations`, params: { eventSlug } }}
      actions={
        showCancelAction && (
          <Button
            variant="text"
            icon="delete"
            onClick={() => setOpenCancelRegistrationModal(true)}
            testId="cancel-registration"
            key="cancel-registration"
          >
            Cancel Registration
          </Button>
        )
      }
    >
      <div className="max-w-[1440px] mx-auto mt-8" data-testid="registration-details-page">
        <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 mb-4">
          <ObjectHeading
            title={firstName && lastName ? `${firstName} ${lastName}` : ""}
            imageUrl={profilePhoto}
            {...(!profilePhoto ? { iconName: "person" } : {})}
            isDetailsView
          >
            <div className="flex flex-wrap -ml-4 text-title-medium text-sys-brand-on-surface">
              {pgaId && <div className="px-4">PGA ID: {pgaId}</div>}
              {mainProgramType?.name && (
                <div className="px-4 border-l border-sys-brand-outline-variant">Program: {mainProgramType?.name}</div>
              )}
            </div>
          </ObjectHeading>
        </div>
        {eventRegistration && <RegistrationDetail eventRegistration={eventRegistration} />}

        {!loading && hasContactInfo && (
          <div>
            <h2 className="flex flex-col flex-start self-stretch mt-8 mb-3 text-sys-brand-on-surface text-title-medium">
              Registrant Contact Information
            </h2>
            <div className="flex flex-col md:grid md:grid-cols-3 gap-4">
              {address && (
                <div>
                  <DetailsCard
                    heading="Address"
                    options={[
                      {
                        iconName: "home",
                        overline: "Home",
                        headline: formattedAddress(address),
                        supportingText: `${address.city}, ${address.state} ${address.postalCode}`
                      }
                    ]}
                  />
                </div>
              )}
              {personData?.email && (
                <div>
                  <DetailsCard
                    heading="Email Address"
                    options={[
                      {
                        headline: personData?.email,
                        iconName: "mail"
                      }
                    ]}
                  />
                </div>
              )}
              {phone?.phoneNumber && (
                <div>
                  <DetailsCard
                    heading="Phone"
                    options={[
                      {
                        overline: "Mobile/Cell",
                        headline: phone?.phoneNumber,
                        iconName: "call"
                      }
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <h2 className="mt-6 mb-3 text-title-medium text-sys-brand-on-surface">Sessions</h2>
        <Table
          data={sessionData || []}
          loading={loading}
          columns={[
            {
              accessorKey: "startsAt",
              header: "Start Date",
              enableSorting: false,
              meta: {
                className: getStickyColumnClasses(!!sessionData?.length)
              }
            },
            { accessorKey: "endsAt", header: "End Date", enableSorting: false },
            { accessorKey: "startTime", header: "Start Time", enableSorting: false },
            { accessorKey: "endTime", header: "End Time", enableSorting: false },
            { accessorKey: "title", header: "Day of the Week", enableSorting: false },
            { accessorKey: "duration", header: "Duration", enableSorting: false }
          ]}
          renderEmptyState={() => <></>}
        />

        {flags["backoffice-events-order-status"] && order && (
          <>
            <h2 className="mt-6 mb-3 text-title-medium text-sys-brand-on-surface">Order</h2>
            <div className="max-w-[320px] sm:max-w-[500px] mb-4">
              <DetailsCard
                className="py-4 pl-4 pr-0"
                options={[
                  {
                    iconName: "receipt_long",
                    overline: `Order #${order.id}`,
                    headline: `${firstName} ${lastName}`,
                    supportingText: `${toDateTimeFormat(order.updatedAt)}`,
                    action: () =>
                      navigate(`/billing/orders/:orderId`, {
                        params: { orderId: order.id }
                      }),
                    renderActions: () => (
                      <div className="flex items-center -mt-3">
                        <span className="text-label-small text-sys-brand-on-surface-variant">
                          {centsToDollarsFormatted(order.totalPaid)}
                        </span>
                        <Menu
                          AnchorComponent={
                            <IconButton
                              name="more_vert"
                              className="text-sys-brand-on-surface"
                              iconClassName="font-extrabold"
                            />
                          }
                          menuItems={[
                            {
                              label: "View Order",
                              onClick: () =>
                                navigate(`/billing/orders/:orderId`, {
                                  params: { orderId: order.id }
                                })
                            }
                          ]}
                        />
                      </div>
                    ),
                    status: () => renderStatus(order.status)
                  }
                ]}
              />
            </div>
          </>
        )}
        {openCancelRegistrationModal && eventRegistration && (
          <CancelModal
            event={eventRegistration.event}
            eventRegistrationId={eventRegistration.id}
            onClosed={() => setOpenCancelRegistrationModal(false)}
            onError={(message: string) => setErrorMessage(message)}
            order={eventRegistration.order}
            refetch={refetch}
          />
        )}
      </div>
    </SinglePageLayout>
  );
}
