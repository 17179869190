import { useMemo, ReactNode, useRef, useEffect, useState } from "react";
import { LitMenu, MdMenu } from "../Menu/LitMenu";
import Icon from "../Icon";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";

type MenuProps = {
  content: ReactNode;
  activeFilters?: number;
  useIsOpen?: boolean;
};

const randomNum = () => (Math.random() * 10000000).toFixed(0);

export default function MenuFilters({ content, activeFilters, useIsOpen = false }: MenuProps) {
  const menuRef = useRef<MdMenu>(null);
  const [isOpen, setIsOpen] = useState(useIsOpen);
  const anchorId = useMemo(() => `menu-filters-anchor-${randomNum()}`, []);
  const isMobileDisplay = isMobile(useWindowSize().width);

  const setVisibility = () => {
    if (isOpen) {
      menuRef.current?.close();
    } else {
      menuRef.current?.show();
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !event.composedPath().includes(document.getElementById(anchorId) as EventTarget)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function showOverflow() {
      const dialogElement = menuRef.current;

      if (dialogElement) {
        const shadowRoot = dialogElement.shadowRoot;
        const itemsElement = shadowRoot?.querySelector(".items") as HTMLElement;

        if (itemsElement) {
          itemsElement.style.overflow = "visible";
        }
      }
    }
    setTimeout(showOverflow, 100);
  }, [anchorId]);

  return (
    <div>
      <div
        id={anchorId}
        role="button"
        onClick={() => setVisibility()}
        data-testid="expanded-filters-button"
        className="w-fit"
      >
        <div className="w-fit sm:w-[116px] max-h-[56px] sm:border border-sys-brand-outline p-4 flex items-center rounded relative">
          {activeFilters && activeFilters > 0 ? (
            <span className="bg-sys-brand-primary text-sys-brand-on-error  text-label-small w-4 h-4 rounded-full flex items-center justify-center tracking-wider absolute right-2.5 sm:-right-1.5 top-2.5 sm:-top-1.5">
              {activeFilters}
            </span>
          ) : null}
          <Icon name="tune" size={24} className="text-sys-brand-on-surface sm:mr-2" />
          <span className="hidden sm:block">Filters</span>
        </div>
      </div>
      <LitMenu
        anchor={anchorId}
        ref={menuRef}
        xOffset={isMobileDisplay ? -16 : 0}
        positioning="document"
        menuCorner="start-end"
        anchorCorner="end-end"
      >
        {content}
      </LitMenu>
    </div>
  );
}
