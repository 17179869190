import Avatar from "../Avatar";
import { MaterialNames } from "../material_symbol_names";
import classNames from "classnames";
import Tooltip from "@/components/Tooltip";

type SubText = string | { text: string; tooltipText?: string }[];

interface PageHeaderProps {
  title: string;
  subText?: SubText;
  iconName?: MaterialNames;
  imageUrl?: string;
  ButtonComp?: () => JSX.Element;
  children?: React.ReactNode;
  isDetailsView?: boolean;
  isDetailsViewDesktop?: boolean;
}

const Text = ({
  children,
  noLastBorder,
  isDetailsViewDesktop
}: {
  children: React.ReactNode;
  noLastBorder?: boolean;
  isDetailsViewDesktop?: boolean;
}) => (
  <p
    className={classNames("pr-4 text-title-medium sm:border-r border-sys-brand-outline-variant", {
      "last:border-r-0": !noLastBorder,
      "sm:border-r-0 block": isDetailsViewDesktop
    })}
  >
    {children}
  </p>
);

function Subtext({ subtext, isDetailsViewDesktop }: { subtext?: SubText; isDetailsViewDesktop?: boolean }) {
  if (!subtext) return null;

  if (Array.isArray(subtext)) {
    return (
      <div
        className={classNames(
          "flex flex-wrap flex-col gap-y-1 sm:gap-y-0 sm:flex-row gap-x-4 mt-1 sm:mt-0 justify-start",
          {
            "sm:flex-col": isDetailsViewDesktop
          }
        )}
      >
        {subtext.map(({ text, tooltipText }, index) =>
          tooltipText ? (
            <Tooltip
              key={index}
              variant="plain"
              color="black"
              supportingText={tooltipText}
              menuCorner="end-start"
              anchorCorner="start-start"
            >
              <Text noLastBorder isDetailsViewDesktop={isDetailsViewDesktop}>
                {text}
              </Text>
            </Tooltip>
          ) : (
            <Text key={index} isDetailsViewDesktop={isDetailsViewDesktop}>
              {text}
            </Text>
          )
        )}
      </div>
    );
  }

  return <p className="text-title-medium">{subtext}</p>;
}

/**
 * Object Heading
 * @see https://www.figma.com/design/HJmPQurloNvKFxyfqvSV9Y/PGA-Product-Design-System?node-id=2786-43187&m=dev
 */

export default function ObjectHeading({
  title,
  subText,
  iconName = "domain",
  imageUrl,
  isDetailsView,
  isDetailsViewDesktop,
  ButtonComp,
  children
}: PageHeaderProps) {
  return (
    <div className="w-full">
      <div
        className={classNames("flex flex-col sm:flex-row items-start sm:items-center justify-between", {
          "sm:flex-col sm:!items-start": isDetailsViewDesktop
        })}
      >
        <div
          className={classNames("flex", {
            "flex-col md:flex-row items-start md:items-center justify-start": isDetailsView && !isDetailsViewDesktop,
            "flex-col items-start": isDetailsViewDesktop && isDetailsView,
            "items-center": !isDetailsView
          })}
        >
          <Avatar
            size="large"
            picture={imageUrl}
            {...(!imageUrl && { iconName })}
            className={classNames("mr-3 flex-shrink-0", {
              "my-3 md:my-0": isDetailsView,
              "!mb-3": isDetailsViewDesktop
            })}
          />
          <div className="flex-col">
            <p className="text-headline-small">{title}</p>
            <Subtext subtext={subText} isDetailsViewDesktop={isDetailsViewDesktop} />
            {children && <div>{children}</div>}
          </div>
        </div>
        {ButtonComp && (
          <div className="mt-4 sm:mt-0">
            <ButtonComp />
          </div>
        )}
      </div>
    </div>
  );
}
