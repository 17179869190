import { gql } from "@apollo/client";

export const ALL_SECTIONS_QUERY = gql`
  query sections {
    sections {
      id
      name
      legacyId
    }
  }
`;
