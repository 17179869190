import { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Controller, useFormContext } from "react-hook-form";
import DateSelect from "@/components/DateSelect";
import TextField from "@/components/TextField";
import Divider from "@/components/Divider";
import Select from "@/components/Select";
import { DateTime } from "luxon";
import { GET_SERIES_QUERY } from "./queries";
import AutoComplete from "@/components/Autocomplete";
import { getVimeoVideoDuration } from "./_utils";
import usePageTitle from "@/hooks/usePageTitle";

const VIMEO_URL_REGEX =
  /^https?:\/\/player\.vimeo\.com\/progressive_redirect\/playback\/(\d+)\/rendition\/720p\/file\.mp4\?.*$/;

const CREDIT_TYPE_OPTIONS = [
  { value: "education", displayText: "Education" },
  { value: "meetings", displayText: "Meetings" },
  { value: "national_surveys", displayText: "National Surveys" },
  { value: "player_engagement", displayText: "Player Engagement" }
];

export function DetailsStep(form: any) {
  usePageTitle("/education/videos/videoDetails", "Education | Videos | Video Details");
  const {
    control,
    clearErrors,
    trigger,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [vimeoError, setVimeoError] = useState<string | null>(null);
  const [vimeoVideoId, setVimeoVideoId] = useState<string | null>(null);
  const readOnly = form.readOnly;

  const { data } = useQuery<{ videoSeries: string[] }>(GET_SERIES_QUERY, {
    variables: { live: false }
  });

  const validateVimeoUrl = useCallback(
    async (url: string) => {
      if (!url) {
        setVimeoError(null);
        setVimeoVideoId(null);
        setValue("durationInSeconds", null);
        return;
      }

      const match = url.match(VIMEO_URL_REGEX);
      if (!match) {
        setVimeoError("Invalid Vimeo URL");
        setVimeoVideoId(null);
        setValue("durationInSeconds", null);
        return;
      }
      setVimeoError(null);
      setVimeoVideoId(match[1]);
      getVimeoVideoDuration(match[1]).then((duration) => {
        setValue("durationInSeconds", duration);
      });
    },
    [setValue]
  );

  useEffect(() => {
    validateVimeoUrl(form.form.watch("url"));
  }, [form.form, validateVimeoUrl]);

  const getCreditTypeDisplayText = (value: string) => {
    const option = CREDIT_TYPE_OPTIONS.find((opt) => opt.value === value);
    return option ? option.displayText : value;
  };

  return (
    <>
      {readOnly ? (
        <>
          <div className="space-y-4">
            <div className="text-title-medium mt-4">Details</div>
            <div className="text-body-medium">
              {form.form.watch("title") && <p>Title: {form.form.watch("title")}</p>}
              {form.form.watch("durationInSeconds") && (
                <p>
                  Running Time:{" "}
                  {form.form.watch("durationInSeconds")
                    ? `${Math.floor(form.form.watch("durationInSeconds") / 60)} minutes ${
                        form.form.watch("durationInSeconds") % 60
                      } seconds`
                    : "N/A"}
                </p>
              )}
              {form.form.watch("creditCategory") && (
                <p>Credit Type: {getCreditTypeDisplayText(form.form.watch("creditCategory"))}</p>
              )}
              {form.form.watch("description")?.length > 1 && <p>Description: {form.form.watch("description")}</p>}
            </div>

            <div className="text-title-medium mt-4">Dates</div>
            <div className="text-body-medium">
              {form.form.watch("updatedAt") && (
                <p>Last Modified: {form.form.watch("updatedAt")?.startDate?.toLocaleDateString()}</p>
              )}
              {form.form.watch("publishedAt") && (
                <p>Publication Date: {form.form.watch("publishedAt")?.startDate?.toLocaleDateString()}</p>
              )}
              {form.form.watch("expiredAt") && (
                <p>Expiration Date: {form.form.watch("expiredAt")?.startDate?.toLocaleDateString()}</p>
              )}
            </div>

            <div className="text-title-medium mt-4">Series</div>
            <div className="text-body-medium">
              <p>{form.form.watch("series")}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="space-y-6">
            <div className="text-headline-small">Edit details about the video.</div>
            <div className="col-span-full">
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Title"
                    placeholder="Enter video title"
                    className="w-full"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      if ((e.target as HTMLInputElement).value && errors.title) {
                        clearErrors("title");
                      }
                      if (!(e.target as HTMLInputElement).value) {
                        trigger("title");
                      }
                    }}
                    required
                    error={!!errors.title}
                    errorText={errors.title?.message as string}
                  />
                )}
              />
            </div>

            <div className="col-span-full">
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Description"
                    placeholder="Description"
                    className="w-full"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      if ((e.target as HTMLInputElement).value && errors.description) {
                        clearErrors("description");
                      }
                      if (!(e.target as HTMLInputElement).value) {
                        trigger("description");
                      }
                    }}
                    required
                    error={!!errors.description}
                    errorText={errors.description?.message as string}
                  />
                )}
              />
            </div>

            <div className="col-span-full">
              <Controller
                name="url"
                control={control}
                rules={{
                  required: "URL is required",
                  validate: (value) => VIMEO_URL_REGEX.test(value) || "Invalid Vimeo URL"
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      label="Vimeo URL"
                      placeholder="Vimeo URL"
                      className="w-full"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        if ((e.target as HTMLInputElement).value && errors.url) {
                          clearErrors("url");
                        }
                        if (!(e.target as HTMLInputElement).value) {
                          trigger("url");
                        }
                        validateVimeoUrl((e.target as HTMLInputElement).value);
                      }}
                      required
                      error={!!errors.url || !!vimeoError}
                      errorText={(errors.url?.message as string) || vimeoError || ""}
                    />
                    {!vimeoVideoId && (
                      <span className="text-[9pt] text-[#49454f] ml-4">
                        https://player.vimeo.com/progressive_redirect/playback/your_video_id/rendition/720p/file.mp4?loc=external&signature=vimeo_generated_signature
                      </span>
                    )}
                  </>
                )}
              />
            </div>

            <div className="col-span-full">
              <Controller
                name="creditCategory"
                control={control}
                rules={{ required: "Credit Category is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Credit Type"
                    options={[
                      { value: "education", displayText: "Education" },
                      { value: "meetings", displayText: "Meetings" },
                      { value: "national_surveys", displayText: "National Surveys" },
                      { value: "player_engagement", displayText: "Player Engagement" }
                    ]}
                    className="w-full"
                    value={value}
                    onSelect={(value) => {
                      onChange(value);
                      if (value && errors.creditCategory) {
                        clearErrors("creditCategory");
                      }
                      if (!value) {
                        trigger("creditCategory");
                      }
                    }}
                    required
                    error={!!errors.creditCategory}
                    errorText={errors.creditCategory?.message as string}
                  />
                )}
              />
            </div>

            <div className="col-span-full">
              <Controller
                name="series"
                control={control}
                rules={{ required: "Series is required" }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <AutoComplete
                      label="Series"
                      data={
                        data?.videoSeries.filter(
                          (series) => series?.toLowerCase().includes(value?.toLowerCase() || "")
                        ) || []
                      }
                      query={value || ""}
                      onChangeText={(e) => {
                        onChange(e);
                        if (e && errors.series) {
                          clearErrors("series");
                        }
                        if (!e) {
                          trigger("series");
                        }
                      }}
                      renderItem={(item) => <div>{item}</div>}
                      reset={() => onChange("")}
                      onSelect={(value) => onChange(value)}
                      required
                      error={!!errors.series}
                      errorText={errors.series?.message as string}
                    />
                    <span className="text-body-small text-[#40484C] ml-4">
                      Videos will be grouped and filterable by Series (e.g. 2024 PGA Show)
                    </span>
                  </>
                )}
              />
            </div>

            <div data-testid="publish-date">
              <Controller
                name="publishedAt"
                control={control}
                rules={{
                  required: "Publication Date is required",
                  validate: (value) => value?.startDate !== null || "Publication Date is required"
                }}
                render={({ field }) => (
                  <DateSelect
                    label="Publish Date *"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      if (e && errors.publishedAt) {
                        clearErrors("publishedAt");
                      }
                      if (!e) {
                        trigger("publishedAt");
                      }
                    }}
                    useSingleDate
                    supportingText="MM/DD/YYYY. Video will be active (published) at 12:00 AM (CT) on this date."
                    showFooter={false}
                    error={!!errors.publishedAt}
                    errorMessage={errors.publishedAt?.message as string}
                  />
                )}
              />
            </div>

            <div data-testid="expiration-date">
              <Controller
                name="expiredAt"
                control={control}
                rules={{
                  required: "Expiration Date is required",
                  validate: (value) => value?.startDate !== null || "Expiration Date is required"
                }}
                render={({ field }) => (
                  <DateSelect
                    label="Expiration Date *"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      if (e && errors.expiredAt) {
                        clearErrors("expiredAt");
                      }
                      if (!e) {
                        trigger("expiredAt");
                      }
                    }}
                    useSingleDate
                    supportingText="MM/DD/YYYY. Video will expire (be unpublished) at 11:59 PM (CT) on this date."
                    showFooter={false}
                    minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
                    error={!!errors.expiredAt}
                    errorMessage={errors.expiredAt?.message as string}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}

      {vimeoVideoId && (
        <div className="w-full">
          <div className="mt-8 mb-4">
            <Divider />
          </div>
          <iframe
            className="w-full"
            style={{ height: "30vw", minHeight: "200px" }}
            src={`https://player.vimeo.com/video/${vimeoVideoId}`}
            allow="autoplay fullscreen picture-in-picture clipboard-write"
          />
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      )}
    </>
  );
}
