import { DOMAIN } from "@/hooks/useCapabilities";
import { FlagRecord } from "@/hooks/useFeatureFlags";
import { NavLink } from "@/layouts/AppLayout/Sidebar/Sidebar";
import { Path } from "@/router";

export const getFacilityNavLinks: (facilityId: string, flags: FlagRecord) => Record<string, NavLink<Path>[]> = (
  facilityId,
  _
) => {
  const facilityLinks = [
    {
      domain: DOMAIN.FACILITIES,
      label: "Details",
      link: {
        to: "/facilities/:facilityId/details",
        params: { facilityId }
      },
      icon: "assignment_ind"
    }
  ].filter(Boolean) as NavLink<Path>[];

  return {
    "Manage Facility": facilityLinks
  };
};
