import ObjectDetailsPanel from "@/components/ObjectDetailsPanel";
import { useQuery } from "@apollo/client";
import { Person } from "@/types";
import {
  phoneOptions,
  addressOptions,
  emailOptions,
  employmentOptions,
  facilityOptions,
  membershipOptions,
  personalOptions
} from "../_utils";
import ListItem from "@/components/ListItem/ListItem";
import classNames from "classnames";
import Button from "@/components/Button";
import { PERSON_DETAILS_QUERY } from "../queries";
import { ObjectHeadingWrapper } from "@/pages/people/[pgaId]/_components/PersonDetailsHeader";
import Skeleton from "@/components/Skeleton";

import { useNavigate } from "@/router";
type PersonData = { person: Person };

export const PersonDetailsPanel = ({
  isDetailsPanelOpen,
  setIsDetailsPanelOpen,
  closeDetailsPanel,
  pgaId
}: {
  isDetailsPanelOpen: boolean;
  setIsDetailsPanelOpen: (value: boolean) => void;
  closeDetailsPanel: () => void;
  pgaId: string;
}) => {
  const { data, loading, error } = useQuery<PersonData>(PERSON_DETAILS_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });
  const navigate = useNavigate();
  if (loading) {
    return (
      <ObjectDetailsPanel
        isDetailsPanelOpen={isDetailsPanelOpen}
        setIsDetailsPanelOpen={setIsDetailsPanelOpen}
        closeDetailsPanel={closeDetailsPanel}
      >
        <div className="px-4">
          <ObjectHeadingWrapper pgaId={pgaId} isDetailsViewDesktop />
        </div>
        <Skeleton rows={7} />
      </ObjectDetailsPanel>
    );
  }

  if (error) return <div>Error: {error.message}</div>;
  const { person } = data!;

  const sections = [
    {
      heading: "Address",
      options: addressOptions(person.addresses)
    },
    {
      heading: "Phone Numbers",
      options: phoneOptions(person.phoneNumbers)
    },
    {
      heading: "Email",
      options: emailOptions(person.email)
    },
    {
      heading: "Personal",
      options: personalOptions(person)
    },
    {
      heading: "Employment",
      options: employmentOptions(person.employments.filter((employment) => employment.isPrimary))
    },
    {
      heading: "Facility Details",
      options: facilityOptions(person.employments.filter((employment) => employment.isPrimary)[0])
    },
    {
      heading: "Membership",
      options: membershipOptions(person.bands?.filter((band) => band.sectionAffiliation?.primary)[0])
    }
  ];

  const ButtonComp = () => (
    <div className="mt-4 mb-3">
      <Button
        onClick={() => {
          navigate("/people/:pgaId/details/overview", { params: { pgaId } });
          window.scrollTo(0, 0);
        }}
        variant="outlined"
      >
        View Person
      </Button>
    </div>
  );
  return (
    <ObjectDetailsPanel
      isDetailsPanelOpen={isDetailsPanelOpen}
      setIsDetailsPanelOpen={setIsDetailsPanelOpen}
      closeDetailsPanel={closeDetailsPanel}
    >
      <div className="px-6">
        <ObjectHeadingWrapper pgaId={pgaId} ButtonComp={ButtonComp} isDetailsViewDesktop />
      </div>
      {sections.map((section, index) => {
        return (
          <div key={index} className="flex flex-col w-full pt-3 px-6">
            <p className="text-title-medium mb-2">{section.heading}</p>
            {section.options.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={item.action ?? item.action}
                  className={classNames(`flex items-start`, { "cursor-pointer": item.action })}
                >
                  <ListItem {...item} />
                  {item.renderActions && item.renderActions()}
                </div>
              );
            })}
          </div>
        );
      })}
    </ObjectDetailsPanel>
  );
};

export default PersonDetailsPanel;
