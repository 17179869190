import { useEffect } from "react";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS, GET_PRODUCT_VARIANTS } from "../queries";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { useFormContext } from "react-hook-form";
import { LineItemFormValue } from "../edit";
import { sortBy, orderBy } from "lodash";
import { NumberFormatBase } from "react-number-format";
import { currencyFormatter } from "@/lib/currencyHelpers";
import TextField from "@/components/TextField";
import { getClassification } from "../../../_utils";

type Variant = {
  id: string;
  name: string;
  priceCents: number;
  changeablePrice?: boolean;
  displayText?: string;
  value: string;
};

type Product = {
  id: string;
  name: string;
};

type ProductData = {
  commerceProducts: {
    nodes: Product[];
  };
};

type VariantData = {
  commerceProductVariants: {
    nodes: Variant[];
  };
};

export const AddProductModal = ({
  onDismiss,
  lineItemsWatch
}: {
  onDismiss: () => void;
  lineItemsWatch: LineItemFormValue[];
}) => {
  const { setValue } = useFormContext();
  const {
    control,
    setValue: setProductValue,
    watch,
    resetField,
    trigger,
    formState: { errors }
  } = useForm();
  const [variables, setVariables] = useState({ input: {} });
  const [selectedProduct, setSelectedProduct] = useState<Product>({ id: "", name: "" });
  const [selectedVariant, setSelectedVariant] = useState<Variant>({
    id: "",
    name: "",
    priceCents: 0,
    changeablePrice: false,
    value: ""
  });
  const { data: productData } = useQuery<ProductData>(GET_PRODUCTS, { variables });

  const [variantsVariables, setVariantsVariables] = useState({ productId: selectedProduct.id });
  const { data: variantData } = useQuery<VariantData>(GET_PRODUCT_VARIANTS, {
    variables: variantsVariables
  });

  const productSearchValue = watch("product");
  const variantSearchValue = watch("variant");
  const isChangeablePrice = selectedVariant.changeablePrice;

  function selectVariant(name: string, value: Variant, setValue: (name: string, value: string) => void) {
    setSelectedVariant({
      id: value.value,
      name: value.name,
      priceCents: value.priceCents,
      changeablePrice: value.changeablePrice,
      value: value.value
    });
    setValue(name, `${value.displayText}`);
  }

  function selectProduct(name: string, value: Product, setValue: (name: string, value: string) => void) {
    setSelectedProduct({ id: value.id, name: value.name });
    setValue(name, `${value.name}`);
    resetField("variant");
    setVariantsVariables({ productId: value.id });
  }

  async function onContinue() {
    const result = await trigger();
    if (result) {
      setValue(
        "lineItems",
        [
          ...lineItemsWatch,
          ...[
            {
              id: `${Date.now()}`,
              originalId: selectedVariant.id,
              name: selectedVariant.name,
              quantity: 1,
              priceCents: isChangeablePrice ? Number(watch("price")) : Number(selectedVariant?.priceCents),
              displayOrder: 99,
              action: "add"
            }
          ]
        ],
        { shouldDirty: true }
      );
      onDismiss();
    }
  }

  useEffect(() => {
    setVariables(() => ({
      ...(productSearchValue ? { input: { search: productSearchValue } } : { input: {} })
    }));
  }, [productSearchValue]);

  useEffect(() => {
    setVariantsVariables((prevValue) => ({
      ...prevValue,
      ...(variantSearchValue ? { search: variantSearchValue } : {})
    }));
  }, [variantSearchValue]);

  const variantOptions =
    variantData?.commerceProductVariants?.nodes.map((variant) => ({
      ...getClassification(variant.name),
      id: variant.id,
      value: variant.id,
      name: variant.name,
      displayText: `${
        !variant.changeablePrice
          ? `${centsToDollarsFormatted(Number(variant.priceCents))} (${variant.name})`
          : `${variant.name}`
      }`,
      priceCents: variant.priceCents,
      changeablePrice: variant.changeablePrice
    })) || [];

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>
      <Button onClick={onContinue}>Continue Editing</Button>
    </>
  );

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline="Add Product"
      className="sm:min-w-[520px] md:min-w-[720px]"
      displayOverflow
    >
      <p className="mb-4">Select a product to add to the order.</p>
      <input className="absolute opacity-0" />
      <div className="w-full space-y-6">
        <Controller
          name="product"
          control={control}
          rules={{ required: "Product is required" }}
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              label="Product"
              required
              query={value}
              data={sortBy(productData?.commerceProducts.nodes, "name") || []}
              onChangeText={onChange}
              reset={() => {
                resetField("product");
                resetField("variant");
                setSelectedProduct({ id: "", name: "" });
              }}
              onSelect={(value) => selectProduct(name, value, setProductValue)}
              renderItem={(data) => <>{data.name}</>}
              error={!!errors?.product}
              errorText={errors?.product?.message?.toString()}
              variant="searchableDropdown"
            />
          )}
        />
        <Controller
          name="variant"
          control={control}
          rules={{ required: "Price/Variant is required" }}
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              label="Price/Variant"
              disabled={!selectedProduct.id}
              required
              query={value}
              data={
                sortBy(orderBy(variantOptions, "classificationNumber"), (item) => [
                  item.name,
                  item.classificationLetter
                ]) || []
              }
              onChangeText={onChange}
              reset={() => {
                resetField("variant");
                setSelectedVariant({ id: "", name: "", priceCents: 0, value: "" });
                setVariantsVariables({ productId: selectedProduct.id });
              }}
              onSelect={(value) => selectVariant(name, value, setProductValue)}
              renderItem={(data) => <>{data.displayText}</>}
              error={!!errors?.variant}
              errorText={errors?.variant?.message?.toString()}
              variant="searchableDropdown"
            />
          )}
        />
        {isChangeablePrice && (
          <Controller
            render={({ field }) => {
              return (
                <NumberFormatBase
                  disabled={false}
                  value={(field.value / 100).toFixed(2) || 0}
                  format={currencyFormatter}
                  customInput={TextField}
                  error={!!errors?.price}
                  errorText={errors?.price?.message?.toString()}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onValueChange={(values) => {
                    field.onChange(Math.round(values.floatValue || 0));
                  }}
                  leadingIcon="attach_money"
                  label="Price *"
                  className="w-full"
                />
              );
            }}
            control={control}
            name="price"
            rules={{
              validate: {
                required: (value) => {
                  if (!value && isChangeablePrice) return "Price is required";
                  return true;
                }
              }
            }}
          />
        )}
      </div>
    </Dialog>
  );
};
