import { TextFieldType } from "@material/web/textfield/internal/text-field";
import { LitOutlinedTextField, LitFilledTextField } from "./LitTextField";
import { SlotIconButton, SlotIconName } from "../Icon/SlotIcon";
import { forwardRef, FocusEvent } from "react";

const variants = {
  outlined: LitOutlinedTextField,
  filled: LitFilledTextField
};

export interface TextFieldProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string;
  leadingIcon?: SlotIconName;
  name?: string;
  onChange?(e: Event): void;
  onChangeText?(v: string): void;
  placeholder?: string;
  required?: boolean;
  trailingIcon?: SlotIconName;
  type?: TextFieldType;
  value?: string;
  variant?: keyof typeof variants;
  onFocus?(): void;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  rows?: number;
  tabIndex?: number;
  readOnly?: boolean;
}

export default forwardRef<HTMLInputElement, TextFieldProps>(function TextField(
  {
    variant = "outlined",
    disabled = false,
    label,
    required,
    value = "",
    leadingIcon,
    trailingIcon,
    onChange,
    onChangeText,
    onBlur,
    ...props
  },
  ref
) {
  const TextFieldComponent = variants[variant];

  return (
    <TextFieldComponent
      onChange={(e: Event) => {
        onChange?.(e);
        onChangeText?.((e.target as HTMLInputElement).value);
      }}
      onBlur={(e: FocusEvent<HTMLElement>) => onBlur?.(e)}
      hasLeadingIcon={!!leadingIcon}
      hasTrailingIcon={!!trailingIcon}
      label={required ? `${label} *` : label}
      aria-label={label}
      ref={ref as any}
      {...{ value, disabled }}
      {...props}
    >
      {leadingIcon && <SlotIconButton name={leadingIcon} slot="leading-icon" />}
      {trailingIcon && <SlotIconButton name={trailingIcon} slot="trailing-icon" />}
    </TextFieldComponent>
  );
});
