import { gql } from "@apollo/client";

export const EVENTS_QUERY = gql`
  query GetBackofficeEvents($query: BackofficeEventsSearchInput, $orderBy: BackofficeEventsEventOrderByInput) {
    events(query: $query, orderBy: $orderBy) {
      id
      slug
      status
      cancelledAt
      endsAt
      startsAt
      updatedAt
      sessionCount
      registrationCount
      registrationType
      title
      description
      eventType {
        id
        name
      }
      section {
        id
        name
        legacyId
        permissions {
          manageEvents
        }
      }
      facility {
        id
        name
      }
      locationType
      location
      address1
      city
      state
      zip
      timezone
      bannerImageUrl
      sessions {
        id
        startsAt
        endsAt
        maxAttendance
      }
      registrationOpenAt
      registrationCloseAt
      hasWaitlist
      customFields
      termsContent
      hasActiveOrders
      patEventAttributes {
        courseRatingFemale
        courseRatingMale
        examiner {
          id
        }
        hostProfessional {
          id
        }
        yardageFemale
        yardageFemaleFiftyPlus
        yardageMale
        yardageMaleFiftyPlus
      }
    }
  }
`;

export const GET_EVENT_TYPES = gql`
  query GetEventTypes {
    eventTypes {
      id
      name
    }
  }
`;

export const GET_MANAGEABLE_SECTIONS = gql`
  query GetManageableSections($input: BackofficeManageableSectionsInput!) {
    manageableSections(input: $input) {
      nodes {
        id
        name
        legacyId
      }
    }
  }
`;

export const GET_FACILITIES = gql`
  query GetFacilities($input: BackofficeCRMFacilitiesInput!) {
    facilities(input: $input, first: 10) {
      nodes {
        id
        name
        addresses {
          address1
          address2
          city
          state
          postalCode
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($input: BackofficeCommerceProductInput!) {
    commerceProducts(input: $input) {
      nodes {
        id
        name
        description
      }
    }
  }
`;
export const GET_PRODUCT_VARIANTS = gql`
  query GetProductVariants($productId: String!) {
    commerceProductVariants(input: { productId: $productId }) {
      totalCount
      nodes {
        id
        name
        priceCents
        externalId
      }
    }
  }
`;

export const GET_ADDRESS_TIMEZONE = gql`
  query GetAddressTimezone($address1: String!, $city: String!, $state: String!, $zip: String!) {
    addressTimezone(address1: $address1, city: $city, state: $state, zip: $zip)
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: BackofficeEventsCreateEventInput!) {
    createEvent(input: $input) {
      success
      message
      event {
        slug
      }
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($input: BackofficeEventsUpdateEventInput!) {
    updateEvent(input: $input) {
      success
      message
    }
  }
`;

export const GET_PEOPLE = gql`
  query GetPeople($input: BackofficeCRMPeopleInput!) {
    people(input: $input) {
      nodes {
        id
        pgaId
        displayName
        profilePhoto
      }
    }
  }
`;
