import { Path, useNavigate } from "@/router";
import { PropsWithChildren } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { LitPrimaryTab } from "./LitTabs";

export interface TabsProps {
  className?: string;
  tabItems: {
    label: string;
    active?: boolean;
    onClick?(): void;
    link?: Path;
  }[];
}

const isTabActive = (currentPath: string, linkTo?: Path, active?: boolean) => {
  if (!linkTo || typeof active === "boolean") {
    return active;
  }

  let path = currentPath;

  while (path && path !== "/") {
    if (matchPath(linkTo, path)) {
      return true;
    }

    path = path.split("/").slice(0, -1).join("/");
  }

  return false;
};

export default function Tabs({ className, tabItems }: PropsWithChildren<TabsProps>) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={`flex border-b overflow-x-auto ${className}`}>
      {tabItems.map(({ label, active, onClick, link }, i) => (
        <LitPrimaryTab
          key={i}
          active={isTabActive(location.pathname, link, active)}
          onClick={link ? () => navigate(link) : onClick}
          data-testid="tab"
        >
          {label}
        </LitPrimaryTab>
      ))}
    </div>
  );
}
