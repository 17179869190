import DetailsCard from "@/components/DetailsCard/DetailsCard";
import { useState } from "react";
import { Person } from "../types";
import EditPersonModal from "./EditPersonModal";

interface PersonCardProps {
  name: string;
  pgaId: string;
  onSave: (value: Person) => void;
  className?: string;
  title: string;
}

export default function PersonCard({ name, pgaId, onSave, className, title }: PersonCardProps) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <DetailsCard
        className={className}
        heading={title}
        options={[
          {
            overline: "Name",
            headline: name,
            supportingText: `PGA ID: ${pgaId}`,
            iconName: "person"
          }
        ]}
        onEdit={() => setIsEditing(true)}
      />

      <EditPersonModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        title={title}
        initialName={name}
        onSave={onSave}
      />
    </>
  );
}
