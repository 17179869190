import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import { SELECTION_CHANGE_COMMAND, FORMAT_TEXT_COMMAND, $getSelection, $isRangeSelection } from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";

import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import Icon from "@/components/Icon";
import { createPortal } from "react-dom";
import { getSelectedNode } from "../utils";
import FloatingLinkEditor from "./FloatingLinkEditor";
const LowPriority = 1;

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState("paragraph");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      const updateFormats = () => {
        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));
        setIsUnderline(selection.hasFormat("underline"));
      };

      const updateLinks = () => {
        const node = getSelectedNode(selection);
        const parent = node.getParent();
        if ($isLinkNode(parent) || $isLinkNode(node)) {
          setIsLink(true);
        } else {
          setIsLink(false);
        }
      };

      updateFormats();
      updateLinks();
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <div
      className="flex flex-row gap-1 text-sys-brand-on-surface px-3 py-2 border-b border-sys-brand-outline"
      ref={toolbarRef}
    >
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"h-6 w-6 rounded-md " + (isBold ? "bg-sys-brand-secondary-container" : "")}
        aria-label="Format Bold"
        data-testid="bold-button"
      >
        <Icon name="format_bold" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"h-6 w-6 rounded-md " + (isItalic ? "bg-sys-brand-secondary-container" : "")}
        aria-label="Format Italics"
      >
        <Icon name="format_italic" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
        className={"h-6 w-6 rounded-md " + (isUnderline ? "bg-sys-brand-secondary-container" : "")}
        aria-label="Format Underline"
      >
        <Icon name="format_underlined" />
      </button>
      <button
        className={"h-6 w-6 rounded-md " + (isLink ? "bg-sys-brand-secondary-container" : "")}
        onClick={insertLink}
        aria-label="Insert Link"
      >
        <Icon name="link" />
      </button>
      {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      <button
        className={"ml-6 h-6 w-6 rounded-md " + (blockType === "ul" ? "bg-sys-brand-secondary-container" : "")}
        onClick={formatBulletList}
      >
        <Icon name="format_list_bulleted" />
      </button>
      <button
        className={"h-6 w-6 rounded-md " + (blockType === "ol" ? "bg-sys-brand-secondary-container" : "")}
        onClick={formatNumberedList}
      >
        <Icon name="format_list_numbered" />
      </button>
    </div>
  );
}
