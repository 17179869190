import Banner from "@/components/Banner";
import { Person } from "../CreateGlobalInteractionSideSheet";

export const UnavailablePersonBanner = ({ person }: { person: Person }) => {
  return (
    <Banner
      variant="error"
      description={`Interactions cannot be added for ${
        person.firstName + " " + person.lastName
      } at this time. Try selecting another person to add an interaction to.`}
      inline
    />
  );
};
