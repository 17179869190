import { gql } from "@apollo/client";

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on BackofficePerson {
    id
    firstName
    lastName
    displayName
    profilePhoto
    pgaId
  }
`;

export const SALESFORCE_PERSON_FRAGMENT = gql`
  fragment SalesforcePersonFragment on BackofficePerson {
    id
    firstName
    lastName
    profilePhoto
    pgaId
    salesforceExternalId
    interactions(search: $search, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        createdAt
        categories
        owner
        messages {
          content
          createdAt
        }
        lastMessage {
          content
          createdAt
          isPrivate
          haveAccess
        }
        lastModifiedDate
        interactionDate
        messagesCount
        mode
        participants {
          firstName
          lastName
          profilePhoto
        }
        summary
        relatedTo
      }
    }
  }
`;

export const MEMBERSHIP_BAND_FRAGMENT = gql`
  fragment MembershipBandFragment on MembershipBand {
    id
    createdReason
    sectionAffiliation {
      id
      primary
      section {
        id
        name
        legacyId
      }
    }
    section {
      id
      name
      legacyId
    }
    programType {
      id
      name
    }
    expiresOn
    startedOn
    terminatesOn
    classification {
      id
      name
      description
    }
  }
`;

export const PERSON_DETAILS_FRAGMENT = gql`
  fragment PersonDetailsFragment on BackofficePerson {
    id
    firstName
    lastName
    suffixName
    gender
    pgaId
    email
    profilePhoto
    informalName
    militaryService {
      id
      status
    }
    primarySection {
      id
      name
    }
    phoneNumbers {
      id
      phoneNumber
      phoneType
      phoneCountry
      phoneFormatted
    }
    contactAddressId
    addresses {
      id
      address1
      address2
      city
      state
      postalCode
      countryCode
      isVerified
      addressType
    }
    employments {
      id
      endedOn
      startedOn
      isPrimary
      title
      facility {
        id
        name
        addresses {
          id
          address1
          address2
          city
          state
          postalCode
          countryCode
          isVerified
          addressType
        }
        phone {
          id
          phoneFormatted
        }
        section {
          id
          name
        }
      }
    }
  }
`;

export const PHONE_NUMBER_FRAGMENT = gql`
  fragment PhoneNumberFragment on CRMPhoneNumber {
    id
    phoneNumber
    phoneType
    phoneCountry
    phoneFormatted
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on CRMAddress {
    id
    address1
    address2
    city
    state
    postalCode
    countryCode
    addressType
    isVerified
  }
`;

export const DUES_RATE_FRAGMENT = gql`
  fragment DuesRateFragment on BackofficeDuesDuesRate {
    classification
    classificationDescription
    id
    lookupKey
    memberGrade
    name
    previousPricing {
      price
      priceCents
      year
    }
    price
    priceCents
    productName
    updatedAt
  }
`;
