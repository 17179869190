import Table, { type Row } from "@/components/Table";
import EmptyState, { Props as EmptyStateProps } from "@/components/EmptyState";
import { useNavigate, Path } from "@/router";
import { Person } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import { getClassification, getPrimaryFacilityName, getPrimarySectionName, getProgramStatus } from "../_utils";
import IconButton from "@/components/IconButton";
import { Control, useController } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { RenderFooter } from "@/hooks/usePagination";
import { PropsWithChildren } from "react";
import { PeopleQueryResponse } from "../queries";
import { generatePath } from "react-router-dom";
import { actionId, Menu } from "@/hooks/useActionMenu";

function buildColumns(
  peopleData: Person[],
  onClickRow: (row: Row<Person>) => void,
  setMenu: (menu: Menu) => void
): ColumnDef<Person, any>[] {
  return [
    {
      size: 230,
      header: "Name",
      id: "displayName",
      cell: ({ row: { original: person } }) => {
        const { displayName, profilePhoto } = person;
        return <AvatarDetails picture={profilePhoto} title={displayName} text="" />;
      },
      meta: {
        className: getStickyColumnClasses(!!peopleData?.length)
      }
    },
    { accessorKey: "pgaId", header: "PGA ID", id: "pgaId", enableSorting: false },
    {
      accessorKey: "class",
      header: "Class",
      id: "class",
      enableSorting: false,
      cell: (cellProps) => getClassification(cellProps.row.original)?.name
    },
    {
      accessorKey: "status",
      header: "Status",
      id: "status",
      enableSorting: false,
      cell: (cellProps) => getProgramStatus(cellProps.row.original)
    },
    {
      accessorKey: "section",
      header: "Section",
      size: 200,
      enableSorting: false,
      cell: (cellProps) => getPrimarySectionName(cellProps.row.original)
    },
    {
      accessorKey: "primaryFacility",
      header: "Primary Facility",
      enableSorting: false,
      size: 300,
      cell: (cellProps) => getPrimaryFacilityName(cellProps.row.original)
    },
    {
      cell({ row, row: { id: rowId } }) {
        const items = [
          {
            label: "View Person",
            onClick: () => onClickRow(row)
          }
        ];

        function onClick() {
          setMenu({ rowId, items });
        }

        return (
          <div
            className="flex flex-row justify-end"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconButton
              id={actionId(rowId)}
              onClick={onClick}
              data-testid="actions"
              title="actions"
              name="more_vert"
              iconClassName="font-extrabold"
              className="!m-0 h-10"
            />
          </div>
        );
      },
      id: "actions",
      size: 100
    }
  ];
}

export const Header = ({
  children,
  control,
  addSearchParam,
  deleteSearchParams
}: PropsWithChildren<{
  control: Control;
  addSearchParam: (key: string, value: string) => void;
  deleteSearchParams: (key: string) => void;
}>) => {
  const { field } = useController({ control, name: "name" });

  return (
    <div>
      <div className="flex md:items-center flex-col md:flex-row justify-between gap-3">
        <div className="max-w-[320px]">
          <SearchInput
            placeholder="Search People"
            query={field.value}
            ref={field.ref}
            param="Name, PGA ID"
            onChange={(e) => {
              field.onChange(e);
              addSearchParam("search", e);
            }}
            onClear={() => {
              deleteSearchParams("search");
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

function buildPersonPath(pgaId: string, searchParamsUrl: string) {
  return generatePath(`/people/:pgaId/details/overview?return_to=/people&${searchParamsUrl}`, { pgaId }) as Path;
}

export type Props = {
  loading: boolean;
  data?: PeopleQueryResponse;
  renderFooter: RenderFooter<Person>;
  control: Control;
  emptyAction?: EmptyStateProps["action"];
  addSearchParam(key: string, value: string): void;
  deleteSearchParams(key: string): void;
  searchParamsUrl: string;
  setMenu(menu: Menu): void;
};

export default function PeopleTable({
  loading,
  data,
  renderFooter,
  control,
  emptyAction,
  addSearchParam,
  deleteSearchParams,
  searchParamsUrl,
  setMenu
}: Props) {
  const navigate = useNavigate();

  const peopleData = data?.people.nodes || [];

  function onClickRow(row: Row<Person>) {
    navigate(buildPersonPath(row.original.pgaId!, searchParamsUrl));
  }

  return (
    <Table
      data={peopleData}
      loading={loading}
      onClickRow={onClickRow}
      renderHeader={() => (
        <Header control={control} addSearchParam={addSearchParam} deleteSearchParams={deleteSearchParams}>
          {renderFooter(data?.people, { variant: "compact" })()}
        </Header>
      )}
      columns={buildColumns(peopleData, onClickRow, setMenu)}
      renderEmptyState={() => (
        <EmptyState
          action={emptyAction}
          title="No Results Found"
          caption="Try a different search or filter"
          iconName="person"
        />
      )}
      renderFooter={renderFooter(data?.people)}
    />
  );
}
