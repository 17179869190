import { useState, ReactNode } from "react";
import Divider from "@/components/Divider";
import Switch from "@/components/Switch/Switch";
import Radio from "@/components/Radio";
import { AssessmentFormEntry } from "../../types";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import Stepper from "@/components/Stepper";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";
import classNames from "classnames";
import MobileStepDisplay from "@/components/MultiStepForm/MobileStepDisplay";
import IconButton from "@/components/IconButton";
import Button from "@/components/Button";
import { LinkProps } from "@generouted/react-router/client";
import { Params, Path } from "@/router";
import ObjectHeading from "@/components/ObjectHeading";
import { DateTime } from "luxon";
import Tag from "@/components/Tag";
import PageTitle from "@/components/PageTitle";

const CREDIT_TYPE_OPTIONS = [
  { value: "education", displayText: "Education" },
  { value: "meetings", displayText: "Meetings" },
  { value: "national_surveys", displayText: "National Surveys" },
  { value: "player_engagement", displayText: "Player Engagement" }
];

interface StepType {
  headline: string;
  supportingText: string;
}

interface Step extends StepType {
  content: ReactNode;
}

function ReadOnlyAssessmentQuestions({
  questions,
  showAnswers
}: {
  questions: AssessmentFormEntry[];
  showAnswers: boolean;
}) {
  return (
    <div className="mt-10 ml-4">
      <ol className="list-decimal space-y-4 pl-5">
        {questions.map((question) => (
          <li key={question.id} className="text-title-medium">
            <div>{question.content}</div>
            <ul className="list-none pl-5">
              {question.options.map((option, optIndex) => (
                <div key={optIndex} className="flex items-center my-4 z-0">
                  <Radio
                    label=""
                    checked={showAnswers && option.answer}
                    name={`multiple_choice_option_${optIndex}_${option.position}`}
                    disabled={true}
                    onChange={() => {}}
                  />
                  <span className="text-body-large">{option.content}</span>
                </div>
              ))}
            </ul>
            <div className="mb-10" />
          </li>
        ))}
      </ol>
    </div>
  );
}

interface StepsNavigationProps {
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

function StepsNavigation({ steps, currentStep, setCurrentStep }: StepsNavigationProps) {
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex mt-8 h-10">
      {currentStep > 0 && (
        <Button icon="arrow_back_ios" variant="text" onClick={handlePrevious}>
          Previous
        </Button>
      )}
      {currentStep < steps.length - 1 && <Button onClick={handleNext}>Next</Button>}
    </div>
  );
}

export function ShowVideoPage({ form }: { form: any }) {
  const [showAnswers, setShowAnswers] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const windowSize = useWindowSize();
  const now = DateTime.now();
  const tag =
    form.watch("expiredAt") && form.watch("expiredAt").startDate < now ? (
      <Tag color="secondary">Expired</Tag>
    ) : form.watch("publishedAt") && form.watch("publishedAt").startDate > now ? (
      <Tag color="tertiaryDim">Upcoming</Tag>
    ) : (
      <Tag color="primary">Active</Tag>
    );
  const isMobileView = isMobile(windowSize.width);
  const homePath: LinkProps<Path, Params> = { to: "/education/videos" };
  const backLink = {
    to: homePath.to,
    title: "Cancel"
  };

  const getCreditTypeDisplayText = (value: string) => {
    const option = CREDIT_TYPE_OPTIONS.find((opt) => opt.value === value);
    return option ? option.displayText : value;
  };

  function DetailsContent() {
    return (
      <div className="space-y-2">
        <div className="text-title-medium">Details</div>
        <div className="text-body-medium">
          {form.watch("title") && <p>Title: {form.watch("title")}</p>}
          {form.watch("durationInSeconds") && (
            <p>
              Running Time:{" "}
              {form.watch("durationInSeconds")
                ? `${Math.floor(form.watch("durationInSeconds") / 60)} minutes ${
                    form.watch("durationInSeconds") % 60
                  } seconds`
                : "N/A"}
            </p>
          )}
          {form.watch("creditCategory") && <p>Credit Type: {getCreditTypeDisplayText(form.watch("creditCategory"))}</p>}
          {form.watch("description")?.length > 1 && <p>Description: {form.watch("description")}</p>}
        </div>

        <div className="text-title-medium pt-4">Dates</div>
        <div className="text-body-medium">
          {form.watch("updatedAt") && <p>Last Modified: {form.watch("updatedAt")?.startDate?.toLocaleDateString()}</p>}
          {form.watch("publishedAt") && (
            <p>Publication Date: {form.watch("publishedAt")?.startDate?.toLocaleDateString()}</p>
          )}
          {form.watch("expiredAt") && (
            <p>Expiration Date: {form.watch("expiredAt")?.startDate?.toLocaleDateString()}</p>
          )}
        </div>

        <div className="text-title-medium pt-4">Series</div>
        <div className="text-body-medium">
          <p>{form.watch("series")}</p>
        </div>

        {form.watch("url") && (
          <div className="w-full">
            <div className="mt-8 mb-4">
              <Divider />
            </div>
            <iframe
              className="w-full"
              style={{ height: "30vw", minHeight: "200px" }}
              src={form.watch("url")}
              allow="autoplay fullscreen picture-in-picture clipboard-write"
            />
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        )}
      </div>
    );
  }

  function AssessmentContent() {
    return (
      <div className="space-y-3">
        <div className="text-title-medium">Assessment Questions</div>
        <div className="text-body-medium">
          The following questions are included in the video assessment to earn credit.
        </div>
        <div className="flex items-center mt-4 z-0">
          <Switch selected={showAnswers} onChange={() => setShowAnswers(!showAnswers)} />
          <div className="text-body-large ml-4">Show Answers</div>
        </div>
        <div className="mt-4">
          <ReadOnlyAssessmentQuestions questions={form.watch("assessment.questions")} showAnswers={showAnswers} />
        </div>
      </div>
    );
  }

  const steps: Step[] = [
    { headline: "Details", supportingText: "Step 1", content: <DetailsContent /> },
    { headline: "Assessment", supportingText: "Step 2", content: <AssessmentContent /> }
  ];

  const handleStepClick = (index: number) => {
    setCurrentStep(index);
    window.scrollTo(0, 0);
  };

  function renderFormSteps(isMobileView: boolean, steps: Step[], currentStep: number) {
    if (isMobileView) {
      return <MobileStepDisplay steps={steps} currentStep={currentStep} handleStepClick={handleStepClick} />;
    }

    return (
      <div className="flex flex-col w-1/6 items-center">
        <Stepper
          variant="status"
          stepsList={steps}
          activeIndex={currentStep}
          onStepClick={handleStepClick}
          className="pl-1.5"
        />
      </div>
    );
  }

  const handleBack = () => {
    if (backLink) {
      window.location.href = backLink.to;
    }
  };

  const backButton = (
    <div className="flex items-center cursor-pointer" onClick={handleBack}>
      <IconButton name="arrow_back" data-testid="back" iconClassName="text-sys-brand-on-surface" />
      <span className="text-title-large">Back</span>
    </div>
  );

  return (
    <SinglePageLayout cancelButton={backButton}>
      <PageTitle
        pattern={location.pathname}
        title={`Education | Videos | Video Details | Show | ${form.watch("title")}`}
      />

      <div className={classNames("flex justify-center gap-3", isMobileView ? "pt-8" : "pt-16")}>
        <div className="flex flex-col w-1/6 min-w-[300px]">
          <ObjectHeading title={form.watch("title")} subText={`Credits: 1`} iconName="assignment" />
        </div>
        <div className="w-8/12 flex justify-end items-start pt-3">{tag}</div>
      </div>
      <div className={classNames("flex", isMobileView ? "flex-col" : "flex-row gap-3 justify-center pt-16")}>
        {isMobileView && <div className="mb-4">{renderFormSteps(isMobileView, steps, currentStep)}</div>}
        {!isMobileView && renderFormSteps(isMobileView, steps, currentStep)}
        <div className={classNames("flex flex-col", isMobileView ? "w-full" : "w-8/12")}>
          {steps[currentStep].content}
          <StepsNavigation steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
      </div>
    </SinglePageLayout>
  );
}
