import { useEffect } from "react";
import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import TextField from "@/components/TextField";
import { Controller, useForm } from "react-hook-form";
import { DateRangeType } from "react-tailwindcss-datepicker";
import { InteractionInput } from "../types";

export default function EditMessageModal({
  open,
  setOpen,
  variables,
  setVariables,
  saveInteraction,
  disabled
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  variables: InteractionInput;
  setVariables: (value: InteractionInput) => void;
  saveInteraction: () => void;
  disabled: boolean;
}) {
  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  useEffect(() => {
    if (open) {
      setValue("interactionDate", { startDate: variables.interactionDate, endDate: variables.interactionDate });
      setValue("interactionDescription", variables.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClosed={() => {
        setVariables({ ...variables, description: null });
        setOpen(false);
      }}
      headline="Edit Description"
      className="w-[490px] p-6 pr-2"
      actions={
        <>
          <Button
            variant="text"
            disabled={disabled}
            onClick={() => {
              setVariables({ ...variables, description: null });
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button trailingIcon={false} icon="check" onClick={handleSubmit(saveInteraction)} disabled={disabled}>
            Save
          </Button>
        </>
      }
    >
      <div className="overflow-auto pt-2">
        <input className="hidden"></input>
        <div className="space-y-6">
          <Controller
            name="interactionDate"
            control={control}
            rules={{
              validate: {
                startDateNotNull: (value) => value?.startDate !== null || "Date cannot be null",
                startDateNotInFuture: (value) =>
                  new Date(value?.startDate) < new Date() || "Date cannot be in the future"
              }
            }}
            render={({ field, fieldState }) => (
              <DateSelect
                label="Date *"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  const dateRange = e as DateRangeType;
                  setVariables({ ...variables, interactionDate: dateRange.startDate });
                }}
                maxDate={new Date()}
                useSingleDate
                showFooter={false}
                error={fieldState.invalid}
                errorMessage={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name="interactionDescription"
            control={control}
            rules={{ required: "Description is required" }}
            render={({ field: { onChange }, fieldState }) => (
              <TextField
                value={variables.description || undefined}
                onChangeText={(text) => {
                  onChange(text);
                  setVariables({ ...variables, description: text.trim() === "" ? null : text });
                }}
                disabled={disabled}
                required
                label="Message Content"
                type="textarea"
                rows={6}
                className="w-full"
                error={fieldState.invalid}
                errorText={fieldState.error?.message}
              />
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}
