import Icon from "../Icon";
import { MaterialNames } from "../material_symbol_names";

export interface ActionCardProps {
  iconName: MaterialNames;
  title: string;
  text: string;
  className?: string;
  actionFn?: () => void;
}

export default function ActionCard({ iconName, title, text, className, actionFn }: ActionCardProps) {
  return (
    <div
      className={`${className} flex items-center border border-b border-sys-brand-secondary-container rounded-xl p-5 max-w-[600px] cursor-pointer hover:shadow-web-2 hover:bg-sys-brand-surface-container-low`}
      data-testid="nav-card"
      onClick={actionFn}
    >
      <div className="flex items-start justify-center">
        <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
          <Icon name={iconName} size={24} />
        </div>
        <div className="flex-column items-center justify-center">
          <p className="text-title-medium mb-1">{title}</p>
          <p className="text-body-medium">{text}</p>
        </div>
      </div>
      <Icon className="flex justify-center ml-4" name="navigate_next" />
    </div>
  );
}
