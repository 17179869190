import Banner from "@/components/Banner";
import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import Progress from "@/components/Progress";
import SegmentedButton from "@/components/SegmentedButton";
import { SegmentedButtonOption } from "@/components/SegmentedButton/SegmentedButton";
import Select from "@/components/Select";
import SideSheetModal from "@/components/SideSheetModal";
import TextField from "@/components/TextField";
import { showSnackbar } from "@/lib/snackbarUtils";
import {
  CATEGORIES_QUERY,
  CREATE_INTERACTION_MUTATION,
  CREATE_GROUP_INTERACTION_MUTATION,
  CategoriesData,
  allModesData
} from "@/pages/people/[pgaId]/interactions/interactions/queries";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { PERSON_WITH_SALESFORCE_ID_QUERY } from "@/pages/people/queries";
import { PEOPLE_WITH_FACILITY_ID, REGISTRANTS_FROM_EVENT_ID, PEOPLE_FROM_IDS } from "./queries";
import { useMutation, useQuery } from "@apollo/client";
import { useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DateRangeType } from "react-tailwindcss-datepicker";
import { GroupInteraction } from "./GroupInteraction";
import { MAX_PEOPLE, MultiPersonAdder } from "./MultiPersonAdder";
import { SinglePerson } from "./SinglePerson";
import { useAiQuery } from "@/lib/aiHelpers";
import { SummaryPayload } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { formatAiSummary, guardAiResponse } from "@/pages/people/[pgaId]/interactions/_utils";
import Switch from "@/components/Switch";
import { useFlags } from "@/hooks/useFeatureFlags";
import useAmplitude from "@/hooks/useAmplitude";
import Autocomplete from "@/components/Autocomplete";
import { useIsMobile } from "@/hooks/useIsMobile";

export type Person = {
  pgaId: string;
  firstName: string;
  lastName: string;
  sectionName: string;
  facilityName: string;
  profilePhoto: string;
  selected: boolean;
  inSalesforce: boolean;
  index: number;
  salesforceExternalId: string;
  primaryFacility: { name: string; id: string };
  primarySectionAffiliation: { section: { name: string } };
  eventId?: string;
  email?: string;
};

export type Event = {
  title: string;
  id: string;
  slug: string;
  index: number;
  startsAt: string;
  endsAt: string;
};

export type Facility = {
  name: string;
  id: string;
  index: number;
  mainAddress: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
    isVerified: boolean;
  };
};

export type User = {
  name: string;
  email: string;
};

export const CreateGlobalInteractionSideSheet = ({
  sidebarOpen,
  setSidebarOpen
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { trackEvent } = useAmplitude();
  const { fetchData: generateSummary, loading: generatingAiSummary } = useAiQuery<SummaryPayload>(
    "career-consultation-summarizer"
  );
  const { fetchData: generateCategory, loading: generatingAiCategory } = useAiQuery<SummaryPayload>(
    "career-consultation-categorizer"
  );
  const defaultInteractionDate = new Date();
  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    mode: "onChange",
    defaultValues: {
      interactionPerson: "",
      interactionMethod: "In-Person",
      interactionTopic: "",
      interactionDescription: "",
      interactionDate: { startDate: defaultInteractionDate, endDate: defaultInteractionDate },
      interactionManualPerson: "",
      interactionFacility: "",
      interactionPeople: "",
      interactionPrivacy: false,
      interactionMemberFeedback: false
    }
  });
  const audienceOptions: SegmentedButtonOption[] = [
    { label: "Single", value: "single" },
    { label: "Group", value: "group" }
  ];
  const [interactionAudience, setInteractionAudience] = useState(audienceOptions[0].value);
  const [topicData, setTopicData] = useState<string[]>([]);
  const [peoplePGAId, setPeoplePGAId] = useState<String>();
  const [currentFacility, setCurrentFacility] = useState<Facility | undefined>();
  const [gatheredFacilities, setGatheredFacilities] = useState<Facility[]>([]);
  const [gatheredEvents, setGatheredEvents] = useState<Event[]>([]);
  const [eventRegistrants, setEventRegistrants] = useState<Person[]>([]);
  const [currentEvent, setCurrentEvent] = useState<Event | undefined>();
  const [ineligibleEvent, setIneligibleEvent] = useState<Event | undefined>();
  const [ineligibleFacility, setIneligibleFacility] = useState<Facility | undefined>();
  const [multiPersonMode, setMultiPersonMode] = useState(false);
  const [peopleArray, setPeopleArray] = useState<Person[]>([]);
  const [maxPeopleReached, setMaxPeopleReached] = useState(false);
  const [peopleVariables, setPeopleVariables] = useState<{ search?: string }>({});
  const [searchedForPerson, setSearchedForPerson] = useState(false);
  const [ineligiblePerson, setIneligiblePerson] = useState<Person | undefined>();
  const [variables, setVariables] = useState<InteractionInput>({
    interactionDate: defaultInteractionDate.toISOString().split("T")[0],
    mode: "In-Person",
    activityType: null,
    description: null,
    participantId: null,
    privacy_restricted: false,
    memberFeedback: false
  });

  const [createInteractionMutation, { loading: creatingInteraction }] = useMutation(CREATE_INTERACTION_MUTATION);
  const [createGroupInteractionMutation, { loading: creatingGroupInteraction }] = useMutation(
    CREATE_GROUP_INTERACTION_MUTATION
  );
  const createMutationLoading = creatingInteraction || creatingGroupInteraction;
  const flags = useFlags();
  const flagInteractionsPrivacyToggle = flags["backoffice-interactions-privacy-toggle"];
  const useAiGeneratedCategoryEnabled = flags["backoffice-interactions-categories"];
  const isMobile = useIsMobile();
  const handleAudienceChange = (audience: string) => {
    if (interactionAudience !== audience) {
      setInteractionAudience(audience);
      resetSinglePersonSearch();
      resetGroupPersonSearch();
    }
  };

  const validatePeopleField = () => {
    setTimeout(() => {
      trigger("interactionPeople");
    }, 100);
  };

  const resetSinglePersonSearch = () => {
    setSearchedForPerson(false);
    setPeoplePGAId(undefined);
    setVariables({ ...variables, participantId: null });
    setPeopleVariables({ ...peopleVariables, search: "" });
    setValue("interactionPerson", "");
  };

  const resetGroupPersonSearch = () => {
    setGatheredFacilities([]);
    setGatheredEvents([]);
    setIneligibleFacility(undefined);
    setPeopleArray([]);
    setPeopleVariables({ ...peopleVariables, search: "" });
    setSearchedForPerson(false);
    setValue("interactionFacility", "");
    setValue("interactionManualPerson", "");
    setVariables({ ...variables, participantIds: null });
  };

  const { data: allCategoriesData, loading: loadingCategories } = useQuery<CategoriesData>(CATEGORIES_QUERY, {
    fetchPolicy: "cache-first",
    skip: !sidebarOpen,
    onCompleted: (data) => {
      setTopicData(data.interactionActivityTypes);
    }
  });
  function filterOptions(string: string) {
    let filteredTopicData = allCategoriesData!.interactionActivityTypes.filter((topic) =>
      topic.toLowerCase().includes(string.toLowerCase())
    );
    setTopicData(filteredTopicData);
  }

  function addPerson(person: Person) {
    if (!person.salesforceExternalId) {
      setIneligiblePerson(person);
    } else if (!peopleArray.some((existingPerson) => existingPerson.pgaId === person.pgaId)) {
      setPeopleArray((prevPeopleArray) => {
        const updatedArray = [...prevPeopleArray, person];
        if (person.selected) {
          setVariables((prevVariables) => ({
            ...prevVariables,
            participantIds: [...(prevVariables.participantIds || []), person.salesforceExternalId]
          }));
        }
        if (updatedArray.length >= MAX_PEOPLE) {
          setMaxPeopleReached(true);
        }
        return updatedArray;
      });
    } else {
      const existingPerson = peopleArray.find((existingPerson) => existingPerson.pgaId === person.pgaId);
      if (existingPerson && person.eventId && !existingPerson.eventId) {
        existingPerson.eventId = person.eventId;
      }
      if (
        existingPerson &&
        person.primaryFacility &&
        (!existingPerson.primaryFacility || !existingPerson?.primaryFacility.id)
      ) {
        existingPerson.primaryFacility = person.primaryFacility;
      }
    }
  }

  const { loading: loadingPerson } = useQuery(PERSON_WITH_SALESFORCE_ID_QUERY, {
    variables: { input: peoplePGAId },
    onCompleted: (data) => {
      if (interactionAudience === "single") {
        setVariables({ ...variables, participantId: data.person.salesforceExternalId });
      }

      if (interactionAudience === "group") {
        data.person &&
          addPerson({
            firstName: data.person.firstName,
            lastName: data.person.lastName,
            pgaId: data.person.pgaId,
            sectionName: data.person.primarySectionAffiliation?.section?.name,
            facilityName: data.person.primaryFacility?.name,
            profilePhoto: data.person.profilePhoto,
            inSalesforce: data.person.salesforceExternalId ? true : false,
            selected: true && !maxPeopleReached,
            index: peopleArray.length,
            salesforceExternalId: data.person.salesforceExternalId,
            primaryFacility: { name: data.person.primaryFacility?.name, id: data.person.primaryFacility?.id },
            primarySectionAffiliation: { section: { name: data.person.primarySectionAffiliation?.section?.name } }
          });
      }
    },
    skip: !peoplePGAId
  });

  const { loading: facilityPeopleLoading } = useQuery(PEOPLE_WITH_FACILITY_ID, {
    variables: { input: { facilityIds: currentFacility?.id } },
    onCompleted: (data) => {
      const eligiblePeople = data.people.nodes.filter((person: Person) => person.salesforceExternalId);
      if (eligiblePeople.length === 0) {
        setIneligibleFacility(currentFacility);
      } else {
        eligiblePeople.forEach((person: Person, index: number) => {
          const selectedPeopleAmount = peopleArray.filter((person) => person.selected).length;
          const myIndex = selectedPeopleAmount + index;
          addPerson({
            firstName: person.firstName,
            lastName: person.lastName,
            pgaId: person.pgaId,
            sectionName: person.primarySectionAffiliation?.section?.name,
            facilityName: person.primaryFacility?.name,
            profilePhoto: person.profilePhoto,
            inSalesforce: true,
            selected:
              myIndex - selectedPeopleAmount >= eligiblePeople.length + selectedPeopleAmount - MAX_PEOPLE &&
              myIndex - selectedPeopleAmount - eligiblePeople.length <= MAX_PEOPLE,
            index: myIndex,
            salesforceExternalId: person.salesforceExternalId,
            primaryFacility: { name: person.primaryFacility?.name, id: person.primaryFacility?.id },
            primarySectionAffiliation: { section: { name: person.primarySectionAffiliation?.section?.name } }
          });
        });
        setGatheredFacilities([...gatheredFacilities, currentFacility!]);
      }
    },
    skip:
      !currentFacility ||
      (currentFacility && gatheredFacilities.map((facility) => facility.id).includes(currentFacility.id))
  });

  const { loading: eventRegistrantsLoading } = useQuery(REGISTRANTS_FROM_EVENT_ID, {
    variables: { eventSlug: currentEvent?.slug },
    onCompleted: (data) => {
      setEventRegistrants(data.eventRegistrants.nodes);
      if (data.eventRegistrants.nodes.length === 0) {
        setIneligibleEvent(currentEvent);
      }
    },
    skip: !currentEvent || (currentEvent && gatheredEvents.map((event) => event.id).includes(currentEvent.id))
  });

  const { loading: eventPeopleLoading } = useQuery(PEOPLE_FROM_IDS, {
    variables: { input: { pgaIds: eventRegistrants.map((registrant) => registrant.pgaId) } },
    onCompleted: (data) => {
      const eligiblePeople = data.people.nodes.filter((person: Person) => person.salesforceExternalId);
      if (eligiblePeople.length === 0) {
        setIneligibleEvent(currentEvent);
      } else {
        eligiblePeople.forEach((person: Person, index: number) => {
          const selectedPeopleAmount = peopleArray.filter((person) => person.selected).length;
          const myIndex = selectedPeopleAmount + index;
          addPerson({
            firstName: person.firstName,
            lastName: person.lastName,
            pgaId: person.pgaId,
            sectionName: person.primarySectionAffiliation?.section?.name,
            facilityName: person.primaryFacility?.name,
            profilePhoto: person.profilePhoto,
            inSalesforce: true,
            selected:
              myIndex - selectedPeopleAmount >= eligiblePeople.length + selectedPeopleAmount - MAX_PEOPLE &&
              myIndex - selectedPeopleAmount - eligiblePeople.length <= MAX_PEOPLE,
            index: myIndex,
            salesforceExternalId: person.salesforceExternalId,
            primaryFacility: { name: person.primaryFacility?.name, id: person.primaryFacility?.id },
            primarySectionAffiliation: { section: { name: person.primarySectionAffiliation?.section?.name } },
            eventId: currentEvent?.id
          });
        });
        setGatheredEvents([...gatheredEvents, currentEvent!]);
      }
    },
    skip:
      !currentEvent ||
      (currentEvent && gatheredEvents.map((event) => event.id).includes(currentEvent.id)) ||
      eventRegistrants.map((registrant) => registrant.pgaId).length === 0
  });

  const viewPerson = () => {
    navigate(`/people/${peoplePGAId}/interactions/interactions`);
  };

  async function createInteraction() {
    try {
      // Generate both AI summary and categorization concurrently before trying to create the interaction
      let categoryPromise = Promise.resolve("");
      const summaryPromise = generateSummary({
        mode: variables.mode ?? null,
        subject: null,
        comments: variables.description ?? null
      });

      if (!variables.memberFeedback && useAiGeneratedCategoryEnabled) {
        categoryPromise = generateCategory({
          mode: variables.mode ?? null,
          subject: null,
          comments: variables.description ?? null
        });
      } else {
        categoryPromise = Promise.resolve("");
      }

      const [summaryResponse, categoryResponse] = await Promise.all([summaryPromise, categoryPromise]);

      const formattedSummary = formatAiSummary(summaryResponse);
      const activityType =
        useAiGeneratedCategoryEnabled && categoryResponse?.length > 0 // if the flag is enabled and the response is not empty or an empty string we'll use the AI response.
          ? guardAiResponse(categoryResponse, allCategoriesData?.interactionActivityTypes || [])
          : variables.activityType;

      // Create interaction with generated summary and activity type
      const { data } = await createInteractionMutation({
        variables: {
          input: {
            participantId: variables.participantId,
            interactionDate: variables.interactionDate,
            mode: variables.mode,
            activityType: variables.memberFeedback ? "Member Feedback" : activityType,
            description: variables.description,
            summary: formattedSummary,
            privacyRestricted: variables.privacy_restricted
          }
        }
      });

      if (data?.createInteraction?.success) {
        showSnackbar(`Interaction added`, {
          actions: [
            <Button key="show-button" variant="text" theme="dark" onClick={viewPerson}>
              View Person
            </Button>
          ]
        });

        trackEvent("create interaction", {
          audience: interactionAudience,
          mode: variables.mode,
          activityType: activityType,
          description: variables.description,
          summary: formattedSummary,
          privacyRestricted: variables.privacy_restricted,
          participants: [variables.participantId],
          success: Boolean(summaryResponse && categoryResponse)
        });

        setSidebarOpen(false);
        resetForm();
        resetVariables();
      }
    } catch (error) {
      showSnackbar("There was an error creating the interaction. Please try again later.");
    }
  }

  async function createGroupInteraction() {
    try {
      // Generate both AI summary and categorization concurrently before trying to create the interaction
      let categoryPromise = Promise.resolve("");
      const summaryPromise = generateSummary({
        mode: variables.mode ?? null,
        subject: null,
        comments: variables.description ?? null
      });

      if (useAiGeneratedCategoryEnabled) {
        categoryPromise = generateCategory({
          mode: variables.mode ?? null,
          subject: "group interaction with " + peopleArray.map((person) => person.firstName).join(", "),
          comments: variables.description ?? null
        });
      }

      const [summaryResponse, categoryResponse] = await Promise.all([summaryPromise, categoryPromise]);

      const formattedSummary = formatAiSummary(summaryResponse);
      const activityType = useAiGeneratedCategoryEnabled
        ? guardAiResponse(categoryResponse, allCategoriesData?.interactionActivityTypes || [])
        : variables.activityType;

      // Create group interaction with generated summary and activity type
      const { data } = await createGroupInteractionMutation({
        variables: {
          input: {
            participantIds: variables.participantIds,
            interactionDate: variables.interactionDate,
            mode: variables.mode,
            activityType: variables.memberFeedback ? "Member Feedback" : activityType,
            description: variables.description,
            summary: formattedSummary
          }
        }
      });

      if (data?.createGroupInteraction?.success) {
        showSnackbar("Group Interaction added");
        trackEvent("create interaction", {
          audience: interactionAudience,
          mode: variables.mode,
          activityType: activityType,
          description: variables.description,
          summary: formattedSummary,
          privacyRestricted: variables.privacy_restricted,
          memberFeedback: variables.memberFeedback,
          participants: variables.participantIds,
          success: Boolean(summaryResponse && categoryResponse)
        });
        setSidebarOpen(false);
        resetForm();
        resetVariables();
      }
    } catch (error) {
      showSnackbar("There was an error creating the interaction. Please try again later.");
    }
  }

  const resetForm = () => {
    setValue("interactionPerson", "");
    setValue("interactionMethod", "In-Person");
    setValue("interactionDate", { startDate: defaultInteractionDate, endDate: defaultInteractionDate });
    setValue("interactionTopic", "");
    setValue("interactionDescription", "");
  };

  const resetVariables = () => {
    setVariables({
      interactionDate: defaultInteractionDate.toISOString().split("T")[0],
      mode: "In-Person",
      activityType: null,
      description: null,
      participantId: null,
      participantIds: null,
      privacy_restricted: false,
      memberFeedback: false
    });
    setSearchedForPerson(false);
    setPeoplePGAId(undefined);
    setPeopleArray([]);
    setCurrentFacility(undefined);
    setCurrentEvent(undefined);
    setInteractionAudience(audienceOptions[0].value);
  };

  const asyncLoading = generatingAiSummary || (useAiGeneratedCategoryEnabled && generatingAiCategory);

  const asyncCreateInteractionLoading = asyncLoading || createMutationLoading; // has both mutation loading states (single and group)

  const dynamicIcon = asyncCreateInteractionLoading ? <Progress size={20} color="white" /> : "check";

  const CreateInteractionActions = () => {
    if (!multiPersonMode) {
      if (interactionAudience === "single") {
        return (
          <div className="flex justify-end space-x-4">
            <Button
              onClick={handleSubmit(createInteraction)}
              variant="filled"
              icon={dynamicIcon}
              disabled={
                asyncCreateInteractionLoading ||
                (interactionAudience === "single" && searchedForPerson && !variables.participantId)
              }
            >
              Save
            </Button>
            <Button
              onClick={() => setSidebarOpen(false)}
              variant="outlined"
              disabled={
                asyncCreateInteractionLoading ||
                (interactionAudience === "single" && searchedForPerson && !variables.participantId)
              }
            >
              Cancel
            </Button>
          </div>
        );
      } else if (interactionAudience === "group") {
        return (
          <div className="flex justify-end space-x-4">
            <Button
              onClick={handleSubmit(createGroupInteraction)}
              variant="filled"
              icon={dynamicIcon}
              disabled={asyncCreateInteractionLoading || (searchedForPerson && !variables.participantIds)}
            >
              Save
            </Button>
            <Button
              onClick={() => setSidebarOpen(false)}
              variant="outlined"
              disabled={asyncCreateInteractionLoading || (searchedForPerson && !variables.participantIds)}
            >
              Cancel
            </Button>
          </div>
        );
      }
    } else if (multiPersonMode) {
      return (
        <div className="flex justify-end space-x-4">
          <Button
            onClick={() => {
              validatePeopleField();
              setMultiPersonMode(false);
            }}
            variant="outlined"
          >
            Add to Interaction
          </Button>
        </div>
      );
    }
  };

  const descriptionRef = useRef<HTMLInputElement>(null);
  const handleDescriptionFocus = () => {
    if (isMobile) {
      if (descriptionRef.current) {
        const elementRect = descriptionRef.current.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + elementRect.top - 20,
          behavior: "smooth"
        });
      }
    } else {
      null;
    }
  };

  return (
    <SideSheetModal
      isSidebarOpen={sidebarOpen}
      title={multiPersonMode ? "Add People" : "Add Interaction"}
      goBack={
        multiPersonMode
          ? () => {
              validatePeopleField();
              setMultiPersonMode(false);
            }
          : undefined
      }
      disableClose={multiPersonMode}
      actions={<CreateInteractionActions />}
      setSidebarOpen={setSidebarOpen}
    >
      {multiPersonMode && (
        <MultiPersonAdder
          peopleArray={peopleArray}
          control={control}
          variables={variables}
          setVariables={setVariables}
          setSearchedForPerson={setSearchedForPerson}
          peopleVariables={peopleVariables}
          setPeopleVariables={setPeopleVariables}
          setPeoplePGAId={setPeoplePGAId}
          setCurrentEvent={setCurrentEvent}
          loading={loadingPerson}
          eventPeopleLoading={eventPeopleLoading}
          eventRegistrantsLoading={eventRegistrantsLoading}
          facilityPeopleLoading={facilityPeopleLoading}
          maxPeopleReached={maxPeopleReached}
          setMaxPeopleReached={setMaxPeopleReached}
          ineligiblePerson={ineligiblePerson}
          setIneligiblePerson={setIneligiblePerson}
          ineligibleEvent={ineligibleEvent}
          setIneligibleEvent={setIneligibleEvent}
          ineligibleFacility={ineligibleFacility}
          setIneligibleFacility={setIneligibleFacility}
          setCurrentFacility={setCurrentFacility}
          gatheredFacilities={gatheredFacilities}
          gatheredEvents={gatheredEvents}
        />
      )}

      {!multiPersonMode && (
        <div className="flex flex-col h-full">
          <div className="mx-auto w-fit mb-2">
            <SegmentedButton
              options={audienceOptions}
              value={interactionAudience}
              onChange={(audience) => {
                audience && handleAudienceChange(audience);
              }}
              selectedColor="bg-sys-brand-secondary-container"
              textColor="text-sys-brand-on-secondary-container"
              backgroundColor="bg-sys-brand-surface-container-high"
              showCheckmark={true}
            />
          </div>

          <div
            className={`space-y-6 mb-2 flex-1 flex flex-col min-h-0 ${
              asyncCreateInteractionLoading ? "opacity-50" : ""
            }`}
          >
            {interactionAudience === "single" && (
              <SinglePerson
                control={control}
                setSearchedForPerson={setSearchedForPerson}
                setVariables={setVariables}
                variables={variables}
                setPeopleVariables={setPeopleVariables}
                peopleVariables={peopleVariables}
                setPeoplePGAId={setPeoplePGAId}
                disabled={asyncCreateInteractionLoading}
              />
            )}
            {interactionAudience === "group" && (
              <GroupInteraction
                setMultiPersonMode={setMultiPersonMode}
                peopleArray={peopleArray}
                control={control}
                disabled={asyncCreateInteractionLoading}
              />
            )}
            {(!searchedForPerson ||
              (searchedForPerson && variables.participantId) ||
              (searchedForPerson && variables.participantIds)) && (
              <div className="flex flex-col flex-1 min-h-0 space-y-4">
                <div className="space-y-4">
                  <Controller
                    name="interactionMethod"
                    control={control}
                    rules={{ required: "Method is required" }}
                    render={({ field, fieldState }) => (
                      <Select
                        label="Method"
                        required={true}
                        options={allModesData}
                        value={variables.mode || undefined}
                        className="w-full"
                        onSelect={(e) => {
                          field.onChange(e);
                          setVariables({ ...variables, mode: e });
                        }}
                        error={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        disabled={asyncCreateInteractionLoading}
                      />
                    )}
                  />

                  <Controller
                    name="interactionDate"
                    control={control}
                    rules={{
                      validate: {
                        startDateNotNull: (value) => value?.startDate !== null || "Date cannot be null",
                        startDateNotInFuture: (value) =>
                          new Date(value?.startDate) < new Date() || "Date cannot be in the future"
                      }
                    }}
                    disabled={asyncCreateInteractionLoading}
                    render={({ field, fieldState }) => (
                      <DateSelect
                        disabled={asyncCreateInteractionLoading}
                        label="Date *"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          const dateRange = e as DateRangeType;
                          setVariables({
                            ...variables,
                            interactionDate: dateRange.startDate
                          });
                        }}
                        maxDate={new Date()}
                        useSingleDate
                        showFooter={false}
                        error={fieldState.invalid}
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                  />
                  {!loadingCategories && !useAiGeneratedCategoryEnabled && (
                    <Controller
                      name="interactionTopic"
                      control={control}
                      rules={{ required: "Topic is required" }}
                      disabled={asyncCreateInteractionLoading}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <Autocomplete
                          label="Topic"
                          required
                          query={value}
                          data={topicData}
                          variant="searchableDropdown"
                          onChangeText={(string) => {
                            onChange(string);
                            filterOptions(string);
                          }}
                          reset={() => {
                            onChange("");
                            setVariables({ ...variables, activityType: null });
                          }}
                          onSelect={(value) => {
                            onChange(value);
                            setVariables({ ...variables, activityType: value });
                          }}
                          renderItem={(data) => <div>{data}</div>}
                          error={fieldState.invalid}
                          errorText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                </div>

                <div className="flex-1 flex flex-col min-h-24">
                  <Controller
                    name="interactionDescription"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        value={value}
                        disabled={asyncCreateInteractionLoading}
                        onChangeText={(text) => {
                          onChange(text);
                          text.trim() === ""
                            ? setVariables({ ...variables, description: null })
                            : setVariables({ ...variables, description: text });
                        }}
                        required
                        label="Description"
                        type="textarea"
                        rows={4}
                        className="w-full h-full"
                        error={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        ref={descriptionRef}
                        onFocus={handleDescriptionFocus}
                      />
                    )}
                  />
                </div>

                <div className="space-y-4 mt-auto">
                  {flagInteractionsPrivacyToggle && (
                    <Controller
                      name="interactionPrivacy"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex space-x-4">
                          <div className="h-fit my-auto">
                            <Switch
                              disabled={asyncCreateInteractionLoading}
                              onChange={() => {
                                const newValue = !value;
                                onChange(newValue);
                                setVariables({ ...variables, privacy_restricted: newValue });
                              }}
                            />
                          </div>

                          <div>
                            <div className="text-body-large text-sys-brand-on-surface">Make Private</div>
                            <div className="text-body-medium text-sys-brand-on-surface-variant">
                              Only your department can view
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  )}

                  <Controller
                    name="interactionMemberFeedback"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="flex space-x-4">
                        <div className="h-fit my-auto">
                          <Switch
                            disabled={asyncCreateInteractionLoading}
                            onChange={() => {
                              onChange(!value);
                              setVariables({ ...variables, memberFeedback: !value });
                            }}
                          />
                        </div>

                        <div>
                          <div className="text-body-large text-sys-brand-on-surface">Set as Member Feedback</div>
                          <div className="text-body-medium text-sys-brand-on-surface-variant">
                            Interaction will be reported as feedback
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
            {searchedForPerson && !variables.participantId && !loadingPerson && interactionAudience === "single" && (
              <Banner
                variant="error"
                description={`Interactions cannot be added for ${getValues(
                  "interactionPerson"
                )} at this time. Try selecting another person to add a interaction to.`}
                inline={true}
              />
            )}
          </div>
        </div>
      )}
    </SideSheetModal>
  );
};
