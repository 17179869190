import { Link, Path } from "@/router";
import classNames from "classnames";
import type { NavigationItemProps } from "./types";

export default function OpenNavigationItem<P extends Path>({
  active = false,
  linkClass,
  icon,
  link
}: NavigationItemProps<P>) {
  return (
    <Link
      {...link}
      className={classNames(
        "hover:!bg-gray-200 flex justify-center items-center h-14 w-14 rounded-full",
        { "bg-sys-brand-secondary-container": active },
        linkClass
      )}
    >
      {icon}
    </Link>
  );
}
