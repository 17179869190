import { MdSwitch } from "@material/web/switch/switch";
import { createComponent } from "@lit/react";
import React from "react";

const LitSwitch = createComponent({
  tagName: "md-switch",
  elementClass: MdSwitch,
  react: React,
  events: { onChange: "change" }
});

export default LitSwitch;
