import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/Divider";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { MaxPeopleBanner } from "./components/MaxPeopleBanner";
import { UnavailableEventBanner } from "./components/UnavailableEventBanner";
import { Event, Person } from "./CreateGlobalInteractionSideSheet";
import { MAX_PEOPLE } from "./MultiPersonAdder";
import { EVENTS_QUERY } from "./queries";

type EventType = {
  control: any;
  gatheredEvents: Event[];
  ineligibleEvent: Event | undefined;
  maxPeopleReached: boolean;
  peopleArray: Person[];
  peopleVariables: { search?: string };
  setIneligibleEvent: (value: Event | undefined) => void;
  setMaxPeopleReached: (value: boolean) => void;
  setCurrentEvent: (value: Event | undefined) => void;
  setPeopleVariables: (value: { search?: string }) => void;
  setSearchedForEvent: (value: boolean) => void;
  setVariables: (value: InteractionInput) => void;
  variables: InteractionInput;
};

export const MultiPersonAdderEvent = ({
  control,
  gatheredEvents,
  ineligibleEvent,
  maxPeopleReached,
  peopleArray,
  setIneligibleEvent,
  setMaxPeopleReached,
  setCurrentEvent,
  setSearchedForEvent,
  setVariables,
  variables
}: EventType) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [eventSearch, setEventSearch] = useState<string>("");

  const { data: eventData, loading: eventsLoading } = useQuery(EVENTS_QUERY, {
    variables: { query: { title: eventSearch } },
    skip: eventSearch === ""
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setEventSearch(debouncedSearchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchTerm]);

  const selectedPeople = peopleArray.filter((person) => person.selected);

  useEffect(() => {
    setMaxPeopleReached(selectedPeople.length >= MAX_PEOPLE);
  }, [peopleArray, setMaxPeopleReached, selectedPeople]);

  return (
    <div className="space-y-6">
      <p>Search for and individually add multiple registrants</p>

      <Controller
        name="interactionEvent"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            autofocus={true}
            label="Event"
            required
            query={value}
            loadingResults={eventsLoading}
            data={eventData?.events || []}
            onChangeText={(string) => {
              onChange(string);
              setDebouncedSearchTerm(string);
              setIneligibleEvent(undefined);

              if (string === "") {
                setEventSearch("");
                setSearchedForEvent(false);
                setCurrentEvent(undefined);
              }
            }}
            reset={() => {
              onChange("");
              setEventSearch("");
              setCurrentEvent(undefined);
              setSearchedForEvent(false);
              setIneligibleEvent(undefined);
            }}
            onSelect={(value) => {
              onChange("");
              setIneligibleEvent(undefined);
              setCurrentEvent({ ...value, index: gatheredEvents.length });
              setSearchedForEvent(true);
            }}
            renderItem={(data: Event) => (
              <>
                <AvatarDetails
                  title={data.title}
                  text={
                    new Date(data.startsAt).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "short",
                      day: "numeric"
                    }) +
                    " - " +
                    new Date(data.endsAt).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "short",
                      day: "numeric"
                    })
                  }
                  icon="today"
                />
              </>
            )}
            error={fieldState.invalid}
            errorText={fieldState.error?.message}
          />
        )}
      />

      {ineligibleEvent && <UnavailableEventBanner event={ineligibleEvent} />}
      {maxPeopleReached && <MaxPeopleBanner />}

      {
        <div className="space-y-8">
          {gatheredEvents
            .sort((a, b) => b.index - a.index)
            .map((event) => (
              <div key={event.id} className="space-y-1">
                <div className="text-title-small text-sys-brand-on-surface-variant">{`${event.title} (${
                  peopleArray.filter((person) => person.eventId === event.id).length
                } Registrants)`}</div>
                {peopleArray
                  .filter((person) => person.eventId === event.id)
                  .sort((a, b) => b.index - a.index)
                  .map((person, index) => (
                    <div key={index} className="space-y-1">
                      <div className="flex">
                        <AvatarDetails
                          picture={person.profilePhoto}
                          title={`${person.firstName} ${person.lastName}`}
                          text={person.facilityName}
                          secondaryText={person.sectionName}
                        />
                        <div className="ml-auto">
                          <Checkbox
                            name={`person-${index}-selected`}
                            disabled={maxPeopleReached && !person.selected}
                            checked={person.selected}
                            onChange={(p) => {
                              person.selected = p;
                              if (p) {
                                setVariables({
                                  ...variables,
                                  participantIds: [...(variables.participantIds || []), person.salesforceExternalId]
                                });
                              } else {
                                setVariables({
                                  ...variables,
                                  participantIds: (variables.participantIds || []).filter(
                                    (id: string) => id !== person.salesforceExternalId
                                  )
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <Divider />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      }
    </div>
  );
};
