import { useEffect } from "react";
import Icon from "@/components/Icon";
import Stepper from "@/components/Stepper";
import NavigationDrawerModal from "@/components/NavigationDrawerModal";
import { useSidebar } from "@/hooks/useSidebar";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";

interface MobileStepDisplayProps {
  steps: Array<{ headline: string; supportingText: string }>;
  currentStep: number;
  handleStepClick: (step: number) => void;
}

export default function MobileStepDisplay({ steps, currentStep, handleStepClick }: MobileStepDisplayProps) {
  const { isSidebarOpen, setSidebarOpen } = useSidebar();
  const windowSize = useWindowSize();

  useEffect(() => {
    document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarOpen]);

  function toggleSidebar() {
    setSidebarOpen(!isSidebarOpen);
  }

  const stepContent = (
    <div className="flex flex-col items-start w-full pl-6">
      <button onClick={toggleSidebar} aria-label="Close sidebar" className="pt-6">
        <Icon name="close" className="text-sys-brand-on-surface-variant" />
      </button>
      <div className="flex w-full pt-6 pl-2 flex-col items-start">
        <Stepper variant="status" stepsList={steps} activeIndex={currentStep} onStepClick={handleStepClick} />
      </div>
    </div>
  );

  if (isMobile(windowSize.width)) {
    return (
      <div className="flex items-start bg-sys-brand-surface -mx-6" data-testid="mobile-step-display">
        <NavigationDrawerModal isOpen={isSidebarOpen} onChange={setSidebarOpen} data-testid="drawer">
          {stepContent}
        </NavigationDrawerModal>
        <div className="flex items-center justify-between w-full p-4 pl-6">
          <div className="flex flex-col">
            <span className="text-body-large">{steps[currentStep].headline}</span>
            <span className="text-body-large">{steps[currentStep].supportingText}</span>
          </div>
          <button onClick={toggleSidebar} data-testid="open-sidebar" className="p-0 m-0">
            <Icon name="pending" className="text-sys-brand-on-surface-variant" />
          </button>
        </div>
      </div>
    );
  }
}
