import { useEffect } from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS, GET_PRODUCT_VARIANTS } from "../queries";
import { useFormContext } from "react-hook-form";
import { sortBy, orderBy } from "lodash";
import Icon from "@/components/Icon";
import { getClassification } from "../../../_utils";
type Variant = {
  id: string;
  name: string;
  priceCents: number;
  lookupKey: string;
  displayText: string;
};

type Product = {
  id: string;
  name: string;
};

type ProductData = {
  commerceProducts: {
    nodes: Product[];
  };
};

type VariantData = {
  commerceProductVariants: {
    nodes: Variant[];
  };
};

export const AddProduct = ({ index }: { index: number }) => {
  const { control, setValue: setProductValue, watch, getValues } = useFormContext<any>();
  const [variables, setVariables] = useState({ input: {} });
  const [selectedProduct, setSelectedProduct] = useState<Product>({ id: "", name: "" });
  const { data: productData } = useQuery<ProductData>(GET_PRODUCTS, { variables });

  const [variantsVariables, setVariantsVariables] = useState({ productId: selectedProduct.id });
  const { data: variantData } = useQuery<VariantData>(GET_PRODUCT_VARIANTS, {
    variables: variantsVariables
  });

  const productSearchValue = watch(`addons.${index}.product` as const);
  const variantSearchValue = watch(`addons.${index}.variant` as const);

  function selectVariant(name: string, value: Variant, setValue: (name: string, value: string) => void) {
    setValue(`addons.${index}.id`, value.lookupKey);
    setValue(name, `${value.displayText}`);
  }
  function selectProduct(name: string, value: Product, setValue: (name: string, value: string) => void) {
    setSelectedProduct({ id: value.id, name: value.name });
    setValue(name, `${value.name}`);
    setValue(`addons.${index}.variant`, "");
    setVariantsVariables({ productId: value.id });
  }

  useEffect(() => {
    setVariables(() => ({
      ...(productSearchValue ? { input: { search: productSearchValue } } : { input: {} })
    }));
  }, [productSearchValue]);

  useEffect(() => {
    setVariantsVariables((prevValue) => ({
      ...prevValue,
      ...(variantSearchValue ? { search: variantSearchValue } : {})
    }));
  }, [variantSearchValue]);

  const variantOptions =
    variantData?.commerceProductVariants?.nodes.map((variant) => ({
      ...getClassification(variant.name),
      value: variant.id,
      displayText: `${variant.name}`,
      priceCents: variant.priceCents,
      id: variant.id,
      lookupKey: variant.lookupKey
    })) || [];

  // This code sets up the Financial Adjustment product by default
  const [isFirstProductLoad, setIsFirstProductLoad] = useState<boolean>(true);
  const [isFirstVariantLoad, setIsFirstVariantLoad] = useState<boolean>(true);
  useEffect(() => {
    if (isFirstProductLoad) {
      setProductValue(`addons.${index}.product`, "Financial Adjustment");
      setIsFirstProductLoad(false);
      return;
    }
    if (isFirstVariantLoad && productData) {
      setSelectedProduct({
        id: productData?.commerceProducts.nodes[0].id as string,
        name: productData?.commerceProducts.nodes[0].name
      });
      setVariantsVariables({ productId: productData?.commerceProducts.nodes[0].id as string });
      setIsFirstVariantLoad(false);

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, variantData]);

  return (
    <div className="w-full space-y-4">
      <Controller
        name={`addons.${index}.product` as const}
        control={control}
        rules={{ required: "Please select a product" }}
        render={({ field: { onChange, value, name }, fieldState }) => (
          <Autocomplete
            label="Product"
            required
            query={value}
            data={sortBy(productData?.commerceProducts.nodes, "name") || []}
            onChangeText={onChange}
            reset={() => {
              setProductValue(`addons.${index}.product`, "");
              setProductValue(`addons.${index}.variant`, "");
              setSelectedProduct({ id: "", name: "" });
            }}
            onSelect={(value) => selectProduct(name, value, setProductValue)}
            renderItem={(data) => <>{data.name}</>}
            error={!!fieldState.error}
            errorText={fieldState.error?.message as string}
            variant="searchableDropdown"
            menuClassName="max-h-[175px]"
          />
        )}
      />
      <Controller
        name={`addons.${index}.variant` as const}
        control={control}
        rules={{ required: "Please select a product variant" }}
        render={({ field: { onChange, value, name }, fieldState }) => (
          <Autocomplete
            label="Price/Variant"
            leadingIcon="attach_money"
            disabled={!getValues(`addons.${index}.product`)}
            required
            query={value}
            data={
              sortBy(orderBy(variantOptions, "classificationNumber"), (item) => [
                item.name,
                item.classificationLetter
              ]) || []
            }
            onChangeText={onChange}
            reset={() => {
              setProductValue(`addons.${index}.variant`, "");
              setVariantsVariables({ productId: selectedProduct.id });
            }}
            onSelect={(value) => selectVariant(name, value, setProductValue)}
            renderItem={(data) => (
              <div className="flex items-center border-bottom ">
                <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
                  <Icon name="box" size={24} />
                </div>
                <div>
                  <div className="text-body-large">{data.name}</div>
                </div>
              </div>
            )}
            error={!!fieldState.error}
            errorText={fieldState.error?.message as string}
            variant="searchableDropdown"
            menuClassName="max-h-[140px]"
          />
        )}
      />
    </div>
  );
};
