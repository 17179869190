import usePageTitle from "@/hooks/usePageTitle";
import TabLayout from "../_TabLayout";
import OrdersTable from "../_components/OrdersTable";

export default function TerminationsPage() {
  usePageTitle(
    "/billing/dues-management/suspensions-and-terminations/terminations",
    "Billing | Dues Management | Suspensions and Terminations | Terminations"
  );
  return (
    <TabLayout>
      <OrdersTable defaultVariables={{ availableForTerminations: true }} />
    </TabLayout>
  );
}
