import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import Icon from "@/components/Icon";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";
import Divider from "@/components/Divider";
import ProductsDropdown from "./ProductsDropdown";
import VariantsDropdown from "./VariantsDropdown";
import CollectionDropdown from "./CollectionDropdown";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "@/pages/events/queries";
import CustomPriceInput from "./CustomPriceInput";
import { ProductsFormValues } from "@/pages/events/_form/types";
import { GET_PRODUCT_VARIANTS } from "@/pages/events/queries";

interface ProductFieldsProps {
  hasRegistrations: boolean;
  hasMultipleProducts: boolean;
  index: number;
  onRemove: () => void;
}

interface Product {
  id: string;
  name: string;
  description: string;
}

const ProductFields = ({ hasRegistrations, hasMultipleProducts, index, onRemove }: ProductFieldsProps) => {
  const { watch, setValue } = useFormContext();
  const windowSize = useWindowSize();
  const isMobileView = isMobile(windowSize.width);
  const products = watch("products");
  const productId = watch(`products.${index}.productId`);
  const eventType = watch("eventType.name");
  const productPrice = watch(`products.${index}.variantPrice`);
  const legacyId = watch("legacyId");
  const isNationalPATFeeProduct = eventType === "PAT" && index === 0;

  const {
    formState: { errors }
  } = useFormContext<ProductsFormValues>();

  const { data: productsData, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      input: isNationalPATFeeProduct
        ? { lookupKey: "pat-pga" }
        : {
            sectionId: legacyId,
            eventType: eventType?.toLowerCase()
          }
    },
    onCompleted: (data) => {
      if (isNationalPATFeeProduct && data?.commerceProducts.nodes[0]) {
        setValue(`products.${index}.productId`, data.commerceProducts.nodes[0].id);
        setValue(`products.${index}.collection`, "registration");
      }
    }
  });

  const availableProducts = productsData?.commerceProducts.nodes || [];
  const productsSupportingText = availableProducts.length > 0 ? "" : "No products available for this event type";
  const hasNoProducts = index === 0 && availableProducts.length === 0;

  const productOptions = availableProducts.map((product: Product) => ({
    value: product.id,
    displayText: product.name,
    description: product.description
  }));
  const disabled = loading || hasRegistrations || isNationalPATFeeProduct || hasNoProducts;

  const checkExternalId = (variantData: any) => {
    return variantData?.commerceProductVariants.nodes.some((variant: any) => variant.externalId);
  };

  const { data: productVariantsData } = useQuery(GET_PRODUCT_VARIANTS, {
    variables: { productId: products[index].productId },
    skip: !products[index].productId,
    onCompleted: (data) => {
      if (!checkExternalId(data)) {
        setValue(`products.${index}.collection`, "onsite");
      }
    }
  });

  const hasExternalId = checkExternalId(productVariantsData);

  function renderPriceField() {
    const renderVariantsDropdown = (isDisabled: boolean) => (
      <VariantsDropdown
        key={`variant-${index}-${productId}`}
        index={index}
        productId={productId}
        disabled={isDisabled}
      />
    );

    if (isNationalPATFeeProduct) {
      return renderVariantsDropdown(true);
    }

    if (eventType === "PAT") {
      return (
        <CustomPriceInput
          key={`custom-price-${index}-${productId}`}
          disabled={disabled}
          name={`products.${index}.variantPrice`}
          defaultValue={productPrice}
          onValueChange={(values) => {
            setValue(`products.${index}.variantPrice`, Math.round(values.floatValue));
          }}
          error={!!errors.products?.[index]?.variantPrice}
          errorText={errors.products?.[index]?.variantPrice?.message as string}
        />
      );
    }

    return renderVariantsDropdown(false);
  }

  const renderMobileLayout = () => (
    <>
      {index > 0 && (
        <div className="py-3">
          <Divider />
        </div>
      )}
      <div className="flex flex-col gap-6 w-full">
        <div className="flex items-start justify-between">
          <ProductsDropdown
            index={index}
            disabled={disabled}
            products={productOptions}
            loading={loading}
            supportingText={productsSupportingText}
          />
          {hasMultipleProducts && !isNationalPATFeeProduct && (
            <div className="h-[56px] flex items-center cursor-pointer p-2 ml-2" onClick={onRemove}>
              <Icon name="delete" />
            </div>
          )}
        </div>
        {productId && (
          <div className="grid gap-x-2 gap-y-6 grid-cols-1 sm:grid-cols-[1fr_2fr] w-full">
            {renderPriceField()}
            <CollectionDropdown
              key={`collection-${index}-${productId}`}
              index={index}
              disabled={disabled || !hasExternalId}
            />
          </div>
        )}
      </div>
    </>
  );

  const renderDesktopLayout = () => (
    <>
      <div className={classNames("relative flex lg:min-w-[680px]")}>
        <div className="w-full">
          <ProductsDropdown
            index={index}
            disabled={disabled}
            products={productOptions}
            loading={loading}
            supportingText={productsSupportingText}
          />
          {productId && (
            <div className="mt-6 grid gap-x-2 gap-y-6 grid-cols-[1fr_2fr]">
              {renderPriceField()}
              <CollectionDropdown
                key={`collection-${index}-${productId}`}
                index={index}
                disabled={disabled || !hasExternalId}
              />
            </div>
          )}
        </div>
        {hasMultipleProducts && !isNationalPATFeeProduct && (
          <div className="absolute -right-10 flex items-center md:h-[50px]">
            <div className="cursor-pointer w-6 h-6" onClick={onRemove}>
              <Icon
                name="delete"
                className={classNames({
                  "cursor-default opacity-[0.38] text-sys-brand-on-surface": disabled
                })}
              />
            </div>
          </div>
        )}
      </div>
      {hasMultipleProducts && index < products.length - 1 && (
        <div className="py-3">
          <Divider />
        </div>
      )}
    </>
  );

  return isMobileView ? renderMobileLayout() : renderDesktopLayout();
};

export default ProductFields;
