import { Controller, useFormContext } from "react-hook-form";
import { NumberFormatBase } from "react-number-format";
import TextField from "@/components/TextField/TextField";
import { currencyFormatter } from "@/lib/currencyHelpers";

interface CustomPriceInputProps {
  disabled: boolean;
  name: string;
  onValueChange: (values: any) => void;
  defaultValue: number;
  errorText: string;
  error: boolean;
}

const CustomPriceInput = ({ disabled, name, onValueChange, defaultValue, error, errorText }: CustomPriceInputProps) => {
  const { control, trigger, clearErrors } = useFormContext();

  return (
    <div data-testid="customPrice-input">
      <Controller
        control={control}
        name={name}
        render={({}) => (
          <NumberFormatBase
            disabled={disabled}
            value={defaultValue || 0}
            format={currencyFormatter}
            customInput={TextField}
            error={error}
            errorText={errorText}
            onFocus={(e) => {
              e.target.select();
            }}
            onValueChange={(values) => {
              onValueChange(values);
              trigger(name);
              if (values.floatValue && values.floatValue > 0) {
                clearErrors(name);
              }
            }}
            required
            leadingIcon="attach_money"
            label="Custom Price"
            className="w-full"
          />
        )}
      />
    </div>
  );
};

export default CustomPriceInput;
