import Tabs from "@/components/Tabs";
import { Path } from "@/router";
import { useParams } from "react-router-dom";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const { pgaId } = useParams();

  if (!pgaId) return null;

  return (
    <>
      <div className="my-8">
        <Tabs tabItems={[{ label: "Orders", link: `/people/${pgaId}/billing/orders` as Path }]} />
      </div>
      <div className="flex flex-col" data-testid="tab-content">
        {children}
      </div>
    </>
  );
}
