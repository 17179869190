import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/Divider";
import { FACILITIES_QUERY } from "./queries";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Person, Facility } from "./CreateGlobalInteractionSideSheet";
import { MAX_PEOPLE } from "./MultiPersonAdder";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { MaxPeopleBanner } from "./components/MaxPeopleBanner";
import { UnavailableFacilityBanner } from "./components/UnavailableFacilityBanner";

type FacilityType = {
  control: any;
  ineligibleFacility: Facility | undefined;
  maxPeopleReached: boolean;
  peopleArray: Person[];
  peopleVariables: { search?: string };
  gatheredFacilities: Facility[];
  setIneligibleFacility: (value: Facility | undefined) => void;
  setMaxPeopleReached: (value: boolean) => void;
  setPeopleVariables: (value: { search?: string }) => void;
  setSearchedForFacility: (value: boolean) => void;
  setVariables: (value: InteractionInput) => void;
  setCurrentFacility: (value: Facility | undefined) => void;
  variables: InteractionInput;
};

export const MultiPersonAdderFacility = ({
  control,
  ineligibleFacility,
  maxPeopleReached,
  peopleArray,
  setIneligibleFacility,
  setMaxPeopleReached,
  gatheredFacilities,
  setSearchedForFacility,
  setVariables,
  setCurrentFacility,
  variables
}: FacilityType) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [facilitySearch, setFacilitySearch] = useState<string>("");

  const { data: facilityData, loading: facilitiesLoading } = useQuery(FACILITIES_QUERY, {
    variables: { input: { search: facilitySearch } },
    skip: facilitySearch === ""
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setFacilitySearch(debouncedSearchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchTerm]);

  const selectedPeople = peopleArray.filter((person) => person.selected);

  useEffect(() => {
    setMaxPeopleReached(selectedPeople.length >= MAX_PEOPLE);
  }, [peopleArray, setMaxPeopleReached, selectedPeople]);

  return (
    <div className="space-y-6">
      <p>Search for and individually add multiple people</p>

      <Controller
        name="interactionFacility"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            autofocus={true}
            label="Facility"
            required
            query={value}
            loadingResults={facilitiesLoading}
            data={facilityData?.facilities?.nodes || []}
            onChangeText={(string) => {
              onChange(string);
              setDebouncedSearchTerm(string);

              if (string === "") {
                setFacilitySearch("");
                setCurrentFacility(undefined);
                setSearchedForFacility(false);
                setIneligibleFacility(undefined);
              }
            }}
            reset={() => {
              onChange("");
              setFacilitySearch("");
              setCurrentFacility(undefined);
              setSearchedForFacility(false);
              setIneligibleFacility(undefined);
            }}
            onSelect={(value) => {
              onChange("");
              setIneligibleFacility(undefined);
              setCurrentFacility({ ...value, index: gatheredFacilities.length });
              setSearchedForFacility(true);
            }}
            renderItem={(data: Facility) => (
              <AvatarDetails
                title={data.name}
                text={
                  data.mainAddress &&
                  `${data.mainAddress?.address1 || ""}, ${data.mainAddress?.city || ""}, ${
                    data.mainAddress?.state || ""
                  }`
                }
                icon="location_on"
              />
            )}
            error={fieldState.invalid}
            errorText={fieldState.error?.message}
          />
        )}
      />

      {ineligibleFacility && <UnavailableFacilityBanner facility={ineligibleFacility} />}
      {maxPeopleReached && <MaxPeopleBanner />}

      {
        <div className="space-y-8">
          {gatheredFacilities
            .sort((a, b) => b.index - a.index)
            .map((facility) => (
              <div key={facility.id} className="space-y-1">
                <div className="text-title-small text-sys-brand-on-surface-variant">{`${facility.name} (${
                  peopleArray.filter((person) => person.primaryFacility?.id === facility.id).length
                } Staff)`}</div>
                {peopleArray
                  .filter((person) => person.primaryFacility?.id === facility.id)
                  .sort((a, b) => b.index - a.index)
                  .map((person, index) => (
                    <div key={index} className="space-y-1">
                      <div className="flex">
                        <AvatarDetails
                          picture={person.profilePhoto}
                          title={`${person.firstName} ${person.lastName}`}
                          text={person.facilityName}
                          secondaryText={person.sectionName}
                        />
                        <div className="ml-auto">
                          <Checkbox
                            name={`person-${index}-selected`}
                            disabled={maxPeopleReached && !person.selected}
                            checked={person.selected}
                            onChange={(p) => {
                              person.selected = p;
                              if (p) {
                                setVariables({
                                  ...variables,
                                  participantIds: [...(variables.participantIds || []), person.salesforceExternalId]
                                });
                              } else {
                                setVariables({
                                  ...variables,
                                  participantIds: (variables.participantIds || []).filter(
                                    (id: string) => id !== person.salesforceExternalId
                                  )
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <Divider />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      }
    </div>
  );
};
