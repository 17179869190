import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import usePagination from "@/hooks/usePagination";
import { Controller } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { upperCase, reject, isEmpty } from "lodash";
import { useSearch } from "@/hooks/useSearch";
import { FormProvider, useForm } from "react-hook-form";
import DateSelect from "@/components/DateSelect";
import MultiSelect from "@/components/MultiSelect";
import Button from "@/components/Button";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import { DateValueType } from "react-tailwindcss-datepicker";
import FacilityAutocomplete from "../../../_components/FacilityAutocomplete";
import SectionAutocomplete from "../../../_components/SectionAutocomplete";
import ExpandedFilters from "@/components/ExpandedFilters";
import { get } from "lodash";
import { GET_CLASSIFICATIONS } from "../../../queries";
import { Variables } from "./PeopleTable";
import getActiveFilters from "@/lib/filterHelpers";
import { PeopleRow } from "./PeopleTable";
import { useParams } from "@/hooks/useSearchParams";

export type FormValues = {
  memberClassifications: string[];
  program: string[];
  sectionIds: string[];
  facilityIds: string[];
  suspensionDateRange: DateValueType | null;
  terminationDateRange: DateValueType | null;
};

export const PeopleTableFilters = ({
  defaultVariables,
  peopleData,
  setVariables,
  variables,
  data
}: {
  defaultVariables: Variables;
  peopleData: PeopleRow[];
  setVariables: any;
  variables: Variables;
  data: any;
}) => {
  const isSuspended = Object.keys(defaultVariables)[0] === "suspended";
  let { params, addSearchParam, deleteSearchParams } = useParams();
  const { renderFooter, resetPagination } = usePagination();
  const { control: controlSearch } = useSearch("name", params.name || "");

  const [selectedValues, setSelectedValues] = useState<string[]>(params.programTypes || []);
  const [selectedClassificationValues, setSelectedClassificationValues] = useState<string[]>(
    params.memberClassifications || []
  );

  const { data: classificationData } = useQuery(GET_CLASSIFICATIONS);

  const form = useForm<FormValues>();
  const { control, reset, watch } = form;

  const sectionIds = watch("sectionIds");
  const facilityIds = watch("facilityIds");

  useEffect(() => {
    setVariables((prevValue: Variables) => ({
      ...prevValue,
      sectionIds: sectionIds,
      facilityIds: facilityIds
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIds, facilityIds]);

  const isMobileDisplay = isMobile(useWindowSize().width);

  const filters = {
    suspendedDateRange: !!variables?.suspendedDateRange?.from && !!variables?.suspendedDateRange?.to,
    terminatedDateRange: !!variables?.terminatedDateRange?.from && !!variables?.terminatedDateRange?.to,
    section: params.sectionIds?.length > 0,
    facility: params.facilityIds?.length > 0,
    clasifications: selectedClassificationValues.length > 0,
    program: selectedValues.length > 0
  };

  const { clasifications, facility, ...extendedFilters } = filters;
  const { isActive, activeFilters } = getActiveFilters(filters, extendedFilters, isMobileDisplay);

  const classificationsNodes = get(classificationData, "classifications.nodes");
  const classificationDataNodes =
    classificationsNodes?.map((node: any) => ({
      value: node.name,
      label: node.name
    })) || [];
  // To DO: remove this line when date range filter will be fixed
  const isDateRangeFilterAvailable = false;
  const ClearAllButton = () => (
    <Button
      variant="text"
      onClick={() => {
        setVariables(defaultVariables);
        setSelectedValues([]);
        setSelectedClassificationValues([]);
        reset({
          suspensionDateRange: { startDate: null, endDate: null }
        });
        deleteSearchParams([
          "memberClassifications[]",
          "programTypes[]",
          "facilityIds[]",
          "sectionIds[]",
          "facility",
          "section"
        ]);
      }}
    >
      Clear All
    </Button>
  );
  return (
    <div className="flex flex-col sm:flex-row md:items-center justify-between">
      <div className="flex md:items-center gap-3 flex-row">
        <FormProvider {...form}>
          <div className="w-full sm:max-w-[320px]">
            <Controller
              name="name"
              control={controlSearch}
              render={({ field }) => (
                <SearchInput
                  placeholder="Search People"
                  query={field.value}
                  param="Name, PGA ID"
                  onChange={(e) => {
                    field.onChange(e);
                    setVariables((prevValue: Variables) => ({ ...prevValue, search: e }));
                    resetPagination();
                  }}
                />
              )}
            />
          </div>
          {!isMobileDisplay && (
            <>
              <div className="min-w-[210px]">
                <Controller
                  name="memberClassifications"
                  control={control}
                  render={() => (
                    <MultiSelect
                      label="Classification"
                      selectedValues={selectedClassificationValues}
                      options={classificationDataNodes}
                      onSelect={(value, checked) => {
                        const status = checked
                          ? [...selectedClassificationValues, value]
                          : selectedClassificationValues.filter((v) => v !== value);

                        setSelectedClassificationValues(reject(status, isEmpty));
                        setVariables((prevValue: Variables) => ({
                          ...prevValue,
                          memberClassifications: reject(status, isEmpty)
                        }));
                        addSearchParam("memberClassifications[]", reject(status, isEmpty).join(","));
                      }}
                      onClear={() => {
                        setSelectedClassificationValues([]);
                        setVariables((prevValue: Variables) => ({
                          ...prevValue,
                          memberClassifications: []
                        }));
                        deleteSearchParams("memberClassifications[]");
                      }}
                      className="w-full"
                    />
                  )}
                />
              </div>
              <div className="min-w-[210px]">
                <FacilityAutocomplete
                  sectionIds={variables?.sectionIds}
                  addSearchParam={addSearchParam}
                  deleteSearchParams={deleteSearchParams}
                  params={params}
                />
              </div>
            </>
          )}
          <ExpandedFilters
            activeFilters={activeFilters}
            items={[
              {
                component: (
                  <Controller
                    name="memberClassifications"
                    control={control}
                    render={() => (
                      <MultiSelect
                        label="Classification"
                        selectedValues={selectedClassificationValues}
                        options={classificationDataNodes && classificationDataNodes}
                        onSelect={(value, checked) => {
                          const status = checked
                            ? [...selectedClassificationValues, value]
                            : selectedClassificationValues.filter((v) => v !== value);
                          setSelectedClassificationValues(reject(status, isEmpty));
                          setVariables((prevValue: Variables) => ({
                            ...prevValue,
                            memberClassifications: reject(status, isEmpty)
                          }));
                          addSearchParam("memberClassifications[]", reject(status, isEmpty).join(","));
                        }}
                        onClear={() => {
                          setSelectedClassificationValues([]);
                          setVariables((prevValue: Variables) => ({
                            ...prevValue,
                            memberClassifications: []
                          }));
                          deleteSearchParams("memberClassifications[]");
                        }}
                        className="w-full"
                      />
                    )}
                  />
                ),
                onlyMobile: true
              },
              {
                component: (
                  <FacilityAutocomplete
                    sectionIds={variables?.sectionIds}
                    addSearchParam={addSearchParam}
                    deleteSearchParams={deleteSearchParams}
                    params={params}
                  />
                ),
                onlyMobile: true
              },
              {
                component: (
                  <Controller
                    name="program"
                    control={control}
                    render={() => (
                      <MultiSelect
                        label="Program"
                        selectedValues={selectedValues}
                        options={[
                          { value: "MEMBER", label: "Member" },
                          { value: "ASSOCIATE", label: "Associate" },
                          { value: "AFFILIATE", label: "Affiliate" }
                        ]}
                        onSelect={(value, checked) => {
                          const status = checked
                            ? [...selectedValues, value]
                            : selectedValues.filter((v) => v !== value);
                          const statusValues = status.map((status) => upperCase(status));
                          setSelectedValues(reject(status, isEmpty));
                          setVariables((prevValue: Variables) => ({
                            ...prevValue,
                            programTypes: reject(statusValues, isEmpty)
                          }));
                          addSearchParam("programTypes[]", reject(status, isEmpty).join(","));
                        }}
                        onClear={() => {
                          setSelectedValues([]);
                          setVariables((prevValue: Variables) => ({
                            ...prevValue,
                            programTypes: []
                          }));
                          deleteSearchParams("programTypes[]");
                        }}
                        className="w-full"
                      />
                    )}
                  />
                )
              },
              ...(isDateRangeFilterAvailable
                ? [
                    ...(isSuspended
                      ? [
                          {
                            component: (
                              <Controller
                                name="suspensionDateRange"
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <DateSelect
                                      placeholder="Select Dates"
                                      label="Suspension Date Range"
                                      value={field.value}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        if (typeof e === "object" && e !== null) {
                                          setVariables((prevValue: Variables) => ({
                                            ...prevValue,
                                            suspendedDateRange: {
                                              from: e.startDate,
                                              to: e.endDate
                                            }
                                          }));
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />
                            )
                          }
                        ]
                      : [
                          {
                            component: (
                              <Controller
                                name="terminationDateRange"
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <DateSelect
                                      placeholder="Select Dates"
                                      label="Termination Date Range"
                                      value={field.value}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        if (typeof e === "object" && e !== null) {
                                          setVariables((prevValue: Variables) => ({
                                            ...prevValue,
                                            terminatedDateRange: {
                                              from: e.startDate,
                                              to: e.endDate
                                            }
                                          }));
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />
                            )
                          }
                        ])
                  ]
                : []),
              {
                component: (
                  <SectionAutocomplete
                    addSearchParam={addSearchParam}
                    deleteSearchParams={deleteSearchParams}
                    params={params}
                  />
                )
              },
              {
                component: <>{isActive && <ClearAllButton />}</>,
                onlyMobile: true
              }
            ]}
          />
          {isActive && (
            <div className="hidden sm:block" data-testid="clear-all-button">
              <ClearAllButton />
            </div>
          )}
        </FormProvider>
      </div>
      <div className="mt-3 sm:mt-0">
        {renderFooter(data?.people, {
          totalCount: data?.people?.totalCount || 0,
          pageCount: peopleData.length,
          variant: "compact"
        })()}
      </div>
    </div>
  );
};

export default PeopleTableFilters;
