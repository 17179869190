import { useEffect, useState } from "react";
import usePageTitle from "@/hooks/usePageTitle";
import EmptyState from "@/components/EmptyState";
import Table, { TableDetails, type Row } from "@/components/Table";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useQuery } from "@apollo/client";
import { EVENTS_QUERY } from "./queries";
import { EventsData, Event, EventsQueryVariables } from "./types";
import { toLocaleDate } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { buildMyPgaUrl } from "@/lib/urlUtils";
import { onActionMenuItemClick } from "./_utils";
import useActionMenu, { actionId, MenuItem } from "@/hooks/useActionMenu";
import IconButton from "@/components/IconButton";
import { cond, matches, snakeCase } from "lodash";
import Tag from "@/components/Tag";
import SearchInput from "@/components/SearchInput";
import DateSelect from "@/components/DateSelect";
import { Controller } from "react-hook-form";
import { useSearch } from "@/hooks/useSearch";
import Button from "@/components/Button";
import { useNavigate, Path } from "@/router";
import MultiSelect from "@/components/MultiSelect";
import { isEmpty } from "lodash";
import { useParams } from "@/hooks/useSearchParams";
import { ColumnSort } from "@tanstack/react-table";
import useCapabilities, { PERMISSION } from "@/hooks/useCapabilities";
import CancelEventModal, { CancelEvent } from "./[eventSlug]/(tabbed)/cancellations/_components/CancelEventModal";

const renderStatus = cond([
  [
    matches("Active"),
    () => (
      <Tag color="tertiaryDim" testId="active">
        Active
      </Tag>
    )
  ],
  [
    matches("Draft"),
    () => (
      <Tag color="secondary" testId="draft">
        Draft
      </Tag>
    )
  ],
  [
    matches("Canceled"),
    () => (
      <Tag color="surface" testId="cancelled">
        Canceled
      </Tag>
    )
  ],
  [
    matches("Completed"),
    () => (
      <Tag color="primary" testId="completed">
        Completed
      </Tag>
    )
  ]
]);

const columnHeaders = {
  title: "Event Name",
  registrationCount: "Registrations",
  updatedAt: "Modified",
  startsAt: "Start Date",
  endsAt: "End Date",
  sessionCount: "Sessions",
  status: "Stage"
};

export default function EventPage() {
  usePageTitle("/events", "Events");
  let { params, searchParamsUrl, addSearchParam, deleteSearchParams } = useParams();
  if (params.dateRange) {
    params.dateRange = params.dateRange && { from: params.dateRange.split(",")[0], to: params.dateRange.split(",")[1] };
  }

  const { hasPermission } = useCapabilities();
  const searchField = "title";
  const { searchValue, control, reset } = useSearch(searchField, params.title || "");
  const [variables, setVariables] = useState<EventsQueryVariables>({ query: params || {} });
  const initialStatusValues = ["Active", "Draft"];

  const defaultStatusValues = params.status ? params.status : isEmpty(params) ? initialStatusValues : [];

  const [selectedValues, setSelectedValues] = useState<string[]>(defaultStatusValues);
  const filters = [variables.query?.dateRange?.from && variables.query?.dateRange?.to, selectedValues.length > 0];

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const { Menu, setMenu } = useActionMenu();
  const navigate = useNavigate();
  const pathTemplate = "/events/:eventSlug/registrations";
  function onClickRow(row: Row<Event>) {
    navigate(`${pathTemplate}?return_to=/events&${searchParamsUrl}` as Path, {
      params: { eventSlug: row.original.slug }
    });
  }

  function onClickEdit(row: Row<Event>) {
    navigate(`/events/${row.original.slug}/edit` as Path, {
      state: { event: row.original }
    });
    window.scrollTo(0, 0);
  }

  function onClickDuplicate(row: Row<Event>) {
    navigate("/events/new", {
      state: { event: row.original }
    });
    window.scrollTo(0, 0);
  }

  function onClickCancel(row: Row<Event>) {
    setSelectedEvent(row.original);
    setIsCancelModalOpen(true);
  }

  const [orderBy, setOrderBy] = useState<ColumnSort[]>([]);
  const filterActive = filters.some((value) => !!value);

  useEffect(() => {
    const { id, desc } = orderBy[0] || {};
    setVariables((prevVariables) => ({
      query: {
        ...prevVariables.query,
        title: searchValue || undefined
      },
      ...(id && {
        orderBy: {
          field: snakeCase(id).toUpperCase(),
          direction: desc ? "DESC" : "ASC"
        }
      })
    }));
  }, [searchValue, orderBy]);

  useEffect(() => {
    if (isEmpty(params)) {
      addSearchParam("status[]", initialStatusValues.join(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, loading, refetch } = useQuery<EventsData>(EVENTS_QUERY, {
    variables: {
      ...variables,
      query: {
        ...variables.query,
        status: selectedValues.length > 0 ? selectedValues : undefined
      }
    }
  });

  const eventData = data?.events.map((event: Event) => ({
    ...event,
    startsAt: toLocaleDate(event.startsAt),
    endsAt: toLocaleDate(event.endsAt),
    updatedAt: toLocaleDate(event.updatedAt)
  }));

  const handleCreateEventClick = () => {
    navigate("/events/new");
  };

  const createEventButton = () => (
    <Button icon="add" onClick={handleCreateEventClick} testId="create-event-button">
      Create Event
    </Button>
  );
  let buttonComps: (() => JSX.Element)[] = [];
  if (hasPermission(PERMISSION.manageEvents)) {
    buttonComps.push(createEventButton);
  }

  const handleSort = (updaterOrValue: any) => {
    const sortState = typeof updaterOrValue === "function" ? updaterOrValue([]) : updaterOrValue;
    const { id, desc } = sortState[0] || {};
    setOrderBy([{ id, desc }]);
  };

  return (
    <AppLayout>
      <div className="pl-0 sm:pl-4">
        <PageHeading title="Events" />
      </div>
      <Menu />
      <Table
        data={eventData || []}
        loading={loading}
        onClickRow={onClickRow}
        onSortingChange={handleSort}
        sortingState={orderBy}
        serverSideSorting={true}
        renderDetails={() => (
          <TableDetails
            body="All events, including those at the Section level, can be viewed and managed below."
            ButtonComps={buttonComps}
          />
        )}
        renderHeader={() => (
          <div className="flex flex-row justify-between items-center pr-4 md:pr-0">
            <div className="flex flex-col sm:flex-row justify-between gap-4 md:w-auto">
              <div className="w-full sm:max-w-[260px]">
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <SearchInput
                      placeholder="Search Event"
                      query={field.value}
                      param={columnHeaders[searchField]}
                      onClear={() => {
                        deleteSearchParams("title");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        addSearchParam("title", e);
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="status"
                  control={control}
                  render={() => (
                    <MultiSelect
                      label="Stage"
                      selectedValues={selectedValues}
                      options={[
                        { value: "Active", label: "Active" },
                        { value: "Cancelled", label: "Canceled" },
                        { value: "Completed", label: "Completed" },
                        { value: "Draft", label: "Draft" }
                      ]}
                      onSelect={(value, checked) => {
                        const status = checked ? [...selectedValues, value] : selectedValues.filter((v) => v !== value);
                        setSelectedValues(status);
                        const { query } = variables;
                        setVariables({
                          query: {
                            ...query,
                            status
                          }
                        });
                        addSearchParam("status[]", status.join(","));
                      }}
                      onClear={() => {
                        setSelectedValues([]);
                        deleteSearchParams("status[]");
                      }}
                    />
                  )}
                />
              </div>
              <div className="w-[270px] sm:w-[300px] max-w-[300px] h-[56px]">
                <Controller
                  name="dates"
                  control={control}
                  render={({ field }) => (
                    <DateSelect
                      label="Session Date Range"
                      placeholder="All Session Dates"
                      value={
                        params.dateRange
                          ? { startDate: new Date(params.dateRange.from), endDate: new Date(params.dateRange.to) }
                          : field.value
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        const { query } = variables;
                        if (typeof e === "object" && e !== null) {
                          setVariables({
                            query: {
                              ...query,
                              dateRange: { from: e.startDate, to: e.endDate }
                            }
                          });
                          addSearchParam("dateRange", [e.startDate, e.endDate].join(","));
                        }
                      }}
                    />
                  )}
                />
              </div>
              {filterActive && (
                <div className="ml-1 flex items-center" data-testid="clear-all-button">
                  <Button
                    variant="text"
                    onClick={() => {
                      setVariables(params);
                      setSelectedValues([]);
                      reset({ dates: { startDate: null, endDate: null } as any });
                      deleteSearchParams(["dateRange", "status[]"]);
                    }}
                  >
                    Clear All
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        columns={[
          {
            accessorKey: "title",
            header: columnHeaders["title"],
            enableSorting: true,
            size: 500,
            meta: {
              className: getStickyColumnClasses(!!eventData?.length)
            }
          },
          { accessorKey: "registrationCount", header: columnHeaders["registrationCount"], enableSorting: true },
          { accessorKey: "updatedAt", header: columnHeaders["updatedAt"], enableSorting: true },
          { accessorKey: "startsAt", header: columnHeaders["startsAt"], enableSorting: true },
          { accessorKey: "endsAt", header: columnHeaders["endsAt"], enableSorting: true },
          { accessorKey: "sessionCount", header: columnHeaders["sessionCount"], enableSorting: true },
          {
            cell: (cellProps) => <>{renderStatus(cellProps.row.original.status)}</>,
            header: columnHeaders["status"],
            id: "stage"
          },
          {
            cell(cellProps) {
              const baseItems: MenuItem[] = [
                {
                  label: "Manage Event",
                  itemClassName: "w-[240px]",
                  onClick: () => {
                    onClickRow(cellProps.row);
                  }
                },
                "divider",
                {
                  label: "View Published Page",
                  iconName: "open_in_new",
                  itemClassName: "w-[240px]",
                  onClick: () => {
                    open(buildMyPgaUrl("member/events", cellProps.row.original.slug), "_blank");
                  }
                }
              ];

              const cancelAction = {
                label: "Cancel Event",
                onClick: () => {
                  onClickCancel(cellProps.row);
                }
              };

              const editAction = {
                label: "Edit Event",
                onClick: () => {
                  onClickEdit(cellProps.row);
                }
              };

              const duplicateAction = {
                label: "Duplicate Event",
                onClick: () => {
                  onClickDuplicate(cellProps.row);
                }
              };

              const { manageEvents = false } = cellProps.row.original.section.permissions || {};
              const items = manageEvents ? [cancelAction, editAction, duplicateAction, ...baseItems] : baseItems;

              return (
                items.length > 0 && (
                  <div
                    className="text-right flex flex-row justify-end"
                    data-testid="menu-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      id={actionId(cellProps.row.id)}
                      onClick={() => onActionMenuItemClick({ setMenu, rowId: cellProps.row.id, items })}
                      data-testid="actions"
                      title="actions"
                      name="more_vert"
                      iconClassName="font-extrabold"
                      skipMargin
                    />
                  </div>
                )
              );
            },
            id: "actions",
            size: 100
          }
        ]}
        renderEmptyState={() => (
          <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="today" />
        )}
      />
      {isCancelModalOpen && (
        <CancelEventModal
          event={selectedEvent as unknown as CancelEvent}
          onClosed={() => {
            setIsCancelModalOpen(false);
            setSelectedEvent(null);
            refetch();
          }}
        />
      )}
    </AppLayout>
  );
}
