import { gql, TypedDocumentNode } from "@apollo/client";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { Address, PhoneNumber } from "@/types";
import { PHONE_NUMBER_FRAGMENT, ADDRESS_FRAGMENT } from "@/fragments";
import { PageInfo, PaginationVariables } from "@/components/Pagination";

export type FacilitySearchResult = {
  id: string;
  name: string;
  mainAddress: Address | null;
  phone: PhoneNumber | null;
  parentCompany: { id: string; name: string } | null;
  highestRankedEmployment: { id: string; person: { id: string; displayName: string } } | null;
};

export type FacilitiesSearchQueryResponse = {
  facilities: { nodes: FacilitySearchResult[]; pageInfo: PageInfo; totalCount: number };
};

export type FacilitiesSearchQueryInput = { search: string; sectionIds?: string[] };

export type FacilitiesSearchQueryVariables = PaginationVariables & { input?: FacilitiesSearchQueryInput };

export const FACILITIES_SEARCH_QUERY: TypedDocumentNode<
  FacilitiesSearchQueryResponse,
  FacilitiesSearchQueryVariables
> = gql`
  query facilitiesSearchQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $input: BackofficeCRMFacilitiesInput!
  ) {
    facilities(first: $first, last: $last, before: $before, after: $after, input: $input) {
      nodes {
        id
        name
        mainAddress {
          ...AddressFragment
        }
        phone {
          ...PhoneNumberFragment
        }
        parentCompany {
          id
          name
        }
        highestRankedEmployment {
          id
          person {
            id
            displayName
          }
        }
      }
      ...PageInfo
    }
  }
  ${ADDRESS_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;
