import { gql, TypedDocumentNode } from "@apollo/client";
import { PERSON_DETAILS_FRAGMENT, MEMBERSHIP_BAND_FRAGMENT } from "@/fragments";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { PageInfo, PaginationVariables } from "@/components/Pagination";
import {
  CHANGE_REQUEST_CHANGE_FRAGMENT,
  CHANGE_REQUEST_FRAGMENT,
  PERSON_FRAGMENT,
  Node,
  ChangeRequestStatus
} from "@/pages/sections/[sectionId]/change-requests/queries";

export const PERSON_OVERVIEW_QUERY = gql`
  query GetPersonOverview($pgaId: ID!) {
    person(pgaId: $pgaId) {
      ...PersonDetailsFragment
      age
      membershipSince
      duesBalance
      latestProgram {
        id
        status
        programType {
          id
          name
        }
        band {
          id
          startedOn
          expiresOn
          createdReason
          classification {
            id
            name
          }
        }
      }
      committeeHistory {
        endDate
      }
      prospectProgram {
        id
        membershipInterestForm {
          id
        }
        qualifyingLevel {
          status
          expirationDate: expiredAt
          endDate: completedAt
        }
        playingAbilityTest {
          status
          startDate: testDate
        }
        backgroundCheck {
          status
        }
      }
      educationLatestCertification {
        startDate
        endDate
        programDescription
        careerPath
        subscription {
          expired
        }
      }
      educationPgmProgramHistory {
        curriculumVersion
        startDate
        endDate
        level
        levelCode
        careerPath
        programType
        completed
      }
    }
  }
  ${PERSON_DETAILS_FRAGMENT}
`;

export const PERSON_DETAILS_QUERY = gql`
  query GetPersonDetails($pgaId: ID!) {
    person(pgaId: $pgaId) {
      ...PersonDetailsFragment
      age
      dob {
        day
        month
        year
      }
      ssn {
        last4
        full
      }
      latestProgram {
        id
        status
        programType {
          id
          name
        }
        band {
          id
          classification {
            id
            name
            description
          }
        }
      }
      primarySectionAffiliation {
        id
        startAt
      }
    }
  }
  ${PERSON_DETAILS_FRAGMENT}
`;

export const INSIGHTS_QUERY = gql`
  query GetInsights($pgaId: ID!) {
    insights(pgaId: $pgaId) {
      person {
        id
        firstName
        lastName
        gender
        employments {
          id
          endedOn
          startedOn
          isPrimary
          title
          facility {
            id
            name
            section {
              id
              name
            }
          }
        }
        membershipSince
        duesBalance
        latestProgram {
          id
          status
          programType {
            id
            name
          }
          band {
            id
            startedOn
            expiresOn
            terminatesOn
            createdReason
            classification {
              id
              name
            }
          }
        }
        prospectProgram {
          id
          backgroundCheck {
            status
            startDate: screenedAt
            expirationDate: expiredAt
          }
          playingAbilityTest {
            status
            startDate: testDate
            expirationDate: expiredAt
            score
            targetScore
          }
          qualifyingLevel {
            status
            startDate
            expirationDate: expiredAt
            endDate: completedAt
          }
        }
      }
    }
  }
`;

export const GET_MEMBERSHIP_DETAILS_QUERY = gql`
  query GetMembershipDetails($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      membershipSince
      duesBalance
      bands {
        ...MembershipBandFragment
      }
      latestProgram {
        id
        status
        programType {
          id
          name
        }
        band {
          id
          classification {
            id
            name
          }
        }
      }
    }
  }
  ${MEMBERSHIP_BAND_FRAGMENT}
`;

export const GET_EMPLOYMENT_DETAILS_QUERY = gql`
  query GetEmploymentDetails($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      employments {
        endedOn
        facility {
          id
          name
        }
        id
        isPrimary
        jobClassification
        sectionApprovalDate
        startedOn
        title
      }
    }
  }
`;

export const GET_SECTION_AFFILIATION_HISTORY_QUERY = gql`
  query GetSectionAffiliationHistory($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      primarySection {
        id
        name
      }
      sectionAffiliations {
        id
        primary
        startAt
        endAt
        section {
          id
          name
        }
      }
    }
  }
`;

export const GET_PERSON_CHANGE_REQUESTS_QUERY: TypedDocumentNode<
  { person: { changeRequests: { nodes: Node[]; pageInfo: PageInfo; totalCount: number } } },
  { pgaId: string; stage?: ChangeRequestStatus } & PaginationVariables
> = gql`
  query GetPersonChangeRequests(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $pgaId: ID!
    $stage: BackofficeChangeRequestStatus
  ) {
    person(pgaId: $pgaId) {
      id
      changeRequests(stage: $stage, first: $first, after: $after, last: $last, before: $before) {
        nodes {
          ...ChangeRequest
          submittedAt
          ...Change
        }
        ...PageInfo
      }
    }
  }

  ${PERSON_FRAGMENT}
  ${CHANGE_REQUEST_FRAGMENT}
  ${CHANGE_REQUEST_CHANGE_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const GET_PROSPECT_PROGRAM_QUERY = gql`
  query GetProspectProgram($pgaId: ID!) {
    person(pgaId: $pgaId) {
      accountCreatedAt
      prospectProgram {
        id
        backgroundCheck {
          status
          startDate: screenedAt
          expirationDate: expiredAt
        }
        playingAbilityTest {
          status
          startDate: testDate
          expirationDate: expiredAt
          score
          targetScore
        }
        qualifyingLevel {
          status
          startDate
          expirationDate: expiredAt
          endDate: completedAt
        }
        membershipInterestForm {
          id
          startedAt
          reasonForBecomingMember
          howTheyHeard
        }
        latestAssociateApplication {
          status
          startDate: startDt
          endDate: endDt
        }
        eligibleEmployment {
          id
          startDate: startedOn
          endDate: endedOn
        }
      }
    }
  }
`;

export const GET_COMMITTEE_HISTORY_QUERY = gql`
  query GetCommitteeHistory($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      committeeHistory {
        committeeName
        position
        startDate
        endDate
      }
    }
  }
`;
