import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Controller } from "react-hook-form";
import { useSearch } from "@/hooks/useSearch";
import Table, { TableDetails } from "@/components/Table";
import Button from "@/components/Button";
import SearchInput from "@/components/SearchInput";
import useActionMenu from "@/hooks/useActionMenu";
import usePagination from "@/hooks/usePagination";
import { EventRegistrants, Variables, RegistrationStatus } from "../types";
import { EVENT_QUERY, EVENT_REGISTRANTS_QUERY } from "../../../queries";
import { RegistrantRow, EventRegistrantNode } from "../types";
import { SortingState } from "@tanstack/react-table";
import FinalizeScoresConfirmationModal from "./FinalizeScoresConfirmationModal";
import FinalizeScoresErrorDialog from "./FinalizeScoresErrorModal";
import UpdateStatusDialog from "./UpdateStatusModal";
import { getColumns } from "./TableColumns";
import EmptyState from "@/components/EmptyState";
import {
  UPDATE_EVENT_SESSIONS_REGISTRATION_ATTENDANCES_MUTATION,
  PAT_UPSET_SUBMISSION_MUTATION
} from "../../attendance/queries";
import { showSnackbar } from "@/lib/snackbarUtils";
import { EventOutletContext } from "../../_layout";
import getAge from "@/lib/ageHelper";
import pluralize from "@/lib/pluralize";
import Chip from "@/components/Chip";

interface RegistrantsTableProps {
  eventSlug: string;
}
export type UpdateStatus = {
  status: string;
  note: string;
  id: string;
};

const mapRegistrantsData = (data: EventRegistrantNode[]) =>
  data.map(({ node: { sessionRegistrations, patSubmission, customFields, ...node } }) => ({
    ...node,
    score_18_1: patSubmission?.roundOne?.roundScore || "",
    meets_qualifying_score_18_1: patSubmission?.roundOne?.meetsQualifyingScore || false,
    score_18_2: patSubmission?.roundTwo?.roundScore || "",
    meets_qualifying_score_18_2: patSubmission?.roundTwo?.meetsQualifyingScore || false,
    score_total: patSubmission?.totalScore || null,
    attended: sessionRegistrations?.[0]?.attended,
    gender: customFields?.gender || null,
    age: getAge(customFields?.date_of_birth),
    status: patSubmission?.result || null,
    additionalStatus: patSubmission?.additionalStatus,
    note: patSubmission?.note,
    sessionRegistrationId: sessionRegistrations?.[0]?.id
  }));

export default function RegistrantsTable({ eventSlug }: RegistrantsTableProps) {
  const { paginationVariables, renderFooter, resetPagination } = usePagination();
  const { setErrorMessage } = useOutletContext<EventOutletContext>();
  const searchField = "search";
  const { searchValue, control, reset } = useSearch(searchField);
  const [variables, setVariables] = useState<Variables>({ eventSlug });
  const filterActive = variables.search;

  const [finalizeScoresDialogOpen, setFinalizeScoresDialogOpen] = useState(false);
  const [finalizeScoresErrorDialogOpen, setFinalizeScoresErrorDialogOpen] = useState(false);
  const [updateStatusDialogOpen, setUpdateStatusDialogOpen] = useState(false);
  const [targetEventRegistration, setTargetEventRegistration] = useState<RegistrantRow | null>(null);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "participant",
      desc: false
    }
  ]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const rowSelected = Object.keys(rowSelection).length;
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);
  const { Menu, setMenu } = useActionMenu();

  const { data: registrantsData, loading: queryLoading } = useQuery<EventRegistrants>(EVENT_REGISTRANTS_QUERY, {
    variables: { ...variables, ...paginationVariables, status: RegistrationStatus.REGISTERED }
  });
  const [selectedOrdersQuery, { data: allSelectedData, loading: allSelectedLoading }] =
    useLazyQuery<EventRegistrants>(EVENT_REGISTRANTS_QUERY);

  const [updateEventSessionsRegistrationAttendances] = useMutation(
    UPDATE_EVENT_SESSIONS_REGISTRATION_ATTENDANCES_MUTATION
  );
  const [patUpsertSubmission] = useMutation(PAT_UPSET_SUBMISSION_MUTATION);

  useEffect(() => {
    resetPagination();
    setVariables((prev) => ({
      ...(searchValue ? { ...prev, search: searchValue } : { eventSlug })
    }));
  }, [searchValue, eventSlug, resetPagination]);

  const [registrations, setRegistrations] = useState<RegistrantRow[]>([]);

  useEffect(() => {
    const row = mapRegistrantsData(
      (isSelectedOnly ? allSelectedData?.eventRegistrants.edges : registrantsData?.eventRegistrants.edges) || []
    );
    setRegistrations(row);
  }, [isSelectedOnly, registrantsData, allSelectedData]);

  useEffect(() => {
    if (rowSelected === 0 && isSelectedOnly) {
      setIsSelectedOnly(false);
    }
  }, [rowSelected, isSelectedOnly]);

  const hasRegistrations = registrations.length > 0;

  const handleFinalizeScores = () => {
    const hasEmptyScores = registrations.some(
      (registrant) => registrant.score_18_1 === "" || registrant.score_18_2 === ""
    );
    if (hasEmptyScores) {
      setFinalizeScoresErrorDialogOpen(true);
    } else {
      setFinalizeScoresDialogOpen(true);
    }
  };

  const handleBulkAttendanceUpdate = async (present: boolean) => {
    const selectedIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    const selectedSessionRegistrationIds = selectedIds.map(
      (id) => registrations.find((reg) => reg.id === id)?.sessionRegistrationId
    );
    if (selectedIds.length > 0) {
      const previousRegistrations = [...registrations];
      const updatedRegistrations = registrations.map((reg) =>
        selectedIds.includes(reg.id) ? { ...reg, attended: present } : reg
      );
      setRegistrations(updatedRegistrations);

      try {
        const response = await updateEventSessionsRegistrationAttendances({
          variables: {
            attendances: selectedSessionRegistrationIds.map((id) => ({
              eventSessionRegistrationId: id,
              attended: present
            }))
          },
          refetchQueries: [
            {
              query: EVENT_QUERY,
              variables: { slug: eventSlug }
            }
          ]
        });

        if (response.data?.updateEventSessionsRegistrationAttendances?.success) {
          showSnackbar("Bulk attendance updated successfully.");
        } else {
          setRegistrations(previousRegistrations);
          showSnackbar(
            response.data?.updateEventSessionsRegistrationAttendances?.message || "Failed to update bulk attendance."
          );
        }
      } catch (error) {
        setRegistrations(previousRegistrations);
        showSnackbar("Failed to update bulk attendance.");
      }
    }
  };

  const handleAttendanceUpdate = (id: string, value: string | null) => {
    const attended = value === "true" ? true : value === "false" ? false : null;

    setRegistrations((prevRegistrations) => {
      const updatedRegistrations = prevRegistrations.map((reg) =>
        reg.id === id ? { ...reg, attended, attendance: attended ?? false } : reg
      );
      return updatedRegistrations;
    });

    const previousRegistrations = [...registrations];

    updateEventSessionsRegistrationAttendances({
      variables: {
        attendances: [
          {
            eventSessionRegistrationId: id,
            attended
          }
        ]
      },
      refetchQueries: [
        {
          query: EVENT_QUERY,
          variables: { slug: eventSlug }
        }
      ]
    })
      .then((response) => {
        if (!response.data?.updateEventSessionsRegistrationAttendances?.success) {
          setRegistrations(previousRegistrations);
          showSnackbar(
            response.data?.updateEventSessionsRegistrationAttendances?.message || "Failed to update attendance."
          );
        } else {
          showSnackbar("Attendance updated successfully.");
        }
      })
      .catch(() => {
        setRegistrations(previousRegistrations);
        showSnackbar("Failed to update attendance.");
      });
  };

  const handleScoreUpdate = (field: string, id: string, value: string) => {
    patUpsertSubmission({
      variables: {
        input: {
          eventRegistrationId: id,
          [field]: field === "roundOneScore" || field === "roundTwoScore" ? Number(value) : value
        }
      },
      refetchQueries: [
        {
          query: EVENT_REGISTRANTS_QUERY,
          variables: { ...variables, ...paginationVariables, status: RegistrationStatus.REGISTERED }
        }
      ]
    }).then((response) => {
      if (!response.data?.patUpsertSubmission?.success) {
        setErrorMessage(response.data?.patUpsertSubmission?.message || "Failed to update score.");
      }
    });
  };
  const handleStatusUpdate = (status: string | null, note: string | null, id: string | null) => {
    patUpsertSubmission({
      variables: {
        input: {
          eventRegistrationId: id,
          additionalStatus: status,
          note: note
        }
      },
      refetchQueries: [
        {
          query: EVENT_REGISTRANTS_QUERY,
          variables: { ...variables, ...paginationVariables, status: RegistrationStatus.REGISTERED }
        }
      ]
    }).then((response) => {
      if (!response.data?.patUpsertSubmission?.success) {
        setErrorMessage(response.data?.patUpsertSubmission?.message || "Failed to update status.");
      }
    });
  };

  const columns = getColumns({
    hasRegistrations,
    setMenu,
    setUpdateStatusDialogOpen,
    onAttendanceUpdate: handleAttendanceUpdate,
    onScoreUpdate: handleScoreUpdate,
    setTargetEventRegistration: setTargetEventRegistration,
    handleStatusUpdate: handleStatusUpdate
  });
  return (
    <>
      <Menu />
      <Table
        data={registrations}
        loading={queryLoading || allSelectedLoading}
        renderHeader={() => (
          <>
            <div className="flex md:items-center justify-between">
              <div className="flex flex-col md:flex-row justify-left gap-4 items-start md:items-center pr-4 md:pr-0">
                <div className="flex flex-row justify-between gap-4 md:w-auto w-full">
                  <div className="w-full sm:max-w-[260px]">
                    <Controller
                      name="search"
                      control={control}
                      render={({ field }) => (
                        <SearchInput
                          placeholder="Search Registrants"
                          query={field.value}
                          param="Name, PGA ID"
                          onChange={(value) => {
                            field.onChange(value);
                            setVariables((prev) => ({
                              ...prev,
                              search: value
                            }));
                          }}
                        />
                      )}
                    />
                  </div>
                  {filterActive && (
                    <div className="ml-1 flex items-center" data-testid="clear-all-button">
                      <Button
                        variant="text"
                        onClick={() => {
                          resetPagination();
                          setVariables({ eventSlug });
                          reset({ search: "" });
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  )}
                </div>
                {Object.keys(rowSelection).length > 0 && (
                  <div className="flex flex-col sm:flex-row gap-4 sm:h-[40px] w-full md:w-auto justify-center md:justify-start items-start">
                    <Button icon="person_check" variant="outlined" onClick={() => handleBulkAttendanceUpdate(true)}>
                      Mark as Present
                    </Button>
                    <Button icon="person_cancel" variant="outlined" onClick={() => handleBulkAttendanceUpdate(false)}>
                      Mark as Absent
                    </Button>
                  </div>
                )}
              </div>
              <div className="hidden ml-3 lg:block">
                {renderFooter(registrantsData?.eventRegistrants, {
                  pageCount: registrations.length,
                  variant: "compact"
                })()}
              </div>
            </div>
            {rowSelected > 0 && (
              <div className="flex items-center gap-2 mt-4 flex-wrap md:flex-nowrap">
                <span className="text-label-large">
                  {rowSelected} {pluralize(rowSelected, "row")} selected
                </span>
                <Chip
                  onClick={() => {
                    setIsSelectedOnly(!isSelectedOnly);
                    selectedOrdersQuery({ variables: { ...variables, ids: Object.keys(rowSelection) } });
                  }}
                  label="Show Selected Only"
                  selected={isSelectedOnly}
                />
                <Button
                  variant="text"
                  onClick={() => {
                    setRowSelection({});
                    setIsSelectedOnly(false);
                    resetPagination();
                  }}
                >
                  Deselect All {rowSelected} {pluralize(rowSelected, "Row")}
                </Button>
              </div>
            )}
            <div className="block mt-3 lg:hidden">
              {renderFooter(registrantsData?.eventRegistrants, {
                pageCount: registrations.length,
                variant: "compact"
              })()}
            </div>
          </>
        )}
        columns={columns}
        renderDetails={() =>
          hasRegistrations ? (
            <TableDetails
              heading="PAT Scores"
              body="Please enter the attendance and player scores from this event. Once you have finished entering the scores, press 'Finalize Scores' to save them to the participants' official records."
              ButtonComps={[() => <Button onClick={() => handleFinalizeScores()}>Finalize Scores</Button>]}
            />
          ) : (
            <TableDetails heading="PAT Scores" body="Registrants will be listed here when available." />
          )
        }
        renderEmptyState={() => (
          <EmptyState title="No Registrants" caption="This event does not have any registrants." iconName="person" />
        )}
        renderFooter={renderFooter(
          isSelectedOnly ? allSelectedData?.eventRegistrants : registrantsData?.eventRegistrants,
          { pageCount: registrations.length }
        )}
        sortingState={sorting}
        onSortingChange={setSorting}
        enableRowSelection
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
      />
      <FinalizeScoresConfirmationModal
        open={finalizeScoresDialogOpen}
        onClose={() => setFinalizeScoresDialogOpen(false)}
        onSave={() => handleFinalizeScores()}
        passingScores={1}
        qualifyingScores={2}
        failingScores={0}
        cheatingScores={4}
        disqualifiedScores={5}
        excessiveScores={6}
        noCardScores={7}
        noShowScores={8}
        poorConductScores={9}
        withdrawalScores={10}
      />
      <FinalizeScoresErrorDialog
        open={finalizeScoresErrorDialogOpen}
        onClose={() => setFinalizeScoresErrorDialogOpen(false)}
        missingRegistrantScores={1}
        missingEventInformation={2}
      />
      {updateStatusDialogOpen && targetEventRegistration && (
        <UpdateStatusDialog
          open={updateStatusDialogOpen}
          onClose={() => setUpdateStatusDialogOpen(false)}
          eventRegistration={targetEventRegistration}
          onSave={(status, note) => handleStatusUpdate(status, note, targetEventRegistration.id)}
        />
      )}
    </>
  );
}
