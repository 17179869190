import { createContext } from "react";
import { Capability, PERMISSION } from "./types";

interface AuthorizationContext {
  capabilities: Capability;
  hasPermission(permission: PERMISSION): boolean;
  loaded: boolean;
}

export const DEFAULT_CAPABILITIES: Capability = { domains: [], permissions: [] };

const defaultContext = {
  capabilities: DEFAULT_CAPABILITIES,
  hasPermission(_: PERMISSION) {
    return false;
  },
  loaded: false
};

export const AuthorizationContext = createContext<AuthorizationContext>(defaultContext);
