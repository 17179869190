import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";

/**
 * Custom hook for handling search functionality.
 *
 * @param {string} fieldName - The name of the search field.
 * @param {string} [defaultValue=""] - The default value for the search field.
 * @returns An object containing the value of the search field, form control, and reset function.
 */
export const useSearch = (fieldName: string, defaultValue: string = "") => {
  const { control, watch, reset, setValue, setFocus } = useForm({
    defaultValues: {
      [fieldName]: defaultValue
    }
  });

  const watchedValue = watch(fieldName);
  const [searchValue, setSearchValue] = useState(defaultValue);

  useEffect(() => {
    const debounceSearch = debounce((value) => {
      setSearchValue((value || "").trim());
    }, 300);

    debounceSearch(watchedValue);

    return debounceSearch.cancel;
  }, [watchedValue]);

  return {
    searchValue,
    control,
    reset,
    setValue,
    setFocus: useCallback(() => {
      setFocus(fieldName);
    }, [fieldName, setFocus])
  };
};
