import { useFlags } from "@/hooks/useFeatureFlags";
import AppLayout from "@/layouts/AppLayout";
import { isDev } from "@/lib/urlUtils";
import { Outlet, useParams } from "react-router-dom";
import { PersonDetailsHeader } from "../_components/PersonDetailsHeader";
import { getPersonNavLinks } from "../_utils";
import TabLayout from "./_TabLayout";
import Button from "@/components/Button";

export default function PersonDetailsLayout() {
  const { pgaId } = useParams();
  const flags = useFlags();

  if (!pgaId) {
    return null;
  }

  const navLinks = getPersonNavLinks(pgaId, flags);

  function ButtonComp() {
    function onClick() {
      const ccmsUrl = isDev()
        ? `https://ccms-pgastage.dev.pga.org/prod/cencustmast.master_pg?q_cust_id=${pgaId}`
        : `https://sms.pgalinks.com/prod/cencustmast.master_pg?q_cust_id=${pgaId}`;

      window.open(ccmsUrl);
    }
    return (
      <Button onClick={onClick} variant="text" icon="open_in_new">
        View in CCMS
      </Button>
    );
  }

  return (
    <AppLayout navLinks={navLinks} interior bottomBar>
      <PersonDetailsHeader pgaId={pgaId} ButtonComp={ButtonComp} />
      <TabLayout>
        <Outlet />
      </TabLayout>
    </AppLayout>
  );
}
