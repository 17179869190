import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import Icon from "@/components/Icon";
import { GET_FACILITIES } from "../queries";
import { useQuery } from "@apollo/client";

interface FacilityType {
  id: string;
  name: string;
  addresses: {
    address1: string;
    city: string;
    state: string;
    postalCode: string;
  }[];
}

interface FacilitiesQueryData {
  facilities: {
    nodes: FacilityType[];
  };
}

const FacilityAutocomplete = () => {
  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors }
  } = useFormContext();
  const [variables, setVariables] = useState({});
  const { data: facilityData } = useQuery<FacilitiesQueryData>(GET_FACILITIES, { variables });

  function selectFacility(
    name: string,
    value: FacilityType,
    setValue: (name: string, value: string | string[]) => void
  ) {
    setValue("paymentSourceId", `${value.id}`);
    setValue(name, `${value.name}`);
  }
  const searchValue = watch("paymentSourceName");

  useEffect(() => {
    setVariables(() => ({
      ...(searchValue ? { input: { search: searchValue } } : {})
    }));
  }, [searchValue]);

  return (
    <Controller
      name="paymentSourceName"
      control={control}
      rules={{ required: "Facility is required" }}
      render={({ field: { onChange, value, name } }) => {
        return (
          <Autocomplete
            label="Facility"
            required
            query={value}
            data={facilityData?.facilities?.nodes || []}
            onChangeText={onChange}
            reset={() => resetField("paymentSourceName")}
            onSelect={(value) => selectFacility(name, value, setValue)}
            error={!!errors?.paymentSourceName}
            errorText={errors?.paymentSourceName?.message as string}
            renderItem={(data) => (
              <div className="flex items-start border-bottom">
                <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
                  <Icon name="location_on" size={24} />
                </div>
                <div>
                  <div className="text-body-large">{data.name}</div>
                  {data.addresses[0] && (
                    <div className="text-body-medium text-sys-brand-on-surface-varian">
                      {data.addresses[0]?.address1}, {data.addresses[0]?.city}, {data.addresses[0]?.state}
                    </div>
                  )}
                </div>
              </div>
            )}
          />
        );
      }}
    />
  );
};

export default FacilityAutocomplete;
