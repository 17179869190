import Pagination from "@/components/Pagination";
import { DEFAULT_PAGE_SIZE, PageInfo, PageSize, PaginationVariables } from "@/components/Pagination/Pagination";
import { gql } from "@apollo/client";
import { useCallback, useState } from "react";
import { useParams } from "@/hooks/useSearchParams";

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfo on ConnectionInterface {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
`;

type EdgesResponse<T> = {
  edges: T[];
  nodes?: never;
};

type NodesResponse<T> = {
  edges?: never;
  nodes: T[];
};

type PaginatedResponse<T> = (NodesResponse<T> | EdgesResponse<T>) & {
  totalCount: number;
  pageInfo: PageInfo;
};

export type RenderFooter<T> = (paginatedResponse?: PaginatedResponse<T>, overrides?: Overrides) => () => JSX.Element;

interface Overrides {
  totalCount?: number;
  pageCount?: number;
  variant?: "compact" | "default";
}

export default function usePagination() {
  const { searchParams, deleteSearchParams } = useParams();
  const pageSizeParam = Number(searchParams?.get("pageSize"));
  const currentPageParam = Number(searchParams?.get("currentPage"));

  const beforeParam = searchParams?.get("before");
  const afterParam = searchParams?.get("after");
  const [pageSize, setPageSize] = useState<PageSize>((pageSizeParam as PageSize) || DEFAULT_PAGE_SIZE);

  const [currentPage, setCurrentPage] = useState(currentPageParam || 0);
  const [paginationVariables, setPaginationVariables] = useState<PaginationVariables>({
    ...(!beforeParam && !afterParam && { first: pageSize }),
    ...(beforeParam && { before: beforeParam, last: pageSize }),
    ...(afterParam && { after: afterParam, first: pageSize })
  });

  const resetPagination = useCallback(() => {
    setPaginationVariables({ first: pageSize });
    setCurrentPage(0);
    if (searchParams) {
      deleteSearchParams(["pageSize", "currentPage", "after", "before"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, searchParams]);

  const renderFooter = useCallback(
    function <T>(paginatedResponse?: PaginatedResponse<T>, { totalCount, pageCount, variant }: Overrides = {}) {
      return function Footer() {
        if (!paginatedResponse) return <></>;

        const { nodes, edges, pageInfo, totalCount: responseTotalCount } = paginatedResponse;

        return (
          <Pagination
            pageInfo={pageInfo}
            setVariables={setPaginationVariables}
            variables={paginationVariables}
            setPageSize={(newSize: PageSize) => {
              setPageSize(newSize);
              resetPagination();
            }}
            pageSize={pageSize}
            pageRows={pageCount || (nodes || edges).length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalRows={totalCount || responseTotalCount}
            variant={variant}
          />
        );
      };
    },
    [currentPage, pageSize, paginationVariables, resetPagination]
  );

  return {
    paginationVariables,
    renderFooter,
    resetPagination,
    currentPage
  };
}
