import designSystem from "./PGAProductDesignSystem.json";

const themes = designSystem.find((t) => t.name === "Themes");
const lightThme = themes?.values.find((t) => t.mode.name === "Light");

function getColors(prefix: string) {
  return (
    lightThme?.color
      .filter((c) => c.name.startsWith(prefix))
      .reduce<{ [key: string]: string }>((acc, entry) => {
        acc[entry.name.toLowerCase().replace(/\//g, "-")] = entry.color;
        return acc;
      }, {}) || {}
  );
}

export const sysColors = getColors("sys/brand");
export const stateLayersColors = getColors("state-layers/brand");
export const extendedColors = getColors("extended");

export const materialWebColorVariables = Object.keys(sysColors).reduce<{ [key: string]: string }>((acc, key) => {
  acc[`--md-sys-color-${key.replace("sys-brand-", "")}`] = sysColors[key];
  return acc;
}, {});
