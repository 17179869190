import { gql } from "@apollo/client";

export const PAYMENT_QUERY = gql`
  query commercePayment($paymentId: ID!) {
    commercePayment(id: $paymentId) {
      id
      amountCents
      refundedAmountCents
      type
      paidAt
      source
      status
      transactionId
      transactionUrl
      stripeAccount
      checkNumber
      payer {
        id
        name
        email
      }
      orderPayments {
        id
        amountCents
        ledgerEntries {
          amountCents
          createdAt
          type
          originalLineItemId
          secondaryLineItem {
            id
            name
            priceCents
          }
          secondaryLineItemId
          lineItem {
            id
            name
            balance
            priceCents
          }
        }
        order {
          id
          stage
          balance
          totalPaid
          description
          dueDate
          updatedAt
          lineItems {
            id
            name
            totalPrice
            priceCents
            balance
            quantity
          }
          person {
            id
            firstName
            lastName
            profilePhoto
            pgaId
          }
        }
      }
    }
  }
`;
