import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@/components/TextField";
import { NumberFormatBase } from "react-number-format";
import { currencyFormatter } from "@/lib/currencyHelpers";

export type EditRateModalProps = {
  onDismiss: () => void;
  handleBulkEditRate: (value: string) => void;
};

export const EditRateModal = ({ onDismiss, handleBulkEditRate }: EditRateModalProps) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });
  const headline = `Edit Rate`;
  const [errorContent] = useState("");

  async function editRate() {
    const value = getValues("duesRate");
    handleBulkEditRate(value);
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={handleSubmit(editRate)}>Save</Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline={headline} className="w-[312px]" displayOverflow>
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}

      <p>Set selected rates to the following value:</p>
      <div className="mt-6">
        <Controller
          name="duesRate"
          control={control}
          rules={{
            required: "Rate is required",
            validate: (value) => {
              if (value < 0) {
                return "Rate must be greater than 0";
              }
              return true;
            }
          }}
          render={({ field }) => (
            <NumberFormatBase
              disabled={false}
              value={(field.value / 100).toFixed(2) || 0}
              format={currencyFormatter}
              customInput={TextField}
              error={!!errors?.duesRate}
              errorText={errors?.duesRate?.message?.toString()}
              onFocus={(e) => {
                e.target.select();
              }}
              onValueChange={(values) => {
                field.onChange(Math.round(values.floatValue || 0));
              }}
              leadingIcon="attach_money"
              label="Rate"
              required
              className="w-full"
            />
          )}
        />
      </div>
    </Dialog>
  );
};
