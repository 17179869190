import { useMutation } from "@apollo/client";
import { REJECT_CHANGE_REQUEST_MUTATION, Node } from "../queries";
import Button from "@/components/Button";
import Progress from "@/components/Progress";
import TextField from "@/components/TextField";
import useModal from "@/hooks/useModal";
import { useForm } from "react-hook-form";

type FormData = { message: string };

export default function RejectModal({
  changeRequest: { id: changeRequestId },
  onClosed
}: {
  changeRequest: Node;
  onClosed(): void;
}) {
  const { save, Modal } = useModal();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch
  } = useForm<FormData>({ defaultValues: { message: "" } });

  const [send, { loading }] = useMutation(REJECT_CHANGE_REQUEST_MUTATION);

  const submit = ({ message }: FormData) =>
    save(
      () => send({ variables: { id: changeRequestId, message } }),
      (data) => data.rejectChangeRequest,
      "Change request rejected"
    );

  return (
    <Modal
      action={
        <Button theme="error" disabled={loading} onClick={handleSubmit(submit)} icon="chat">
          Send Rejection Message
        </Button>
      }
      headline="Reject Change Request"
      onClosed={onClosed}
    >
      {loading ? (
        <Progress variant="linear" />
      ) : (
        <>
          <p className="max-w-sm">
            Please include a message to the member specifying why this change request is being rejected.
          </p>

          <TextField
            className="w-full h-48 mt-6 mb-3"
            variant="outlined"
            type="textarea"
            label="Message to Member *"
            error={!!errors.message}
            errorText={errors.message?.message}
            value={watch("message")}
            {...register("message", { required: "Message is required" })}
          />
        </>
      )}
    </Modal>
  );
}
