import { useState, useEffect, PropsWithChildren } from "react";
import { useQuery } from "@apollo/client";
import { USER_FEATURE_FLAG_QUERY } from "./queries";
import { initializeFeatures, FlagRecord, FeatureFlagContext, BackofficeUser } from "@/hooks/useFeatureFlags";

type UserQueryData = {
  me: BackofficeUser;
};

const FeatureFlagProvider = ({ children }: PropsWithChildren<{}>) => {
  const [featureFlags, setFeatureFlags] = useState<FlagRecord>({});
  const { data } = useQuery<UserQueryData>(USER_FEATURE_FLAG_QUERY);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      if (!data) {
        return;
      }
      const flags = await initializeFeatures(data.me);

      if (flags) {
        setFeatureFlags(flags);
      }
    };
    fetchFeatureFlags();
  }, [data]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagProvider;
