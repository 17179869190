import { User } from "@auth0/auth0-react";
import Avatar from "@/components/Avatar";
import Menu, { MenuItem } from "@/components/Menu";
import IconButton from "@/components/IconButton";
import { useSidebar } from "@/hooks/useSidebar";
import NavLogo from "./NavLogo";

type TopAppBarProps = {
  interior?: boolean;
  user: User;
  logout(): void;
  actions?: React.ReactNode;
};

export default function TopAppBar({ user, logout, interior, actions }: TopAppBarProps) {
  const { picture, name } = user;
  const menuItems: MenuItem[] = [
    {
      label: name || "Unknown User",
      iconName: "person",
      disabled: true,
      itemClassName: "w-[215px] opacity-100"
    },
    { label: "Logout", onClick: logout }
  ];

  const bgColor = interior ? "tablet:bg-sys-brand-surface bg-sys-brand-surface-container" : "bg-sys-brand-surface";

  const { isSidebarOpen, setSidebarOpen } = useSidebar();

  return (
    <>
      <div
        className={`px-3 tablet:px-12 pt-4 pb-2 sticky z-[1] top-0 left-0 w-full  ${bgColor}`}
        data-testid="top-app-bar"
      >
        <div className="w-full flex">
          <div className="tablet:hidden flex items-center z-drawer">
            <IconButton name={"menu"} onClick={() => setSidebarOpen(!isSidebarOpen)} />
            <NavLogo className="w-12 h-12" />
          </div>
          <div className="ml-auto flex gap-3">
            <div id="icons" className="flex my-auto">
              {actions}
            </div>
            <Menu yOffset={8} {...{ AnchorComponent: <Avatar {...{ picture }} />, menuItems }} />
          </div>
        </div>
      </div>
    </>
  );
}
