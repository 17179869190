import classNames from "classnames";
import { LitRadio } from "./LitRadio";

interface RadioProps {
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  name: string;
}

export default function Radio({ label, onChange, checked, disabled = false, name }: RadioProps) {
  return (
    <div className="flex items-center" data-testid="radio">
      <LitRadio
        disabled={disabled}
        checked={checked}
        name={name}
        id={name}
        onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
      />
      <label
        className={classNames("ml-3 text-body-large cursor-pointer", { "text-gray-400": disabled })}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
}
