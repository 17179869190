import { MenuItem as MenuItem$ } from "@/components/Menu";

export type MenuItem = MenuItem$ | "divider";

export type Menu = {
  rowId: string;
  items: MenuItem[];
};

export function actionId(rowId: string) {
  return `action-${rowId}`;
}
