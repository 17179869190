import { useMemo } from "react";
import type { PATMetricType } from "../types";
import { EventState } from "..";
import PATMetricGrid from "./PATMetricGrid";

interface PATSetupSectionProps {
  onUpdate: (update: Partial<EventState>) => void;
  currentState: EventState;
}

type MetricField = Exclude<
  keyof EventState["patEventAttributes"],
  "hostProfessional" | "examiner" | "conditions" | "problems"
>;

export function PATSetupSection({ onUpdate, currentState }: PATSetupSectionProps) {
  const patMetrics: { [key: string]: PATMetricType[] } = useMemo(() => {
    const createMetric = (field: MetricField, label: string, editable = true): PATMetricType => ({
      label,
      value: currentState.patEventAttributes[field]?.toString() || "",
      ...(editable && {
        onEdit: (newValue: string) => {
          let value: string | number = "";

          if (newValue.trim() === "") {
            value = "";
          } else if (field.includes("yardage")) {
            if (isNaN(parseInt(newValue, 10))) {
              value = "";
            } else {
              value = parseInt(newValue, 10);
            }
          } else if (field.includes("Rating")) {
            if (isNaN(parseFloat(newValue))) {
              value = "";
            } else {
              value = parseFloat(newValue);
            }
          } else {
            value = newValue;
          }

          onUpdate({
            patEventAttributes: {
              ...currentState.patEventAttributes,
              [field]: value
            }
          });
        }
      })
    });

    return {
      male: [
        createMetric("yardageMale", "Yardage - Male"),
        createMetric("courseRatingMale", "Course Rating - Male"),
        createMetric("yardageMaleFiftyPlus", "Yardage - Male 50+"),
        createMetric("targetScoreMale", "Target Score - Male", false)
      ],
      female: [
        createMetric("yardageFemale", "Yardage - Female"),
        createMetric("courseRatingFemale", "Course Rating - Female"),
        createMetric("yardageFemaleFiftyPlus", "Yardage - Female 50+"),
        createMetric("targetScoreFemale", "Target Score - Female", false)
      ]
    };
  }, [currentState, onUpdate]);

  return (
    <>
      <div className="w-full pt-6 pb-2 md:w-auto flex flex-col md:flex-row md:items-center justify-between">
        <p className="text-title-medium">PAT Setup</p>
      </div>
      <div className="grid grid-cols-1 gap-3 w-full md:grid-cols-2">
        <PATMetricGrid key="male" metrics={patMetrics.male} />
        <PATMetricGrid key="female" metrics={patMetrics.female} />
      </div>
    </>
  );
}
