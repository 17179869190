import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useNavigate } from "@/router";

export function NoEditsModal({ onDismiss }: { onDismiss: () => void }) {
  async function cancelModal() {
    onDismiss();
  }

  const actions = (
    <div className="flex justify-end gap-2 mt-4">
      <Button onClick={cancelModal}>Go Back</Button>
    </div>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline="No Order Edits">
      <p>No changes to the order have been made. Add or remove products to edit the order.</p>
    </Dialog>
  );
}

export function DiscardModal({ onDismiss }: { onDismiss: () => void }) {
  async function cancelModal() {
    onDismiss();
  }
  const navigate = useNavigate();

  const actions = (
    <div className="flex justify-end gap-2 mt-4">
      <Button variant="text" onClick={cancelModal}>
        Keep Editing
      </Button>
      <Button onClick={() => navigate(-1)}>Discard Changes</Button>
    </div>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline="Discard Order Changes">
      <p>Do you want to discard your changes to this order? This cannot be undone.</p>
    </Dialog>
  );
}
