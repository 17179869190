import { useMutation } from "@apollo/client";
import { showSnackbar } from "@/lib/snackbarUtils";
import { CANCEL_EVENT_REGISTRATION_MUTATION } from "../../../queries";
import Button from "@/components/Button";
import useModal from "@/hooks/useModal";
import { OrderStatus } from "../../../types";

interface CancelModalProps {
  event: { waitlistEnabled?: boolean };
  eventRegistrationId: string;
  onClosed: () => void;
  onError: (errorMessage: string) => void;
  order?: { status: OrderStatus };
  refetch?: () => void;
}

export default function CancelModal({
  event,
  order,
  onClosed,
  onError,
  eventRegistrationId,
  refetch
}: CancelModalProps) {
  const { close, Modal } = useModal();

  const [cancelEventRegistration, { loading: mutationLoading }] = useMutation(CANCEL_EVENT_REGISTRATION_MUTATION);
  async function handleCancel() {
    try {
      const { data } = await cancelEventRegistration({ variables: { id: eventRegistrationId } });
      const { success, message } = data.cancelEventRegistration;
      if (success) {
        refetch && refetch();
        showSnackbar("Registration canceled successfully", { close: true });
      } else {
        onError(`The registration could not be canceled. Error: ${message}`);
      }
    } catch (e) {
      onError((e as Error).message);
    } finally {
      await close();
    }
  }

  const action = (
    <Button disabled={mutationLoading} onClick={handleCancel}>
      Cancel Registration
    </Button>
  );

  const text = [
    "Are you sure you want to cancel this person's registration to this event?",
    !!order?.status && "They will be refunded their registration payment.",
    event.waitlistEnabled &&
      "All those who have signed up for event notifications will also be notified that a spot is now available."
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <Modal action={action} onClosed={onClosed} headline="Cancel Registration" data-testid="override-modal">
      <div className="flex items-center text-sys-brand-on-surface-variant pb-6">
        <p className="text-body-medium">{text}</p>
      </div>
    </Modal>
  );
}
