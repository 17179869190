import { useState } from "react";
import { uniqBy } from "lodash";
import Select from "@/components/Select";
import { useMutation, useQuery } from "@apollo/client";
import FileUpload from "@/components/FileUpload";
import { CURRENT_AND_PREVIOUS_QUARTERS_QUERY, CREATE_SPONSOR_CONTRIBUTION_MUTATION } from "./queries";
import type { Quarter, Sponsor } from "./queries";
import Icon from "@/components/Icon";
import Tooltip from "@/components/Tooltip";
import Button from "@/components/Button";
import { parseTsv, ParseResult } from "@/lib/papaParse";
import { useNavigate } from "@/router";
import GrpFormLayout from "../_GrpFormLayout";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import usePageTitle from "@/hooks/usePageTitle";

interface Data {
  grpQuarters: Quarter[];
  grpSponsors: Sponsor[];
}

type UploadFileRow = {
  Sponsor_id: string;
  Entity_ID: string;
  Name: string;
  Amount: string;
  Info?: string;
  Facility_id?: string;
};

type ParseTsvResult = ParseResult<UploadFileRow>;

type KeyOfUploadFile = Array<keyof UploadFileRow>;

const VALID_HEADERS: KeyOfUploadFile = ["Sponsor_id", "Entity_ID", "Name", "Amount", "Info", "Facility_id"];

const CURRENT_QUARTER = "Current Quarter";
const PREVIOUS_QUARTER = "Previous Quarter";

const validateSponsorId = (result: ParseTsvResult) => {
  const sponsorCount = uniqBy(result.data, (obj) => obj.Sponsor_id);
  return sponsorCount.length !== 1;
};

const validateHeaders = (result: ParseTsvResult) => {
  const fields = result?.meta?.fields;
  return fields && !fields.every((f) => VALID_HEADERS.some((v) => v === f));
};

const validateRows = (result: ParseTsvResult, grpSponsors?: Sponsor[]) => {
  const { data: rows } = result;

  const isInvalidHeaders = validateHeaders(result);
  if (isInvalidHeaders) return `Make sure file headers match these fields: ${VALID_HEADERS.join("; ")}`;

  const isNotUniqSponsorId = validateSponsorId(result);
  if (isNotUniqSponsorId) return "All rows must have unique sponsor ids.";

  const hasSponsor = grpSponsors && grpSponsors.find((v: Sponsor) => rows.some((r) => r.Sponsor_id === v.legacyId));
  if (!hasSponsor) return "Unable to upload. The sponsor ID is not valid.";
};

export default function SponsorContributionsUpload() {
  usePageTitle(
    "/benefit-programs/grp/sponsor-contributions/upload",
    "Benefit Programs | Golf Retirement Plus | Sponsor Contributions | Upload Contributions"
  );
  useRequireCapability({ domain: DOMAIN.BENEFIT_PROGRAMS, permission: PERMISSION.viewSponsorContributions });
  const navigate = useNavigate();

  const { data } = useQuery<Data>(CURRENT_AND_PREVIOUS_QUARTERS_QUERY);
  const [createSponsorContributionMutation, { loading }] = useMutation(CREATE_SPONSOR_CONTRIBUTION_MUTATION);

  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState(CURRENT_QUARTER);

  const currentQuarter = data?.grpQuarters[0];
  const grpQuarters = data?.grpQuarters;
  const grpSponsors = data?.grpSponsors;
  const options = grpQuarters?.map((rest, i) => ({
    ...rest,
    value: i === 0 ? CURRENT_QUARTER : PREVIOUS_QUARTER
  }));

  return (
    <GrpFormLayout
      navHeaderProps={{
        title: "Upload Sponsor Contributions",
        backLink: { to: "/benefit-programs/grp/sponsor-contributions" }
      }}
    >
      <div className="flex justify-between">
        <div className="text-title-medium text-sys-brand-on-surface">Upload a Sponsor Contributions File</div>
        <Tooltip
          title="Sponsor Contribution File Format"
          supportingText="The Sponsor Contribution file must be formatted specifically with columns for Sponsor ID, Member ID, Member Name, Facility ID, and Amount."
          actions={{
            primary: (
              <Button variant="text" icon="open_in_new">
                View Help
              </Button>
            )
          }}
        >
          <Icon name="help" className="cursor-pointer" />
        </Tooltip>
      </div>

      {!!currentQuarter && (
        <div className="text-label-large text-sys-brand-on-surface mt-5">
          Current Quarter: {currentQuarter.displayName}
        </div>
      )}

      <div className="mt-6">
        {!!options && (
          <Select
            onSelect={(v: string) => setSelectedQuarter(v)}
            options={options}
            value={selectedQuarter}
            label="Quarter"
            className="min-w-full"
          />
        )}
      </div>
      <div className="mt-6">
        <FileUpload
          loading={loading}
          errorMsg={fileUploadErrorMsg}
          accept=".tsv"
          handleUpload={async (file) => {
            const parsed = await parseTsv<UploadFileRow>(file);
            const errorMsg = validateRows(parsed, grpSponsors);

            if (errorMsg) {
              return setFileUploadErrorMsg(errorMsg);
            }

            const sponsorLegacyId = parsed.data[0].Sponsor_id;
            const { id: quarterId } = options?.find((o) => o.value === selectedQuarter) || {};
            const { id: sponsorId } = grpSponsors?.find((o) => o.legacyId === sponsorLegacyId) || {};

            const contributions = parsed.data.map(({ Amount, Entity_ID, Name, Facility_id: facilityId, Info }) => ({
              amount: Amount,
              memberNumber: Entity_ID,
              memberName: Name,
              facilityId,
              info: Info
            }));

            const { data } = await createSponsorContributionMutation({
              variables: {
                input: {
                  sponsorId,
                  quarterId,
                  contributions
                }
              }
            });

            const { grpCreateSponsorContribution: response } = data;

            return response.success
              ? navigate("/benefit-programs/grp/sponsor-contributions")
              : setFileUploadErrorMsg(response.message || "Unexpected error occurred.");
          }}
        />
      </div>
    </GrpFormLayout>
  );
}
