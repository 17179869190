export default function ProfileSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div className="animate-pulse rounded-full bg-gradient-to-r from-gray-200 to-gray-50 h-12 w-12" />
        <div className="flex-1 space-y-2 py-1">
          <div className="animate-pulse h-4 rounded-full bg-gradient-to-r from-gray-200 to-gray-50 w-3/4" />
          <div className="animate-pulse h-4 rounded-full bg-gradient-to-r from-gray-200 to-gray-50 w-1/2" />
        </div>
      </div>
    </div>
  );
}
