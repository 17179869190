import Tabs from "@/components/Tabs";
import { Path, useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const { sectionId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const currentUrl = pathname.replace(/\/$/, "");
  const navigate = useNavigate();

  if (!sectionId) return null;

  return (
    <>
      <div className="mt-4 sm:mt-8">
        <Tabs
          tabItems={[
            {
              label: "Jobs",
              to: "/sections/:sectionId/jobs/list" as Path,
              active: currentUrl === `/sections/${sectionId}/jobs/list`
            }
          ].map(({ label, to, active }) => ({
            label,
            onClick: () => navigate(to, { params: { sectionId } }),
            active
          }))}
        />
      </div>
      <div className="flex flex-col" data-testid="tab-content">
        {children}
      </div>
    </>
  );
}
