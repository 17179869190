import { gql } from "@apollo/client";

export interface PersonType {
  id: string;
  firstName: string;
  lastName: string;
  profilePhoto: string;
  pgaId: string;
  mainProgramType: {
    name: string;
  };
}

export interface OrderType {
  id: string;
  balance: number;
  description: string;
  person: PersonType;
  lineItems: {
    id: string;
    name: string;
    balance: number;
  }[];
  addons: {
    id: string;
    name: string;
    priceCents: number;
  }[];
}

export interface OrdersQuery {
  commerceOrders: {
    nodes: OrderType[];
  };
}

export const ORDERS_QUERY = gql`
  query GetCommerceOrders($first: Int, $ids: [String!]) {
    commerceOrders(first: $first, filters: { ids: $ids }) {
      nodes {
        id
        balance
        description
        person {
          id
          firstName
          lastName
          profilePhoto
          pgaId
          mainProgramType {
            id
            name
          }
        }
        lineItems {
          id
          name
          balance
        }
        addons {
          id: productVariantLookupKey
          name
          priceCents
        }
      }
    }
  }
`;

export const CREATE_CHECK_PAYMENT_MUTATION = gql`
  mutation CreateCheckPayment($input: CommerceCheckPaymentInput!) {
    commerceCreateCheckPayment(input: $input) {
      payment {
        id
        orderPayments {
          id
          order {
            id
            stage
            balance
            payable
          }
        }
      }
      message
      success
    }
  }
`;

export const GET_FACILITIES = gql`
  query GetFacilities($input: BackofficeCRMFacilitiesInput!) {
    facilities(input: $input, first: 10) {
      nodes {
        id
        name
        addresses {
          address1
          city
          state
          postalCode
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($input: BackofficeCommerceProductInput!) {
    commerceProducts(input: $input) {
      nodes {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCT_VARIANTS = gql`
  query GetProductVariants($productId: String!, $search: String) {
    commerceProductVariants(input: { productId: $productId, search: $search }) {
      totalCount
      nodes {
        id
        name
        priceCents
        lookupKey
      }
    }
  }
`;

export const GET_FINANCIAL_ADJUSTMENT_TYPES = gql`
  query GetFinancialAdjustmentTypes {
    commerceFinancialAdjustmentTypes {
      id
      name
    }
  }
`;
