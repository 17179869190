import usePagination from "@/hooks/usePagination";
import { ChangeRequestTable } from "@/pages/sections/[sectionId]/change-requests/_components/ChangeRequestTable";
import { useQuery } from "@apollo/client";
import { useParams } from "@/router";
import { GET_PERSON_CHANGE_REQUESTS_QUERY } from "./queries";
import { ChangeRequestStatus } from "@/pages/sections/[sectionId]/change-requests/queries";
import { useEffect, useState, PropsWithChildren } from "react";
import StageSelect, {
  Props as StageSelectProps
} from "@/pages/sections/[sectionId]/change-requests/_components/StageSelect";
import { TableDetails } from "@/components/Table";

const path = "/people/:pgaId/details/change-requests";

const emptyAction = (pgaId: string) => ({
  children: "View Audit Log",
  to: "/people/:pgaId/audit-log" as const,
  params: { pgaId }
});

const TableHeader = ({ children, ...props }: PropsWithChildren<StageSelectProps>) => (
  <div className="flex md:items-center justify-between">
    <div className="flex md:items-center gap-3 w-full">
      <StageSelect {...props} />
    </div>
    {children}
  </div>
);

const Details = () => (
  <TableDetails
    heading="Change Request History"
    body="A list of all personal data changes that this member has requested can be found below. Those that require manual approval before the data change is saved in the system are marked as “Needs Approval”."
  />
);

export default function ChangeRequests() {
  const { pgaId } = useParams(path);
  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const [stage, onChangeStage] = useState<ChangeRequestStatus>();
  const { data, loading, error } = useQuery(GET_PERSON_CHANGE_REQUESTS_QUERY, {
    variables: { pgaId, stage, ...paginationVariables },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    resetPagination();
  }, [stage, resetPagination]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const emptyProps = stage
    ? { emptyTitle: "No Results Found", emptyCaption: "Try a different search or filter" }
    : { emptyCaption: "This person does not have any past or current change requests." };
  const changeRequests = data?.person.changeRequests.nodes || [];

  return (
    <ChangeRequestTable
      data-testid="change-requests-table"
      loading={loading}
      data={changeRequests ?? []}
      renderDetails={Details}
      renderFooter={renderFooter(data?.person.changeRequests)}
      renderHeader={
        changeRequests.length || stage
          ? () => (
              <TableHeader onChange={onChangeStage} stage={stage}>
                {renderFooter(data?.person.changeRequests, { variant: "compact" })()}
              </TableHeader>
            )
          : undefined
      }
      columns={["submittedAt", "type", "change", "notes", "status"]}
      emptyAction={emptyAction(pgaId)}
      {...emptyProps}
    />
  );
}
