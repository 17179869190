import { useMemo, ReactNode, useState } from "react";

import Divider from "../Divider";
import { LitMenu, LitMenuItem } from "./LitMenu";
import { MaterialNames } from "../material_symbol_names";
import Icon from "../Icon";

export type MenuItem = {
  label: string;
  iconName?: MaterialNames;
  onClick?(): void;
  disabled?: boolean;
  itemClassName?: string;
};

type MenuProps = {
  menuItems: MenuItem[];
  yOffset?: number;
  title?: string;
  AnchorComponent: ReactNode;
};

const randomNum = () => (Math.random() * 10000000).toFixed(0);

export default function Menu({ menuItems, yOffset = 2, AnchorComponent }: MenuProps) {
  const [open, setOpen] = useState(false);

  function setVisibility() {
    setOpen((prev) => !prev);
  }
  const anchorId = useMemo(() => `menu-anchor-${randomNum()}`, []);

  return (
    <div
      className="relative cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div id={anchorId} onClick={setVisibility}>
        {!!AnchorComponent && AnchorComponent}
      </div>
      <LitMenu open={open} anchor={anchorId} yOffset={yOffset} menuCorner="start-end" anchorCorner="end-end">
        {menuItems.map(({ label, iconName, onClick, disabled, itemClassName = "w-[215px]" }: MenuItem, i: number) => (
          <div key={i}>
            <LitMenuItem className={`${itemClassName}`} onClick={onClick} disabled={disabled}>
              <div className="flex flex-row items-center">
                {!!iconName && <div className="flex mr-4">{<Icon name={iconName} />}</div>}
                {label}
              </div>
            </LitMenuItem>
            {i < menuItems.length - 1 && <Divider />}
          </div>
        ))}
      </LitMenu>
    </div>
  );
}
