import Tabs from "@/components/Tabs";
import { DOMAIN } from "@/hooks/useCapabilities";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useNavigate } from "@/router";
import { useLocation } from "react-router-dom";

export default function GrpTabLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  return (
    <AppLayout
      interior
      navLinks={[
        {
          domain: DOMAIN.BENEFIT_PROGRAMS,
          label: "Benefit Management",
          link: { to: "/benefit-programs/grp" },
          icon: "settings"
        }
      ]}
    >
      <PageHeading title="Golf Retirement Plus" backLink={{ to: "/benefit-programs" }} />
      <Tabs
        className="my-8"
        tabItems={[
          { label: "Member Contributions", to: "/benefit-programs/grp/member-contributions" as const },
          { label: "Sponsor Contributions", to: "/benefit-programs/grp/sponsor-contributions" as const }
        ].map(({ label, to }) => ({
          label,
          onClick: () => navigate(to),
          active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
        }))}
      />
      <div className="flex flex-col">{children}</div>
    </AppLayout>
  );
}
