import Banner from "@/components/Banner";
import { MAX_PEOPLE } from "../MultiPersonAdder";

export const MaxPeopleBanner = () => {
  return (
    <Banner
      variant="warn"
      description={`A max of ${MAX_PEOPLE} people can be added to group interactions at this time. Remove one or more selected people to add different ones.`}
      inline
    />
  );
};
