import { Controller, useFormContext } from "react-hook-form";
import Select from "@/components/Select";
import { ProductsFormValues } from "../types";

interface ProductOption {
  value: string;
  displayText: string;
  description: string;
}

interface ProductsDropdownProps {
  index: number;
  disabled?: boolean;
  products: Array<ProductOption>;
  loading?: boolean;
  supportingText?: string;
}

const ProductsDropdown = ({ index, disabled, products = [], supportingText }: ProductsDropdownProps) => {
  const {
    control,
    setValue,
    clearErrors,
    trigger,
    formState: { errors }
  } = useFormContext<ProductsFormValues>();

  return (
    <Controller
      name={`products.${index}.productId`}
      control={control}
      render={({ field: { value } }) => (
        <Select
          label="Product"
          options={products}
          className="w-full"
          value={value}
          disabled={disabled}
          supportingText={supportingText}
          onSelect={(value) => {
            const description = products.find((option: { value: string }) => option.value === value)?.description || "";
            setValue(`products.${index}.description`, description);
            setValue(`products.${index}.variantId`, "");
            setValue(`products.${index}.variantName`, "");
            setValue(`products.${index}.variantPrice`, 0);
            setValue(`products.${index}.collection`, "registration");
            setValue(`products.${index}.productId`, value);
            clearErrors(`products.${index}.productId`);
            trigger(`products.${index}.productId`);
          }}
          required
          data-testid={`product-select-${index}`}
          error={!!errors.products?.[index]?.productId}
          errorText={errors.products?.[index]?.productId?.message as string}
        />
      )}
    />
  );
};

export default ProductsDropdown;
