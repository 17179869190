import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import Icon from "@/components/Icon";
import { GET_SECTIONS } from "../queries";
import { useQuery } from "@apollo/client";
import { Params } from "@/hooks/useSearchParams";
interface SectionType {
  id: string;
  name: string;
  legacyId: number;
}

interface SectionsQueryData {
  crmSections: {
    nodes: SectionType[];
  };
}

const SectionAutocomplete = ({
  params,
  addSearchParam,
  deleteSearchParams
}: {
  params?: Params;
  addSearchParam?: (key: string, value: string) => void;
  deleteSearchParams?: (key: string | string[]) => void;
}) => {
  const form = useFormContext();
  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors }
  } = form;
  const [variables, setVariables] = useState({});
  const { data: sectionData } = useQuery<SectionsQueryData>(GET_SECTIONS, { variables });

  function selectSection(name: string, value: SectionType, setValue: (name: string, value: string | string[]) => void) {
    setValue("sectionIds", [`${value.id}`]);
    setValue(name, `${value.name}`);
    addSearchParam?.("sectionIds[]", value.id);
    addSearchParam?.("section", value.name);
  }

  const searchValue = watch("section");

  useEffect(() => {
    setVariables(() => ({
      ...(searchValue ? { input: { search: searchValue } } : {})
    }));
  }, [searchValue]);

  return (
    <>
      <Controller
        name="section"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          return (
            <Autocomplete
              label="Section"
              query={params?.section || value}
              data={sectionData?.crmSections?.nodes || []}
              onChangeText={onChange}
              reset={() => {
                resetField("section");
                setValue("sectionIds", []);
                deleteSearchParams?.(["sectionIds[]", "section"]);
              }}
              onSelect={(value) => selectSection(name, value, setValue)}
              error={!!errors?.paymentSourceName}
              errorText={errors?.paymentSourceName?.message as string}
              renderItem={(data) => (
                <div className="flex items-center border-bottom">
                  <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
                    <Icon name="domain" size={24} />
                  </div>
                  <div>
                    <div className="text-body-large">{data.name}</div>
                  </div>
                </div>
              )}
            />
          );
        }}
      />
    </>
  );
};

export default SectionAutocomplete;
