import Tag from "@/components/Tag";
import { OrderPaymentStatus, OrderStage, PaymentMethod, PaymentStatus } from "./types";
import { TagColor } from "@/components/Tag/Tag";
import type { MaterialNames } from "@/components/material_symbol_names";
import { capitalize } from "lodash";
import { sumBy } from "lodash";
import type { OrderFormValue } from "./orders/apply-payment";
import { PayerType } from "./orders/[orderId]/payment/[paymentId]/types";
import { toFormat, toUTCFormat } from "@/lib/dateHelpers";

type StageTag = { color: TagColor; label: string; icon?: MaterialNames };

const STAGES_MAP: Record<OrderStage, StageTag> = {
  [OrderStage.PAID]: { color: "primary", label: "Paid" },
  [OrderStage.REFUNDED]: { color: "primary", label: "Paid", icon: "money_off" },
  [OrderStage.OPEN]: { color: "tertiaryDim", label: "Open" },
  [OrderStage.DRAFT]: { color: "secondary", label: "Draft" },
  [OrderStage.CANCELED]: { color: "surface", label: "Canceled" },
  [OrderStage.OVERDUE]: { color: "error", label: "Overdue" },
  [OrderStage.PENDING]: { color: "tertiaryDim", label: "Pending" },
  [OrderStage.FAILED]: { color: "error", label: "Failed", icon: "error" }
};

const ORDER_PAYMENT_STATUS_MAP: Record<OrderPaymentStatus, MaterialNames> = {
  [OrderPaymentStatus.FAILED]: "error",
  [OrderPaymentStatus.OVERDUE]: "schedule",
  [OrderPaymentStatus.REFUNDED]: "money_off"
};

export const renderStatus = (stage: OrderStage, paymentStatus: OrderPaymentStatus) => {
  const data = STAGES_MAP[stage] || { label: capitalize(stage), color: "tertiaryDim" };
  const icon = ORDER_PAYMENT_STATUS_MAP[paymentStatus] || data?.icon;

  return (
    <Tag color={data?.color as TagColor} testId={data.label} iconName={icon}>
      {data.label}
    </Tag>
  );
};

const PAYMENT_TYPE_MAP = {
  [PaymentMethod.US_BANK_ACCOUNT]: "ACH",
  [PaymentMethod.CARD]: "Credit Card",
  [PaymentMethod.CHECK]: "Check",
  [PaymentMethod.FINANCIAL_ADJUSTMENT]: "Finance Adjustment"
};

export const renderPaymentType = (paymentType: PaymentMethod) => {
  return PAYMENT_TYPE_MAP[paymentType];
};

const PAYMENT_STATUS_MAP: Record<PaymentStatus, StageTag> = {
  [PaymentStatus.FAILED]: { color: "error", label: "Failed" },
  [PaymentStatus.PAID]: { color: "primary", label: "Paid" },
  [PaymentStatus.PARTIALLY_REFUNDED]: { color: "primary", label: "Paid", icon: "money_off" },
  [PaymentStatus.PENDING]: { color: "tertiaryDim", label: "Pending" },
  [PaymentStatus.REFUNDED]: { color: "surface", label: "Refunded", icon: "money_off" }
};

export const renderPaymentStatus = (status: PaymentStatus) => {
  const data = PAYMENT_STATUS_MAP[status] || { label: capitalize(status), color: "tertiaryDim" };

  return (
    <Tag color={data?.color as TagColor} testId={data.label} iconName={data?.icon}>
      {data.label}
    </Tag>
  );
};

export function buildTableData(orders: OrderFormValue[], paymentAmount?: number | null) {
  const orderData = orders.map((order) => {
    const allocatedAddons = sumBy(
      order.addons.filter((l) => l.selected),
      (item) => item.allocated || 0
    );
    const allocatedLineItems = sumBy(
      order.lineItems.filter((l) => l.selected),
      (item) => item.allocated || 0
    );
    return {
      ...order,
      amount: order.balance + allocatedAddons,
      totalAllocated: allocatedAddons + allocatedLineItems,
      balanceAfterPayment: order.balance - allocatedLineItems
    };
  });

  const totalAmount = sumBy(orderData, (order) => order.amount);
  const totalAllocated = sumBy(orderData, (order) => order.totalAllocated);
  const totals = {
    totalAmount,
    totalAllocated,
    totalUnallocated: (paymentAmount || 0) - totalAllocated,
    totalRemainings: totalAmount - totalAllocated
  };

  return {
    orderData,
    totals
  };
}

type PayerOptionType = { label: string; icon?: MaterialNames };

const PAYER_TYPE_MAP: Record<PayerType, PayerOptionType> = {
  [PayerType.FACILITY]: { icon: "location_on", label: "Facility" },
  [PayerType.SELF]: { icon: "person", label: "Name" },
  [PayerType.OTHER]: { icon: "person", label: "Proxy Check" }
};

export const getPayerOption = (type: PayerType) => {
  const data = PAYER_TYPE_MAP[type] || { icon: "person", label: capitalize(type) };

  return data;
};

export const paymentDate = (paymentMethod: PaymentMethod, date?: string) => {
  if (!date) return "";

  if (paymentMethod === PaymentMethod.CHECK || paymentMethod === PaymentMethod.FINANCIAL_ADJUSTMENT) {
    return toUTCFormat(date, "LLL dd, yyyy");
  } else {
    return toFormat(date, "LLL dd, yyyy t");
  }
};

const regex = /([A-Z])-(\d{1,2})/;

export const getClassification = (str: string) => {
  const match = str.match(regex);
  if (match) {
    const matchIndex = str.indexOf(match[0]);
    return {
      name: str.substring(0, matchIndex),
      classificationLetter: match[1],
      classificationNumber: Number(match[2])
    };
  }
  return {
    name: str,
    classificationLetter: null,
    classificationNumber: null
  };
};

export const sourcesData = Object.entries(PAYMENT_TYPE_MAP).map(([key, value]) => ({
  label: value,
  value: key
}));

export const paymentStatusData = Object.entries(PAYMENT_STATUS_MAP).map(([key, value]) => ({
  label: key === PaymentStatus.PARTIALLY_REFUNDED ? "Partially Refunded" : value.label,
  value: key
}));
