import { capitalize } from "lodash";
import { Band, Person } from "@/types";
import { Query } from "./_hooks/useQuickSearch";

export const getPrimarySectionName = ({ primarySection }: Person) => primarySection?.name;
export const getProgramStatus = ({ latestProgram }: Person) => capitalize(latestProgram?.status);
export const getClassification = ({ latestProgram }: Person) => latestProgram?.band?.classification;
export const getPrimaryFacilityName = ({ employments }: Person) => employments.find((e) => e.isPrimary)?.facility?.name;
export const getSectionName = ({ sectionAffiliation }: Band) => sectionAffiliation?.section?.name;

export const getFieldLabel = (field: keyof Query) =>
  ({
    contactPostalCode: "Zip",
    email: "Email",
    firstName: "First Name",
    informalName: "Preferred First Name",
    lastName: "Last Name",
    memberClassifications: "Classification",
    pgaId: "PGA ID",
    phone: "Phone Number",
    programTypes: "Program",
    sectionIds: "Section",
    statuses: "Status"
  })[field];
