import EmptyState from "@/components/EmptyState";
import Table, { TableDetails } from "@/components/Table";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Employment, Person } from "@/types";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_EMPLOYMENT_DETAILS_QUERY } from "./queries";
import { toFormat } from "@/lib/dateHelpers";
import Tooltip from "@/components/Tooltip";
import Icon from "@/components/Icon";

type PersonData = { person: Person };

function formatTableData(list: Employment[]) {
  return list.map((e: Employment) => ({
    id: e.id,
    facilityName: e.facility.name,
    classification: e.jobClassification,
    jobTitle: e.title,
    startDate: toFormat(e.startedOn),
    endDate: e.endedOn ? toFormat(e.endedOn) : "Present",
    sectionApprovalDate: e.sectionApprovalDate ? toFormat(e.sectionApprovalDate) : null,
    isPrimary: e.isPrimary
  }));
}

export default function EmploymentDetails() {
  const flags = useFlags();

  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_EMPLOYMENT_DETAILS_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!flags["backoffice-person-profile-employment"]) {
    return null;
  }

  const personData = data?.person;
  const employments = personData?.employments;
  const tableData = !!employments?.length ? formatTableData(employments) : [];

  return (
    <Table
      renderDetails={() => (
        <TableDetails
          heading="Employment Details"
          body="View employment history including past and present facilities and job titles below"
        />
      )}
      data={tableData}
      loading={loading}
      columns={[
        {
          header: "Facility Name",
          size: 250,
          id: "facilityName",
          accessorKey: "facilityName",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="flex items-center gap-2">
              {row.original.isPrimary && (
                <Tooltip
                  variant="plain"
                  supportingText="Primary"
                  color="black"
                  menuCorner="end-start"
                  anchorCorner="start-start"
                >
                  <Icon name="check" size={24} />
                </Tooltip>
              )}
              {row.original.facilityName}
            </div>
          )
        },
        {
          header: "Classification",
          size: 200,
          id: "classification",
          accessorKey: "classification",
          enableSorting: false
        },
        { header: "Job Title", size: 250, id: "jobTitle", accessorKey: "jobTitle", enableSorting: false },
        { header: "Start Date", id: "startDate", accessorKey: "startDate", enableSorting: false },
        { header: "End Date", id: "endDate", accessorKey: "endDate", enableSorting: false },
        {
          header: "Section Approval",
          size: 200,
          id: "sectionApproval",
          accessorKey: "sectionApprovalDate",
          enableSorting: false
        }
      ]}
      renderEmptyState={() => (
        <EmptyState
          title="No Employments"
          caption="This person does not have any known employments"
          iconName="business_center"
        />
      )}
      data-testid="employment-details-table"
    />
  );
}
