import Button from "@/components/Button";
import Switch from "@/components/Switch";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { ApprovalType, ChangeSetting, changeTypeToDisplayName } from "../utils";
import { SECTION_CHANGEFORM_SET_SETTING_MUTATION } from "../queries";
import { useSnackbar } from "notistack";
import useModal from "@/hooks/useModal";

export default function EditModal({
  setting: { id, approvalType, changeType },
  onClosed
}: {
  setting: ChangeSetting;
  onClosed(): void;
}) {
  const { close, Modal } = useModal();

  const [isManual, setIsManual] = useState(ApprovalType.manual === approvalType);

  const { enqueueSnackbar } = useSnackbar();

  const [submit, { loading: mutationLoading }] = useMutation(SECTION_CHANGEFORM_SET_SETTING_MUTATION);

  function flipSwitch() {
    setIsManual((is) => !is);
  }

  async function save() {
    const approvalType = isManual ? ApprovalType.manual : ApprovalType.automatic;

    try {
      await submit({ variables: { input: { changeformSettingId: id, approvalType } } });
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    } finally {
      await close();
    }
  }

  const action = (
    <Button icon="check" disabled={mutationLoading} onClick={save}>
      Save
    </Button>
  );

  return (
    <Modal action={action} onClosed={onClosed} headline="Edit Change Request Approver">
      <dl className="flex items-center text-sys-brand-on-surface-variant pb-6">
        <dt className="text-label-large font-semibold">Change Type:</dt>

        <dd className="text-body-medium ml-1">{changeTypeToDisplayName(changeType)}</dd>
      </dl>

      <label className="my-2 block">
        <dl className="flex items-center justify-between">
          <dt className="text-sys-brand-on-surface text-body-large">Manual Approval</dt>

          <dd>
            <Switch selected={isManual} onChange={flipSwitch} />
          </dd>
        </dl>
      </label>
    </Modal>
  );
}
