import Icon from ".";
import type { Slot } from "./Icon.types";
import type { MaterialNames } from "../material_symbol_names";
import classNames from "classnames";

export type SlotIconName = JSX.Element | MaterialNames;

const getIconName = (name: SlotIconName) => (typeof name === "object" ? name : <Icon name={name} />);

export const SlotIcon = ({ name, slot, disabled }: { name: SlotIconName; slot: Slot; disabled?: boolean }) => (
  <div
    slot={slot}
    className={classNames("contents", {
      "[&>md-icon]:opacity-[0.38] [&>md-icon]:text-sys-brand-on-surface": disabled
    })}
  >
    {getIconName(name)}
  </div>
);

export const SlotIconButton = ({ name, slot }: { name: SlotIconName; slot: Slot }) => (
  <div slot={slot} className="contents">
    {getIconName(name)}
  </div>
);
