import { useState, useCallback, useRef } from "react";
import { DateTime } from "luxon";

const cache: Record<string, any> = {};

export function useAiQuery<T>(agent: string) {
  const [result, setResult] = useState<{ message: string; loading: boolean; error: any }>({
    message: "",
    loading: false,
    error: null
  });

  const abortControllerRef = useRef<AbortController | null>(null);

  const prefix = `[system: { "current_date": "${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}" }]`;

  const fetchData = useCallback(
    async (data: T, options?: { ignoreCache?: boolean }): Promise<string> => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const cacheKey = `${agent}-${JSON.stringify(data)}`;
      if (!options?.ignoreCache && cache[cacheKey]) {
        setResult({ message: cache[cacheKey], loading: false, error: null });
        return cache[cacheKey];
      }

      setResult({ message: "", loading: true, error: null });
      await new Promise((resolve) => setTimeout(resolve, 500));

      try {
        const res = await fetch(`${import.meta.env.VITE_AI_URI}/${agent}`, {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            "X-Api-Key": import.meta.env.VITE_AI_KEY
          },
          body: `${prefix} ${JSON.stringify(data)}`,
          signal: abortController.signal
        });

        if (!res.ok) {
          throw new Error("Network response was not ok");
        }

        const responseText = await res.text();

        if (!options?.ignoreCache) {
          cache[cacheKey] = responseText;
        }

        setResult({ message: responseText, loading: false, error: null });
        return responseText;
      } catch (error: any) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
          return "";
        } else {
          setResult({ message: "", loading: false, error });
          throw error;
        }
      } finally {
        abortControllerRef.current = null;
      }
    },
    [agent, prefix]
  );

  const cancelFetch = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setResult((prevResult) => ({ ...prevResult, loading: false }));
    }
  }, []);

  return {
    message: result.message,
    loading: result.loading,
    error: result.error,
    fetchData,
    cancelFetch
  };
}
