import { TypedDocumentNode, gql } from "@apollo/client";
import { VideoQuery } from "./types";

export const VIDEOS_QUERY: TypedDocumentNode<VideoQuery> = gql`
  query getMembershipVideos(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $searchTerm: String
    $creditCategories: [MembershipCreditCategory!]
    $stages: [String!]
    $series: [String!]
    $orderBy: [BackofficeMembershipVideoOrderByInput!]
  ) {
    membershipVideos(
      first: $first
      last: $last
      before: $before
      after: $after
      searchTerm: $searchTerm
      creditCategories: $creditCategories
      stages: $stages
      series: $series
      orderBy: $orderBy
    ) {
      nodes {
        id
        title
        description
        url
        series
        publishedAt
        expiredAt
        creditCategory
        durationInSeconds
        assessment {
          id
          questions {
            id
            content
            position
            options {
              id
              content
              answer
              position
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_SERIES_QUERY = gql`
  query GetSeries($live: Boolean) {
    videoSeries(live: $live)
  }
`;
