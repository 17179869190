import Table, { Row } from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import { ColumnDef } from "@tanstack/react-table";
import { RowType } from "@/components/Table/Table";

interface TableDetailsPanelProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  renderFooter?: () => React.ReactElement;
  onClickRow?: (row: Row<T>) => void;
}

export default function TableDetailsPanel<T extends RowType>({
  data,
  columns,
  renderFooter,
  onClickRow
}: TableDetailsPanelProps<T>) {
  return (
    <div className="py-8 px-12">
      <Table
        data={data}
        loading={false}
        columns={columns}
        onClickRow={onClickRow}
        renderEmptyState={() => (
          <EmptyState title="No Data Found" caption="No data found for this item." iconName="box" />
        )}
        renderFooter={renderFooter}
      />
    </div>
  );
}
