import { z } from "zod";
import {
  validateSessionTimes,
  validateOnsiteFields,
  validateFacility,
  validateRegistrationOptions,
  validateProduct
} from "./utils";

const DateSchema = z.object({
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required")
});

const SessionSchema = z
  .object({
    id: z.string().optional(),
    date: DateSchema,
    startTime: z.string().min(1, "Start time is required"),
    endTime: z.string().min(1, "End time is required"),
    maxAttendance: z.string().optional()
  })
  .refine(validateSessionTimes, {
    message: "End time must be after start time",
    path: ["endTime"]
  });

export const ProductSchema = z
  .object({
    productId: z.string(),
    description: z.string(),
    variantId: z.string(),
    variantName: z.string(),
    variantPrice: z.number(),
    collection: z.string()
  })
  .refine(validateProduct, {
    message: "Variant and collection are required",
    path: ["product"]
  })
  .superRefine((data, ctx) => {
    if (data.productId && data.variantPrice <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Price must be more than $0",
        path: ["variantPrice"]
      });
    }
  });

export const EventSchema = z
  .object({
    eventName: z.string().min(1, "Event Name is required"),
    eventType: z.object({
      id: z.string().min(1, "Event Type is required"),
      name: z.string().min(1, "Event Type is required")
    }),
    section: z.string().min(1, "Section is required"),
    sectionId: z.string(),
    legacyId: z.string(),
    locationType: z.enum(["onsite", "virtual"]),
    facility: z.string().optional(),
    facilityId: z.string().optional(),
    address1: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zipcode: z.string().optional(),
    timezone: z.string(),
    location: z.string().optional(),
    description: z.string().optional(),
    bannerImageUrl: z.string(),
    sessions: z.array(SessionSchema).min(1, "At least one session is required"),
    registrationType: z.enum(["individual", "all"]),
    registrationOpenDate: DateSchema,
    registrationCloseDate: DateSchema,
    products: z.array(ProductSchema).optional(),
    waitlistType: z.enum(["disabled", "notification"]),
    customFields: z.array(z.string()).optional(),
    termsContent: z.string().optional(),
    collectsAttendance: z.boolean().optional(),
    patEventAttributes: z.object({
      yardageMale: z.string().optional(),
      courseRatingMale: z.string().optional(),
      yardageMaleFiftyPlus: z.string().optional(),
      yardageFemale: z.string().optional(),
      courseRatingFemale: z.string().optional(),
      yardageFemaleFiftyPlus: z.string().optional(),
      hostProfessional: z.object({
        id: z.string().optional(),
        profilePhoto: z.string().optional(),
        name: z.string().optional()
      }),
      examiner: z.object({
        id: z.string().optional(),
        profilePhoto: z.string().optional(),
        name: z.string().optional()
      })
    })
  })
  .superRefine((data, ctx) => {
    if (data.locationType === "onsite") {
      validateOnsiteFields(data, ctx);
    }

    if (data.eventType.name === "PAT") {
      validateFacility(data, ctx);
    }

    validateRegistrationOptions(data, ctx);
  });

export type FormValues = z.infer<typeof EventSchema>;
