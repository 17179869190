import { useState, useEffect } from "react";
import Tabs from "@/components/Tabs";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import { getDuesManagementNavLinks } from "../../_utils";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Outlet } from "react-router-dom";
import Banner from "@/components/Banner";

export default function AnnualNationalRatesLayout() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const flags = useFlags();
  const navLinks = getDuesManagementNavLinks(flags);

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorMessage]);

  return (
    <AppLayout
      interior
      bottomBar
      navLinks={navLinks}
      BannerComponent={errorMessage && <Banner closable={true} description={errorMessage} variant="error" />}
    >
      <PageHeading title="Products" backLink={{ to: "/billing/dues-management" }} />
      <Tabs
        className="my-8"
        tabItems={[
          {
            label: "Annual National Rates",
            to: "/billing/dues-management/manage-products/annual-national-rates" as const
          }
        ].map(({ label, to }) => ({
          label,
          onClick: () => navigate(to),
          active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
        }))}
      />
      <Outlet context={{ setErrorMessage }} />
    </AppLayout>
  );
}
