import Table, { Props as TableProps } from "@/components/Table";
import { RowType } from "@/components/Table/Table";
import { toFormat } from "@/lib/dateHelpers";
import { CellContext, ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { ReactElement, useState } from "react";
import { ChangeRequestChange, Node, Note, NoteVisibility } from "../queries";
import { ChangeType, changeTypeToDisplayName } from "../settings/utils";
import EmptyState, { Props as EmptyStateProps } from "@/components/EmptyState";
import Icon from "@/components/Icon";
import IconButton from "@/components/IconButton";
import Tooltip from "@/components/Tooltip";
import { formatStage } from "../_utils";

type ExpandedColumn = "notes" | "change" | null;

type ExpansionState = Record<string, ExpandedColumn>;

interface ExpansionProps {
  onExpand(rowId: string, id: ExpandedColumn): void;
  expanded: ExpansionState;
}

const toggle = (expansion: ExpandedColumn | undefined, column: ExpandedColumn): ExpandedColumn =>
  expansion === column ? null : column;

type ChangeField = "title" | "description" | "facility" | "isPrimaryFacility" | "effectiveDate" | "classification";

export type FormattedChange = {
  id: ChangeField;
  requested?: string;
  existing: string | null;
};

type OnEditChangeField = (node: Node, field: FormattedChange) => (() => void) | undefined;

const changeFieldTypes: Record<ChangeField, string> = {
  title: "Title",
  description: "Description",
  facility: "Facility",
  isPrimaryFacility: "Is Primary Facility",
  effectiveDate: "Effective Date",
  classification: "Classification"
};

function formatChanges(change: ChangeRequestChange): FormattedChange[] {
  let fields: FormattedChange[] = [];

  switch (change.__typename) {
    case "BackofficeAddEmploymentChange":
    case "BackofficeAddUnlistedEmploymentChange":
      fields = [
        { id: "title", requested: change.title, existing: null },
        { id: "description", requested: change.description, existing: null },
        { id: "facility", requested: change.facility.name, existing: null },
        { id: "isPrimaryFacility", requested: change.isPrimaryFacility.toString(), existing: null },
        { id: "effectiveDate", requested: toFormat(change.effectiveDate)!, existing: null },
        { id: "classification", requested: change.classification ?? undefined, existing: null }
      ];
      break;
    case "BackofficeUpdateEmploymentChange":
    case "BackofficeUpdateUnlistedEmploymentChange":
      fields = [
        { id: "title", requested: change.title, existing: change.currentEmployment.title },
        { id: "description", requested: change.description, existing: change.currentEmployment.description },
        {
          id: "effectiveDate",
          requested: toFormat(change.effectiveDate)!,
          existing: toFormat(change.currentEmployment.effectiveDate)!
        },
        {
          id: "classification",
          requested: change.classification ?? undefined,
          existing: change.currentEmployment.classification
        }
      ];
  }

  return fields;
}

const getNotes = ({ notes }: Node) => notes;

const visibilityLabel = { [NoteVisibility.INTERNAL]: "Internal Only", [NoteVisibility.EXTERNAL]: "Shared with Member" };

const changeTypeForRequestType = {
  BackofficeAddEmploymentChange: ChangeType.start_new_employment,
  BackofficeAddUnlistedEmploymentChange: ChangeType.start_new_unlisted_employment,
  BackofficeUpdateEmploymentChange: ChangeType.change_employment,
  BackofficeUpdateUnlistedEmploymentChange: ChangeType.change_employment_unlisted
};

const requestTypeToChangeType = (requestType: Node["change"]["__typename"]) => changeTypeForRequestType[requestType];

const formatRequestType = (type: Node["change"]["__typename"]) =>
  changeTypeToDisplayName(requestTypeToChangeType(type));

function DetailsTable<T extends RowType>({
  data,
  columns,
  ["data-testid"]: testId
}: {
  columns: ColumnDef<T, any>[];
  data: T[];
  ["data-testid"]?: string;
}) {
  return (
    <div className="px-12 py-8">
      <Table
        data-testid={testId}
        loading={false}
        renderEmptyState={() => <></>}
        data={data}
        columns={columns.map((column) => ({ ...column, enableSorting: false }))}
      />
    </div>
  );
}

const Change = ({ node, onEdit }: { node: Node; onEdit?(field: FormattedChange): ReturnType<OnEditChangeField> }) => {
  const { accessor, display } = createColumnHelper<FormattedChange>();

  const columns = [
    display({
      id: "label",
      header: "Type of Data",
      cell({ row }) {
        return changeFieldTypes[row.original.id];
      }
    }),
    accessor("existing", { header: "Existing Value" }),
    accessor("requested", {
      header: "Requested Value",
      cell: ({ getValue, row }) => {
        return (
          getValue() ||
          (onEdit ? (
            <Tooltip
              variant="plain"
              color="black"
              supportingText={`${changeFieldTypes[row.original.id]} needs to be set`}
              menuCorner="end-start"
              anchorCorner="start-start"
            >
              <div className="flex items-center py-2">
                <Icon className="text-extended-warning-brand-color" name="emergency_home" />
                <span className="ml-1 opacity-20">--</span>
              </div>
            </Tooltip>
          ) : (
            <span className="opacity-20">--</span>
          ))
        );
      }
    })
  ].concat(
    onEdit
      ? display({
          id: "edit",
          cell({ row: { original: field } }) {
            const onClick = onEdit(field);
            if (onClick)
              return (
                <div className="flex justify-end">
                  <IconButton name="edit" title={`Edit ${changeFieldTypes[field.id]}`} onClick={onClick} />
                </div>
              );
            return null;
          }
        })
      : []
  );

  return <DetailsTable data-testid="change-details" data={formatChanges(node.change)} columns={columns} />;
};

const Notes = ({ node }: { node: Node }) => (
  <DetailsTable
    data-testid="notes"
    data={getNotes(node)}
    columns={[
      { header: "Created", accessorKey: "createdAt", cell: ({ getValue }) => toFormat(getValue()) },
      { header: "Author", accessorFn: ({ author }) => `${author.firstName} ${author.lastName}` },
      { header: "Note", accessorKey: "note" },
      { header: "Visibility", accessorFn: ({ visibility }) => visibilityLabel[visibility] }
    ]}
  />
);

const renderExpandedRow = (expandedColumns: ExpansionState, onEdit?: OnEditChangeField) =>
  function ExpandedRow({ row: { id, original: node } }: { row: Row<Node> }) {
    const onEdit$ = onEdit && ((change: FormattedChange) => onEdit?.(node, change));

    switch (expandedColumns[id]) {
      case "notes":
        return <Notes node={node} />;
      case "change":
        return <Change node={node} onEdit={onEdit$} />;
      default:
        return <></>;
    }
  };

function renderExpandButton<T>({
  onExpand,
  expanded,
  getCount,
  title
}: ExpansionProps & {
  getCount(cellData: T): number;
  title: string;
}) {
  return function ExpandingCell({
    cell: { getValue, column },
    row: { id: rowId, toggleExpanded, getIsExpanded }
  }: CellContext<Node, T>) {
    const rowExpansions = expanded[rowId];

    const columnId = column.id as ExpandedColumn;

    function onClick() {
      if ((!toggle(rowExpansions, columnId) && getIsExpanded()) || !getIsExpanded()) toggleExpanded();
      onExpand(rowId, columnId);
    }

    const count = getCount(getValue());

    return (
      <button
        title={title}
        className="text-left w-full h-full flex items-center"
        disabled={count < 1}
        onClick={onClick}
      >
        {count ? (
          <>
            {count}
            <Icon className="ml" name={rowExpansions === columnId ? "expand_less" : "expand_more"} />
          </>
        ) : (
          "0"
        )}
      </button>
    );
  };
}

const getChangeCount = (change: ChangeRequestChange) =>
  formatChanges(change).filter(({ requested, existing }) => (requested || existing) && requested !== existing).length;

const getNotesCount = (notes: Note[]) => notes.length;

const { accessor } = createColumnHelper<Node>();

const columnDefs = {
  submittedAt: () =>
    accessor("submittedAt", {
      header: "Submitted",
      enableSorting: false,
      cell: ({ getValue }) => toFormat(getValue())
    }),
  type: () =>
    accessor("change.__typename", {
      header: "Request Type",
      enableSorting: false,
      cell: ({ getValue }) => formatRequestType(getValue())
    }),
  pgaId: () => accessor("person.pgaId", { header: "PGA ID", enableSorting: false }),
  fullName: () =>
    accessor(({ person }) => `${person.firstName} ${person.lastName}`, {
      id: "name",
      enableSorting: false,
      header: "Name"
    }),
  change: ({ onExpand, expanded }: ExpansionProps) =>
    accessor("change", {
      header: "Changes",
      enableSorting: false,
      cell: renderExpandButton({ onExpand, expanded, getCount: getChangeCount, title: "View changes" })
    }),
  notes: ({ onExpand, expanded }: ExpansionProps) =>
    accessor(getNotes, {
      header: "Notes",
      id: "notes",
      enableSorting: false,
      cell: renderExpandButton({ onExpand, expanded, getCount: getNotesCount, title: "View notes" })
    }),
  status: () =>
    accessor("status", {
      cell: ({ getValue }) => formatStage(getValue()),
      header: "Stage",
      enableSorting: false
    })
};
type ChangeRequestColumn = keyof typeof columnDefs | ColumnDef<Node, any>;

function buildColumns(columns: ChangeRequestColumn[], props: ExpansionProps) {
  return columns.map((column) => (typeof column === "string" ? columnDefs[column](props) : column));
}

interface Props extends Omit<TableProps<Node>, "columns" | "renderExpandedRow" | "renderEmptyState"> {
  loading: boolean;
  data: Node[];
  renderFooter(): ReactElement;
  manageChangeRequests?: boolean;
  onHorizontalScroll?(): void;
  emptyCaption: string;
  emptyTitle?: string;
  columns: ChangeRequestColumn[];
  onEdit?: OnEditChangeField;
  emptyAction?: EmptyStateProps["action"];
}

export function ChangeRequestTable({
  emptyAction,
  columns,
  emptyCaption,
  emptyTitle = "No Change Requests",
  manageChangeRequests,
  onEdit,
  ...props
}: Props) {
  const [expanded, setExpandedColumn] = useState<ExpansionState>({});

  function onExpand(id: string, column: ExpandedColumn) {
    setExpandedColumn(({ [id]: expansionsForRow, ...expandedColumns }) => ({
      ...expandedColumns,
      [id]: toggle(expansionsForRow, column)
    }));
  }

  return (
    <Table
      data-testid="change-requests-table"
      renderExpandedRow={renderExpandedRow(expanded, onEdit)}
      columns={buildColumns(columns, { expanded, onExpand })}
      renderEmptyState={() => (
        <EmptyState title={emptyTitle} caption={emptyCaption} iconName="person" action={emptyAction} />
      )}
      {...props}
    />
  );
}
