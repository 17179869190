import { Outlet, useParams } from "react-router-dom";
import TabLayout from "./_TabLayout";

import AppLayout from "@/layouts/AppLayout";
import { getSectionNavLinks } from "../_utils";
import { useQuery } from "@apollo/client";
import { SECTION_DETAILS_HEADER_QUERY } from "../queries";
import { useFlags } from "@/hooks/useFeatureFlags";
import { DOMAIN, PERMISSION, useHasCapabilityBool } from "@/hooks/useCapabilities";
import { SectionDetailsHeader } from "../_components/SectionDetailsHeader";

export default function EducationLayout() {
  const { sectionId } = useParams();
  const flags = useFlags();
  const hasPermissions = useHasCapabilityBool({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewPayments });

  const { data } = useQuery(SECTION_DETAILS_HEADER_QUERY, {
    variables: { sectionId },
    fetchPolicy: "cache-first"
  });

  const { legacyId } = data?.section || {};
  const contextValue = {
    legacyId
  };

  if (!sectionId || !legacyId) {
    return null;
  }

  const navLinks = getSectionNavLinks(sectionId, flags, hasPermissions);

  return (
    <AppLayout navLinks={navLinks} interior bottomBar>
      <SectionDetailsHeader sectionId={sectionId} title="Billing" />
      <TabLayout>
        <Outlet context={contextValue} />
      </TabLayout>
    </AppLayout>
  );
}
