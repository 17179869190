import react from "react";
import { createComponent } from "@lit/react";
import { MdCheckbox } from "@material/web/checkbox/checkbox";

export const LitCheckbox = createComponent({
  tagName: "md-checkbox",
  elementClass: MdCheckbox,
  react,
  events: {
    onChange: "change"
  }
});
