import { useMutation } from "@apollo/client";
import { toUpper } from "lodash";
import usePageTitle from "@/hooks/usePageTitle";
import { showSnackbar } from "@/lib/snackbarUtils";
import { FormValues } from "../_form/validation/schema";
import { getDefaultFormValues, setToDayBoundary, toNumber, transformSessionsToInput } from "../_form/utils";
import { BackofficeEventsCreateEventInput } from "../_form/types";
import { CREATE_EVENT_MUTATION } from "../queries";
import EventForm from "../_form";
import { useLocation, useNavigate } from "react-router-dom";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";

export default function CreateEventPage() {
  usePageTitle("/events/new", "Events | Create Event");

  const location = useLocation();
  const event = location.state?.event;

  useRequireCapability({ domain: DOMAIN.SECTIONS, permission: PERMISSION.manageEvents });
  const [createEventMutation] = useMutation(CREATE_EVENT_MUTATION);
  const navigate = useNavigate();

  const isCopy = event ?? false;
  const initialValues = getDefaultFormValues(event, isCopy);

  const handleSubmit = async (data: FormValues) => {
    const transformedSessions = transformSessionsToInput(data.sessions, data.timezone);

    const addressFields =
      data.locationType === "virtual"
        ? {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: ""
          }
        : {
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zip: data.zipcode
          };

    const {
      yardageMale,
      courseRatingMale,
      yardageMaleFiftyPlus,
      yardageFemale,
      courseRatingFemale,
      yardageFemaleFiftyPlus,
      hostProfessional,
      examiner
    } = data.patEventAttributes || {};

    const createEventInput: BackofficeEventsCreateEventInput = {
      bannerImageUrl: data.bannerImageUrl,
      description: data.description,
      title: data.eventName,
      eventTypeId: data.eventType.id,
      sectionId: data.sectionId,
      facilityId: data.facilityId,
      location: data.location,
      locationType: toUpper(data.locationType),
      timezone: data.timezone,
      registrationOpenAt: setToDayBoundary(data.registrationOpenDate.startDate, "start", data.timezone),
      registrationCloseAt: setToDayBoundary(data.registrationCloseDate.startDate, "end", data.timezone),
      registrationType: toUpper(data.registrationType),
      sessions: transformedSessions,
      waitlistType: toUpper(data.waitlistType),
      ...addressFields,
      customFields: data.customFields,
      termsContent: data.termsContent,
      collectsAttendance: data.collectsAttendance,
      patEventAttributes: {
        yardageMale: toNumber(yardageMale),
        courseRatingMale: toNumber(courseRatingMale),
        yardageMaleFiftyPlus: toNumber(yardageMaleFiftyPlus),
        yardageFemale: toNumber(yardageFemale),
        courseRatingFemale: toNumber(courseRatingFemale),
        yardageFemaleFiftyPlus: toNumber(yardageFemaleFiftyPlus),
        hostProfessionalId: hostProfessional?.id,
        examinerId: examiner?.id
      }
    };

    const { data: responseData } = await createEventMutation({
      variables: { input: createEventInput }
    });
    const { success, event } = responseData.createEvent;
    if (success) {
      navigate(`/events/${event.slug}/registrations`);
      showSnackbar("Event successfully created", { close: true });
    } else {
      showSnackbar(responseData.createEvent.message);
    }
  };

  return <EventForm onSubmit={handleSubmit} initialValues={initialValues} />;
}
