import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { ModalValidationType } from "../types";

const VALIDATIONS_MAP = {
  [ModalValidationType.AmountNotAllocated]: {
    headline: "Allocate the Payment",
    description: "Please allocate the payment amount by pressing the edit button(s) to allocate funds to the order(s)."
  },
  [ModalValidationType.AmountUnderAllocated]: {
    headline: "Allocate Remaining Amount",
    description:
      "Please allocate the remaining payment amount by pressing the edit buttons to allocate funds to add-ons."
  },
  [ModalValidationType.AmountOverAllocated]: {
    headline: "Payment is Over-Allocated",
    description: "Please adjust payment allocations so the allocated amount is equal to the received payment amount."
  }
};

export default function ValidationModal({
  onDismiss,
  validationType
}: {
  onDismiss: () => void;
  validationType?: ModalValidationType;
}) {
  const actions = <Button onClick={onDismiss}>Go Back</Button>;

  const { headline, description } = validationType
    ? VALIDATIONS_MAP[validationType]
    : { headline: "", description: "" };

  return (
    <Dialog onClosed={onDismiss} open={!!validationType} actions={actions} headline={headline}>
      <p className="text-body-medium">{description}</p>
    </Dialog>
  );
}
