import Button from "@/components/Button";
import EmptyState from "@/components/EmptyState";
import IconButton from "@/components/IconButton";
import Progress from "@/components/Progress";
import Table from "@/components/Table";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { useNavigate, useParams } from "@/router";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useActions } from "../_hooks";
import { ORDER_QUERY } from "../queries";
import { LineItemsType, OrderQuery, OrderType } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { UPDATE_ORDER_MUTATION } from "./queries";
import { sortBy } from "lodash";
import { showSnackbar } from "@/lib/snackbarUtils";
import { AddProductModal } from "../_components/AddProductModal";
import { NoEditsModal, DiscardModal } from "./_components/EditModals";
import { OrderStage } from "../../../types";
import { CancelOrderModal } from "../../[orderId]/_components/CancelOrderModal";
import usePageTitle from "@/hooks/usePageTitle";
import { sumBy, groupBy } from "lodash";
import { DateTime } from "luxon";
import { SaveChangesModal } from "./_components/SaveChangesModal";
import { PaymentMethod, PaymentStatus } from "@/pages/billing/types";
// eslint-disable-next-line react-refresh/only-export-components
export enum ActionType {
  ADD = "add",
  REMOVE = "remove"
}

export interface RefundablePaymentsResult {
  id?: string | undefined;
  amountCents?: number | undefined;
  payment?: {
    id: string;
    amountCents: number;
    checkNumber?: string | undefined;
    status: PaymentStatus;
    type: PaymentMethod;
    source: string;
    paidAt: string;
    payer: {
      id: string;
      name: string;
    };
  };
  paymentId: string;
  removedAmountCents: number;
}

export interface LineItemFormValue extends LineItemsType {
  action?: ActionType;
  originalId?: string;
  refundablePayments?: any;
}

export interface FormValues {
  lineItems: LineItemFormValue[];
  dueDate: {
    startDate: string;
    endDate: string;
  };
  refunds: {
    paymentId: string;
    amount: number;
  }[];
  notifyPayor: boolean;
  note: string;
}

const Order = () => {
  usePageTitle("/billing/orders/:orderId/edit", "Billing | Orders | Order Details | Edit Order");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.manageOrders });
  const { orderId } = useParams("/billing/orders/:orderId/edit");
  const { error, data } = useQuery<OrderQuery>(ORDER_QUERY, { variables: { orderId } });
  const order = data?.commerceOrder;

  if (error) {
    return <EmptyState title="Error" caption="An error occurred while loading this order." iconName="error" />;
  } else if (order) {
    return <OrderWrapper order={order} />;
  } else {
    return (
      <div className="p-10 flex items-center justify-center">
        <Progress />
      </div>
    );
  }
};

const OrderWrapper = ({ order }: { order: OrderType }) => {
  const { setCancelButton, setActions, setExtendedActions } = useActions();
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openNoEditsDialog, setOpenNoEditsDialog] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  const { totalPaid, orderPayments } = order;

  const [errorContent, setErrorContent] = useState<string>();
  const navigate = useNavigate();
  const [updateOrderMutation, { loading: saving }] = useMutation(UPDATE_ORDER_MUTATION);
  const dueDate = DateTime.now().plus({ days: 30 }).toISO();

  const defaultValues = {
    notifyPayor: false,
    dueDate: {
      startDate: dueDate,
      endDate: dueDate
    },
    lineItems: order.lineItems.reduce<LineItemFormValue[]>((acc, item) => {
      acc.push(item);

      if (item.removedAt) {
        acc.push({
          ...item,
          id: `${item.id}-${Date.now()}`,
          quantity: -1 * item.quantity
        });
      }

      return acc;
    }, [])
  };

  const form = useForm<FormValues>({ defaultValues, mode: "onSubmit" });
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty }
  } = form;

  const onSubmit = async (values: FormValues) => {
    const { data } = await updateOrderMutation({
      variables: {
        input: {
          orderId: order.id,
          lineItems: values.lineItems
            .filter((l) => l.action)
            .map(({ originalId, action, priceCents }) => ({
              id: originalId,
              action,
              priceCents
            }))
        }
      }
    });

    if (data?.commerceUpdateOrder?.success) {
      navigate("/billing/orders/:orderId", { params: { orderId: order.id } });
      showSnackbar("Order Updated");
    } else {
      setErrorContent(data?.commerceUpdateOrder?.message || "An error occurred while updating order.");
    }
  };

  const lineItemsWatch = watch("lineItems");
  const lineItemsTotal = lineItemsWatch.map((l) => l.priceCents * l.quantity).reduce((acc, curr) => acc + curr, 0);
  const balance = lineItemsTotal - totalPaid;

  const hasAddedProducts = lineItemsWatch.some((l) => l.action === ActionType.ADD);
  const removedProductsLenght = lineItemsWatch.filter((l) => l.action === ActionType.REMOVE && !l.removedAt).length;
  const notRemovedProductsLength = order.lineItems.filter((l) => !l.removedAt).length;
  const removedProducts = lineItemsWatch.filter((l) => l.action === ActionType.REMOVE && !l.removedAt);

  const refundablePaymentsArray = removedProducts.flatMap((product) => product.refundablePayments);
  const refundablePaymentsResult = sortBy(
    Object.values(groupBy(refundablePaymentsArray, "paymentId")).map((items) => {
      return {
        paymentId: items[0]?.paymentId,
        removedAmountCents: sumBy(items, "amountCents"),
        ...orderPayments.find((payment) => payment.payment.id === items[0]?.paymentId)
      };
    }),
    (item) => item.payment?.type
  );

  useEffect(() => {
    setCancelButton(
      <div
        className="flex items-center"
        onClick={() =>
          isDirty ? setOpenDiscardDialog(true) : navigate("/billing/orders/:orderId", { params: { orderId: order.id } })
        }
      >
        <IconButton name="arrow_back" data-testid="back" iconClassName="text-sys-brand-on-surface" />
        <span className="text-title-large">Cancel</span>
      </div>
    );

    setActions(
      <>
        {totalPaid === 0 && removedProductsLenght === notRemovedProductsLength && !hasAddedProducts ? (
          <Button
            variant="filled"
            onClick={() => {
              setOpenCancelOrderModal(true);
            }}
          >
            Cancel Order
          </Button>
        ) : (
          <Button
            variant="filled"
            disabled={saving}
            onClick={
              isDirty
                ? order.totalPaid === 0
                  ? handleSubmit(onSubmit)
                  : () => setOpenSaveChangesModal(true)
                : () => setOpenNoEditsDialog(true)
            }
          >
            {order.totalPaid === 0 ? "Save Order" : "Next"}
          </Button>
        )}
      </>
    );
    setExtendedActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, setActions, saving, isDirty, removedProductsLenght, hasAddedProducts]);

  const tableData = sortBy(
    lineItemsWatch.map((item) => {
      return {
        ...item,
        price: centsToDollarsFormatted(item.priceCents),
        amount: centsToDollarsFormatted(item.priceCents * item.quantity)
      };
    }),
    (item) => [item.displayOrder, item.productVariantId, item.id, -item.quantity]
  );

  const getRowClasses = (row: any) => {
    if (row.original.action === ActionType.ADD || row.original.action === ActionType.REMOVE) {
      return "bg-sys-brand-tertiary-container text-sys-brand-on-tertiary-container";
    }
    return "";
  };
  return (
    <div className="max-w-[1440px] mx-auto" data-testid="order-edit-page">
      <FormProvider {...form}>
        {!!errorContent && <div className="text-red-500 font-bold mb-4">{errorContent}</div>}
        <div className="text-headline-small mt-8">Edit Order</div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6 sm:mb-0">
          <div className="max-w-[1000px]">
            <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Products</h2>
            <p className="text-body-medium mb-6 sm:mb-8">
              Adding and removing products changes how much a customer owes. New products can be added to or existing
              products can be removed from this order.
              {order.stage === OrderStage.PAID &&
                " The changes may result in reopening the order to collect the new balance, refunding the overpayment, or not taking any further action."}
            </p>
          </div>
          <Button
            variant="outlined"
            icon="add"
            onClick={() => {
              setOpenAddProductModal(true);
            }}
          >
            Add Product
          </Button>
        </div>
        <Table
          data={tableData}
          loading={false}
          getRowClasses={getRowClasses}
          columns={[
            {
              header: "Product Name",
              accessorKey: "name",
              enableSorting: false,
              size: 800,
              meta: {
                className: getStickyColumnClasses(!!tableData.length)
              },
              cell: ({ row }) => {
                const removed = row.original.quantity < 0;
                return (
                  <div style={removed ? { marginLeft: 16 } : {}}>
                    {removed ? "Removed from order" : row.original.name}
                  </div>
                );
              }
            },
            {
              header: "Price",
              accessorKey: "price",
              enableSorting: false,
              size: 200,
              cell: ({ row }) => <div>{row.original.quantity < 0 ? "" : row.original.price}</div>
            },
            {
              header: "Quantity",
              accessorKey: "quantity",
              enableSorting: false,
              size: 200,
              cell: ({ row }) => <div>{row.original.quantity < 0 ? "" : row.original.quantity}</div>
            },
            {
              header: "Amount",
              accessorKey: "amount",
              enableSorting: false,
              size: 200,
              meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" },
              cell: ({ row }) => <div className="whitespace-nowrap">{row.original.amount}</div>
            },
            {
              id: "actions",
              header: () => null,
              size: 40,
              meta: { className: "text-right !py-0" },
              cell: ({ row }) => {
                const alreadyRemoved = lineItemsWatch.find((l) => l.originalId === row.original.id);
                if (row.original.removedAt || alreadyRemoved) {
                  return null;
                }

                return (
                  <IconButton
                    name="delete"
                    className="m-0"
                    iconClassName={
                      row.original.action === ActionType.ADD || row.original.action === ActionType.REMOVE
                        ? "text-sys-brand-on-tertiary-container"
                        : ""
                    }
                    data-testid="edit"
                    onClick={() => {
                      if (row.original.action) {
                        setValue(
                          "lineItems",
                          lineItemsWatch.filter((l) => l.id !== row.original.id)
                        );
                      } else {
                        setValue(
                          `lineItems.${tableData.length}`,
                          {
                            ...row.original,
                            id: `${row.original.id}-${Date.now()}`,
                            originalId: row.original.id,
                            quantity: -1 * row.original.quantity,
                            action: ActionType.REMOVE
                          },
                          { shouldDirty: true }
                        );
                      }
                    }}
                  />
                );
              }
            }
          ]}
          renderFooter={() => (
            <>
              <div className="pb-3">
                <div className="flex justify-end">
                  <h5 className="text-label-large">Order Total</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-1 md:mr-[156px]">
                    {centsToDollarsFormatted(lineItemsTotal)}
                  </div>
                </div>
              </div>
              <div className="border-t -ml-3 w-[calc(100%+1.5rem)]" />
              <div className="pt-3 flex flex-col justify-end">
                <div className="flex justify-end pb-2">
                  <h5 className="text-body-medium">Net Amount Paid</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-1 md:mr-[156px]">
                    {totalPaid === 0 && "-"} {centsToDollarsFormatted(-totalPaid)}
                  </div>
                </div>
                <div className="flex justify-end">
                  <h5 className="text-label-large">{balance >= 0 ? "Balance Due" : "Refund Due"}</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-1 md:mr-[156px]">
                    {centsToDollarsFormatted(Math.abs(balance))}
                  </div>
                </div>
              </div>
            </>
          )}
          renderEmptyState={() => (
            <EmptyState title="No Products" caption="This order does not have any products." iconName="box" />
          )}
        />
        {openAddProductModal && (
          <AddProductModal
            onDismiss={() => {
              setOpenAddProductModal(false);
            }}
            lineItemsWatch={lineItemsWatch}
          />
        )}
        {openNoEditsDialog && <NoEditsModal onDismiss={() => setOpenNoEditsDialog(false)} />}
        {openDiscardDialog && <DiscardModal onDismiss={() => setOpenDiscardDialog(false)} />}
        {openSaveChangesModal && (
          <SaveChangesModal
            onDismiss={() => setOpenSaveChangesModal(false)}
            balance={balance}
            order={order}
            setErrorContent={setErrorContent}
            refundablePaymentsResult={refundablePaymentsResult}
          />
        )}
      </FormProvider>
      {openCancelOrderModal && (
        <CancelOrderModal
          onDismiss={() => {
            setOpenCancelOrderModal(false);
          }}
          order={order}
          returnTo={() => navigate("/billing/orders/:orderId", { params: { orderId: order.id } })}
        />
      )}
    </div>
  );
};

export default Order;
