import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

export type Params = Record<string, any>;

export function useReturnTo() {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("return_to");

  let params: Params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { return_to, ...searchParametrs } = params;
  const searchParametrsString = new URLSearchParams(searchParametrs).toString();

  return returnTo ? `${returnTo}?${searchParametrsString}` : null;
}

export function useParams() {
  let [searchParams, setSearchParams] = useSearchParams();

  let params: Params = {};
  searchParams.forEach((value, key) => {
    if (value !== "") {
      params[key.replace("[]", "")] = key.includes("[]") ? value?.split(",") : value;
    }
  });

  function addSearchParam(key: string, value: string) {
    if (isEmpty(value)) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    setSearchParams(searchParams, { replace: true });
  }
  function deleteSearchParams(key: string | string[]) {
    if (Array.isArray(key)) {
      key.forEach((k) => {
        searchParams.delete(k);
      });
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams, { replace: true });
  }

  const { before, after, ...defaultParams } = params;
  return {
    params: { ...defaultParams },
    addSearchParam,
    deleteSearchParams,
    searchParams,
    searchParamsUrl: searchParams.toString()
  };
}
