import { gql } from "@apollo/client";

export const GET_SECTIONS = gql`
  query GetSections($input: BackofficeCRMSectionsInput!) {
    crmSections(input: $input) {
      totalCount
      nodes {
        id
        name
        legacyId
      }
    }
  }
`;

export const GET_FACILITIES = gql`
  query GetFacilities($input: BackofficeCRMFacilitiesInput!) {
    facilities(input: $input, first: 10) {
      nodes {
        id
        name
        addresses {
          address1
          city
          state
          postalCode
        }
      }
    }
  }
`;

export const GET_CLASSIFICATIONS = gql`
  query GetClassification {
    classifications {
      nodes {
        id
        name
      }
    }
  }
`;
