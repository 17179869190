import GrpTabLayout from "../_GrpTabLayout";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import usePageTitle from "@/hooks/usePageTitle";

export default function MemberContributions() {
  usePageTitle(
    "/benefit-programs/grp/member-contributions",
    "Benefit Programs | Golf Retirement Plus | Member Contributions"
  );
  useRequireCapability({ domain: DOMAIN.BENEFIT_PROGRAMS, permission: PERMISSION.viewMemberContributions });

  return (
    <GrpTabLayout>
      <h2 className="text-title-medium px-4" data-testid="title">
        Contributions to Members
      </h2>
    </GrpTabLayout>
  );
}
