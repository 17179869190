import IconButton from "@/components/IconButton";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

export function showSnackbar(
  message: string,
  { actions, close = true }: { actions?: JSX.Element[]; close?: boolean } = {}
) {
  const action = (snackbarId: SnackbarKey) => (
    <>
      {actions && actions}
      {close && (
        <IconButton
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
          name="close"
          skipMargin
          className="ml-2"
          iconClassName="text-sys-brand-surface-container-high"
          data-testid="close-snackbar"
        />
      )}
    </>
  );

  setTimeout(() => {
    return enqueueSnackbar(message, {
      action
    });
  }, 250);
}
