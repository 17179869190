import { useEffect, useRef } from "react";
import classNames from "classnames";
import { LitCheckbox } from "./LitCheckbox";
import { stateLayersColors } from "@/assets/theme/colors";
import { updateFocusRing } from "@/lib/styleHelpers";

const customStyles = {
  "--md-focus-ring-width": "-10"
} as React.CSSProperties;

export interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  name: string;
  onChange: (checked: boolean) => void;
}

export default function Checkbox({ label, onChange, checked, disabled = false, name, indeterminate }: CheckboxProps) {
  const checkboxRef = useRef<HTMLDivElement>(null);
  const backgroundColor = checked
    ? stateLayersColors["state-layers-brand-primary-opacity-0-12"]
    : stateLayersColors["state-layers-brand-on-surface-opacity-0-08"];
  useEffect(() => {
    setTimeout(() => updateFocusRing(checkboxRef, "md-checkbox", backgroundColor), 100);
  }, [checked, backgroundColor]);

  return (
    <div className="flex items-center" data-testid="checkbox" ref={checkboxRef}>
      <LitCheckbox
        style={customStyles}
        disabled={disabled}
        checked={checked}
        indeterminate={indeterminate}
        name={name}
        id={name}
        onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
      />

      {label && (
        <label
          className={classNames("ml-3 text-body-large cursor-pointer", { "text-gray-400": disabled })}
          htmlFor={name}
        >
          {label}
        </label>
      )}
    </div>
  );
}
