import { useParams } from "react-router-dom";
import { OverviewDetails } from "./_components/OverviewDetails";
import { useQuery } from "@apollo/client";
import { INSIGHTS_QUERY, PERSON_OVERVIEW_QUERY } from "./queries";
import { Insights, Person } from "@/types";
import { CardSkeleton } from "@/components/Skeleton";

type PersonData = { person: Person };
type InsightsData = { insights: Insights };

export default function PersonOverview() {
  const { pgaId } = useParams();

  const { data, error } = useQuery<PersonData>(PERSON_OVERVIEW_QUERY, {
    variables: { pgaId }
  });

  const { person } = data || {};

  const { data: insightsData } = useQuery<InsightsData>(INSIGHTS_QUERY, {
    variables: { pgaId: person?.pgaId },
    skip: !person?.latestProgram?.programType,
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!person) {
    return (
      <div className="flex mt-4 gap-4">
        <CardSkeleton maxWidth={60} />
        <CardSkeleton maxWidth={60} />
      </div>
    );
  }

  return <OverviewDetails person={person} insights={insightsData?.insights} />;
}
