import { useEffect, useRef, useState } from "react";
import { LexicalEditor } from "lexical";
import { Controller, useFormContext } from "react-hook-form";
import { useFlags } from "@/hooks/useFeatureFlags";
import { useAiQuery } from "@/lib/aiHelpers";
import { showSnackbar } from "@/lib/snackbarUtils";
import { buildPrompt } from "../prompt";
import RichTextEditor from "@/components/RichTextEditor";
import PgaAssistantIcon from "@/assets/images/pga-assistant.svg";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import Progress from "@/components/Progress";
import { handleEditorChange, handleInitialState } from "@/pages/events/_form/utils/markdownUtils";

interface EventDetailsInput {
  prompt: string;
}

const DescriptionField = () => {
  const { watch, control, setValue } = useFormContext();

  const facility = watch("facility");
  const sessions = watch("sessions");
  const city = watch("city");
  const state = watch("state");
  const hasExistingDescription = !!watch("description");
  const isPAT = watch("eventType").name === "PAT";
  const editorRef = useRef<LexicalEditor | null>(null);

  const flags = useFlags();
  const hasGenerateButton = !!flags["backoffice-ai-event-details-generator"] && isPAT;

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { message: aiResponse, loading: aiLoading, fetchData, cancelFetch } = useAiQuery<EventDetailsInput>("base");

  const handleGenerateDescription = () => {
    const requestData = {
      prompt: buildPrompt({ facility, sessions, city, state })
    };
    fetchData(requestData, { ignoreCache: true });
  };

  const handleDialogConfirm = () => {
    handleGenerateDescription();
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
    if (aiLoading) {
      cancelFetch();
    }
  };

  useEffect(() => {
    if (!aiLoading && aiResponse) {
      try {
        const { description } = JSON.parse(aiResponse);

        if (description) {
          setValue("description", description);

          if (editorRef.current) {
            editorRef.current.update(() => {
              handleInitialState(editorRef.current as LexicalEditor, description);
            });
          }
        }
      } catch (error) {
        console.error("Error parsing AI response:", error);
        showSnackbar("There was a problem generating the event description. Please try again later");
      } finally {
        setShowConfirmationDialog(false);
      }
    }
  }, [aiResponse, aiLoading, setValue]);

  return (
    <>
      <div className="relative min-h-[300px]">
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              placeholder="Event Description"
              hasOverlayButton={hasGenerateButton}
              onInitialState={(editor) => {
                handleInitialState(editor, value);
                editorRef.current = editor;
              }}
              onChange={(editorState) => handleEditorChange(editorState, onChange)}
            />
          )}
        />
        {hasGenerateButton && (
          <div className="absolute bottom-0 right-0 p-4">
            <Button
              onClick={() => setShowConfirmationDialog(true)}
              disabled={aiLoading}
              theme="pgaAssistant"
              icon={<img src={PgaAssistantIcon} alt="icon" className="w-4 h-4" />}
            >
              {aiLoading ? "Generating Description..." : "Generate Description"}
            </Button>
          </div>
        )}
      </div>
      {showConfirmationDialog && (
        <div>
          <Dialog
            open={showConfirmationDialog}
            onClosed={handleDialogClose}
            headline="Generate Event Description"
            className="max-w-[423px] p-1 pr-2"
            actions={
              <>
                <span
                  className="text-sys-brand-primary cursor-pointer flex items-center justify-center mr-4"
                  onClick={handleDialogClose}
                >
                  Cancel
                </span>
                <Button variant="filled" onClick={handleDialogConfirm} disabled={aiLoading}>
                  {aiLoading ? (
                    <>
                      <Progress size={20} className="mr-2" />
                      Generating
                    </>
                  ) : (
                    "Generate Description"
                  )}
                </Button>
              </>
            }
          >
            <p>
              Do you want to generate the event description with the PGA Assistant?
              {hasExistingDescription && (
                <span> Existing description will be overwritten and cannot be recovered.</span>
              )}{" "}
              This process will take a few moments.
            </p>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default DescriptionField;
