import Button from "@/components/Button";
import { useState, useEffect } from "react";
import { Person } from "../types";
import AvatarDetails from "@/components/AvatarDetails";
import { useQuery } from "@apollo/client";
import { GET_PEOPLE } from "../queries";
import useModal from "@/hooks/useModal";
import AutoComplete from "@/components/Autocomplete";

interface PeopleData {
  id: string;
  displayName: string;
  pgaId: string;
  profilePhoto: string;
}

interface EditPersonModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  initialName: string;
  onSave: (value: Person) => void;
}

export default function EditPersonModal({ open, onClose, title, initialName, onSave }: EditPersonModalProps) {
  const [name, setName] = useState(initialName);
  const [pgaId, setPgaId] = useState("");
  const [id, setId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { Modal } = useModal();

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const { data } = useQuery(GET_PEOPLE, {
    variables: {
      input: {
        search: searchTerm
      },
      first: 10
    },
    skip: searchTerm.length < 3
  });

  const mapPeople = (data: PeopleData[]) =>
    (data || []).map((person) => {
      return {
        name: person?.displayName || "",
        pgaId: person?.pgaId || "",
        profilePhoto: person?.profilePhoto || "",
        id: person?.id || ""
      };
    });

  const peopleOptions = mapPeople(data?.people.nodes);

  return (
    open && (
      <Modal
        onClosed={onClose}
        headline={`Edit ${title}`}
        className="w-[423px]"
        displayOverflow={true}
        action={
          <Button
            onClick={() => {
              onSave({ id, displayName: name, pgaId });
              onClose();
            }}
          >
            Save
          </Button>
        }
      >
        <AutoComplete
          label="Name"
          data={peopleOptions}
          query={name}
          renderItem={(item: any) => <AvatarDetails picture={item.profilePhoto} title={item.name} text={item.pgaId} />}
          onChangeText={(text: any) => {
            setName(text);
            setSearchTerm(text);
          }}
          reset={() => {
            setName("");
            setSearchTerm("");
            setPgaId("");
            setId("");
          }}
          onSelect={(item: any) => {
            setName(item.name);
            setSearchTerm("");
            setPgaId(item.pgaId);
            setId(item.id);
          }}
          menuClassName="max-h-[450px] max-w-[450px]"
        />
      </Modal>
    )
  );
}
