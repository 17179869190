import Button from "@/components/Button";
import Dialog from "@/components/Dialog";

export default function DeleteInteractionModal({
  open,
  setOpen,
  participantsCount,
  deleteInteraction,
  disabled
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  participantsCount: number;
  deleteInteraction: () => void;
  disabled: boolean;
}) {
  return (
    <Dialog
      open={open}
      onClosed={() => {
        setOpen(false);
      }}
      headline="Delete Interaction"
      className="w-[490px] p-6 pr-2"
      actions={
        <>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
            disabled={disabled}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            theme="error"
            trailingIcon={false}
            icon="delete"
            onClick={deleteInteraction}
            disabled={disabled}
          >
            Delete
          </Button>
        </>
      }
    >
      <p>
        <span>Are you sure you want to delete this interaction? </span>
        {participantsCount > 0 && (
          <span>
            {`It will be deleted for ${participantsCount + 1} people in this ${
              participantsCount > 1 ? "group interaction" : "interaction"
            }.`}
          </span>
        )}
        <span>This cannot be undone.</span>
      </p>
    </Dialog>
  );
}
