import Button from "@/components/Button";
import useModal from "@/hooks/useModal";
import { Event } from "@/pages/events/types";
import { CANCEL_EVENT_MUTATION } from "../../../queries";
import { useMutation } from "@apollo/client";
import { showSnackbar } from "@/lib/snackbarUtils";

export type CancelEvent = Pick<Event, "id" | "registrationCount" | "hasActiveOrders">;

interface CancelEventDialogProps {
  event: CancelEvent;
  onClosed: () => void;
}

export default function CancelEventModal({ event, onClosed }: CancelEventDialogProps) {
  const { close, Modal } = useModal();

  const [cancelEvent] = useMutation(CANCEL_EVENT_MUTATION, {
    onCompleted: (data) => {
      if (data.cancelEvent.success) {
        showSnackbar("Event cancelled successfully", { close: true });
        onClosed();
      } else {
        showSnackbar(`Error: ${data.cancelEvent.message}`);
      }
    },
    onError: (error) => {
      showSnackbar(`Error: ${error.message}`);
    }
  });

  const confirmationMessage = () => {
    const hasRegistrations = (event.registrationCount ?? 0) > 0;
    const hasActiveOrders = !!event?.hasActiveOrders;

    if (hasRegistrations && !hasActiveOrders) {
      return "Are you sure you want to cancel this event? All registrants will be notified. This cannot be undone.";
    } else if (hasRegistrations && hasActiveOrders) {
      return "Are you sure you want to cancel this event? All registrants will be notified and their payments refunded. This cannot be undone.";
    } else {
      return "Are you sure you want to cancel this event? This cannot be undone.";
    }
  };

  async function handleCancel() {
    try {
      await cancelEvent({ variables: { id: event.id } });
    } catch (e) {
      showSnackbar(`Error: ${e}`);
    } finally {
      await close();
    }
  }

  return (
    <Modal
      className="max-w-[423px] p-6 pr-2"
      onClosed={onClosed}
      headline={"Cancel Event"}
      data-testid="cancel-event-modal"
      action={
        <Button variant="filled" onClick={handleCancel} testId="cancel-event-button">
          Cancel Event
        </Button>
      }
    >
      <p>{confirmationMessage()}</p>
    </Modal>
  );
}
