import { ExternalLink } from "../withLink";
import { Action } from "./shared";

const SecondaryAction = ({ secondaryAction }: { secondaryAction?: Action }) =>
  secondaryAction ? (
    <ExternalLink href={secondaryAction.link}>
      <p className="text-label-large">{secondaryAction.label}</p>
    </ExternalLink>
  ) : null;

export default SecondaryAction;
