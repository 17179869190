import { ReactNode, useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import { ApolloError, FetchResult } from "@apollo/client";
import { MdDialog } from "@material/web/dialog/dialog";

import Dialog, { Props } from "@/components/Dialog";
import Button from "@/components/Button";

type ModalProps = Omit<Props, "open" | "actions"> & {
  action: ReactNode;
};

interface MutationResponse {
  success: boolean;
  message: string;
}

export default function useModal() {
  const ref = useRef<MdDialog>(null);
  const { enqueueSnackbar } = useSnackbar();

  const close = useCallback(() => ref.current?.close(), []);

  const open = useCallback(() => ref.current?.show(), []);

  const save = useCallback(
    async function <T>(
      save: () => Promise<FetchResult<T>>,
      getResponse: (data: T) => MutationResponse,
      successMessage: string
    ) {
      let snackMessage, variant;

      try {
        const { data } = await save();

        const { success = false, message: responseMessage = "Something went wrong" } =
          (data && getResponse(data)) || {};

        [snackMessage, variant] = success ? [successMessage, "default" as const] : [responseMessage, "error" as const];
      } catch (error) {
        snackMessage = (error as ApolloError).message;
        variant = "error" as const;
      }

      await ref.current?.close();
      enqueueSnackbar(snackMessage, { variant });
    },
    [enqueueSnackbar]
  );

  return {
    close,
    open,
    save,
    Modal: useCallback(
      ({ action, ...dialogProps }: ModalProps) => {
        const actions = (
          <div className="flex flex-wrap justify-end gap-1 items-center">
            <Button variant="text" onClick={close}>
              Cancel
            </Button>
            {action}
          </div>
        );

        return <Dialog open {...{ ref, actions }} {...dialogProps} />;
      },
      [close]
    )
  };
}
