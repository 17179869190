import { useMutation } from "@apollo/client";
import { useState } from "react";
import { APPROVE_CHANGE_REQUEST_MUTATION, Node } from "../queries";
import Button from "@/components/Button";
import Progress from "@/components/Progress";
import TextField from "@/components/TextField";
import useModal from "@/hooks/useModal";

export default function ApproveModal({
  changeRequest: { id: changeRequestId },
  onClosed
}: {
  changeRequest: Node;
  onClosed(): void;
}) {
  const { save, Modal } = useModal();
  const [message, setMessage] = useState("");

  const submitLabel = message ? "Approve and Send" : "Approve";

  const [approveChangeRequest, { loading }] = useMutation(APPROVE_CHANGE_REQUEST_MUTATION);

  const submit = () =>
    save(
      () => approveChangeRequest({ variables: { id: changeRequestId, message } }),
      (data) => data.approveChangeRequest,
      "Change request approved"
    );

  return (
    <Modal
      onClosed={onClosed}
      action={
        <Button disabled={loading} onClick={submit} icon="check">
          {submitLabel}
        </Button>
      }
      headline="Approve Change Request"
    >
      {loading ? (
        <Progress variant="linear" />
      ) : (
        <>
          <p className="max-w-sm">
            Are you sure you wish to approve this change request? The member information will be updated with the
            requested changes, and the member will receive notification of the update. You may include a message to the
            member as well.
          </p>

          <TextField
            className="w-full h-48 mt-6 mb-3"
            variant="outlined"
            type="textarea"
            label="Message to Member (Optional)"
            value={message}
            onChangeText={setMessage}
          />
        </>
      )}
    </Modal>
  );
}
