import { TypedDocumentNode, gql } from "@apollo/client";
import { Capability } from "./types";

export const CAPABILITIES_QUERY: TypedDocumentNode<{ me: { capabilities: Capability } }> = gql`
  query MeQuery {
    me {
      id
      capabilities {
        domains
        permissions
      }
    }
  }
`;
