import { ColumnDef, CellContext, Row, Cell } from "@tanstack/react-table";
import { RegistrantRow } from "../types";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { actionId, MenuItem } from "@/hooks/useActionMenu";
import { onActionMenuItemClick } from "@/pages/events/_utils";
import AvatarDetails from "@/components/AvatarDetails";
import Checkbox from "@/components/Checkbox";
import SegmentedButton from "@/components/SegmentedButton/SegmentedButton";
import IconButton from "@/components/IconButton";
import { renderEventRegistrationStatus, renderEventRegistrationAdditionalStatus } from "../_utils";
import Icon from "@/components/Icon";
import Tooltip from "@/components/Tooltip";

interface GetColumnsProps {
  hasRegistrations: boolean;
  setMenu: (options: any) => void;
  setUpdateStatusDialogOpen: (open: boolean) => void;
  onAttendanceUpdate?: (id: string, value: string | null) => void;
  onScoreUpdate?: (field: string, id: string, value: string) => void;
  setTargetEventRegistration: (targetEventRegistration: RegistrantRow) => void;
  handleStatusUpdate: (status: string | null, note: string | null, id: string | null) => void;
}

export const getColumns = ({
  hasRegistrations,
  setMenu,
  setUpdateStatusDialogOpen,
  onAttendanceUpdate,
  onScoreUpdate,
  setTargetEventRegistration,
  handleStatusUpdate
}: GetColumnsProps): ColumnDef<RegistrantRow, any>[] => [
  {
    id: "select",
    header: ({ table }: { table: any }) => (
      <Checkbox
        name="select-all"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={(checked) => table.toggleAllRowsSelected(checked)}
      />
    ),
    cell: ({ row }: { row: Row<RegistrantRow> }) => (
      <Checkbox
        name={`select-row-${row.id}`}
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={(checked) => row.toggleSelected(checked)}
      />
    ),
    enableSorting: false,
    size: 40
  },
  {
    cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
      const { profilePhoto, registrantName, programType } = cellProps.row.original;
      return <AvatarDetails picture={profilePhoto} title={registrantName} text={programType} />;
    },
    id: "participant",
    header: "Participant",
    size: 200,
    enableSorting: false,
    accessorFn: (row: RegistrantRow) => row.registrantName,
    meta: {
      className: getStickyColumnClasses(hasRegistrations)
    }
  },
  {
    accessorKey: "gender",
    header: "Gender",
    enableSorting: false
  },
  {
    accessorKey: "age",
    header: "Age",
    enableSorting: false
  },
  {
    id: "attendance",
    header: "Attendance",
    enableSorting: false,
    cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
      const { attended, sessionRegistrationId } = cellProps.row.original;

      const handleAttendanceChange = (value: string | null) => {
        const currentValue = attended === null ? null : attended ? "true" : "false";
        if (value === currentValue) {
          onAttendanceUpdate?.(sessionRegistrationId, null);
        } else {
          onAttendanceUpdate?.(sessionRegistrationId, value);
        }
      };

      return (
        <SegmentedButton
          key={`${sessionRegistrationId}-${attended}`}
          showCheckmark
          allowDeselect
          options={[
            { label: "Present", value: "true" },
            { label: "Absent", value: "false" }
          ]}
          value={attended === null ? null : attended ? "true" : "false"}
          onChange={handleAttendanceChange}
        />
      );
    }
  },
  {
    accessorKey: "score_18_1",
    header: "1st 18 Hole Score",
    enableSorting: false,
    meta: {
      className: getStickyColumnClasses(hasRegistrations),
      editable: true,
      onEdit: (value: string, cell: Cell<RegistrantRow, unknown>) => {
        onScoreUpdate?.("roundOneScore", cell.row.original.id, value);
      },
      editableIcon: (cell: Cell<RegistrantRow, unknown>) => {
        const { meets_qualifying_score_18_1, status } = cell.row.original;
        const meetsQualifyingScore = meets_qualifying_score_18_1 && status === "qualified";
        return meetsQualifyingScore && <Icon name="check" />;
      }
    }
  },
  {
    accessorKey: "score_18_2",
    header: "2nd 18 Hole Score",
    enableSorting: false,
    meta: {
      className: getStickyColumnClasses(hasRegistrations),
      editable: true,
      onEdit: (value: string, cell: Cell<RegistrantRow, unknown>) => {
        onScoreUpdate?.("roundTwoScore", cell.row.original.id, value);
      },
      editableIcon: (cell: Cell<RegistrantRow, unknown>) => {
        const { meets_qualifying_score_18_2, status } = cell.row.original;
        const meetsQualifyingScore = meets_qualifying_score_18_2 && status === "qualified";
        return meetsQualifyingScore && <Icon name="check" />;
      }
    }
  },
  {
    accessorKey: "score_total",
    header: "Total 36 Hole Score",
    enableSorting: false,
    meta: {
      className: getStickyColumnClasses(hasRegistrations)
    },
    cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
      const { score_total } = cellProps.row.original;
      return <span>{score_total || "--"}</span>;
    }
  },
  {
    accessorKey: "registrantStatus",
    header: "Status",
    enableSorting: false,
    cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
      const { status, additionalStatus, note } = cellProps.row.original;
      return additionalStatus ? (
        <>
          {note ? (
            <Tooltip
              variant="plain"
              color="black"
              supportingText={note}
              menuCorner="end-start"
              anchorCorner="start-start"
              positioning="fixed"
              anchorClassName="tablet:-mx-0 tablet:px-0"
            >
              <div className="flex flex-row items-center">
                <Icon name="info" className="text-sys-brand-error mr-1" />
                {renderEventRegistrationAdditionalStatus(additionalStatus)}{" "}
              </div>
            </Tooltip>
          ) : (
            renderEventRegistrationAdditionalStatus(additionalStatus)
          )}
        </>
      ) : status ? (
        renderEventRegistrationStatus(status)
      ) : null;
    }
  },
  {
    cell: (cellProps: CellContext<RegistrantRow, unknown>) => {
      const items: MenuItem[] = [
        {
          label: "Update Status",
          itemClassName: "w-[240px]",
          onClick: () => {
            setUpdateStatusDialogOpen(true);
            setTargetEventRegistration(cellProps.row.original);
          }
        },
        {
          label: "Reset Status",
          itemClassName: "w-[240px]",
          onClick: () => {
            handleStatusUpdate(null, null, cellProps.row.original.id);
          }
        }
      ];

      return (
        items.length > 0 && (
          <div
            className="text-right flex flex-row justify-end"
            data-testid="menu-icon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconButton
              id={actionId(cellProps.row.id)}
              onClick={() => onActionMenuItemClick({ setMenu, rowId: cellProps.row.id, items })}
              data-testid="actions"
              title="actions"
              name="more_vert"
              iconClassName="font-extrabold"
              skipMargin
            />
          </div>
        )
      );
    },
    id: "actions",
    size: 100
  }
];
