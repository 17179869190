import { gql } from "@apollo/client";

export const CREATE_MEMBERSHIP_VIDEO_MUTATION = gql`
  mutation CreateMembershipVideo($input: BackofficeCreateMembershipVideoInput!) {
    createMembershipVideo(videoInput: $input) {
      message
      success
    }
  }
`;

export const GET_SERIES_QUERY = gql`
  query GetSeries($live: Boolean) {
    videoSeries(live: $live)
  }
`;
