import { LitChipSet, LitFilterChip } from "./LitChip";

interface ChipProps {
  label?: string;
  onClick?(): void;
  onRemove?(e: Event): void;
  selected?: boolean;
  removable?: boolean;
}

export const ChipSet = LitChipSet;

export default function Chip({ label, ...props }: ChipProps) {
  return <LitFilterChip {...props}>{label}</LitFilterChip>;
}
