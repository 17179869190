import { useState, useEffect } from "react";
import { PathPattern, useMatch } from "react-router";

export const DEFAULT_TITLE = "Backoffice";

type Pattern = PathPattern<string> | string;

export default function usePageTitle(pattern: Pattern, title?: string) {
  const [t, setTitle] = useState<string>(title || DEFAULT_TITLE);
  const match = useMatch(pattern);

  useEffect(() => {
    if (title) setTitle(title);
    if (match && t) document.title = t;
  }, [t, title, match]);

  return setTitle;
}
