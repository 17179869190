import { PageInfo } from "@/components/Pagination";
import { TypedDocumentNode, gql } from "@apollo/client";
import { OrderStage, PaymentMethod, PaymentStatus, OrderPaymentStatus } from "../types";
import { Person } from "@/types";

export interface PaymentNode {
  id: string;
  stage: OrderStage;
  type: PaymentMethod;
  balance: number;
  checkNumber: string;
  updatedAt: string;
  description: string;
  person: Person;
  lineItems: { id: string }[];
  payer: {
    id: string;
    name: string;
  };
  amountCents: number;
  refundedAmountCents: number;
  status: PaymentStatus;
  orderPayments: {
    id: string;
    amountCents: number;
    ledgerEntries: { amountCents: number }[];
    order: {
      id: string;
      totalPaid: number;
      totalRefunded: number;
      description: string;
      lineItems: { id: string }[];
      addons: { productVariantLookupKey: string }[];
      stage: OrderStage;
      person: Person;
      paymentStatus: OrderPaymentStatus;
    };
  }[];
}

type Query = {
  commercePayments: {
    totalCount: number;
    nodes: PaymentNode[];
    pageInfo: PageInfo;
  };
};

export const PAYMENTS_QUERY: TypedDocumentNode<Query> = gql`
  query GetCommercePayments(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
    $type: [String!]
    $status: [String!]
  ) {
    commercePayments(
      first: $first
      last: $last
      after: $after
      before: $before
      filters: { search: $query, type: $type, status: $status }
    ) {
      nodes {
        id
        amountCents
        checkNumber
        paidAt
        refundedAmountCents
        status
        type
        source
        updatedAt
        payer {
          id
          name
        }
        orderPayments {
          id
          amountCents
          ledgerEntries {
            amountCents
          }
          order {
            id
            totalPaid
            totalRefunded
            description
            lineItems {
              id
            }
            addons {
              productVariantLookupKey
            }
            stage
            paymentStatus
            person {
              id
              firstName
              lastName
              profilePhoto
              pgaId
              mainProgramType {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
