export enum EventRegistrationStatus {
  PASSED = "passed",
  QUALIFIED = "qualified",
  FAILED = "failed"
}

export enum EventRegistrationAdditionalStatus {
  CHEATING = "cheating",
  DISQUALIFIED = "disqualified",
  EXCUSED = "excused",
  NO_CARD = "no_card",
  NO_SHOW = "no_show",
  POOR_CONDUCT = "poor_conduct",
  WITHDRAWAL = "withdrawal"
}

export interface EventRegistrant {
  id: string;
  pgaId: string;
  profilePhoto: string;
  programType: string;
  gender: string;
  age: number;
  registrantName: string;
  attended: boolean | null;
  status: string;
  customFields: {
    gender: string;
    date_of_birth: string;
  };
  sessionRegistrations: {
    id: string;
    attended: boolean;
  }[];
  patSubmission: {
    roundOne: {
      roundScore: string;
      meetsQualifyingScore: boolean;
    };
    roundTwo: {
      roundScore: string;
      meetsQualifyingScore: boolean;
    };
    totalScore: number;
    result: EventRegistrationStatus;
    additionalStatus: string;
    note: string;
  };
}

export interface Person {
  id: string;
  displayName: string;
  pgaId: string;
}

export interface RegistrantRow {
  id: string;
  pgaId: string;
  profilePhoto: string;
  programType: string;
  gender: string | null;
  age: number | null;
  registrantName: string;
  attended: boolean | null;
  score_18_1: string | "";
  meets_qualifying_score_18_1: boolean;
  score_18_2: string | "";
  meets_qualifying_score_18_2: boolean;
  score_total: number | null;
  status: string;
  sessionRegistrationId: string;
  additionalStatus: string;
  note: string;
}

export interface EventRegistrantNode {
  node: EventRegistrant;
}

export interface EventRegistrants {
  eventRegistrants: {
    edges: EventRegistrantNode[];
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
      endCursor?: string;
    };
  };
}

export interface Status {
  status: string;
}

export enum RegistrationStatus {
  ALL = "ALL",
  CANCELED = "CANCELED",
  REGISTERED = "REGISTERED"
}

export interface Variables {
  eventSlug?: string;
  search?: string;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  status?: RegistrationStatus;
}

export interface PATMetricType {
  label: string;
  value: string | number;
  onEdit?: (newValue: string) => void;
}
