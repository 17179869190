import { DateTime, Duration, Interval } from "luxon";

export function toLocaleDate(date: string, format: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT) {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return "";
  }
  return parsedDate.toLocaleString(format);
}

export function toUTCFormat(date: string, format: string = "LLL dd, yyyy") {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return null;
  }
  return parsedDate.toUTC().toFormat(format);
}

export function toFormat(date: string, format: string = "LLL dd, yyyy") {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return null;
  }
  return parsedDate.toFormat(format);
}

export const calculateYearsOfMembership = (membershipSince: string) => {
  const parsedDate = DateTime.fromISO(membershipSince);
  if (!parsedDate.isValid) {
    return null;
  }
  const duration = Interval.fromDateTimes(parsedDate, DateTime.now()).toDuration(["years", "months"]).toObject();

  const years = Math.floor(duration.years ?? 0);
  const months = Math.floor(duration.months ?? 0);

  if (years === 0) {
    return `${months} mo`;
  }
  return `${years} yrs ${months} mos`;
};

export function toDateTimeFormat(date: string, format: string = "LLL dd, yyyy t") {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return null;
  }
  return parsedDate.toFormat(format);
}

export function toDateFormat(date: string, format: string = "LLL dd, yyyy") {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return null;
  }
  return parsedDate.toFormat(format);
}

export function getTime(
  date: string,
  format: Intl.DateTimeFormatOptions = DateTime.TIME_SIMPLE,
  timezone: string = "utc"
) {
  const parsedDate = DateTime.fromISO(date, { zone: timezone });
  if (!parsedDate.isValid) {
    return null;
  }
  return parsedDate.toLocaleString(format);
}

export function getHours(duration: string) {
  const parsedTime = Duration.fromISO(duration);
  if (!parsedTime.isValid) {
    return null;
  }

  let humanDuration = parsedTime.toHuman({ unitDisplay: "short" });
  return humanDuration.replace(/,/g, "");
}

export function toISO(date: string) {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return "";
  }
  return parsedDate.toISO();
}

export function getAge(date: string): Duration | null {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return null;
  }
  return Interval.fromDateTimes(parsedDate, DateTime.now()).toDuration(["years", "months", "days", "hours"]);
}

export const getDiffYears = (date: string) => DateTime.now().diff(DateTime.fromISO(date), ["years"]).rescale().years;

export function isInFuture(date: string) {
  const parsedDate = DateTime.fromISO(date);
  if (!parsedDate.isValid) {
    return false;
  }
  return parsedDate > DateTime.now();
}
