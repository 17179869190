import Avatar from "@/components/Avatar";
import Button from "@/components/Button";
import { Controller } from "react-hook-form";
import { Person } from "./CreateGlobalInteractionSideSheet";

type argumentsType = {
  setMultiPersonMode: (value: boolean) => void;
  peopleArray: Person[];
  control: any;
  disabled?: boolean;
};

export const GroupInteraction = ({ setMultiPersonMode, peopleArray, control, disabled }: argumentsType) => {
  const selectedPeople = peopleArray.filter((person) => person.selected);
  const withoutPictures = selectedPeople.filter((person) => !person.profilePhoto);
  const displayablePeople = selectedPeople.filter((person) => person.profilePhoto);

  return (
    <>
      <div>Create one interaction that includes multiple people</div>

      <div className="flex">
        <Controller
          name="interactionPeople"
          control={control}
          rules={{
            validate: {
              selectedPeopleNotEmpty: () => selectedPeople.length > 0 || "At least one person must be added"
            }
          }}
          render={({ fieldState }) => (
            <div className={`w-full ${disabled ? "opacity-50" : ""}`}>
              <div className="flex">
                {fieldState.invalid && (
                  <div className="h-fit my-auto text-sys-brand-error text-label-large">{fieldState.error?.message}</div>
                )}
                {!fieldState.invalid && (
                  <>
                    {selectedPeople.length === 0 && <div className="h-fit my-auto">0 People Added</div>}
                    {displayablePeople.length > 0 && (
                      <div className="space-y-1 h-fit my-auto">
                        <div className="flex items-center">
                          {displayablePeople
                            .sort((a, b) => a.index - b.index)
                            .map((person, index) => (
                              <div key={index} className="flex items-center">
                                {index < 5 && (
                                  <div className={`${index !== 0 && "-ml-3"}`}>
                                    <div title={`${person.firstName} ${person.lastName}`}>
                                      <Avatar picture={person.profilePhoto} size="small" />
                                    </div>
                                  </div>
                                )}
                                {index === 4 && (
                                  <div className="text-sys-brand-on-surface-variant font-[500] text-[14px] h-fit my-auto ml-1">
                                    <Button
                                      disabled={disabled}
                                      variant="text"
                                      onClick={() => {
                                        setMultiPersonMode(true);
                                      }}
                                    >
                                      +{displayablePeople.length + withoutPictures.length - 5} more
                                    </Button>
                                  </div>
                                )}
                              </div>
                            ))}
                          {withoutPictures.length > 0 && displayablePeople.length < 5 && (
                            <div className="text-sys-brand-on-surface-variant font-[500] text-[14px] h-fit my-auto ml-1">
                              <Button
                                disabled={disabled}
                                variant="text"
                                onClick={() => {
                                  setMultiPersonMode(true);
                                }}
                              >
                                +{withoutPictures.length} more
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {displayablePeople.length === 0 && withoutPictures.length > 0 && (
                      <div className="text-sys-brand-on-surface-variant font-[500] text-[14px] h-fit my-auto ml-1 flex items-center">
                        <Avatar size="small" />
                        <div className="h-fit my-auto ml-1">
                          <Button
                            disabled={disabled}
                            variant="text"
                            onClick={() => {
                              setMultiPersonMode(true);
                            }}
                          >
                            {withoutPictures.length > 1 && <div>+{withoutPictures.length - 1} more</div>}
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="ml-auto">
                  <Button
                    disabled={disabled}
                    variant="text"
                    icon="add"
                    onClick={() => {
                      setMultiPersonMode(true);
                    }}
                  >
                    Add People
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </>
  );
};
