import Tag from "@/components/Tag";
import { Menu, MenuItem } from "@/hooks/useActionMenu";
import { TagColor } from "@/components/Tag/Tag";
import type { MaterialNames } from "@/components/material_symbol_names";
import { capitalize } from "lodash";
import { OrderStatus } from "./[eventSlug]/types";

type StatusTag = { color: TagColor; label: string; icon?: MaterialNames };

const STATUS_MAP: Record<OrderStatus, StatusTag> = {
  [OrderStatus.PAID]: { color: "primary", label: "Paid" },
  [OrderStatus.REFUNDED]: { color: "primary", label: "Paid", icon: "money_off" },
  [OrderStatus.OPEN]: { color: "tertiaryDim", label: "Open" },
  [OrderStatus.CANCELED]: { color: "surface", label: "Canceled" },
  [OrderStatus.PENDING]: { color: "tertiaryDim", label: "Pending" },
  [OrderStatus.FAILED]: { color: "error", label: "Failed", icon: "error" }
};

export const renderStatus = (stage: OrderStatus) => {
  const data = STATUS_MAP[stage] || { label: capitalize(stage), color: "tertiaryDim" };

  return (
    <Tag color={data?.color as TagColor} testId={data.label} iconName={data?.icon}>
      {data.label}
    </Tag>
  );
};

type ActionMenuClickProps = {
  setMenu: (menu: Menu) => void;
  rowId: string;
  items: MenuItem[];
};

export const onActionMenuItemClick = ({ setMenu, rowId, items }: ActionMenuClickProps) => {
  setMenu({ rowId, items });
};
