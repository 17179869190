import PGALogo from "@/assets/images/pga-logo.svg";
import { Link } from "@/router";

const NavLogo = ({ className }: { className?: string }) => (
  <Link to="/">
    <img src={PGALogo} className={className} />
  </Link>
);

export default NavLogo;
