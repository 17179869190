import { stateLayersColors } from "@/assets/theme/colors";

export function getStickyColumnClasses(hasData: boolean): string {
  return hasData
    ? "sticky left-0 bg-sys-brand-surface-container-lowest group-hover:bg-sys-brand-surface-container"
    : "";
}

export function updateFocusRing(
  checkboxRef: React.RefObject<HTMLDivElement>,
  element: string,
  backgroundColor?: string
) {
  if (checkboxRef.current) {
    const shadowRoot = checkboxRef.current.querySelector(element)?.shadowRoot;
    const focusRing = shadowRoot?.querySelector("md-focus-ring") as HTMLElement;

    if (focusRing)
      Object.assign(focusRing.style, {
        width: "40px",
        height: "40px",
        backgroundColor: backgroundColor ?? stateLayersColors["state-layers-brand-on-surface-opacity-0-08"],
        zIndex: -1,
        margin: element === "md-icon-button" ? "2px" : "0px"
      });
  }
}
