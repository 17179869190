import usePageTitle from "@/hooks/usePageTitle";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import Tabs from "@/components/Tabs";
import { useNavigate, Path } from "@/router";
import { useLocation } from "react-router-dom";
import usePeopleQuery from "./_hooks/usePeopleQuery";
import PeopleTable from "./_components/PeopleTable";
import useActionMenu from "@/hooks/useActionMenu";

export default function PeoplePage() {
  usePageTitle("/people", "People");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tableProps = usePeopleQuery();
  const { Menu, setMenu } = useActionMenu();

  return (
    <AppLayout>
      <PageHeading title="People" />

      <Tabs
        className="mb-8 mt-4 sm:mt-8"
        tabItems={[{ label: "All", to: "/people" as Path }].map(({ label, to }) => ({
          label,
          onClick: () => navigate(to),
          active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
        }))}
      />

      <Menu />
      <div className="w-full">
        <PeopleTable {...tableProps} setMenu={setMenu} />
      </div>
    </AppLayout>
  );
}
