export interface RegistrantRow {
  id: string;
  pgaId: string;
  profilePhoto: string;
  programType: string;
  registeredAt: string;
  registrantName: string;
  sessionCount: number;
  orderStatus: OrderStatus;
  additionalGuests: number;
}

export enum OrderStatus {
  OPEN = "open",
  PENDING = "pending",
  PAID = "paid",
  REFUNDED = "refunded",
  FAILED = "failed",
  CANCELED = "canceled"
}

export interface Person {
  id: string;
  pgaId: string;
  profilePhoto: string;
  displayName: string;
  programType: string;
}

export interface Registration {
  id: string;
  acceptedTerms: boolean;
  registeredAt: string;
  event: any;
  person: Person;
  customFields: {
    gender: string;
    dateOfBirth: string;
  };
}
export interface SessionRegistrations {
  id: string;
  attended: boolean | null;
  registration: Registration;
}

export interface EventRegistrant {
  id: string;
  pgaId: string;
  profilePhoto: string;
  programType: string;
  registeredAt: string;
  registrantName: string;
  sessionCount: number;
  order?: {
    status: OrderStatus;
  };
  customFields: {
    additional_guests: number;
  };
}

export interface EventRegistrantNode {
  node: EventRegistrant;
}

export interface EventRegistrants {
  eventRegistrants: {
    edges: EventRegistrantNode[];
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string;
      endCursor?: string;
    };
  };
}

export enum BackofficeEventsRegistrationStatusEnum {
  ALL = "ALL",
  CANCELED = "CANCELED",
  REGISTERED = "REGISTERED"
}

export interface Variables {
  eventSlug?: string;
  search?: string;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  status?: BackofficeEventsRegistrationStatusEnum;
}

export interface EventWrapper {
  event: Event;
}
export interface Event {
  id: string;
  status: string;
  title: string;
  hasWaitlist: boolean;
  waitlistEnabled: boolean;
  cancelledAt: string;
  eventType: {
    id: string;
    name: string;
  };
  collectsAttendance: boolean;
  sessionCount: number;
  registrationCount: number;
  hasActiveOrders: boolean;
  timezone: string;
  sessions: {
    id: string;
    orderKey: number;
    startsAt: string;
    endsAt: string;
    registrations: SessionRegistrations[];
  }[];
  patEventAttributes: {
    hostProfessional: {
      id: string;
      pgaId: string;
      displayName: string;
    };
    examiner: {
      id: string;
      pgaId: string;
      displayName: string;
    };
    courseConditions: string;
    problemsIssues: string;
    yardageMale: string;
    courseRatingMale: string;
    yardageMaleFiftyPlus: string;
    yardageFemale: string;
    courseRatingFemale: string;
    yardageFemaleFiftyPlus: string;
    targetScoreMale: string;
    targetScoreFemale: string;
  };
  customFields: string[];
}

export enum RegistrationStatus {
  ALL = "ALL",
  CANCELED = "CANCELED",
  REGISTERED = "REGISTERED"
}
