import ObjectHeading from "@/components/ObjectHeading";
import { ProfileSkeleton } from "@/components/Skeleton";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import type { Params, Path } from "@/router";
import { useQuery } from "@apollo/client";
import { LinkProps } from "@generouted/react-router/client";
import { FACILITY_DETAILS_HEADER_QUERY } from "../queries";
import { Facility } from "@/types";
import { capitalize } from "lodash";
import { useReturnTo } from "@/hooks/useSearchParams";
import PageTitle from "@/components/PageTitle";
import { useLocation } from "react-router-dom";

type ObjectHeadingWrapper = {
  facilityId: string;
  ButtonComp?: () => JSX.Element;
  isDetailsViewDesktop?: boolean;
};

type FacilityData = { facility: Facility };

export const ObjectHeadingWrapper = ({ facilityId, ButtonComp, isDetailsViewDesktop }: ObjectHeadingWrapper) => {
  const { data, loading, error } = useQuery<FacilityData>(FACILITY_DETAILS_HEADER_QUERY, {
    variables: { facilityId }
  });
  const location = useLocation();
  if (loading) return <ProfileSkeleton />;
  if (error) return <div>Error: {error.message}</div>;

  const { facility } = data!;
  const { name } = facility;

  const subText = [{ text: `Section: TODO` }, { text: `Type: TODO` }, { text: `Status: TODO` }];

  return (
    <>
      <PageTitle
        pattern={location.pathname}
        title={`Facilities | Facility Details | ${capitalize(location.pathname.split("/").pop())} | ${name}`}
      />
      <ObjectHeading
        title={name}
        iconName="store"
        ButtonComp={ButtonComp}
        isDetailsView
        subText={subText}
        isDetailsViewDesktop={isDetailsViewDesktop}
      />
    </>
  );
};

type Props = {
  facilityId: string;
  title?: string;
  ButtonComp?: () => JSX.Element;
};

export function FacilityDetailsHeader({ facilityId, title, ButtonComp }: Props) {
  const returnTo = useReturnTo();
  const backLinkTo = returnTo || "/facilities";

  return (
    <div data-testid="person-details-header">
      <div className="sm:pb-6">
        <PageHeading title={title || "Details"} backLink={{ to: backLinkTo } as LinkProps<Path, Params>} />
      </div>
      <ObjectHeadingWrapper facilityId={facilityId} ButtonComp={ButtonComp} />
    </div>
  );
}
