import { useNavigate, useSearchParams } from "react-router-dom";
import { createElement, useCallback } from "react";
import { useForm } from "react-hook-form";
import QuickSearch from "../_components/QuickSearch";
import { uniq } from "lodash";
import { Path } from "@/router";

const RESULTS_PATH: Path = "/people/search";

export const defaultValues = {
  contactPostalCode: "",
  email: "",
  firstName: "",
  informalName: "",
  lastName: "",
  pgaId: "",
  phone: "",
  memberClassifications: [] as string[],
  programTypes: [] as string[],
  sectionIds: [] as string[],
  statuses: [] as string[]
};

export type Query = typeof defaultValues;

export type KeysByValue<Q, V> = { [Key in keyof Q]: Q[Key] extends V ? Key : never }[keyof Q];

const idArrayKeys: KeysByValue<Query, string[]>[] = ["sectionIds", "programTypes", "statuses", "memberClassifications"];

const fragmentKeys: KeysByValue<Query, string>[] = [
  "contactPostalCode",
  "email",
  "firstName",
  "informalName",
  "lastName",
  "pgaId",
  "phone"
];

function toObject(params: URLSearchParams) {
  const query: Partial<Query> = {};

  for (const key of fragmentKeys) {
    const value = params.get(key);

    if (value) query[key] = value;
  }

  for (const key of idArrayKeys) {
    const values = params.getAll(key);

    if (values.length) query[key] = uniq(values).sort();
  }

  return query;
}

function fromObject(data: Partial<Query>) {
  const params = new URLSearchParams();
  for (const key of fragmentKeys) {
    const value = data[key];

    if (value) params.append(key, value);
  }

  for (const key of idArrayKeys) for (const value of uniq(data[key]).sort()) params.append(key, value);

  return params;
}

const getReturnTo = (url: Location) =>
  url.pathname === RESULTS_PATH
    ? new URLSearchParams(url.search).get("return_to")
    : url.toString().split(url.origin, 2)[1];

export function useQuickSearchParams() {
  const [params$, setParams] = useSearchParams();
  const params = toObject(params$);

  return {
    params,
    clearParam: useCallback(
      (param: keyof Query) => {
        if (params[param])
          setParams((params) => {
            params.delete(param);
            return params;
          });
      },
      [setParams, params]
    )
  };
}

export function useQuickSearch() {
  const { params: query } = useQuickSearchParams();

  const { handleSubmit, reset, control } = useForm<Query>({
    defaultValues,
    values: { ...defaultValues, ...query }
  });

  const navigate = useNavigate();

  return {
    submit: useCallback(async () => {
      let valid = true;

      await handleSubmit(
        (data: Query) => {
          const search = fromObject(data);
          const returnTo = getReturnTo(window.location);

          if (returnTo) search.set("return_to", returnTo);

          navigate({ pathname: RESULTS_PATH, search: search.toString() });
        },
        () => {
          valid = false;
        }
      )();

      return valid;
    }, [handleSubmit, navigate]),
    Form: useCallback(
      () =>
        createElement(QuickSearch, {
          control,
          reset() {
            reset(defaultValues);
          }
        }),
      [reset, control]
    )
  };
}
