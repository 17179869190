import Banner from "@/components/Banner";
import { Facility } from "../CreateGlobalInteractionSideSheet";

export const UnavailableFacilityBanner = ({ facility }: { facility: Facility }) => {
  return (
    <Banner
      variant="error"
      description={`Interactions cannot be added for people at ${facility.name} at this time. Try selecting another facility.`}
      inline
    />
  );
};
