import { gql } from "@apollo/client";

export const GET_PEOPLE = gql`
  query GetPeople($input: BackofficeCRMPeopleInput!) {
    people(input: $input) {
      nodes {
        id
        pgaId
        displayName
        profilePhoto
        phoneNumbers {
          phoneNumber
        }
      }
    }
  }
`;
