import React, { useState, useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import SegmentedButton, { SegmentedButtonOption } from "@/components/SegmentedButton/SegmentedButton";
import { DateTime } from "luxon";
import ClockIcon from "./ClockIcon";

export interface TimePickerProps {
  value: string;
  onChange: (time: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
  placeholder?: string;
  supportingText?: string;
  error?: boolean;
  errorMessage?: string;
}

type Period = "AM" | "PM";
interface TimeState {
  hour: string;
  minute: string;
  period: Period;
}

const formatTimeState = (timeString: string): TimeState => {
  const parsedTime = DateTime.fromFormat(timeString, "h:mm a");
  return {
    hour: parsedTime.toFormat("h"),
    minute: parsedTime.toFormat("mm"),
    period: parsedTime.hour >= 12 ? "PM" : "AM"
  };
};

const parseTime = (value: string, defaultValue: number = 0): number => {
  const parsed = parseInt(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

export default function TimePicker({
  value,
  onChange,
  onFocus,
  onBlur,
  label = "",
  placeholder = "Select Time",
  supportingText,
  error,
  errorMessage
}: TimePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState<TimeState>(formatTimeState(value));

  const pickerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const hourInputRef = useRef<HTMLInputElement>(null);

  const amPmOptions: SegmentedButtonOption[] = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" }
  ];

  const resetToPreviousValue = useCallback(
    (value: string) => {
      const prevValue = value ? DateTime.fromFormat(value, "h:mm a") : null;
      if (prevValue) {
        const newTime = formatTimeState(prevValue.toFormat("h:mm a"));
        setTime(newTime);
        onChange(value);
      }
    },
    [onChange]
  );

  const updateTime = useCallback(
    (newTime: TimeState) => {
      const { hour, minute, period } = newTime;
      if (hour === "" || minute === "") {
        onChange("");
        setTime(newTime);
      } else {
        const date = DateTime.fromObject({
          hour: period === "PM" ? (parseTime(hour) % 12) + 12 : parseTime(hour) % 12,
          minute: parseTime(minute)
        });
        const newTimeString = date.toFormat("h:mm a");
        onChange(newTimeString);
        setTime(newTime);
      }
    },
    [onChange]
  );

  const closeTimePicker = useCallback(() => {
    setIsOpen(false);
    if (!time.hour || !time.minute) {
      resetToPreviousValue(value);
    }
    onBlur?.();
  }, [time, value, onBlur, resetToPreviousValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        !pickerRef.current?.contains(event.target as Node)
      ) {
        closeTimePicker();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeTimePicker]);

  const toggleTimePicker = () => {
    if (!isOpen) {
      onFocus?.();
      setTimeout(() => {
        hourInputRef.current?.focus();
      }, 0);
    } else {
      closeTimePicker();
    }
    setIsOpen(!isOpen);
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newHour = e.target.value.replace(/[^0-9]/g, "");
    if (newHour !== "" && parseInt(newHour) > 12) {
      newHour = "12";
    }
    updateTime({ ...time, hour: newHour });
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newMinute = e.target.value.replace(/[^0-9]/g, "");
    if (newMinute !== "" && parseInt(newMinute) > 59) {
      newMinute = "59";
    }
    updateTime({ ...time, minute: newMinute });
  };

  const handlePeriodChange = (newPeriod: string) => {
    if (newPeriod === "AM" || newPeriod === "PM") {
      updateTime({ ...time, period: newPeriod as "AM" | "PM" });
    }
  };

  return (
    <div
      ref={pickerRef}
      data-testid="time-picker"
      className={classNames("relative w-full", {
        "filter-active": value
      })}
    >
      <div className="relative">
        <input
          type="text"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          onClick={toggleTimePicker}
          placeholder={placeholder}
          readOnly={true}
          className={classNames(
            "w-full h-[56px] pl-4 pr-10 border text-sys-brand-on-surface-variant placeholder-sys-brand-on-surface-variant border-sys-brand-outline bg-sys-brand-surface rounded-[0.25rem] cursor-pointer focus:outline-none",
            {
              "border-sys-brand-primary border-2 text-sys-brand-primary": isOpen && !error,
              "!border-sys-brand-error border-2 !text-sys-brand-error": error
            }
          )}
        />
        <ClockIcon
          className={classNames("absolute right-3 top-1/2 transform -translate-y-1/2", {
            "text-sys-brand-primary": isOpen && !error,
            "text-sys-brand-on-surface-variant": !isOpen && !error,
            "!text-sys-brand-error": error
          })}
          onClick={toggleTimePicker}
        />
      </div>
      {label && (
        <label
          className={classNames(
            "absolute px-1 -top-[8px] left-[15px] bg-white text-body-small text-sys-brand-on-surface-variant",
            {
              "text-sys-brand-primary": isOpen && !error,
              "!text-sys-brand-error": error
            }
          )}
        >
          {label}
        </label>
      )}
      {isOpen && (
        <div
          ref={popupRef}
          className={classNames(
            "absolute top-[calc(100%+8px)] left-0 bg-sys-brand-surface-container-low border border-sys-brand-outline-variant rounded-[16px] p-6 z-10",
            {
              "opacity-100 pointer-events-auto": isOpen,
              "opacity-0 pointer-events-none": !isOpen
            }
          )}
        >
          <h3 className="text-label-large leading-4 tracking-[0.5px] text-left text-sys-brand-on-surface-variant mt-2 mb-6">
            Enter time
          </h3>
          <div className="flex items-start justify-start mb-4">
            <div className="flex flex-col items-start caret-[#0053ff] h-24">
              <input
                ref={hourInputRef}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                value={time.hour}
                onChange={handleHourChange}
                onFocus={handleInputFocus}
                placeholder="HH"
                maxLength={2}
                className="font-roboto text-[45px] font-normal leading-[52px] text-center text-sys-brand-on-surface bg-sys-brand-surface-container-high outline-none w-24 h-[72px] rounded-lg focus:bg-sys-brand-primary-container focus:border-2 focus:border-sys-brand-primary"
              />
              <span className="text-body-small leading-4 tracking-[0.4px] text-left text-sys-brand-on-surface-variant mt-2">
                Hour
              </span>
            </div>
            <div className="flex flex-col justify-center items-center mx-2 h-[60px] mt-[6px]">
              <span className="w-[6px] h-[6px] bg-sys-brand-on-surface rounded-full mb-2 mt-1"></span>
              <span className="w-[6px] h-[6px] bg-sys-brand-on-surface rounded-full mt-3 mb-1"></span>
            </div>
            <div className="flex flex-col items-start caret-[#0053ff] h-24">
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                value={time.minute}
                onChange={handleMinuteChange}
                onFocus={handleInputFocus}
                placeholder="MM"
                maxLength={2}
                className="font-roboto text-[45px] font-normal leading-[52px] text-center text-sys-brand-on-surface bg-sys-brand-surface-container-high outline-none w-24 h-[72px] rounded-lg focus:bg-sys-brand-primary-container focus:border-2 focus:border-sys-brand-primary"
              />
              <span className="text-body-small leading-4 tracking-[0.4px] text-left text-sys-brand-on-surface-variant mt-2">
                Minute
              </span>
            </div>
            <div className="flex flex-col items-start ml-2 h-24">
              <div className="h-[72px] flex flex-col">
                <SegmentedButton
                  options={amPmOptions}
                  value={time.period}
                  onChange={(value) => handlePeriodChange(value || "")}
                  vertical
                  selectedColor="bg-sys-brand-primary-container"
                  textColor="text-sys-brand-on-secondary-container"
                  backgroundColor="bg-sys-brand-surface-container-high"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-[9pt] text-sys-brand-on-surface-variant ml-4">{supportingText}</div>
      {error && <div className="text-body-small text-sys-brand-error ml-4 mt-1">{errorMessage}</div>}
    </div>
  );
}
