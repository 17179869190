import SkeletonBase from "./Skeleton";

type CardSkeletonProps = {
  maxWidth?: number;
  width?: number;
  className?: string;
  rows?: number;
};

export default function CardSkeleton({ maxWidth = 100, width, className, rows = 3 }: CardSkeletonProps) {
  const style = {
    width: width ? `${width}%` : `${Math.random() * (maxWidth - 25) + 25}%`
  };

  return (
    <div
      className={`border-0 border-b border-sys-brand-secondary-container rounded-2xl p-6 shadow-web-1 ${className}`}
      data-testid="member-insights-skeleton"
      style={style}
    >
      <SkeletonBase rows={rows} />
    </div>
  );
}
