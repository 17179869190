import { useQuery } from "@apollo/client";

import Table, { type Row } from "@/components/Table";
import Tabs from "@/components/Tabs";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useNavigate } from "@/router";
import { ALL_SECTIONS_QUERY } from "./queries";
import { Section } from "@/types";
import usePageTitle from "@/hooks/usePageTitle";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";

const pattern = "/sections";

export default function SectionManagement() {
  useRequireCapability({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewPeople });

  const navigate = useNavigate();

  usePageTitle(pattern, "Sections");

  function onClickRow(row: Row<Section>) {
    navigate(`${pattern}/:sectionId`, { params: { sectionId: row.original.id } });
  }

  const { data, loading } = useQuery<{ sections: Section[] }>(ALL_SECTIONS_QUERY);
  return (
    <AppLayout>
      <PageHeading title="Sections" />
      <div className="my-8">
        <Tabs
          tabItems={[
            {
              active: true,
              label: "Sections"
            }
          ]}
        />
      </div>
      <Table
        loading={loading}
        onClickRow={onClickRow}
        data={data?.sections || []}
        columns={[
          {
            accessorKey: "name",
            header: "Name",
            enableSorting: false,
            meta: {
              className: getStickyColumnClasses(!!data?.sections.length)
            }
          },
          { accessorKey: "legacyId", header: "PGA ID", enableSorting: false }
        ]}
        renderEmptyState={() => <div>No sections found</div>}
      />
    </AppLayout>
  );
}
