import Dialog from "@/components/Dialog";
import TextField from "@/components/TextField";
import Button from "@/components/Button";
import { useState, useEffect } from "react";

interface EditMetricModalProps {
  open: boolean;
  onClose: () => void;
  fieldName: string;
  initialValue: string | number;
  onSave: (value: string) => void;
}

export default function EditMetricModal({ open, onClose, fieldName, initialValue, onSave }: EditMetricModalProps) {
  const [value, setValue] = useState(String(initialValue));

  useEffect(() => {
    setValue(String(initialValue));
  }, [initialValue]);

  return (
    <Dialog
      open={open}
      onClosed={onClose}
      headline={`Edit Value`}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave(value);
              onClose();
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <TextField label={fieldName} value={value} onChange={(e) => setValue((e.target as HTMLInputElement).value)} />
    </Dialog>
  );
}
