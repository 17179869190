import { useParams } from "@/router";
import { useQuery } from "@apollo/client";
import { changeTypeToDisplayName, approvalTypeToDisplayName, ChangeSetting } from "./utils";
import Progress from "@/components/Progress";
import { SECTION_CHANGEFORM_SETTINGS_QUERY } from "./queries";
import Table, { Row } from "@/components/Table";
import usePageTitle from "@/hooks/usePageTitle";
import Heading from "@/layouts/ScreenHeading";
import Icon from "@/components/Icon";
import { useDenyAuthorization } from "@/hooks/useCapabilities";
import { useEffect, useState } from "react";
import EditModal from "./_components/EditModal";

const pattern = "/sections/:sectionId/change-requests/settings";

const columnConfig = {
  enableSorting: false,
  meta: { className: "py-2.5" }
};

export default function ChangeRequestSettings() {
  const [settingId, setEditSettingId] = useState<string>();
  const { sectionId } = useParams(pattern);
  usePageTitle(
    "/sections/:sectionId/change-requests/settings",
    "Sections | Section Details | Change Requests | Settings"
  );

  const { data, loading } = useQuery(SECTION_CHANGEFORM_SETTINGS_QUERY, {
    variables: { sectionId }
  });

  const denyAuthorization = useDenyAuthorization();

  useEffect(() => {
    if (data && !data.section.permissions.manageChangeformSettings) denyAuthorization();
  }, [data, denyAuthorization]);

  const { changeformSettings = [], name: sectionName = "", legacyId } = data?.section || {};

  function onClickRow({ original: { id } }: Row<ChangeSetting>) {
    setEditSettingId(id);
  }

  function closeEditModal() {
    setEditSettingId(undefined);
  }

  const sortedChangeformSettings = [...changeformSettings].sort((a, b) => a.changeType.localeCompare(b.changeType));

  const selectedSetting = changeformSettings.find((setting) => setting.id === settingId);

  return (
    <div>
      {selectedSetting && <EditModal setting={selectedSetting} onClosed={closeEditModal} />}
      <Heading
        pageTitle="Edit Settings"
        objectTitle={sectionName}
        subText={`PGA ID: ${legacyId}`}
        backLink={{ to: "/sections/:sectionId/change-requests", params: { sectionId } }}
      />
      <div className="ml-4 mt-8 flex flex-col sm:flex-row items-center justify-between">
        <div className="flex-col sm:w-3/4 w-full">
          <p className="text-title-medium">Member and Associate Change Request Settings</p>
          <p className="text-body-medium mt-1">
            Set or update the active (manual) approvals required for member and associate change requests.
          </p>
        </div>
      </div>
      <div className="ml-4 mt-6">
        <Table
          loading={loading}
          columns={[
            {
              ...columnConfig,
              accessorKey: "changeType",
              header: "Change Type",
              cell: ({ getValue }) => changeTypeToDisplayName(getValue())
            },
            {
              ...columnConfig,
              accessorKey: "approvalType",
              header: "Approval Type",
              cell: ({ getValue }) => approvalTypeToDisplayName(getValue())
            },
            {
              ...columnConfig,
              cell: () => (
                <div className="flex justify-end mr-1">
                  <Icon name="edit" />
                </div>
              ),
              id: "actions"
            }
          ]}
          data={sortedChangeformSettings}
          onClickRow={onClickRow}
          renderEmptyState={() => <Progress />}
        />
      </div>
    </div>
  );
}
