const theme = {
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  list: {
    ol: "list-decimal pl-6",
    ul: "list-disc pl-6",
    listitem: "editor-listitem"
  },
  text: {
    bold: "font-bold",
    italic: "italic",
    underline: "underline"
  },
  link: "text-sys-brand-primary cursor-pointer"
};

export default theme;
