import { useCallback, useEffect, useRef, useState } from "react";
import {
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  CLICK_COMMAND
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";

import { mergeRegister } from "@lexical/utils";
import Icon from "@/components/Icon";
import { getSelectedNode, positionEditorElement } from "../utils";

export default function FloatingLinkEditor({ editor }: { editor: any }) {
  const editorRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(true);
  const [lastSelection, setLastSelection] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const toggleEditorPosition = ({
    shouldPositionEditor,
    nativeSelection,
    rootElement,
    editorElem,
    selection
  }: {
    shouldPositionEditor: boolean;
    nativeSelection: any;
    rootElement: any;
    editorElem: any;
    selection: any;
  }) => {
    const activeElement = document.activeElement;
    if (shouldPositionEditor) {
      const domRange = nativeSelection?.getRangeAt(0);
      let rect;
      if (nativeSelection?.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange?.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect as any);
      }

      setLastSelection(selection as any);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }
  };

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    const shouldPositionEditor =
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode);

    toggleEditorPosition({
      shouldPositionEditor,
      nativeSelection,
      rootElement,
      editorElem,
      selection
    });

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }: any) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),

      editor.registerCommand(
        CLICK_COMMAND,
        () => {
          setEditMode(false);
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  const EditActions = () => {
    return (
      <div className="flex justify-between items-center gap-1">
        <div
          className="link-edit block w-6 h-6 rounded-md"
          role="button"
          tabIndex={0}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            positionEditorElement(editorRef.current, null);
            if (!isConfirmed) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
            }
            setEditMode(false);
          }}
        >
          <Icon name="close" />
        </div>
        <div
          className="link-edit block w-6 h-6 rounded-md"
          role="button"
          tabIndex={0}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            if (lastSelection !== null) {
              if (linkUrl !== "") {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                setTimeout(() => {
                  positionEditorElement(editorRef.current, null);
                }, 100);
                setEditMode(false);
                setIsConfirmed(true);
              }
            }
          }}
        >
          <Icon name="check_circle" />
        </div>
      </div>
    );
  };

  const DefaultActions = () => {
    return (
      <div className="flex justify-between items-center gap-1">
        <div
          className="link-edit block w-6 h-6 rounded-md"
          role="button"
          tabIndex={0}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            setEditMode(true);
          }}
        >
          <Icon name="edit" />
        </div>
        <div
          className="link-edit block w-6 h-6 rounded-md"
          role="button"
          tabIndex={0}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            setLinkUrl("");
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
          }}
        >
          <Icon name="delete" />
        </div>
      </div>
    );
  };

  return (
    <div
      ref={editorRef}
      className="link-editor absolute border border-sys-brand-outline-variant bg-sys-brand-surface rounded-md p-2 w-full max-w-sm flex justify-between items-center"
    >
      <div className="link-input flex justify-between items-center w-full">
        {isEditMode ? (
          <input
            ref={inputRef}
            className="link-input text-body-medium outline-none w-[300px]"
            value={linkUrl}
            onChange={(event) => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (lastSelection !== null) {
                  if (linkUrl !== "") {
                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                    setTimeout(() => {
                      positionEditorElement(editorRef.current, null);
                    }, 100);
                    setEditMode(false);
                    setIsConfirmed(true);
                  }
                }
              }
            }}
          />
        ) : (
          <a
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-body-medium text-sys-brand-primary underline max-w-[300px] overflow-hidden overflow-ellipsis"
          >
            {linkUrl}
          </a>
        )}
        {isEditMode ? <EditActions /> : <DefaultActions />}
      </div>
    </div>
  );
}
