import { useFlags } from "@/hooks/useFeatureFlags";
import AppLayout from "@/layouts/AppLayout";
import { Outlet, useParams } from "react-router-dom";
import { PersonDetailsHeader } from "../_components/PersonDetailsHeader";
import { getPersonNavLinks } from "../_utils";
import TabLayout from "./_TabLayout";

export default function PersonDetailsLayout() {
  const { pgaId } = useParams();
  const flags = useFlags();

  if (!pgaId) {
    return null;
  }

  const navLinks = getPersonNavLinks(pgaId, flags);

  return (
    <AppLayout navLinks={navLinks} interior bottomBar>
      <PersonDetailsHeader pgaId={pgaId} title="Billing" />
      <TabLayout>
        <Outlet />
      </TabLayout>
    </AppLayout>
  );
}
