import { ApolloClient, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import buildCache from "./cache";

import { ReactNode, useMemo } from "react";
const uri = import.meta.env.VITE_GRAPHQL_URI;
const httpLink = createHttpLink({
  uri: (operation) => `${uri}?op=${operation.operationName}`
});

function buildClient(getToken: () => Promise<string>) {
  const authLink = setContext((_, { headers }) =>
    getToken().then((token) => ({ headers: { ...headers, authorization: `Bearer ${token}` } }))
  );

  const link = authLink.concat(httpLink);
  return new ApolloClient({
    cache: buildCache(),
    link,
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } }
  });
}

export default function GraphqlProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();
  const client = useMemo(() => buildClient(getAccessTokenSilently), [getAccessTokenSilently]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
