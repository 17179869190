import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import Icon from "@/components/Icon";
import { GET_FACILITIES } from "../../queries";
import { useQuery } from "@apollo/client";
import { Facility } from "../types";

interface FacilitiesQueryData {
  facilities: {
    nodes: Facility[];
  };
}

interface FacilityAutocompleteProps {
  sectionId: string;
  onFacilitySelect?: (facility: Facility) => void;
}

const arrayWrap = (x: string) => (!!x && [x]) || [];

const FacilityAutocomplete = ({ sectionId, onFacilitySelect }: FacilityAutocompleteProps) => {
  const {
    control,
    setValue,
    trigger,
    clearErrors,
    watch,
    formState: { errors }
  } = useFormContext();
  const [variables, setVariables] = useState({ input: { sectionIds: arrayWrap(sectionId) } });
  const { data: facilityData } = useQuery<FacilitiesQueryData>(GET_FACILITIES, { variables });

  function selectFacility(value: any, setValue: any) {
    setValue("facilityId", value.id);
    setValue("facility", value.name);
    onFacilitySelect?.(value);
    clearErrors(["facility", "facilityId"]);
  }

  const facility = watch("facility");
  const facilityId = watch("facilityId");

  useEffect(() => {
    setVariables((prevValue) => ({
      ...prevValue,
      ...(facility ? { input: { sectionIds: arrayWrap(sectionId), search: facility } } : {})
    }));
  }, [facility, sectionId]);

  return (
    <>
      <Controller
        name="facility"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Autocomplete
              label="Facility"
              query={value}
              data={facilityData?.facilities?.nodes || []}
              onChangeText={(value) => {
                onChange(value);
                setValue("facility", value);
                setValue("facilityId", "");
                if (value && errors.facility) {
                  clearErrors("facility");
                }
              }}
              onBlur={() => {
                onBlur();
                if (value && !facilityId) {
                  setValue("facility", "");
                  trigger("facility");
                }
              }}
              onMouseDown={(e: MouseEvent) => {
                e.preventDefault();
              }}
              onSelect={(value) => selectFacility(value, setValue)}
              reset={() => {
                setValue("facility", "");
                setValue("facilityId", "");
              }}
              error={!!errors?.facility}
              errorText={errors?.facility?.message as string}
              renderItem={(data) => (
                <div className="flex items-start border-bottom">
                  <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
                    <Icon name="location_on" size={24} />
                  </div>
                  <div>
                    <div className="text-body-large">{data.name}</div>
                    {data.addresses[0] && (
                      <div className="text-body-medium text-sys-brand-on-surface-varian">
                        {data.addresses[0]?.address1}, {data.addresses[0]?.city}, {data.addresses[0]?.state}
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          );
        }}
      />
    </>
  );
};

export default FacilityAutocomplete;
