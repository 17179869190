import { useFormContext, useWatch } from "react-hook-form";
import { sumBy } from "lodash";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import Divider from "@/components/Divider";

interface Product {
  description: string;
  variantId: string;
  variantName: string;
  variantPrice: number;
  collection: string;
}

interface LineItem {
  name: string;
  price: number;
  collection: string;
  subText: string;
}

const getDisplayName = ({ variantName, description, collection }: Product) => {
  const baseName = variantName || description;

  switch (collection) {
    case "onsite":
      return `${baseName} (Onsite)`;
    case "optional":
      return `${baseName} (Optional)`;
    default:
      return baseName;
  }
};

const getSubtext = (product: Product) =>
  product.collection === "onsite"
    ? `Not included in your online payment, ${centsToDollarsFormatted(product.variantPrice)} will be collected onsite.`
    : product.collection === "optional"
    ? "Optional add-on included in your online payment"
    : "";

const PaymentSummary = () => {
  const { control } = useFormContext();
  const products = useWatch({
    control,
    name: "products"
  });

  const lineItems = products.map(
    (product: Product): LineItem => ({
      name: getDisplayName(product),
      price: product.variantPrice ? product.variantPrice : 0,
      collection: product.collection,
      subText: getSubtext(product)
    })
  );

  const dueNowItems = lineItems.filter((item: LineItem) => item.collection !== "onsite");
  const dueOnsiteItems = lineItems.filter((item: LineItem) => item.collection === "onsite");

  const dueNowTotal = sumBy(dueNowItems, "price");
  const dueOnsiteTotal = sumBy(dueOnsiteItems, "price");
  const total = dueNowTotal + dueOnsiteTotal;

  return (
    <div>
      <h3 className="text-title-medium mb-3">Payment Summary (Registrant view)</h3>
      <>
        {lineItems.map((item: LineItem, index: number) => (
          <div key={index} className="flex justify-between items-center text-body-normal py-2">
            <div className="flex flex-col">
              <span>{item.name}</span>
              {item.subText && (
                <span className="text-body-medium text-sys-brand-on-surface-variant">{item.subText}</span>
              )}
            </div>
            <span>{centsToDollarsFormatted(item.price)}</span>
          </div>
        ))}
        {lineItems.length > 0 && (
          <div className="py-3">
            <Divider />
          </div>
        )}
        <div className="flex justify-between text-title-medium py-2">
          <span>Total Due</span>
          <span>{centsToDollarsFormatted(total)}</span>
        </div>
        {dueOnsiteItems.length > 0 && (
          <>
            <div className="flex justify-between text-body-normal py-2">
              <span>Due Onsite</span>
              <span>{centsToDollarsFormatted(dueOnsiteTotal)}</span>
            </div>
            <div className="flex justify-between text-body-normal py-2">
              <span>Due Now</span>
              <span>{centsToDollarsFormatted(dueNowTotal)}</span>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default PaymentSummary;
