import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useMutation } from "@apollo/client";
import { OrderStage } from "../../../types";
import { CANCEL_ORDER_MUTATION } from "../queries";
import { OrderType } from "../types";
import { useState } from "react";
import { showSnackbar } from "@/lib/snackbarUtils";

export const CancelOrderModal = ({
  onDismiss,
  order,
  refetch,
  returnTo
}: {
  onDismiss: () => void;
  order: OrderType;
  refetch?: () => void;
  returnTo?: () => void;
}) => {
  const [cancelOrderMutation, { loading }] = useMutation(CANCEL_ORDER_MUTATION);
  const headline = order.stage === OrderStage.DRAFT ? "Cancel Draft Order" : "Cancel Order";

  let content =
    order.stage === OrderStage.DRAFT
      ? "Are you sure you want to cancel this order?"
      : "Are you sure you want to cancel this order? The payor will no longer be able to pay this order. This cannot be undone.";
  const [errorContent, setErrorContent] = useState<string>("");

  async function cancelCommerceOrder() {
    const { data } = await cancelOrderMutation({
      variables: { input: { commerceOrderId: order.id } }
    });

    if (data.cancelCommerceOrder.success) {
      refetch && refetch();
      returnTo && returnTo();
      showSnackbar("Order canceled successfully");
      onDismiss();
    } else {
      setErrorContent(data.cancelCommerceOrder.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Go Back
      </Button>

      <Button onClick={cancelCommerceOrder} disabled={loading}>
        Cancel Order
      </Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline={headline}>
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      {content}
    </Dialog>
  );
};
