import { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import useCapabilities, { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useNavigate } from "@/router";
import { useSearch } from "@/hooks/useSearch";
import usePageTitle from "@/hooks/usePageTitle";
import usePagination from "@/hooks/usePagination";
import { ORDERS_QUERY } from "./queries";
import { Controller } from "react-hook-form";
import { OrderStage } from "../types";
import { Variables } from "./types";
import { RowSelectionState } from "@tanstack/react-table";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import getActiveFilters from "@/lib/filterHelpers";
import { renderStatus } from "../_utils";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { intersectionBy, reject, isEmpty } from "lodash";
import pluralize from "@/lib/pluralize";
import TabLayout from "../_TabLayout";
import SearchInput from "@/components/SearchInput";
import CreateOrderModal from "./_components/CreateOrderModal";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import Chip from "@/components/Chip";
import Tooltip from "@/components/Tooltip";
import AvatarDetails from "@/components/AvatarDetails";
import Icon from "@/components/Icon";
import TableDetailsPanel from "@/components/TableDetailsPanel";
import MultiSelect from "@/components/MultiSelect";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { useParams } from "@/hooks/useSearchParams";
import PersonDetailsPanel from "./_components/PersonDetailsPanel";
import { OrderRow, OrderNode } from "../types";
import { Path } from "@/router";
import IconButton from "@/components/IconButton";

const mapCommerceOrders = (data: OrderNode[]) =>
  (data || []).map(({ updatedAt, dueDate, balance, lineItems, ...order }) => ({
    ...order,
    pgaId: order.person.pgaId,
    updatedAt: formatDate(updatedAt),
    dueDate: formatDate(dueDate),
    balance: centsToDollarsFormatted(balance) || undefined,
    products: lineItems.length,
    lineItems: lineItems
  }));

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

export default function OrdersPage() {
  usePageTitle("/billing/orders", "Billing | Orders");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const { params, addSearchParam, deleteSearchParams, searchParamsUrl } = useParams();
  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState<boolean>(false);
  const rowSelected = Object.keys(rowSelection).length;
  const [selectedData, setSelectedData] = useState<OrderRow[]>([]);
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);
  const { hasPermission } = useCapabilities();
  const [selectedStatusValues, setSelectedStatusValues] = useState<string[]>(params.statuses || []);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [selectedPgaId, setSelectedPgaId] = useState<string | null>(null);
  const togglesColumns = ["updatedAt", "description", "pgaId", "dueDate"];
  const defaultColumns = ["fullName", "products", "balance", "stage", ...togglesColumns];

  function transformArrayToObject(columns: string[], state: boolean): { [key: string]: boolean } {
    const obj: { [key: string]: boolean } = {};
    columns.forEach((column) => {
      obj[column] = state;
    });
    return obj;
  }

  const [columnVisibility, setColumnVisibility] = useState(transformArrayToObject(defaultColumns, true));

  const [variables, setVariables] = useState<Variables>(params);
  const { data, loading } = useQuery(ORDERS_QUERY, { variables: { ...variables, ...paginationVariables } });
  const [selectedOrdersQuery, { data: allSelectedData, loading: allSelectedLoading }] = useLazyQuery(ORDERS_QUERY);

  const navigate = useNavigate();

  const pattern = "/billing/orders";
  function onClickRow(row: Row<OrderRow>) {
    navigate(`${pattern}/${row.original.id}?return_to=/billing/orders&${searchParamsUrl}` as Path);
  }

  const { control } = useSearch("name", params.query || "");
  const orderData: OrderRow[] = mapCommerceOrders(data?.commerceOrders.nodes || []);
  const allSelectedDataArray: OrderRow[] = mapCommerceOrders(allSelectedData?.commerceOrders.nodes || []);

  const isNotPayable = selectedData.some((item) => !item.payable);

  function convertToUrlParams(rowSelection: RowSelectionState) {
    return Object.keys(rowSelection)
      .map((item) => `orderId=${item}`)
      .join("&");
  }
  const selectableRows = orderData.filter((row) => row.payable || row.stage === OrderStage.DRAFT);
  const getIsSomeRowsSelected = () => {
    return (
      intersectionBy(selectedData, selectableRows, "id").length > 0 && selectedData.length !== selectableRows.length
    );
  };

  const filters = {
    status: selectedStatusValues.length > 0
  };
  const { isActive } = getActiveFilters(filters, filters, true);

  function openDetailsPanel(pgaId: string) {
    setSelectedPgaId(pgaId);
    setIsDetailsPanelOpen(true);
    setColumnVisibility({
      ...columnVisibility,
      ...transformArrayToObject(togglesColumns, false)
    });
  }

  function closeDetailsPanel() {
    setSelectedPgaId(null);
    setIsDetailsPanelOpen(false);
    setColumnVisibility({
      ...columnVisibility,
      ...transformArrayToObject(togglesColumns, true)
    });
    setActiveRow(null);
  }

  const getRowClasses = (row: Row<OrderRow>) => {
    if (row.original.id === activeRow) {
      return "!bg-sys-brand-surface-container-high";
    }
    return "";
  };

  return (
    <TabLayout>
      <div className="flex w-full">
        <div className="w-full">
          <div className="pt-6 sm:pt-8 px-0 sm:px-4 pb-6 justify-between tablet:flex">
            <div className="max-w-[1000px] pr-5 min-h-[48px]">
              <p className="text-body-medium">
                View all orders, including order details and personal information. Track the status of each order,
                search for specific orders, or take action such as updating an order status or cancelling an order.
              </p>
            </div>

            <div className="mt-4 tablet:mt-0">
              {rowSelected === 0 && hasPermission(PERMISSION.manageOrders) && (
                <Button variant="filled" icon="add" onClick={() => setIsCreateOrderModalOpen(true)}>
                  Create Order
                </Button>
              )}
            </div>
          </div>

          <div className="w-full">
            <Table
              data={(isSelectedOnly ? allSelectedDataArray : orderData) || []}
              loading={loading || allSelectedLoading}
              enableRowSelection={(row) => row.original.payable || row.original.stage === OrderStage.DRAFT}
              onClickRow={onClickRow}
              getRowClasses={getRowClasses}
              renderExpandedRow={({ row }) => (
                <TableDetailsPanel
                  data={row.original.lineItems || []}
                  columns={[
                    {
                      accessorKey: "name",
                      header: "Product Name",
                      enableSorting: false,
                      size: 400,
                      cell: (cellProps) => {
                        return <span>{cellProps.row.original.name}</span>;
                      }
                    },
                    {
                      accessorKey: "priceCents",
                      header: "Price",
                      enableSorting: false,
                      meta: { className: "text-left pl-20", headerClassName: "pl-16" },
                      cell: (cellProps) => {
                        return <span>{centsToDollarsFormatted(cellProps.row.original.priceCents)}</span>;
                      }
                    },
                    {
                      accessorKey: "quantity",
                      header: "Quantity",
                      enableSorting: false,
                      meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" }
                    },
                    {
                      accessorKey: "totalPrice",
                      header: "Amount",
                      enableSorting: false,
                      meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" },
                      cell: (cellProps) => {
                        return <span>{centsToDollarsFormatted(cellProps.row.original.totalPrice)}</span>;
                      }
                    }
                  ]}
                />
              )}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              setSelectedData={setSelectedData}
              columnVisibility={columnVisibility}
              renderHeader={() => (
                <div>
                  <div className="flex md:items-center flex-col md:flex-row justify-between">
                    <div className="flex md:items-center flex-col gap-3 md:flex-row">
                      <div className="w-full sm:max-w-[320px]">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <SearchInput
                              placeholder="Search Orders"
                              query={field.value}
                              param="Name, PGA ID"
                              onClear={() => deleteSearchParams("query")}
                              onChange={(e) => {
                                field.onChange(e);
                                setVariables((prevValue) => ({
                                  ...prevValue,
                                  query: e
                                }));
                                resetPagination();
                                addSearchParam("query", e);
                              }}
                            />
                          )}
                        />
                      </div>
                      {rowSelected === 0 && (
                        <>
                          <div className="min-w-[210px]">
                            <Controller
                              name="status"
                              control={control}
                              render={() => (
                                <MultiSelect
                                  label="Stage"
                                  selectedValues={selectedStatusValues}
                                  options={[
                                    { value: "canceled", label: "Canceled" },
                                    { value: "draft", label: "Draft" },
                                    { value: "open", label: "Open" },
                                    { value: "paid", label: "Paid" },
                                    { value: "pending", label: "Pending" }
                                  ]}
                                  onSelect={(value, checked) => {
                                    const status = checked
                                      ? [...selectedStatusValues, value]
                                      : selectedStatusValues.filter((v) => v !== value);

                                    setSelectedStatusValues(reject(status, isEmpty));
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      statuses: reject(status, isEmpty)
                                    }));
                                    resetPagination();
                                    addSearchParam("statuses[]", reject(status, isEmpty).join(","));
                                  }}
                                  onClear={() => {
                                    setSelectedStatusValues([]);
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      statuses: []
                                    }));
                                    deleteSearchParams("statuses[]");
                                  }}
                                  className="w-full"
                                />
                              )}
                            />
                          </div>
                          {isActive && (
                            <div className="hidden sm:block" data-testid="clear-all-button">
                              <Button
                                variant="text"
                                onClick={() => {
                                  setVariables((prevValue) => ({
                                    query: prevValue.query
                                  }));
                                  setSelectedStatusValues([]);
                                  resetPagination();
                                  deleteSearchParams("statuses[]");
                                }}
                              >
                                Clear All
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
                        {rowSelected > 0 && hasPermission(PERMISSION.managePayments) && (
                          <>
                            {isNotPayable ? (
                              <Tooltip
                                supportingText="Select only open orders to apply payment"
                                variant="plain"
                                color="black"
                              >
                                <Button
                                  variant="outlined"
                                  disabled={true}
                                  onClick={() => {
                                    navigate(
                                      `/billing/orders/apply-payment?${convertToUrlParams(rowSelection)}` as Path
                                    );
                                  }}
                                >
                                  Apply Payment
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button
                                variant="outlined"
                                disabled={isNotPayable}
                                onClick={() => {
                                  navigate(`/billing/orders/apply-payment?${convertToUrlParams(rowSelection)}` as Path);
                                }}
                              >
                                Apply Payment
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="hidden md:block">
                      {!isSelectedOnly &&
                        renderFooter(data?.commerceOrders, {
                          totalCount: data?.commerceOrders.totalCount || 0,
                          pageCount: orderData.length,
                          variant: "compact"
                        })()}
                    </div>
                  </div>

                  {rowSelected > 0 && (
                    <div className="flex items-center gap-2 mt-4 flex-wrap md:flex-nowrap">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                      <Button
                        variant="text"
                        onClick={() => {
                          setRowSelection({});
                          setIsSelectedOnly(false);
                          resetPagination();
                        }}
                      >
                        Deselect All {rowSelected} {pluralize(rowSelected, "Row")}
                      </Button>
                    </div>
                  )}
                  <div className="block md:hidden">
                    {!isSelectedOnly &&
                      renderFooter(data?.commerceOrders, {
                        totalCount: data?.commerceOrders.totalCount || 0,
                        pageCount: orderData.length,
                        variant: "compact"
                      })()}
                  </div>
                </div>
              )}
              columns={[
                {
                  header: ({ table }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Checkbox
                            name="select"
                            checked={table.getIsAllRowsSelected() && selectableRows.length > 0}
                            indeterminate={getIsSomeRowsSelected()}
                            onChange={(checked) => {
                              table.getToggleAllRowsSelectedHandler()({
                                target: { checked: getIsSomeRowsSelected() ? true : checked }
                              });
                              setIsSelectedOnly(false);
                            }}
                          />
                        </div>
                        <span className="text-label-large">Name</span>
                      </div>
                    );
                  },
                  id: "fullName",
                  cell: ({
                    row,
                    row: {
                      original: { person }
                    }
                  }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4" onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            name="select"
                            disabled={!row.getCanSelect()}
                            onChange={(checked) => {
                              row.getToggleSelectedHandler()({ target: { checked } });
                            }}
                            checked={row.getIsSelected()}
                          />
                        </div>
                        <div className="flex items-center py-2">
                          <AvatarDetails
                            picture={person.profilePhoto}
                            title={`${person.firstName} ${person.lastName}`}
                            text={person.mainProgramType?.name || ""}
                          />
                        </div>
                      </div>
                    );
                  },
                  meta: {
                    className: getStickyColumnClasses(!!orderData?.length)
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
                { accessorKey: "description", header: "Description", enableSorting: false },
                {
                  accessorKey: "products",
                  header: "Products",
                  enableSorting: false,
                  cell: ({ row }) => {
                    return (
                      <div
                        className="flex items-center gap-2 py-4 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          row.getToggleExpandedHandler()();
                        }}
                      >
                        <span>{row.original.lineItems?.length ?? 0}</span>
                        <Icon name={row.getIsExpanded() ? "expand_less" : "expand_more"} />
                      </div>
                    );
                  }
                },
                { accessorKey: "updatedAt", header: "Modified", enableSorting: false },
                { accessorKey: "dueDate", header: "Due Date", enableSorting: false },
                { accessorKey: "balance", header: "Balance", enableSorting: false },
                {
                  header: "Stage",
                  id: "stage",
                  cell: ({ row: { original } }) => renderStatus(original.stage, original.paymentStatus),
                  size: 100
                },
                {
                  id: "actions",
                  header: () => null,
                  cell: ({ row: { original } }) => {
                    return (
                      <IconButton
                        name="open_in_full"
                        className="sm:hidden group-hover:inline-flex"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (original.pgaId) {
                            openDetailsPanel(original.pgaId);
                            setActiveRow(original.id);
                          }
                        }}
                      />
                    );
                  },
                  meta: {
                    className: "text-right"
                  },
                  size: 100
                }
              ]}
              renderEmptyState={() => (
                <EmptyState
                  title="Search for an Order"
                  caption="Utilize the Search box above to see a list of matching orders."
                  iconName="receipt_long"
                />
              )}
              renderFooter={() => (
                <div
                  className={`${
                    rowSelected > 0 ? "justify-between" : "justify-end"
                  } flex flex-col md:flex-row md:items-center`}
                >
                  {rowSelected > 0 && (
                    <div className="flex items-center gap-2 mb-3 md:mb-0">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                    </div>
                  )}
                  {!isSelectedOnly &&
                    renderFooter(data?.commerceOrders, {
                      totalCount: data?.commerceOrders.totalCount || 0,
                      pageCount: orderData.length
                    })()}
                </div>
              )}
            />
          </div>
        </div>
        {isCreateOrderModalOpen && <CreateOrderModal onDismiss={() => setIsCreateOrderModalOpen(false)} />}
        {isDetailsPanelOpen && (
          <PersonDetailsPanel
            isDetailsPanelOpen={isDetailsPanelOpen}
            setIsDetailsPanelOpen={setIsDetailsPanelOpen}
            closeDetailsPanel={closeDetailsPanel}
            pgaId={selectedPgaId || ""}
          />
        )}
      </div>
    </TabLayout>
  );
}
