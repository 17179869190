import { gql } from "@apollo/client";

export const FACILITY_DETAILS_HEADER_QUERY = gql`
  query FacilityDetailsHeader($facilityId: ID!) {
    facility(id: $facilityId) {
      id
      name
    }
  }
`;
