import Tabs from "@/components/Tabs";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Path, useNavigate } from "@/router";
import { reject } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { EventWrapper } from "./[eventSlug]/types";

const tabs = ["registrations", "waitlist", "cancellations", "attendance", "results"] as const;
type TabType = (typeof tabs)[number];

interface EventTabsProps {
  eventData: EventWrapper["event"] | undefined;
}

export default function EventTabs({ eventData }: EventTabsProps) {
  const { eventSlug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const currentUrl = pathname.replace(/\/$/, "");
  const navigate = useNavigate();
  const flags = useFlags();

  if (!eventSlug) return null;

  const tabOptions: Record<TabType, { label: string; path: string }> = {
    registrations: {
      label: "Registrations",
      path: `/events/${eventSlug}/registrations`
    },
    waitlist: {
      label: "Waitlist",
      path: `/events/${eventSlug}/waitlist`
    },
    cancellations: {
      label: "Cancellations",
      path: `/events/${eventSlug}/cancellations`
    },
    attendance: {
      label: "Attendance",
      path: `/events/${eventSlug}/attendance`
    },
    results: {
      label: "Results",
      path: `/events/${eventSlug}/results`
    }
  };

  const showResults = eventData?.eventType?.name === "PAT";
  const showAttendance =
    flags["backoffice-events-attendance"] && eventData?.eventType?.name !== "PAT" && eventData?.collectsAttendance;

  let availableTabs = !!flags["backoffice-events-waitlist"] ? tabs : reject(tabs, (x) => x === "waitlist");
  availableTabs = !!flags["backoffice-events-cancel-registration"]
    ? availableTabs
    : reject(availableTabs, (x) => x === "cancellations");
  availableTabs = showResults ? availableTabs : reject(availableTabs, (x) => x === "results");
  if (!flags["backoffice-events-attendance"] || !showAttendance) {
    availableTabs = reject(availableTabs, (x) => x === "attendance");
  }

  const tabItems = availableTabs.map((tab) => ({
    label: tabOptions[tab].label,
    active: currentUrl.startsWith(tabOptions[tab].path),
    onClick: () => navigate(tabOptions[tab].path as Path)
  }));

  return (
    <div className="my-8">
      <Tabs tabItems={tabItems} />
    </div>
  );
}
