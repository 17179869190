import Checkbox from "@/components/Checkbox";
import { Controller, useFormContext } from "react-hook-form";
import RichTextToMarkdown from "@/pages/events/_form/components/RichTextToMarkdown.tsx";
import { useCallback } from "react";
import { CUSTOM_FIELDS, TERMS_CONTENT } from "../utils";

const CustomFieldsStep = () => {
  const { control, watch, setValue } = useFormContext();
  const isPAT = watch("eventType").name === "PAT";
  const customFields = watch("customFields");

  const setTermsContent = useCallback(
    (isChecked: boolean) => {
      setValue("termsContent", isChecked ? TERMS_CONTENT : "");
    },
    [setValue]
  );

  const handleCheckboxChange = (option: string, isChecked: boolean) => {
    const currentValues = watch("customFields");
    const updatedValues = isChecked
      ? [...currentValues, option]
      : currentValues.filter((value: string) => value !== option);

    setValue("customFields", updatedValues);

    if (option === "disclaimer") {
      setTermsContent(isChecked);
    }
  };

  return (
    <div className="flex flex-col gap-6 mb-0">
      <p className="text-title-large mb-2">
        Add any additional information that registrants should be asked to provide.
      </p>
      <p className="text-title-medium mb-0">Additional Information Fields</p>
      <p className="text-body-medium -mt-3.5">Check any additional information that should be asked of registrants.</p>

      <Controller
        control={control}
        name="customFields"
        data-testid="custom-fields-group"
        render={({ field }) => (
          <div className="flex flex-col">
            {Object.entries(CUSTOM_FIELDS).map(([key, label]) => (
              <div className="py-2" key={key}>
                <div className="mb-3 ml-7">
                  <Checkbox
                    name={`customFields-${key}`}
                    label={label}
                    checked={isPAT || field.value.includes(key)}
                    onChange={(checked: boolean) => {
                      if (!isPAT) {
                        handleCheckboxChange(key, checked);
                      }
                    }}
                    disabled={isPAT}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      />

      {customFields.includes("disclaimer") && (
        <div className="ml-7">
          <RichTextToMarkdown fieldName="termsContent" />
        </div>
      )}
    </div>
  );
};

export default CustomFieldsStep;
