import Dialog from "@/components/Dialog";
import Button from "@/components/Button";

interface FinalizeScoresErrorModalProps {
  open: boolean;
  onClose: () => void;
  missingRegistrantScores: number;
  missingEventInformation: number;
}

export default function FinalizeScoresErrorModal({
  open,
  onClose,
  missingRegistrantScores,
  missingEventInformation
}: FinalizeScoresErrorModalProps) {
  return (
    <Dialog
      open={open}
      onClosed={onClose}
      headline={`Finalize Scores`}
      actions={
        <>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Go Back
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          {missingRegistrantScores > 0 && (
            <div className="text-label-large">Missing Registrant Scores: {missingRegistrantScores}</div>
          )}
          {missingEventInformation > 0 && (
            <div className="text-label-large">Missing Event Information: {missingEventInformation}</div>
          )}
        </div>
        <div>
          {missingRegistrantScores > 0 && (
            <div className="text-body-medium">
              Please provide scores or statuses for all participants before finalizing.
            </div>
          )}
          {missingEventInformation > 0 && (
            <div className="text-body-medium">Please provide all event setup information before finalizing.</div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
