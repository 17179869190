import { PropsWithChildren } from "react";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { Params, Path } from "@/router";
import { DOMAIN } from "@/hooks/useCapabilities";
import { LinkProps } from "@generouted/react-router/client";

type Props<P extends Path> = {
  navHeaderProps: {
    title: string;
    backLink: LinkProps<P, Params>;
  };
};

export default function GrpFormLayout<P extends Path>(props: PropsWithChildren<Props<P>>) {
  const { navHeaderProps, children } = props;
  const { title, backLink } = navHeaderProps;

  return (
    <AppLayout
      interior
      navLinks={[
        {
          domain: DOMAIN.BENEFIT_PROGRAMS,
          label: "Benefit Management",
          link: { to: "/benefit-programs/grp" },
          icon: "settings"
        }
      ]}
    >
      <PageHeading title={title} backLink={backLink} />

      <div className="mx-auto max-w-[1200px] mt-16">
        <div className="px-6 py-6 laptop:px-12 laptop:py-8 rounded-2xl shadow-web-1 bg-sys-brand-surface">
          {children}
        </div>
      </div>
    </AppLayout>
  );
}
