import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Controller, useFormContext } from "react-hook-form";
import { GET_ADDRESS_TIMEZONE } from "../../queries";
import TextField from "@/components/TextField";
import Radio from "@/components/Radio";
import EventTypesDropdown from "../components/EventTypesDropdown";
import SectionAutocomplete from "../components/SectionAutocomplete";
import FacilityAutocomplete from "../components/FacilityAutocomplete";
import { Facility } from "../types";
import AddressFields from "../components/AddressFields";
import { BACKOFFICE_TIMEZONE } from "../utils";

const LOCATION_TYPES = [
  { value: "onsite", label: "Onsite" },
  { value: "virtual", label: "Virtual" }
];

const EventDetailsStep = ({ hasRegistrations }: { hasRegistrations: boolean }) => {
  const {
    control,
    clearErrors,
    trigger,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();
  const locationType = watch("locationType");
  const sectionId = watch("sectionId");
  const eventType = watch("eventType");

  const handleFacilitySelect = (facility: Facility) => {
    if (facility.addresses.length > 0) {
      const { address1, address2, city, state, postalCode } = facility.addresses[0];
      setValue("address1", address1 || "", { shouldValidate: true });
      setValue("address2", address2 || "", { shouldValidate: true });
      setValue("city", city || "", { shouldValidate: true });
      setValue("state", state || "", { shouldValidate: true });
      setValue("zipcode", postalCode || "", { shouldValidate: true });
    }
  };

  const address1 = watch("address1");
  const city = watch("city");
  const state = watch("state");
  const zipcode = watch("zipcode");

  const [getAddressTimezone] = useLazyQuery(GET_ADDRESS_TIMEZONE);

  useEffect(() => {
    if (locationType === "onsite" && address1 && city && state && zipcode) {
      getAddressTimezone({
        variables: { address1, city, state, zip: zipcode },
        onCompleted: (data) => {
          setValue("timezone", data.addressTimezone ?? BACKOFFICE_TIMEZONE);
        },
        onError: () => {
          setValue("timezone", BACKOFFICE_TIMEZONE);
        }
      });
    } else {
      setValue("timezone", BACKOFFICE_TIMEZONE);
    }
  }, [locationType, address1, city, state, zipcode, getAddressTimezone, setValue]);

  useEffect(() => {
    if (locationType === "virtual") {
      setValue("timezone", BACKOFFICE_TIMEZONE);
    }
  }, [locationType, setValue]);

  return (
    <div className="flex flex-col gap-6">
      <p className="text-title-large">Enter details about the event.</p>
      <Controller
        name="eventName"
        data-testid="event-name-input"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            label="Event Name"
            className="w-full"
            value={value}
            onChange={(e) => {
              onChange(e);
              if ((e.target as HTMLInputElement).value && errors.eventName) {
                clearErrors("eventName");
              }
              if (!(e.target as HTMLInputElement).value) {
                trigger("eventName");
              }
            }}
            required
            error={!!errors.eventName}
            errorText={errors.eventName?.message as string}
          />
        )}
      />
      <EventTypesDropdown disabled={hasRegistrations} />
      <SectionAutocomplete />

      {eventType.name !== "PAT" && (
        <>
          <p className="text-title-medium">Event Location</p>
          <Controller
            control={control}
            name="locationType"
            render={({ field }) => (
              <div className="flex flex-col" data-testid="location-type-radio-group">
                {LOCATION_TYPES.map((option, i) => (
                  <div className={`px-6 ${i === 0 ? "pb-3" : "py-3"}`} key={i}>
                    <Radio
                      name={`locationType-${option.value}`}
                      label={option.label}
                      checked={field.value === option.value}
                      onChange={() => {
                        field.onChange(option.value);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          />
        </>
      )}
      {locationType === "onsite" && (
        <>
          <FacilityAutocomplete sectionId={sectionId} onFacilitySelect={handleFacilitySelect} />
          <AddressFields />
        </>
      )}
      <div>
        <Controller
          name="location"
          data-testid="location-input"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <TextField label="Location Details" className="w-full" value={value} onChange={onChange} />
              <span className="text-body-small text-sys-brand-on-surface-variant ml-4">
                e.g. Ballroom A, Room 246, Meet at Golf Range, Google Meet: https://...
              </span>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default EventDetailsStep;
