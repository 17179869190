import { PageInfo } from "@/components/Pagination";
import { TypedDocumentNode, gql } from "@apollo/client";
import { WaitlistEntry } from "./types";

interface WaitlistEntryNode {
  node: WaitlistEntry;
}

type Query = {
  eventRegistrants: {
    totalCount: number;
    edges: WaitlistEntryNode[];
    pageInfo: PageInfo;
  };
};

export const WAITLIST_ENTRIES_QUERY: TypedDocumentNode<Query> = gql`
  query GetWaitlistEntries($eventSlug: String!, $first: Int, $last: Int, $after: String, $before: String) {
    eventWaitlistEntries(slug: $eventSlug, first: $first, last: $last, after: $after, before: $before) {
      edges {
        node {
          id
          personId
          entryName
          profilePhoto
          programType
          pgaId
          gender
          age
          email
          phone
          createdAt
        }
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const PROMOTE_FROM_WAITLIST_MUTATION = gql`
  mutation PromoteFromWaitlist($eventId: ID!, $personId: ID!) {
    promoteFromWaitlist(eventId: $eventId, personId: $personId) {
      success
      message
    }
  }
`;
