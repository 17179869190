import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import { useMutation, useQuery } from "@apollo/client";
import Avatar from "@/components/Avatar";
import { ORDERS_QUERY, OrderType, OrdersQuery, PersonType } from "./queries";
import { centsToDollarsFormatted, currencyFormatter } from "@/lib/currencyHelpers";
import Progress from "@/components/Progress";
import { toFormat } from "@/lib/dateHelpers";
import IconButton from "@/components/IconButton";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import Radio from "@/components/Radio";
import DateSelect from "@/components/DateSelect";
import TextField from "@/components/TextField";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { NumberFormatBase } from "react-number-format";
import Button from "@/components/Button";
import ApplyPaymentModal from "./_components/ApplyPaymentModal";
import ValidationModal from "./_components/ValidationModal";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import { buildTableData } from "../../_utils";
import { CREATE_CHECK_PAYMENT_MUTATION, GET_FINANCIAL_ADJUSTMENT_TYPES } from "./queries";
import { ModalValidationType } from "./types";
import { LinkProps } from "@generouted/react-router/client";
import { showSnackbar } from "@/lib/snackbarUtils";
import FacilityAutocomplete from "./_components/FacilityAutocomplete";
import { Params, type Path, useNavigate } from "@/router";
import Select from "@/components/Select";
import { sortBy } from "lodash";
import usePageTitle from "@/hooks/usePageTitle";
import { paymentSourceTypes } from "@/pages/billing/types";

export type ItemFormValue = {
  id: string;
  name: string;
  balance: number;
  allocated: number;
  selected: boolean;
  isNew?: boolean;
  product?: string;
  variant?: string;
};

export type OrderFormValue = {
  id: string;
  balance: number;
  person: PersonType;
  lineItems: ItemFormValue[];
  addons: ItemFormValue[];
  note: string;
};

export type FormValues = {
  paymentSourceType: string;
  paymentSource: string;
  paymentSourceId?: string;
  paymentSourceName?: string;
  paymentDate: {
    startDate: string;
    endDate: string;
  };
  checkNumber?: string | undefined;
  paymentAmount?: number | null;
  orders: OrderFormValue[];
};

export type FinancialAdjustmentTypes = {
  value: string;
  displayText: string;
  id: string;
  isFinancialAdjustment: boolean;
  name: string;
};

type FinancialAdjustmentTypesQuery = {
  commerceFinancialAdjustmentTypes: FinancialAdjustmentTypes[];
};

const ApplyPayment = () => {
  usePageTitle("/billing/orders/apply-payment", "Billing | Orders | Apply Payment");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.manageOrders });
  let [searchParams] = useSearchParams();
  const orderIds = searchParams.getAll("orderId");

  const { data, loading, error } = useQuery<OrdersQuery>(ORDERS_QUERY, {
    variables: { first: orderIds.length, ids: orderIds }
  });
  const { data: financialAdjustmentTypesData } =
    useQuery<FinancialAdjustmentTypesQuery>(GET_FINANCIAL_ADJUSTMENT_TYPES);

  if (loading)
    return (
      <div className="p-10 flex items-center justify-center">
        <Progress />
      </div>
    );
  if (error) return <EmptyState title="Error" caption="An error occurred while loading this order." iconName="error" />;
  return (
    <ApplyPaymentWrapper
      orders={data?.commerceOrders.nodes || []}
      financialAdjustmentTypes={financialAdjustmentTypesData?.commerceFinancialAdjustmentTypes || []}
    />
  );
};

const ApplyPaymentWrapper = <P extends Path>({
  orders,
  financialAdjustmentTypes
}: {
  orders: OrderType[];
  financialAdjustmentTypes: FinancialAdjustmentTypes[];
}) => {
  const [createCheckPaymentMutation, { loading: saving }] = useMutation(CREATE_CHECK_PAYMENT_MUTATION);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState<number>(-1);
  const [modalValidationType, setModalValidationType] = useState<ModalValidationType>();
  const [errorContent, setErrorContent] = useState<string>();
  const [selectedSource, setSelectedSource] = useState<FinancialAdjustmentTypes>();

  const [actions, setActions] = useState<React.ReactNode>(null);
  const [backLink, setBackLink] = useState({ to: "/billing/orders" } as LinkProps<P, Params>);
  const singleOrder = orders.length === 1;

  useEffect(() => {
    const renderActions = () => {
      return (
        <Button variant="filled" icon="check" disabled={saving} onClick={handleSubmit(onSubmit)}>
          Save Payment
        </Button>
      );
    };
    setActions(renderActions());
    setBackLink(
      singleOrder
        ? ({ to: `/billing/orders/:orderId`, params: { orderId: orders[0].id } } as LinkProps<P, Params>)
        : ({ to: "/billing/orders" } as LinkProps<P, Params>)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActions, setBackLink, saving]);

  const currentDate = new Date();
  const defaultValues = {
    paymentSourceType: singleOrder ? paymentSourceTypes.self : paymentSourceTypes.facility,
    paymentSourceId: singleOrder ? orders[0].person.id : undefined,
    paymentSource: "",
    paymentDate: {
      startDate: toFormat(currentDate.toISOString(), "yyyy-MM-dd") || undefined,
      endDate: toFormat(currentDate.toISOString(), "yyyy-MM-dd") || undefined
    },
    checkNumber: undefined,
    paymentAmount: null,
    orders: orders.map((order) => ({
      id: order.id,
      person: order.person,
      balance: order.balance,
      pgaId: order.person.pgaId,
      description: order.description,
      lineItems: order.lineItems
        .filter((item) => !!item.balance)
        .map((item) => ({
          id: item.id,
          name: item.name,
          selected: true,
          balance: item.balance,
          allocated: item.balance
        })),
      addons: order.addons.map((item) => ({
        id: item.id,
        name: item.name,
        selected: false,
        balance: item.priceCents,
        allocated: item.priceCents
      }))
    }))
  };

  const form = useForm<FormValues>({
    defaultValues: defaultValues
  });

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    resetField,
    handleSubmit
  } = form;
  const paymentAmount = watch("paymentAmount");
  const paymentSourceType = watch("paymentSourceType");
  const ordersWatch = watch("orders");

  const options = [
    ...(singleOrder ? [{ value: "self", label: "Personal Check" }] : []),
    { value: "facility", label: "Facility Check" },
    { value: "other", label: "Other" }
  ];
  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    const { totals } = buildTableData(values.orders, values.paymentAmount);
    if (totals.totalAllocated === 0) {
      setModalValidationType(ModalValidationType.AmountNotAllocated);
      return;
    }

    if (totals.totalUnallocated > 0) {
      setModalValidationType(ModalValidationType.AmountUnderAllocated);
      return;
    }
    if (totals.totalUnallocated < 0) {
      setModalValidationType(ModalValidationType.AmountOverAllocated);
      return;
    }

    const { data } = await createCheckPaymentMutation({
      variables: {
        input: {
          totalAmountCents: values.paymentAmount || 0,
          paymentDate: values.paymentDate.startDate,
          checkNumber: values.checkNumber,
          payer: {
            type:
              values.paymentSource !== "proxyCheck" && values.paymentSource !== ""
                ? "financial_adjustment"
                : values.paymentSourceType,
            id: values.paymentSource !== "proxyCheck" ? values.paymentSourceId : null,
            name: values.paymentSource !== "proxyCheck" ? values.paymentSource : values.paymentSourceName
          },
          orders: values.orders.map((order) => ({
            id: order.id,
            note: order.note,
            lineItems: order.lineItems
              .filter((item) => item.selected && item.allocated > 0)
              .map((item) => ({
                id: item.id,
                amountCents: item.allocated
              }))
              .filter((item) => item.amountCents > 0),
            addons: order.addons
              .filter((addon) => addon.selected && addon.allocated > 0)
              .map((addon) => ({
                lookupKey: addon.id,
                amountCents: addon.allocated
              }))
          }))
        }
      }
    });

    if (data?.commerceCreateCheckPayment?.success) {
      if (singleOrder) {
        navigate(
          `/billing/orders/${orders[0].id}/payment/${data?.commerceCreateCheckPayment?.payment.id}?payment-applied=true` as Path
        );
      } else {
        navigate(`/billing/orders/payment/:paymentId`, {
          params: { paymentId: data?.commerceCreateCheckPayment?.payment.id }
        });
      }
      showSnackbar(`Payment applied to ${orders.length} order${orders.length > 1 ? "s" : ""}`);
    } else {
      setErrorContent(data?.commerceCreateCheckPayment?.message || "An error occurred while applying payment.");
    }
  };

  const { orderData, totals } = buildTableData(ordersWatch, paymentAmount);
  const sourceOptions = sortBy(
    financialAdjustmentTypes
      .map((type) => ({
        value: type.name,
        displayText: type.name,
        id: type.id,
        isFinancialAdjustment: true
      }))
      .concat({ value: "proxyCheck", displayText: "Proxy Check", id: "proxyCheck", isFinancialAdjustment: false }),
    "displayText"
  );

  function selectSource(field: any, value: string) {
    field.onChange(value);

    sourceOptions.forEach((source) => {
      if (source.value === value) {
        setValue("paymentSourceId", source.id);

        setSelectedSource({
          value: source.value,
          displayText: source.displayText,
          id: source.id,
          name: source.displayText,
          isFinancialAdjustment: source.isFinancialAdjustment
        });
      }
    });
  }

  return (
    <SinglePageLayout backLink={backLink} actions={actions}>
      <div className="max-w-[1440px] mx-auto mt-8" data-testid="apply-payment-page">
        <FormProvider {...form}>
          {!!errorContent && <div className="text-red-500 font-bold mb-4">{errorContent}</div>}
          <div className="text-headline-small mb-6">Apply Payment</div>
          <div className="text-title-medium mb-1">Enter Payment Details</div>
          <p className="text-body-medium">Enter the payment source, check number, and the payment amount received.</p>
          <h3 className="text-title-medium mt-8 mb-3">Payment Source</h3>
          <Controller
            control={control}
            name="paymentSourceType"
            render={({ field }) => (
              <>
                {options.map((option, i) => (
                  <div className="py-3 px-6" key={i}>
                    <Radio
                      label={option.label}
                      checked={field.value === option.value}
                      onChange={() => {
                        field.onChange(option.value);
                        resetField("paymentSource");
                        resetField("paymentSourceId");
                      }}
                      name="paymentSourceType"
                    />
                  </div>
                ))}
              </>
            )}
          />
          <div className={`mt-3 grid max-w-[756px]  gap-4 grid-cols-2`}>
            {paymentSourceType === paymentSourceTypes.facility && <FacilityAutocomplete />}
            {paymentSourceType === paymentSourceTypes.other && (
              <Controller
                name="paymentSource"
                control={control}
                rules={{ required: "Source is required" }}
                render={({ field }) => (
                  <Select
                    className="w-full"
                    label="Source"
                    required
                    onSelect={(value) => selectSource(field, value)}
                    options={sourceOptions}
                  />
                )}
              />
            )}
            {watch("paymentSource") === "proxyCheck" && (
              <Controller
                name="paymentSourceName"
                control={control}
                rules={{ required: "Source Name is required" }}
                render={({ field }) => (
                  <TextField
                    disabled={false}
                    value={field.value}
                    error={!!errors?.paymentSourceName}
                    errorText={errors?.paymentSourceName?.message}
                    onChangeText={field.onChange}
                    label="Source Name *"
                    className="w-full"
                  />
                )}
              />
            )}
          </div>
          <h3 className="text-title-medium mt-8 mb-3">Payment Details</h3>
          <div className={`grid max-w-[756px] gap-4 grid-cols-3`}>
            <div className="my-2">
              <Controller
                render={({ field }) => {
                  return (
                    <DateSelect
                      applyButtonText="OK"
                      cancelButtonText="Cancel"
                      useSingleDate={true}
                      label="Payment Date *"
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      showFooter
                      value={field.value}
                      error={!!errors?.paymentDate}
                      errorMessage={errors?.paymentDate?.message}
                    />
                  );
                }}
                control={control}
                name={`paymentDate`}
                rules={{
                  validate: (value) =>
                    (value.startDate !== null && value.endDate !== null) || "Payment Date is required"
                }}
              />
            </div>
            <div className="my-2">
              <Controller
                render={({ field }) => (
                  <TextField
                    disabled={false}
                    value={field.value}
                    error={!!errors?.checkNumber}
                    errorText={errors?.checkNumber?.message}
                    onChangeText={field.onChange}
                    label={`${selectedSource?.isFinancialAdjustment ? "Reference Number" : "Check Number"}`}
                    required={!selectedSource?.isFinancialAdjustment}
                    className="w-full"
                  />
                )}
                control={control}
                rules={{ required: !selectedSource?.isFinancialAdjustment && "Check Number is required" }}
                name={`checkNumber`}
              />
            </div>
            <div className="my-2" data-testid="currency-input">
              <Controller
                render={({ field }) => (
                  <NumberFormatBase
                    disabled={false}
                    value={field.value}
                    format={currencyFormatter}
                    customInput={TextField}
                    error={!!errors?.paymentAmount}
                    errorText={errors?.paymentAmount?.message}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    onValueChange={(values) => {
                      field.onChange(Math.round(values.floatValue || 0));
                    }}
                    required
                    leadingIcon="attach_money"
                    label="Payment Amount"
                    className="w-full"
                  />
                )}
                control={control}
                rules={{
                  validate: (value) => {
                    if (value === null || value === undefined) {
                      return "Payment Amount is required";
                    }
                    return true;
                  }
                }}
                name={`paymentAmount`}
              />
            </div>
          </div>
          <h3 className="text-title-medium mt-10 mb-1">Apply Payment</h3>
          <p className="text-body-medium mb-6 max-w-[1000px]">
            To change payment allocation for an order, or allocate funds to add-ons, press the edit button on the order.
            The total Payment Amount must be completely allocated to save this payment.
          </p>
          <Table
            data={orderData || []}
            loading={false}
            columns={[
              {
                header: "Name",
                id: "fullName",
                size: 400,
                cell: (cellProps) => {
                  const { person } = cellProps.row.original;
                  return (
                    <div className="flex items-center py-2">
                      <Avatar picture={person.profilePhoto} className="min-w-[3rem]" />
                      <div className="ml-2">
                        <span className="text-body-medium">
                          {person.firstName} {person.lastName}
                        </span>
                        <br />
                        <span className="text-body-small">{person.mainProgramType?.name}</span>
                      </div>
                    </div>
                  );
                },
                meta: {
                  className: getStickyColumnClasses(!!orderData?.length)
                }
              },
              { accessorKey: "pgaId", header: "PGA ID", size: 150, enableSorting: false },
              { accessorKey: "description", header: "Description", size: 300, enableSorting: false },
              {
                accessorKey: "products",
                header: "Products",
                size: 150,
                enableSorting: false,
                cell: ({ row }) => {
                  return (
                    <div className="flex items-center gap-2 py-4 cursor-pointer">
                      <span>
                        {row.original.lineItems.filter((l) => l.selected).length +
                          row.original.addons.filter((l) => l.selected).length}
                      </span>
                    </div>
                  );
                }
              },
              {
                accessorKey: "amount",
                header: "Amount",
                size: 100,
                enableSorting: false,
                cell: ({ row }) => {
                  return <div>{centsToDollarsFormatted(row.original.amount)}</div>;
                }
              },
              {
                accessorKey: "balanceAfterPayment",
                header: "Balance After Payment",
                size: 150,
                enableSorting: false,
                cell: ({ row }) => {
                  return <div>{centsToDollarsFormatted(row.original.balanceAfterPayment)}</div>;
                }
              },
              {
                accessorKey: "totalAllocated",
                header: "Payment to Apply",
                size: 150,
                enableSorting: false,
                meta: { className: "text-right", headerClassName: "justify-end" },
                cell: ({ row }) => {
                  return (
                    <div className="flex items-center justify-end">
                      {centsToDollarsFormatted(row.original.totalAllocated)}
                    </div>
                  );
                }
              },

              {
                id: "actions",
                header: () => null,
                size: 40,
                cell: ({ row }) => {
                  return <IconButton name="edit" data-testid="edit" onClick={() => setSelectedOrderIndex(row.index)} />;
                }
              }
            ]}
            renderFooter={() => (
              <div className="mr-2">
                <div className="pb-3">
                  <div className="flex justify-end">
                    <h5 className="text-label-large">Total Amount Due</h5>
                    <div className="flex justify-end text-body-medium w-[100px] mr-0 md:mr-[84px]">
                      {centsToDollarsFormatted(totals.totalAmount)}
                    </div>
                  </div>
                </div>
                <div className="border-t -ml-3 w-[calc(100%+1.5rem)]" />
                <div className="pt-3 flex flex-col justify-end">
                  <div className="flex justify-end pb-2">
                    <h5 className="text-body-medium">Amount Allocated from Payment</h5>
                    <div className="flex justify-end text-body-medium w-[100px] mr-0 md:mr-[84px]">
                      -{centsToDollarsFormatted(totals.totalAllocated)}
                    </div>
                  </div>
                  <div className="flex justify-end pb-2">
                    <h5 className="text-body-medium">Amount Unallocated from Payment</h5>
                    <div className="flex justify-end text-body-medium w-[100px] mr-0 md:mr-[84px]">
                      {centsToDollarsFormatted(totals.totalUnallocated)}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <h5 className="text-label-large">Total Order Balance Remaining</h5>
                    <div className="flex justify-end text-body-medium w-[100px] mr-0 md:mr-[84px]">
                      {centsToDollarsFormatted(totals.totalRemainings)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            renderEmptyState={() => (
              <EmptyState title="No Orders" caption="There are no orders to display." iconName="receipt_long" />
            )}
          />
          {selectedOrderIndex >= 0 && (
            <ApplyPaymentModal
              order={ordersWatch[selectedOrderIndex]}
              paymentAmount={paymentAmount}
              orderData={orderData}
              onChange={(updatedOrder) => {
                setValue(`orders.${selectedOrderIndex}`, updatedOrder);
                setSelectedOrderIndex(-1);
              }}
              onDismiss={() => setSelectedOrderIndex(-1)}
            />
          )}
          <ValidationModal validationType={modalValidationType} onDismiss={() => setModalValidationType(undefined)} />
        </FormProvider>
      </div>
    </SinglePageLayout>
  );
};

export default ApplyPayment;
