import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TERMINATE_PROGRAMS_MUTATION, ORDERS_QUERY } from "../queries";
import { showSnackbar } from "@/lib/snackbarUtils";
import { DateTime } from "luxon";
import { ChangeDueDateModalProps } from "./ChangeDueDateModal";

interface TerminateModalProps extends ChangeDueDateModalProps {
  terminateAll: boolean;
  totalPeople: number | null;
}

export const TerminateModal = ({
  onDismiss,
  orders,
  refetch,
  setRowSelection,
  terminateAll,
  totalPeople,
  setStats,
  resetPagination
}: TerminateModalProps) => {
  const { control, watch } = useForm();
  const headline = `Terminate People`;
  const orderIds = Object.keys(orders);
  const { data } = useQuery(ORDERS_QUERY, { variables: { ids: orderIds } });

  const [terminateProgramsMutation, { loading }] = useMutation(TERMINATE_PROGRAMS_MUTATION);
  const [errorContent, setErrorContent] = useState("");

  const currentDate = DateTime.now().toISO();

  const terminationDateRange = watch("terminationDate", {
    startDate: currentDate,
    endDate: currentDate
  });

  const personIds = data?.currentDuesCycle.orders.nodes.map((order: any) => order.person.id) || null;
  const variables = {
    ...(!terminateAll && { personIds }),
    ...(terminateAll && { terminateAll }),
    terminationDate: terminationDateRange.startDate
  };

  async function terminatePrograms() {
    const { data } = await terminateProgramsMutation({
      variables: { input: variables }
    });

    if (data.duesBulkTerminatePrograms.success) {
      const successfulPersonIds = data.duesBulkTerminatePrograms.successfulPersonIds.length;
      showSnackbar(`${successfulPersonIds} people have been terminated`);

      setStats({
        totalCount: null,
        failedPayments: null
      });
      resetPagination();
      refetch();
      setRowSelection([]);
      if (data.duesBulkTerminatePrograms.failedPersonIds.length > 0) {
        setErrorContent(data.duesBulkTerminatePrograms.message);
        resetPagination();
        refetch();
        setRowSelection([]);
      } else {
        onDismiss();
      }
    } else {
      setErrorContent(data.duesBulkTerminatePrograms.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={terminatePrograms} disabled={loading || !terminationDateRange.startDate}>
        Terminate {terminateAll ? totalPeople : personIds?.length} People
      </Button>
    </>
  );

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline={headline}
      className="w-[423px] mt-[140px]"
      displayOverflow
    >
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      <p className="mb-3 text-label-large">
        Total People to be Terminated: {terminateAll ? totalPeople : personIds?.length}
      </p>
      <p className="my-3">
        Are you sure you wish to terminate these people from their PGA program? A $25 re-establishment fee will be added
        to their open dues order. If they pay their invoice, their membership will be restored automatically.
      </p>
      <p>Terminated people will be sent an email notification.</p>
      <div className="mt-6">
        <input className="mt-4 ml-1 absolute"></input>

        <Controller
          name="terminationDate"
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Termination Effective Date *"
              value={terminationDateRange}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              showFooter={false}
              popoverDirection="up"
              supportingText="MM/DD/YYYY"
            />
          )}
        />
      </div>
    </Dialog>
  );
};
