import { useSidebar } from "@/hooks/useSidebar";
import ClosedNavigationItem from "./ClosedNavigationItem";
import OpenNavigationItem from "./OpenNavigationItem";
import { NavigationItemProps } from "./types";
import { Path } from "@/router";

export default function NavigationLink<P extends Path>({ ...props }: NavigationItemProps<P>) {
  const { isSidebarOpen } = useSidebar();
  const Component = isSidebarOpen ? OpenNavigationItem : ClosedNavigationItem;

  return <Component {...props} />;
}
