import { useNavigate } from "@/router";
import { Person } from "@/types";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CREATE_DRAFT_ORDER_MUTATION, PERSON_DRAFT_DUES_ORDERS_QUERY } from "../queries";
import CreateOrderDialog from "./CreateOrderDialog";
import DuplicateOrderDialog from "./DuplicateOrderDialog";
import OrderCreationErrorDialog from "./OrderCreationErrorDialog";
import { PERSON_DETAILS_HEADER_QUERY } from "@/pages/people/[pgaId]/queries";
type PersonData = { person: Person };

interface QueryData {
  people: {
    nodes: Person[];
  };

  commerceOrderTemplates: TemplateData[];

  commerceOrders: {
    nodes: {
      id: string;
      status: string;
      type: string;
    }[];
  };
}

interface TemplateData {
  id: string;
  name: string;
  type: string;
  kind: string;
}

enum Steps {
  OrderForm,
  DuplicationWarning,
  Error
}

export default function CreateOrderModal({ onDismiss, pgaId }: { onDismiss: () => void; pgaId?: string }) {
  const { data } = useQuery<PersonData>(PERSON_DETAILS_HEADER_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  const { person } = data || {};
  const [orderCreationErrorReasons, setOrderCreationErrorReasons] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(Steps.OrderForm);
  const [createDraftOrderMutation, { loading }] = useMutation(CREATE_DRAFT_ORDER_MUTATION);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateData>({
    id: person?.id || "",
    name: "",
    type: "",
    kind: ""
  });
  const [personId, setPersonId] = useState(person?.id || "");

  const { control: orderControl, watch } = useForm();
  const navigate = useNavigate();

  const templateId = watch("templateId");
  const effectiveDate = watch("effectiveDate", { startDate: new Date(), endDate: new Date() });

  const { data: orderData, refetch: refetchPersonOrders } = useQuery<QueryData>(PERSON_DRAFT_DUES_ORDERS_QUERY, {
    variables: {
      filters: {
        personId: personId,
        type: "dues",
        status: "draft",
        kind: selectedTemplate.kind
      }
    }
  });

  const existingDraftDuesOrderId =
    orderData?.commerceOrders.nodes && orderData?.commerceOrders.nodes[0]
      ? orderData.commerceOrders.nodes[0].id
      : undefined;

  async function createDraftOrder() {
    const { data: createDraftOrderData } = await createDraftOrderMutation({
      variables: {
        input: { personId: personId, templateId: selectedTemplate.id, effectiveDate: effectiveDate.startDate }
      }
    });

    if (createDraftOrderData.commerceCreateDraftOrder.success) {
      navigate(`/billing/orders/:orderId`, {
        params: { orderId: createDraftOrderData.commerceCreateDraftOrder.order.id }
      });
    } else {
      const errorReasons = JSON.parse(createDraftOrderData.commerceCreateDraftOrder.message) || [
        "There was an error creating the order."
      ];
      setOrderCreationErrorReasons(errorReasons);
      setCurrentStep(Steps.Error);
    }
  }

  async function maybeCreateDraftOrder() {
    if (existingDraftDuesOrderId && selectedTemplate.type == "dues") {
      setCurrentStep(Steps.DuplicationWarning);
    } else {
      createDraftOrder();
    }
  }

  useEffect(() => {
    if (personId) {
      refetchPersonOrders();
    }
  }, [personId, templateId, refetchPersonOrders]);

  return (
    <>
      {currentStep === Steps.OrderForm && (
        <CreateOrderDialog
          onDismiss={onDismiss}
          onCreateDraft={maybeCreateDraftOrder}
          loading={loading}
          effectiveDate={effectiveDate}
          setSelectedTemplate={setSelectedTemplate}
          personName={person?.displayName || ""}
          setPersonId={setPersonId}
          orderControl={orderControl}
          selectedTemplate={selectedTemplate}
        />
      )}

      {currentStep === Steps.DuplicationWarning && existingDraftDuesOrderId && (
        <DuplicateOrderDialog
          onDismiss={onDismiss}
          existingOrderId={existingDraftDuesOrderId}
          onCreateDraftOrder={createDraftOrder}
        />
      )}

      {currentStep === Steps.Error && (
        <OrderCreationErrorDialog
          onDismiss={onDismiss}
          templateName={selectedTemplate.name}
          errorReasons={orderCreationErrorReasons}
          openCreateOrderModal={() => {
            setOrderCreationErrorReasons([]);
            setCurrentStep(Steps.OrderForm);
          }}
        />
      )}
    </>
  );
}
