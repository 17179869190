import { PageInfo } from "@/components/Pagination";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { TypedDocumentNode, gql } from "@apollo/client";

interface Registrant {
  pgaId: string;
  profilePhoto: string;
  programType: string;
  registeredAt: string;
  registrantName: string;
  sessionCount: number;
}

interface RegistrantNode {
  node: Registrant;
}

type Query = {
  eventRegistrants: {
    totalCount: number;
    edges: RegistrantNode[];
    pageInfo: PageInfo;
  };
};

export const EVENT_REGISTRANTS_QUERY: TypedDocumentNode<Query> = gql`
  query GetEventRegistrants(
    $eventSlug: String!
    $search: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: BackofficeEventsRegistrationStatusEnum
    $ids: [String!]
  ) {
    eventRegistrants(
      slug: $eventSlug
      search: $search
      first: $first
      last: $last
      after: $after
      before: $before
      status: $status
      ids: $ids
    ) {
      edges {
        node {
          id
          pgaId
          profilePhoto
          programType
          registeredAt
          registrantName
          sessionCount
          customFields
          sessionRegistrations {
            id
            attended
          }
          patSubmission {
            id
            roundOne {
              roundScore
              meetsQualifyingScore
            }
            roundTwo {
              roundScore
              meetsQualifyingScore
            }
            totalScore
            additionalStatus
            note
            result
          }
        }
      }
      totalCount
      ...PageInfo
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;

export const EVENT_QUERY = gql`
  query GetBackofficeEvent($slug: String!) {
    event(slug: $slug) {
      id
      status
      title
      hasWaitlist
      waitlistEnabled
      cancelledAt
      sessionCount
      collectsAttendance
      registrationCount
      hasActiveOrders
      timezone
      eventType {
        id
        name
      }
      patEventAttributes {
        yardageMale
        courseRatingMale
        yardageMaleFiftyPlus
        yardageFemale
        courseRatingFemale
        yardageFemaleFiftyPlus
        targetScoreMale
        targetScoreFemale
        hostProfessional {
          id
          pgaId
          displayName
        }
        examiner {
          id
          pgaId
          displayName
        }
        courseConditions
        problemsIssues
      }
      customFields
      sessions {
        id
        orderKey
        startsAt
        endsAt
        registrations {
          id
          attended
          registration {
            id
            customFields {
              gender
              dateOfBirth
            }
            person {
              id
              pgaId
              profilePhoto
              displayName
              mainProgramType {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GENERATE_REGISTRATION_EXPORT_MUTATION = gql`
  mutation generateRegistrationExport($input: BackofficeEventsGenerateRegistrationExportInput!) {
    generateRegistrationExport(input: $input) {
      url
      success
      message
    }
  }
`;

export const CANCEL_EVENT_REGISTRATION_MUTATION = gql`
  mutation CancelEventRegistration($id: ID!) {
    cancelEventRegistration(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($input: BackofficeEventsUpdateEventInput!) {
    updateEvent(input: $input) {
      success
      message
    }
  }
`;

export const CANCEL_EVENT_MUTATION = gql`
  mutation CancelEvent($id: ID!) {
    cancelEvent(id: $id) {
      success
      message
    }
  }
`;
