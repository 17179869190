// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/benefit-programs`
  | `/benefit-programs/grp`
  | `/benefit-programs/grp/member-contributions`
  | `/benefit-programs/grp/sponsor-contributions`
  | `/benefit-programs/grp/sponsor-contributions/upload`
  | `/billing`
  | `/billing/dues-management`
  | `/billing/dues-management/manage-products`
  | `/billing/dues-management/manage-products/annual-national-rates`
  | `/billing/dues-management/reporting`
  | `/billing/dues-management/reporting/suspensions`
  | `/billing/dues-management/reporting/terminations`
  | `/billing/dues-management/reporting/unpaid-dues`
  | `/billing/dues-management/suspensions-and-terminations`
  | `/billing/dues-management/suspensions-and-terminations/suspensions`
  | `/billing/dues-management/suspensions-and-terminations/terminations`
  | `/billing/orders`
  | `/billing/orders/:orderId`
  | `/billing/orders/:orderId/edit`
  | `/billing/orders/:orderId/payment/:paymentId`
  | `/billing/orders/apply-payment`
  | `/billing/orders/payment/:paymentId`
  | `/billing/payments`
  | `/education/videos`
  | `/education/videos/:videoSlug`
  | `/education/videos/:videoSlug/queries`
  | `/education/videos/:videoSlug/show`
  | `/education/videos/assessmentDetails`
  | `/education/videos/publish-video`
  | `/education/videos/videoDetails`
  | `/errors`
  | `/events`
  | `/events/:eventSlug`
  | `/events/:eventSlug/attendance`
  | `/events/:eventSlug/attendance/queries`
  | `/events/:eventSlug/cancellations`
  | `/events/:eventSlug/edit`
  | `/events/:eventSlug/registrations`
  | `/events/:eventSlug/registrations/:registrationId`
  | `/events/:eventSlug/results`
  | `/events/:eventSlug/waitlist`
  | `/events/new`
  | `/facilities`
  | `/facilities/:facilityId`
  | `/facilities/:facilityId/details`
  | `/facilities/:facilityId/details/contact`
  | `/people`
  | `/people/:pgaId`
  | `/people/:pgaId/audit-log`
  | `/people/:pgaId/billing`
  | `/people/:pgaId/billing/orders`
  | `/people/:pgaId/details`
  | `/people/:pgaId/details/change-requests`
  | `/people/:pgaId/details/committees`
  | `/people/:pgaId/details/contact`
  | `/people/:pgaId/details/employment`
  | `/people/:pgaId/details/membership`
  | `/people/:pgaId/details/overview`
  | `/people/:pgaId/details/section`
  | `/people/:pgaId/education`
  | `/people/:pgaId/education/learning`
  | `/people/:pgaId/education/professional-development`
  | `/people/:pgaId/education/program`
  | `/people/:pgaId/interactions`
  | `/people/:pgaId/interactions/interactions`
  | `/people/:pgaId/interactions/interactions/:interactionId`
  | `/people/search`
  | `/sections`
  | `/sections/:sectionId`
  | `/sections/:sectionId/audit-log`
  | `/sections/:sectionId/billing`
  | `/sections/:sectionId/billing/dues-rates`
  | `/sections/:sectionId/change-requests`
  | `/sections/:sectionId/change-requests/settings`
  | `/sections/:sectionId/jobs`
  | `/sections/:sectionId/jobs/list`

export type Params = {
  '/billing/orders/:orderId': { orderId: string }
  '/billing/orders/:orderId/edit': { orderId: string }
  '/billing/orders/:orderId/payment/:paymentId': { orderId: string; paymentId: string }
  '/billing/orders/payment/:paymentId': { paymentId: string }
  '/education/videos/:videoSlug': { videoSlug: string }
  '/education/videos/:videoSlug/queries': { videoSlug: string }
  '/education/videos/:videoSlug/show': { videoSlug: string }
  '/events/:eventSlug': { eventSlug: string }
  '/events/:eventSlug/attendance': { eventSlug: string }
  '/events/:eventSlug/attendance/queries': { eventSlug: string }
  '/events/:eventSlug/cancellations': { eventSlug: string }
  '/events/:eventSlug/edit': { eventSlug: string }
  '/events/:eventSlug/registrations': { eventSlug: string }
  '/events/:eventSlug/registrations/:registrationId': { eventSlug: string; registrationId: string }
  '/events/:eventSlug/results': { eventSlug: string }
  '/events/:eventSlug/waitlist': { eventSlug: string }
  '/facilities/:facilityId': { facilityId: string }
  '/facilities/:facilityId/details': { facilityId: string }
  '/facilities/:facilityId/details/contact': { facilityId: string }
  '/people/:pgaId': { pgaId: string }
  '/people/:pgaId/audit-log': { pgaId: string }
  '/people/:pgaId/billing': { pgaId: string }
  '/people/:pgaId/billing/orders': { pgaId: string }
  '/people/:pgaId/details': { pgaId: string }
  '/people/:pgaId/details/change-requests': { pgaId: string }
  '/people/:pgaId/details/committees': { pgaId: string }
  '/people/:pgaId/details/contact': { pgaId: string }
  '/people/:pgaId/details/employment': { pgaId: string }
  '/people/:pgaId/details/membership': { pgaId: string }
  '/people/:pgaId/details/overview': { pgaId: string }
  '/people/:pgaId/details/section': { pgaId: string }
  '/people/:pgaId/education': { pgaId: string }
  '/people/:pgaId/education/learning': { pgaId: string }
  '/people/:pgaId/education/professional-development': { pgaId: string }
  '/people/:pgaId/education/program': { pgaId: string }
  '/people/:pgaId/interactions': { pgaId: string }
  '/people/:pgaId/interactions/interactions': { pgaId: string }
  '/people/:pgaId/interactions/interactions/:interactionId': { pgaId: string; interactionId: string }
  '/sections/:sectionId': { sectionId: string }
  '/sections/:sectionId/audit-log': { sectionId: string }
  '/sections/:sectionId/billing': { sectionId: string }
  '/sections/:sectionId/billing/dues-rates': { sectionId: string }
  '/sections/:sectionId/change-requests': { sectionId: string }
  '/sections/:sectionId/change-requests/settings': { sectionId: string }
  '/sections/:sectionId/jobs': { sectionId: string }
  '/sections/:sectionId/jobs/list': { sectionId: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
