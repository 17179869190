import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import Select from "@/components/Select";
import { Controller, useForm } from "react-hook-form";
import { allModesData, modesMap } from "../queries";
import { InteractionInput } from "../types";
import Switch from "@/components/Switch";
import { useEffect, useState } from "react";
import { useFlags } from "@/hooks/useFeatureFlags";

export default function EditMethodModal({
  open,
  setOpen,
  variables,
  setVariables,
  saveInteraction,
  interactionPrivate,
  disabled,
  allCategories
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  variables: InteractionInput;
  setVariables: (value: InteractionInput) => void;
  saveInteraction: () => void;
  interactionPrivate: boolean;
  disabled: boolean;
  allCategories: string[];
}) {
  const { control } = useForm({
    mode: "onChange"
  });
  const activityType = allCategories?.find((category) => category === variables.activityType) || "";

  const [isPrivate, setIsPrivate] = useState<boolean>(interactionPrivate);

  const flags = useFlags();
  const flagInteractionsPrivacyToggle = flags["backoffice-interactions-privacy-toggle"];

  useEffect(() => {
    setIsPrivate(interactionPrivate);
  }, [interactionPrivate]);

  return (
    <Dialog
      open={open}
      onClosed={() => {
        setVariables({ ...variables, mode: null, activityType: null, privacy_restricted: interactionPrivate });
        setOpen(false);
      }}
      headline="Edit Interaction"
      className="w-[490px] p-6 pr-2"
      actions={
        <>
          <Button
            variant="text"
            disabled={disabled}
            onClick={() => {
              setVariables({ ...variables, mode: null, activityType: null, privacy_restricted: interactionPrivate });
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button trailingIcon={false} icon="check" onClick={saveInteraction} disabled={disabled}>
            Save
          </Button>
        </>
      }
    >
      <Controller
        name="interactionDescription"
        control={control}
        rules={{ required: "Description is required" }}
        render={({ field }) => (
          <Select
            label="Method"
            required={true}
            options={allModesData}
            className="w-full mb-4"
            onSelect={(e) => {
              field.onChange(e);
              setVariables({ ...variables, mode: e });
            }}
            disabled={disabled}
            value={modesMap[variables.mode as keyof typeof modesMap]}
          />
        )}
      />
      <Controller
        name="interactionTopic"
        control={control}
        rules={{ required: "Topic is required" }}
        render={({ field }) => (
          <Select
            label="Topic"
            required={true}
            options={allCategories.map((category) => ({ label: category, value: category }))}
            className="w-full my-4"
            optionListMaxHeight="extended"
            onSelect={(e) => {
              field.onChange(e);
              setVariables({ ...variables, activityType: e });
            }}
            disabled={disabled}
            value={activityType || ""}
          />
        )}
      />

      {flagInteractionsPrivacyToggle && (
        <Controller
          name="interactionPrivacy"
          control={control}
          render={({ field: { onChange } }) => (
            <div className={`flex space-x-4 !mt-2 ${disabled ? "opacity-50" : ""}`}>
              <div className="h-fit my-auto">
                <Switch
                  selected={isPrivate}
                  disabled={disabled}
                  onChange={() => {
                    setIsPrivate(!isPrivate);
                    onChange(!isPrivate);
                    setVariables({ ...variables, privacy_restricted: !isPrivate });
                  }}
                />
              </div>

              <div>
                <div className="text-body-large text-sys-brand-on-surface">Make Private</div>
                <div className="text-body-medium text-sys-brand-on-surface-variant">
                  Only your department can view description
                </div>
              </div>
            </div>
          )}
        />
      )}
    </Dialog>
  );
}
