import { gql, TypedDocumentNode } from "@apollo/client";
import { PageInfo } from "@/components/Pagination";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";

interface Registrant {
  pgaId: string;
  profilePhoto: string;
  programType: string;
  registeredAt: string;
  registrantName: string;
  sessionCount: number;
}

interface RegistrantNode {
  node: Registrant;
}

type Query = {
  eventRegistrants: {
    totalCount: number;
    edges: RegistrantNode[];
    pageInfo: PageInfo;
  };
};

export const UPDATE_EVENT_SESSIONS_REGISTRATION_ATTENDANCES_MUTATION = gql`
  mutation UpdateEventSessionsRegistrationAttendances(
    $attendances: [BackofficeEventsUpdateEventSessionsRegistrationAttendanceInput!]!
  ) {
    updateEventSessionsRegistrationAttendances(attendances: $attendances) {
      success
      message
    }
  }
`;

export const PAT_UPSET_SUBMISSION_MUTATION = gql`
  mutation PatUpsertSubmission($input: PATSubmissionInput!) {
    patUpsertSubmission(input: $input) {
      success
      message
      submission {
        id
        result
        totalScore
        roundOne {
          roundScore
        }
        roundTwo {
          roundScore
        }
        additionalStatus
        note
      }
    }
  }
`;

export const EVENT_QUERY = gql`
  query GetBackofficeEvent($slug: String!) {
    event(slug: $slug) {
      id
      status
      title
      sessionCount
      eventType
      collectsAttendance
      registrationCount
      hadPaidRegistrations
      sessions {
        id
        orderKey
        startsAt
        endsAt
        registrations {
          id
          attended
          registration {
            id
            person {
              id
              pgaId
              profilePhoto
              displayName
              mainProgramType {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const EVENT_REGISTRANTS_QUERY: TypedDocumentNode<Query> = gql`
  query GetEventRegistrants(
    $eventSlug: String!
    $search: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: BackofficeEventsRegistrationStatusEnum
  ) {
    eventRegistrants(
      slug: $eventSlug
      search: $search
      first: $first
      last: $last
      after: $after
      before: $before
      status: $status
    ) {
      edges {
        node {
          id
          pgaId
          profilePhoto
          programType
          registeredAt
          registrantName
          sessionCount
        }
      }
      totalCount
      ...PageInfo
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;
