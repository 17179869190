import classNames from "classnames";
import ListItem, { ListItemProps } from "../ListItem/ListItem";

interface DetailSection {
  heading: string;
  options: ListItemProps[];
}

export interface MultiSectionDetailsCardProps {
  className?: string;
  sections: DetailSection[];
}

export default function MultiSectionDetailsCard({ className, sections = [] }: MultiSectionDetailsCardProps) {
  const filteredSections = sections.filter((section) => section.options.length > 0);
  if (filteredSections.length === 0) return null;
  return (
    <div
      data-testid="multi-section-details-card"
      className={`${className} flex flex-col items-start border-0 border-b border-sys-brand-secondary-container rounded-2xl shadow-web-1 h-full divide-y`}
    >
      {filteredSections.map((section, index) => {
        return (
          <div key={index} className="flex flex-col w-full pt-6 pb-4 px-6" data-testid={section.heading}>
            <p className="text-title-medium mb-2">{section.heading}</p>
            {section.options.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={item.action ?? item.action}
                  className={classNames(`flex items-start`, { "cursor-pointer": item.action })}
                >
                  <ListItem {...item} />
                  {item.renderActions && item.renderActions()}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
