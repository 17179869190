import react from "react";
import { createComponent } from "@lit/react";
import { MdFilterChip } from "@material/web/chips/filter-chip";
import { MdChipSet } from "@material/web/chips/chip-set";

export const LitFilterChip = createComponent({
  tagName: "md-filter-chip",
  elementClass: MdFilterChip,
  react,
  events: { onRemove: "remove" }
});

export const LitChipSet = createComponent({
  tagName: "md-chip-set",
  elementClass: MdChipSet,
  react
});
