import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import { TableDetails } from "@/components/Table";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Person } from "@/types";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PGM_PROGRAM_HISTORY_QUERY } from "./queries";
import { toFormat, isInFuture } from "@/lib/dateHelpers";
import MetricGrid from "@/components/MetricGrid";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { formatPgmTableData } from "./_utils";
import ProgramStatus from "../details/_components/ProgramStatus";

type PersonData = { person: Person };

const TOOLTIP_TITLE = "PGM Program Acceptable Progress";
const ACTIVE_PROGRAM_MESSAGE =
  "Enrollee has 9 years to complete the Professional Golf Management Program from the date of purchase (Level 1 Start Date). If the program is not completed by the expiration, the enrollee will need to start over at the Qualifying Level to elect to membership.";
const EXPIRED_PROGRAM_MESSAGE =
  "Enrollee has not completed the Professional Golf Management Program within 9 years from the date of purchase (Level 1 Start Date). The enrollee must start over at the Qualifying Level to elect to membership.";

const getExpirationDateMetric = (latestPgmProgram: Person["educationLatestPgmProgram"]) => {
  if (!latestPgmProgram?.pgmProgramCompleted) {
    return isInFuture(latestPgmProgram?.pgmProgramExpirationDate || "")
      ? { tooltip: { supportingText: ACTIVE_PROGRAM_MESSAGE, title: TOOLTIP_TITLE }, variant: "default" as const }
      : { tooltip: { supportingText: EXPIRED_PROGRAM_MESSAGE, title: TOOLTIP_TITLE }, variant: "error" as const };
  }
};

export default function LearningDetails() {
  const flags = useFlags();
  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_PGM_PROGRAM_HISTORY_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!flags["backoffice-person-profile-education-learning"]) {
    return null;
  }

  const personData = data?.person;
  const pgmProgramHistory = personData?.educationPgmProgramHistory;
  const latestPgmProgram = personData?.educationLatestPgmProgram;
  const tableData = !!pgmProgramHistory?.length ? formatPgmTableData(pgmProgramHistory) : [];

  const metrics = latestPgmProgram
    ? [
        {
          label: "PGM Program Type",
          value: latestPgmProgram.programType
        },
        {
          label: "PGM Version",
          value: latestPgmProgram.curriculumVersion
        },

        {
          label: "Completion Date",
          value: latestPgmProgram?.pgmProgramCompleted ? toFormat(latestPgmProgram.endDate || "") : null
        },
        {
          label: "Expiration Date",
          value: toFormat(latestPgmProgram?.pgmProgramExpirationDate || ""),
          ...getExpirationDateMetric(latestPgmProgram)
        }
      ]
    : [];

  return (
    <div>
      {!!metrics.length && (
        <div className="mt-4">
          <MetricGrid metrics={metrics} layout="expanded" />
        </div>
      )}
      <Table
        loading={loading}
        renderDetails={() => (
          <TableDetails
            heading="PGA Professional Golf Management (PGM) History"
            body="View PGM history including requirement statuses for each level below"
          />
        )}
        data={tableData}
        columns={[
          {
            header: "Level",
            id: "level",
            accessorKey: "level",
            size: 400,
            enableSorting: false,
            meta: {
              className: getStickyColumnClasses(!!tableData?.length)
            },
            cell: ({ row }) => {
              return (
                <>
                  <div className="text-body-medium text-sys-brand-on-surface-variant">{row.original.level}</div>
                  <div className="text-body-small text-sys-brand-on-surface-variant mt-1">
                    {row.original.programDescription}
                  </div>
                </>
              );
            }
          },
          {
            header: "Version",
            id: "curriculumVersion",
            accessorKey: "curriculumVersion",
            enableSorting: false
          },
          {
            header: "Stage",
            id: "stage",
            accessorKey: "stage",
            enableSorting: false
          },
          {
            header: "Start Date",
            id: "startDate",
            accessorKey: "startDate",
            enableSorting: false
          },
          {
            header: "End Date",
            id: "endDate",
            accessorKey: "endDate",
            enableSorting: false
          },
          {
            header: "Program Status",
            id: "programStatus",
            accessorKey: "programStatus",
            enableSorting: false,
            cell: ({ row }) => <ProgramStatus completed={row.original.completed} />
          }
        ]}
        renderEmptyState={() => (
          <EmptyState
            title="No PGM History"
            caption="This person does not have any Professional Golf Management Program history"
            iconName="school"
          />
        )}
        data-testid="program-history-table"
      />
    </div>
  );
}
