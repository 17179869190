import { useState } from "react";
import { Outlet } from "react-router-dom";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import type { Params, Path } from "@/router";

import React from "react";
import { LinkProps } from "@generouted/react-router/client";

export default function OrderIdLayout<P extends Path>() {
  const [actions, setActions] = useState<React.ReactNode>(null);
  const [backLink, setBackLink] = useState<LinkProps<P, Params>>({ to: "/", title: "Back" } as unknown as LinkProps<
    P,
    Params
  >);

  return (
    <SinglePageLayout backLink={backLink} actions={actions}>
      <Outlet context={{ setActions, setBackLink }} />
    </SinglePageLayout>
  );
}
