import classNames from "classnames";
import ListItem, { ListItemProps } from "../ListItem/ListItem";
import IconButton from "../IconButton";

interface SelectOptions extends ListItemProps {}

export interface DetailsCardProps {
  heading?: string;
  className?: string;
  options: SelectOptions[];
  onEdit?: () => void;
  skipRenderWithEmptyOptions?: boolean;
}

export default function DetailsCard({
  className,
  heading,
  options = [],
  onEdit,
  skipRenderWithEmptyOptions = false
}: DetailsCardProps) {
  if (skipRenderWithEmptyOptions && options.length === 0) return null;

  return (
    <div
      data-testid="details-card"
      className={`${className} flex flex-col items-start border-0 border-b border-sys-brand-secondary-container rounded-2xl p-6 shadow-web-1 h-full`}
    >
      <div className="flex-row w-full" data-testid={heading}>
        {heading && (
          <div className="flex justify-between items-center mb-2">
            <p className="text-title-medium">{heading}</p>
            {onEdit && <IconButton name="edit" title="Edit" onClick={onEdit} skipMargin />}
          </div>
        )}
        <div className="flex flex-col w-full">
          {options.map((item: SelectOptions, index) => (
            <div
              key={index}
              onClick={item.action ?? item.action}
              className={classNames(`flex items-start`, { "cursor-pointer": item.action })}
            >
              <ListItem {...item} />
              {item.renderActions && item.renderActions()}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
