import { MdDialog } from "@material/web/dialog/dialog";
import { createComponent } from "@lit/react";
import React from "react";

export type Ref = MdDialog;

const LitDialog = createComponent({
  tagName: "md-dialog",
  elementClass: MdDialog,
  react: React,
  events: { onOpen: "open", onOpened: "opened", onClose: "close", onClosed: "closed", onCancel: "cancel" }
});

export default LitDialog;
