import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CHANGE_DUE_DATES_MUTATION } from "../queries";
import pluralize from "@/lib/pluralize";
import { showSnackbar } from "@/lib/snackbarUtils";
import { DateTime } from "luxon";
import { OrderType } from "@/pages/billing/orders/[orderId]/types";
import { StatsType } from "../../types";

export type ChangeDueDateModalProps = {
  onDismiss: () => void;
  orders: OrderType[];
  refetch: () => void;
  setStats: (stats: StatsType) => void;
  setRowSelection: (orders: any) => void;
  resetPagination: () => void;
};

export const ChangeDueDateModal = ({
  onDismiss,
  orders,
  refetch,
  setStats,
  setRowSelection,
  resetPagination
}: ChangeDueDateModalProps) => {
  const { control, watch } = useForm();
  const orderIds = Object.keys(orders);
  const headline = `Change Due ${pluralize(orders.length, "Date")}`;

  const [changeDueDateMutation, { loading }] = useMutation(CHANGE_DUE_DATES_MUTATION);
  const [errorContent, setErrorContent] = useState("");
  const [sendNotification, setSendNotification] = useState(false);

  const dueDate = DateTime.now().plus({ days: 30 }).toISO();

  const orderDueDateRange = watch("orderDueDate", {
    startDate: dueDate,
    endDate: dueDate
  });

  const variables = {
    orderIds: orderIds,
    dueDate: orderDueDateRange.startDate,
    sendNotification: sendNotification
  };

  async function changeDueDate() {
    const { data } = await changeDueDateMutation({
      variables: { input: variables }
    });

    if (data.commerceBulkChangeOrderDueDate.success) {
      const successfulOrders = data.commerceBulkChangeOrderDueDate.successfulOrders.length;
      showSnackbar(`${successfulOrders} ${pluralize(successfulOrders, "order")} due dates have been extended`);
      if (sendNotification) {
        showSnackbar("Email Notification Sent");
      }

      setStats({
        totalCount: null,
        failedPayments: null
      });
      resetPagination();
      refetch();
      setRowSelection([]);
      if (data.commerceBulkChangeOrderDueDate.failedOrders.length > 0) {
        setErrorContent(data.commerceBulkChangeOrderDueDate.message);
        resetPagination();
        refetch();
        setRowSelection([]);
      } else {
        onDismiss();
      }
    } else {
      setErrorContent(data.commerceBulkChangeOrderDueDate.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={changeDueDate} disabled={loading || !orderDueDateRange.startDate}>
        {headline}
      </Button>
    </>
  );

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline={headline}
      className="w-[423px] mt-[200px]"
      displayOverflow
    >
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      <p>
        Changing the due {pluralize(orders.length, "date")} of the selected {pluralize(orders.length, "order")} may
        affect eligibility for suspension or termination.
      </p>
      <p className="mt-5 text-label-large">Orders to Update: {orderIds?.length}</p>
      <div className="mt-6">
        <input className="mt-4 ml-1 absolute"></input>

        <Controller
          name="orderDueDate"
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Order Due Date *"
              value={orderDueDateRange}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              showFooter={false}
              popoverDirection="up"
              supportingText="MM/DD/YYYY"
            />
          )}
        />

        <div className="mt-4">
          <Checkbox
            name="send-notification"
            label="Send email notification to payor"
            checked={sendNotification}
            onChange={() => {
              setSendNotification(!sendNotification);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
