import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Controller, FieldValues, UseFormSetValue, useFormContext } from "react-hook-form";
import Autocomplete from "@/components/Autocomplete";
import Icon from "@/components/Icon";
import { GET_MANAGEABLE_SECTIONS } from "../../queries";
import { Section } from "../types";

interface SectionsQueryData {
  manageableSections: {
    nodes: Section[];
  };
}

const SectionAutocompleteWrapper = () => {
  const { setValue } = useFormContext();
  const { data: sectionData } = useQuery<SectionsQueryData>(GET_MANAGEABLE_SECTIONS, {
    variables: { input: { search: null } }
  });
  const manageableSections = useMemo(() => sectionData?.manageableSections?.nodes || [], [sectionData]);

  useEffect(() => {
    if (manageableSections.length === 1) {
      const [section] = manageableSections;
      setValue("sectionId", section.id);
      setValue("section", section.name);
      setValue("legacyId", section.legacyId);
    }
  }, [manageableSections, setValue]);

  if (manageableSections.length <= 1) {
    return <></>;
  } else {
    return <SectionAutocomplete sections={manageableSections} />;
  }
};

const SectionAutocomplete = ({ sections }: { sections: Section[] }) => {
  const {
    control,
    setValue,
    trigger,
    clearErrors,
    watch,
    formState: { errors }
  } = useFormContext();

  function selectSection(value: Section, setValue: UseFormSetValue<FieldValues>) {
    setValue("sectionId", value.id);
    setValue("section", value.name);
    setValue("legacyId", value.legacyId);
    clearErrors(["section", "sectionId"]);
  }

  const filterSections = (value: string) =>
    sections.filter((section) => section.name.toLowerCase().includes(value?.toLowerCase() || ""));

  const sectionId = watch("sectionId");

  return (
    <>
      <Controller
        data-testid="section-select"
        name="section"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Autocomplete
              variant="searchableDropdown"
              label="Managing Section *"
              query={value}
              data={filterSections(value)}
              onChangeText={(value) => {
                onChange(value);
                setValue("section", value);
                setValue("sectionId", "");
                if (value && errors.section) {
                  clearErrors("section");
                }
              }}
              onBlur={() => {
                onBlur();
                if (value && !sectionId) {
                  setValue("section", "");
                  trigger("section");
                }
              }}
              onMouseDown={(e: MouseEvent) => {
                e.preventDefault();
              }}
              onSelect={(value) => selectSection(value, setValue)}
              reset={() => {
                setValue("section", "");
                setValue("sectionId", "");
              }}
              error={!!errors?.section}
              errorText={errors?.section?.message as string}
              renderItem={(data) => (
                <div className="flex items-center border-bottom">
                  <div className="bg-sys-brand-secondary-container rounded-full p-2 mr-4 flex items-center justify-center">
                    <Icon name="domain" size={24} />
                  </div>
                  <div>
                    <div className="text-body-large">{data.name}</div>
                  </div>
                </div>
              )}
            />
          );
        }}
      />
    </>
  );
};

export default SectionAutocompleteWrapper;
