import IconButton from "../IconButton";
import Icon from "@/components/Icon";
import { useParams } from "@/hooks/useSearchParams";
export const DEFAULT_PAGE_SIZE = 20;
export type PageSize = typeof DEFAULT_PAGE_SIZE | 50 | 100;

export type PaginationVariables = {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
};

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
};

export type PaginationProps = {
  pageInfo: PageInfo;
  variables: PaginationVariables;
  setVariables: (variables: PaginationVariables) => void;
  pageSize: PageSize;
  setPageSize: (pageSize: PageSize) => void;
  pageRows: number;
  totalRows: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  variant?: "compact" | "default";
};

export default function Pagination({
  pageInfo,
  setVariables,
  variables,
  setPageSize,
  pageSize,
  pageRows,
  totalRows,
  currentPage,
  setCurrentPage,
  variant = "default"
}: PaginationProps) {
  const { searchParamsUrl, addSearchParam, deleteSearchParams } = useParams();

  const startPage = totalRows === 0 ? 0 : currentPage * pageSize + 1;
  const endPage = totalRows === 0 ? 0 : startPage + pageRows - 1;
  const options = [{ value: "20" }, { value: "50" }, { value: "100" }];

  return (
    <div className="flex items-start md:items-center justify-end flex-col md:flex-row sticky">
      {variant !== "compact" && (
        <div className="flex items-center mr-2">
          <span className="text-label-large mr-1">Rows per page:</span>
          <div className="w-[68px]">
            <div className="relative">
              <select
                className="bg-white border text-sys-brand-on-surface-variant appearance-none text-label-large rounded-lg block w-full h-8 py-1 px-2 pl-4 border-sys-brand-on-surface-variant cursor-pointer"
                onChange={(v: React.ChangeEvent<HTMLSelectElement>) => {
                  setPageSize(Number(v.target.value) as PageSize);
                  setCurrentPage(0);
                  const { before, after, last, ...otherParams } = variables;
                  setVariables({ ...otherParams, first: Number(v.target.value) });
                  if (searchParamsUrl) {
                    addSearchParam("pageSize", v.target.value);
                  }
                }}
                data-testid="page-size-select"
                value={String(pageSize)}
              >
                {options.map(({ value }: { value: string }, i: number) => (
                  <option key={i} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <Icon name="arrow_drop_down" size={24} className="pointer-events-none absolute bottom-1 right-2" />
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between md:justify-end w-full md:w-fit">
        <div className="text-label-large">
          {startPage} - {endPage} of {totalRows}
        </div>
        <div>
          <IconButton
            name="navigate_before"
            skipMargin
            onClick={() => {
              const { after, first, ...variablesNew } = variables;
              setCurrentPage(currentPage - 1);
              setVariables({ ...variablesNew, before: pageInfo?.startCursor, last: pageSize });
              if (searchParamsUrl) {
                addSearchParam("before", pageInfo?.startCursor || "");
                addSearchParam("currentPage", (currentPage - 1).toString());
                deleteSearchParams(["after", "first"]);
              }
            }}
            disabled={!pageInfo?.hasPreviousPage}
            data-testid="previous-button"
          />
          <IconButton
            name="navigate_next"
            skipMargin
            onClick={() => {
              const { before, last, ...variablesNew } = variables;
              setCurrentPage(currentPage + 1);
              setVariables({ ...variablesNew, after: pageInfo?.endCursor || "", first: pageSize });
              if (searchParamsUrl) {
                addSearchParam("after", pageInfo?.endCursor || "");
                addSearchParam("currentPage", (currentPage + 1).toString());
                deleteSearchParams(["before", "last"]);
              }
            }}
            disabled={!pageInfo?.hasNextPage}
            data-testid="next-button"
          />
        </div>
      </div>
    </div>
  );
}
