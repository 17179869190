import Avatar from "@/components/Avatar";
import AvatarDetails from "@/components/AvatarDetails";
import Button from "@/components/Button";
import EmptyState from "@/components/EmptyState";
import Icon from "@/components/Icon";
import IconButton from "@/components/IconButton";
import Menu from "@/components/Menu";
import ObjectHeading from "@/components/ObjectHeading";
import { CardSkeleton, ProfileSkeleton } from "@/components/Skeleton";
import Table from "@/components/Table";
import Tag from "@/components/Tag";
import usePageTitle from "@/hooks/usePageTitle";
import useAmplitude from "@/hooks/useAmplitude";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import { toDateFormat, toDateTimeFormat } from "@/lib/dateHelpers";
import { showSnackbar } from "@/lib/snackbarUtils";
import { getPrimaryFacilityName } from "@/pages/people/_utils";
import { PERMISSION } from "@/providers/AuthorizationProvider";
import { Path } from "@/router";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { convertMode, getIcon, getLinkDescription, groupMessages } from "../_utils";
import DeleteInteractionModal from "./_components/DeleteInteractionModal";
import EditMessageModal from "./_components/EditMessageModal";
import EditMethodModal from "./_components/EditMethodModal";
import ShareInteractionModal from "./_components/ShareInteractionModal";
import AiCard from "@/components/AiCard";
import { useAiQuery } from "@/lib/aiHelpers";
import { SummaryPayload } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { formatAiSummary } from "@/pages/people/[pgaId]/interactions/_utils";
import {
  DELETE_INTERACTION_MUTATION,
  GET_PERSON_INTERACTION_QUERY,
  UPDATE_INTERACTION_MUTATION,
  CategoriesData,
  CATEGORIES_QUERY,
  SHARE_INTERACTION_MUTATION
} from "./queries";
import { Interaction, InteractionInput } from "./types";
import { useFlags } from "@/hooks/useFeatureFlags";
import { User } from "@auth0/auth0-react";
import { getInteractionTrackingData } from "./_utils/amplitudeHelper";

type InteractionData = { crmInteraction: Interaction };

export default function InteractionShowPage() {
  usePageTitle(
    "/people/:pgaId/interactions/interactions/:interactionId",
    "People | Person Details | Interactions | Interaction Details"
  );
  const { trackEvent } = useAmplitude();
  const { pgaId, interactionId } = useParams();
  const navigate = useNavigate();
  const { fetchData: generateSummary, loading: generatingSummary } = useAiQuery<SummaryPayload>(
    "career-consultation-summarizer"
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareVariables, setShareVariables] = useState<{ recipients: User[]; notes: string }>({
    recipients: [],
    notes: ""
  });
  const [editMessageModalOpen, setEditMessageModalOpen] = useState(false);
  const [variables, setVariables] = useState<InteractionInput>({
    mode: null,
    description: null,
    interactionDate: null,
    activityType: null
  });

  const [editInteractionMutation, { loading: updatingInteraction }] = useMutation(UPDATE_INTERACTION_MUTATION);
  const [deleteInteractionMutation, { loading: deletingInteraction }] = useMutation(DELETE_INTERACTION_MUTATION);
  const [shareInteractionMutation, { loading: sharingInteraction }] = useMutation(SHARE_INTERACTION_MUTATION);

  const asyncLoading = updatingInteraction || generatingSummary;
  const flags = useFlags();
  const backofficeInteractionsSharing = flags["backoffice-interactions-sharing"];

  const { data, loading, error } = useQuery<InteractionData>(GET_PERSON_INTERACTION_QUERY, {
    variables: { id: interactionId },
    fetchPolicy: "cache-first"
  });
  const { data: allCategoriesData, loading: loadingCategories } = useQuery<CategoriesData>(CATEGORIES_QUERY, {
    fetchPolicy: "cache-first"
  });

  if (error || !pgaId) {
    return <div data-testid="error">Error! {error?.message}</div>;
  }

  const interaction = data?.crmInteraction;

  const hasAccessToInteraction =
    !interaction?.lastMessage.isPrivate ||
    (interaction?.lastMessage?.isPrivate && interaction?.lastMessage?.haveAccess);

  if (!hasAccessToInteraction) {
    navigate(`/people/${pgaId}/interactions/interactions`);
    showSnackbar("Access to this interaction is restricted");
  }

  const Actions = () => {
    let menuItems = [
      {
        label: "Edit Interaction",
        onClick: () => {
          setVariables({ ...variables, mode: mode, activityType: categories?.[0] });
          setEditModalOpen(true);
        }
      },
      {
        label: "Delete Interaction",
        onClick: () => {
          setDeleteModalOpen(true);
        }
      }
    ];

    if (backofficeInteractionsSharing) {
      menuItems.push({
        label: "Share Interaction",
        onClick: () => {
          setShareModalOpen(true);
        }
      });
    }

    if (hasAccessToInteraction) {
      if (PERMISSION.managePeopleInteractions) {
        return (
          <>
            <div className="tablet:hidden">
              <Menu
                yOffset={8}
                {...{
                  AnchorComponent: <IconButton name="more_vert" iconClassName="font-extrabold" />,
                  menuItems: menuItems
                }}
              />
            </div>

            <div className="hidden tablet:block">
              <div className="space-x-3">
                <Button
                  variant="text"
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  <div className="flex space-x-2">
                    <Icon name="delete" className="my-auto" size={20} />
                    <div className="my-auto">Delete Interaction</div>
                  </div>
                </Button>
                <Button
                  trailingIcon={false}
                  variant="outlined"
                  onClick={() => {
                    setVariables({ ...variables, mode: mode, activityType: categories?.[0] });
                    setEditModalOpen(true);
                  }}
                >
                  Edit Interaction
                </Button>

                {backofficeInteractionsSharing && (
                  <Button
                    trailingIcon={false}
                    onClick={() => {
                      setShareModalOpen(true);
                    }}
                  >
                    <div className="flex space-x-2">
                      <Icon name="ios_share" className="my-auto" size={20} />
                      <div className="my-auto">Share Interaction</div>
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </>
        );
      }
    }
  };

  const backTo: Path = "/people/:pgaId/interactions/interactions";
  const backLink = { to: backTo, params: { pgaId } };

  if (loading || loadingCategories) {
    return (
      <SinglePageLayout backLink={backLink}>
        <div className="mt-8 space-y-8">
          <div className="w-[750px]">
            <ProfileSkeleton />
          </div>

          <CardSkeleton className="!w-full" />

          <CardSkeleton className="!w-full" rows={6} />
        </div>
      </SinglePageLayout>
    );
  }

  if (!interaction || !allCategoriesData) {
    return <div data-testid="error">Error! Cannot find the Interaction</div>;
  }

  const {
    mode,
    owner,
    relatedTo,
    lastMessage,
    messagesCount,
    categories,
    messages,
    participants,
    summary,
    interactionDate,
    lastModifiedDate
  } = interaction;
  const { haveAccess } = lastMessage;

  const icon = getIcon(mode);
  const linkDescription = getLinkDescription(mode);
  const allCategories = [...new Set([...allCategoriesData.interactionActivityTypes, ...categories])].sort();
  const messagesMap = groupMessages(messages, new Date(interactionDate));
  let subText;

  const participantsMinusSelf = participants.filter((participant) => participant.pgaId !== pgaId);

  const currentParticipant = participants.find((participant) => participant.pgaId === pgaId);
  const currentParticipantName = currentParticipant
    ? `${currentParticipant?.firstName} ${currentParticipant?.lastName}`
    : relatedTo;

  if (mode === "EMAIL") {
    subText = [
      { text: `Modified: ${toDateTimeFormat(lastModifiedDate as string) || "-"}` },
      { text: `Messages: ${messagesCount}` },
      { text: `Subject: ${haveAccess ? summary : "You don't have access to this message"}` }
    ];
  } else {
    subText = [
      { text: `Modified: ${toDateTimeFormat(lastModifiedDate as string) || "-"}` },
      { text: `Messages: ${messagesCount}` }
    ];
  }

  async function deleteInteraction() {
    try {
      const { data } = await deleteInteractionMutation({
        variables: {
          id: interactionId
        }
      });

      if (data?.deleteInteraction?.success) {
        setDeleteModalOpen(false);
        showSnackbar("Interaction deleted");
        navigate(`/people/${pgaId}/interactions/interactions`);
      } else {
        setDeleteModalOpen(false);
        showSnackbar("There was a problem deleting this interaction. Please try again later");
      }
    } catch {
      setDeleteModalOpen(false);
      showSnackbar("There was a problem deleting this interaction. Please try again later");
    }
  }
  async function closeModals() {
    setEditModalOpen(false);
    setEditMessageModalOpen(false);
  }

  async function saveInteraction() {
    try {
      // Generate AI summary and capture the returned value
      const summaryResponse = await generateSummary({
        mode: variables.mode ?? null,
        subject: variables.description ?? null,
        comments: lastMessage?.content ?? null
      });

      const modeEnum = variables.mode ? convertMode.fromAPI(variables.mode) : null;
      const apiMode = modeEnum ? convertMode.toAPI(modeEnum) : null;
      const formattedSummary = formatAiSummary(summaryResponse);

      const updateInput: any = {
        interactionId: interactionId,
        mode: apiMode,
        description: variables.description,
        interactionDate: variables.interactionDate,
        activityType: variables.activityType,
        summary: formattedSummary,
        privacyRestricted: variables.privacy_restricted
      };

      const { data } = await editInteractionMutation({
        variables: { input: updateInput },
        refetchQueries: [{ query: GET_PERSON_INTERACTION_QUERY, variables: { id: interactionId } }],
        awaitRefetchQueries: true
      });

      if (data?.updateInteraction?.success) {
        const trackingData = getInteractionTrackingData(
          variables,
          interaction!,
          participants,
          formattedSummary,
          interactionId!,
          pgaId!
        );

        trackEvent("update interaction", trackingData);

        closeModals();
        showSnackbar("Interaction saved");
      } else {
        throw new Error(data?.updateInteraction?.message || "Save failed");
      }
    } catch (error) {
      closeModals();
      showSnackbar("There was a problem saving this interaction. Please try again later");
    }
  }

  async function shareInteraction() {
    try {
      const { data } = await shareInteractionMutation({
        variables: {
          input: {
            interaction: {
              id: interaction!.id,
              description: interaction!.lastMessage.content,
              owner: interaction!.owner,
              date: interaction!.interactionDate,
              mode: interaction!.mode,
              topic: interaction!.categories[0],
              participants: [
                interaction!.relatedTo,
                ...interaction!.participants.map((participant) => `${participant.firstName} ${participant.lastName}`)
              ]
            },
            recipients: shareVariables.recipients.map((recipient) => ({
              name: recipient.name,
              email: recipient.email
            })),
            notes: shareVariables.notes,
            pgaId: pgaId
          }
        }
      });

      if (data?.shareInteraction?.success) {
        showSnackbar("Interaction has been shared");
        setShareModalOpen(false);
        setShareVariables({ recipients: [], notes: "" });
      } else {
        showSnackbar("There was a problem sharing this interaction. Please try again later");
      }
    } catch {
      showSnackbar("There was a problem sharing this interaction. Please try again later");
    }
  }

  return (
    <SinglePageLayout backLink={backLink} actions={<Actions />}>
      <DeleteInteractionModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        participantsCount={participants.length}
        deleteInteraction={deleteInteraction}
        disabled={deletingInteraction}
      />

      <EditMethodModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        variables={variables}
        setVariables={setVariables}
        interactionPrivate={interaction.lastMessage.isPrivate}
        saveInteraction={saveInteraction}
        disabled={asyncLoading}
        allCategories={allCategories}
      />

      <EditMessageModal
        open={editMessageModalOpen}
        setOpen={setEditMessageModalOpen}
        variables={variables}
        setVariables={setVariables}
        saveInteraction={saveInteraction}
        disabled={asyncLoading}
      />

      <ShareInteractionModal
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        shareInteraction={shareInteraction}
        isPrivate={interaction.lastMessage.isPrivate}
        shareVariables={shareVariables}
        setShareVariables={setShareVariables}
        disabled={sharingInteraction}
      />

      <div className="max-w-[1440px] mx-auto mt-3 sm:mt-8" data-testid="interaction-details-page">
        <ObjectHeading
          title={`${owner} ${linkDescription} ${currentParticipantName}`} //this is an override to show the current participant's name instead of the WHO's name IOT provide a bit of clarity
          iconName={icon}
          subText={subText}
          isDetailsView
        />
        {hasAccessToInteraction && <AiCard className="mt-6" title="Interaction Summary" text={interaction?.summary} />}

        {hasAccessToInteraction && (
          <ul className="mt-6 flex flex-wrap gap-2" data-testid="interaction-tags">
            {allCategories
              .filter((category) => categories.includes(category))
              .map((category, i) => (
                <li key={i}>
                  <Tag color="secondary" size="large" iconClass="text-sys-brand-primary">
                    {category}
                  </Tag>
                </li>
              ))}
          </ul>
        )}
        <div className="mt-8">
          <p className="text-title-medium">Messages</p>
          <p className="mt-1 text-body-medium">View all messages associated with this interaction</p>
        </div>
        <div className="mt-6 border border-sys-brand-outline-variant rounded-lg p-4">
          <ul className="flex flex-col gap-4">
            {Object.entries(messagesMap).map(([monthYear, messages], i) => (
              <li key={i} data-testid={`messages-month-group-${i}`} className="flex flex-col items-center">
                <div className="text-title-medium">{monthYear}</div>
                <ul
                  className={`flex flex-col gap-12 mt-6 w-full group pt-1 ${
                    hasAccessToInteraction && "cursor-pointer hover:bg-sys-brand-surface-container"
                  }`}
                >
                  {messages.map((message, i) => (
                    <li key={i} className={`flex max-w-full gap-3 ${message.fromOwner ? "self-end" : "self-start"}`}>
                      {!message.fromOwner && <Avatar picture={message.fromProfilePhoto} iconClassName="p-6" />}
                      <div className="tablet:max-w-[93%] max-w-[80%] relative">
                        <div
                          className={`border w-fit px-3 rounded-lg shadow-lg border-gray-400 opacity-0 transition-all duration-200 absolute -top-12 right-0 bg-white ${
                            hasAccessToInteraction && "group-hover:opacity-100"
                          }`}
                        >
                          <IconButton
                            name="edit"
                            className="!m-0"
                            onClick={() => {
                              setVariables({
                                ...variables,
                                interactionDate: interactionDate.toString().split("T")[0],
                                description: lastMessage.content
                              });
                              setEditMessageModalOpen(true);
                            }}
                          />
                        </div>
                        <div
                          className={`rounded-lg py-3 px-4 flex gap-3 ${
                            message.fromOwner
                              ? "bg-sys-brand-secondary-container"
                              : "bg-sys-brand-surface-container-high"
                          }`}
                        >
                          {message.fromOwner && message.isPrivate && <Icon name="lock" size={18} className="mt-1" />}
                          <p className="text-body-large tablet:max-w-[800px] tablet:w-full w-[85%] whitespace-pre-wrap break-words">
                            {message.isPrivate && !message.haveAccess
                              ? "You don't have access to this message"
                              : message.content}
                          </p>
                          {!message.fromOwner && message.isPrivate && (
                            <Icon name="lock" size={18} className="ml-auto mt-1" />
                          )}
                        </div>
                        <p className={`mt-2 text-label-small ${message.fromOwner && "text-right	"}`}>
                          {toDateFormat(new Date(interactionDate).toISOString().split("T")[0]) || ""}
                        </p>
                      </div>
                      {message.fromOwner && <Avatar picture={message.fromProfilePhoto} iconClassName="p-6" />}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        {participantsMinusSelf.length > 0 && (
          <>
            <div className="mt-8 mb-6" data-testid="additional-people-included">
              <p className="text-title-medium">Additional People Included</p>
              <p className="mt-1 text-body-medium">Select a person to view their corresponding interaction</p>
            </div>

            <Table
              data={participantsMinusSelf}
              loading={loading}
              data-testid="additional-people-table"
              // onClickRow={onClickRow}
              columns={[
                {
                  size: 230,
                  header: "Name",
                  id: "fullName",
                  cell: ({ row: { original: person } }) => {
                    const { firstName, lastName, profilePhoto } = person;
                    return <AvatarDetails picture={profilePhoto} title={`${firstName} ${lastName}`} text="" />;
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", id: "pgaId", enableSorting: false },
                { accessorKey: "classification", header: "Class", id: "class", enableSorting: false },
                { accessorKey: "currentStatus", header: "Status", id: "status", enableSorting: false },
                {
                  accessorKey: "primary_section_affiliation",
                  header: "Section",
                  size: 200,
                  enableSorting: false,
                  cell: ({ row: { original: person } }) =>
                    person.primarySectionAffiliation?.section?.name || <div className="opacity-20">--</div>
                },
                {
                  accessorKey: "primaryFacility",
                  header: "Primary Facility",
                  enableSorting: false,
                  size: 300,
                  cell: (cellProps) =>
                    getPrimaryFacilityName(cellProps.row.original) || <div className="opacity-20">--</div>
                }
              ]}
              renderEmptyState={() => <EmptyState title="No Results Found" iconName="today" />}
            />
          </>
        )}
      </div>
    </SinglePageLayout>
  );
}
