import { Outlet, useParams } from "react-router-dom";
import { getSectionNavLinks } from "../_utils";
import { useFlags } from "@/hooks/useFeatureFlags";
import AppLayout from "@/layouts/AppLayout";
import { SectionDetailsHeader } from "../_components/SectionDetailsHeader";
import { DOMAIN, PERMISSION, useHasCapabilityBool } from "@/hooks/useCapabilities";

export default function SectionIdLayout() {
  const { sectionId } = useParams();
  const flags = useFlags();
  const hasJobPermissions = useHasCapabilityBool({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewJobPosts });

  if (!sectionId) {
    return <div data-testid="error">Error!</div>;
  }

  const navLinks = getSectionNavLinks(sectionId, flags, hasJobPermissions);

  return (
    <AppLayout navLinks={navLinks} interior bottomBar>
      <SectionDetailsHeader sectionId={sectionId} title="People" />
      <Outlet />
    </AppLayout>
  );
}
