import { DOMAIN } from "@/hooks/useCapabilities";
import { NavLink } from "@/layouts/AppLayout/Sidebar/Sidebar";
import { Path } from "@/router";
import { FlagRecord } from "@/hooks/useFeatureFlags";

export const getDuesManagementNavLinks: (flags: FlagRecord) => Record<string, NavLink<Path>[]> = (flags) => {
  const manageDuesLinks = [
    ...(flags["backoffice-dues-rates"]
      ? [
          {
            domain: DOMAIN.COMMERCE,
            label: "Products",
            link: { to: "/billing/dues-management/manage-products" },
            icon: "box"
          }
        ]
      : []),
    {
      domain: DOMAIN.COMMERCE,
      label: "Suspensions and Terminations",
      link: { to: "/billing/dues-management/suspensions-and-terminations" },
      icon: "group_off"
    },
    {
      domain: DOMAIN.COMMERCE,
      label: "Reporting",
      link: { to: "/billing/dues-management/reporting" },
      icon: "monitoring"
    }
  ].filter(Boolean) as NavLink<Path>[];

  return {
    "Manage Dues": manageDuesLinks
  };
};
