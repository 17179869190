import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ORDERS_QUERY } from "../queries";
import { Variables } from "../types";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import useCapabilities, { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useNavigate, Path } from "@/router";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import usePagination from "@/hooks/usePagination";
import { Controller, useForm } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { intersectionBy, reject, isEmpty } from "lodash";
import IconButton from "@/components/IconButton";
import { ChangeDueDateModal } from "./ChangeDueDateModal";
import { TerminateModal } from "./TerminateModal";
import { SuspendModal } from "./SuspendModal";
import Checkbox from "@/components/Checkbox";
import { RowSelectionState } from "@tanstack/react-table";
import Chip from "@/components/Chip";
import Button from "@/components/Button";
import pluralize from "@/lib/pluralize";
import useActionMenu, { actionId } from "@/hooks/useActionMenu";
import MultiSelect from "@/components/MultiSelect";
import DateSelect from "@/components/DateSelect";
import { NumberFormatBase } from "react-number-format";
import TextField from "@/components/TextField";
import { isNull, get } from "lodash";
import FacilityAutocomplete from "../../../_components/FacilityAutocomplete";
import { FormProvider } from "react-hook-form";
import ExpandedFilters from "@/components/ExpandedFilters";
import SectionAutocomplete from "../../../_components/SectionAutocomplete";
import { currencyFormatter } from "@/lib/currencyHelpers";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useSearch } from "@/hooks/useSearch";
import { renderStatus } from "@/pages/billing/_utils";
import { GET_CLASSIFICATIONS } from "../../../queries";
import { ClassificationType, StatsType } from "../../types";
import MetricGrid from "@/components/MetricGrid";
import getActiveFilters from "@/lib/filterHelpers";
import { useParams } from "@/hooks/useSearchParams";
import { OrderRow, OrderNode } from "@/pages/billing/types";

export type FormValues = {
  maxBalance: string;
  memberClassifications: string[];
  paymentStatuses: [];
  sectionIds: string[];
  facilityIds: string[];
  dueDateRange: { startDate: Date | null; endDate: Date | null };
};

const mapCommerceOrders = (data: OrderNode[]) =>
  (data || []).map(({ updatedAt, dueDate, balance, lineItems, ...order }) => ({
    ...order,
    pgaId: order.person.pgaId,
    classification: order.person.classification || "",
    section: order.person.primarySectionAffiliation?.section.name || "",
    updatedAt: formatDate(updatedAt),
    dueDate: formatDate(dueDate),
    balance: centsToDollarsFormatted(balance) || undefined,
    products: lineItems.length
  }));

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

export const OrdersTable = ({ defaultVariables }: { defaultVariables: Variables }) => {
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const { hasPermission } = useCapabilities();
  const availableForSuspensions = Object.keys(defaultVariables)[0] === "availableForSuspensions";

  let { params, addSearchParam, deleteSearchParams, searchParamsUrl } = useParams();
  params.balanceRange = params.maxBalance && { to: Number(params.maxBalance) };
  params.dueDateRange = params.dueDateRange && { from: null, to: params.dueDateRange || null };

  const { control: searchControl } = useSearch("name", params.query || "");

  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const [openChangeDueDateModal, setOpenChangeDueDateModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [terminateAll, setTerminateAll] = useState(false);
  const [suspendAll, setSuspendAll] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState<any>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const rowSelected = Object.keys(rowSelection).length;
  const [selectedData, setSelectedData] = useState<OrderRow[]>([]);
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>(params.paymentStatuses || []);
  const [selectedClassificationValues, setSelectedClassificationValues] = useState<string[]>(
    params.memberClassifications || []
  );

  const [variables, setVariables] = useState<Variables>({ ...defaultVariables, ...params });
  const { data, loading, refetch } = useQuery(ORDERS_QUERY, { variables: { ...variables, ...paginationVariables } });
  const [selectedOrdersQuery, { data: allSelectedData, loading: allSelectedLoading }] = useLazyQuery(ORDERS_QUERY);
  const { data: classificationData } = useQuery(GET_CLASSIFICATIONS);

  const navigate = useNavigate();
  const { Menu, setMenu } = useActionMenu();

  const form = useForm<FormValues>();
  const { control, reset, watch, getValues, setValue } = form;

  const pattern = "/billing/orders";
  function onClickRow(row: Row<OrderRow>) {
    navigate(
      `${pattern}/${row.original.id}?return_to=/billing/dues-management/suspensions-and-terminations/${
        availableForSuspensions ? `suspensions&${searchParamsUrl}` : `terminations&${searchParamsUrl}`
      }` as Path
    );
  }

  const orderData: OrderRow[] = mapCommerceOrders(data?.currentDuesCycle?.orders.nodes || []);

  const allSelectedDataArray: OrderRow[] = mapCommerceOrders(allSelectedData?.currentDuesCycle?.orders.nodes || []);

  const getIsSomeRowsSelected = () => {
    return intersectionBy(selectedData, orderData, "id").length > 0 && selectedData.length !== orderData.length;
  };

  const [stats, setStats] = useState<StatsType>({
    totalCount: null,
    failedPayments: null
  });

  useEffect(() => {
    if (!loading && data) {
      if (stats.totalCount === null || stats.failedPayments == null) {
        setStats({
          totalCount: data.currentDuesCycle?.totalOrders?.totalCount,
          failedPayments: data.currentDuesCycle?.totalOrdersWithFailedPayments?.totalCount
        });
      }
      setValue("sectionIds", params.sectionIds);
      setValue("facilityIds", params.facilityIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);
  const sectionIds = watch("sectionIds");
  const facilityIds = watch("facilityIds");

  useEffect(() => {
    setVariables((prevValue) => ({
      ...prevValue,
      ...(sectionIds ? { sectionIds: sectionIds } : {}),
      ...(facilityIds ? { facilityIds: facilityIds } : {})
    }));
  }, [sectionIds, facilityIds]);

  const isMobileDisplay = isMobile(useWindowSize().width);

  const filters = {
    maxBalance: !!getValues("maxBalance"),
    section: !!sectionIds,
    status: selectedValues.length > 0,
    classifications: selectedClassificationValues.length > 0,
    dueDateRange: !!variables.dueDateRange?.to,
    facility: !!facilityIds
  };

  const { maxBalance, section, ...extendedFilters } = filters;
  const { isActive, activeFilters } = getActiveFilters(filters, extendedFilters, isMobileDisplay);

  const classificationNodes = get(classificationData, "classifications.nodes");
  const classificationDataNodes =
    classificationNodes?.map((node: ClassificationType) => ({
      value: node.name,
      label: node.name
    })) || [];

  const ClearAllButton = () => (
    <Button
      variant="text"
      onClick={() => {
        {
          setVariables(defaultVariables);
          setSelectedValues([]);
          setSelectedClassificationValues([]);
          reset({
            dueDateRange: { startDate: null, endDate: null }
          });
          deleteSearchParams([
            "dueDateRange",
            "maxBalance",
            "memberClassifications[]",
            "paymentStatuses[]",
            "sectionIds[]",
            "section",
            "facilityIds[]",
            "facility"
          ]);
        }
      }}
    >
      Clear All
    </Button>
  );
  return (
    <>
      {stats.totalCount === 0 ? (
        <EmptyState
          title={`No-one Eligible for ${availableForSuspensions ? "Suspension" : "Termination"} for Overdue Dues`}
          iconName="person"
        />
      ) : (
        <>
          <MetricGrid
            metrics={[
              {
                label: `Total Eligible for ${availableForSuspensions ? "Suspension" : "Termination"}`,
                value: `${!isNull(stats.totalCount) && stats.totalCount}`,
                loading: isNull(stats.totalCount)
              },
              {
                label: `Orders in Active Filter(s)`,
                value: `${data?.currentDuesCycle?.orders.totalCount}`,
                loading: loading
              },
              {
                label: "Total Orders with Failed Payment",
                value: `${!isNull(stats.failedPayments) && stats.failedPayments}`,
                variant: "error",
                loading: isNull(stats.failedPayments)
              }
            ]}
            layout="expanded"
          />
          <Menu />

          <div className="pt-8 px-4 pb-6 justify-between tablet:flex">
            <div className="max-w-[1000px] pr-5">
              <p className="text-body-medium">
                {availableForSuspensions
                  ? "List of Dues orders that have not been fully paid and are overdue. Multi-select people to suspend a subset, or press “Suspend All” to start the suspension process for everyone."
                  : "List of Dues orders that have not been fully paid and are overdue by more than 30 days. Multi-select people to terminate a subset, or press “Terminate All” to start the termination process for everyone."}
              </p>
            </div>

            <div className="mt-4 tablet:mt-0">
              {rowSelected === 0 && hasPermission(PERMISSION.manageOrders) && (
                <>
                  {availableForSuspensions ? (
                    <Button
                      variant="filled"
                      onClick={() => {
                        setSuspendAll(true);
                        setSelectedOrders([]);
                        setOpenSuspendModal(true);
                      }}
                    >
                      Suspend All
                    </Button>
                  ) : (
                    <Button
                      variant="filled"
                      onClick={() => {
                        setTerminateAll(true);
                        setSelectedOrders([]);
                        setOpenTerminateModal(true);
                      }}
                    >
                      Terminate All
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-full">
            <Table
              data={(isSelectedOnly ? allSelectedDataArray : orderData) || []}
              loading={loading || allSelectedLoading}
              onClickRow={onClickRow}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              setSelectedData={setSelectedData}
              renderHeader={() => (
                <div>
                  <div className="flex md:items-center justify-between">
                    <div className="flex md:items-center flex-col md:flex-row w-full ">
                      <FormProvider {...form}>
                        <div className="flex gap-3 md:items-center w-full">
                          <div className="w-full sm:max-w-[320px]">
                            <Controller
                              name="name"
                              control={searchControl}
                              render={({ field }) => (
                                <SearchInput
                                  placeholder="Search Orders"
                                  query={field.value}
                                  param="Name, PGA ID"
                                  onClear={() => {
                                    deleteSearchParams("query");
                                  }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setVariables((prevValue) => ({ ...prevValue, query: e }));
                                    resetPagination();
                                    addSearchParam("query", e);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {rowSelected === 0 && (
                            <>
                              {!isMobileDisplay && (
                                <>
                                  <div className="min-w-[210px]">
                                    <Controller
                                      name="maxBalance"
                                      control={control}
                                      defaultValue={params.maxBalance || ""}
                                      render={({ field }) => {
                                        return (
                                          <NumberFormatBase
                                            label="Max Balance"
                                            placeholder="Enter Amount"
                                            leadingIcon="attach_money"
                                            customInput={TextField}
                                            format={currencyFormatter}
                                            value={field.value ?? ""}
                                            name={field.name}
                                            onValueChange={(values) => {
                                              field.onChange(values.floatValue);
                                              if (values.floatValue) {
                                                setVariables((prevValue) => ({
                                                  ...prevValue,
                                                  balanceRange: { to: values.floatValue }
                                                }));
                                                addSearchParam("maxBalance", values.floatValue.toString());
                                              } else {
                                                setVariables((prevValue) => {
                                                  const { balanceRange, ...rest } = prevValue;
                                                  return {
                                                    ...rest
                                                  };
                                                });
                                                deleteSearchParams("maxBalance");
                                              }
                                            }}
                                            className="w-full"
                                          />
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="min-w-[210px]">
                                    <SectionAutocomplete
                                      params={params}
                                      addSearchParam={addSearchParam}
                                      deleteSearchParams={deleteSearchParams}
                                    />
                                  </div>
                                </>
                              )}
                              <ExpandedFilters
                                activeFilters={activeFilters}
                                items={[
                                  {
                                    component: (
                                      <Controller
                                        name="maxBalance"
                                        control={control}
                                        defaultValue={params.maxBalance || ""}
                                        render={({ field }) => {
                                          return (
                                            <NumberFormatBase
                                              label="Max Balance"
                                              placeholder="Enter Amount"
                                              leadingIcon="attach_money"
                                              customInput={TextField}
                                              format={currencyFormatter}
                                              value={field.value ?? ""}
                                              name={field.name}
                                              onValueChange={(values) => {
                                                field.onChange(values.floatValue);
                                                if (values.floatValue) {
                                                  setVariables((prevValue) => ({
                                                    ...prevValue,
                                                    balanceRange: { to: values.floatValue }
                                                  }));
                                                  addSearchParam("maxBalance", values.floatValue.toString());
                                                } else {
                                                  setVariables((prevValue) => {
                                                    const { balanceRange, ...rest } = prevValue;
                                                    return {
                                                      ...rest
                                                    };
                                                  });
                                                  deleteSearchParams("maxBalance");
                                                }
                                              }}
                                              className="w-full"
                                            />
                                          );
                                        }}
                                      />
                                    ),
                                    onlyMobile: true
                                  },
                                  {
                                    component: (
                                      <SectionAutocomplete
                                        params={params}
                                        addSearchParam={addSearchParam}
                                        deleteSearchParams={deleteSearchParams}
                                      />
                                    ),
                                    onlyMobile: true
                                  },
                                  {
                                    component: (
                                      <Controller
                                        name="memberClassifications"
                                        control={control}
                                        render={() => (
                                          <MultiSelect
                                            label="Classification"
                                            selectedValues={selectedClassificationValues}
                                            options={classificationDataNodes}
                                            onSelect={(value, checked) => {
                                              const status = checked
                                                ? [...selectedClassificationValues, value]
                                                : selectedClassificationValues.filter((v) => v !== value);
                                              setSelectedClassificationValues(reject(status, isEmpty));
                                              setVariables((prevValue) => ({
                                                ...prevValue,
                                                memberClassifications: reject(status, isEmpty)
                                              }));
                                              addSearchParam(
                                                "memberClassifications[]",
                                                reject(status, isEmpty).toString()
                                              );
                                            }}
                                            onClear={() => {
                                              setSelectedClassificationValues([]);
                                              setVariables((prevValue) => ({
                                                ...prevValue,
                                                memberClassifications: []
                                              }));
                                              deleteSearchParams("memberClassifications[]");
                                            }}
                                            className="w-full"
                                          />
                                        )}
                                      />
                                    )
                                  },
                                  {
                                    component: (
                                      <Controller
                                        name="paymentStatuses"
                                        control={control}
                                        render={() => (
                                          <MultiSelect
                                            label="Status"
                                            selectedValues={selectedValues}
                                            options={[
                                              { value: "HAS_FAILED_PAYMENTS", label: "Has Failed Payment" },
                                              { value: "HAS_REFUNDED_PAYMENTS", label: "Has Refunded Payment" }
                                            ]}
                                            onSelect={(value, checked) => {
                                              const status = checked
                                                ? [...selectedValues, value]
                                                : selectedValues.filter((v) => v !== value);

                                              setSelectedValues(reject(status, isEmpty));
                                              setVariables((prevValue) => ({
                                                ...prevValue,
                                                paymentStatuses: reject(status, isEmpty)
                                              }));
                                              addSearchParam("paymentStatuses[]", reject(status, isEmpty).toString());
                                            }}
                                            onClear={() => {
                                              setSelectedValues([]);
                                              setVariables((prevValue) => ({
                                                ...prevValue,
                                                paymentStatuses: []
                                              }));
                                              deleteSearchParams("paymentStatuses[]");
                                            }}
                                            className="w-full"
                                          />
                                        )}
                                      />
                                    )
                                  },
                                  {
                                    component: (
                                      <Controller
                                        name="dueDateRange"
                                        control={control}
                                        render={({ field }) => {
                                          return (
                                            <DateSelect
                                              placeholder="Select a Date"
                                              label="Due Date On or Before"
                                              value={field.value}
                                              useSingleDate
                                              onChange={(e) => {
                                                field.onChange(e);
                                                if (typeof e === "object" && e !== null) {
                                                  setVariables((prevValue) => ({
                                                    ...prevValue,
                                                    dueDateRange: { from: null, to: e.endDate }
                                                  }));
                                                  addSearchParam("dueDateRange", e.endDate?.toString() || "");
                                                }
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )
                                  },
                                  {
                                    component: (
                                      <FacilityAutocomplete
                                        sectionIds={variables?.sectionIds}
                                        params={params}
                                        addSearchParam={addSearchParam}
                                        deleteSearchParams={deleteSearchParams}
                                      />
                                    )
                                  },
                                  {
                                    component: <>{isActive && <ClearAllButton />}</>,
                                    onlyMobile: true
                                  }
                                ]}
                              />
                              {isActive && (
                                <div className="hidden sm:block" data-testid="clear-all-button">
                                  <ClearAllButton />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </FormProvider>

                      {rowSelected > 0 && hasPermission(PERMISSION.manageOrders) && (
                        <div className="flex items-center gap-3 flex-wrap sm:flex-nowrap mt-3 sm:mt-0 ml-0 sm:ml-3">
                          {availableForSuspensions ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setSelectedOrders(rowSelection);
                                setSuspendAll(false);
                                setOpenSuspendModal(true);
                              }}
                            >
                              Suspend Selected
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setSelectedOrders(rowSelection);
                                setTerminateAll(false);
                                setOpenTerminateModal(true);
                              }}
                            >
                              Terminate Selected
                            </Button>
                          )}
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedOrders(rowSelection);
                              setOpenChangeDueDateModal(true);
                            }}
                          >
                            Change Due Date
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="hidden md:block">
                      {!isSelectedOnly &&
                        renderFooter(data?.currentDuesCycle?.orders, {
                          totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                          pageCount: orderData.length,
                          variant: "compact"
                        })()}
                    </div>
                  </div>

                  {rowSelected > 0 && (
                    <div className="flex items-center gap-3 mt-2 md:mt-3 flex-wrap md:flex-nowrap">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                      <Button
                        variant="text"
                        onClick={() => {
                          setRowSelection({});
                          setIsSelectedOnly(false);
                          resetPagination();
                        }}
                      >
                        Deselect All {rowSelected} {pluralize(rowSelected, "Row")}
                      </Button>
                    </div>
                  )}
                  <div className="block mt-3 md:hidden">
                    {!isSelectedOnly &&
                      renderFooter(data?.currentDuesCycle?.orders, {
                        totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                        pageCount: orderData.length,
                        variant: "compact"
                      })()}
                  </div>
                </div>
              )}
              columns={[
                {
                  header: ({ table }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Checkbox
                            name="select"
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={getIsSomeRowsSelected()}
                            onChange={(checked) => {
                              table.getToggleAllRowsSelectedHandler()({
                                target: { checked: getIsSomeRowsSelected() ? true : checked }
                              });
                              setIsSelectedOnly(false);
                            }}
                          />
                        </div>
                        <span className="text-label-large">Name</span>
                      </div>
                    );
                  },
                  id: "fullName",
                  cell: ({
                    row,
                    row: {
                      original: { person }
                    }
                  }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4" onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            name="select"
                            disabled={!row.getCanSelect()}
                            onChange={(checked) => {
                              row.getToggleSelectedHandler()({ target: { checked } });
                            }}
                            checked={row.getIsSelected()}
                          />
                        </div>
                        <div className="flex items-center py-2">
                          <AvatarDetails
                            picture={person.profilePhoto}
                            title={`${person.firstName} ${person.lastName}`}
                            text={person.mainProgramType?.name || ""}
                          />
                        </div>
                      </div>
                    );
                  },
                  meta: {
                    className: getStickyColumnClasses(!!orderData?.length)
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
                { accessorKey: "classification", header: "Classification", enableSorting: false },
                { accessorKey: "section", header: "Section", enableSorting: false },
                {
                  accessorKey: "products",
                  header: "Products",
                  enableSorting: false
                },
                { accessorKey: "updatedAt", header: "Modified", enableSorting: false },
                { accessorKey: "dueDate", header: "Due Date", enableSorting: false },
                { accessorKey: "balance", header: "Balance", enableSorting: false },
                {
                  header: "Stage",
                  id: "stage",
                  cell: ({ row: { original } }) => renderStatus(original.stage, original.paymentStatus),
                  size: 100
                },
                {
                  id: "actions",
                  cell({ row: { id: rowId, original } }) {
                    const items = [
                      {
                        label: "Change Due Date",
                        onClick() {
                          setSelectedOrders({ [`${original.id}`]: true });
                          setOpenChangeDueDateModal(true);
                        }
                      }
                    ];

                    if (!items.length) return null;

                    function onClick() {
                      setMenu({ rowId, items });
                    }

                    return (
                      <div
                        className="flex flex-row justify-end"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <IconButton
                          id={actionId(rowId)}
                          onClick={onClick}
                          data-testid="actions"
                          title="actions"
                          name="more_vert"
                          iconClassName="font-extrabold"
                        />
                      </div>
                    );
                  }
                }
              ]}
              renderEmptyState={() => (
                <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="person" />
              )}
              renderFooter={() => (
                <div
                  className={`${
                    rowSelected > 0 ? "justify-between" : "justify-end"
                  } flex flex-col md:flex-row md:items-center`}
                >
                  {rowSelected > 0 && (
                    <div className="flex items-center gap-2 mb-3 md:mb-0">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                    </div>
                  )}
                  {!isSelectedOnly &&
                    renderFooter(data?.currentDuesCycle?.orders, {
                      totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                      pageCount: orderData.length
                    })()}
                </div>
              )}
            />
          </div>
        </>
      )}
      {openChangeDueDateModal && (
        <ChangeDueDateModal
          onDismiss={() => {
            setOpenChangeDueDateModal(false);
          }}
          orders={selectedOrders}
          refetch={refetch}
          setRowSelection={setRowSelection}
          setStats={setStats}
          resetPagination={resetPagination}
        />
      )}
      {openTerminateModal && (
        <TerminateModal
          onDismiss={() => {
            setOpenTerminateModal(false);
          }}
          orders={selectedOrders}
          refetch={refetch}
          setRowSelection={setRowSelection}
          terminateAll={terminateAll}
          totalPeople={stats.totalCount}
          setStats={setStats}
          resetPagination={resetPagination}
        />
      )}
      {openSuspendModal && (
        <SuspendModal
          onDismiss={() => {
            setOpenSuspendModal(false);
          }}
          orders={selectedOrders}
          refetch={refetch}
          setRowSelection={setRowSelection}
          suspendAll={suspendAll}
          totalPeople={stats.totalCount}
          setStats={setStats}
          resetPagination={resetPagination}
        />
      )}
    </>
  );
};

export default OrdersTable;
