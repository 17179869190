import { PropsWithChildren } from "react";
import MenuTooltip, { MenuProps } from "./MenuTooltip";

type Actions = {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
};

export type TooltipProps = Pick<MenuProps, "positioning" | "menuCorner" | "anchorCorner"> & {
  title?: string;
  supportingText: string;
  actions?: Actions;
  variant?: "rich" | "plain";
  color?: "white" | "black";
  anchorClassName?: string;
};

const Content = ({ title, supportingText, actions, variant }: TooltipProps) => (
  <div className="text-left">
    {!!title && <p className="text-title-small">{title}</p>}
    <p className={`text-body-medium ${variant === "rich" ? "mt-1" : "mt-0"}`}>{supportingText}</p>

    {!!actions && (
      <div className="mt-4">
        {actions.primary} {actions.secondary}
      </div>
    )}
  </div>
);

export default function Tooltip(props: PropsWithChildren<TooltipProps>) {
  const {
    title,
    supportingText,
    actions,
    children,
    variant = "rich",
    color = "white",
    anchorClassName,
    ...rest
  } = props;
  return (
    <MenuTooltip
      AnchorComponent={children}
      content={<Content title={title} supportingText={supportingText} actions={actions} variant={variant} />}
      variant={variant}
      color={color}
      anchorClassName={anchorClassName}
      {...rest}
    />
  );
}
