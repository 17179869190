import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { showSnackbar } from "@/lib/snackbarUtils";
import { useNavigate } from "@/router";
import MultiStepForm from "@/components/MultiStepForm";
import { DetailsStep } from "../videoDetails";
import { UPDATE_MEMBERSHIP_VIDEO_MUTATION } from "./queries";
import Skeleton from "@/components/Skeleton";
import { LinkProps } from "@generouted/react-router/client";
import { Params, Path } from "@/router";
import { useEffect } from "react";
import AssessmentDetails from "../assessmentDetails";
import { useFlags } from "@/hooks/useFeatureFlags";
import useCapabilities, { PERMISSION } from "@/hooks/useCapabilities";
import { ShowVideoPage } from "./show";
import Tag from "@/components/Tag";
import ObjectHeading from "@/components/ObjectHeading";
import { useReturnTo } from "@/hooks/useSearchParams";
import PageTitle from "@/components/PageTitle";

export default function EditVideoPage() {
  const location = useLocation();
  const videoFromState = location.state?.video;

  const video = videoFromState;

  const navigate = useNavigate();

  const returnTo = useReturnTo();
  const homePath: LinkProps<Path, Params> = { to: "/education/videos" };
  const backLink = {
    to: returnTo ? returnTo : homePath.to,
    title: "Cancel"
  } as unknown as LinkProps<Path, Params>;

  const flags = useFlags();
  const flagAddVideo = flags["backoffice-pdr-videos"];

  const initialValues = {
    id: video?.id,
    title: video?.title,
    description: video?.description || "",
    url: video?.url,
    series: video?.series,
    creditCategory: video?.creditCategory.toLowerCase(),
    publishedAt: {
      startDate: new Date(video?.publishedAt),
      endDate: new Date(video?.publishedAt)
    },
    expiredAt: {
      startDate: new Date(video?.expiredAt),
      endDate: new Date(video?.expiredAt)
    },
    durationInSeconds: video?.durationInSeconds,
    assessment: {
      id: video?.assessment?.id,
      questions:
        video?.assessment?.questions.map((question: any) => ({
          id: question.id,
          content: question.content,
          position: question.position,
          options: question.options.map((option: any) => ({
            id: option.id,
            content: option.content,
            answer: option.answer,
            position: option.position
          }))
        })) || {}
    }
  };

  const form = useForm({
    defaultValues: initialValues
  });

  const [updateMembershipVideo] = useMutation(UPDATE_MEMBERSHIP_VIDEO_MUTATION);

  useEffect(() => {
    if (!video || !flagAddVideo) {
      navigate(homePath.to);
    }
  }, [video, flagAddVideo, homePath.to, navigate]);

  async function handleSubmit(formData: any) {
    const isValid = await form.trigger();
    if (isValid) {
      try {
        const input = {
          id: video.id,
          ...formData,
          publishedAt: formData.publishedAt.startDate,
          expiredAt: formData.expiredAt?.startDate,
          assessment: {
            id: formData.assessment.id,
            questions: formData.assessment.questions.map((question: any, index: number) => ({
              id: question.id,
              content: question.content,
              position: index + 1,
              options: question.options.map((option: any, optionIndex: number) => ({
                id: option.id,
                content: option.content,
                answer: option.answer,
                position: optionIndex + 1
              }))
            }))
          }
        };
        const response = await updateMembershipVideo({ variables: { input } });

        if (response.data.updateMembershipVideo.success) {
          navigate(homePath.to);
          showSnackbar("Video Updated Successfully");
        } else {
          showSnackbar(response.data.updateMembershipVideo.message);
        }
      } catch (e) {
        showSnackbar("Error updating video");
      }
    }
  }

  const { hasPermission } = useCapabilities();
  const now = new Date();
  const expiredAt = form.watch("expiredAt").startDate;
  const readOnly = !hasPermission(PERMISSION.manageVideos) || (expiredAt && now >= expiredAt);

  const tag =
    form.watch("expiredAt") && form.watch("expiredAt").startDate < now ? (
      <Tag color="secondary">Expired</Tag>
    ) : form.watch("publishedAt") && form.watch("publishedAt").startDate > now ? (
      <Tag color="tertiaryDim">Upcoming</Tag>
    ) : (
      <Tag color="primary">Active</Tag>
    );

  const steps = [
    {
      headline: "Details",
      supportingText: "Step 1",
      content: form ? <DetailsStep form={form} /> : <Skeleton rows={7} />
    },
    {
      headline: "Assessment",
      supportingText: "Step 2",
      content: <AssessmentDetails form={form} />
    }
  ];

  if (!flagAddVideo) {
    return null;
  }

  if (readOnly) {
    return <ShowVideoPage form={form} />;
  }

  return (
    <>
      <PageTitle
        pattern="/education/videos/:videoSlug"
        title={`Education | Videos | Video Details | ${form.watch("title")}`}
      />
      <MultiStepForm
        form={form}
        steps={steps}
        renderStepper={true}
        onSubmit={handleSubmit}
        backLink={backLink}
        modelName="Video"
        submitText="Publish Changes"
        isEditing={true}
        enableSubmitOnLastStep={true}
        fullWidth={true}
        objectHeading={<ObjectHeading title={video?.title} subText={`Credits: 1`} iconName="assignment" />}
        tag={tag}
      />
    </>
  );
}
