import { gql } from "@apollo/client";

export const ORDER_QUERY = gql`
  query commerceOrder($orderId: ID!) {
    commerceOrder(id: $orderId) {
      id
      stage
      paymentStatus
      balance
      payable
      totalPaid
      totalRefunded
      lineItemsTotal
      dueDate
      updatedAt
      pdfAvailable
      description
      orderPayments {
        id
        amountCents
        ledgerEntries {
          lineItem {
            id
            priceCents
          }
        }
        payment {
          id
          amountCents
          checkNumber
          type
          status
          source
          paidAt
          payer {
            id
            name
          }
        }
      }
      person {
        id
        firstName
        lastName
        profilePhoto
        pgaId
        email
        mainProgramType {
          id
          name
        }
        contactAddress {
          id
          address1
          address2
          city
          state
          postalCode
          countryCode
          addressType
        }
      }
      lineItems(includeRemoved: true) {
        id
        name
        priceCents
        notes
        quantity
        displayOrder
        productVariantId
        removedAt
        ledgerEntries {
          createdAt
          type
          amountCents
        }
        proration {
          originalPrice
          proratedNumberOfMonths
          proratedPrice
        }
        utilization {
          creditForwardedAmount
          lineItemId
          name
          originalPrice
          utilizedEnd
          utilizedNumberOfMonths
          utilizedStart
        }
        refundablePayments {
          paymentId
          amountCents
        }
      }
    }
  }
`;

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelCommerceOrder($input: CommerceCancelCommerceOrderInput!) {
    cancelCommerceOrder(input: $input) {
      order {
        id
        stage
      }
      message
      success
    }
  }
`;

export const OPEN_ORDER_MUTATION = gql`
  mutation OpenCommerceOrder($input: CommerceOpenCommerceOrderInput!) {
    openCommerceOrder(input: $input) {
      order {
        id
        stage
        dueDate
      }
      message
      success
    }
  }
`;

export const CHANGE_DUE_DATE_MUTATION = gql`
  mutation ChangeDueDate($input: CommerceChangeOrderDueDateInput!) {
    commerceChangeOrderDueDate(input: $input) {
      order {
        id
        status
        dueDate
      }
      message
      success
    }
  }
`;

export const PDF_QUERY = gql`
  query commerceOrder($orderId: ID!) {
    commerceOrder(id: $orderId) {
      id
      pdfUrl
    }
  }
`;

export const CREATE_PAYMENT_REFUND_MUTATION = gql`
  mutation CreatePaymentRefund($input: CommerceCreatePaymentRefundInput!) {
    commerceCreatePaymentRefund(input: $input) {
      refund {
        id
        status
      }
      message
      success
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($input: BackofficeCommerceProductInput!) {
    commerceProducts(input: $input) {
      nodes {
        id
        name
        description
      }
    }
  }
`;
export const GET_PRODUCT_VARIANTS = gql`
  query GetProductVariants($productId: String!, $search: String) {
    commerceProductVariants(input: { productId: $productId, search: $search }) {
      totalCount
      nodes {
        id
        name
        priceCents
        changeablePrice
      }
    }
  }
`;
