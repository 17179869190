import SegmentedButton from "@/components/SegmentedButton/SegmentedButton";

interface AttendanceCellProps {
  id: string;
  attended: boolean | null;
  onAttendanceUpdate: (id: string, value: string | null) => void;
}

export const AttendanceCell = ({ id, attended, onAttendanceUpdate }: AttendanceCellProps) => {
  const handleAttendanceChange = (value: string | null) => {
    const currentValue = attended === null ? null : attended ? "true" : "false";
    if (value === currentValue) {
      onAttendanceUpdate(id, null);
    } else {
      onAttendanceUpdate(id, value);
    }
  };

  return (
    <SegmentedButton
      showCheckmark
      allowDeselect
      options={[
        { label: "Present", value: "true" },
        { label: "Absent", value: "false" }
      ]}
      value={attended === null ? null : attended ? "true" : "false"}
      onChange={handleAttendanceChange}
    />
  );
};
