import { ReactNode } from "react";
import Icon from "../Icon";
import type { MaterialNames } from "../material_symbol_names";

export type TagColor = "primary" | "secondary" | "tertiary" | "tertiaryDim" | "surface" | "error" | "outline";
type TagSize = "small" | "medium" | "large";

interface TagProps {
  color?: TagColor;
  size?: TagSize;
  children: ReactNode;
  testId?: string;
  iconName?: MaterialNames;
  iconClass?: string;
}

const colorClasses = {
  primary:
    "bg-sys-brand-primary-container-bright text-sys-brand-on-primary-container border-sys-brand-primary-container-bright",
  secondary:
    "bg-sys-brand-secondary-container text-sys-brand-on-secondary-container border-sys-brand-secondary-container",
  tertiaryDim: "bg-sys-brand-tertiary-fixed-dim text-sys-brand-on-tertiary-fixed border-sys-brand-tertiary-fixed-dim",
  tertiary: "bg-sys-brand-tertiary text-white border-sys-brand-tertiary",
  surface: "bg-sys-brand-surface-dim text-sys-brand-on-surface border-sys-brand-surface-dim",
  error: "bg-sys-brand-error-container text-sys-brand-on-error-container border-sys-brand-error-container",
  outline: "border-sys-brand-outline"
};

const sizeClasses = {
  small: "text-label-small py-[2px] px-[6px]",
  medium: "text-label-medium py-[4px] px-[8px]",
  large: "text-label-large py-[6px] px-[10px]"
};

export default function Tag({ color = "primary", size = "medium", iconName, iconClass, children, testId }: TagProps) {
  return (
    <div
      data-testid={testId}
      className={`inline-flex rounded-lg justify-center items-center whitespace-nowrap border ${colorClasses[color]} ${sizeClasses[size]}`}
    >
      {iconName && <Icon name={iconName} size={16} className={`mr-1 ${iconClass}`} />}
      {children}
    </div>
  );
}
