import { useAuth0 } from "@auth0/auth0-react";

export default function useCurrentUser() {
  const { user, logout } = useAuth0();

  if (!user) throw new Error("User not available");

  return {
    user,
    logout: () => logout({ logoutParams: { returnTo: window.location.origin } })
  };
}
