import type { BackofficeUser } from "./types";
import { fetchActiveFlagKeys, fetchEvaluateFlags } from "./api";
import { FlagRecord } from "./flags";

const initializeFeatures = async (me: BackofficeUser): Promise<FlagRecord | undefined> => {
  try {
    const activeFlagKeys = await fetchActiveFlagKeys();
    if (activeFlagKeys.length > 0) {
      const flagValues = await fetchEvaluateFlags(activeFlagKeys, me);
      return flagValues;
    }
  } catch (error) {
    console.error("Failed to fetch feature flags", error);
  }
};

export default initializeFeatures;
