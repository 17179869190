import { ColumnDef, CellContext } from "@tanstack/react-table";
import { SessionRegistrationRow } from "../types";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import Checkbox from "@/components/Checkbox";
import { AttendanceCell } from "./AttendanceCell";

interface GetColumnsProps {
  hasRegistrations: boolean;
  onAttendanceUpdate?: (id: string, value: string | null) => void;
  updateEventSessionsRegistrationAttendances: (options: any) => void;
  setIsSelectedOnly: (value: boolean) => void;
}

export const getColumns = ({
  hasRegistrations,
  onAttendanceUpdate,
  setIsSelectedOnly
}: GetColumnsProps): ColumnDef<SessionRegistrationRow, any>[] => [
  {
    id: "select",
    header: ({ table }) => {
      return (
        <div className="flex items-center">
          <div className="mr-4" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              name="select"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={(checked) => {
                table.getToggleAllRowsSelectedHandler()({
                  target: { checked: table.getIsSomeRowsSelected() ? true : checked }
                });
                if (!checked) {
                  setIsSelectedOnly(false);
                }
              }}
            />
          </div>
          <span className="text-label-large">Name</span>
        </div>
      );
    },
    cell: ({
      row,
      row: {
        original: {
          registration: { person }
        }
      }
    }) => {
      return (
        <div className="flex items-center">
          <div className="mr-4" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              name="select"
              disabled={!row.getCanSelect()}
              onChange={(checked) => {
                row.getToggleSelectedHandler()({ target: { checked } });
              }}
              checked={row.getIsSelected()}
            />
          </div>
          <div className="flex items-center py-2">
            <AvatarDetails
              picture={person.profilePhoto}
              title={`${person.displayName}`}
              text={person.mainProgramType?.name || ""}
            />
          </div>
        </div>
      );
    },
    enableSorting: true,
    accessorFn: (row: SessionRegistrationRow) => row.registration.person.displayName,
    meta: {
      className: getStickyColumnClasses(hasRegistrations)
    }
  },
  {
    id: "pgaId",
    header: "PGA ID",
    enableSorting: false,
    accessorFn: (row: SessionRegistrationRow) => row.registration.person.pgaId
  },
  {
    id: "attendance",
    header: "Attendance",
    enableSorting: false,
    cell: (cellProps: CellContext<SessionRegistrationRow, unknown>) => (
      <AttendanceCell
        id={cellProps.row.original.id}
        attended={cellProps.row.original.attended}
        onAttendanceUpdate={onAttendanceUpdate!}
      />
    ),
    size: 300
  }
];
