import Chip, { ChipSet } from "@/components/Chip";
import { getFieldLabel } from "../_utils";
import usePageTitle from "@/hooks/usePageTitle";
import PeopleTable, { Props as PeopleTableProps } from "../_components/PeopleTable";
import usePeopleQuery from "../_hooks/usePeopleQuery";
import { useQuickSearchParams, Query } from "../_hooks/useQuickSearch";
import { useQuery } from "@apollo/client";
import { QUICKSEARCH_OPTIONS_QUERY, PeopleQueryInput } from "../queries";
import { Params, Path, useNavigate } from "@/router";
import { useEffect } from "react";
import AppLayout, { PageHeading, SideBar, useTopActionBar } from "@/layouts/AppLayout";
import { capitalize } from "lodash";
import { LinkProps } from "@generouted/react-router/client";
import useActionMenu from "@/hooks/useActionMenu";

const path = "/people/search";
const pageTitle = "Search Results";

const arrayToValue = (values: string[]) => values.sort().join(", ");

function Chips({ query, onClick }: { query: Partial<Query>; onClick(k: keyof Query): void }) {
  const { data } = useQuery(QUICKSEARCH_OPTIONS_QUERY, {
    fetchPolicy: "cache-first",
    skip: !query.sectionIds?.length
  });

  function getChipValue(key: keyof Query) {
    switch (key) {
      case "sectionIds":
        const names = (data?.crmSections.nodes ?? []).reduce<Record<string, string>>(
          (acc, { id, name }) => ({ ...acc, [id]: name }),
          {}
        );
        return arrayToValue((query.sectionIds ?? []).map((id) => names[id]));

      case "programTypes":
      case "statuses":
        return arrayToValue((query[key] ?? []).map(capitalize));
      case "memberClassifications":
        return arrayToValue(query[key] ?? []);

      default:
        return query[key];
    }
  }

  const toChip = (key: keyof Query) => {
    function click() {
      onClick(key);
    }

    function remove(e: Event) {
      e.preventDefault();
      onClick(key);
    }

    return (
      <Chip
        key={key}
        label={`${getFieldLabel(key)}: ${getChipValue(key)}`}
        removable
        onClick={click}
        onRemove={remove}
      />
    );
  };

  const chips = [];
  let key: keyof Query;

  for (key in query) chips.push(toChip(key));

  return (
    <ChipSet title="filters" className="mb-6 gap-4">
      {chips}
    </ChipSet>
  );
}

function sanitize({ pgaId, phone, ...data }: Partial<Query>): PeopleQueryInput {
  let query: PeopleQueryInput = data;

  if (phone) query.phone = phone.replace(/\D/g, "");
  if (pgaId) query.pgaIds = [pgaId];
  return query;
}

function Table(props: PeopleTableProps) {
  const { showSidebar } = useTopActionBar();

  return (
    <PeopleTable
      {...props}
      emptyAction={{
        children: "Edit Search",
        onClick: showSidebar(SideBar.QUICK_SEARCH)
      }}
    />
  );
}

export default function SearchResults() {
  const { params: query, clearParam } = useQuickSearchParams();
  const navigate = useNavigate();
  const { data, ...tableProps } = usePeopleQuery(sanitize(query));

  useEffect(() => {
    if (data?.people.totalCount === 1) {
      const pgaId = data.people.nodes[0].pgaId!;
      navigate("/people/:pgaId", { params: { pgaId }, replace: true });
    }
  }, [navigate, data]);

  usePageTitle(path, pageTitle);
  const { Menu, setMenu } = useActionMenu();

  const returnTo = new URLSearchParams(location.search).get("return_to");

  const backLink = returnTo
    ? ({ to: returnTo } as LinkProps<Path, Params>)
    : {
        to: "/people" as const
      };

  const hasQuery = Object.keys(query).length > 0;

  return (
    <AppLayout>
      <div className="pl-4">
        <PageHeading title={pageTitle} backLink={backLink} />

        <h2 className="mt-10 mb-4 text-title-medium">
          Showing {data?.people.totalCount ?? 0} results {hasQuery && "for:"}
        </h2>

        {hasQuery && <Chips query={query} onClick={clearParam} />}
      </div>
      <Menu />
      <Table {...tableProps} data={data} setMenu={setMenu} />
    </AppLayout>
  );
}
