import { ListItemProps } from "@/components/ListItem/ListItem";
import Tooltip from "@/components/Tooltip";
import { formattedAddress } from "@/lib/addressHelpers";
import { isInFuture, toFormat, toUTCFormat } from "@/lib/dateHelpers";
import {
  Address,
  Employment,
  Person,
  PhoneNumber,
  PlayingAbilityTest,
  ProgramStatus as ProgramStatusType,
  ProgramTypeName
} from "@/types";
import { capitalize, orderBy } from "lodash";
import { DateTime } from "luxon";

export function phoneOptions(phoneNumbers: PhoneNumber[] | undefined): ListItemProps[] {
  if (!phoneNumbers) return [];
  return phoneNumbers.map((phoneNumber) => ({
    iconName: "call",
    headline: phoneNumber.phoneFormatted,
    overline: capitalize(phoneNumber.phoneType)
  }));
}

export function emailOptions(email: string | undefined): ListItemProps[] {
  if (!email) return [];
  return [{ iconName: "mail", headline: email }];
}

export function addressOptions(addresses: Address[] | undefined, contactAddressId?: string): ListItemProps[] {
  // "Home" address type should be displayed as "Primary"
  const formatAddressType = (addressType: string) => {
    return addressType === "home" ? "primary" : addressType;
  };

  if (!addresses) return [];
  return addresses.map((address) => ({
    iconName: "home",
    overline: capitalize(formatAddressType(address.addressType)),
    headline: formattedAddress(address),
    ...contactPreferenceText(address, contactAddressId)
  }));
}

export function facilityOptions(employments: Employment[], contactAddressId?: string): ListItemProps[] {
  const options = employments
    .filter((e) => !e.endedOn)
    .map((employment) => {
      const { facility } = employment;
      const addresses = facility.addresses || [];
      const address = addresses.find((a) => a.addressType === "main") || addresses[0];
      const addressText = address ? formattedAddress(address) : "No address available";
      const phoneText = facility.phone?.phoneFormatted;

      return {
        iconName: "location_on",
        overline: employment.isPrimary ? "Primary" : "Secondary",
        headline: facility.name,
        ...contactPreferenceText(address, contactAddressId),
        supportingText: [addressText, phoneText].filter(Boolean).join("\n")
      } as ListItemProps;
    });

  // Primary facility should be displayed first
  return options.sort((a, b) => {
    if (a.overline === "Primary") return -1;
    if (b.overline === "Primary") return 1;
    return 0;
  });
}

export function contactPreferenceText(address?: Address, contactAddressId?: string) {
  if (address && address.id === contactAddressId) {
    return { trailingSupportingText: "Contact Preference" };
  } else {
    return {};
  }
}

export function personalOptions(person: Person): ListItemProps[] {
  let details: ListItemProps[] = [];

  details.push({
    iconName: "cake",
    overline: "Date of Birth",
    headline: dobHeadline(person)
  });

  details.push({
    iconName: "person",
    overline: "Age",
    headline: ageHeadline(person)
  });

  details.push({
    iconName: "badge",
    overline: "Social Security Number",
    headline: ssnHeadline(person)
  });

  if (!!person.gender) {
    let genderString;

    if (person.gender === "F") {
      genderString = "Female";
    } else if (person.gender === "M") {
      genderString = "Male";
    } else if (person.gender === "O") {
      genderString = "Other";
    } else {
      genderString = "Not specified";
    }

    details.push({
      iconName: "person",
      overline: "Gender Identity",
      headline: genderString
    });
  }

  if (!!person.informalName) {
    details.push({
      iconName: "person",
      overline: "Preferred First Name",
      headline: person.informalName!
    });
  }

  // TODO: Add "Spouse Full Name"
  return details;
}

const fullName = (person: Person): string => `${person.firstName} ${person.lastName}`;

const ssnHeadline = (person: Person) => {
  if (!person.ssn) return null;

  const ssnNumber = person.ssn.full || person.ssn.last4;

  return ssnNumber ? (
    <Tooltip
      variant="plain"
      color="black"
      supportingText={maskedSSN(ssnNumber)}
      menuCorner="end-start"
      anchorCorner="start-start"
    >
      {maskedSSN()}
    </Tooltip>
  ) : (
    maskedSSN()
  );
};

const maskedSSN = (ssn?: string) => {
  ssn = (ssn || "").padStart(9, "•");
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
};

const ageHeadline = (person: Person) => {
  if (!person.dob) return null;
  return person.age ? String(person.age) : "••";
};

const dobHeadline = (person: Person) => {
  if (!person.dob) return null;

  const formattedDate = formatDob(person);
  const masked = "••/••/••••";

  return formattedDate ? (
    <Tooltip
      variant="plain"
      color="black"
      supportingText={formattedDate}
      menuCorner="end-start"
      anchorCorner="start-start"
    >
      {masked}
    </Tooltip>
  ) : (
    masked
  );
};

function formatDob(person: Person) {
  const { day, month, year } = person.dob || {};
  if (!day) return null;

  const date = DateTime.fromObject({ day, month, year });
  if (year) {
    return date.toFormat("MMM d, yyyy");
  } else {
    return date.toFormat("MMM d");
  }
}

export function officialDetailsOptions(person: Person): ListItemProps[] {
  const { name: classificationName, description: classificationDesc } =
    person.latestProgram?.band?.classification || {};
  const status = person.latestProgram?.status;

  return [
    {
      iconName: "person",
      overline: "Full Name",
      headline: fullName(person)
    },
    {
      iconName: "person",
      overline: "Name Suffix",
      headline: person.suffixName
    },
    {
      iconName: "badge",
      overline: "PGA ID",
      headline: person.pgaId!
    },
    {
      iconName: "badge",
      overline: "Classification",
      headline: classificationName,
      supportingText: classificationDesc
    },
    {
      iconName: "badge",
      overline: "Status",
      headline: capitalize(status)
    }
  ];
}

export function sectionOptions(person: Person): ListItemProps[] {
  const date = person.primarySectionAffiliation?.startAt;
  const trailingSupportingText = date ? `Since ${toFormat(date)}` : null;
  const supportingText =
    person.employments.find((e) => e.isPrimary)?.facility?.section?.id === person.primarySection?.id
      ? "Primary Employment"
      : "Primary Address";

  return [
    {
      iconName: "domain",
      overline: "Primary Section",
      headline: person.primarySection?.name,
      supportingText,
      trailingSupportingText
    }
  ];
}

export function committeesOptions(person: Person): ListItemProps[] {
  if (![ProgramTypeName.Member, ProgramTypeName.Associate].includes(person.latestProgram?.programType.name!)) {
    return [];
  }

  return [
    {
      iconName: "groups",
      overline: "Active Committees",
      headline: person.committeeHistory
        ? String(person.committeeHistory.filter((c) => !c.endDate || isInFuture(c.endDate)).length)
        : undefined
    }
  ];
}

export function qualifyingLevelData(person: Person) {
  const rightProgram = [ProgramTypeName.Affiliate, ProgramTypeName.University, ProgramTypeName.Prospect].includes(
    person.latestProgram?.programType.name!
  );
  const { qualifyingLevel, membershipInterestForm } = person.prospectProgram || {};
  const showData = rightProgram && (!!membershipInterestForm || !!qualifyingLevel);
  if (!showData) return null;

  return {
    status: qualifyingLevel
      ? qualifyingLevel?.endDate
        ? "Complete"
        : formatStatus(qualifyingLevel.status)
      : "Not Started",
    expirationDate: qualifyingLevel?.expirationDate
  };
}

export function backgroundCheckData(person: Person) {
  const rightProgram = [ProgramTypeName.Prospect].includes(person.latestProgram?.programType.name!);
  const { backgroundCheck } = person.prospectProgram || {};
  const showData = rightProgram && backgroundCheck;
  if (!showData) return null;

  return {
    status: backgroundCheck?.status ? formatStatus(backgroundCheck.status) : "Not Started"
  };
}

export function playingAbilityTestData(person: Person) {
  const rightProgram = [ProgramTypeName.Prospect, ProgramTypeName.Affiliate].includes(
    person.latestProgram?.programType.name!
  );

  const { membershipInterestForm, playingAbilityTest } = person.prospectProgram || {};
  const pat = findMostRecentPAT(playingAbilityTest);
  const showData = rightProgram && (!!membershipInterestForm || !!pat);
  if (!showData) return null;

  return {
    status: pat ? formatStatus(pat.status) : "Not Started",
    date: pat?.startDate
  };
}

export function qualifyingLevelOptions(person: Person): ListItemProps[] {
  const qualifyingLevel = qualifyingLevelData(person);
  const pat = playingAbilityTestData(person);

  const options: ListItemProps[] = [];

  if (qualifyingLevel) {
    const supportingText = qualifyingLevel?.expirationDate
      ? `Expires ${toUTCFormat(qualifyingLevel.expirationDate)}`
      : undefined;

    options.push({
      iconName: "book",
      overline: "Qualifying Level Course",
      headline: qualifyingLevel.status,
      supportingText
    });
  }

  if (pat) {
    options.push({
      iconName: "golf_course",
      overline: "Playing Ability Test (PAT)",
      headline: pat.status,
      supportingText: pat.date && `${toUTCFormat(pat.date)}`
    });
  }

  return options;
}

export function membershipOptions(person: Person): ListItemProps[] {
  const program = person.latestProgram;
  if (!program) return [];

  const { band } = program;

  const supportingText = band
    ? `${toFormat(band.startedOn)} - ${
        program.status === ProgramStatusType.ACTIVE ? "Current" : toFormat(band.expiresOn)
      }`
    : undefined;

  return [
    {
      iconName: "badge",
      overline: band?.createdReason ? formatStatus(band.createdReason) : undefined,
      headline: band?.classification?.name,
      supportingText
    }
  ];
}

export function educationLearningOptions(person: Person): ListItemProps[] {
  const { educationLatestCertification: certification, latestProgram } = person;
  const programName = latestProgram?.programType.name;
  const expired = certification?.subscription?.expired;
  if (programName !== ProgramTypeName.Member || !certification || expired) return [];

  return [
    {
      iconName: "book",
      overline: certification.careerPath,
      headline: certification.programDescription,
      supportingText: `${toFormat(certification.startDate)} - ${
        certification.endDate ? toFormat(certification.endDate) : "Current"
      }`
    }
  ];
}

export function employmentOptions(person: Person): ListItemProps[] {
  const employment = person.employments.find((e) => e.isPrimary);
  if (!employment) return [];

  return [
    {
      iconName: "location_on",
      overline: employment.title,
      headline: employment.facility.name,
      supportingText: employment.facility.phone?.phoneFormatted,
      trailingSupportingText: employment.startedOn ? `Since ${toFormat(employment.startedOn)}` : null
    }
  ];
}

export const formatStatus = (status?: string, defaultStatus?: string) =>
  status
    ? status
        .split("_")
        .map((word) => capitalize(word.toLowerCase()))
        .join(" ")
    : defaultStatus;

const checkStatus = (status?: string, desiredStatus?: string) => status === desiredStatus;

export const findMostRecentPAT = (playingAbilityTest?: PlayingAbilityTest[]) => {
  const sortedPats = orderBy(playingAbilityTest, ["startDate"], ["desc"]);
  const passedPat = sortedPats.find((test) => checkStatus(test.status, "PASSED"));
  const qualifiedPat = sortedPats.find((test) => checkStatus(test.status, "QUALIFIED"));
  const newestPat = sortedPats[0];
  return passedPat || qualifiedPat || newestPat;
};
