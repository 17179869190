import react from "react";
import { createComponent } from "@lit/react";
import { MdMenu } from "@material/web/menu/menu";
import { MdMenuItem } from "@material/web/menu/menu-item";

export { MdMenu };

export const LitMenu = createComponent({
  tagName: "md-menu",
  elementClass: MdMenu,
  react,
  events: {
    onOpening: "opening",
    onOpened: "opened",
    onClosing: "closing",
    onClosed: "closed"
  }
});

export const LitMenuItem = createComponent({
  tagName: "md-menu-item",
  elementClass: MdMenuItem,
  react
});
