import TabLayout from "../_TabLayout";
import OrdersTable from "../_components/OrdersTable";
import usePageTitle from "@/hooks/usePageTitle";

export default function SuspensionsPage() {
  usePageTitle(
    "/billing/dues-management/suspensions-and-terminations/suspensions",
    "Billing | Dues Management | Suspensions and Terminations | Suspensions"
  );
  return (
    <TabLayout>
      <OrdersTable defaultVariables={{ availableForSuspensions: true }} />
    </TabLayout>
  );
}
