import * as amplitude from "@amplitude/analytics-browser";
import { useAuth0, User as Auth0User } from "@auth0/auth0-react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { PropsWithChildren, createContext, useEffect, useState } from "react";

interface BackofficeUser extends Auth0User {
  department: string | undefined;
  location: string | undefined;
  organization: string | undefined;
}

interface AccessTokenClaims extends JwtPayload {
  backoffice_sections: string[] | undefined;
  backoffice_roles: string[] | undefined;
}

export const AmplitudeContext = createContext(amplitude);

const sha256 = async (value: string) => {
  const sourceBytes = new TextEncoder().encode(value);
  const digest = await crypto.subtle.digest("SHA-256", sourceBytes);
  const resultBytes = [...new Uint8Array(digest)];
  return resultBytes.map((x) => x.toString(16).padStart(2, "0")).join("");
};

const AmplitudeProvider = ({ children }: PropsWithChildren<{}>) => {
  const apiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
  const [userIdentifyComplete, setUserIdentifyComplete] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0<BackofficeUser>();

  useEffect(() => {
    apiKey &&
      amplitude.init(apiKey, {
        autocapture: {
          attribution: true,
          pageViews: true,
          sessions: true,
          formInteractions: true,
          fileDownloads: true,
          elementInteractions: true
        }
      });
  }, [apiKey]);

  useEffect(() => {
    if (import.meta.env.MODE === "test") return;
    if (!user || userIdentifyComplete) return;

    const identifyAmplitudeUser = async () => {
      const userHash = await sha256(user?.sub ?? "");
      amplitude.setUserId(userHash);

      const accessToken = await getAccessTokenSilently();
      const accessTokenClaims = jwtDecode<AccessTokenClaims>(accessToken);

      const identify = new amplitude.Identify();
      identify.set("name", user.name || "");
      identify.set("first_name", user.given_name || "");
      identify.set("last_name", user.family_name || "");
      identify.set("email", user.email || "");
      if (user.department != null) identify.set("department", user.department);
      if (user.location != null) identify.set("location", user.location);
      if (user.organization != null) identify.set("organization", user.organization);
      identify.set("backoffice_sections", accessTokenClaims?.backoffice_sections || []);
      identify.set("backoffice_roles", accessTokenClaims?.backoffice_roles || []);
      amplitude.identify(identify);

      setUserIdentifyComplete(true);
    };

    identifyAmplitudeUser();
  }, [user, userIdentifyComplete, getAccessTokenSilently]);

  return <AmplitudeContext.Provider value={amplitude}>{children}</AmplitudeContext.Provider>;
};

export default AmplitudeProvider;
