import EmptyState from "@/components/EmptyState";
import Table, { TableDetails } from "@/components/Table";
import { useFlags } from "@/hooks/useFeatureFlags";
import { toFormat } from "@/lib/dateHelpers";
import { CommitteeAssignment, Person } from "@/types";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_COMMITTEE_HISTORY_QUERY } from "./queries";

type PersonData = { person: Person };

// TODO: get this from the backend
// For now, we'll add mappings for the values we know
const positionNameMap: Record<string, string> = {
  BOD: "Board of Directors",
  BRDLIASN: "Board Liaison",
  CHAIR: "Chairperson",
  COCHAIR: "Co-Chairperson",
  COUNSEL: "Outside Counsel",
  HNPRES: "Honorary President",
  INCBOD: "Incoming Board of Directors",
  INCDRCTR: "Incoming Director",
  INDV: "Member",
  OFFICER: "Officer",
  PLYER: "Player Director",
  STAFF: "Staff Liaison",
  VCHAIR: "Vice Chairman"
};

function formatTableData(list: CommitteeAssignment[]) {
  return list.map((e: CommitteeAssignment) => ({
    id: e.committeeName,
    committeeName: e.committeeName,
    position: positionNameMap[e.position] || e.position,
    startDate: toFormat(e.startDate),
    endDate: e.endDate && toFormat(e.endDate)
  }));
}

export default function CommitteeDetails() {
  const flags = useFlags();
  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_COMMITTEE_HISTORY_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!flags["backoffice-person-profile-committees"]) {
    return null;
  }

  const personData = data?.person;
  const committeeHistory = personData?.committeeHistory;
  const tableData = !!committeeHistory?.length ? formatTableData(committeeHistory) : [];

  return (
    <Table
      renderDetails={() => (
        <TableDetails
          heading="Committee Participation History"
          body="View committee participation history including positions and dates served below"
        />
      )}
      data={tableData}
      loading={loading}
      columns={[
        {
          header: "Committee Name",
          id: "committeeName",
          accessorKey: "committeeName",
          enableSorting: false
        },
        {
          header: "Position",
          id: "position",
          accessorKey: "position",
          enableSorting: false
        },
        {
          header: "Start Date",
          id: "startDate",
          accessorKey: "startDate",
          enableSorting: false
        },
        {
          header: "End Date",
          id: "endDate",
          accessorKey: "endDate",
          enableSorting: false
        }
      ]}
      renderEmptyState={() => (
        <EmptyState
          title="No Committee Participation"
          caption="This person does not have committee participation history"
          iconName="group"
        />
      )}
      data-testid="committee-history-table"
    />
  );
}
