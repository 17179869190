import { Outlet } from "react-router-dom";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import { useParams } from "@/router";

export default function SectionIdLayout() {
  const params = useParams("/sections/:sectionId/audit-log");
  return (
    <SinglePageLayout backLink={{ to: "/sections/:sectionId", params }}>
      <div className="max-w-[1440px] mx-auto">
        <Outlet />
      </div>
    </SinglePageLayout>
  );
}
