import { LitMenu, LitMenuItem, MdMenu } from "@/components/Menu/LitMenu";
import Icon from "@/components/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { actionId, Menu, MenuItem } from "./utils";
import Divider from "@/components/Divider";
import IconButton from "@/components/IconButton";

/**
 * A single menu that shows actions for the selected row.
 * Intended for use with side-scrolling tables.
 * A workaround for absolute positioning with `overflow: auto`.
 */
export default function useActionMenu() {
  const menuRef = useRef<MdMenu>(null);
  const [menu, setMenu] = useState<Menu>();

  function reposition() {
    menuRef.current?.reposition();
  }

  useEffect(() => {
    document.addEventListener("scroll", reposition);
    return () => {
      document.removeEventListener("scroll", reposition);
    };
  }, []);

  useEffect(() => {
    const { current } = menuRef;
    if (!current) return;

    if (menu) current.show();
    else current.close();
  }, [menu]);

  const setMenu$ = useCallback(
    (newMenu: Menu) => {
      if (menu?.rowId === newMenu.rowId && menuRef.current?.open) {
        menuRef.current.close();
      } else {
        setMenu(newMenu);
      }
    },
    [menu]
  );

  const ActionCell = ({ id, actions }: { id: string; actions: MenuItem[] }) => {
    function onClick() {
      setMenu$({ rowId: id, items: actions });
    }

    return (
      <div className="flex flex-row justify-end">
        <IconButton
          id={actionId(id)}
          onClick={onClick}
          data-testid="actions"
          title="actions"
          name="more_vert"
          iconClassName="font-extrabold"
        />
      </div>
    );
  };

  return {
    reposition: useCallback(reposition, []),
    setMenu: setMenu$,
    ActionCell: useCallback(ActionCell, [setMenu$]),
    Menu: useCallback(
      () => (
        <LitMenu
          anchor={menu && actionId(menu.rowId)}
          ref={menuRef}
          yOffset={2}
          positioning="fixed"
          menuCorner="start-end"
          anchorCorner="end-end"
        >
          {menu?.items.map((item, i) =>
            item === "divider" ? (
              <Divider key={i} />
            ) : (
              <LitMenuItem key={i} onClick={item.onClick} className={`${item.itemClassName}`}>
                <div className="flex flex-row items-center">
                  {!!item.iconName && <div className="flex mr-4">{<Icon name={item.iconName} />}</div>}
                  {item.label}
                </div>
              </LitMenuItem>
            )
          )}
        </LitMenu>
      ),
      [menu]
    )
  };
}
