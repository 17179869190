import Table from "@/components/Table";
import { useQuery } from "@apollo/client";
import { Quarter, CURRENT_QUARTER_QUERY } from "./queries";
import { Params, Path, useNavigate } from "@/router";
import GrpTabLayout from "../_GrpTabLayout";
import { useWindowSize } from "@/hooks/useWindowSize";
import IconButton from "@/components/IconButton";
import Menu from "@/components/Menu";
import { isMobile } from "@/assets/theme/sizes";
import { ButtonWithLink } from "@/components/withLink";
import { ButtonVariant } from "@/components/Button/Button";
import { MaterialNames } from "@/components/material_symbol_names";
import EmptyState from "@/components/EmptyState";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { LinkProps } from "@generouted/react-router/client";
import usePageTitle from "@/hooks/usePageTitle";

interface Data {
  currentQuarter: Quarter;
}

const ButtonGroup = <P extends Path>({
  actions
}: {
  actions: { label: string; icon?: MaterialNames; variant: ButtonVariant; link: LinkProps<P, Params> }[];
}) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const { label: lastActionLabel, ...lastActionProps } = actions[actions.length - 1];
  return (
    <div className="flex gap-2 items-center">
      {isMobile(width) ? (
        <>
          <ButtonWithLink {...lastActionProps}>{lastActionLabel}</ButtonWithLink>
          <Menu
            menuItems={[...actions]
              .reverse()
              .slice(1)
              .map((action) => ({
                label: action.label,
                iconName: action.icon,
                itemClassName: "w-[235px]",
                onClick: () => navigate(action.link.to)
              }))}
            AnchorComponent={<IconButton name="pending" />}
          />
        </>
      ) : (
        actions.map(({ label, ...props }, index) => (
          <div key={index}>
            <ButtonWithLink {...props}>{label}</ButtonWithLink>
          </div>
        ))
      )}
    </div>
  );
};

export default function SponsorContributions() {
  usePageTitle(
    "/benefit-programs/grp/sponsor-contributions",
    "Benefit Programs | Golf Retirement Plus | Sponsor Contributions"
  );
  useRequireCapability({ domain: DOMAIN.BENEFIT_PROGRAMS, permission: PERMISSION.viewSponsorContributions });
  const { data } = useQuery<Data>(CURRENT_QUARTER_QUERY);

  return (
    <GrpTabLayout>
      <Metrics
        metrics={[
          { name: "Total Contributions", value: "--" },
          { name: "Held Contributions", value: "-" },
          { name: "Errors", value: "-", error: true },
          { name: "Total Amount", value: "--" },
          { name: "Held Amount", value: "--" },
          { name: "Amount from Errors", value: "--", error: true }
        ]}
      />
      <div className="flex flex-wrap px-4 pt-8 pb-5 gap-4 tablet:items-center">
        <h2 className="text-title-medium flex-shrink-0 mr-auto w-full tablet:w-auto" data-testid="title">
          Sponsor Contributions{data?.currentQuarter ? ` for ${data?.currentQuarter.displayName}` : ""}
        </h2>
        <ButtonGroup
          actions={[
            {
              label: "Add Contribution",
              variant: "text",
              icon: "add",
              link: { to: "/benefit-programs/grp/sponsor-contributions" }
            },
            {
              label: "Upload Contributions",
              variant: "outlined",
              icon: "upload",
              link: { to: "/benefit-programs/grp/sponsor-contributions/upload" }
            },
            {
              label: "Review and Finalize",
              variant: "filled",
              link: { to: "/benefit-programs/grp/sponsor-contributions" }
            }
          ]}
        />
      </div>
      <Table
        loading={false}
        data={[]}
        columns={[
          { header: "Sponsor" },
          { header: "Modified Date" },
          { header: "Ready" },
          { header: "Held" },
          { header: "Errors" },
          { header: "Submitted" },
          { header: "Modified By" }
        ]}
        renderEmptyState={() => <EmptyMessage />}
      />
    </GrpTabLayout>
  );
}

const EmptyMessage = () => {
  return (
    <EmptyState
      title="No Contributions Added"
      caption="Add or upload sponsor contributions to start GRP quarterly processing."
      iconName="person"
      action={{
        to: "/benefit-programs/grp/sponsor-contributions/upload",
        children: "Upload Contributions",
        icon: "upload"
      }}
    />
  );
};

type MetricType = { name: string; value: string; error?: boolean };

const Metrics = ({ metrics }: { metrics: MetricType[] }) => {
  return (
    <div className="pt-4 pb-6 grid grid-cols-2 tablet:grid-cols-3 desktop:grid-cols-6 gap-2">
      {metrics.map((metric, i) => (
        <Metric key={i} {...metric} />
      ))}
    </div>
  );
};

const Metric = ({ name, value, error }: MetricType) => {
  return (
    <div className="px-4 flex flex-col gap-2" data-testid="metric">
      <div className="text-body-medium">{name}</div>
      <div className={`text-headline-medium ${error ? "text-sys-brand-error" : "text-sys-brand-on-surface"}`}>
        {value}
      </div>
    </div>
  );
};
