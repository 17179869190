import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { SOME_PEOPLE_QUERY } from "@/pages/people/queries";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

type argumentsType = {
  control: any;
  peopleVariables: { search?: string };
  setPeoplePGAId: (value: string) => void;
  setPeopleVariables: (value: { search?: string }) => void;
  setSearchedForPerson: (value: boolean) => void;
  setVariables: (value: InteractionInput) => void;
  variables: InteractionInput;
  disabled?: boolean;
};

export const SinglePerson = ({
  control,
  peopleVariables,
  setPeoplePGAId,
  setPeopleVariables,
  setSearchedForPerson,
  setVariables,
  variables,
  disabled
}: argumentsType) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const { data: personData, loading: peopleLoading } = useQuery(SOME_PEOPLE_QUERY, {
    variables: { input: peopleVariables },
    skip: Object.keys(peopleVariables).length === 0 || peopleVariables.search === ""
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setPeopleVariables({ search: debouncedSearchTerm });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchTerm, setPeopleVariables]);
  return (
    <>
      <div>Create one interaction for one person</div>

      <Controller
        name="interactionPerson"
        control={control}
        rules={{
          required: "Person is required"
        }}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            label="Person"
            required
            disabled={disabled}
            query={value}
            loadingResults={peopleLoading}
            data={personData?.people?.nodes || []}
            onChangeText={(string) => {
              onChange(string);
              setDebouncedSearchTerm(string);

              if (string === "") {
                setSearchedForPerson(false);
                setVariables({ ...variables, participantId: null });
              }
            }}
            reset={() => {
              onChange("");
              setPeopleVariables({ ...peopleVariables, search: "" });
              setSearchedForPerson(false);
            }}
            onSelect={(value) => {
              onChange(`${value.firstName} ${value.lastName}`);
              setPeoplePGAId(value.pgaId);
              setSearchedForPerson(true);
            }}
            renderItem={(data: {
              firstName: string;
              lastName: string;
              pgaId: string;
              salesforceExternalId: string;
              primarySectionAffiliation: { section: { name: string } };
              primaryFacility: { name: string };
              profilePhoto: string;
            }) => (
              <AvatarDetails
                title={`${data.firstName} ${data.lastName}`}
                text={data.primaryFacility?.name || ""}
                picture={data.profilePhoto}
                secondaryText={data.primarySectionAffiliation?.section?.name || ""}
              />
            )}
            error={fieldState.invalid}
            errorText={fieldState.error?.message}
          />
        )}
      />
    </>
  );
};
