import DescriptionField from "../components/DescriptionField";

const EventDescriptionStep = () => {
  return (
    <div className="flex flex-col gap-6">
      <p className="text-headline-small">Provide information to display on the published event details page</p>
      <DescriptionField />
    </div>
  );
};

export default EventDescriptionStep;
