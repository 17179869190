import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Date as EventDate } from "../types";
import DateSelect from "@/components/DateSelect";
import RadioItem from "@/components/Radio/RadioItem";

const WAITLIST_TYPES = [
  {
    value: "disabled",
    label: "Disabled Registration",
    subtext: "Potential registrants will not be able to register or take other actions"
  },
  {
    value: "notification",
    label: "Notification Signup",
    subtext: "Potential registrants will be able to request a message notifying them when a spot becomes available"
  }
];

type RegistrationFieldName = "registrationOpenDate" | "registrationCloseDate";

type RegistrationDetailsStepProps = {
  hasMaxAttendance: boolean;
};

export default function RegistrationDetailsStep({ hasMaxAttendance }: RegistrationDetailsStepProps) {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  const prevValues = useRef<{
    registrationOpenDate: EventDate;
    registrationCloseDate: EventDate;
  }>({ registrationOpenDate: { endDate: "", startDate: "" }, registrationCloseDate: { startDate: "", endDate: "" } });

  const handleFocus = (field: RegistrationFieldName) => {
    prevValues.current[field] = watch(field);
  };

  const handleBlur = (field: RegistrationFieldName) => {
    const currentValue = watch(field);

    if (!currentValue?.startDate?.trim()) {
      setValue(field, prevValues.current[field]);
    }

    trigger();
  };

  const handleChange = (field: RegistrationFieldName, value: any) => {
    setValue(field, value);
  };

  return (
    <div className="flex flex-col gap-6 mb-0">
      <p className="text-title-large mb-2">Enter registration options for this event.</p>
      <Controller
        name="registrationOpenDate"
        control={control}
        render={({ field }) => (
          <DateSelect
            label="Registration Open *"
            value={field.value}
            useSingleDate
            popoverDirection="down"
            minDate={new Date()}
            supportingText="MM/DD/YYYY"
            onChange={(value) => handleChange("registrationOpenDate", value)}
            onFocus={() => handleFocus("registrationOpenDate")}
            onBlur={() => handleBlur("registrationOpenDate")}
          />
        )}
      />
      <Controller
        name="registrationCloseDate"
        control={control}
        render={({ field }) => (
          <DateSelect
            label="Registration Close *"
            value={field.value}
            useSingleDate
            popoverDirection="down"
            minDate={new Date()}
            supportingText="MM/DD/YYYY"
            onChange={(value) => handleChange("registrationCloseDate", value)}
            onFocus={() => handleFocus("registrationCloseDate")}
            onBlur={() => handleBlur("registrationCloseDate")}
            error={!!errors.registrationCloseDate}
            errorMessage={errors.registrationCloseDate?.message as string}
          />
        )}
      />
      {hasMaxAttendance && (
        <div className="mt-6">
          <p className="text-title-medium mb-2">Waitlist Type</p>
          <p className="text-body-medium mb-3">
            Select how this event should handle the attendance limit being reached
          </p>

          <Controller
            control={control}
            name="waitlistType"
            data-testid="waitlist-type-radio-group"
            render={({ field }) => (
              <div className="flex flex-col">
                {WAITLIST_TYPES.map((option, i) => (
                  <div className="py-2 cursor-pointer" key={i}>
                    <div className="mb-3">
                      <RadioItem
                        name="waitlistType"
                        onChange={() => {
                          field.onChange(option.value);
                        }}
                        checked={field.value === option.value || field.value === "default"}
                        headline={option.label}
                        supportingText={option.subtext}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
}
