import { LitDivider } from "./LitDivider";

interface Props {
  className?: string;
  inset?: boolean;
}

export default function Divider({ className, inset }: Props) {
  return <LitDivider role="separator" className={className} inset={inset}></LitDivider>;
}
