import { useNavigate, useParams } from "@/router";
import { useEffect } from "react";

export default function EducationIndex() {
  const { pgaId } = useParams("/people/:pgaId/education");
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/people/:pgaId/education/learning", { params: { pgaId }, replace: true });
  });

  return null;
}
