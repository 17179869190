import Papa, { ParseResult as PapaParseResult } from "papaparse";

const config = {
  header: true,
  skipEmptyLines: true
};

export type ParseResult<T> = PapaParseResult<T>;

export const parseTsv = async <T>(data: File): Promise<ParseResult<T>> => {
  return new Promise((complete, error) => Papa.parse(data, { ...config, complete, error }));
};
