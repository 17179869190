import { Insights } from "@/types";
import InsightsCard from "@/components/InsightsCard";
import CardSkeleton from "@/components/Skeleton/CardSkeleton";
import { useAiQuery } from "@/lib/aiHelpers";
import { useEffect, useState } from "react";

type InsightData = {
  insights: Insights;
};

function isValidMessage(message: unknown): message is string {
  return typeof message === "string" && message.trim().length > 0 && !message.trim().startsWith("{");
}

export function MemberInsights({ payload }: { payload: InsightData }) {
  const [aiMessage, setAiMessage] = useState<string | null>(null);
  const [noContent, setNoContent] = useState(false);

  const { message, loading, error, fetchData } = useAiQuery<Insights>("member-insights");

  useEffect(() => {
    if (payload.insights) {
      fetchData(payload.insights);
    }
  }, [fetchData, payload.insights]);

  useEffect(() => {
    if (isValidMessage(message)) {
      setAiMessage(message);
    } else if (message === "") {
      setAiMessage(null);
    } else {
      setAiMessage(null);
      setNoContent(true);
    }
  }, [message]);

  if (error || noContent) return null;

  const isLoading = loading || !aiMessage;

  if (isLoading) {
    return (
      <div className="mt-4 sm:mt-8">
        <CardSkeleton className="min-w-full" />
      </div>
    );
  }

  if (aiMessage && isValidMessage(aiMessage)) {
    return (
      <div className="mt-4 sm:mt-8">
        <InsightsCard message={aiMessage} />
      </div>
    );
  }

  return null; // Fallback for any other unexpected state
}
