import { createContext, useContext } from "react";

type Value = {
  currentSidebar?: SideBar;
  showSidebar(sidebar: SideBar): () => void;
  toggleSidebar(sidebar: SideBar): (show: boolean) => void;
};

const TopActionBarContext = createContext<Value>({ showSidebar: () => () => {}, toggleSidebar: () => () => {} });

export const useTopActionBar = () => useContext(TopActionBarContext);
export enum SideBar {
  CREATE_GLOBAL_INTERACTION,
  QUICK_SEARCH
}

export default TopActionBarContext;
