import Button from "@/components/Button";
import SideSheetModal from "@/components/SideSheetModal";
import Tabs from "@/components/Tabs";
import { useQuickSearch } from "../../pages/people/_hooks/useQuickSearch";
import { useEffect, useState } from "react";

enum Tab {
  PEOPLE
}

export default function QuickSearch({ show, onToggle }: { show: boolean; onToggle(show: boolean): void }) {
  const { submit: submitPeople, Form: PeopleForm } = useQuickSearch();
  const [tab, setTab] = useState(Tab.PEOPLE);

  let submit$: () => Promise<boolean>;
  let Form;

  switch (tab) {
    case Tab.PEOPLE:
      submit$ = submitPeople;
      Form = PeopleForm;
      break;
  }

  async function submit() {
    if (await submit$()) onToggle(false);
  }

  useEffect(() => {
    function listener(e: KeyboardEvent) {
      if (show) {
        switch (e.key) {
          case "Enter":
            submit();
            e.preventDefault();
            break;
          case "Escape":
            onToggle(false);
            e.preventDefault();
            break;
        }
      }
    }

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SideSheetModal
      isSidebarOpen={show}
      actions={<Button onClick={submit}>Search</Button>}
      setSidebarOpen={onToggle}
      title="Quick Search"
    >
      <Tabs
        className="mt-4"
        tabItems={[
          {
            label: "People",
            onClick() {
              setTab(Tab.PEOPLE);
            },
            active: tab === Tab.PEOPLE
          }
        ]}
      />
      <Form />
    </SideSheetModal>
  );
}
