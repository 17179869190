export const variants = {
  info: "bg-sys-brand-primary-container-bright text-sys-brand-on-primary-container",
  warn: "bg-extended-warning-brand-color-container text-extended-warning-brand-on-color-container",
  error: "bg-sys-brand-error-container text-sys-brand-on-error-container"
};

export interface Action {
  label: string;
  link: string;
}

type Variant = "info" | "warn" | "error";

export interface BannerProps {
  description: string;
  variant?: Variant;
  secondaryAction?: Action;
  primaryAction?: Action;
  closable?: boolean;
  onClose?: () => void;
  className?: string;
  inline?: boolean;
}
