import { gql } from "@apollo/client";
import { Section } from "@/types";

export type SectionFragment = {
  section: Section & { permissions: { manageChangeformSettings: boolean; manageChangeRequests: boolean } };
};

export const SECTION_FRAGMENT = gql`
  fragment SectionFragment on BackofficeSection {
    id
    name
    legacyId
    permissions {
      manageChangeformSettings
      manageChangeRequests
    }
  }
`;

export const SECTION_DETAILS_HEADER_QUERY = gql`
  query section($sectionId: ID!) {
    section(id: $sectionId) {
      id
      name
      legacyId
      permissions {
        manageChangeformSettings
        manageChangeRequests
      }
    }
  }
`;
