import AvatarDetails from "@/components/AvatarDetails";
import EmptyState from "@/components/EmptyState";
import Icon from "@/components/Icon";
import Table, { Props, RowType } from "@/components/Table";
import { toDateTimeFormat } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { AuditEntry } from "@/pages/sections/types";
import { CellContext, ColumnDef, Row } from "@tanstack/react-table";

function DetailsTable<T extends Omit<RowType, "id">>({
  data,
  columns,
  ["data-testid"]: testId
}: {
  columns: ColumnDef<T & { id: string }, any>[];
  data: T[];
  ["data-testid"]?: string;
}) {
  // add own ID to each item in the dataset
  const dataWithId = data.map((row, i) => ({ ...row, id: i.toString() }));
  return (
    <div className="px-12 py-8">
      <Table
        data-testid={testId}
        loading={false}
        renderEmptyState={() => <></>}
        data={dataWithId}
        columns={columns.map((column) => ({ ...column, enableSorting: false }))}
      />
    </div>
  );
}

const renderExpandedRow = function ExpandedRow({ row }: { row: Row<AuditEntry> }) {
  return (
    <DetailsTable
      data={row.original.changeset}
      columns={[
        { accessorKey: "typeOfData", header: "Type of Data" },
        { accessorKey: "oldValue", header: "Old Value" },
        { accessorKey: "newValue", header: "New Value" }
      ]}
    />
  );
};

function renderExpandButton<T>({ title, getCount }: { title: string; getCount: (changeset: T) => number }) {
  return function ExpandingCell({
    cell: { getValue },
    row: { toggleExpanded, getIsExpanded }
  }: CellContext<AuditEntry, T>) {
    function onClick() {
      toggleExpanded();
    }

    const count = getCount(getValue());

    return (
      <button
        title={title}
        className="text-left w-full h-full flex items-center"
        disabled={count < 1}
        onClick={onClick}
      >
        <>
          {count}
          {count > 0 && <Icon className="ml" name={getIsExpanded() ? "expand_less" : "expand_more"} />}
        </>
      </button>
    );
  };
}

const getCount = (changeset: AuditEntry["changeset"]) => changeset?.length || 0;

const columnDefs: Record<string, ColumnDef<AuditEntry, any>> = {
  itemModified: {
    header: "Item Modified",
    cell: (cellProps: { row: { original: AuditEntry } }) => (
      <AvatarDetails
        picture={cellProps.row.original.profilePhotoUrl}
        title={cellProps.row.original.itemName}
        text="Member"
      />
    )
  },
  pgaId: {
    accessorKey: "pgaId",
    header: "PGA ID"
  },
  changeType: {
    header: "Change Type",
    cell: "Personal data"
  },
  description: {
    accessorKey: "description",
    header: "Description"
  },
  changedAt: {
    accessorKey: "changedAt",
    header: "Timestamp",
    cell: ({ getValue }: { getValue(): string }) => toDateTimeFormat(getValue(), "LLL dd, yyyy h:mm a (ZZZZ)")
  },
  changeset: {
    accessorKey: "changeset",
    header: "Changes",
    cell: renderExpandButton({ title: "View changes", getCount })
  }
};

type ColumnName = keyof typeof columnDefs;

const defaultColumns: ColumnName[] = ["itemModified", "pgaId", "changeType", "description", "changedAt", "changeset"];

const firstSticky = (hasData: boolean, [column, ...rest]: ColumnDef<AuditEntry, any>[]) => [
  {
    ...column,
    meta: {
      className: getStickyColumnClasses(hasData)
    }
  },
  ...rest
];

type AuditLogTableProps = Omit<
  Props<AuditEntry>,
  "columns" | "renderEmptyState" | "sortingState" | "renderExpandedRow"
> & { columns?: ColumnName[] };

export default function AuditLogTable({ columns: columnNames, data, ...props }: AuditLogTableProps) {
  const columns = (columnNames || defaultColumns).map((name) => ({ ...columnDefs[name], enableSorting: false }));

  return (
    <Table
      renderExpandedRow={renderExpandedRow}
      columns={firstSticky(!!data?.length, columns)}
      renderEmptyState={() => (
        <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="today" />
      )}
      data={data}
      {...props}
    />
  );
}
