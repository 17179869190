import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { PEOPLE_QUERY } from "../queries";
import { Person } from "@/types";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { toFormat } from "@/lib/dateHelpers";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import usePagination from "@/hooks/usePagination";
import { capitalize, isNull } from "lodash";
import { DateType } from "react-tailwindcss-datepicker";
import { useNavigate, Path, Params } from "@/router";
import PeopleTableFilters from "./PeopleTableFilters";
import MetricGrid from "@/components/MetricGrid";
import { LinkProps } from "@generouted/react-router/client";
import { PageInfo } from "@/components/Pagination";
import { useParams } from "@/hooks/useSearchParams";
import { generatePath } from "react-router-dom";

export interface Variables {
  suspended?: boolean;
  terminated?: boolean;
  suspendedDateRange?: {
    from: DateType;
    to: DateType;
  };
  terminatedDateRange?: {
    from: DateType;
    to: DateType;
  };
  search?: string;
  sectionIds?: string[];
  facilityIds?: string[];
  balanceRange?: {
    from?: number;
    to?: number;
  };
}

export interface PeopleRow {
  id: string;
  person: Person;
  pgaId?: string;
}

type StatsType = {
  totalCount: number | null;
  totalEligibleForTermination: number | null;
};

const mapPeople = (data: any[]) =>
  (data || []).map(({ updatedAt, dueDate, balance, lineItems, ...person }) => {
    return {
      ...person,
      person: {
        firstName: person.firstName,
        lastName: person.lastName,
        profilePhoto: person.profilePhoto,
        mainProgramType: {
          name: person.latestProgram?.programType?.name
        }
      },
      status: capitalize(person.latestProgram?.status),
      classification: person.latestProgram?.band?.classification?.name,
      section: person.primarySectionAffiliation?.section.name || "",
      primaryFacility: person.employments.filter((e: any) => e.isPrimary)[0]?.facility?.name || "",
      suspensionsDate: formatDate(person.suspensions[0]?.startTime),
      terminationsDate: formatDate(person.terminations[0]?.startTime)
    };
  });

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

type PeopleData = {
  people: {
    nodes: Person[];
    totalCount: number;
    pageInfo: PageInfo;
  };
  currentDuesCycle: {
    orders: {
      totalCount: number;
    };
  };
};

export const PeopleTable = <P extends Path>({ defaultVariables = {} }: { defaultVariables?: Variables }) => {
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const isSuspended = Object.keys(defaultVariables || {})[0] === "suspended";
  let { params, searchParamsUrl } = useParams();

  const { renderFooter, paginationVariables } = usePagination();

  const [variables, setVariables] = useState<Variables>({ ...defaultVariables, ...params });
  const { data, loading } = useQuery<PeopleData>(PEOPLE_QUERY, {
    variables: { input: { ...variables }, ...paginationVariables }
  });

  const peopleData: PeopleRow[] = mapPeople(data?.people?.nodes || []);

  const navigate = useNavigate();

  function onClickRow(row: Row<PeopleRow>) {
    navigate(
      generatePath(
        `/people/:pgaId/details/contact?return_to=/billing/dues-management/reporting/${
          isSuspended ? "suspensions" : "terminations"
        }&${searchParamsUrl}`,
        { pgaId: row.original.pgaId! }
      ) as Path
    );
  }

  const [stats, setStats] = useState<StatsType>({
    totalCount: null,
    totalEligibleForTermination: null
  });

  useEffect(() => {
    if (!loading && data) {
      if (stats.totalCount === null || stats.totalEligibleForTermination == null) {
        setStats({
          totalCount: data.people?.totalCount,
          totalEligibleForTermination: data.currentDuesCycle?.orders.totalCount
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  return (
    <>
      {stats.totalCount === 0 ? (
        <EmptyState title={`No Suspended People from Dues Billing`} iconName="group_off" />
      ) : (
        <>
          <MetricGrid
            metrics={[
              {
                label: `Total ${isSuspended ? "Suspended" : "Terminated"} People (Unpaid Dues)`,
                value: `${!isNull(stats.totalCount) && stats.totalCount}`,
                loading: isNull(stats.totalCount)
              },
              ...(isSuspended
                ? [
                    {
                      label: `Total Eligible for Termination`,
                      value: `${!isNull(stats.totalEligibleForTermination) && stats.totalEligibleForTermination}`,
                      loading: isNull(stats.totalEligibleForTermination),
                      link: { to: "/billing/dues-management/suspensions-and-terminations/terminations" } as LinkProps<
                        P,
                        Params
                      >
                    }
                  ]
                : [])
            ]}
            layout="expanded"
          />

          <div className="pt-8 px-4 pb-6 justify-between tablet:flex">
            <div className="max-w-[1000px] pr-5">
              <p className="text-body-medium">
                View all persons who have been {isSuspended ? "suspended" : "terminated"} due to non-payment of Dues in
                the current billing cycle.
              </p>
            </div>
          </div>

          <div className="w-full">
            <Table
              data={peopleData || []}
              loading={loading}
              onClickRow={onClickRow}
              renderHeader={() => (
                <PeopleTableFilters
                  defaultVariables={defaultVariables}
                  peopleData={peopleData}
                  variables={variables}
                  setVariables={setVariables}
                  data={data}
                />
              )}
              columns={[
                {
                  header: "Name",
                  id: "fullName",
                  cell: ({
                    row: {
                      original: { person }
                    }
                  }) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex items-center py-2">
                          <AvatarDetails
                            picture={person.profilePhoto}
                            title={`${person.firstName} ${person.lastName}`}
                            text={person.mainProgramType?.name || ""}
                          />
                        </div>
                      </div>
                    );
                  },
                  meta: {
                    className: getStickyColumnClasses(!!peopleData?.length)
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
                { accessorKey: "classification", header: "Class", enableSorting: false },
                { accessorKey: "status", header: "Status", enableSorting: false },
                { accessorKey: "section", header: "Section", enableSorting: false },
                { accessorKey: "primaryFacility", header: "Primary Facility", enableSorting: false },
                ...(isSuspended
                  ? [{ accessorKey: "suspensionsDate", header: "Suspension Date", enableSorting: false }]
                  : [{ accessorKey: "terminationsDate", header: "Termination Date", enableSorting: false }])
              ]}
              renderEmptyState={() => (
                <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="person" />
              )}
              renderFooter={() => (
                <div className={` flex justify-end flex-col md:flex-row md:items-center px-3`}>
                  {renderFooter(data?.people, {
                    totalCount: data?.people?.totalCount || 0,
                    pageCount: peopleData.length
                  })()}
                </div>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PeopleTable;
