import { PropsWithChildren } from "react";
import IconButton from "@/components/IconButton";
import DrawerModal from "./DrawerModal";

type OpenSidebarProps = {
  title: string;
  actions: React.ReactNode;
  closeSidebar: () => void;
  goBack?: () => void;
  disableClose?: boolean;
};
const zIndex = 1000;
const OpenSidebar = ({
  children,
  closeSidebar,
  title = "Title",
  actions,
  goBack,
  disableClose
}: PropsWithChildren<OpenSidebarProps>) => {
  return (
    <div
      className="flex flex-col items-start self-stretch pt-4 absolute top-0 left-0 right-0 bottom-0 bg-sys-brand-surface-container"
      style={{ zIndex }}
    >
      <div className="flex items-center justify-between self-stretch pl-6 pr-3">
        <div className="flex items-center flex-1 min-w-0">
          {goBack && <IconButton name="arrow_back" ariaLabel="back" onClick={goBack} className={`!my-1`} />}
          <div className="text-title-large text-sys-brand-on-surface-variant truncate px-4">{title}</div>
        </div>
        {!disableClose && <IconButton name="close" ariaLabel="close" onClick={closeSidebar} className={`!my-1`} />}
      </div>
      <div className="w-full px-6 border-b grow overflow-y-auto">{children}</div>
      <div className="flex items-start gap-2 px-6 py-4 h-[72px]">{actions}</div>
    </div>
  );
};

interface SidebarProps {
  isSidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
  title: string;
  actions: React.ReactNode;
  children: React.ReactNode;
  goBack?: () => void;
  disableClose?: boolean;
}

export default function SideSheetModal({
  isSidebarOpen,
  setSidebarOpen,
  title,
  actions,
  children,
  goBack,
  disableClose
}: SidebarProps) {
  return (
    <DrawerModal zIndex={zIndex} isOpen={isSidebarOpen} onChange={setSidebarOpen} data-testid="drawer">
      {isSidebarOpen ? (
        <OpenSidebar
          title={title}
          closeSidebar={() => setSidebarOpen(false)}
          actions={actions}
          goBack={goBack}
          disableClose={disableClose}
        >
          {children}
        </OpenSidebar>
      ) : null}
    </DrawerModal>
  );
}
