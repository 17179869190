import { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { ORDERS_QUERY } from "../queries";
import { Variables } from "../types";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import { renderStatus } from "@/pages/billing/_utils";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useNavigate, Path } from "@/router";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import usePagination from "@/hooks/usePagination";
import { Controller, useForm } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { intersectionBy, reject, isEmpty } from "lodash";
import Checkbox from "@/components/Checkbox";
import { RowSelectionState } from "@tanstack/react-table";
import Chip from "@/components/Chip";
import Button from "@/components/Button";
import pluralize from "@/lib/pluralize";
import MultiSelect from "@/components/MultiSelect";
import DateSelect from "@/components/DateSelect";
import { NumberFormatBase } from "react-number-format";
import TextField from "@/components/TextField";
import { isNull, get } from "lodash";
import FacilityAutocomplete from "../../../_components/FacilityAutocomplete";
import { FormProvider } from "react-hook-form";
import ExpandedFilters from "@/components/ExpandedFilters";
import SectionAutocomplete from "../../../_components/SectionAutocomplete";
import { currencyFormatter } from "@/lib/currencyHelpers";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import { DateValueType } from "react-tailwindcss-datepicker";
import { useSearch } from "@/hooks/useSearch";
import { GET_CLASSIFICATIONS } from "../../../queries";
import MetricGrid from "@/components/MetricGrid";
import getActiveFilters from "@/lib/filterHelpers";
import { useParams } from "@/hooks/useSearchParams";
import { ClassificationType, StatsType } from "../../types";
import { OrderRow, OrderNode } from "@/pages/billing/types";

export type FormValues = {
  name: string;
  minBalance: string;
  maxBalance: string;
  memberClassifications: string[];
  paymentStatuses: string[];
  sectionIds: string[];
  facilityIds: string[];
  eligibleForSuspensionOn: DateValueType | null;
  eligibleForTerminationOn: DateValueType | null;
  facility: string;
  section: string;
};

const mapCommerceOrders = (data: OrderNode[]) =>
  (data || []).map(({ updatedAt, dueDate, balance, lineItems, ...order }) => ({
    ...order,
    pgaId: order.person.pgaId,
    classification: order.person.classification || "",
    section: order.person.primarySectionAffiliation?.section.name || "",
    updatedAt: formatDate(updatedAt),
    dueDate: formatDate(dueDate),
    balance: centsToDollarsFormatted(balance) || undefined,
    products: lineItems.length
  }));

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

export const OrdersTable = ({ defaultVariables }: { defaultVariables: Variables }) => {
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  let { params, addSearchParam, deleteSearchParams, searchParamsUrl } = useParams();
  params.balanceRange = params.maxBalance && { to: Number(params.maxBalance) };
  params.balanceRange = params.minBalance && { from: Number(params.minBalance) };
  const { renderFooter, paginationVariables, resetPagination } = usePagination();

  const { control: controlSearch } = useSearch("name", params.name || "");

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const rowSelected = Object.keys(rowSelection).length;
  const [selectedData, setSelectedData] = useState<OrderRow[]>([]);
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>(params.paymentStatuses || []);
  const [selectedClassificationValues, setSelectedClassificationValues] = useState<string[]>(
    params.memberClassifications || []
  );

  const [variables, setVariables] = useState<Variables>({ ...defaultVariables, ...params });
  const { data, loading } = useQuery(ORDERS_QUERY, { variables: { ...variables, ...paginationVariables } });
  const [selectedOrdersQuery, { data: allSelectedData, loading: allSelectedLoading }] = useLazyQuery(ORDERS_QUERY);
  const { data: classificationData } = useQuery(GET_CLASSIFICATIONS);

  const navigate = useNavigate();

  const form = useForm<FormValues>();
  const { control, reset, watch, getValues } = form;

  const pattern = "/billing/orders";
  function onClickRow(row: Row<OrderRow>) {
    navigate(
      `${pattern}/${row.original.id}?return_to=/billing/dues-management/reporting/unpaid-dues&${searchParamsUrl}` as Path
    );
  }

  const orderData: OrderRow[] = mapCommerceOrders(data?.currentDuesCycle?.orders.nodes || []);

  const allSelectedDataArray: OrderRow[] = mapCommerceOrders(allSelectedData?.currentDuesCycle?.orders.nodes || []);

  const getIsSomeRowsSelected = () => {
    return intersectionBy(selectedData, orderData, "id").length > 0 && selectedData.length !== orderData.length;
  };

  const [stats, setStats] = useState<StatsType>({
    totalCount: null,
    failedPayments: null,
    overdueOrders: null
  });

  useEffect(() => {
    if (!loading && data) {
      if (stats.totalCount === null || stats.failedPayments == null) {
        setStats({
          totalCount: data.currentDuesCycle?.totalOrders?.totalCount,
          failedPayments: data.currentDuesCycle?.totalOrdersWithFailedPayments?.totalCount,
          overdueOrders: data.currentDuesCycle?.totalOverdueOrders?.totalCount
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);
  const sectionIds = watch("sectionIds");
  const facilityIds = watch("facilityIds");

  useEffect(() => {
    setVariables((prevValue) => ({
      ...prevValue,
      ...(sectionIds ? { sectionIds: sectionIds } : {}),
      ...(facilityIds ? { facilityIds: facilityIds } : {})
    }));
  }, [sectionIds, facilityIds]);

  const isMobileDisplay = isMobile(useWindowSize().width);

  const filters = {
    eligibleForSuspensionOn: !!variables.eligibleForSuspensionOn,
    paymentStatuses: selectedValues.filter((v) => v !== "OPEN").length > 0,
    clasifications: selectedClassificationValues.length > 0,
    minBalance: !!getValues("minBalance"),
    maxBalance: !!getValues("maxBalance"),
    eligibleForTerminationOn: !!variables.eligibleForTerminationOn,
    section: params.sectionIds?.length > 0,
    facility: params.facilityIds?.length > 0
  };

  const { eligibleForSuspensionOn, facility, ...extendedFilters } = filters;
  const { isActive, activeFilters } = getActiveFilters(filters, extendedFilters, isMobileDisplay);

  const classificationNodes = get(classificationData, "classifications.nodes");
  const classificationDataNodes =
    classificationNodes?.map((node: ClassificationType) => ({
      value: node.name,
      label: node.name
    })) || [];

  const ClearAllButton = () => (
    <Button
      variant="text"
      onClick={() => {
        setVariables(defaultVariables);
        setSelectedValues([]);
        setSelectedClassificationValues([]);
        reset({
          eligibleForSuspensionOn: { startDate: null, endDate: null },
          eligibleForTerminationOn: { startDate: null, endDate: null }
        });
        deleteSearchParams([
          "eligibleForSuspensionOn",
          "eligibleForTerminationOn",
          "maxBalence",
          "minBalance",
          "memberClassifications[]",
          "paymentStatuses[]",
          "facilityIds[]",
          "sectionIds[]",
          "facility",
          "section"
        ]);
      }}
    >
      Clear All
    </Button>
  );

  return (
    <>
      {stats.totalCount === 0 ? (
        <EmptyState title={``} iconName="person" />
      ) : (
        <>
          <MetricGrid
            metrics={[
              {
                label: `Total Unpaid Dues`,
                value: `${!isNull(stats.totalCount) && stats.totalCount}`,
                loading: isNull(stats.totalCount)
              },
              {
                label: `Orders in Active Filter(s)`,
                value: `${data?.currentDuesCycle?.orders.totalCount}`,
                loading: loading
              },
              {
                label: `Overdue Orders`,
                value: `${!isNull(stats.overdueOrders) && stats.overdueOrders}`,
                loading: isNull(stats.overdueOrders),
                variant: "error"
              },
              {
                label: `Total Orders with Failed Payment`,
                value: `${!isNull(stats.failedPayments) && stats.failedPayments}`,
                loading: isNull(stats.failedPayments),
                variant: "error"
              }
            ]}
            layout="stacked"
          />

          <div className="pt-8 px-4 pb-6 justify-between tablet:flex">
            <div className="max-w-[1000px] pr-5">
              <p className="text-body-medium">
                List of Dues orders that have been billed but not fully paid. When open orders become overdue, they will
                be added to the “Eligible for Suspension” list.
              </p>
            </div>
          </div>

          <div className="w-full">
            <Table
              data={(isSelectedOnly ? allSelectedDataArray : orderData) || []}
              loading={loading || allSelectedLoading}
              onClickRow={onClickRow}
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              setSelectedData={setSelectedData}
              renderHeader={() => (
                <div>
                  <div className="flex md:items-center justify-between">
                    <div className="flex md:items-center gap-3 flex-row w-full">
                      <FormProvider {...form}>
                        <div className="w-full sm:max-w-[320px]">
                          <Controller
                            name="name"
                            control={controlSearch}
                            render={({ field }) => (
                              <SearchInput
                                placeholder="Search Orders"
                                query={field.value}
                                param="Name, PGA ID"
                                onChange={(e) => {
                                  field.onChange(e);
                                  setVariables((prevValue) => ({ ...prevValue, query: e }));
                                  resetPagination();
                                  addSearchParam("name", e);
                                }}
                                onClear={() => {
                                  deleteSearchParams("name");
                                }}
                              />
                            )}
                          />
                        </div>
                        {rowSelected === 0 && (
                          <>
                            {!isMobileDisplay && (
                              <>
                                <div className="min-w-[210px]">
                                  <Controller
                                    name="eligibleForSuspensionOn"
                                    control={control}
                                    render={({ field }) => {
                                      return (
                                        <DateSelect
                                          placeholder="Select a Date"
                                          label="Eligible For Suspension On"
                                          value={
                                            params.eligibleForSuspensionOn
                                              ? {
                                                  startDate: new Date(params.eligibleForSuspensionOn),
                                                  endDate: new Date(params.eligibleForSuspensionOn)
                                                }
                                              : field.value
                                          }
                                          useSingleDate
                                          onChange={(e) => {
                                            field.onChange(e);
                                            if (typeof e === "object" && e !== null) {
                                              setVariables((prevValue) => ({
                                                ...prevValue,
                                                eligibleForSuspensionOn: e.endDate
                                              }));
                                              addSearchParam("eligibleForSuspensionOn", e.endDate?.toString() || "");
                                            }
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                <div className="min-w-[210px]">
                                  <FacilityAutocomplete
                                    sectionIds={variables?.sectionIds}
                                    addSearchParam={addSearchParam}
                                    deleteSearchParams={deleteSearchParams}
                                    params={params}
                                  />
                                </div>
                              </>
                            )}
                            <ExpandedFilters
                              activeFilters={activeFilters}
                              items={[
                                {
                                  component: (
                                    <Controller
                                      name="eligibleForSuspensionOn"
                                      control={control}
                                      render={({ field }) => {
                                        return (
                                          <DateSelect
                                            placeholder="Select a Date"
                                            label="Eligible For Suspension On"
                                            value={field.value}
                                            useSingleDate
                                            onChange={(e) => {
                                              field.onChange(e);
                                              if (typeof e === "object" && e !== null) {
                                                setVariables((prevValue) => ({
                                                  ...prevValue,
                                                  eligibleForSuspensionOn: e.endDate
                                                }));
                                                addSearchParam("eligibleForSuspensionOn", e.endDate?.toString() || "");
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  ),
                                  onlyMobile: true
                                },
                                {
                                  component: (
                                    <FacilityAutocomplete
                                      sectionIds={variables?.sectionIds}
                                      addSearchParam={addSearchParam}
                                      deleteSearchParams={deleteSearchParams}
                                      params={params}
                                    />
                                  ),
                                  onlyMobile: true
                                },
                                {
                                  component: (
                                    <Controller
                                      name="paymentStatuses"
                                      control={control}
                                      render={() => (
                                        <MultiSelect
                                          label="Status"
                                          selectedValues={selectedValues}
                                          options={[
                                            { value: "HAS_FAILED_PAYMENTS", label: "Has Failed Payment" },
                                            { value: "HAS_REFUNDED_PAYMENTS", label: "Has Refunded Payment" },
                                            { value: "IS_OVERDUE", label: "Overdue" }
                                          ]}
                                          onSelect={(value, checked) => {
                                            let status = checked
                                              ? [...selectedValues, value]
                                              : selectedValues.filter((v) => v !== value);

                                            setSelectedValues(reject(status, isEmpty));
                                            setVariables((prevValue) => ({
                                              ...prevValue,
                                              paymentStatuses: reject(status, isEmpty)
                                            }));
                                            addSearchParam("paymentStatuses[]", reject(status, isEmpty).join(","));
                                          }}
                                          onClear={() => {
                                            setSelectedValues([]);
                                            setVariables((prevValue) => ({
                                              ...prevValue,
                                              paymentStatuses: []
                                            }));
                                            deleteSearchParams("paymentStatuses[]");
                                          }}
                                          className="w-full"
                                        />
                                      )}
                                    />
                                  )
                                },
                                {
                                  component: (
                                    <Controller
                                      name="memberClassifications"
                                      control={control}
                                      render={() => (
                                        <MultiSelect
                                          label="Classification"
                                          selectedValues={selectedClassificationValues}
                                          options={classificationDataNodes}
                                          onSelect={(value, checked) => {
                                            const status = checked
                                              ? [...selectedClassificationValues, value]
                                              : selectedClassificationValues.filter((v) => v !== value);
                                            setSelectedClassificationValues(reject(status, isEmpty));
                                            setVariables((prevValue) => ({
                                              ...prevValue,
                                              memberClassifications: reject(status, isEmpty)
                                            }));
                                            addSearchParam(
                                              "memberClassifications[]",
                                              reject(status, isEmpty).join(",")
                                            );
                                          }}
                                          onClear={() => {
                                            setSelectedClassificationValues([]);
                                            setVariables((prevValue) => ({
                                              ...prevValue,
                                              memberClassifications: []
                                            }));
                                            deleteSearchParams("memberClassifications[]");
                                          }}
                                          className="w-full"
                                        />
                                      )}
                                    />
                                  )
                                },
                                {
                                  component: (
                                    <Controller
                                      name="minBalance"
                                      control={control}
                                      defaultValue={params.minBalance || ""}
                                      render={({ field }) => {
                                        return (
                                          <NumberFormatBase
                                            label="Min Balance"
                                            placeholder="Enter Amount"
                                            leadingIcon="attach_money"
                                            customInput={TextField}
                                            format={currencyFormatter}
                                            value={field.value ?? ""}
                                            name={field.name}
                                            onValueChange={(values) => {
                                              field.onChange(values.floatValue);
                                              if (values.floatValue) {
                                                setVariables((prevValue) => ({
                                                  ...prevValue,
                                                  balanceRange: {
                                                    from: values.floatValue,
                                                    to: prevValue.balanceRange?.to
                                                  }
                                                }));
                                                addSearchParam("minBalance", values.floatValue.toString());
                                              } else {
                                                setVariables((prevValue) => {
                                                  const { balanceRange, ...rest } = prevValue;
                                                  const { to } = balanceRange || {};
                                                  return {
                                                    balanceRange: {
                                                      to
                                                    },
                                                    ...rest
                                                  };
                                                });
                                                deleteSearchParams("minBalance");
                                              }
                                            }}
                                            className="w-full"
                                          />
                                        );
                                      }}
                                    />
                                  )
                                },
                                {
                                  component: (
                                    <Controller
                                      name="maxBalance"
                                      control={control}
                                      defaultValue={params.maxBalance || ""}
                                      render={({ field }) => {
                                        return (
                                          <NumberFormatBase
                                            label="Max Balance"
                                            placeholder="Enter Amount"
                                            leadingIcon="attach_money"
                                            customInput={TextField}
                                            format={currencyFormatter}
                                            value={field.value ?? ""}
                                            name={field.name}
                                            onValueChange={(values) => {
                                              field.onChange(values.floatValue);
                                              if (values.floatValue) {
                                                setVariables((prevValue) => ({
                                                  ...prevValue,
                                                  balanceRange: {
                                                    from: prevValue.balanceRange?.from,
                                                    to: values.floatValue
                                                  }
                                                }));
                                                addSearchParam("maxBalance", values.floatValue.toString());
                                              } else {
                                                setVariables((prevValue) => {
                                                  const { balanceRange, ...rest } = prevValue;
                                                  const { from } = balanceRange || {};
                                                  return {
                                                    balanceRange: {
                                                      from
                                                    },
                                                    ...rest
                                                  };
                                                });
                                                deleteSearchParams("maxBalance");
                                              }
                                            }}
                                            className="w-full"
                                          />
                                        );
                                      }}
                                    />
                                  )
                                },
                                {
                                  component: (
                                    <SectionAutocomplete
                                      addSearchParam={addSearchParam}
                                      deleteSearchParams={deleteSearchParams}
                                      params={params}
                                    />
                                  )
                                },

                                {
                                  component: (
                                    <Controller
                                      name="eligibleForTerminationOn"
                                      control={control}
                                      render={({ field }) => {
                                        return (
                                          <DateSelect
                                            placeholder="Select a Date"
                                            label="Eligible For Termination On"
                                            value={
                                              params.eligibleForTerminationOn
                                                ? {
                                                    startDate: new Date(params.eligibleForTerminationOn),
                                                    endDate: new Date(params.eligibleForTerminationOn)
                                                  }
                                                : field.value
                                            }
                                            useSingleDate
                                            onChange={(e) => {
                                              field.onChange(e);
                                              if (typeof e === "object" && e !== null) {
                                                setVariables((prevValue) => ({
                                                  ...prevValue,
                                                  eligibleForTerminationOn: e.endDate
                                                }));
                                                addSearchParam("eligibleForTerminationOn", e.endDate?.toString() || "");
                                              }
                                            }}
                                            supportingText="Orders 30+ days overdue on this date"
                                          />
                                        );
                                      }}
                                    />
                                  )
                                },
                                {
                                  component: <>{isActive && <ClearAllButton />}</>,
                                  onlyMobile: true
                                }
                              ]}
                            />
                            {isActive && (
                              <div className="hidden sm:block" data-testid="clear-all-button">
                                <ClearAllButton />
                              </div>
                            )}
                          </>
                        )}
                      </FormProvider>
                    </div>
                    <div className="hidden ml-3 lg:block">
                      {!isSelectedOnly &&
                        renderFooter(data?.currentDuesCycle?.orders, {
                          totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                          pageCount: orderData.length,
                          variant: "compact"
                        })()}
                    </div>
                  </div>

                  {rowSelected > 0 && (
                    <div className="flex items-center gap-2 my-3 flex-wrap md:flex-nowrap">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                      <Button
                        variant="text"
                        onClick={() => {
                          setRowSelection({});
                          setIsSelectedOnly(false);
                          resetPagination();
                        }}
                      >
                        Deselect All {rowSelected} {pluralize(rowSelected, "Row")}
                      </Button>
                    </div>
                  )}
                  <div className="block mt-3 lg:hidden">
                    {!isSelectedOnly &&
                      renderFooter(data?.currentDuesCycle?.orders, {
                        totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                        pageCount: orderData.length,
                        variant: "compact"
                      })()}
                  </div>
                </div>
              )}
              columns={[
                {
                  header: ({ table }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Checkbox
                            name="select"
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={getIsSomeRowsSelected()}
                            onChange={(checked) => {
                              table.getToggleAllRowsSelectedHandler()({
                                target: { checked: getIsSomeRowsSelected() ? true : checked }
                              });
                              setIsSelectedOnly(false);
                            }}
                          />
                        </div>
                        <span className="text-label-large">Name</span>
                      </div>
                    );
                  },
                  id: "fullName",
                  cell: ({
                    row,
                    row: {
                      original: { person }
                    }
                  }) => {
                    return (
                      <div className="flex items-center">
                        <div className="mr-4" onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            name="select"
                            disabled={!row.getCanSelect()}
                            onChange={(checked) => {
                              row.getToggleSelectedHandler()({ target: { checked } });
                            }}
                            checked={row.getIsSelected()}
                          />
                        </div>
                        <div className="flex items-center py-2">
                          <AvatarDetails
                            picture={person.profilePhoto}
                            title={`${person.firstName} ${person.lastName}`}
                            text={person.mainProgramType?.name || ""}
                          />
                        </div>
                      </div>
                    );
                  },
                  meta: {
                    className: getStickyColumnClasses(!!orderData?.length)
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
                { accessorKey: "classification", header: "Classification", enableSorting: false },
                { accessorKey: "section", header: "Section", enableSorting: false },
                {
                  accessorKey: "products",
                  header: "Products",
                  enableSorting: false
                },
                { accessorKey: "updatedAt", header: "Modified", enableSorting: false },
                { accessorKey: "dueDate", header: "Due Date", enableSorting: false },
                { accessorKey: "balance", header: "Balance", enableSorting: false },
                {
                  header: "Stage",
                  id: "stage",
                  cell: ({ row: { original } }) => renderStatus(original.stage, original.paymentStatus),
                  size: 100
                }
              ]}
              renderEmptyState={() => (
                <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="person" />
              )}
              renderFooter={() => (
                <div
                  className={`${
                    rowSelected > 0 ? "justify-between" : "justify-end"
                  } flex flex-col md:flex-row md:items-center`}
                >
                  {rowSelected > 0 && (
                    <div className="flex items-center gap-2 mb-3 md:mb-0">
                      <span className="text-label-large">
                        {rowSelected} {pluralize(rowSelected, "row")} selected
                      </span>
                      <Chip
                        onClick={() => {
                          setIsSelectedOnly(!isSelectedOnly);
                          selectedOrdersQuery({ variables: { ids: Object.keys(rowSelection) } });
                        }}
                        label="Show Selected Only"
                        selected={isSelectedOnly}
                      />
                    </div>
                  )}
                  {!isSelectedOnly &&
                    renderFooter(data?.currentDuesCycle?.orders, {
                      totalCount: data?.currentDuesCycle?.orders?.totalCount || 0,
                      pageCount: orderData.length
                    })()}
                </div>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default OrdersTable;
