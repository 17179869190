import type { FlagKey, FlagRecord } from "./flags";
import type { BackofficeUser } from "./types";

const BACKOFFICE_FRONTEND_TAG = "backoffice-frontend";

export const fetchActiveFlagKeys = async (): Promise<FlagKey[]> => {
  const res = await fetch(`${import.meta.env.VITE_FLAGR_URL}/flags?enabled=true&tags=${BACKOFFICE_FRONTEND_TAG}`);
  const data = await res.json();
  const result = data.map((flag: { key: FlagKey }) => flag.key);
  return result;
};

type EvaluationResponse = {
  evaluationResults: {
    flagKey: FlagKey;
    variantKey?: "true";
  }[];
};

export const fetchEvaluateFlags = async (flagKeys: FlagKey[], me: BackofficeUser): Promise<FlagRecord> => {
  const { externalId } = me;

  const res = await fetch(`${import.meta.env.VITE_FLAGR_URL}/evaluation/batch`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      enableDebug: false,
      flagKeys,
      entities: [
        {
          entityID: externalId,
          entityType: "user",
          entityContext: {
            user_id: externalId
          }
        }
      ]
    })
  });

  const data = (await res.json()) as EvaluationResponse;

  const flagValues = data.evaluationResults.reduce<FlagRecord>((acc, result) => {
    const { flagKey, variantKey } = result;
    acc[flagKey] = variantKey === "true";
    return acc;
  }, {});

  return flagValues;
};
