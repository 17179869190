import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import EmptyState from "@/components/EmptyState";
import Icon from "@/components/Icon";
import MetricGrid from "@/components/MetricGrid";
import Progress from "@/components/Progress";
import Select from "@/components/Select";
import SideSheetModal from "@/components/SideSheetModal";
import Skeleton from "@/components/Skeleton";
import Stepper from "@/components/Stepper";
import { StepType } from "@/components/Stepper/Stepper";
import Switch from "@/components/Switch";
import Tag from "@/components/Tag";
import TextField from "@/components/TextField";
import useAmplitude from "@/hooks/useAmplitude";
import { DOMAIN, PERMISSION, useCanManageInteractionsBool, useRequireCapability } from "@/hooks/useCapabilities";
import { useFlags } from "@/hooks/useFeatureFlags";
import AppLayout from "@/layouts/AppLayout";
import { toDateFormat } from "@/lib/dateHelpers";
import { showSnackbar } from "@/lib/snackbarUtils";
import { Path, useNavigate } from "@/router";
import { Person } from "@/types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { DateRangeType } from "react-tailwindcss-datepicker";
import { PersonDetailsHeader } from "../../_components/PersonDetailsHeader";
import { getPersonNavLinks } from "../../_utils";
import ConnectedPeople from "../_components/ConnectedPeople";
import TabLayout from "../_TabLayout";
import { filterInteractionsForSummary, getIcon, getLinkDescription, groupInteractions } from "../_utils";
import { Interaction, InteractionInput, InteractionStats, SummaryPayload } from "./types";
import { useAiQuery } from "@/lib/aiHelpers";
import { formatAiSummary, guardAiResponse } from "@/pages/people/[pgaId]/interactions/_utils";
import AiCard from "@/components/AiCard";
import Autocomplete from "@/components/Autocomplete";
import {
  CATEGORIES_QUERY,
  CategoriesData,
  allModesData,
  CREATE_INTERACTION_MUTATION,
  GET_PERSON_INTERACTIONS_QUERY,
  statsData,
  SHARE_INTERACTION_MUTATION
} from "./queries";
import ShareInteractionModal from "./_components/ShareInteractionModal";
import { User } from "@auth0/auth0-react";
import { useIsMobile } from "@/hooks/useIsMobile";

export const PAGE_SIZE = 10;

type PersonData = { person: Person };

const getMetrics = (stats: InteractionStats) => {
  return [
    {
      label: "Total Interactions",
      value: `${stats.totalInteractions || "-"}`,
      loading: !stats
    },
    {
      label: "Total Phone Interactions",
      value: `${stats.totalPhoneInteractions || "-"}`,
      loading: !stats
    },
    {
      label: "Total Video Interactions",
      value: `${stats.totalVideoInteractions || "-"}`,
      loading: !stats
    },
    {
      label: "Total SMS Interactions",
      value: `${stats.totalSmsInteractions || "-"}`,
      loading: !stats
    },
    {
      label: "Total Email Interactions",
      value: `${stats.totalEmailInteractions || "-"}`,
      loading: !stats
    },
    {
      label: "Total In-Person Interactions",
      value: `${stats.totalInPersonInteractions || "-"}`,
      loading: !stats
    }
  ];
};

interface InteractionGroupProps {
  monthYear: string;
  interactions: Interaction[];
  pgaId: string;
}

const InteractionGroup = ({ monthYear, interactions, pgaId }: InteractionGroupProps) => {
  const [isExpanded, setExpended] = useState(true);
  const canManageInteractions = useCanManageInteractionsBool();
  const [selectedInteraction, setSelectedInteraction] = useState<Interaction>();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareVariables, setShareVariables] = useState<{ recipients: User[]; notes: string }>({
    recipients: [],
    notes: ""
  });
  const flags = useFlags();
  const backofficeInteractionsSharing = flags["backoffice-interactions-sharing"];
  const [shareInteractionMutation, { loading: sharingInteraction }] = useMutation(SHARE_INTERACTION_MUTATION);

  async function shareInteraction(interaction: Interaction) {
    try {
      const { data } = await shareInteractionMutation({
        variables: {
          input: {
            interaction: {
              id: interaction.id,
              description: interaction.lastMessage.content,
              owner: interaction.owner,
              date: interaction.interactionDate,
              mode: interaction.mode,
              topic: interaction.categories[0],
              participants: [
                ...interaction.participants.map((participant) => `${participant.firstName} ${participant.lastName}`)
              ]
            },
            recipients: shareVariables.recipients.map((recipient) => ({
              name: recipient.name,
              email: recipient.email
            })),
            notes: shareVariables.notes,
            pgaId: pgaId
          }
        }
      });

      if (data?.shareInteraction?.success) {
        setShareModalOpen(false);
        showSnackbar("Interaction has been shared");
        setShareVariables({ recipients: [], notes: "" });
      } else {
        showSnackbar("There was a problem sharing this interaction. Please try again later");
      }
    } catch {
      showSnackbar("There was a problem sharing this interaction. Please try again later");
    }
  }

  const navigate = useNavigate();
  const showLink: Path = "/people/:pgaId/interactions/interactions/:interactionId";
  const SUMMARY_LIMIT = 600;

  const stepsList = interactions.map((interaction) => {
    const { summary, mode, owner, lastMessage, messagesCount, categories, interactionDate } = interaction;
    const { isPrivate, haveAccess } = lastMessage;

    const participants = interaction.participants.map(({ profilePhoto }) => profilePhoto);
    const icon = getIcon(mode);
    const linkDescription = getLinkDescription(mode, true); // Capitalize the first letter
    const hasAccessToInteraction =
      !interaction.lastMessage.isPrivate || (interaction.lastMessage.isPrivate && interaction.lastMessage.haveAccess);

    const step: StepType = {
      headline: (
        <div className="flex flex-wrap gap-x-2 items-center relative">
          <span className="text-sys-brand-primary -mr-1">{linkDescription}</span>
          {owner}
          {messagesCount > 1 && `(${messagesCount} messages)`}
          {isPrivate && <Icon name="lock" size={24} />}
          {backofficeInteractionsSharing && hasAccessToInteraction && canManageInteractions && (
            <div
              className="tablet:hidden hover:inline group-hover:inline"
              id={interaction.id}
              onClick={(event) => {
                event.stopPropagation();
                setSelectedInteraction(interaction);
                setShareModalOpen(true);
              }}
            >
              <div className="tablet:absolute -top-1 mt-0.5 tablet:mt-0">
                <Icon name="ios_share" size={24} className="mt-1" />
              </div>
            </div>
          )}
        </div>
      ),
      supportingText: toDateFormat(new Date(interactionDate).toISOString().split("T")[0]) || "",
      icon,
      children: (
        <>
          <ConnectedPeople participants={participants} />
          {(!isPrivate || (isPrivate && haveAccess)) && (
            <>
              <ul className="flex flex-wrap gap-2 items-center mt-3">
                {categories.map((category, index) => (
                  <li key={index}>
                    <Tag color="secondary">{category}</Tag>
                  </li>
                ))}
              </ul>
              <p className="mt-3">
                {summary?.substring(0, SUMMARY_LIMIT)}
                {summary?.length > SUMMARY_LIMIT && "..."}
              </p>
            </>
          )}
        </>
      )
    };

    return step;
  });

  return (
    <>
      <div className="pt-8 pb-3 flex items-center gap-2 cursor-pointer" onClick={() => setExpended(!isExpanded)}>
        {monthYear}
        <Icon name={isExpanded ? "expand_less" : "expand_more"} />
      </div>
      {isExpanded && (
        <Stepper
          onStepClick={(i) => {
            const interaction = interactions[i];
            const hasAccessToInteraction =
              !interaction.lastMessage.isPrivate ||
              (interaction.lastMessage.isPrivate && interaction.lastMessage.haveAccess);

            hasAccessToInteraction
              ? navigate(showLink, { params: { pgaId, interactionId: interaction.id } })
              : showSnackbar("Access to this interaction is restricted");
          }}
          variant="solid"
          stepsList={stepsList}
        />
      )}
      {shareModalOpen && selectedInteraction && (
        <ShareInteractionModal
          open={shareModalOpen}
          setOpen={setShareModalOpen}
          shareInteraction={() => shareInteraction(selectedInteraction)}
          isPrivate={selectedInteraction.lastMessage.isPrivate}
          shareVariables={shareVariables}
          setShareVariables={setShareVariables}
          disabled={sharingInteraction}
        />
      )}
    </>
  );
};

const InteractionsPage = ({
  setSalesforceId,
  interactions,
  setInteractions
}: {
  setSalesforceId: (value: string) => void;
  interactions: Interaction[];
  setInteractions: (value: Interaction[] | ((prevInteractions: Interaction[]) => Interaction[])) => void;
}) => {
  useRequireCapability({ domain: DOMAIN.PEOPLE, permission: PERMISSION.viewPeopleInteractions });
  const { pgaId } = useParams();
  const stats = statsData;
  const [insightSummary, setInsightSummary] = useState<string>("");
  const [showAiSummary, setShowAiSummary] = useState<boolean>(false);
  const [getInteractions, { data, loading, error, fetchMore }] = useLazyQuery<PersonData>(
    GET_PERSON_INTERACTIONS_QUERY,
    {
      fetchPolicy: "network-only"
    }
  );
  const interactionsMap = groupInteractions(interactions);
  const metrics = getMetrics(stats);
  const flags = useFlags();
  const flagInteractionsMetrics = flags["backoffice-interactions-metrics"];

  const flagInteractionsSummary = flags["backoffice-interactions-summary"];
  const pageInfo = data?.person?.interactions?.pageInfo;
  const shouldGenerateAiSummary = flagInteractionsSummary && interactions.length > 0;
  const salesforceId = data?.person?.salesforceExternalId;
  const hasMore = pageInfo && pageInfo.hasNextPage;
  const nextPage = pageInfo && pageInfo.endCursor;
  const [grabbedMore, setGrabbedMore] = useState(false);

  const { fetchData: generateInsightsSummary, loading: generatingInsightsSummary } =
    useAiQuery("career-consultation-insights");

  useEffect(() => {
    salesforceId && setSalesforceId(salesforceId);
  }, [salesforceId, setSalesforceId]);

  useEffect(() => {
    if (pgaId) {
      getInteractions({ variables: { search: pgaId, first: PAGE_SIZE } });
    }
    return () => {
      setInteractions([]); // Cleanup on unmount
    };
  }, [getInteractions, pgaId, setInteractions]);

  useEffect(() => {
    const newInteractions = data?.person?.interactions?.nodes || [];

    setInteractions((prevInteractions) => {
      // Don't update if we already have these interactions
      if (prevInteractions.some((prev) => newInteractions.some((next) => next.id === prev.id))) {
        return prevInteractions;
      }

      const updatedInteractions = [...prevInteractions, ...newInteractions];
      const filteredInteractions = filterInteractionsForSummary(updatedInteractions);

      // Only fetch more if we haven't already started fetching
      if (filteredInteractions.length < 5 && hasMore && !loading && !grabbedMore) {
        setGrabbedMore(true);
        fetchMore({ variables: { after: nextPage } });
      }

      return updatedInteractions;
    });
  }, [
    data?.person?.interactions?.nodes,
    hasMore,
    nextPage,
    loading,
    fetchMore,
    flagInteractionsSummary,
    grabbedMore,
    setInteractions
  ]);

  useEffect(() => {
    let isSubscribed = true;

    async function generateSummary() {
      const allInteractions = data?.person?.interactions?.nodes || [];
      const filteredInteractions = filterInteractionsForSummary(allInteractions);

      if (!filteredInteractions?.length) return;

      setShowAiSummary(true); // Show card immediately when starting to generate

      const summary = await generateInsightsSummary(filteredInteractions);
      if (!isSubscribed || !summary) {
        setShowAiSummary(false);
        setInsightSummary("");
        return;
      }

      setInsightSummary(summary.replace(/^"|"$/g, ""));
    }

    if (shouldGenerateAiSummary) {
      generateSummary();
    }

    return () => {
      isSubscribed = false;
      setShowAiSummary(false);
      setInsightSummary("");
    };
  }, [data?.person?.interactions?.nodes, generateInsightsSummary, shouldGenerateAiSummary]);

  if (error || !pgaId) {
    return <div data-testid="error">Error! {error?.message}</div>;
  }

  if (!interactions.length && loading) {
    return (
      <div className="w-[500px] mt-8">
        <Skeleton rows={10} />
      </div>
    );
  }

  if (!salesforceId && !loading) {
    return (
      <div data-testid="interactions-empty-state">
        <EmptyState
          iconName="3p"
          title="No Interactions"
          caption="UNDER CONSTRUCTION: Interactions cannot be loaded for this person at this time."
        />
      </div>
    );
  }

  if (!interactions.length && !loading) {
    return (
      <div data-testid="interactions-empty-state">
        <EmptyState
          iconName="3p"
          title="No Interactions"
          caption="There are no interactions associated with this person."
        />
      </div>
    );
  }

  return (
    <>
      {showAiSummary && <AiCard title="Insights" text={insightSummary} loading={generatingInsightsSummary} />}
      {flagInteractionsMetrics && <MetricGrid metrics={metrics} layout="balanced" />}
      <ul className="flex flex-col">
        {Object.entries(interactionsMap).map(([monthYear, interactions], i) => (
          <li key={i} data-testid={`interactions-month-group-${i}`}>
            <InteractionGroup monthYear={monthYear} interactions={interactions} pgaId={pgaId} />
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-6">
        {(loading || hasMore) && (
          <Button
            onClick={() => {
              getInteractions({ variables: { search: pgaId, first: PAGE_SIZE, after: nextPage } });
              setGrabbedMore(true);
            }}
            variant="filledTonal"
            disabled={loading}
          >
            Load More Interactions {loading && <Progress className="ml-2" />}
          </Button>
        )}
        {!loading && !hasMore && grabbedMore && <p>No more past interactions to load</p>}
      </div>
    </>
  );
};

export default function InteractionsPageContainer() {
  const { pgaId } = useParams();
  const flags = useFlags();
  const isMobile = useIsMobile();
  const useAiGeneratedCategoryEnabled = flags["backoffice-interactions-categories"];
  const [salesforceId, setSalesforceId] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [topicData, setTopicData] = useState<string[]>([]);
  const defaultInteractionDate = new Date();
  const { trackEvent } = useAmplitude();
  const { fetchData: generateSummary, loading: loadingAiSummary } = useAiQuery<SummaryPayload>(
    "career-consultation-summarizer"
  );
  const { fetchData: generateCategory, loading: generatingAiCategory } = useAiQuery<SummaryPayload>(
    "career-consultation-categorizer"
  );
  const descriptionRef = useRef<HTMLInputElement>(null);

  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      interactionMethod: "In-Person",
      interactionTopic: "",
      interactionDescription: "",
      interactionDate: { startDate: defaultInteractionDate, endDate: defaultInteractionDate },
      interactionPrivacy: false,
      interactionMemberFeedback: false
    }
  });

  const [variables, setVariables] = useState<InteractionInput>({
    interactionDate: defaultInteractionDate.toISOString().split("T")[0],
    mode: "In-Person",
    activityType: null,
    description: null,
    privacy_restricted: false,
    memberFeedback: false
  });
  const [createInteractionMutation, { loading: loadingCreateInteractionMutation }] =
    useMutation(CREATE_INTERACTION_MUTATION);
  const { data: allCategoriesData, loading: loadingCategories } = useQuery<CategoriesData>(CATEGORIES_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data) {
        setTopicData(data.interactionActivityTypes);
      }
    }
  });

  function filterOptions(string: string) {
    let filteredTopicData = allCategoriesData!.interactionActivityTypes.filter((topic) =>
      topic.toLowerCase().includes(string.toLowerCase())
    );

    setTopicData(filteredTopicData);
  }

  const asyncLoading =
    loadingCategories ||
    loadingAiSummary ||
    (useAiGeneratedCategoryEnabled && generatingAiCategory) ||
    loadingCreateInteractionMutation;

  if (!pgaId) {
    return <div data-testid="error">Error!</div>;
  }

  const navLinks = getPersonNavLinks(pgaId, flags);
  const flagInteractionsCreation = flags["backoffice-interactions-creation"];
  const flagInteractionsPrivacyToggle = flags["backoffice-interactions-privacy-toggle"];

  const handleDescriptionFocus = () => {
    if (isMobile) {
      if (descriptionRef.current) {
        const elementRect = descriptionRef.current.getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + elementRect.top - 20,
          behavior: "smooth"
        });
      }
    } else {
      null;
    }
  };

  async function createInteraction() {
    try {
      // Generate both promises here
      const summaryPromise = generateSummary({
        mode: variables.mode ?? null,
        subject: null,
        comments: variables.description ?? null
      });

      let categoryPromise = Promise.resolve("");
      if (useAiGeneratedCategoryEnabled && !variables.memberFeedback) {
        categoryPromise = generateCategory({
          mode: variables.mode ?? null,
          subject: null,
          comments: variables.description ?? null
        });
      }
      // Wait for both promises to resolve based on the flag
      const [summaryResponse, categoryResponse] = await Promise.all([summaryPromise, categoryPromise]);

      const formattedSummary = formatAiSummary(summaryResponse);
      const activityType =
        useAiGeneratedCategoryEnabled && categoryResponse
          ? guardAiResponse(categoryResponse, allCategoriesData?.interactionActivityTypes || [])
          : variables.activityType || "Member Feedback";
      const { data } = await createInteractionMutation({
        variables: {
          input: {
            participantId: salesforceId,
            interactionDate: variables.interactionDate,
            mode: variables.mode,
            activityType: activityType,
            description: variables.description,
            summary: formattedSummary,
            privacyRestricted: variables.privacy_restricted
          }
        }
      });

      if (data?.createInteraction?.success) {
        trackEvent("create interaction", {
          audience: "single",
          mode: variables.mode,
          activityType: activityType,
          description: variables.description,
          summary: formattedSummary,
          privacyRestricted: variables.privacy_restricted,
          memberFeedback: variables.memberFeedback,
          participants: [variables.participantId],
          success: Boolean(summaryResponse && categoryResponse)
        });
        let sortedInteractionsArray = [...interactions, data.createInteraction.interaction];

        sortedInteractionsArray.sort((a, b) => {
          const dateA = new Date(a.interactionDate);
          const dateB = new Date(b.interactionDate);
          if (dateA.getTime() === dateB.getTime()) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          }
          return dateB.getTime() - dateA.getTime();
        });

        setInteractions(sortedInteractionsArray);
        showSnackbar(`Interaction added`);
        setSidebarOpen(false);
        resetForm();
        resetVariables();
      } else {
        showSnackbar("There was an error creating the interaction. Please try again later.");
      }
    } catch (error) {
      showSnackbar("There was an error creating the interaction. Please try again later.");
    }
  }

  const resetForm = () => {
    setValue("interactionMethod", "In-Person");
    setValue("interactionDate", { startDate: defaultInteractionDate, endDate: defaultInteractionDate });
    setValue("interactionTopic", "");
    setValue("interactionDescription", "");
  };

  const resetVariables = () => {
    setVariables({
      interactionDate: defaultInteractionDate.toISOString().split("T")[0],
      mode: "In-Person",
      activityType: null,
      description: null,
      privacy_restricted: false,
      memberFeedback: false
    });
  };
  const dynamicIcon = asyncLoading ? <Progress size={20} color="white" /> : "check";
  const CreateInteractionActions = () => (
    <div className="flex justify-end space-x-4">
      <Button onClick={handleSubmit(createInteraction)} variant="filled" icon={dynamicIcon} disabled={asyncLoading}>
        Save
      </Button>

      <Button onClick={() => setSidebarOpen(false)} variant="outlined" disabled={asyncLoading}>
        Cancel
      </Button>
    </div>
  );

  const ButtonComp = () => (
    <>
      {flagInteractionsCreation && salesforceId && (
        <Button icon="add" onClick={() => setSidebarOpen(true)} variant="outlined" testId="add-interaction">
          Add Interaction
        </Button>
      )}
    </>
  );

  return (
    <AppLayout navLinks={navLinks} interior bottomBar>
      <PersonDetailsHeader title="Interactions" pgaId={pgaId} ButtonComp={ButtonComp} />

      <SideSheetModal
        isSidebarOpen={sidebarOpen}
        title={"Add Interaction"}
        actions={<CreateInteractionActions />}
        setSidebarOpen={setSidebarOpen}
      >
        <div className="flex flex-col h-full max-h-[100dvh] overflow-y-auto">
          <div className={`my-4 space-y-${isMobile ? 4 : 6} flex-1 flex flex-col`}>
            <Controller
              name="interactionMethod"
              control={control}
              rules={{ required: "Method is required" }}
              render={({ field, fieldState }) => (
                <Select
                  label="Method"
                  disabled={asyncLoading}
                  required={true}
                  options={allModesData}
                  value={field.value}
                  className="w-full"
                  onSelect={(e) => {
                    field.onChange(e);
                    setVariables({ ...variables, mode: e });
                  }}
                  error={fieldState.invalid}
                  errorText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name="interactionDate"
              control={control}
              rules={{
                validate: {
                  startDateNotNull: (value) => value?.startDate !== null || "Date is required",
                  startDateNotInFuture: (value) =>
                    new Date(value?.startDate) < new Date() || "Date cannot be in the future"
                }
              }}
              render={({ field, fieldState }) => (
                <DateSelect
                  disabled={asyncLoading}
                  label="Date *"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    const dateRange = e as DateRangeType;
                    setVariables({
                      ...variables,
                      interactionDate: dateRange.startDate
                    });
                  }}
                  maxDate={new Date()}
                  useSingleDate
                  showFooter={false}
                  error={fieldState.invalid}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
            {!loadingCategories && !useAiGeneratedCategoryEnabled && (
              <Controller
                name="interactionTopic"
                control={control}
                rules={{ required: "Topic is required" }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <Autocomplete
                    label="Topic"
                    disabled={asyncLoading}
                    required
                    query={value}
                    data={topicData}
                    variant="searchableDropdown"
                    onChangeText={(string) => {
                      onChange(string);
                      filterOptions(string);
                    }}
                    reset={() => {
                      onChange("");
                      setVariables({ ...variables, activityType: null });
                    }}
                    onSelect={(value) => {
                      onChange(value);
                      setVariables({ ...variables, activityType: value });
                    }}
                    renderItem={(data) => <div>{data}</div>}
                    error={fieldState.invalid}
                    errorText={fieldState.error?.message}
                  />
                )}
              />
            )}

            <div className="flex-1 flex flex-col min-h-0">
              <Controller
                name="interactionDescription"
                control={control}
                rules={{ required: "Description is required" }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <TextField
                    value={value}
                    disabled={asyncLoading}
                    onChangeText={(text) => {
                      onChange(text);
                      text.trim() === ""
                        ? setVariables({ ...variables, description: null })
                        : setVariables({ ...variables, description: text });
                    }}
                    required
                    label="Description"
                    type="textarea"
                    className="w-full h-full"
                    error={fieldState.invalid}
                    errorText={fieldState.error?.message}
                    ref={descriptionRef}
                    onFocus={handleDescriptionFocus}
                  />
                )}
              />
            </div>
            {flagInteractionsPrivacyToggle && (
              <Controller
                name="interactionPrivacy"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={`flex space-x-4 ${asyncLoading ? "opacity-50" : ""}`}>
                    <div className="h-fit my-auto">
                      <Switch
                        disabled={asyncLoading}
                        onChange={() => {
                          onChange(!value);
                          setVariables({ ...variables, privacy_restricted: !value });
                        }}
                      />
                    </div>

                    <div>
                      <div className="text-body-large text-sys-brand-on-surface">Make Private</div>
                      <div className="text-body-medium text-sys-brand-on-surface-variant">
                        Only your department can view
                      </div>
                    </div>
                  </div>
                )}
              />
            )}
            <Controller
              name="interactionMemberFeedback"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={`flex space-x-4 ${asyncLoading ? "opacity-50" : ""}`}>
                  <div className="h-fit my-auto">
                    <Switch
                      disabled={asyncLoading}
                      onChange={() => {
                        onChange(!value);
                        setVariables({ ...variables, memberFeedback: !value });
                      }}
                    />
                  </div>

                  <div className="">
                    <div className="text-body-large text-sys-brand-on-surface">Set as Member Feedback</div>
                    <div className="text-body-medium text-sys-brand-on-surface-variant">
                      Interaction will be reported as feedback
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </SideSheetModal>
      <TabLayout>
        <InteractionsPage
          setSalesforceId={setSalesforceId}
          interactions={interactions}
          setInteractions={setInteractions}
        />
      </TabLayout>
    </AppLayout>
  );
}
