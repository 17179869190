import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_VARIANTS } from "@/pages/events/queries";
import Select from "@/components/Select";
import { ProductsFormValues } from "../types";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";

interface Variant {
  id: string;
  name: string;
  priceCents: string;
}

interface VariantOption extends Variant {
  value: string;
  displayText: string;
}

interface VariantsDropdownProps {
  index: number;
  productId: string;
  disabled?: boolean;
}

const VariantsDropdown = ({ index, productId, disabled }: VariantsDropdownProps) => {
  const {
    control,
    watch,
    clearErrors,
    trigger,
    setValue,
    formState: { errors }
  } = useFormContext<ProductsFormValues>();

  const setVariant = (variant: VariantOption) => {
    setValue(`products.${index}.variantId`, variant.id);
    setValue(`products.${index}.variantName`, variant.name);
    setValue(`products.${index}.variantPrice`, parseInt(variant.priceCents));
  };

  const { data: variantData } = useQuery(GET_PRODUCT_VARIANTS, {
    variables: { productId },
    onCompleted: (data) => {
      const variants = data?.commerceProductVariants?.nodes || [];
      const currentVariantId = watch(`products.${index}.variantId`);

      if (variants.length > 0 && !currentVariantId) {
        setVariant(variants[0]);
        trigger(`products.${index}.variantId`);
      }
    }
  });

  const variantOptions =
    variantData?.commerceProductVariants.nodes.map(
      (variant: Variant): VariantOption => ({
        id: variant.id,
        value: variant.id,
        displayText: centsToDollarsFormatted(parseInt(variant.priceCents)) ?? "",
        name: variant.name,
        priceCents: variant.priceCents
      })
    ) || [];

  return (
    <Controller
      name={`products.${index}.variantId`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          label="Price"
          options={variantOptions}
          className="w-full"
          value={value}
          disabled={disabled}
          onSelect={(value) => {
            onChange(value);
            const selectedVariant = variantOptions.find((option: VariantOption) => option.value === value);
            setVariant(selectedVariant || variantOptions[0]);

            if (value && errors.products?.[index]?.variantId) {
              clearErrors(`products.${index}.variantId`);
            }
            if (!value) {
              trigger(`products.${index}.variantId`);
            }
          }}
          required
          data-testid={`variant-select-${index}`}
          error={!!errors.products?.[index]?.variantId}
          errorText={errors.products?.[index]?.variantId?.message as string}
        />
      )}
    />
  );
};

export default VariantsDropdown;
