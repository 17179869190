import { PageInfo } from "@/components/Pagination";
import { TypedDocumentNode, gql } from "@apollo/client";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { OrderNode } from "@/pages/billing/types";

type Query = {
  currentDuesCycle: {
    totalOrders: {
      totalCount: number;
    };
    totalOrdersWithFailedPayments: {
      totalCount: number;
    };
    orders: {
      totalCount: number;
      nodes: OrderNode[];
      pageInfo: PageInfo;
    };
  };
};

export const ORDERS_QUERY: TypedDocumentNode<Query> = gql`
  query GetCurrentDuesCycle(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $availableForTerminations: Boolean
    $availableForSuspensions: Boolean
    $query: String
    $dueDateRange: DateRangeInput
    $ids: [String!]
    $balanceRange: MoneyCentsRangeInput
    $sectionIds: [String!]
    $memberClassifications: [String!]
    $facilityIds: [String!]
    $statuses: [CommerceOrderStatus!]
    $paymentStatuses: [CommerceOrderPaymentStatus!]
  ) {
    currentDuesCycle {
      id
      startsOn
      endsOn
      dueDate
      totalOrders: orders(
        filters: {
          availableForTerminations: $availableForTerminations
          availableForSuspensions: $availableForSuspensions
        }
      ) {
        totalCount
      }
      totalOrdersWithFailedPayments: orders(
        filters: {
          availableForTerminations: $availableForTerminations
          availableForSuspensions: $availableForSuspensions
          paymentStatuses: [HAS_FAILED_PAYMENTS]
        }
      ) {
        totalCount
      }
      orders(
        first: $first
        last: $last
        after: $after
        before: $before
        filters: {
          availableForTerminations: $availableForTerminations
          availableForSuspensions: $availableForSuspensions
          search: $query
          dueDateRange: $dueDateRange
          ids: $ids
          balanceRange: $balanceRange
          sectionIds: $sectionIds
          memberClassifications: $memberClassifications
          facilityIds: $facilityIds
          statuses: $statuses
          paymentStatuses: $paymentStatuses
        }
      ) {
        nodes {
          id
          stage
          balance
          dueDate
          payable
          updatedAt
          description
          status
          paymentStatus
          person {
            id
            firstName
            lastName
            profilePhoto
            pgaId
            classification
            primarySectionAffiliation {
              id
              section {
                id
                name
              }
            }
            mainProgramType {
              id
              name
            }
          }
          lineItems {
            id
          }
        }
        ...PageInfo
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;

export const CHANGE_DUE_DATES_MUTATION = gql`
  mutation ChangeDueDates($input: CommerceBulkChangeOrderDueDateInput!) {
    commerceBulkChangeOrderDueDate(input: $input) {
      successfulOrders {
        id
        dueDate
      }
      failedOrders {
        id
        dueDate
      }
      message
      success
    }
  }
`;

export const TERMINATE_PROGRAMS_MUTATION = gql`
  mutation terminatePrograms($input: BackofficeDuesBulkTerminateProgramsInput!) {
    duesBulkTerminatePrograms(input: $input) {
      successfulPersonIds
      failedPersonIds
      message
      success
    }
  }
`;

export const SUSPEND_PROGRAMS_MUTATION = gql`
  mutation suspendPrograms($input: BackofficeDuesBulkSuspendProgramsInput!) {
    duesBulkSuspendPrograms(input: $input) {
      successfulPersonIds
      failedPersonIds
      message
      success
    }
  }
`;
