import { useState, useEffect } from "react";
import { ColumnSort } from "@tanstack/react-table";
import { useOutletContext } from "react-router-dom";
import usePageTitle from "@/hooks/usePageTitle";
import Table from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { DuesRatesRow, Variables, DuesRatesData, ContextType } from "./types";
import { DuesRates, PreviousPricing } from "@/types";
import { useQuery } from "@apollo/client";
import { GET_DUES_RATES_QUERY } from "./queries";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import { sortBy, orderBy as orderByLodash } from "lodash";
import Tooltip from "@/components/Tooltip";
import { Controller } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { useSearch } from "@/hooks/useSearch";
import { Updater, SortingState } from "@tanstack/table-core";

const getPriceByYear = (previousPricing: PreviousPricing[], year: string) => {
  return centsToDollarsFormatted(previousPricing?.filter((p) => p.year === year)?.[0]?.priceCents);
};

const formatTableData = (data: DuesRates[]) => {
  return data?.map(({ id, classification, classificationDescription, updatedAt, priceCents, previousPricing }) => ({
    id,
    classification,
    classificationDescription,
    rate_2025: centsToDollarsFormatted(priceCents),
    rate_2024: getPriceByYear(previousPricing, "2024"),
    rate_2023: getPriceByYear(previousPricing, "2023"),
    note: null,
    updatedAt: toFormat(updatedAt),
    classificationNumber: Number(classification?.split("-")?.[1]),
    classificationLetter: classification?.split("-")?.[0]
  }));
};

export default function SectionalDuesRatesPage() {
  usePageTitle("/sections/:sectionId/billing/dues-rates", "Sections | Billing | Dues Rates");
  const { legacyId } = useOutletContext<ContextType>();
  const defaultSort = [{ id: "classification", desc: false }];
  const [orderBy, setOrderBy] = useState<ColumnSort[]>(defaultSort);
  const { searchValue, control } = useSearch("name");
  const defaultVariables = { kind: `section-${legacyId}` };
  const [variables, setVariables] = useState<Variables>(defaultVariables);
  const { data, loading } = useQuery<DuesRatesData>(GET_DUES_RATES_QUERY, {
    variables: {
      filters: {
        ...variables
      }
    },
    skip: !legacyId
  });

  const tableData: DuesRatesRow[] = sortBy(
    orderByLodash(formatTableData(data?.duesRates?.nodes || []), "classificationNumber"),
    (item) => [item.classificationLetter]
  );
  const currentYear = new Date().getFullYear();

  const handleSort = (updaterOrValue: Updater<SortingState>) => {
    const sortState = typeof updaterOrValue === "function" ? updaterOrValue([]) : updaterOrValue;
    const { id, desc } = sortState[0] || {};
    setOrderBy([{ id, desc }]);
  };
  useEffect(() => {
    setVariables((prev) => ({
      ...(searchValue ? { ...prev, search: searchValue } : { ...defaultVariables })
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  return (
    <>
      <div className="pt-8 px-4 pb-6 justify-between tablet:flex">
        <div className="max-w-[1000px] pr-5">
          <p className="text-title-medium mb-1">Annual Dues</p>
          <p className="text-body-medium">
            Please set the section’s annual dues rates below for upcoming member year July 1, {currentYear} - June 30,{" "}
            {currentYear + 1}. Rates have been defaulted to the current year’s rates for each classification. The
            deadline to Finalize Rates is Mar 21, {currentYear}. Invoices will be sent to members/associates/affiliates
            on or after May 1, {currentYear}.
          </p>
        </div>
      </div>
      <Table
        data={tableData}
        loading={loading}
        onSortingChange={handleSort}
        sortingState={orderBy}
        columns={[
          {
            header: "Classification",
            accessorKey: "classification",
            enableSorting: true,
            cell: ({ row }) => {
              return (
                <Tooltip
                  variant="plain"
                  supportingText={row.original.classificationDescription}
                  color="black"
                  menuCorner="end-start"
                  anchorCorner="start-start"
                >
                  {row.original.classification}
                </Tooltip>
              );
            },
            meta: {
              className: getStickyColumnClasses(!!tableData?.length)
            }
          },
          {
            header: "2025 Rate",
            accessorKey: "rate_2025",
            enableSorting: false
          },
          {
            header: "2024 Rate",
            accessorKey: "rate_2024",
            enableSorting: false
          },
          {
            header: "2023 Rate",
            accessorKey: "rate_2023",
            enableSorting: false
          },
          {
            header: "Additional Information",
            accessorKey: "note",
            enableSorting: false,
            size: 450
          },
          {
            header: "Modified",
            accessorKey: "updatedAt",
            enableSorting: false
          }
        ]}
        renderHeader={() => (
          <div className="flex md:items-center flex-col gap-3 md:flex-row">
            <div className="w-full sm:max-w-[320px]">
              <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchInput
                    placeholder="Search Classifications"
                    query={value}
                    param="Classifications"
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        )}
        renderEmptyState={() => (
          <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="request_quote" />
        )}
      />
    </>
  );
}
