export enum ChangeType {
  change_employment = "change_employment",
  start_new_employment = "start_new_employment",
  change_employment_unlisted = "change_employment_to_unlisted",
  start_new_unlisted_employment = "start_new_unlisted_employment",
  retire = "retire"
}

export enum ApprovalType {
  national = "national",
  manual = "manual",
  automatic = "automatic"
}

export type ChangeSetting = {
  id: string;
  changeType: ChangeType;
  approvalType: ApprovalType;
};

const changeTypeDisplayNames = {
  [ChangeType.change_employment]: "Change Employment",
  [ChangeType.start_new_employment]: "Start New Employment",
  [ChangeType.change_employment_unlisted]: "Change Employment to Unlisted",
  [ChangeType.start_new_unlisted_employment]: "Start New Unlisted Employment",
  [ChangeType.retire]: "Retire"
};

export const changeTypeToDisplayName = (changeType: ChangeType) => changeTypeDisplayNames[changeType];

const approvalTypeDisplayNames = {
  [ApprovalType.national]: "National",
  [ApprovalType.manual]: "Manual",
  [ApprovalType.automatic]: "Automatic"
};

export const approvalTypeToDisplayName = (approvalType: ApprovalType) => approvalTypeDisplayNames[approvalType];
