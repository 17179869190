import { FlagRecord } from "@/hooks/useFeatureFlags";
import { NavLink } from "@/layouts/AppLayout/Sidebar/Sidebar";
import { DOMAIN } from "@/providers/AuthorizationProvider";
import { Path } from "@/router";

export const getSectionNavLinks: (
  sectionId: string,
  flags: FlagRecord,
  hasJobPermissions: boolean
) => Record<string, NavLink<Path>[]> = (sectionId, flags, hasJobPermissions) => {
  // for the lower-cased component name
  const flagJobPostList = flags["backoffice-job-post-section-list"];
  const flagDuesRates = flags["backoffice-dues-rates"];

  const sectionLinks = [
    flagJobPostList &&
      hasJobPermissions && {
        domain: DOMAIN.SECTIONS,
        label: "Jobs",
        link: {
          to: "/sections/:sectionId/jobs",
          params: { sectionId }
        },
        icon: "business_center"
      },
    {
      domain: DOMAIN.SECTIONS,
      label: "People",
      link: {
        to: "/sections/:sectionId/change-requests",
        params: { sectionId }
      },
      icon: "group"
    },
    flagDuesRates && {
      domain: DOMAIN.SECTIONS,
      label: "Billing",
      link: {
        to: "/sections/:sectionId/billing/",
        params: { sectionId }
      },
      icon: "card_membership"
    }
  ].filter(Boolean) as NavLink<Path>[];

  return {
    "Manage Section": sectionLinks
  };
};
