import { PropsWithChildren, ReactNode } from "react";
import MenuFilters from "./MenuFilters";

export type ItemProps = {
  component: ReactNode;
  onlyMobile?: boolean;
};

export type ExpandedFiltersProps = {
  items: ItemProps[];
  activeFilters?: number;
};

const Content = ({ items }: { items: ItemProps[] }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6 min-w-[calc(100vw-3rem)] sm:min-w-[420px] md:min-w-[620px] sm:pb-10">
      {items?.map((item: ItemProps, i: number) => (
        <div className={`${item.onlyMobile ? "block sm:hidden" : ""} w-full`} key={i}>
          {item.component}
        </div>
      ))}
    </div>
  );
};

export default function ExpandedFilters(props: PropsWithChildren<ExpandedFiltersProps>) {
  const { items, activeFilters } = props;
  return <MenuFilters content={<Content items={items} />} activeFilters={activeFilters} />;
}
