import { useRef, useState } from "react";
import Button from "../Button";
import Progress from "../Progress";

export interface FileUploadProps {
  overline?: string;
  label?: string;
  subtitle?: string;
  buttonText?: string;
  handleUpload(file: File): void;
  accept?: string;
  loading?: boolean;
  errorMsg?: string;
}

function parseStrToArray(str: string): Array<string> {
  try {
    const arr = JSON.parse(str);
    return arr;
  } catch (e) {
    return [str];
  }
}

type ErrorProps = { message: string };

const ErrorMessage = ({ message }: ErrorProps) => (
  <p className="text-sys-brand-error text-body-medium pt-1">{message}</p>
);

const ErrorMessages = ({ message }: ErrorProps) => {
  const array = parseStrToArray(message);

  return array.map((errorMsg: string, i) => <ErrorMessage key={i} message={errorMsg} />);
};

export default function FileUpload({
  overline = "",
  label = "Choose a file to upload",
  subtitle = "",
  buttonText = "Upload",
  handleUpload,
  accept,
  loading,
  errorMsg
}: FileUploadProps) {
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const handleClick = () => hiddenInputRef?.current?.click();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!event.target?.files?.length) {
      const file = event?.target.files[0];
      setUploadedFileName(file.name);
      handleUpload(file);
    }
  };

  return (
    <div className="w-full">
      <span
        className={`border border-solid cursor-pointer w-full rounded px-4 py-2 flex flex-row justify-between items-center ${
          !!errorMsg ? "border-sys-brand-error" : "border-sys-brand-outline"
        }`}
        onClick={handleClick}
      >
        <div>
          {!!overline && <p className="text-sys-brand-on-surface-variant text-label-medium">{overline}</p>}
          <p className="text-sys-brand-on-surface text-body-large">{uploadedFileName ? uploadedFileName : label}</p>
          {!!subtitle && <p className="text-sys-brand-on-surface-variant text-body-medium">{subtitle}</p>}
        </div>
        {loading ? (
          <Progress />
        ) : (
          <Button variant="filledTonal" icon="upload">
            {buttonText}
          </Button>
        )}
      </span>
      <input
        className="hidden"
        type="file"
        ref={hiddenInputRef}
        onChange={handleChange}
        data-testid="file-upload"
        accept={accept}
      />
      {!!errorMsg && <ErrorMessages message={errorMsg} />}
    </div>
  );
}
