import ObjectHeading from "@/components/ObjectHeading";
import { ProfileSkeleton } from "@/components/Skeleton";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import type { Params, Path } from "@/router";
import { useQuery } from "@apollo/client";
import { LinkProps } from "@generouted/react-router/client";
import { PERSON_DETAILS_HEADER_QUERY } from "../queries";
import { Classification, Person } from "@/types";
import { capitalize } from "lodash";
import { getProgramStatus, getClassification, getPrimarySectionName } from "@/pages/people/_utils";
import { useReturnTo } from "@/hooks/useSearchParams";
import PageTitle from "@/components/PageTitle";
import { useLocation } from "react-router-dom";

type ObjectHeadingWrapper = {
  pgaId: string;
  ButtonComp?: () => JSX.Element;
  isDetailsViewDesktop?: boolean;
};

type PersonData = { person: Person };

const createProgramInfo = (latestProgram?: Person["latestProgram"]) => {
  if (!latestProgram) return [];
  const { programType } = latestProgram;

  return [{ text: `Program: ${programType.name}` }];
};

const createSectionInfo = (person: Person) => {
  const primarySectionName = getPrimarySectionName(person);
  if (!primarySectionName) return [];
  return [{ text: `Section: ${primarySectionName}` }];
};

const createClassificationInfo = (classification?: Classification) => {
  if (!classification) return [];
  const { name, description } = classification;

  return [
    {
      text: `Class: ${name}`,
      tooltipText: description
    }
  ];
};

const createStatusInfo = (status?: string) => (status ? [{ text: `Status: ${capitalize(status)}` }] : []);

export const ObjectHeadingWrapper = ({ pgaId, ButtonComp, isDetailsViewDesktop }: ObjectHeadingWrapper) => {
  const { data, loading, error } = useQuery<PersonData>(PERSON_DETAILS_HEADER_QUERY, {
    variables: { pgaId }
  });
  const location = useLocation();
  if (loading) return <ProfileSkeleton />;
  if (error) return <div>Error: {error.message}</div>;

  const { person } = data!;
  const { profilePhoto, displayName, informalName, latestProgram } = person;

  const subText = [
    { text: `PGA ID: ${pgaId}` },
    ...createProgramInfo(latestProgram),
    ...createSectionInfo(person),
    ...createClassificationInfo(getClassification(person)),
    ...createStatusInfo(getProgramStatus(person))
  ];

  const [firstName, ...lastNameAndSuffix] = displayName.split(" ");
  const title =
    informalName && informalName !== firstName
      ? `${firstName} (${informalName}) ${lastNameAndSuffix.join(" ")}`
      : displayName;

  return (
    <>
      <PageTitle
        pattern={location.pathname}
        title={`People | Person Details | ${capitalize(location.pathname.split("/").pop())} | ${title}`}
      />
      <ObjectHeading
        title={title}
        imageUrl={profilePhoto}
        iconName="person"
        ButtonComp={ButtonComp}
        isDetailsView
        subText={subText}
        isDetailsViewDesktop={isDetailsViewDesktop}
      />
    </>
  );
};

type Props = {
  pgaId: string;
  title?: string;
  ButtonComp?: () => JSX.Element;
};

export function PersonDetailsHeader({ pgaId, title, ButtonComp }: Props) {
  const returnTo = useReturnTo();
  const backLinkTo = returnTo || "/people";

  return (
    <div data-testid="person-details-header">
      <div className="sm:pb-6">
        <PageHeading
          action={{
            iconName: "quick_reference_all",
            title: "Audit Log",
            link: { to: "/people/:pgaId/audit-log", params: { pgaId } }
          }}
          title={title || "Details"}
          backLink={{ to: backLinkTo } as LinkProps<Path, Params>}
        />
      </div>
      <ObjectHeadingWrapper pgaId={pgaId} ButtonComp={ButtonComp} />
    </div>
  );
}
