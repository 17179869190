import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import Button from "@/components/Button";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import Select from "@/components/Select";
import { useSearch } from "@/hooks/useSearch";
import { Person } from "@/types";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GET_PAYORS, GET_TEMPLATES } from "../queries";

interface QueryData {
  people: {
    nodes: Person[];
  };

  commerceOrderTemplates: TemplateData[];

  commerceOrders: {
    nodes: {
      id: string;
      status: string;
      type: string;
    }[];
  };
}

interface TemplateData {
  id: string;
  name: string;
  type: string;
  lookupKey: string;
}

export default function CreateOrderDialog({
  onDismiss,
  onCreateDraft,
  loading,
  effectiveDate,
  setSelectedTemplate,
  personName,
  setPersonId,
  orderControl,
  selectedTemplate
}: {
  onDismiss: () => void;
  onCreateDraft: () => void;
  loading: boolean;
  effectiveDate: {
    startDate: Date;
    endDate: Date;
  };
  setSelectedTemplate: ({ id, name, type, kind }: { id: string; name: string; type: string; kind: string }) => void;
  personName: string;
  setPersonId: (value: string) => void;
  orderControl: any;
  selectedTemplate: any;
}) {
  const searchField = "payor";
  const { searchValue, control, reset, setValue } = useSearch(searchField);
  const [variables, setVariables] = useState({});

  const { data } = useQuery<QueryData>(GET_PAYORS, { variables });
  const { data: templateData } = useQuery<QueryData>(GET_TEMPLATES);

  function selectTemplate(field: any, value: string) {
    field.onChange(value);

    templateOptions.forEach((template) => {
      if (template.value === value) {
        setSelectedTemplate({
          id: template.value,
          name: template.displayText,
          type: template.type,
          kind: template.kind
        });
      }
    });
  }

  function selectPayor(name: string, value: Person, setValue: (name: string, value: string) => void) {
    setPersonId(value.id);
    setValue(name, `${value.firstName} ${value.lastName}`);
  }

  useEffect(() => {
    setVariables(() => ({
      ...(searchValue ? { input: { search: searchValue } } : {})
    }));
  }, [searchValue]);

  const templateOptions =
    templateData?.commerceOrderTemplates.map((template) => ({
      value: template.id,
      displayText: template.name,
      type: template.type,
      kind: template.lookupKey.split("-").pop() || ""
    })) || [];

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>
      <Button onClick={onCreateDraft} disabled={loading}>
        Create Draft
      </Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline="Create Order" displayOverflow>
      <div className="w-[275px] tablet:w-[375px] space-y-6">
        <p>
          Select the {!personName ? "payor and" : ""} template of the order you would like to create. You will have a
          chance to review the order before sending it.
        </p>
        {!personName && (
          <Controller
            name="payor"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Autocomplete
                label="Payor"
                required
                query={value}
                data={data?.people?.nodes || []}
                onChangeText={onChange}
                reset={reset}
                onSelect={(value) => selectPayor(name, value, setValue)}
                renderItem={(data) => (
                  <AvatarDetails
                    picture={data.profilePhoto}
                    text={data.pgaId || ""}
                    title={`${data.firstName} ${data.lastName}`}
                  />
                )}
              />
            )}
          />
        )}
        <Controller
          name="templateId"
          control={orderControl}
          render={({ field }) => (
            <Select
              className="w-full"
              label="Order Template"
              required
              onSelect={(value) => selectTemplate(field, value)}
              options={templateOptions}
            />
          )}
        />

        <Controller
          name="effectiveDate"
          control={orderControl}
          render={({ field }) => (
            <DateSelect
              label="Effective Date*"
              value={effectiveDate || { startDate: new Date(), endDate: new Date() }}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              supportingText="Date of payor's classification to use in the order"
              showFooter={false}
              minDate={
                selectedTemplate.name.includes("Dues - Member Year 2024-2025") ? new Date("2024-05-01") : undefined
              }
              maxDate={
                selectedTemplate.name.includes("Dues - Member Year 2024-2025") ? new Date("2025-06-30") : undefined
              }
            />
          )}
        />
      </div>
    </Dialog>
  );
}
