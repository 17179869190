export const getVimeoVideoDuration = (videoId: string) => {
  return fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`)
    .then((response) => response.json())
    .then((data) => {
      const durationInSeconds = data.duration;
      return durationInSeconds;
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const assessmentQuestionFrame = [
  {
    id: 1,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 2,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 3,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 4,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 5,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 6,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 7,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 8,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 9,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 10,
    content: undefined,
    options: [
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false },
      { content: undefined, answer: false }
    ],
    questionType: "multiple_choice"
  }
];

export const seedAssessmentQuestions = [
  {
    id: 1,
    content: "What is the primary purpose of the PDR video project?",
    options: [
      {
        content:
          "To create a video player for Members to watch video content and answer assessment questions for PDR credits.",
        answer: true
      },
      { content: "To create a new membership portal.", answer: false },
      { content: "To update the existing ColdFusion interface.", answer: false },
      { content: "To provide a new communication tool for Members.", answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 2,
    content: "The current video player is built on a ColdFusion interface.",
    options: [
      { content: "True", answer: true },
      { content: "False", answer: false }
    ],
    questionType: "true_false"
  },
  {
    id: 3,
    content: "The PDR video project aims to increase mobile app usage.",
    options: [
      { content: "True", answer: true },
      { content: "False", answer: false }
    ],
    questionType: "true_false"
  },
  {
    id: 4,
    content: "What are the main goals and objectives of the PDR video project?",
    options: [
      {
        content:
          "Automate manual processes, enhance Member experience, improve efficiency, increase mobile app usage, and provide self-service opportunities.",
        answer: true
      },
      { content: "Increase membership fees, provide new tools for staff, and update the website.", answer: false },
      {
        content: "Create a new video library, enhance video quality, and provide new assessment tools.",
        answer: false
      },
      {
        content: "Improve communication, provide new training materials, and update the membership database.",
        answer: false
      }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 5,
    content: "Who are the key stakeholders involved in the PDR video project?",
    options: [
      {
        content: "Liz Elliott, Carrie McDonald, Kyle Helms, Tom Brawley, and Membership Representatives.",
        answer: true
      },
      { content: "John Doe, Jane Smith, Bob Johnson, and Alice Davis.", answer: false },
      { content: "Michael Brown, Sarah Wilson, David Lee, and Emily Clark.", answer: false },
      { content: "Chris Martin, Laura Scott, Kevin White, and Rachel Green.", answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 6,
    content: "What are the assumptions regarding eligibility for PDRs?",
    options: [
      {
        content:
          "Associates who have completed all education requirements and earned 28 experience credits, except those in specific classifications like A-3, A-5, A-25, Life Member-Retired, Life Member-Century, and Retired Member.",
        answer: true
      },
      { content: "All Members are eligible for PDRs regardless of their status.", answer: false },
      { content: "Only new Members are eligible for PDRs.", answer: false },
      { content: "Only senior Members are eligible for PDRs.", answer: false }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 7,
    content: "The Member Interface for the PDR video project will be accessible both online and via the Member App.",
    options: [
      { content: "True", answer: true },
      { content: "False", answer: false }
    ],
    questionType: "true_false"
  },
  {
    id: 8,
    content: "What are the business rules for viewing content and attempting assessments?",
    options: [
      {
        content:
          "Members must view the video in its entirety without fast-forwarding, can rewind, limited to two assessment attempts, must obtain a passing score of 70% or higher, and can only receive PDRs for a specific video and assessment once.",
        answer: true
      },
      {
        content:
          "Members can fast-forward and rewind, unlimited assessment attempts, no passing score required, and can receive PDRs multiple times for the same video.",
        answer: false
      },
      {
        content:
          "Members must view the video in its entirety, no rewinding allowed, limited to one assessment attempt, must obtain a passing score of 80% or higher, and can receive PDRs multiple times for the same video.",
        answer: false
      },
      {
        content:
          "Members can fast-forward, no rewinding allowed, limited to three assessment attempts, must obtain a passing score of 60% or higher, and can only receive PDRs for a specific video and assessment once.",
        answer: false
      }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 9,
    content: "What are the key features of the Backoffice interface for the PDR video project?",
    options: [
      {
        content:
          "Intuitive and modern design, ability to define video content via Vimeo link, create assessment questions and answers, define assessment questions in draft mode, assign PDR 'bucket' per assessment, and assign mandatory expiration dates for PDR opportunities.",
        answer: true
      },
      {
        content: "Basic design, limited video content options, no assessment creation tools, and no draft mode.",
        answer: false
      },
      {
        content:
          "Outdated design, ability to define video content via YouTube link, create assessment questions only, no draft mode, and no expiration dates.",
        answer: false
      },
      {
        content:
          "Modern design, ability to define video content via Vimeo link, create assessment questions only, no draft mode, and no expiration dates.",
        answer: false
      }
    ],
    questionType: "multiple_choice"
  },
  {
    id: 10,
    content: "What are the key milestones and timeline for the PDR video project?",
    options: [
      {
        content: "Phase 1: Requirements Gathering & Design, Phase 2: Development, Phase 3: Testing & Deployment.",
        answer: true
      },
      { content: "Phase 1: Design, Phase 2: Development, Phase 3: Testing.", answer: false },
      { content: "Phase 1: Requirements Gathering, Phase 2: Development, Phase 3: Deployment.", answer: false },
      { content: "Phase 1: Design, Phase 2: Testing, Phase 3: Deployment.", answer: false }
    ],
    questionType: "multiple_choice"
  }
];
