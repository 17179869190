import { useState, useEffect } from "react";
import { Path, useNavigate } from "@/router";
import { PropsWithChildren } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { LitPrimaryTab } from "./LitTabs";
import Icon from "../Icon";
import { MaterialNames } from "@/components/material_symbol_names";
import { DOMAIN } from "@/hooks/useCapabilities";

export interface TabsProps {
  className?: string;
  tabItems?: {
    domain: DOMAIN;
    label: string;
    onClick?(): void;
    link: any;
    icon: MaterialNames;
    active?: boolean;
  }[];
  domains: DOMAIN[];
}

const isMenuActive = (currentPath: string, linkTo: Path, active?: boolean) => {
  if (!linkTo || typeof active === "boolean") {
    return true;
  }

  let path = currentPath;

  while (path && path !== "/") {
    if (matchPath(linkTo, path)) {
      return true;
    }

    path = path.split("/").slice(0, -1).join("/");
  }

  return false;
};

const customStyles = {
  "--md-primary-tab-active-indicator-color": "transparent",
  "--_with-icon-and-label-text-container-height": "auto",
  "--md-primary-tab-pressed-state-layer-opacity": "0",
  "--md-ripple-hover-opacity": "0",
  "--_container-color": "transparent"
} as React.CSSProperties;

function getScrollDirection(setIsScrollDown: (isScrollDown: boolean) => void) {
  const threshold = 0;
  let lastScrollY = window.scrollY;
  let ticking = false;

  const updateScrollDir = () => {
    const scrollY = window.scrollY;

    if (Math.abs(scrollY - lastScrollY) < threshold) {
      ticking = false;
      return;
    }
    setIsScrollDown(scrollY > lastScrollY ? true : false);
    lastScrollY = scrollY > 0 ? scrollY : 0;
    ticking = false;
  };

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(updateScrollDir);
      ticking = true;
    }
  };

  window.addEventListener("scroll", onScroll);

  return () => window.removeEventListener("scroll", onScroll);
}

export default function BottomTabs({ className, tabItems, domains }: PropsWithChildren<TabsProps>) {
  const permittedLinks = tabItems?.filter(
    (navLink) => navLink.domain === DOMAIN.EVERYONE || domains.includes(navLink.domain)
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [isScrollDown, setIsScrollDown] = useState(false);

  useEffect(() => {
    getScrollDirection(setIsScrollDown);
  }, [isScrollDown]);

  return (
    <div
      className={`bg-sys-brand-surface-container fixed bottom-0 left-0 z-50 w-full flex flex-col justify-between  duration-700 ease-in-out transition-transform translate-y-0 ${className} ${
        isScrollDown ? "translate-y-[108px]" : ""
      }`}
    >
      <div className="grid grid-flow-col auto-cols-auto justify-center items-baseline px-2">
        {permittedLinks?.map(({ label, link, icon, active }, i) => {
          const isActive = isMenuActive(location.pathname, link.to, active);
          const formattedLabel = label.replace(/ and /g, " & ");
          return (
            <LitPrimaryTab
              key={i}
              active={isActive}
              onClick={() => navigate(link.to, { params: link.params })}
              data-testid="tab"
              hasIcon={true}
              style={customStyles}
            >
              <div
                className={`h-[32px] w-[64px] py-2 mt-3 mb-1  flex items-center justify-center rounded-full ${
                  isActive ? "bg-sys-brand-secondary-container" : ""
                }`}
              >
                <Icon name={icon as MaterialNames} size={24} className="text-sys-brand-on-surface-variant" />
              </div>
              <div className="text-sys-brand-on-surface-variant text-label-medium whitespace-normal text-center mb-3.5">
                {formattedLabel}
              </div>
            </LitPrimaryTab>
          );
        })}
      </div>
    </div>
  );
}
