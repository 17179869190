import { convertToDateTime } from "../utils";
import { z } from "zod";
import type { FormValues } from "./schema";

type SessionData = {
  startTime: string;
  endTime: string;
};

export const validateSessionTimes = (data: SessionData): boolean => {
  const start = convertToDateTime(data.startTime);
  const end = convertToDateTime(data.endTime);

  if (!start || !end) {
    return false;
  }

  return end > start;
};

export const validateOnsiteFields = (data: FormValues, ctx: z.RefinementCtx): void => {
  if (!data.address1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Street Address is required",
      path: ["address1"]
    });
  }
  if (!data.city) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "City is required",
      path: ["city"]
    });
  }
  if (!data.state) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "State is required",
      path: ["state"]
    });
  }
  if (!data.zipcode) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Zipcode is required",
      path: ["zipcode"]
    });
  }
};

export const validateFacility = (data: FormValues, ctx: z.RefinementCtx) => {
  const isOnsiteLocation = data.locationType === "onsite";
  const isFacilityInfoMissing = !data.facility || !data.facilityId;

  if (isOnsiteLocation && isFacilityInfoMissing) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Facility is required",
      path: ["facility"]
    });
  }
};

export const validateRegistrationOptions = (data: FormValues, ctx: z.RefinementCtx) => {
  const registrationOpenDate = data.registrationOpenDate.startDate;
  const registrationCloseDate = data.registrationCloseDate.startDate;

  if (registrationCloseDate < registrationOpenDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Registration close date must be after registration open date",
      path: ["registrationCloseDate"]
    });
  }

  const hasMaxAttendance = data.sessions.some(({ maxAttendance }) => !!maxAttendance);
  const waitlistType = !!data.waitlistType;

  if (hasMaxAttendance && !waitlistType) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Waitlist Type is required",
      path: ["waitlistType"]
    });
  }
};

export const validateProduct = (data: { productId: string; variantPrice: number; collection: string }) => {
  const hasNoSelection = !data.productId;
  const hasCompleteSelection = !!(data.productId && data.variantPrice && data.collection);

  return hasNoSelection || hasCompleteSelection;
};
