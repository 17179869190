import { useEffect } from "react";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useNavigate } from "@/router";
import TextField from "@/components/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { centsToDollarsFormatted, currencyFormatter } from "@/lib/currencyHelpers";
import Icon from "@/components/Icon";
import { showSnackbar } from "@/lib/snackbarUtils";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_MUTATION } from "../queries";
import { FormValues, ActionType } from "../index";
import { renderPaymentType } from "@/pages/billing/_utils";
import { PaymentMethod } from "@/pages/billing/types";
import DateSelect from "@/components/DateSelect";
import pluralize from "@/lib/pluralize";
import { NumberFormatBase } from "react-number-format";
import { sumBy } from "lodash";
import { OrderStage } from "../../../../types";
import { OrderType } from "../../types";
import { RefundablePaymentsResult } from "../index";

export function OrderPaidInfo({ title, content }: { title: string; content: string }) {
  return (
    <div className="flex p-3 items-center bg-sys-brand-secondary-container rounded-2xl">
      <div className="pl-1 pr-4 flex items-center justify-center">
        <Icon name="info" />
      </div>
      <div className="px-6 border-l border-sys-brand-outline-variant">
        <h6 className="text-title-small text-sys-brand-on-seconday-container">{title}</h6>
        <p className="text-body-medium text-sys-brand-on-seconday-container">
          {content} Changes will be reflected on all invoices and receipts.
        </p>
      </div>
    </div>
  );
}

export function SaveChangesModal({
  onDismiss,
  balance,
  order,
  setErrorContent,
  refundablePaymentsResult
}: {
  onDismiss: () => void;
  balance: number;
  order: OrderType;
  setErrorContent: (message: string) => void;
  refundablePaymentsResult: RefundablePaymentsResult[];
}) {
  const { handleSubmit, control, watch, register, setValue, trigger, clearErrors } = useFormContext<FormValues>();
  const navigate = useNavigate();

  const [updateOrderMutation, { loading: saving }] = useMutation(UPDATE_ORDER_MUTATION);

  async function cancelModal() {
    onDismiss();
  }

  const refunds = watch(`refunds`);

  const onSubmit = async (values: FormValues) => {
    const { data } = await updateOrderMutation({
      variables: {
        input: {
          orderId: order.id,
          notifyPayor: values.notifyPayor,
          lineItems: values.lineItems
            .filter((l) => l.action)
            .map(({ originalId, action }: { originalId?: string; action?: ActionType }) => ({
              id: originalId,
              action
            })),
          ...(balance > order.balance && { dueDate: values.dueDate.startDate }),
          refundAllocations: values.refunds?.map(({ amount, paymentId }: { amount: number; paymentId: string }) => ({
            amountCents: amount,
            paymentId
          }))
        }
      }
    });

    if (data?.commerceUpdateOrder?.success) {
      navigate("/billing/orders/:orderId", { params: { orderId: order.id } });
      showSnackbar("Order Updated");
    } else {
      setErrorContent(data?.commerceUpdateOrder?.message || "An error occurred while updating order.");
    }
  };

  const hasAddedProducts =
    watch("lineItems").some((l) => l.action === ActionType.ADD) ||
    sumBy(refundablePaymentsResult, "removedAmountCents") > -balance;

  const actions = (
    <div className="flex flex-col">
      <div className="flex justify-end gap-1 py-1">
        <span className="text-label-large">{balance >= 0 ? "Balance Due" : "Refund Due"}</span>
        <div className="text-body-medium w-[80px] text-right">{centsToDollarsFormatted(Math.abs(balance))}</div>
      </div>

      <div className="flex justify-end gap-2 mt-5">
        <Button variant="text" onClick={cancelModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={saving}>
          {balance < 0 ? "Refund Payor" : <>{balance > 0 && order.stage === OrderStage.PAID ? "Open" : "Save"} Order</>}
        </Button>
      </div>
    </div>
  );
  useEffect(() => {
    setValue(
      "notifyPayor",
      (order.stage === OrderStage.OPEN && balance === 0) || (order.stage === OrderStage.PAID && balance > 0)
    );
  }, [balance, order, setValue]);

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline="Save Order Changes"
      displayOverflow={order.stage === OrderStage.PAID && balance > 0}
      className={`${balance < 0 && hasAddedProducts ? "md:min-w-[750px]" : "md:min-w-[520px]"} h-fit max-h-[90vh]`}
    >
      {order.stage === OrderStage.PAID && (
        <>
          {balance === 0 && (
            <OrderPaidInfo
              title="Order will Remain Paid with No Balance Due"
              content="The updated order is at the same total as the previous version and will remain paid with no balance due."
            />
          )}

          {balance > 0 && (
            <OrderPaidInfo
              title="Order will be Opened with a Balance Due"
              content="The updated order is at a higher total than the previous version and will be opened with a
                    balance due."
            />
          )}
          {balance < 0 && (
            <OrderPaidInfo
              title={
                -balance === order.totalPaid
                  ? "Order will be Canceled with a Refund Issued"
                  : "Order will Remain Paid with a Refund Issued"
              }
              content={
                -balance === order.totalPaid
                  ? "The updated order has no remaining products from the previous version and will be marked canceled with a refund issued."
                  : "The updated order is at a lower total than the previous version and will remain paid with a refund issued."
              }
            />
          )}
        </>
      )}
      {order.stage === OrderStage.OPEN && (
        <>
          {balance > 0 && (
            <OrderPaidInfo
              title="Order will Remain Open with a Balance Due"
              content={`The updated order is at ${
                balance > order.balance
                  ? " a higher total than "
                  : balance === order.balance
                  ? " the same total as "
                  : " a lower total than "
              }
                   the previous version and will remain open with a balance due.`}
            />
          )}
          {balance === 0 && (
            <OrderPaidInfo
              title="Order will be Marked Paid with No Balance Due"
              content="The updated order is at a lower total than the previous version and will be marked paid with no
                                       balance due."
            />
          )}
          {balance < 0 && (
            <OrderPaidInfo
              title={
                -balance === order.totalPaid
                  ? "Order will be Canceled with a Refund Issued"
                  : "Order will be Marked Paid with a Refund Issued"
              }
              content={
                -balance === order.totalPaid
                  ? "The updated order has no remaining products from the previous version and will be marked canceled with a refund issued."
                  : "The updated order is at a lower total than the previous version and will be marked paid with a refund issued."
              }
            />
          )}
        </>
      )}
      {balance >= 0 ? (
        <div className="flex items-center py-2 mt-3">
          <Controller
            render={({ field }) => {
              return <Checkbox checked={field.value} onChange={field.onChange} name={`notifyPayor`} />;
            }}
            control={control}
            name={`notifyPayor`}
          />
          <div className="pl-4">
            <h6 className="text-body-large">Notify Payor of Balance</h6>
            <h6 className="text-body-medium">
              {balance === 0
                ? "Send a notification to the order payor about the paid balance"
                : "Send a notification to the order payor about the open balance due"}
            </h6>
          </div>
        </div>
      ) : (
        <>
          <h5 className="text-title-medium mt-6 mb-2">
            Payment {pluralize(refundablePaymentsResult.length, "Source")} to Refund
          </h5>
          {hasAddedProducts && refundablePaymentsResult.length > 1 && (
            <div className="text-body-medium mb-2">
              Refund allocation amounts for applied payments must equal the “Refund Due” and individually cannot exceed
              their net payment amount.
            </div>
          )}
          {refundablePaymentsResult.map((source, i: number) => {
            const { payment } = source;
            return (
              source.removedAmountCents > 0 &&
              payment && (
                <div className="flex items-start py-3 " key={i}>
                  <div className="flex justify-between w-full">
                    <div
                      className={`flex ${
                        hasAddedProducts && refundablePaymentsResult.length > 1 ? "flex-col" : ""
                      } space-x-2 w-full justify-between md:flex-row`}
                    >
                      <div className="flex space-x-2">
                        <div className="flex flex-col justify-start">
                          <Icon
                            name="monetization_on"
                            size={24}
                            className="flex mr-2 text-sys-brand-on-surface-variant"
                          />
                        </div>
                        <div className="flex flex-col items-start md:w-[410px]">
                          <div className="text-label-medium text-sys-brand-on-surface-variant">
                            {hasAddedProducts && refundablePaymentsResult.length > 1
                              ? `Net Payment: ${centsToDollarsFormatted(source.removedAmountCents)}`
                              : payment.type === PaymentMethod.FINANCIAL_ADJUSTMENT
                              ? "Financial Adjustment"
                              : "Payment"}
                          </div>
                          <div className="text-body-large text-sys-brand-on-surface word-break">
                            {payment.type === PaymentMethod.FINANCIAL_ADJUSTMENT
                              ? payment.payer.name
                              : renderPaymentType(payment.type)}
                            {payment?.type === PaymentMethod.CHECK ? ` #${payment.checkNumber}` : ""}
                          </div>
                          <div className="text-body-medium text-sys-brand-on-surface-variant">
                            {payment.type === PaymentMethod.CARD &&
                              PaymentMethod.US_BANK_ACCOUNT &&
                              `The refund will be issued via Stripe and returned to the original payment method. Expect a processing time of 7-10 days.`}

                            {payment.type === PaymentMethod.CHECK &&
                              `You must submit a check processing request for the payor(s) to receive the refund.`}
                            {payment.type === PaymentMethod.FINANCIAL_ADJUSTMENT &&
                              `Adjustments will be reflected immediately.`}
                          </div>
                        </div>
                      </div>
                      {hasAddedProducts && refundablePaymentsResult.length > 1 ? (
                        <div className="w-[210px] mt-5 md:mt-4 !ml-10 md:!ml-0" data-testid="allocation-amount">
                          <input type="hidden" {...register(`refunds.${i}.paymentId`)} value={payment.id} />

                          <Controller
                            render={({ field, fieldState }) => {
                              return (
                                <NumberFormatBase
                                  disabled={false}
                                  value={(field.value / 100).toFixed(2) || 0}
                                  format={currencyFormatter}
                                  customInput={TextField}
                                  error={!!fieldState.error}
                                  errorText={fieldState?.error?.message}
                                  onFocus={(e) => {
                                    e.target.select();
                                  }}
                                  onValueChange={(values) => {
                                    field.onChange(Math.round(values.floatValue || 0));
                                    clearErrors("refunds");
                                    trigger("refunds");
                                  }}
                                  leadingIcon="attach_money"
                                  label="Refund Amount"
                                />
                              );
                            }}
                            control={control}
                            name={`refunds.${i}.amount`}
                            defaultValue={0}
                            rules={{
                              max: {
                                value: source.removedAmountCents,
                                message: "Must be less than or equal to net payment"
                              },
                              validate: (value) =>
                                (sumBy(refunds, "amount") === -balance && value !== undefined) ||
                                "Total amounts must equal refund due"
                            }}
                          />
                        </div>
                      ) : (
                        <div className="text-label-small text-sys-brand-on-surface-variant">
                          {centsToDollarsFormatted(
                            refundablePaymentsResult.length > 1 ? source.removedAmountCents : -balance
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </>
      )}
      {order.stage === OrderStage.PAID && balance > 0 && (
        <div className="mt-6">
          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <DateSelect
                useSingleDate
                value={field.value || ""}
                label="Order Due Date *"
                onChange={field.onChange}
                bgClassName="!bg-gray-100"
                minDate={new Date()}
              />
            )}
          />
        </div>
      )}
      <div className="py-6 hidden">
        <Controller
          render={({ field }) => (
            <TextField
              disabled={false}
              type="text"
              className="w-full"
              value={field.value || ""}
              label="Note"
              onChangeText={field.onChange}
            />
          )}
          control={control}
          name="note"
        />
      </div>
    </Dialog>
  );
}
