import Icon from "@/components/Icon";
import { Path } from "@/router";
import classNames from "classnames";
import { MetricCardType, MetricCardVariant } from "./MetricCard.types";
import Tooltip from "../Tooltip";

const VARIANT_CLASSES: Record<MetricCardVariant, string> = {
  default: "text-sys-brand-on-surface",
  success: "text-extended-success-brand-color",
  error: "text-sys-brand-error"
};

export default function MetricCard<P extends Path>({
  label,
  value,
  loading,
  link,
  variant = "default",
  width = "160px",
  tooltip
}: MetricCardType<P>) {
  const isEmpty = !value;
  const valueClasses = classNames("text-headline-medium", VARIANT_CLASSES[variant], {
    "opacity-20": isEmpty
  });
  return (
    <div className="p-4 flex flex-col gap-2" data-testid="metric">
      <div className="text-body-medium flex items-center min-h-[24px]">
        {label}
        {!!tooltip && (
          <div className="ml-1">
            <Tooltip
              title={tooltip.title}
              supportingText={tooltip.supportingText}
              menuCorner="end-start"
              anchorCorner="start-start"
            >
              <Icon name="info" className="mb-4xxx" />
            </Tooltip>
          </div>
        )}
        {link && <Icon name="navigate_next" className="flex-shrink-0" />}
      </div>
      {loading ? (
        <div
          className="animate-pulse h-4 rounded-full bg-gradient-to-r from-gray-200 to-gray-50 mt-5"
          style={{ width }}
        />
      ) : (
        <div className={valueClasses}>{isEmpty ? "--" : value}</div>
      )}
    </div>
  );
}
