import usePageTitle from "@/hooks/usePageTitle";
import TabLayout from "../_TabLayout";
import OrdersTable from "../_components/OrdersTable";

export default function TerminationsPage() {
  usePageTitle("/billing/dues-management/reporting/unpaid-dues", "Billing | Dues Management | Reporting | Unpaid Dues");
  return (
    <TabLayout>
      <OrdersTable defaultVariables={{ statuses: ["OPEN"] }} />
    </TabLayout>
  );
}
