import usePageTitle from "@/hooks/usePageTitle";
import TabLayout from "../_TabLayout";
import PeopleTable from "../_components/PeopleTable";

export default function SuspensionsPage() {
  usePageTitle("/billing/dues-management/reporting/suspensions", "Billing | Dues Management | Reporting | Suspensions");
  return (
    <TabLayout>
      <PeopleTable defaultVariables={{ suspended: true }} />
    </TabLayout>
  );
}
