import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { get } from "lodash";
import { PaymentType } from "./types";
import { useActions } from "../../_hooks";
import { useQuery } from "@apollo/client";
import { PAYMENT_QUERY } from "./queries";
import Progress from "@/components/Progress";
import EmptyState from "@/components/EmptyState";
import { useRequireCapability, DOMAIN, PERMISSION } from "@/hooks/useCapabilities";
import PaymentWrapper from "../../../_components/PaymentWrapper";
import usePageTitle from "@/hooks/usePageTitle";

const Payment = () => {
  usePageTitle("/billing/orders/:orderId/payment/:paymentId", "Billing | Orders | Order Details | Payment Details");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewPayments });
  const { paymentId, orderId } = useParams();

  const { loading, error, data, refetch } = useQuery(PAYMENT_QUERY, { variables: { paymentId } });

  const { setBackLink, setActions } = useActions();

  const [searchParams] = useSearchParams();
  const isPaymentApplied = searchParams.get("payment-applied") === "true";

  useEffect(() => {
    setActions([]);
    setBackLink({
      to: "/billing/orders/:orderId",
      params: { orderId: orderId || "" },
      title: isPaymentApplied ? "Back to Order" : "Back"
    });
  }, [setBackLink, setActions, orderId, isPaymentApplied]);

  if (loading)
    return (
      <div className="p-10 flex items-center justify-center">
        <Progress />
      </div>
    );
  if (error) return <EmptyState title="Error" caption="An error occurred while loading this order." iconName="error" />;
  return (
    <PaymentWrapper payment={get(data, "commercePayment") as PaymentType} setActions={setActions} refetch={refetch} />
  );
};

export default Payment;
