import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import usePagination from "@/hooks/usePagination";
import { useParams } from "@/hooks/useSearchParams";
import { useSearch } from "@/hooks/useSearch";
import { PEOPLE_QUERY, PeopleQueryInput } from "../queries";

export default function usePeopleQuery(quickSearch?: PeopleQueryInput) {
  const { paginationVariables, renderFooter, resetPagination } = usePagination();
  const { params, addSearchParam, deleteSearchParams, searchParamsUrl } = useParams();
  const { searchValue, control } = useSearch("name", params.search || "");
  const { data, loading } = useQuery(PEOPLE_QUERY, {
    variables: { ...paginationVariables, input: { search: searchValue, ...quickSearch } },
    errorPolicy: "all"
  });

  useEffect(() => {
    resetPagination();
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    control,
    renderFooter,
    loading,
    data,
    addSearchParam,
    deleteSearchParams,
    searchParamsUrl
  };
}
