import { gql } from "@apollo/client";
import { DUES_RATE_FRAGMENT } from "../../../../fragments";

export const GET_DUES_RATES_QUERY = gql`
  query GetDuesRates($filters: BackofficeDuesDuesRatesInput) {
    duesRates(filters: $filters) {
      nodes {
        ...DuesRateFragment
      }
    }
  }
  ${DUES_RATE_FRAGMENT}
`;
