import React, { createContext, useState, useEffect } from "react";
import rg4js from "raygun4js";
import useRaygun from "@/hooks/useRaygun";

export const RaygunContext = createContext<{ raygunBooted: boolean; raygunApiKey: string }>({
  raygunBooted: false,
  raygunApiKey: ""
});

export function RaygunProvider({ children }: { children: React.ReactNode }) {
  const [raygunBooted, setRaygunBooted] = useState(false);
  const raygunApiKey = import.meta.env.VITE_RAYGUN_API_KEY;

  useEffect(() => {
    if (!raygunBooted) {
      rg4js("apiKey", raygunApiKey);
      rg4js("enableCrashReporting", true);
      rg4js("options", { ignore3rdPartyErrors: true });
      setRaygunBooted(true);
    }
  }, [raygunBooted, raygunApiKey]);

  return <RaygunContext.Provider value={{ raygunBooted, raygunApiKey }}>{children}</RaygunContext.Provider>;
}

export function InitializeRaygunUser() {
  useRaygun();
  return null;
}
