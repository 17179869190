import TextField from "@/components/TextField";
import { Controller, useFormContext } from "react-hook-form";

const numericMask = (value: string): string => {
  return value.replace(/[^0-9]/g, "");
};

const MaxAttendanceField = ({ index }: { index: number }) => {
  const { control } = useFormContext();

  return (
    <div className="relative">
      <Controller
        name={`sessions.${index}.maxAttendance`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            label="Max Registrants Allowed"
            className="w-full"
            value={value}
            onChange={(e: Event) => {
              const maskedValue = numericMask((e.target as HTMLInputElement).value);
              onChange(maskedValue);
            }}
          />
        )}
      />
      <div className="text-sys-brand-on-surface text-body-small pt-1 pl-4">Leave blank if no limit</div>
    </div>
  );
};

export default MaxAttendanceField;
