import { useEffect } from "react";
import usePageTitle from "@/hooks/usePageTitle";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { LinkProps } from "@generouted/react-router/client";
import { Path, Params } from "@/router";
import { FormValues } from "../../_form/validation/schema";
import EventForm from "../../_form";
import { useMutation } from "@apollo/client";
import { UPDATE_EVENT_MUTATION } from "../../queries";
import { showSnackbar } from "@/lib/snackbarUtils";
import { toUpper } from "lodash";
import { BackofficeEventsUpdateEventInput } from "../../_form/types";
import { getDefaultFormValues, setToDayBoundary, transformSessionsToInput } from "../../_form/utils";

export default function EditEventPage() {
  const { eventSlug } = useParams();
  usePageTitle(`/events/${eventSlug}/edit`, "Events | Edit Event");

  const location = useLocation();
  const event = location.state?.event;

  const navigate = useNavigate();
  const homePath: LinkProps<Path, Params> = { to: "/events" };

  const [updateEventMutation] = useMutation(UPDATE_EVENT_MUTATION);

  useEffect(() => {
    if (!event) {
      navigate(homePath.to);
    }
  }, [event, homePath.to, navigate]);

  const hasRegistrations = event?.registrationCount > 0;

  const initialValues = getDefaultFormValues(event);

  const handleSubmit = async (data: FormValues) => {
    const transformedSessions = transformSessionsToInput(data.sessions, data.timezone);

    const addressFields =
      data.locationType === "virtual"
        ? {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: ""
          }
        : {
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zip: data.zipcode
          };

    const updateEventInput: BackofficeEventsUpdateEventInput = {
      id: event?.id,
      description: data.description,
      title: data.eventName,
      eventTypeId: data.eventType.id,
      sectionId: data.sectionId,
      facilityId: data.facilityId,
      location: data.location,
      locationType: toUpper(data.locationType),
      timezone: data.timezone,
      registrationOpenAt: setToDayBoundary(data.registrationOpenDate.startDate, "start", data.timezone),
      registrationCloseAt: setToDayBoundary(data.registrationCloseDate.startDate, "end", data.timezone),
      sessions: transformedSessions,
      waitlistType: toUpper(data.waitlistType),
      ...addressFields,
      customFields: data.customFields,
      termsContent: data.termsContent
    };

    const { data: responseData } = await updateEventMutation({
      variables: { input: updateEventInput }
    });
    const { success } = responseData.updateEvent;
    if (success) {
      navigate(`/events/${eventSlug}/registrations`);
      showSnackbar("Event successfully updated", { close: true });
    } else {
      showSnackbar(responseData.updateEvent.message);
    }
  };

  return (
    <EventForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isEditing={true}
      hasRegistrations={hasRegistrations}
    />
  );
}
