import PgaAssistantIcon from "@/assets/images/pga-assistant.svg";
import { useState, useEffect, useRef } from "react";
import AssessmentQuestion from "@/components/AssessmentQuestion";
import { assessmentQuestionFrame } from "./_utils";
import { useFormContext } from "react-hook-form";
import { useAiQuery } from "@/lib/aiHelpers";
import Button from "@/components/Button";
import { AssessmentFormEntry } from "./types";
import Dialog from "@/components/Dialog";
import Progress from "@/components/Progress";
import { Reorder, useDragControls } from "framer-motion";
import { useFlags } from "@/hooks/useFeatureFlags";
import usePageTitle from "@/hooks/usePageTitle";

function DraggableAssessmentQuestion({
  question,
  index,
  focusIndex,
  setFocusIndex,
  handleQuestionChange,
  handleOptionsChange,
  containerRef
}: {
  question: AssessmentFormEntry;
  index: number;
  focusIndex: number;
  setFocusIndex: (index: number) => void;
  handleQuestionChange: (index: number, newQuestion: string) => void;
  handleOptionsChange: (index: number, newOptions: any) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const dragControls = useDragControls();
  const [wasDragged, setWasDragged] = useState(false);

  const handleDragStart = () => {
    setFocusIndex(-1);
    setWasDragged(true);
  };

  const handleDragEnd = () => {
    setTimeout(() => setWasDragged(false), 0);
  };

  const activeStyle = {
    backgroundColor: "var(--sys-brand-surface-container)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    opacity: 0.8,
    border: "1px solid var(--sys-brand-outline-variant)",
    borderRadius: "16px",
    zIndex: 1000
  };

  return (
    <Reorder.Item
      key={question.id}
      value={question}
      dragControls={dragControls}
      dragListener={false}
      layout
      transition={{ duration: 0 }}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragConstraints={containerRef}
      dragElastic={0.1}
      whileDrag={activeStyle}
      style={{ position: "relative" }}
    >
      <div
        data-testid={`assessment-question-${index}`}
        className={`my-6 ${focusIndex !== index ? "cursor-pointer" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!wasDragged && !(e.target as HTMLElement).closest(".drag-handle")) {
            setFocusIndex(index);
          }
        }}
      >
        <AssessmentQuestion
          position={index}
          isFocused={focusIndex === index}
          question={question.content}
          setQuestion={(newQuestion) => handleQuestionChange(index, newQuestion)}
          questionOptions={question.options.map((option: any, optIndex: number) => ({
            position: optIndex,
            text: option.content,
            isCorrect: option.answer
          }))}
          setQuestionOptions={(newOptions) => handleOptionsChange(index, newOptions)}
          onDragHandlePointerDown={(event) => {
            dragControls.start(event);
            handleDragStart();
          }}
        />
      </div>
    </Reorder.Item>
  );
}

export default function AssessmentDetails({ form }: { form: any }) {
  usePageTitle("/education/videos/assessmentDetails", "Education | Videos | Assessment Details");
  const { setValue, watch } = useFormContext();
  const [focusIndex, setFocusIndex] = useState<number>(0);
  const pageRef = useRef<HTMLDivElement>(null);
  const assessmentData = form?.formState?.defaultValues?.assessment;

  const [questions, setQuestions] = useState<AssessmentFormEntry[]>(
    assessmentData?.questions?.length ? assessmentData.questions : assessmentQuestionFrame
  );

  const videoUrl = watch("url");

  const {
    message: aiResponse,
    loading: aiLoading,
    error: aiError,
    fetchData,
    cancelFetch
  } = useAiQuery<{ videoUrl: string }>("assessment-generator");
  const [aiParseError, setAiParseError] = useState<Error | null>(null);

  const [showGenerateConfirmationDialog, setShowGenerateConfirmationDialog] = useState(false);

  const handleGenerateQuestions = () => {
    const requestData = {
      videoUrl,
      ...(aiError || aiParseError ? { error: aiError || aiParseError, originalResponse: aiResponse } : {})
    };
    fetchData(requestData, { ignoreCache: true });
  };

  const handleDialogConfirm = () => {
    handleGenerateQuestions();
  };

  const handleDialogClose = () => {
    setShowGenerateConfirmationDialog(false);
    if (aiLoading) {
      cancelFetch();
    }
  };

  const flags = useFlags();
  const flagAiAssessmentGenerator = flags["backoffice-ai-assessment-generator"];

  useEffect(() => {
    if (!aiLoading && aiResponse) {
      try {
        console.log("AI: RAW", aiResponse);
        const parsedResponse = JSON.parse(aiResponse);

        const generatedQuestions = parsedResponse.map((item: any) => ({
          content: item.content,
          questionType: item.questionType || "multiple_choice",
          options: item.options.map((option: any) => ({
            content: option.content,
            answer: option.answer
          }))
        }));

        console.log("AI: parsed ", generatedQuestions);

        setQuestions(generatedQuestions);
        generatedQuestions.forEach((question: any, index: number) => {
          setValue(
            `assessment.questions[${index}].options`,
            question.options.map((option: any) => ({
              content: option.content,
              answer: option.answer
            }))
          );
          setValue(`assessment.questions[${index}].content`, question.content);
          setValue(`assessment.questions[${index}].questionType`, question.questionType);
        });
        setAiParseError(null);
      } catch (error) {
        console.error("Error parsing AI response:", error);
        setAiParseError(error as Error);
      } finally {
        setShowGenerateConfirmationDialog(false);
      }
    }
  }, [aiResponse, aiLoading, setValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pageRef.current && !pageRef.current.contains(event.target as Node)) {
        setFocusIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    questions.forEach((question, index) => {
      setValue(
        `assessment.questions[${index}]`,
        {
          id: question.id,
          content: question.content,
          options: question.options.map((option) => ({
            id: option.id,
            content: option.content,
            answer: option.answer
          }))
        },
        { shouldDirty: false }
      );
    });
  }, [questions, setValue]);

  const formAssessment = watch("assessment.questions");

  useEffect(() => {
    if (Array.isArray(formAssessment) && formAssessment.length > 0) {
      setQuestions(
        formAssessment.map((question: any) => ({
          id: question.id,
          content: question.content,
          options: Array.isArray(question.options)
            ? question.options.map((option: any) => ({
                id: option.id,
                content: option.content,
                answer: option.answer
              }))
            : []
        }))
      );
    }
  }, [formAssessment]);

  const handleQuestionChange = (index: number, newQuestion: string) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        content: newQuestion
      };
      return updatedQuestions;
    });
    setValue(`assessment.questions[${index}].content`, newQuestion, { shouldDirty: true });
  };

  const handleOptionsChange = (index: number, newOptions: any) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        options: newOptions.map((option: any) => ({
          content: option.text,
          answer: option.isCorrect
        }))
      };
      return updatedQuestions;
    });
    setValue(
      `assessment.questions[${index}].options`,
      newOptions.map((option: any) => ({
        id: option.id,
        content: option.text,
        answer: option.isCorrect
      })),
      { shouldDirty: true }
    );
  };

  return (
    <div className="space-y-6">
      <div className="text-headline-small">Set up the assessment</div>
      <div className="text-body-large flex items-center justify-between flex-col md:flex-row">
        <span className="mb-2 md:mb-0">
          Set up the assessment questions below. The selected option for each question will be stored as the correct
          answer for scoring.
        </span>
        <div className="w-full md:w-auto flex justify-start md:justify-end">
          {flagAiAssessmentGenerator && (
            <Button
              onClick={() => setShowGenerateConfirmationDialog(true)}
              disabled={aiLoading}
              theme="pgaAssistant"
              icon={<img src={PgaAssistantIcon} alt="icon" className="w-4 h-4" />}
            >
              {aiLoading ? "Generating Questions..." : "Generate Questions"}
            </Button>
          )}
        </div>
      </div>

      {(aiError || aiParseError) && (
        <div className="text-red-500 mt-2">Error generating questions, please try again.</div>
      )}
      <div className="col-span-full">
        <div ref={pageRef}>
          <Reorder.Group
            axis="y"
            values={questions}
            onReorder={setQuestions}
            layoutScroll
            style={{ overflow: "visible", width: "100%" }}
          >
            {questions.map((question, index) => (
              <DraggableAssessmentQuestion
                key={question.id}
                question={question}
                index={index}
                focusIndex={focusIndex}
                setFocusIndex={setFocusIndex}
                handleQuestionChange={handleQuestionChange}
                handleOptionsChange={handleOptionsChange}
                containerRef={pageRef}
              />
            ))}
          </Reorder.Group>
        </div>
      </div>

      {showGenerateConfirmationDialog && (
        <div>
          <Dialog
            open={showGenerateConfirmationDialog}
            onClosed={handleDialogClose}
            headline="Generate Assessment Questions with PGA Assistant"
            className="max-w-[423px] p-1 pr-2"
            actions={
              <>
                <span
                  className="text-sys-brand-primary cursor-pointer flex items-center justify-center mr-4"
                  onClick={handleDialogClose}
                >
                  Cancel
                </span>
                <Button variant="filled" onClick={handleDialogConfirm} disabled={aiLoading}>
                  {aiLoading ? (
                    <>
                      <Progress size={20} className="mr-2" />
                      Generating
                    </>
                  ) : (
                    "Generate Questions"
                  )}
                </Button>
              </>
            }
          >
            <p>
              Do you want to generate assessment questions with the PGA Assistant? Existing questions will be
              overwritten and cannot be recovered. This process will take a few moments.
            </p>
          </Dialog>
        </div>
      )}
    </div>
  );
}
