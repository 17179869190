export type SkeletonProps = { rows: number };

export default function Skeleton({ rows }: SkeletonProps) {
  return (
    <div className="space-y-4">
      {Array.from({ length: rows }).map((_, index) => {
        const width = `${Math.random() * 25 + 75}%`;
        return (
          <div
            key={index}
            className="animate-pulse h-8 rounded-full bg-gradient-to-r from-gray-200 to-gray-50"
            style={{ width }}
            data-testid="skeleton-row"
          />
        );
      })}
    </div>
  );
}
