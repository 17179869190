import { PageInfo } from "@/components/Pagination";
import { TypedDocumentNode, gql } from "@apollo/client";
import { PERSON_FRAGMENT } from "@/fragments";
import { OrderNode } from "@/pages/billing/types";

type Query = {
  currentDuesCycle: {
    totalOrders: {
      totalCount: number;
    };
    totalOrdersWithFailedPayments: {
      totalCount: number;
    };
    totalOverdueOrders: {
      totalCount: number;
    };
    orders: {
      totalCount: number;
      nodes: OrderNode[];
      pageInfo: PageInfo;
    };
  };
};

export const ORDERS_QUERY: TypedDocumentNode<Query> = gql`
  query GetCurrentDuesCycle(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
    $dueDateRange: DateRangeInput
    $ids: [String!]
    $balanceRange: MoneyCentsRangeInput
    $statuses: [CommerceOrderStatus!]
    $eligibleForSuspensionOn: ISO8601Date
    $eligibleForTerminationOn: ISO8601Date
    $facilityIds: [String!]
    $sectionIds: [String!]
    $memberClassifications: [String!]
    $paymentStatuses: [CommerceOrderPaymentStatus!]
  ) {
    currentDuesCycle {
      id
      startsOn
      endsOn
      dueDate
      totalOrders: orders(filters: { statuses: [OPEN] }) {
        totalCount
      }
      totalOrdersWithFailedPayments: orders(filters: { statuses: [OPEN], paymentStatuses: [HAS_FAILED_PAYMENTS] }) {
        totalCount
      }
      totalOverdueOrders: orders(filters: { statuses: [OPEN], paymentStatuses: [IS_OVERDUE] }) {
        totalCount
      }
      orders(
        first: $first
        last: $last
        after: $after
        before: $before
        filters: {
          search: $query
          dueDateRange: $dueDateRange
          ids: $ids
          balanceRange: $balanceRange
          statuses: $statuses
          eligibleForSuspensionOn: $eligibleForSuspensionOn
          eligibleForTerminationOn: $eligibleForTerminationOn
          facilityIds: $facilityIds
          sectionIds: $sectionIds
          memberClassifications: $memberClassifications
          paymentStatuses: $paymentStatuses
        }
      ) {
        nodes {
          id
          stage
          balance
          dueDate
          payable
          updatedAt
          description
          status
          isOverdue
          paymentStatus
          person {
            id
            firstName
            lastName
            profilePhoto
            pgaId
            classification
            primarySectionAffiliation {
              id
              section {
                id
                name
              }
            }
            mainProgramType {
              id
              name
            }
          }
          lineItems {
            id
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

export const CHANGE_DUE_DATES_MUTATION = gql`
  mutation ChangeDueDates($input: CommerceBulkChangeOrderDueDateInput!) {
    commerceBulkChangeOrderDueDate(input: $input) {
      successfulOrders {
        id
        dueDate
      }
      failedOrders {
        id
        dueDate
      }
      message
      success
    }
  }
`;

export const TERMINATE_PROGRAMS_MUTATION = gql`
  mutation terminatePrograms($input: BackofficeDuesBulkTerminateProgramsInput!) {
    duesBulkTerminatePrograms(input: $input) {
      successfulPersonIds
      failedPersonIds
      message
      success
    }
  }
`;

export const PEOPLE_QUERY = gql`
  query allPeopleQuery($first: Int, $last: Int, $before: String, $after: String, $input: BackofficeCRMPeopleInput) {
    currentDuesCycle {
      orders(filters: { availableForTerminations: true }) {
        totalCount
      }
    }
    people(first: $first, last: $last, before: $before, after: $after, input: $input) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...PersonFragment
        primarySectionAffiliation {
          id
          section {
            id
            name
            facilities {
              id
              name
            }
          }
        }
        suspensions {
          code
          startTime
          endTime
        }
        terminations {
          code
          startTime
          endTime
        }
        employments {
          id
          isPrimary
          facility {
            id
            name
          }
        }
        latestProgram {
          id
          status
          programType {
            id
            name
          }
          band {
            id
            classification {
              id
              name
              description
            }
          }
        }
      }
    }
  }
  ${PERSON_FRAGMENT}
`;
