import { OrderPaymentStatus, OrderStage, PaymentMethod, PaymentStatus } from "@/pages/billing/types";

export enum PayerType {
  SELF = "self",
  OTHER = "other",
  FACILITY = "facility"
}

export type PaymentType = {
  id: string;
  amountCents: number;
  refundedAmountCents: number;
  status: PaymentStatus;
  type: PaymentMethod;
  paidAt: string;
  transactionId: string;
  transactionUrl: string;
  stripeAccount: string;
  checkNumber?: string;
  source: string;
  payer: {
    id: string;
    name: string;
    email: string;
    type: PayerType;
  };
  orderPayments: OrderPayment[];
  bypass: boolean;
};

export type OrderPayment = {
  id: string;
  amountCents: number;
  createdAt: string;
  type: string;
  order: Order;
  ledgerEntries: {
    amountCents: number;
    createdAt: string;
    type: string;
    lineItem: LineItem;
    originalLineItemId: string;
    secondaryLineItem: LineItem;
    secondaryLineItemId: string;
  }[];
  lineItemsOriginal?: LineItem[];
  stage: OrderStage;
  balanceCents: number;
};

export interface LineItem {
  id: string;
  name: string;
  totalPrice: number;
  priceCents: number;
  balance: number;
}

export interface Order {
  id: string;
  person: {
    id: string;
    firstName: string;
    lastName: string;
    profilePhoto: string;
    pgaId: string;
    mainProgramType: {
      name: string;
    };
  };
  description: string;
  dueDate: string;
  balance: number;
  lineItems: LineItem[];
  lineItemsTotal: number;
  stage: OrderStage;
  paymentStatus: OrderPaymentStatus;
}
export interface LineItem {
  id: string;
  name: string;
  quantity: number;
  priceCents: number;
}
export interface CellProps {
  row: {
    original: Order;
  };
}
