import EmptyState from "@/components/EmptyState";
import Icon from "@/components/Icon";
import MetricCard from "@/components/MetricGrid/MetricCard";
import Table, { TableDetails } from "@/components/Table";
import Tooltip from "@/components/Tooltip";
import { useFlags } from "@/hooks/useFeatureFlags";
import { toFormat } from "@/lib/dateHelpers";
import { Person, SectionAffiliation } from "@/types";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_SECTION_AFFILIATION_HISTORY_QUERY } from "./queries";

type PersonData = { person: Person };

function sortSectionAffiliations(list: SectionAffiliation[]) {
  return [...list].sort((a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime());
}

function formatTableData(list: SectionAffiliation[]) {
  return sortSectionAffiliations(list).map((e: SectionAffiliation) => ({
    id: e.id,
    primary: e.primary,
    section: e.section.name,
    startDate: toFormat(e.startAt),
    endDate: toFormat(e.endAt)
  }));
}

export default function SectionDetails() {
  const flags = useFlags();
  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_SECTION_AFFILIATION_HISTORY_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!flags["backoffice-person-profile-sections"]) {
    return null;
  }

  const personData = data?.person;
  const primarySection = personData?.primarySection?.name;
  const sectionAffiliations = personData?.sectionAffiliations;
  const tableData = !!sectionAffiliations?.length ? formatTableData(sectionAffiliations) : [];

  return (
    <>
      <div className="mt-4">
        <MetricCard label="Primary Section" value={primarySection} />
      </div>
      <Table
        renderDetails={() => (
          <TableDetails
            heading="Section Affiliation History"
            body="Show the section(s) this person is a part of. A person's section can be determined by their employment, their home address, or manually associated."
          />
        )}
        data={tableData}
        loading={loading}
        columns={[
          {
            header: "Section",
            size: 250,
            id: "section",
            accessorKey: "section",
            enableSorting: false,
            cell: ({ row }) => (
              <div className="flex items-center gap-2">
                {row.original.primary && (
                  <Tooltip
                    variant="plain"
                    supportingText="Primary"
                    color="black"
                    menuCorner="end-start"
                    anchorCorner="start-start"
                  >
                    <Icon name="check" size={24} />
                  </Tooltip>
                )}
                {row.original.section}
              </div>
            )
          },
          {
            header: "Start Date",
            id: "startDate",
            accessorKey: "startDate",
            enableSorting: false
          },
          {
            header: "End Date",
            id: "endDate",
            accessorKey: "endDate",
            enableSorting: false
          }
        ]}
        renderEmptyState={() => (
          <EmptyState
            title="No Section Affiliation"
            caption="This person does not have a section affiliation because their address and/or employment is unknown"
            iconName="domain"
          />
        )}
        data-testid="section-affiliation-table"
      />
    </>
  );
}
