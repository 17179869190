import react from "react";
import { createComponent } from "@lit/react";
import { MdOutlinedSelect } from "@material/web/select/outlined-select";
import { MdFilledSelect } from "@material/web/select/filled-select";
import { MdSelectOption } from "@material/web/select/select-option";

export { MdOutlinedSelect } from "@material/web/select/outlined-select";

export const LitOutlinedSelect = createComponent({
  tagName: "md-outlined-select",
  elementClass: MdOutlinedSelect,
  react
});

export const LitFilledSelect = createComponent({
  tagName: "md-filled-select",
  elementClass: MdFilledSelect,
  react
});

export const LitSelectOption = createComponent({
  tagName: "md-select-option",
  elementClass: MdSelectOption,
  react
});
