import { gql, TypedDocumentNode } from "@apollo/client";
import { PERSON_FRAGMENT } from "@/fragments";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { Person } from "@/types";
import { PageInfo, PaginationVariables } from "@/components/Pagination";
import { Query } from "./_hooks/useQuickSearch";

export type PeopleQueryResponse = {
  people: { nodes: Person[]; pageInfo: PageInfo; totalCount: number };
};

export type PeopleQueryInput = Partial<Omit<Query, "pgaId"> & { pgaIds: string[]; search: string }>;

export type PeopleQueryVariables = PaginationVariables & { input?: PeopleQueryInput };

export const QUICKSEARCH_OPTIONS_QUERY: TypedDocumentNode<{
  crmSections: { nodes: { id: string; name: string }[] };
  classifications: { nodes: { id: string; name: string }[] };
}> = gql`
  query quicksearchOptionsQuery {
    crmSections {
      nodes {
        id
        name
      }
    }
    classifications {
      nodes {
        id
        name
      }
    }
  }
`;

export const PEOPLE_QUERY: TypedDocumentNode<PeopleQueryResponse, PeopleQueryVariables> = gql`
  query allPeopleQuery($first: Int, $last: Int, $before: String, $after: String, $input: BackofficeCRMPeopleInput) {
    people(first: $first, last: $last, before: $before, after: $after, input: $input) {
      nodes {
        ...PersonFragment
        primarySection {
          id
          name
        }
        latestProgram {
          id
          status
          band {
            id
            classification {
              id
              name
            }
          }
        }
        employments {
          id
          title
          isPrimary
          facility {
            id
            name
            section {
              id
              name
            }
            addresses {
              id
              city
              state
              addressType
            }
          }
        }
      }
      ...PageInfo
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${PERSON_FRAGMENT}
`;

export const SOME_PEOPLE_QUERY = gql`
  query GetPeople($input: BackofficeCRMPeopleInput, $first: Int) {
    people(input: $input, first: $first) {
      totalCount
      nodes {
        id
        pgaId
        firstName
        lastName
        profilePhoto
        email
        primaryFacility {
          name
        }
        primarySectionAffiliation {
          section {
            name
          }
        }
      }
    }
  }
`;

export const SOME_USERS_QUERY = gql`
  query GetBackofficeUsers($search: String!) {
    backofficeUsers(search: $search) {
      id
      name
      email
    }
  }
`;

export const PERSON_WITH_SALESFORCE_ID_QUERY = gql`
  query GetPerson($input: ID) {
    person(pgaId: $input) {
      id
      firstName
      lastName
      pgaId
      primaryFacility {
        name
      }
      primarySectionAffiliation {
        section {
          name
        }
      }
      profilePhoto
      salesforceExternalId
    }
  }
`;
