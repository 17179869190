import { gql } from "@apollo/client";

export const FACILITIES_QUERY = gql`
  query GetFacilities($input: BackofficeCRMFacilitiesInput!) {
    facilities(input: $input) {
      totalCount
      nodes {
        name
        id
        mainAddress {
          address1
          address2
          city
          state
          postalCode
          countryCode
          isVerified
        }
      }
    }
  }
`;

export const EVENTS_QUERY = gql`
  query GetEvents($query: BackofficeEventsSearchInput) {
    events(query: $query) {
      id
      title
      slug
      startsAt
      endsAt
    }
  }
`;

export const REGISTRANTS_FROM_EVENT_ID = gql`
  query GetPeopleByEventId($eventSlug: String!) {
    eventRegistrants(slug: $eventSlug) {
      totalCount
      nodes {
        pgaId
      }
    }
  }
`;

export const PEOPLE_FROM_IDS = gql`
  query GetPeopleByPGAIDs($input: BackofficeCRMPeopleInput!) {
    people(input: $input) {
      totalCount
      nodes {
        firstName
        lastName
        salesforceExternalId
        pgaId
        profilePhoto
        primarySectionAffiliation {
          section {
            name
          }
        }
        primaryFacility {
          name
          id
        }
      }
    }
  }
`;

export const PEOPLE_WITH_FACILITY_ID = gql`
  query GetPeopleByFacilityId($input: BackofficeCRMPeopleInput!) {
    people(input: $input) {
      totalCount
      nodes {
        id
        firstName
        lastName
        pgaId
        primarySectionAffiliation {
          section {
            name
          }
        }
        primaryFacility {
          name
          id
        }
        profilePhoto
        salesforceExternalId
        email
      }
    }
  }
`;
