import { gql } from "@apollo/client";

export const ALL_JOBS = [
  {
    id: "1",
    title: "Job 1",
    facility: "Facility",
    serviceLevel: "Service Level",
    employer: "Bob Bobertson",
    updatedAt: "2021-10-01T00:00:00Z",
    postedOn: "2021-09-01T00:00:00Z",
    applicantCount: 0,
    postingStage: "Draft",
    hiringStatus: null
  },
  {
    id: "2",
    title: "Job 2",
    facility: "Facility",
    serviceLevel: "Service Level",
    employer: "Bob Bobertson",
    updatedAt: "2021-10-01T00:00:00Z",
    postedOn: "2021-09-01T00:00:00Z",
    applicantCount: 5,
    postingStage: "Pending",
    hiringStatus: "Unfilled"
  },
  {
    id: "3",
    title: "Job 3",
    facility: "Facility",
    serviceLevel: "Service Level",
    employer: "Bob Bobertson",
    updatedAt: "2021-10-01T00:00:00Z",
    postedOn: "2021-09-01T00:00:00Z",
    applicantCount: 2,
    postingStage: "Open",
    hiringStatus: "Unfilled"
  },
  {
    id: "4",
    title: "Job 4",
    facility: "Facility",
    serviceLevel: "Service Level",
    employer: "Albert Albertson",
    updatedAt: "2021-10-01T00:00:00Z",
    postedOn: "2021-09-01T00:00:00Z",
    applicantCount: 1,
    postingStage: "Closed",
    hiringStatus: "Unfilled"
  },
  {
    id: "5",
    title: "Job 5",
    facility: "Facility",
    serviceLevel: "Service Level",
    employer: "Bob Bobertson",
    updatedAt: "2021-10-01T00:00:00Z",
    postedOn: "2021-09-01T00:00:00Z",
    applicantCount: 0,
    postingStage: "Closed",
    hiringStatus: "Filled"
  }
];

export const JOBS_SEARCH_QUERY = gql`
  query jobPosts($input: BackofficeJobPostSearchInput!) {
    jobPosts(input: $input) {
      totalCount
      nodes {
        id
        createdAt
        updatedAt
        facility {
          id
          name
        }
        section {
          id
          name
        }
        currentCompensation
        previousCompensation
        owner {
          name
          email
        }
        numberOfApplicants
        priority
        status
        subject
        name
        agreedToTermsOfService
        serviceLevel
        compensation {
          baseMaximum
          baseMinimum
          totalAnticipated
          currencyUnit
        }
        benefits
        additionalIncome
        pointOfContact {
          name
          email
          title
        }
        essentialResponsibilities
        category {
          name
          area
          type
        }
        overview
        pgaOrgUrl
        requiredEducation
        requiredExperienceInYears
        requiredExperienceDescription
        hideCompensationFromJobBoard
        applicantGroup
        applyVia
        paySchedule
        positionType
        stage
        optInToCareerConsultantAssistance
        otherSections
        otherStates
        submissionDeadline
        additionalPgaEducation
        externalJobUrl
        stopCaseCloseNotifications
        switchToConsultantAssistance
      }
    }
  }
`;
