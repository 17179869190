import { PageInfo } from "@/components/Pagination";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";
import { AuditEntry } from "@/pages/sections/types";
import { gql, TypedDocumentNode } from "@apollo/client";

export type Variables = {
  pgaId: string;
  startDateTime: string;
  endDateTime: string;
};

type Result = {
  person: {
    id: string;
    displayName: string;
    profilePhoto: string;
    auditLog: {
      totalCount: number;
      pageInfo: PageInfo;
      nodes: AuditEntry[];
    };
  };
};

export const PERSON_AUDIT_LOG_QUERY: TypedDocumentNode<Result, Variables> = gql`
  query personAuditLog(
    $pgaId: ID!
    $startDateTime: ISO8601DateTime!
    $endDateTime: ISO8601DateTime!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    person(pgaId: $pgaId) {
      id
      displayName
      profilePhoto
      auditLog(
        startDateTime: $startDateTime
        endDateTime: $endDateTime
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        totalCount
        nodes {
          id
          changedAt
          description
          changeset {
            id
            typeOfData
            oldValue
            newValue
          }
        }
        ...PageInfo
      }
    }
  }

  ${PAGE_INFO_FRAGMENT}
`;
