import { Link } from "react-router-dom";
import Button from "@/components/Button";
import { useQuery } from "@apollo/client";
import { CHANGE_REQUESTS_QUERY, ChangeRequestStatus, Node } from "./queries";
import { useParams } from "@/router";
import { createColumnHelper } from "@tanstack/react-table";
import usePagination from "@/hooks/usePagination";
import { useCallback, useEffect, useState } from "react";
import useActionMenu from "@/hooks/useActionMenu";
import { ChangeRequestTable, FormattedChange } from "./_components/ChangeRequestTable";
import NoteModal from "./_components/NoteModal";
import ApproveModal from "./_components/ApproveModal";
import RejectModal from "./_components/RejectModal";
import EditModal from "./_components/EditModal";
import EditsRequiredModal from "./_components/EditsRequiredModal";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import SearchInput from "@/components/SearchInput";
import { useSearch } from "@/hooks/useSearch";
import { useController } from "react-hook-form";
import StageSelect from "./_components/StageSelect";

const path = "/sections/:sectionId/change-requests";

type RowAction = { changeRequest: Node; action: "approve" | "note" | "reject" | "edit" | "editsRequired" };

const { display } = createColumnHelper<Node>();

function editableRequest({ change, status }: Node) {
  let editableType = false;

  switch (change.__typename) {
    case "BackofficeUpdateUnlistedEmploymentChange":
    case "BackofficeAddUnlistedEmploymentChange":
      editableType = true;
  }

  return editableType && status === ChangeRequestStatus.PENDING;
}

const editsRequired = (node: Node) => editableRequest(node) && !node.change.classification;

function Modal({ rowAction, onCloseRowModal }: { rowAction?: RowAction; onCloseRowModal(): void }) {
  if (!rowAction) return;

  const Component = {
    approve: ApproveModal,
    edit: EditModal,
    editsRequired: EditsRequiredModal,
    note: NoteModal,
    reject: RejectModal
  }[rowAction.action];

  return <Component changeRequest={rowAction.changeRequest} onClosed={onCloseRowModal} />;
}

const TableHeader = ({
  onChangeSearch,
  onChangeStage,
  search,
  stage
}: {
  onChangeSearch(value?: string): void;
  onChangeStage(stage?: ChangeRequestStatus): void;
  search?: string;
  stage?: ChangeRequestStatus;
}) => (
  <div className="flex md:items-center justify-between">
    <div className="flex md:items-center gap-3 flex-row w-full">
      <div className="w-full sm:max-w-[320px]">
        <SearchInput query={search || ""} placeholder="Search People" param="Name, PGA ID" onChange={onChangeSearch} />
      </div>
      <StageSelect onChange={onChangeStage} stage={stage} />
    </div>
  </div>
);

function useTableHeader() {
  const [stage, onChangeStage] = useState<ChangeRequestStatus>();
  const { searchValue, control } = useSearch("search");
  const {
    field: { onChange: onChangeSearch, value: search }
  } = useController({ control, name: "search" });

  return {
    filter: { query: searchValue || undefined, stage },
    TableHeader: useCallback(
      () => <TableHeader {...{ onChangeSearch, search, onChangeStage, stage }} />,
      [onChangeSearch, search, onChangeStage, stage]
    )
  };
}

const emptyCaption = (filter: { query?: string; stage?: ChangeRequestStatus }) =>
  Object.values(filter).some(Boolean)
    ? "No change requests in this section match the filter."
    : "This section does not have any past or current change requests.";

function ChangeRequests() {
  const { sectionId } = useParams(path);
  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const { TableHeader, filter } = useTableHeader();

  useEffect(() => {
    resetPagination();
  }, [filter.query, filter.stage, resetPagination]);

  const {
    data: currentData,
    previousData,
    loading
  } = useQuery(CHANGE_REQUESTS_QUERY, { variables: { sectionId, ...filter, ...paginationVariables } });
  const data = currentData || previousData;
  const [rowAction, setRowAction] = useState<RowAction>();
  const { Menu, ActionCell, reposition: repositionMenu } = useActionMenu();

  useRequireCapability({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewPeople });

  function onCloseRowModal() {
    setRowAction(undefined);
  }

  const { manageChangeformSettings = false, manageChangeRequests = false } = data?.section.permissions || {};

  const actionColumn = display({
    id: "actions",
    cell({ row: { original: changeRequest } }) {
      const openModal = (action: RowAction["action"]) => () => {
        setRowAction({ action, changeRequest });
      };

      if (changeRequest.status !== ChangeRequestStatus.PENDING) return null;

      return (
        <ActionCell
          id={changeRequest.id}
          actions={[
            {
              label: "Approve Change Request",
              onClick() {
                openModal(editsRequired(changeRequest) ? "editsRequired" : "approve")();
              }
            },
            {
              label: "Add Internal Note",
              onClick: openModal("note")
            },
            "divider",
            {
              label: "Reject Change Request",
              onClick: openModal("reject")
            }
          ]}
        />
      );
    }
  });

  function onEdit(changeRequest: Node, field: FormattedChange) {
    if (field.id === "classification" && editableRequest(changeRequest)) {
      return () => {
        setRowAction({ action: "edit", changeRequest });
      };
    }
  }

  return (
    <>
      <Modal rowAction={rowAction} onCloseRowModal={onCloseRowModal} />
      <Menu />
      <div>
        <div className="ml-4 mt-8 flex flex-col sm:flex-row items-start sm:items-end justify-between">
          <div className="flex-col sm:w-3/4 w-full">
            <p className="text-title-medium">Member and Associate Data Change Requests</p>
            <p className="text-body-medium mt-1">
              A list of all personal data changes that members and associates of this section have requested can be
              found below. Those that require manual approval before the data change is saved in the system are marked
              as “Needs Approval”.
            </p>
          </div>

          {manageChangeformSettings && (
            <Link to="settings" className="mt-4">
              <Button variant="outlined">Edit Settings</Button>
            </Link>
          )}
        </div>
        <div className="ml-4 mt-6">
          <ChangeRequestTable
            loading={loading}
            data={data?.section.changeRequests.nodes || []}
            renderFooter={renderFooter(data?.section.changeRequests)}
            renderHeader={TableHeader}
            emptyCaption={emptyCaption(filter)}
            onHorizontalScroll={repositionMenu}
            onEdit={manageChangeRequests ? onEdit : undefined}
            columns={[
              "submittedAt",
              "type",
              "pgaId",
              "fullName",
              "change",
              "notes",
              "status",
              ...(manageChangeRequests ? [actionColumn] : [])
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default ChangeRequests;
