import Tabs from "@/components/Tabs";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Path, useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const flags = useFlags();
  const { pgaId } = useParams();

  const location = useLocation();
  const { pathname } = location;
  const currentUrl = pathname.replace(/\/$/, "");
  const navigate = useNavigate();

  if (!pgaId) return null;

  return (
    <>
      <div className="mt-4 sm:mt-8">
        <Tabs
          tabItems={[
            {
              label: "Overview",
              to: "/people/:pgaId/details/overview" as Path,
              active: currentUrl === `/people/${pgaId}/details/overview`
            },
            {
              label: "Contact",
              to: "/people/:pgaId/details/contact" as Path,
              active: currentUrl === `/people/${pgaId}/details/contact`
            },
            {
              label: "Membership",
              to: "/people/:pgaId/details/membership" as Path,
              active: currentUrl === `/people/${pgaId}/details/membership`
            },
            ...(flags["backoffice-person-profile-employment"]
              ? [
                  {
                    label: "Employment",
                    to: "/people/:pgaId/details/employment" as Path,
                    active: currentUrl === `/people/${pgaId}/details/employment`
                  }
                ]
              : []),
            ...(flags["backoffice-person-profile-sections"]
              ? [
                  {
                    label: "Section",
                    to: "/people/:pgaId/details/section" as Path,
                    active: currentUrl === `/people/${pgaId}/details/section`
                  }
                ]
              : []),
            ...(flags["backoffice-person-profile-committees"]
              ? [
                  {
                    label: "Committees",
                    to: "/people/:pgaId/details/committees" as Path,
                    active: currentUrl === `/people/${pgaId}/details/committees`
                  }
                ]
              : []),
            {
              label: "Change Requests",
              to: "/people/:pgaId/details/change-requests" as Path,
              active: currentUrl === `/people/${pgaId}/details/change-requests`
            }
          ].map(({ label, to, active }) => ({
            label,
            onClick: () => navigate(to, { params: { pgaId } }),
            active
          }))}
        />
      </div>
      <div className="flex flex-col" data-testid="tab-content">
        {children}
      </div>
    </>
  );
}
