export enum PERMISSION {
  manageAllSectionChangeRequests = "manageAllSectionChangeRequests",
  manageOwnSectionChangeRequests = "manageOwnSectionChangeRequests",
  manageOwnSectionChangeRequestSettings = "manageOwnSectionChangeRequestSettings",
  viewMemberContributions = "viewMemberContributions",
  viewSponsorContributions = "viewSponsorContributions",
  viewOrders = "viewOrders",
  viewPayments = "viewPayments",
  manageOrders = "manageOrders",
  managePayments = "managePayments",
  viewPeople = "viewPeople",
  managePeople = "managePeople",
  manageVideos = "manageVideos",
  viewPeopleInteractions = "viewPeopleInteractions",
  managePeopleInteractions = "managePeopleInteractions",
  manageEvents = "manageEvents",
  viewJobPosts = "viewJobPosts",
  manageJobPosts = "manageJobPosts"
}

export enum DOMAIN {
  GRP = "benefit programs",
  EDUCATION = "education",
  BENEFIT_PROGRAMS = "benefit programs",
  SECTIONS = "sections",
  COMMERCE = "commerce",
  PEOPLE = "people",
  FACILITIES = "facilities",
  EVERYONE = "EVERYONE"
}

export interface Capability {
  domains: DOMAIN[];
  permissions: PERMISSION[];
}
