import { useState } from "react";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { useMutation } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { CREATE_PAYMENT_REFUND_MUTATION } from "../../[orderId]/queries";
import RefundSettings from "./steps/RefundSettings";
import RefundAmount from "./steps/RefundAmount";
import { PaymentType } from "../../[orderId]/payment/[paymentId]/types";
import { sumBy, groupBy } from "lodash";
import { OrderStage } from "@/pages/billing/types";
import { OrderPayment } from "../../[orderId]/payment/[paymentId]/types";

type RefundAllocation = {
  id: string;
  selected: boolean;
  amountCents: number;
  defaulAmountCents: number;
  name: string;
};

export type FormValues = {
  note: string;
  notifyPayor: boolean;
  dueDate: {
    startDate: Date;
    endDate: Date;
  };
  refundAllocations: RefundAllocation[];
  reopenOrder: boolean;
};

export default function RefundPaymentModal({
  payment,
  onDismiss,
  refetch,
  order,
  setErrorMessage,
  setSelectedOrder
}: {
  payment: PaymentType;
  onDismiss: () => void;
  setErrorMessage: (message: string) => void;
  refetch: () => void;
  order?: OrderPayment | null;
  setSelectedOrder: (order: OrderPayment | null) => void;
}) {
  const [createPaymentRefund, { loading }] = useMutation(CREATE_PAYMENT_REFUND_MUTATION);
  const [activeStep, setActiveStep] = useState(order ? 0 : 1);
  const currentDate = new Date();

  const defaultLineItems = Object.values(groupBy(order?.ledgerEntries, "lineItem.id")).map((objs) => ({
    id: objs[0].lineItem.id,
    amountCents: sumBy(objs, "amountCents"),
    defaulAmountCents: sumBy(objs, "amountCents"),
    name: objs[0].lineItem.name,
    selected: false,
    balance: objs[0].lineItem.balance
  }));

  const netPaymentAmount = order
    ? sumBy(defaultLineItems, "defaulAmountCents")
    : sumBy(payment.orderPayments, (obj) => sumBy(obj.ledgerEntries, "amountCents"));
  const form = useForm<FormValues>({
    defaultValues: {
      refundAllocations: defaultLineItems,
      note: "",
      notifyPayor: true,
      reopenOrder: true,
      dueDate: {
        startDate: currentDate,
        endDate: currentDate
      }
    },
    mode: "onChange"
  });

  const { watch } = form;
  const reopenOrder = watch("reopenOrder");
  const notifyPayor = watch("notifyPayor");
  const refundAllocations = watch("refundAllocations");

  const nonRefundedLineItems = order?.lineItemsOriginal?.filter(
    (item) => !refundAllocations.some((allocation) => allocation.id === item.id)
  );
  const nonRefundedLineItemsBalance = sumBy(nonRefundedLineItems, "balance");

  const filterBySelected = (items: RefundAllocation[]) => items.filter((item) => item.selected);
  const totalRefund = sumBy(filterBySelected(refundAllocations || []), "amountCents");

  const isSomeOrderOpen =
    order?.stage === OrderStage.OPEN ||
    (!order && payment.orderPayments.some((order) => order.order.stage === OrderStage.OPEN));

  async function submitPaymentRefund() {
    const formValues = form.getValues();
    const isPartialRefund = formValues.refundAllocations.length > 0;
    const input = {
      ...(isPartialRefund
        ? {
            adjustBalanceOnPartialRefunds: !formValues.reopenOrder
          }
        : { cancelOrder: !formValues.reopenOrder }),
      paymentId: payment.id,
      notes: formValues.note,
      notifyPayor: notifyPayor,
      ...((formValues.reopenOrder || (isSomeOrderOpen && nonRefundedLineItemsBalance > 0)) && {
        newOrderDueDate: formValues.dueDate.startDate
      }),

      bypassStripe: payment.bypass,
      ...(formValues.refundAllocations.length > 0 && {
        orderId: order?.id,
        refundAllocations: formValues.refundAllocations
          .filter((item) => !!item.selected)
          .map((item) => ({
            lineItemId: item.id,
            amountCents: item.amountCents
          }))
      })
    };

    const { data } = await createPaymentRefund({ variables: { input } });

    if (data.commerceCreatePaymentRefund.success) {
      onDismiss();
      refetch();
      setSelectedOrder(null);
    } else {
      setErrorMessage(`Unable to process the refund at this time. Error: ${data.commerceCreatePaymentRefund.message}`);
      onDismiss();
    }
  }
  async function next() {
    setActiveStep(activeStep + 1);
  }
  async function back() {
    setActiveStep(activeStep - 1);
  }

  const steps = [
    {
      name: "Refund Amount",
      component: <RefundAmount />
    },
    {
      name: "Refund Amount",
      component: <RefundSettings payment={payment} totalRefund={totalRefund || netPaymentAmount} order={order} />
    }
  ];

  const actions = (
    <div className="flex flex-col w-full">
      <div className="py-3">
        <div className="flex justify-end gap-1 py-1">
          <span className="text-body-medium">Net Payment Amount</span>
          <div className="text-body-medium w-[80px] text-right">{centsToDollarsFormatted(netPaymentAmount)}</div>
        </div>
        <div className="flex justify-end gap-1 py-1">
          <span className="text-label-large text-sys-brand-on-surface">Total Refund Amount</span>
          <div className="text-body-medium w-[80px] text-right">
            {centsToDollarsFormatted(order ? totalRefund : netPaymentAmount)}
          </div>
        </div>
        {activeStep === 1 && (
          <div className="flex justify-end gap-1 py-1">
            <span className="text-label-large text-sys-brand-on-surface">New Balance Due</span>
            <div className="text-body-medium w-[80px] text-right">
              {centsToDollarsFormatted(
                refundAllocations.length === 0
                  ? netPaymentAmount - totalRefund
                  : reopenOrder
                  ? (order?.balanceCents ?? 0) + totalRefund
                  : nonRefundedLineItemsBalance
              )}
            </div>
          </div>
        )}
      </div>
      {activeStep === steps.length - 1 && (
        <div className="flex justify-end sm:hidden mb-2">
          <Button onClick={submitPaymentRefund} disabled={loading}>
            Refund Payment{" "}
            {reopenOrder &&
            (order?.stage === OrderStage.PAID ||
              payment.orderPayments.some((order) => order.order.stage === OrderStage.PAID))
              ? ` and Reopen ${!order ? "Orders" : "Order"}`
              : ""}
          </Button>
        </div>
      )}
      <div className={`w-full flex ${activeStep === 0 || !order ? "justify-end" : "justify-between"} `}>
        {activeStep !== 0 && order ? (
          <Button variant="text" onClick={back}>
            Back
          </Button>
        ) : null}
        <div className="flex gap-2">
          <Button variant="text" onClick={onDismiss}>
            Cancel
          </Button>
          {activeStep !== steps.length - 1 && (
            <Button disabled={totalRefund === 0} onClick={next}>
              Next
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <div className="sm:block hidden">
              <Button onClick={submitPaymentRefund} disabled={loading}>
                Refund Payment{" "}
                {reopenOrder &&
                (order?.stage === OrderStage.PAID ||
                  payment.orderPayments.some((order) => order.order.stage === OrderStage.PAID))
                  ? ` and Reopen ${!order ? "Orders" : "Order"}`
                  : ""}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline="Refund Payment"
      className="sm:min-w-[740px] h-fit max-h-[90vh]"
    >
      <FormProvider {...form}>
        {steps.map((step, index) => (
          <div key={index}>{activeStep === index && step.component}</div>
        ))}
      </FormProvider>
    </Dialog>
  );
}
