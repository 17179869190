import { useQuery } from "@apollo/client";
import { GET_MEMBERSHIP_DETAILS_QUERY, GET_PROSPECT_PROGRAM_QUERY } from "./queries";
import { useParams } from "react-router-dom";
import { Person, Band, PlayingAbilityTest, QualifyingLevel, BackgroundCheck, EligibleEmployment } from "@/types";
import EmptyState from "@/components/EmptyState";
import Table, { TableDetails } from "@/components/Table";
import { calculateYearsOfMembership, toUTCFormat, toFormat } from "@/lib/dateHelpers";
import { getSectionName } from "@/pages/people/_utils";
import Icon from "@/components/Icon";
import Tooltip from "@/components/Tooltip";
import MetricGrid from "@/components/MetricGrid";
import { capitalize } from "lodash";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import TableDetailsPanel from "@/components/TableDetailsPanel";
import { findMostRecentPAT, formatStatus } from "./_components/utils";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import ProgramStatus from "./_components/ProgramStatus";

type PersonData = { person: Person };

const formatTableData = (bands: Band[]) =>
  bands.map((b: Band) => ({
    id: b.id,
    section: getSectionName(b),
    isPrimarySectionAffiliation: b?.sectionAffiliation?.primary,
    program: b?.programType?.name,
    classification: b?.classification?.name,
    startDate: toFormat(b.startedOn),
    endDate: toFormat(b.expiresOn),
    reason: b.createdReason ? formatStatus(b.createdReason) : null
  }));

type ActivityType = QualifyingLevel | PlayingAbilityTest | BackgroundCheck | EligibleEmployment;

const createBaseActivity = (activity: string, activityType: ActivityType, completed: boolean) => {
  const { status, startDate, endDate, expirationDate } = activityType || {};
  return {
    id: activity,
    activity,
    status: formatStatus(status, "Not Started"),
    date: startDate ? toUTCFormat(startDate) : endDate ? toUTCFormat(endDate) : null,
    expiredAt: expirationDate && toUTCFormat(expirationDate),
    lineItems: [],
    completed
  };
};

const createPgaAccountActivity = (accountCreatedAt?: string) => ({
  id: "pga-account",
  activity: "PGA Account",
  status: "Complete",
  date: toUTCFormat(accountCreatedAt || ""),
  expiredAt: null,
  lineItems: [],
  completed: true
});

export default function MembershipDetails() {
  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_MEMBERSHIP_DETAILS_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  const {
    data: qualifyingLevelData,
    loading: qualifyingLevelLoading,
    error: qualifyingLevelError
  } = useQuery<PersonData>(GET_PROSPECT_PROGRAM_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error || qualifyingLevelError) return <div>Error: {error?.message || qualifyingLevelError?.message}</div>;

  const { bands, membershipSince, latestProgram, duesBalance } = data?.person ?? {};
  const tableData = !!bands?.length ? formatTableData(bands) : [];
  const programTypeName = latestProgram?.programType?.name;

  const prospectProgram = qualifyingLevelData?.person?.prospectProgram;
  const accountCreatedAt = qualifyingLevelData?.person?.accountCreatedAt;
  const employmentStatus = prospectProgram?.eligibleEmployment?.id ? "Complete" : "Not Started";
  const prospectProgramTableData = prospectProgram
    ? prospectProgram.membershipInterestForm
      ? [
          // If membership interest form exists, show all activities (and set status to "Not Started" for those that don't have a status)
          createBaseActivity(
            "Associate Program Application",
            prospectProgram?.latestAssociateApplication,
            ["Approved", "Elected"].includes(prospectProgram?.latestAssociateApplication?.status || "")
          ),
          createBaseActivity(
            "Eligible Employment",
            {
              ...prospectProgram?.eligibleEmployment,
              status: employmentStatus
            },
            !!prospectProgram?.eligibleEmployment?.id
          ),
          createBaseActivity(
            "Qualifying Level Courses",
            prospectProgram?.qualifyingLevel,
            prospectProgram?.qualifyingLevel?.status === "COMPLETED"
          ),
          createBaseActivity(
            "Playing Ability Test (PAT)",
            findMostRecentPAT(prospectProgram?.playingAbilityTest),
            ["PASSED", "QUALIFIED"].includes(findMostRecentPAT(prospectProgram?.playingAbilityTest)?.status || "")
          ),
          createBaseActivity(
            "Background Check",
            prospectProgram?.backgroundCheck,
            prospectProgram?.backgroundCheck?.status === "APPROVED"
          ),
          {
            id: "pga-membership-interest-form",
            activity: "PGA Membership Interest Form",
            status: "Complete",
            date: toUTCFormat(prospectProgram.membershipInterestForm.startedAt),
            completed: true,
            expiredAt: null,
            lineItems: [
              {
                id: "reason-for-interest",
                item: "Reason For Interest",
                answer: prospectProgram.membershipInterestForm.reasonForBecomingMember
              }
            ]
          },
          createPgaAccountActivity(accountCreatedAt)
        ]
      : [
          // If no membership interest form exists, only show activities that have data
          ...(!!prospectProgram?.latestAssociateApplication
            ? [
                createBaseActivity(
                  "Associate Program Application",
                  prospectProgram?.latestAssociateApplication,
                  ["Approved", "Elected"].includes(prospectProgram?.latestAssociateApplication?.status || "")
                )
              ]
            : []),
          ...(!!prospectProgram?.eligibleEmployment
            ? [
                createBaseActivity(
                  "Eligible Employment",
                  {
                    ...prospectProgram?.eligibleEmployment,
                    status: employmentStatus
                  },
                  !!prospectProgram?.eligibleEmployment?.id
                )
              ]
            : []),
          ...(!!prospectProgram?.qualifyingLevel
            ? [
                createBaseActivity(
                  "Qualifying Level Courses",
                  prospectProgram?.qualifyingLevel,
                  prospectProgram?.qualifyingLevel?.status === "COMPLETED"
                )
              ]
            : []),
          ...(!!prospectProgram?.playingAbilityTest?.length
            ? [
                createBaseActivity(
                  "Playing Ability Test (PAT)",
                  findMostRecentPAT(prospectProgram?.playingAbilityTest),
                  ["PASSED", "QUALIFIED"].includes(findMostRecentPAT(prospectProgram?.playingAbilityTest)?.status || "")
                )
              ]
            : []),
          ...(prospectProgram?.backgroundCheck
            ? [
                createBaseActivity(
                  "Background Check",
                  prospectProgram?.backgroundCheck,
                  prospectProgram?.backgroundCheck?.status === "APPROVED"
                )
              ]
            : []),
          ...(accountCreatedAt ? [createPgaAccountActivity(accountCreatedAt)] : [])
        ]
    : [];

  const metrics = ["Member", "Associate", "Affiliate"].includes(programTypeName || "")
    ? [
        {
          label: `${programTypeName} Since`,
          value: toUTCFormat(membershipSince || "")
        },
        {
          label: `Total Years in ${programTypeName} Program`,
          value: calculateYearsOfMembership(membershipSince || "")
        },
        {
          label: "Classification",
          value: latestProgram?.band?.classification?.name
        },
        {
          label: "Membership Status",
          value: capitalize(latestProgram?.status!)
        },
        {
          label: "Dues Balance",
          value: centsToDollarsFormatted(duesBalance!)
        }
      ]
    : [];

  return (
    <div>
      {!!metrics.length && (
        <div className="mt-4">
          <MetricGrid metrics={metrics} layout="expanded" />
        </div>
      )}
      <Table
        data={tableData}
        loading={loading}
        renderDetails={() => (
          <TableDetails
            heading="Membership History"
            body="View membership history including changes to program and classification below"
          />
        )}
        columns={[
          {
            size: 250,
            header: "Section",
            id: "section",
            cell: ({ row }) => (
              <div className="flex items-center gap-2">
                {row.original.isPrimarySectionAffiliation && (
                  <Tooltip
                    variant="plain"
                    supportingText="Primary"
                    color="black"
                    menuCorner="end-start"
                    anchorCorner="start-start"
                  >
                    <Icon name="check" size={24} />
                  </Tooltip>
                )}

                {row.original.section}
              </div>
            )
          },
          { accessorKey: "program", header: "Program", id: "program", enableSorting: false },
          { accessorKey: "classification", header: "Classification", id: "classification", enableSorting: false },
          { accessorKey: "startDate", header: "Start Date", id: "startDate", enableSorting: false },
          { accessorKey: "endDate", header: "End Date", id: "endDate", enableSorting: false },
          { accessorKey: "reason", header: "Reason", id: "reason", enableSorting: false }
        ]}
        renderEmptyState={() => (
          <EmptyState title="No Membership History" caption="This person has no membership history" iconName="today" />
        )}
        data-testid="membership-history-table"
      />

      {(qualifyingLevelLoading || !!prospectProgramTableData.length) && (
        <Table
          data={prospectProgramTableData}
          loading={qualifyingLevelLoading}
          renderDetails={() => (
            <TableDetails
              heading="Prospect Program"
              body="View prospect program history including all activities required for becoming an affiliate/associate below"
            />
          )}
          renderExpandedRow={({ row }) =>
            !!row.original.lineItems.length ? (
              <TableDetailsPanel
                data={row.original.lineItems}
                columns={[
                  {
                    accessorKey: "item",
                    header: "Item",
                    enableSorting: false,
                    size: 100,
                    cell: (cellProps) => {
                      return <span>{cellProps.row.original.item}</span>;
                    }
                  },
                  {
                    accessorKey: "answer",
                    header: "Answer",
                    enableSorting: false,
                    size: 500,
                    meta: { className: "text-left pl-20", headerClassName: "pl-16" },
                    cell: (cellProps) => {
                      return <span>{cellProps.row.original.answer}</span>;
                    }
                  }
                ]}
              />
            ) : (
              <></>
            )
          }
          columns={[
            {
              size: 250,
              header: "Activity",
              id: "activity",
              accessorKey: "activity",
              meta: {
                className: getStickyColumnClasses(!!prospectProgramTableData?.length)
              }
            },
            {
              accessorKey: "status",
              header: "Stage",
              id: "status",
              enableSorting: false,
              cell: ({ row }) => {
                return !!row.original.lineItems.length ? (
                  <div
                    className="flex items-center gap-2 py-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    <span>{row.original.status}</span>
                    <Icon name={row.getIsExpanded() ? "expand_less" : "expand_more"} />
                  </div>
                ) : (
                  <div className="flex items-center gap-2 py-4 cursor-pointer">
                    <span>{row.original.status}</span>
                  </div>
                );
              }
            },
            { accessorKey: "date", header: "Date", id: "date", enableSorting: false },
            { accessorKey: "expiredAt", header: "Expiration", id: "expiredAt", enableSorting: false },
            {
              accessorKey: "programStatus",
              header: "Program Status",
              id: "programStatus",
              enableSorting: false,
              cell: ({ row }) => <ProgramStatus completed={row.original.completed} />
            }
          ]}
          renderEmptyState={() => <></>}
          data-testid="qualifying-level-table"
        />
      )}
    </div>
  );
}
