import Banner from "@/components/Banner";
import { Event } from "../CreateGlobalInteractionSideSheet";

export const UnavailableEventBanner = ({ event }: { event: Event }) => {
  return (
    <Banner
      variant="error"
      description={`Interactions cannot be added for people at ${event.title} at this time. Try selecting another event.`}
      inline
    />
  );
};
