import { Controller, useFormContext } from "react-hook-form";
import RichTextEditor from "@/components/RichTextEditor";
import { handleEditorChange, handleInitialState } from "@/pages/events/_form/utils/markdownUtils";

interface RichTextToMarkdownProps {
  fieldName: string;
  placeholder?: string;
}

const RichTextToMarkdown = ({ fieldName, placeholder }: RichTextToMarkdownProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RichTextEditor
          placeholder={placeholder || ""}
          onInitialState={(editor) => handleInitialState(editor, value)}
          onChange={(editorState) => handleEditorChange(editorState, onChange)}
        />
      )}
    />
  );
};

export default RichTextToMarkdown;
