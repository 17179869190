import { Controller, useFormContext } from "react-hook-form";
import TextField from "@/components/TextField";

const AddressFields = () => {
  const {
    control,
    clearErrors,
    trigger,
    formState: { errors }
  } = useFormContext();
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-2">
        <div className="col-span-1 relative">
          <Controller
            name="address1"
            data-testid="address1-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Street Address"
                className="w-full"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if ((e.target as HTMLInputElement).value && errors.address1) {
                    clearErrors("address1");
                  }
                  if (!(e.target as HTMLInputElement).value) {
                    trigger("address1");
                  }
                }}
                required
                error={!!errors.address1}
                errorText={errors.address1?.message as string}
              />
            )}
          />
        </div>
        <div className="col-span-1 relative">
          <Controller
            name="address2"
            data-testid="address2-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Street Address Line 2"
                className="w-full"
                value={value}
                onChange={onChange}
                error={!!errors.address2}
                errorText={errors.address2?.message as string}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-8 gap-6 sm:gap-2">
        <div className="col-span-1 sm:col-span-4 xl:col-span-6 relative">
          <Controller
            name="city"
            data-testid="city-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="City"
                className="w-full"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if ((e.target as HTMLInputElement).value && errors.city) {
                    clearErrors("city");
                  }
                  if (!(e.target as HTMLInputElement).value) {
                    trigger("city");
                  }
                }}
                required
                error={!!errors.city}
                errorText={errors.city?.message as string}
              />
            )}
          />
        </div>
        <div className="relative col-span-1 sm:col-span-2 xl:col-span-1">
          <Controller
            name="state"
            data-testid="state-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="State"
                className="w-full"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if ((e.target as HTMLInputElement).value && errors.state) {
                    clearErrors("state");
                  }
                  if (!(e.target as HTMLInputElement).value) {
                    trigger("state");
                  }
                }}
                required
                error={!!errors.state}
                errorText={errors.state?.message as string}
              />
            )}
          />
        </div>
        <div className="relative col-span-1 sm:col-span-2 xl:col-span-1">
          <Controller
            name="zipcode"
            data-testid="zipcode-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Zip"
                className="w-full"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if ((e.target as HTMLInputElement).value && errors.zipcode) {
                    clearErrors("zipcode");
                  }
                  if (!(e.target as HTMLInputElement).value) {
                    trigger("zipcode");
                  }
                }}
                required
                error={!!errors.zipcode}
                errorText={errors.zipcode?.message as string}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default AddressFields;
