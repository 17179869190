import { SALESFORCE_PERSON_FRAGMENT } from "@/fragments";
import { gql } from "@apollo/client";
import { InteractionStats } from "./types";

export const GET_PERSON_INTERACTIONS_QUERY = gql`
  query person($search: ID!, $first: Int, $after: String) {
    person(pgaId: $search) {
      ...SalesforcePersonFragment
    }
  }
  ${SALESFORCE_PERSON_FRAGMENT}
`;

export const GET_PERSON_INTERACTION_QUERY = gql`
  query crmInteraction($id: ID!) {
    crmInteraction(id: $id) {
      id
      categories
      owner
      interactionDate
      lastModifiedDate
      lastMessage {
        content
        createdAt
        isPrivate
        fromOwner
        haveAccess
      }
      messages {
        content
        createdAt
        isPrivate
        fromOwner
        haveAccess
      }
      messagesCount
      mode
      participants {
        currentStatus
        employments {
          isPrimary
          facility {
            name
          }
        }
        firstName
        lastName
        pgaId
        profilePhoto
        classification
        primarySectionAffiliation {
          section {
            name
          }
        }
      }
      summary
      relatedTo
    }
  }
`;

export const CREATE_INTERACTION_MUTATION = gql`
  mutation CreateInteraction($input: BackofficeCRMCreateInteractionInput!) {
    createInteraction(input: $input) {
      interaction {
        id
        categories
        createdAt
        owner
        interactionDate
        lastModifiedDate
        lastMessage {
          content
          createdAt
          isPrivate
          fromOwner
          haveAccess
        }
        messages {
          content
          createdAt
          isPrivate
          fromOwner
          haveAccess
        }
        messagesCount
        mode
        participants {
          currentStatus
          employments {
            isPrimary
            facility {
              name
            }
          }
          firstName
          lastName
          pgaId
          profilePhoto
          classification
          primarySectionAffiliation {
            section {
              name
            }
          }
        }
        summary
        relatedTo
      }
      success
      message
    }
  }
`;

export const CREATE_GROUP_INTERACTION_MUTATION = gql`
  mutation CreateInteraction($input: BackofficeCRMCreateGroupInteractionInput!) {
    createGroupInteraction(input: $input) {
      interaction {
        id
        mode
        lastModifiedDate
        lastMessage {
          content
          createdAt
          isPrivate
        }
      }
      success
      message
    }
  }
`;

export const DELETE_INTERACTION_MUTATION = gql`
  mutation DeleteInteraction($id: ID!) {
    deleteInteraction(id: $id) {
      success
      message
    }
  }
`;

export const UPDATE_INTERACTION_MUTATION = gql`
  mutation UpdateInteraction($input: BackofficeCRMUpdateInteractionInput!) {
    updateInteraction(input: $input) {
      success
      message
    }
  }
`;

export const SHARE_INTERACTION_MUTATION = gql`
  mutation ShareInteraction($input: BackofficeCRMShareInteractionInput!) {
    shareInteraction(input: $input) {
      success
      message
    }
  }
`;

export const statsData: InteractionStats = {
  totalInteractions: 1001,
  totalPhoneInteractions: 99,
  totalVideoInteractions: 97,
  totalSmsInteractions: 95,
  totalEmailInteractions: 42,
  totalInPersonInteractions: 14
};

export const CATEGORIES_QUERY = gql`
  query InteractionActivityTypesQuery {
    interactionActivityTypes
  }
`;

export type CategoriesData = { interactionActivityTypes: string[] };

// allModesData should eventually be pulled dynamically from the backend

export const allModesData = [
  { displayText: "Email", value: "Email" },
  { displayText: "In-Person", value: "In-Person" },
  { displayText: "Phone Call", value: "Phone Call" },
  { displayText: "Text Message", value: "Text Message" },
  { displayText: "Video Call", value: "Video Chat" }
];

export const modesMap = {
  EMAIL: "Email",
  IN_PERSON: "In-Person",
  PHONE_CALL: "Phone Call",
  TEXT: "Text Message",
  VIDEO_CHAT: "Video Chat"
};

export const interactionPlaceholder =
  "What was the purpose of the conversation? Was there anything noteworthy that stood out to you? Note any specific details that were discussed, including how the conversation concluded and if there were there any next steps outlined.";
