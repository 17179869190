import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCapabilities, { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import Button from "@/components/Button";
import { useLazyQuery, useQuery } from "@apollo/client";
import ObjectHeading from "@/components/ObjectHeading";
import { renderStatus, renderPaymentType, renderPaymentStatus, paymentDate } from "../../_utils";
import { useActions } from "./_hooks";
import { ORDER_QUERY, PDF_QUERY } from "./queries";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { OrderType, LineItemsType, OrderQuery } from "./types";
import Progress from "@/components/Progress";
import type { Params, Path } from "@/router";
import { toFormat } from "@/lib/dateHelpers";
import Menu from "@/components/Menu";
import IconButton from "@/components/IconButton";
import { useNavigate } from "@/router";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { isNull } from "lodash";
import { OrderStage } from "../../types";
import { CancelOrderModal } from "./_components/CancelOrderModal";
import { OpenOrderModal } from "./_components/OpenOrderModal";
import { ChangeDueDateModal } from "./_components/ChangeDueDateModal";
import { openBrowser } from "@/lib/urlUtils";
import DetailsCard from "@/components/DetailsCard";
import { ButtonWithLink } from "@/components/withLink";
import { LinkProps } from "@generouted/react-router/client";
import PageTitle from "@/components/PageTitle";
import { useReturnTo } from "@/hooks/useSearchParams";
import { sumBy } from "lodash";
import pluralize from "@/lib/pluralize";
import PersonDetailsPanel from "../_components/PersonDetailsPanel";

interface LineItemTableData extends LineItemsType {
  removed?: boolean;
  price?: string | null;
  amount?: string | null;
  adjustment?: boolean;
  createdAt?: string;
  amountCents?: number;
}

const Order = () => {
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const { orderId } = useParams();
  const { error, data, refetch } = useQuery<OrderQuery>(ORDER_QUERY, { variables: { orderId } });
  const order = data?.commerceOrder;

  if (error) {
    return <EmptyState title="Error" caption="An error occurred while loading this order." iconName="error" />;
  } else if (order) {
    return <OrderWrapper order={order} refetch={refetch} />;
  } else {
    return (
      <div className="p-10 flex items-center justify-center">
        <Progress />
      </div>
    );
  }
};

const OrderWrapper = ({ order, refetch }: { order: OrderType; refetch: () => void }) => {
  const { dueDate, balance, stage, person, totalPaid, totalRefunded, description, pdfAvailable }: OrderType = order;

  const { setActions, setBackLink, setExtendedActions } = useActions();
  const { hasPermission } = useCapabilities();
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const navigate = useNavigate();
  const [openOpenOrderModal, setOpenOpenOrderModal] = useState(false);
  const [openChangeDueDateModal, setOpenChangeDueDateModal] = useState(false);

  const returnTo = useReturnTo();
  useEffect(() => {
    setBackLink({ to: returnTo || "/billing/orders" } as unknown as LinkProps<Path, Params>);
  }, [setBackLink, returnTo]);

  useEffect(() => {
    const actions = [];
    const extendedActions = [];

    if (hasPermission(PERMISSION.manageOrders)) {
      if ((stage === OrderStage.DRAFT || stage === OrderStage.OPEN) && totalPaid == 0) {
        actions.push(
          <Button
            variant="text"
            onClick={() => {
              setOpenCancelOrderModal(true);
            }}
            testId="cancel-order"
            key="cancel-order"
          >
            Cancel Order
          </Button>
        );
      }

      if (stage === OrderStage.OPEN) {
        extendedActions.push(
          <Button
            variant="outlined"
            onClick={() => {
              setOpenChangeDueDateModal(true);
            }}
            key="change-due-date"
          >
            Change Due Date
          </Button>
        );
      }
    }
    if (hasPermission(PERMISSION.managePayments)) {
      if (totalPaid > 0 || stage === OrderStage.DRAFT || (stage === OrderStage.OPEN && totalPaid == 0)) {
        actions.push(
          <ButtonWithLink
            variant="outlined"
            key="edit-order"
            link={{ to: "/billing/orders/:orderId/edit", params: { orderId: order.id } }}
          >
            Edit Order
          </ButtonWithLink>
        );
      }

      if (order.payable) {
        actions.push(
          <Button
            variant="filled"
            key="apply-payment"
            onClick={() => navigate(`/billing/orders/apply-payment?orderId=${order.id}` as Path)}
          >
            Apply Payment
          </Button>
        );
      }
    }
    if (hasPermission(PERMISSION.manageOrders)) {
      if (stage === OrderStage.DRAFT) {
        actions.push(
          <Button
            onClick={() => {
              setOpenOpenOrderModal(true);
            }}
            testId="open-order"
            key="open-order"
          >
            Open Order
          </Button>
        );
      }
    }
    setActions(actions);
    setExtendedActions(extendedActions);
  }, [stage, hasPermission, setActions, totalPaid, navigate, order, setExtendedActions]);

  const lineItems = (order?.lineItems || []).reduce<LineItemTableData[]>((acc, item) => {
    const { proration, utilization } = item;
    acc.push({
      ...item,
      price: centsToDollarsFormatted(item.priceCents),
      amount: centsToDollarsFormatted(item.priceCents * item.quantity),
      amountCents: item.priceCents * item.quantity
    });

    if (item.removedAt) {
      acc.push({
        ...item,
        subLineItem: true,
        removed: true,
        amount: centsToDollarsFormatted(-1 * item.priceCents * item.quantity),
        amountCents: -1 * item.priceCents * item.quantity
      });
    }

    if (item.ledgerEntries.some((entry) => entry.type === "adjustment_partial_refund")) {
      acc.push({
        ...item,
        adjustment: true,
        createdAt: item.ledgerEntries.find((entry) => entry.type === "adjustment_partial_refund")?.createdAt,
        amount: centsToDollarsFormatted(
          -(item.ledgerEntries.find((entry) => entry.type === "adjustment_partial_refund")?.amountCents ?? 0)
        ),
        amountCents: -(item.ledgerEntries.find((entry) => entry.type === "adjustment_partial_refund")?.amountCents ?? 0)
      });
    }

    if (proration?.proratedNumberOfMonths && proration.proratedPrice) {
      const adjustedPrice = proration.proratedPrice - proration.originalPrice;

      acc.push({
        ...item,
        id: `${item.id}-proration`,
        subLineItem: true,
        name: `Proration ${proration.proratedNumberOfMonths} ${pluralize(
          item.proration.proratedNumberOfMonths,
          "Month"
        )} Original/Adjustment: ${centsToDollarsFormatted(proration.originalPrice)}/${centsToDollarsFormatted(
          adjustedPrice
        )}`
      });
    }

    if (utilization?.utilizedStart && utilization?.utilizedEnd) {
      const utilizedStart = toFormat(utilization?.utilizedStart, "MMM yyyy") || "";
      const utilizedEnd = toFormat(utilization?.utilizedEnd, "MMM yyyy") || "";

      acc.push({
        ...item,
        id: `${item.id}-utilization`,
        subLineItem: true,
        name: `Dues (${utilizedStart} - ${utilizedEnd})  Paid/Credit: ${centsToDollarsFormatted(
          utilization.originalPrice
        )}/-${centsToDollarsFormatted(utilization.creditForwardedAmount)}`
      });
    }

    return acc;
  }, []);

  const lineItemsTotal = sumBy(lineItems, "amountCents");

  const [pdfQuery, { loading }] = useLazyQuery<OrderQuery>(PDF_QUERY, { variables: { orderId: order.id } });

  const handlePDFClick = async () => {
    const { data } = await pdfQuery();
    const pdfUrl = data?.commerceOrder?.pdfUrl;
    pdfUrl && openBrowser(pdfUrl);
  };

  return (
    <div className="flex max-w-[1440px] mx-auto ">
      <PageTitle
        pattern={location.pathname}
        title={`Billing | Orders | Order Details | ${description} for #${person.pgaId}`}
      />
      <div className=" mt-3 sm:mt-8" data-testid="order-details-page">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
          <ObjectHeading title={`${description} for #${person.pgaId}`} iconName="receipt_long" isDetailsView>
            <div
              className="flex flex-col sm:flex-row flex-wrap text-title-medium text-sys-brand-on-surface gap-1 sm:gap-0 mt-1 sm:mt-0"
              style={{ marginLeft: "-16px" }}
            >
              {dueDate && (
                <div className="px-4 sm:border-r border-sys-brand-outline-variant">Due Date: {toFormat(dueDate)}</div>
              )}
              {!isNull(lineItemsTotal) && (
                <div className="px-4 sm:border-r border-sys-brand-outline-variant">
                  Amount: {centsToDollarsFormatted(lineItemsTotal)}
                </div>
              )}
              {!isNull(balance) && <div className="px-4">Balance: {centsToDollarsFormatted(balance)}</div>}
            </div>
          </ObjectHeading>
          <div className="mt-4 sm:mt-0">{renderStatus(stage, order.paymentStatus)}</div>
        </div>
        {description && (
          <>
            <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-1">Description</h2>
            <p className="text-body-medium text-sys-brand-on-surface mb-3">{description}</p>
          </>
        )}
        {pdfAvailable && (
          <Button onClick={handlePDFClick} variant="outlined" testId="order-pdf">
            <div className="flex items-center justify-center">
              {loading && (
                <div className="mr-2">
                  <Progress size={24} />
                </div>
              )}
              View Order PDF
            </div>
          </Button>
        )}
        <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Summary</h2>
        <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 mb-4">
          {person && (
            <div>
              <DetailsCard
                heading="Billed To"
                options={[
                  {
                    picture: person.profilePhoto,
                    iconName: "person",
                    overline: "Name",
                    headline: `${person.firstName} ${person.lastName}`,
                    supportingText: "",
                    action: () => setIsDetailsPanelOpen(true)
                  }
                ]}
              />
            </div>
          )}
          {person.contactAddress && (
            <DetailsCard
              heading="Billing Address"
              options={[
                {
                  iconName: "home",
                  overline: "Home",
                  headline: `${person.contactAddress.address1}, ${person.contactAddress.city}, ${person.contactAddress.state}, ${person.contactAddress.postalCode}`,
                  supportingText: ""
                }
              ]}
            />
          )}
          {person.email && (
            <DetailsCard
              heading="Email Address"
              options={[
                {
                  iconName: "mail",
                  headline: person.email
                }
              ]}
            />
          )}
        </div>
        <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Products</h2>
        <Table
          data={lineItems}
          loading={false}
          columns={[
            {
              header: "Product Name",
              accessorKey: "name",
              enableSorting: false,
              size: 600,
              meta: {
                className: getStickyColumnClasses(!!lineItems?.length)
              },
              cell: ({ row }) => {
                if (row.original.removed) {
                  return <div className="ml-6">Removed from order</div>;
                }
                if (row.original.adjustment) {
                  return <div className="ml-6">Partial Refund Adjustment</div>;
                }
                if (row.original.subLineItem) {
                  return <div className="ml-6">{row.original.name}</div>;
                }
                return <div>{row.original.name}</div>;
              }
            },
            {
              header: "Price",
              accessorKey: "price",
              size: 400,
              enableSorting: false,
              cell: ({ row }) => <div>{row.original.subLineItem ? "" : row.original.price}</div>
            },
            {
              header: "Quantity",
              accessorKey: "quantity",
              size: 400,
              enableSorting: false,
              cell: ({ row }) => <div>{row.original.subLineItem ? "" : row.original.quantity}</div>
            },
            {
              header: "Amount",
              accessorKey: "amount",
              enableSorting: false,
              size: 40,
              meta: { className: "text-right pr-7 md:pr-20", headerClassName: "justify-end pr-4 md:pr-16" }
            }
          ]}
          renderFooter={() => (
            <>
              <div className="pb-3">
                <div className="flex justify-end">
                  <h5 className="text-label-large">Order Total</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-4 md:mr-[68px]">
                    {centsToDollarsFormatted(lineItemsTotal)}
                  </div>
                </div>
              </div>
              <div className="border-t -ml-3 w-[calc(100%+1.5rem)]" />
              <div className="pt-3 flex flex-col justify-end">
                <div className="flex justify-end pb-2">
                  <h5 className="text-body-medium">Total Amount Paid</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-4 md:mr-[68px]">
                    {centsToDollarsFormatted(-(totalPaid + totalRefunded))}
                  </div>
                </div>
                {totalRefunded > 0 && (
                  <div className="flex justify-end pb-2">
                    <h5 className="text-body-medium">Total Refund Amount</h5>
                    <div className="flex justify-end text-body-medium w-[100px] mr-1 md:mr-[68px]">
                      {centsToDollarsFormatted(totalRefunded)}
                    </div>
                  </div>
                )}
                <div className="flex justify-end">
                  <h5 className="text-label-large">Balance Due</h5>
                  <div className="flex justify-end text-body-medium w-[100px] mr-4 md:mr-[68px]">
                    {centsToDollarsFormatted(balance)}
                  </div>
                </div>
              </div>
            </>
          )}
          renderEmptyState={() => (
            <EmptyState title="No Products" caption="This order does not have any products." iconName="box" />
          )}
        />
        {order.orderPayments.length > 0 && hasPermission(PERMISSION.viewPayments) && <Payments order={order} />}

        {openCancelOrderModal && (
          <CancelOrderModal
            onDismiss={() => {
              setOpenCancelOrderModal(false);
            }}
            order={order}
            refetch={refetch}
          />
        )}
        {openOpenOrderModal && (
          <OpenOrderModal
            onDismiss={() => {
              setOpenOpenOrderModal(false);
            }}
            order={order}
            refetch={refetch}
          />
        )}
        {openChangeDueDateModal && (
          <ChangeDueDateModal
            onDismiss={() => {
              setOpenChangeDueDateModal(false);
            }}
            order={order}
            refetch={refetch}
          />
        )}
      </div>
      {isDetailsPanelOpen && (
        <PersonDetailsPanel
          isDetailsPanelOpen={isDetailsPanelOpen}
          setIsDetailsPanelOpen={setIsDetailsPanelOpen}
          closeDetailsPanel={() => setIsDetailsPanelOpen(false)}
          pgaId={person.pgaId}
        />
      )}
    </div>
  );
};

const Payments = ({ order }: { order: OrderType }) => {
  const { orderPayments } = order;
  const navigate = useNavigate();
  return (
    <>
      <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Payments</h2>
      <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 mb-4">
        {orderPayments.map((orderPayment) => {
          return (
            <div key={orderPayment.id}>
              <DetailsCard
                className="py-4 pl-4 pr-0"
                options={[
                  {
                    iconName: "monetization_on",
                    overline: `${renderPaymentType(orderPayment.payment.type)}`,
                    headline: `${orderPayment.payment.payer.name}`,
                    supportingText: `${paymentDate(orderPayment.payment.type, orderPayment.payment.paidAt)}`,
                    action: () =>
                      navigate(`/billing/orders/:orderId/payment/:paymentId`, {
                        params: { orderId: order.id, paymentId: orderPayment.payment.id }
                      }),
                    renderActions: () => (
                      <div className="flex items-center -mt-3">
                        <span className="text-label-small text-sys-brand-on-surface-variant">
                          {centsToDollarsFormatted(orderPayment.amountCents)}
                        </span>
                        <Menu
                          AnchorComponent={
                            <IconButton
                              name="more_vert"
                              className="text-sys-brand-on-surface"
                              iconClassName="font-extrabold"
                            />
                          }
                          menuItems={[
                            {
                              label: "View Payment",
                              onClick: () =>
                                navigate(`/billing/orders/:orderId/payment/:paymentId`, {
                                  params: { orderId: order.id, paymentId: orderPayment.payment.id }
                                })
                            }
                          ]}
                        />
                      </div>
                    ),
                    status: () => renderPaymentStatus(orderPayment.payment.status)
                  }
                ]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Order;
