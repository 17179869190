import PATMetricCard from "./PATMetricCard";
import { PATMetricType } from "../types";

interface PATMetricGridProps {
  metrics: PATMetricType[];
}

export default function PATMetricGrid({ metrics }: PATMetricGridProps) {
  return (
    <div className="grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-2 items-start" data-testid="pat-metrics">
      {metrics.map((metric, i) => (
        <PATMetricCard key={i} {...metric} />
      ))}
    </div>
  );
}
