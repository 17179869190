import DateSelect from "@/components/DateSelect";
import { toISO } from "@/lib/dateHelpers";
import { DateTime } from "luxon";
import { DateRangePickerProps } from "../types";

export default function DateRangePicker({ variables, setVariables }: DateRangePickerProps) {
  return (
    <DateSelect
      label="Date Range"
      maxDate={new Date()}
      value={{
        startDate: variables.startDateTime,
        endDate: variables.endDateTime
      }}
      onChange={(e) => {
        if (typeof e === "object" && e !== null) {
          if (e.startDate === null && e.endDate === null) {
            setVariables({
              ...variables,
              startDateTime: DateTime.now().minus({ days: 30 }).toISO(),
              endDateTime: DateTime.now().toISO()
            });
          } else {
            setVariables({
              ...variables,
              startDateTime: toISO(e.startDate?.toLocaleString() || ""),
              endDateTime: toISO(e.endDate?.toLocaleString() || "")
            });
          }
        }
      }}
    />
  );
}
