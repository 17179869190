import { PropsWithChildren, useCallback, useState } from "react";
import TopActionBarContext, { SideBar } from "./TopActionBarContext";

export default function TopActionBarProvider({ children }: PropsWithChildren<{}>) {
  const [currentSidebar, setTopActionBarState] = useState<SideBar>();

  const value = {
    currentSidebar,
    showSidebar: useCallback(
      (sidebar: SideBar) => () => {
        setTopActionBarState(sidebar);
      },
      []
    ),
    toggleSidebar: useCallback(
      (sideBar: SideBar) => (show: boolean) => {
        setTopActionBarState(show ? sideBar : undefined);
      },
      []
    )
  };

  return <TopActionBarContext.Provider value={value}>{children}</TopActionBarContext.Provider>;
}
