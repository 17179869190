import { ListItemProps } from "@/components/ListItem/ListItem";
import { toFormat } from "@/lib/dateHelpers";
import { Address, Employment, Person, PhoneNumber, Band } from "@/types";
import { capitalize } from "lodash";

export function phoneOptions(phoneNumbers: PhoneNumber[] | undefined): ListItemProps[] {
  const phoneNumbersList =
    phoneNumbers?.length === 0
      ? [
          {
            phoneFormatted: null,
            phoneType: "home"
          }
        ]
      : phoneNumbers;

  return (
    phoneNumbersList?.map((phoneNumber) => ({
      iconName: "call",
      headline: phoneNumber.phoneFormatted,
      overline: capitalize(phoneNumber.phoneType)
    })) || []
  );
}

export function emailOptions(email: string | undefined): ListItemProps[] {
  const emailAddress = email ? email : null;

  return [{ iconName: "mail", headline: emailAddress }];
}

export function addressOptions(addresses: Address[] | undefined): ListItemProps[] {
  const formatAddressType = (addressType: string) => {
    return addressType === "home" ? "primary" : addressType;
  };
  const addressList =
    addresses?.length === 0
      ? [
          {
            addressType: "home",
            address1: null,
            address2: null,
            city: null,
            state: null,
            postalCode: null
          }
        ]
      : addresses || [];

  return addressList.map((address) => {
    const { address1, address2, city, state, postalCode } = address;
    return {
      iconName: "home",
      overline: capitalize(formatAddressType(address.addressType)),
      headline: address1 ? (
        <>
          <span>
            {address1}
            {address2 ? `, ${address2}` : ""}
          </span>
          <br />
          <span>{`${city}, ${state} ${postalCode}`}</span>
        </>
      ) : null
    };
  });
}

export function employmentOptions(employments: Employment[]): ListItemProps[] {
  const employmentList =
    employments.length === 0
      ? [
          {
            title: null
          }
        ]
      : employments;

  return employmentList.map((employment) => ({
    iconName: "store",
    headline: employment.title,
    overline: "Job Title"
  }));
}
export function facilityOptions(employment: Employment): ListItemProps[] {
  const primaryEmployment = !employment
    ? {
        facility: {
          name: null
        }
      }
    : employment;
  let details: ListItemProps[] = [];

  if (!!primaryEmployment?.facility) {
    details.push({
      iconName: "location_on",
      overline: "Facility Name",
      headline: primaryEmployment.facility.name
    });
  }
  return details;
}

export function membershipOptions(band: Band | undefined): ListItemProps[] {
  const primaryBand = !band
    ? {
        section: {
          name: null
        },
        expiresOn: "--",
        startedOn: "--"
      }
    : band;

  let details: ListItemProps[] = [];

  if (!!primaryBand?.section) {
    details.push({
      iconName: "location_on",
      overline: "Section",
      headline: primaryBand.section.name
    });
    details.push({
      iconName: "calendar_today",
      overline: "Expiration",
      headline: toFormat(primaryBand.expiresOn)
    });
    details.push({
      iconName: "calendar_today",
      overline: "Start Date",
      headline: toFormat(primaryBand.startedOn)
    });
  }
  return details;
}

export function personalOptions(person: Person): ListItemProps[] {
  const primaryPerson = !person
    ? {
        age: null,
        gender: null,
        informalName: null
      }
    : person;
  let details: ListItemProps[] = [];

  if (!!primaryPerson.age) {
    details.push({
      iconName: "person",
      overline: "Age",
      headline: `${primaryPerson.age}`
    });
  }

  if (!!primaryPerson.gender) {
    const gender = {
      F: "Female",
      M: "Male",
      O: "Other"
    };

    const headline = gender[primaryPerson.gender as keyof typeof gender] || "Not specified";

    details.push({
      iconName: "person",
      overline: "Gender",
      headline
    });
  }

  if (!!primaryPerson.informalName) {
    details.push({
      iconName: "person",
      overline: "Preferred First Name",
      headline: primaryPerson.informalName!
    });
  }

  return details;
}
