import { useState, useEffect, useMemo } from "react";
import usePageTitle from "@/hooks/usePageTitle";
import Table from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import { DuesRatesRow, Variables } from "./types";
import { DuesRates, PreviousPricing } from "@/types";
import { useQuery } from "@apollo/client";
import { GET_DUES_RATES_QUERY } from "./queries";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import { sortBy, orderBy as orderByLodash } from "lodash";
import { ColumnSort } from "@tanstack/react-table";
import { Updater } from "@tanstack/table-core";
import { Controller } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { useSearch } from "@/hooks/useSearch";
import { GET_CLASSIFICATIONS } from "../../../queries";
import { ClassificationType } from "../../types";
import MultiSelect from "@/components/MultiSelect";
import { get } from "lodash";
import getActiveFilters from "@/lib/filterHelpers";
import Button from "@/components/Button";
import { RowSelectionState } from "@tanstack/react-table";
import pluralize from "@/lib/pluralize";
import Chip from "@/components/Chip";
import { AdjustRateModal } from "./_components/AdjustRateModal";
import { Cell } from "@tanstack/react-table";
import { EditRateModal } from "./_components/EditRateModal";
import { getColumns } from "./_components/TableColumns";

type DuesRatesData = {
  duesRates: {
    nodes: DuesRates[];
  };
};

const getPriceByYear = (previousPricing: PreviousPricing[], year: string) => {
  return centsToDollarsFormatted(previousPricing?.filter((p) => p.year === year)?.[0]?.priceCents);
};

const formatTableData = (data: DuesRates[]) => {
  return data.map(
    ({
      id,
      classification,
      classificationDescription,
      productName,
      memberGrade,
      updatedAt,
      priceCents,
      previousPricing
    }) => ({
      id,
      productName: `${memberGrade} ${productName} `,
      classification,
      classificationDescription,
      updatedAt: toFormat(updatedAt),
      rate_2025: centsToDollarsFormatted(priceCents),
      rate_2024: getPriceByYear(previousPricing, "2024"),
      rate_2023: getPriceByYear(previousPricing, "2023"),
      classificationNumber: Number(classification?.split("-")?.[1]),
      classificationLetter: classification?.split("-")?.[0],
      // TODO: replace this once we have the status from the query
      status: undefined,
      statusMessage: undefined
    })
  );
};

export default function AnnualNationalRatesPage() {
  usePageTitle(
    "/billing/dues-management/manage-products/annual-national-rates",
    "Billing | Dues Management | Manage Products | Annual National Rates"
  );
  const defaultVariables = {
    kind: "national"
  };

  const [variables, setVariables] = useState<Variables>({ ...defaultVariables });
  const [selectedClassificationValues, setSelectedClassificationValues] = useState<string[]>([]);
  const defaultSort = [{ id: "classification", desc: false }];
  const [orderBy, setOrderBy] = useState<ColumnSort[]>(defaultSort);
  const [_selectedData, setSelectedData] = useState<DuesRatesRow[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const rowSelected = Object.keys(rowSelection).length;
  const [isSelectedOnly, setIsSelectedOnly] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
  const [openEditRateModal, setOpenEditRateModal] = useState(false);
  const [openAdjustRateModal, setOpenAdjustRateModal] = useState(false);
  const { data: classificationData } = useQuery(GET_CLASSIFICATIONS);
  const { data, loading } = useQuery<DuesRatesData>(GET_DUES_RATES_QUERY, {
    variables: {
      filters: {
        ...variables
      }
    },
    fetchPolicy: "cache-first"
  });
  const tableData: DuesRatesRow[] = sortBy(
    orderByLodash(formatTableData(data?.duesRates?.nodes || []), "classificationNumber"),
    (item) => [item.classificationLetter]
  );

  const currentYear = new Date().getFullYear();
  const { searchValue, control } = useSearch("name");

  const handleSort = (updaterOrValue: Updater<ColumnSort[]>) => {
    const sortState = typeof updaterOrValue === "function" ? updaterOrValue([]) : updaterOrValue;
    const { id, desc } = sortState[0] || {};
    setOrderBy([{ id, desc }]);
  };

  const filters = {
    status: selectedClassificationValues.length > 0
  };
  const { isActive } = getActiveFilters(filters, filters, true);

  const classificationNodes = get(classificationData, "classifications.nodes");
  const classificationDataNodes =
    classificationNodes?.map((node: ClassificationType) => ({
      value: node.name,
      label: node.name
    })) || [];

  useEffect(() => {
    setVariables({
      search: searchValue || undefined,
      classification: selectedClassificationValues.length > 0 ? selectedClassificationValues : undefined,
      ...defaultVariables
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectedClassificationValues]);

  useEffect(() => {
    if (rowSelected === 0 && isSelectedOnly) {
      setIsSelectedOnly(false);
    }
  }, [rowSelected, isSelectedOnly]);
  // TODO: filter by unfinalized status
  const selectableRows = tableData;
  const filteredData = tableData.filter((item) => rowSelection[item.id]);

  const handleBulkEditRate = async (value: string) => {
    const selectedIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    // TODO: add the mutation here
    console.log(selectedIds, value);
    setOpenEditRateModal(false);
  };

  const handleBulkAdjustRate = async (value: string) => {
    const selectedIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    // TODO: add the mutation here
    console.log(selectedIds, value);
    setOpenAdjustRateModal(false);
  };

  const handleEditRate = (value: string, cell: Cell<DuesRatesRow, unknown>) => {
    // TODO: add the mutation here
    console.log(cell.row.id, value);
    setOpenEditRateModal(false);
  };

  const columns = useMemo(() => {
    return getColumns({
      selectableRows,
      setIsSelectedOnly,
      setEditableRowIndex,
      tableData,
      rowSelected,
      handleEditRate
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelected]);

  return (
    <>
      <div className="px-4 pb-6 justify-between tablet:flex">
        <div className="max-w-[1000px] pr-5">
          <p className="text-title-medium mb-1">National Annual Dues and Fees</p>
          <p className="text-body-medium">
            Please set PGA National’s annual dues and fee amounts below for July 1, {currentYear} - June 30,{" "}
            {currentYear + 1}. Previous annual prices are shown for convenience and encompass July 1st of the applicable
            year through June 30th of the following. Once pricing is finalized, Sections will be notified to set their
            rates. Rate changes are saved immediately, but remain editable until finalized.
          </p>
        </div>
      </div>
      <Table
        data={isSelectedOnly ? filteredData : tableData}
        loading={loading}
        onSortingChange={handleSort}
        sortingState={orderBy}
        enableRowSelection={true}
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
        setSelectedData={setSelectedData}
        editableRowIndex={editableRowIndex}
        setEditableRowIndex={(index: number | null) => setEditableRowIndex(index)}
        columns={columns}
        renderHeader={() => (
          <div>
            <div className="flex md:items-center flex-col md:flex-row justify-between">
              <div className="flex md:items-center flex-col gap-3 md:flex-row">
                <div className="w-full sm:max-w-[320px]">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <SearchInput
                        placeholder="Search Product"
                        query={field.value}
                        param="Product Name"
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                {rowSelected === 0 && (
                  <>
                    <div className="min-w-[210px]">
                      <Controller
                        name="classifications"
                        control={control}
                        render={() => (
                          <MultiSelect
                            label="Classification"
                            selectedValues={selectedClassificationValues}
                            options={classificationDataNodes}
                            onSelect={(value, checked) => {
                              const classification = checked
                                ? [...selectedClassificationValues, value]
                                : selectedClassificationValues.filter((v) => v !== value);
                              setSelectedClassificationValues(classification);
                            }}
                            onClear={() => {
                              setSelectedClassificationValues([]);
                              setVariables((prevValue) => ({
                                ...prevValue,
                                classification: []
                              }));
                            }}
                            className="w-full"
                          />
                        )}
                      />
                    </div>
                    {isActive && (
                      <div className="hidden sm:block" data-testid="clear-all-button">
                        <Button
                          variant="text"
                          onClick={() => {
                            setVariables((prevValue) => ({
                              ...defaultVariables,
                              search: prevValue.search
                            }));
                            setSelectedClassificationValues([]);
                          }}
                        >
                          Clear All
                        </Button>
                      </div>
                    )}
                  </>
                )}
                <div className="flex items-center gap-2 flex-wrap sm:flex-nowrap">
                  {rowSelected > 0 && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setOpenEditRateModal(true);
                        }}
                      >
                        Edit Rate
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setOpenAdjustRateModal(true);
                        }}
                      >
                        Adjust Rate by %
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {rowSelected > 0 && (
              <div className="flex items-center gap-2 mt-4 flex-wrap md:flex-nowrap">
                <span className="text-label-large">
                  {rowSelected} {pluralize(rowSelected, "row")} selected
                </span>
                <Chip
                  onClick={() => {
                    setIsSelectedOnly(!isSelectedOnly);
                  }}
                  label="Show Selected Only"
                  selected={isSelectedOnly}
                />
                <Button
                  variant="text"
                  onClick={() => {
                    setRowSelection({});
                    setIsSelectedOnly(false);
                  }}
                >
                  Deselect All {rowSelected} {pluralize(rowSelected, "Row")}
                </Button>
              </div>
            )}
          </div>
        )}
        renderEmptyState={() => (
          <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="request_quote" />
        )}
      />
      {openEditRateModal && (
        <EditRateModal
          onDismiss={() => {
            setOpenEditRateModal(false);
          }}
          handleBulkEditRate={handleBulkEditRate}
        />
      )}
      {openAdjustRateModal && (
        <AdjustRateModal
          onDismiss={() => {
            setOpenAdjustRateModal(false);
          }}
          handleBulkAdjustRate={handleBulkAdjustRate}
        />
      )}
    </>
  );
}
