import { Link, Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import Button, { ButtonProps } from "./Button";
import IconButton, { IconButtonProps } from "./IconButton";
import StackedCard, { StackedCardProps } from "./StackedCard";

export const ButtonWithLink = <P extends Path>(
  props: ButtonProps & { link: LinkProps<P, Params>; "data-testid"?: string }
) => {
  const { link, "data-testid": testId, ...rest } = props;
  return (
    <Link {...link} data-testid={testId}>
      <Button {...rest} />
    </Link>
  );
};

export const IconButtonWithLink = <P extends Path>(
  props: IconButtonProps & { link: LinkProps<P, Params>; "data-testid"?: string }
) => {
  const { link, "data-testid": testId, ...rest } = props;
  return (
    <Link {...link} data-testid={testId}>
      <IconButton {...rest} />
    </Link>
  );
};

export const StackedCardWithLink = <P extends Path>(
  props: StackedCardProps & { link: LinkProps<P, Params>; "data-testid"?: string }
) => {
  const { link, "data-testid": testId, ...rest } = props;
  return (
    <Link {...link} data-testid={testId}>
      <StackedCard {...rest} />
    </Link>
  );
};

export const ExternalLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </a>
  );
};
