import { MEMBERSHIP_BAND_FRAGMENT } from "@/fragments";
import { gql } from "@apollo/client";

export const PERSON_DETAILS_HEADER_QUERY = gql`
  query PersonDetailsHeader($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      pgaId
      firstName
      lastName
      displayName
      informalName
      profilePhoto
      primarySection {
        id
        name
      }
      latestProgram {
        id
        status
        programType {
          id
          name
        }
        band {
          ...MembershipBandFragment
        }
      }
    }
  }
  ${MEMBERSHIP_BAND_FRAGMENT}
`;
