import IconButton from "@/components/IconButton";
import TopActionBar from "@/components/TopActionBar";
import { useCanManageInteractionsBool } from "@/hooks/useCapabilities";
import useCurrentUser from "@/hooks/useCurrentUser";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Path } from "@/router";
import { SnackbarProvider } from "notistack";
import { CreateGlobalInteractionSideSheet } from "./CreateGlobalInteraction/CreateGlobalInteractionSideSheet";
import Sidebar from "./Sidebar";
import { NavLink } from "./Sidebar/Sidebar";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";
import QuickSearchSideSheet from "./QuickSearchSideSheet";
import TopActionBarProvider from "./TopActionBarProvider";
import { SideBar, useTopActionBar } from "./TopActionBarContext";

type AppLayoutProps<P extends Path> = {
  interior?: boolean;
  navLinks?: Record<string, NavLink<P>[]> | NavLink<P>[];
  BannerComponent?: React.ReactNode;
  bottomBar?: boolean;
};

function Main<P extends Path>({
  navLinks,
  BannerComponent,
  interior,
  bottomBar,
  children
}: React.PropsWithChildren<AppLayoutProps<P>>) {
  const flags = useFlags();
  const flagInteractionsCreation = flags["backoffice-interactions-creation"];

  const { user, logout } = useCurrentUser();
  const isMobileDisplay = isMobile(useWindowSize().width);
  const { showSidebar, toggleSidebar, currentSidebar } = useTopActionBar();
  const canManageInteractions = useCanManageInteractionsBool();

  function createActions() {
    let actions = [<IconButton key="quickSearch" name="search" onClick={showSidebar(SideBar.QUICK_SEARCH)} />];

    if (flagInteractionsCreation && canManageInteractions)
      actions.push(
        <IconButton
          key="createGlobalInteraction"
          name="add_comment"
          title="Create Interaction"
          onClick={showSidebar(SideBar.CREATE_GLOBAL_INTERACTION)}
        />
      );

    return <>{actions}</>;
  }

  return (
    <main className={`flex min-h-screen ${bottomBar && isMobileDisplay ? "pb-[120px]" : ""}`}>
      <Sidebar interior={interior} navLinks={navLinks} bottomBar={bottomBar} />
      <div className="w-full">
        <TopActionBar {...{ user, logout, interior }} actions={createActions()} />
        {BannerComponent}
        <CreateGlobalInteractionSideSheet
          sidebarOpen={currentSidebar === SideBar.CREATE_GLOBAL_INTERACTION}
          setSidebarOpen={toggleSidebar(SideBar.CREATE_GLOBAL_INTERACTION)}
        />
        <QuickSearchSideSheet
          show={currentSidebar === SideBar.QUICK_SEARCH}
          onToggle={toggleSidebar(SideBar.QUICK_SEARCH)}
        />
        <div className="mx-6 tablet:mx-12 mb-4 sm:mb-24">{children}</div>
      </div>
    </main>
  );
}

export default function AppLayout<P extends Path>(props: React.PropsWithChildren<AppLayoutProps<P>>) {
  return (
    <TopActionBarProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <Main {...props} />
      </SnackbarProvider>
    </TopActionBarProvider>
  );
}
