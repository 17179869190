import { DOMAIN, useCanViewInteractionsBool } from "@/hooks/useCapabilities";
import { FlagRecord } from "@/hooks/useFeatureFlags";
import { NavLink } from "@/layouts/AppLayout/Sidebar/Sidebar";
import { Path } from "@/router";

export const getPersonNavLinks: (pgaId: string, flags: FlagRecord) => Record<string, NavLink<Path>[]> = (
  pgaId,
  flags
) => {
  // for the lower-cased component name
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const canViewInteractions = useCanViewInteractionsBool();
  const flagInteractions = flags["backoffice-interactions"] && canViewInteractions;
  const flagEducationLearning = flags["backoffice-person-profile-education-learning"];

  const personLinks = [
    {
      domain: DOMAIN.EVERYONE,
      label: "Details",
      link: {
        to: "/people/:pgaId/details",
        params: { pgaId }
      },
      icon: "assignment_ind"
    },
    flagEducationLearning && {
      domain: DOMAIN.EVERYONE,
      label: "Education",
      link: {
        to: "/people/:pgaId/education",
        params: { pgaId }
      },
      icon: "school"
    },
    {
      domain: DOMAIN.EVERYONE,
      label: "Billing",
      link: {
        to: "/people/:pgaId/billing",
        params: { pgaId }
      },
      icon: "card_membership"
    },
    flagInteractions && {
      domain: DOMAIN.EVERYONE,
      label: "Interactions",
      link: {
        to: "/people/:pgaId/interactions",
        params: { pgaId }
      },
      icon: "3p"
    }
  ].filter(Boolean) as NavLink<Path>[];

  return {
    "Manage Person": personLinks
  };
};
