import { AuditLogTable, AuditLogHeading, DateRangePicker } from "./_components";
import { useParams } from "@/router";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useQuery } from "@apollo/client";
import { AUDIT_LOG_PAGINATION_QUERY } from "./queries";
import { PropsWithChildren, useEffect, useState } from "react";
import { useSearch } from "@/hooks/useSearch";
import usePagination from "@/hooks/usePagination";
import { DateTime } from "luxon";
import { AuditLogVariables } from "./types";
import { Control, Controller } from "react-hook-form";
import SearchInput from "@/components/SearchInput";

const title = "Audit Log";

const path = "/sections/:sectionId/audit-log";

function Header({
  children,
  control,
  variables,
  setVariables
}: PropsWithChildren<{
  control: Control;
  variables: AuditLogVariables;
  setVariables: (variables: AuditLogVariables) => void;
}>) {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-col sm:flex-row justify-start items-start gap-5">
        <div className="max-w-[320px]">
          <Controller
            name="Item Modified"
            control={control}
            render={({ field }) => (
              <SearchInput
                placeholder="Search Log"
                query={field.value}
                param="Item Modified, PGA ID"
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="w-[270px] sm:w-[300px] max-w-[300px] h-[56px]">
          <DateRangePicker variables={variables} setVariables={setVariables} />
        </div>
      </div>
      <div className="w-full md:w-auto">{children}</div>
    </div>
  );
}

export default function AuditLog() {
  useRequireCapability({ domain: DOMAIN.SECTIONS, permission: PERMISSION.viewPeople });
  const { sectionId } = useParams(path);

  const startDateTime = DateTime.now().minus({ days: 30 }).toISO();
  const endDateTime = DateTime.now().toISO();

  const [variables, setVariables] = useState<AuditLogVariables>({
    sectionId,
    startDateTime,
    endDateTime,
    searchQuery: null
  });

  const { searchValue, control } = useSearch("Item Modified");
  const { renderFooter, paginationVariables, resetPagination } = usePagination();

  useEffect(() => {
    resetPagination();
    setVariables((prevVariables) => ({
      ...prevVariables,
      searchQuery: searchValue === "" ? null : searchValue
    }));
  }, [searchValue, resetPagination, variables.startDateTime, variables.endDateTime]);

  const { data, loading } = useQuery(AUDIT_LOG_PAGINATION_QUERY, {
    variables: { ...variables, ...paginationVariables }
  });

  const auditLog = data?.section.auditLogPagination;

  return (
    <div className="tablet:mx-16">
      <div className="mb-6">
        <AuditLogHeading section={data?.section} title={title} />
      </div>
      <AuditLogTable
        data={auditLog?.nodes || []}
        loading={loading}
        renderHeader={() => (
          <Header {...{ control, variables, setVariables }}>
            {auditLog?.nodes.length ? renderFooter(auditLog, { variant: "compact" })() : null}
          </Header>
        )}
        renderFooter={renderFooter(auditLog)}
      />
    </div>
  );
}
