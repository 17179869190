import Tag from "@/components/Tag";
import { TagColor } from "@/components/Tag/Tag";
import type { MaterialNames } from "@/components/material_symbol_names";
import { HiringStatus, PostStage } from "./types";
import { Menu } from "@/hooks/useActionMenu";
import { MenuItem as MenuItem$ } from "@/components/Menu";

export type MenuItem = MenuItem$ | "divider";

type StageTag = { color: TagColor; label: string; icon?: MaterialNames };

const STAGES_MAP: Record<string, StageTag> = {
  [PostStage.DRAFT]: { color: "secondary", label: "Draft" },
  [PostStage.PENDING]: { color: "tertiaryDim", label: "Pending" },
  [PostStage.REVIEW]: { color: "tertiaryDim", label: "Review" },
  [PostStage.ACTIVE]: { color: "primary", label: "Active" },
  [PostStage.INACTIVE]: { color: "secondary", label: "Inactive" },
  [PostStage.REMOVED]: { color: "secondary", label: "Removed" }
};

const JOB_STATUSES_MAP: Record<string, StageTag> = {
  [HiringStatus.FILLED]: { color: "primary", label: "Filled" },
  [HiringStatus.INCOMPLETE]: { color: "secondary", label: "Incomplete" },
  [HiringStatus.IN_PROGRESS]: { color: "tertiaryDim", label: "In Progress" },
  [HiringStatus.EXPIRED]: { color: "secondary", label: "Expired" }
};

export const renderPostStage = (stage: string) => {
  const data = STAGES_MAP[stage] || { label: stage, color: "tertiaryDim" };

  return (
    <Tag color={data?.color as TagColor} testId={data.label}>
      {data.label}
    </Tag>
  );
};

export const renderJobStatus = (hiring_status: string | null) => {
  const data = hiring_status
    ? JOB_STATUSES_MAP[hiring_status] || {
        label: hiring_status,
        color: "tertiaryDim"
      }
    : null;

  if (data) {
    return (
      <Tag color={data?.color as TagColor} testId={data?.label}>
        {data?.label}
      </Tag>
    );
  } else {
    return <div className="opacity-20">--</div>;
  }
};

type ActionMenuClickProps = {
  setMenu: (menu: Menu) => void;
  rowId: string;
  items: MenuItem[];
};

export const onActionMenuItemClick = ({ setMenu, rowId, items }: ActionMenuClickProps) => {
  setMenu({ rowId, items });
};
