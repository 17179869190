import * as LitComps from "./LitButton";
import { SlotIcon, SlotIconName } from "../Icon";
import { sysColors, extendedColors } from "@/assets/theme/colors";

const variants = {
  outlined: LitComps.LitOutlinedButton,
  filled: LitComps.LitFilledButton,
  elevated: LitComps.LitElevatedButton,
  filledTonal: LitComps.LitFilledTonalButton,
  text: LitComps.LitTextButton
};

export type ButtonVariant = keyof typeof variants;

const themes = {
  dark: {
    "--md-sys-color-primary": sysColors["sys-brand-inverse-primary"]
  } as React.CSSProperties,
  light: {},
  error: {
    "--md-sys-color-primary": sysColors["sys-brand-error"],
    "--md-outlined-button-label-text-color": sysColors["sys-brand-on-error-container"],
    "--md-outlined-button-hover-label-text-color": sysColors["sys-brand-on-error-container"],
    "--md-outlined-button-focus-label-text-color": sysColors["sys-brand-on-error-container"],
    "--md-outlined-button-pressed-label-text-color": sysColors["sys-brand-on-error-container"]
  } as React.CSSProperties,
  warn: {
    "--md-sys-color-primary": extendedColors["extended-warning-brand-on-color-container"]
  } as React.CSSProperties,
  info: {
    "--md-sys-color-primary": sysColors["sys-brand-on-primary-container"]
  } as React.CSSProperties,
  pgaAssistant: {
    "--md-sys-color-primary": sysColors["sys-brand-primary-container-bright"],
    "--md-sys-color-on-primary": sysColors["sys-brand-on-primary-container"]
  } as React.CSSProperties
};

export type ThemeName = keyof typeof themes;

export interface Props {
  disabled?: boolean;
  icon?: SlotIconName;
  trailingIcon?: boolean;
  variant?: ButtonVariant;
  testId?: string;
  onClick?(): void;
  theme?: ThemeName;
  children?: React.ReactNode;
}

export default function Button({
  children,
  disabled,
  icon,
  trailingIcon,
  variant = "filled",
  onClick,
  testId,
  theme = "light"
}: Props) {
  const ButtonComponent = variants[variant];
  const style = themes[theme];

  return (
    <ButtonComponent
      disabled={disabled}
      onClick={onClick}
      trailingIcon={trailingIcon}
      data-testid={testId}
      style={style}
    >
      {icon && <SlotIcon name={icon} slot="icon" disabled={disabled} />}
      {children}
    </ButtonComponent>
  );
}
