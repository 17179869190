import { gql } from "@apollo/client";

export const USER_FEATURE_FLAG_QUERY = gql`
  query UserFeatureFlagQuery {
    me {
      id
      externalId
    }
  }
`;
