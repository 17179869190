import Icon from "@/components/Icon";
import { DOMAIN } from "@/hooks/useCapabilities";
import { Path } from "@/router";
import React from "react";
import { Location, matchPath } from "react-router-dom";
import { NavigationLink } from ".";
import { NavLink } from "./Sidebar";

interface RenderNavLinksProps<P extends Path> {
  navLinks: NavLink<P>[];
  isLastGroup: boolean;
  location: Location;
  groupTitle?: string;
  isSidebarOpen: boolean;
  domains: string[];
}

const isMenuActive = (currentPath: string, linkTo: Path) => {
  let path = currentPath;

  while (path && path !== "/") {
    if (matchPath(linkTo, path)) {
      return true;
    }

    path = path.split("/").slice(0, -1).join("/");
  }

  return false;
};

const renderNavLinks = <P extends Path>({
  navLinks,
  groupTitle,
  isLastGroup,
  domains,
  isSidebarOpen,
  location: { pathname }
}: RenderNavLinksProps<P>) => {
  const permittedLinks = navLinks.filter(
    (navLink) => navLink.domain === DOMAIN.EVERYONE || domains.includes(navLink.domain)
  );

  return permittedLinks.length > 0 ? (
    <React.Fragment key={groupTitle}>
      {isSidebarOpen && groupTitle ? <div className="text-title-small p-4 mb-1">{groupTitle}</div> : null}
      {permittedLinks.map(({ label, link, icon }, key) => {
        return (
          <NavigationLink
            key={key}
            active={isMenuActive(pathname, link.to)}
            icon={<Icon name={icon} />}
            {...{ label, link }}
          />
        );
      })}
      {!isLastGroup && isSidebarOpen && <div className="w-full border-t border-gray-200 m-2"></div>}
    </React.Fragment>
  ) : null;
};

export default renderNavLinks;
