import { useState, useCallback, useRef, useEffect } from "react";
import TextField from "../TextField";
import Radio from "../Radio";
import IconButton from "../IconButton";
import Icon from "../Icon";
import { Reorder, useDragControls } from "framer-motion";
import { isEqual } from "lodash";

export interface AnswerOption {
  position: number;
  text: string | undefined;
  isCorrect: boolean;
}

export interface AssessmentQuestionProps {
  className?: string;
  position?: number;
  isFocused?: boolean;
  question?: string;
  setQuestion: (value: string) => void;
  questionOptions?: AnswerOption[];
  setQuestionOptions: (options: AnswerOption[]) => void;
  draggable?: boolean;
  onDragHandlePointerDown?: (event: React.PointerEvent) => void;
  maxOptions?: number;
}

export default function AssessmentQuestion({
  className,
  isFocused = true,
  position,
  question = "",
  setQuestion,
  questionOptions = [],
  setQuestionOptions,
  draggable = true,
  onDragHandlePointerDown,
  maxOptions = 4
}: AssessmentQuestionProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState<AnswerOption[]>(() =>
    questionOptions.length > 0
      ? questionOptions
      : [
          { position: 0, text: "Option Text", isCorrect: true },
          { position: 1, text: "Option Text", isCorrect: false }
        ]
  );

  const handleMultipleChoiceOptionTextChange = useCallback((position: number, text: string) => {
    setMultipleChoiceOptions((prevOptions) =>
      prevOptions.map((option) => (option.position === position ? { ...option, text } : option))
    );
  }, []);

  const addMultipleChoiceOption = useCallback(() => {
    setMultipleChoiceOptions((prevOptions) => {
      const newPosition = prevOptions.length;
      return [...prevOptions, { position: newPosition, text: "Option Text", isCorrect: false }];
    });
  }, []);

  const handleMultipleChoiceOptionDelete = useCallback((position: number) => {
    setMultipleChoiceOptions((prevOptions) => {
      const updatedOptions = prevOptions.filter((option) => option.position !== position);
      if (updatedOptions.length > 0 && updatedOptions.every((option) => !option.isCorrect)) {
        updatedOptions[0].isCorrect = true;
      }
      return updatedOptions.map((option, index) => ({ ...option, position: index }));
    });
  }, []);

  const handleMultipleChoiceOptionSelectionChange = useCallback((selectedPosition: number) => {
    setMultipleChoiceOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        isCorrect: option.position === selectedPosition
      }))
    );
  }, []);

  useEffect(() => {
    if (!isEqual(questionOptions, multipleChoiceOptions)) {
      setQuestionOptions(multipleChoiceOptions);
    }
  }, [multipleChoiceOptions, setQuestionOptions, questionOptions]);

  const renderMultipleChoiceOptions = () => (
    <div>
      {isFocused && draggable ? (
        <Reorder.Group
          axis="y"
          values={multipleChoiceOptions}
          onReorder={setMultipleChoiceOptions}
          layoutScroll
          style={{ minHeight: multipleChoiceOptions.length * 60 }}
        >
          {multipleChoiceOptions.map((option) => (
            <div key={option.position} className="my-4">
              <MultipleChoiceOption
                option={option}
                isFocused={isFocused}
                position={position}
                onTextChange={handleMultipleChoiceOptionTextChange}
                onSelectionChange={handleMultipleChoiceOptionSelectionChange}
                onDelete={handleMultipleChoiceOptionDelete}
                canDelete={multipleChoiceOptions.length > 2}
                draggable={draggable}
                containerRef={containerRef}
              />
            </div>
          ))}
        </Reorder.Group>
      ) : (
        multipleChoiceOptions.map((option) => (
          <div key={option.position} className="my-4">
            <MultipleChoiceOption
              option={option}
              isFocused={isFocused}
              position={position}
              onTextChange={handleMultipleChoiceOptionTextChange}
              onSelectionChange={handleMultipleChoiceOptionSelectionChange}
              onDelete={handleMultipleChoiceOptionDelete}
              canDelete={isFocused && multipleChoiceOptions.length > 2}
              draggable={false}
              containerRef={containerRef}
            />
          </div>
        ))
      )}
    </div>
  );

  return (
    <div
      ref={containerRef}
      data-testid="assessment-question"
      className={`
        ${className} 
        flex flex-col items-start 
        border border-sys-brand-outline-variant 
        rounded-[16px]
        ${isFocused ? "bg-sys-brand-surface-container" : "bg-sys-brand-surface-container-lowest hover:shadow-md"}
      `}
      style={{ userSelect: "none", pointerEvents: "auto" }}
    >
      <div className="flex flex-col w-full relative pt-4 px-2">
        <div className="pl-2 flex items-center justify-between gap-2">
          {draggable && onDragHandlePointerDown && (
            <div
              className="drag-handle cursor-grab h-6 w-6 flex items-center justify-center pr-1"
              onPointerDown={onDragHandlePointerDown}
              style={{ touchAction: "none" }}
            >
              <Icon name="drag_indicator" />
            </div>
          )}
          <div className="flex-grow w-full pr-2">
            {isFocused ? (
              <TextField
                label="Question Text *"
                className="text-title-medium w-full bg-sys-brand-surface"
                value={question}
                onChange={(e) => setQuestion((e.target as HTMLInputElement).value)}
              />
            ) : (
              <span className="text-title-medium w-full">{question}</span>
            )}
          </div>
        </div>

        {isFocused && <hr className="mt-4 w-full h-[1px] border-t border-sys-brand-outline-variant" />}
      </div>
      <div className="flex flex-col w-full relative pb-2 pl-1">
        <div className="pl-2">
          {renderMultipleChoiceOptions()}
          {isFocused && multipleChoiceOptions.length < maxOptions && (
            <button
              type="button"
              className="bg-transparent border-none text-label-large leading-5 tracking-[0.1px] text-center text-sys-brand-primary cursor-pointer hover:text-sys-brand-primary-hover pb-4 pl-3"
              onClick={addMultipleChoiceOption}
            >
              + Add Option
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

interface MultipleChoiceOptionProps {
  option: AnswerOption;
  isFocused: boolean;
  position: number | undefined;
  onTextChange: (position: number, text: string) => void;
  onSelectionChange: (position: number) => void;
  onDelete: (position: number) => void;
  canDelete: boolean;
  draggable: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

function MultipleChoiceOption({
  option,
  isFocused,
  position,
  onTextChange,
  onSelectionChange,
  onDelete,
  canDelete,
  draggable,
  containerRef
}: MultipleChoiceOptionProps) {
  const dragControls = useDragControls();
  const [isActive, setIsActive] = useState(false);

  const activeStyle = {
    backgroundColor: "var(--sys-brand-surface-container)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    opacity: 0.8,
    border: "1px solid var(--sys-brand-outline-variant)",
    borderRadius: "8px",
    zIndex: 1000
  };

  const OptionContent = (
    <div
      className={`flex items-center my-0 py-2 pl-2 rounded-md ${
        isFocused ? "bg-sys-brand-surface-container" : "bg-sys-brand-surface-container-lowest"
      }`}
      style={{ userSelect: "none", pointerEvents: "auto" }}
    >
      <div className="flex items-center w-full pr-2">
        <Radio
          label=""
          checked={option.isCorrect}
          name={`multiple_choice_option_${position}_${option.position}`}
          onChange={() => onSelectionChange(option.position)}
          disabled={!isFocused && !option.isCorrect}
        />
        <div className="flex-grow">
          {isFocused ? (
            <TextField
              value={option.text}
              className="w-full bg-sys-brand-surface"
              label="Option Text"
              onChange={(e) => {
                onTextChange(option.position, (e.target as HTMLInputElement).value ?? "");
              }}
            />
          ) : (
            <span className="text-body-large">{option.text}</span>
          )}
        </div>
        {isFocused && draggable && (
          <div className="pl-2 pr-4 flex items-center">
            <div
              className="cursor-grab h-4 flex items-center justify-center pl-2"
              onPointerDown={(event) => {
                setIsActive(true);
                dragControls.start(event);
              }}
              onPointerUp={() => setIsActive(false)}
              onPointerLeave={() => setIsActive(false)}
              style={{ touchAction: "none" }}
            >
              <Icon name="drag_handle" />
            </div>
          </div>
        )}
        {isFocused && canDelete && (
          <IconButton
            name="delete"
            onClick={() => {
              onDelete(option.position);
            }}
          />
        )}
      </div>
    </div>
  );

  return draggable ? (
    <Reorder.Item
      value={option}
      dragListener={false}
      dragControls={dragControls}
      whileDrag={activeStyle}
      animate={isActive ? activeStyle : {}}
      transition={{ duration: 0 }}
      dragConstraints={containerRef}
      dragElastic={0.1}
      style={{ position: "relative" }}
    >
      {OptionContent}
    </Reorder.Item>
  ) : (
    OptionContent
  );
}
