import { isLaptopOrDesktop } from "@/assets/theme/sizes";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface SidebarState {
  isSidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const useSidebarStore = create<SidebarState>()(
  devtools(
    persist(
      (set) => ({
        isSidebarOpen: process.env.NODE_ENV === "test" || isLaptopOrDesktop(),
        setSidebarOpen: (open) => set((state) => ({ ...state, isSidebarOpen: open }))
      }),
      {
        name: "sidebar-storage"
      }
    )
  )
);

export const useSidebar = () => {
  const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
  const setSidebarOpen = useSidebarStore((state) => state.setSidebarOpen);

  return { isSidebarOpen, setSidebarOpen };
};
