import IconButton from "@/components/IconButton";
import { useState } from "react";
import EditMetricModal from "./EditMetricModal";
import classNames from "classnames";

interface PATMetricCardProps {
  label: string;
  value: string | number;
  onEdit?: (newValue: string) => void;
}

export default function PATMetricCard({ label, value, onEdit }: PATMetricCardProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const formatValue = (val: string | number) => {
    if (!val && val !== 0) return "--";
    if (typeof val === "number" && label.includes("Rating")) {
      return val.toFixed(2);
    }
    return val;
  };

  const handleSave = (newValue: string) => {
    if (!newValue.trim()) {
      onEdit?.("");
      return;
    }

    if (label.includes("Rating")) {
      const parsed = parseFloat(newValue);
      if (isNaN(parsed) || parsed < 0) return;
      const formatted = parsed.toFixed(2);
      onEdit?.(formatted);
    } else {
      onEdit?.(newValue);
    }
  };

  return (
    <>
      <div className="bg-surface-container-low py-4 rounded-xl flex flex-col h-[100px]">
        <div className="flex items-center h-8">
          <span className="text-body-medium text-on-surface">{label}</span>
          {onEdit && <IconButton name="edit" title="Edit" onClick={() => setIsDialogOpen(true)} className="ml-1" />}
        </div>
        <div
          className={classNames(
            "text-headline-medium text-on-surface mt-2",
            "overflow-hidden text-ellipsis whitespace-nowrap",
            { "opacity-20": !value && value !== 0 }
          )}
        >
          {formatValue(value)}
        </div>
      </div>

      {onEdit && (
        <EditMetricModal
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          fieldName={label}
          initialValue={value}
          onSave={handleSave}
        />
      )}
    </>
  );
}
