import Dialog from "@/components/Dialog";
import Button from "@/components/Button";

interface FinalizeScoresConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  passingScores: number;
  qualifyingScores: number;
  failingScores: number;
  cheatingScores: number;
  disqualifiedScores: number;
  excessiveScores: number;
  noCardScores: number;
  noShowScores: number;
  poorConductScores: number;
  withdrawalScores: number;
}

export default function FinalizeScoresConfirmationModal({
  open,
  onClose,
  onSave,
  passingScores,
  qualifyingScores,
  failingScores,
  cheatingScores,
  disqualifiedScores,
  excessiveScores,
  noCardScores,
  noShowScores,
  poorConductScores,
  withdrawalScores
}: FinalizeScoresConfirmationModalProps) {
  return (
    <Dialog
      open={open}
      onClosed={onClose}
      headline={`Finalize Scores`}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave();
              onClose();
            }}
          >
            Finalize Scores
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          {passingScores > 0 && <div className="text-label-large">Passing: {passingScores}</div>}
          {qualifyingScores > 0 && <div className="text-label-large">Qualifying: {qualifyingScores}</div>}
          {failingScores > 0 && <div className="text-label-large">Failing: {failingScores}</div>}
          {cheatingScores > 0 && <div className="text-label-large">Cheating: {cheatingScores}</div>}
          {disqualifiedScores > 0 && <div className="text-label-large">Disqualified: {disqualifiedScores}</div>}
          {excessiveScores > 0 && <div className="text-label-large">Excessive: {excessiveScores}</div>}
          {noCardScores > 0 && <div className="text-label-large">No Card: {noCardScores}</div>}
          {noShowScores > 0 && <div className="text-label-large">No Show: {noShowScores}</div>}
          {poorConductScores > 0 && <div className="text-label-large">Poor Conduct: {poorConductScores}</div>}
          {withdrawalScores > 0 && <div className="text-label-large">Withdrawal: {withdrawalScores}</div>}
        </div>

        <div>
          <div className="text-body-medium">
            Are you sure you want to finalize these scores?
            {failingScores > 0 && <div>{failingScores} people will be suspended from taking the PAT for 90 days.</div>}
            {cheatingScores > 0 && (
              <div>{cheatingScores} people will be suspended from taking the PAT indefinitely.</div>
            )}
            Registrants will receive a notification of their verified result.
          </div>
        </div>
      </div>
    </Dialog>
  );
}
