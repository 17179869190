import { ColumnDef, Cell } from "@tanstack/react-table";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import Checkbox from "@/components/Checkbox";
import { DuesRatesRow } from "../types";
import Tooltip from "@/components/Tooltip";
import IconButton from "@/components/IconButton";
import Icon from "@/components/Icon";
import { StatusType } from "../types";
import type { MaterialNames } from "@/components/material_symbol_names";

interface GetColumnsProps {
  selectableRows: DuesRatesRow[];
  setIsSelectedOnly: (value: boolean) => void;
  setEditableRowIndex: (value: number | null) => void;
  tableData: DuesRatesRow[];
  rowSelected: number;
  handleEditRate: (value: string, cell: Cell<DuesRatesRow, unknown>) => void;
}

type StatusTag = { color: string; icon: MaterialNames };

const STATUS_MAP: Record<StatusType, StatusTag> = {
  [StatusType.WARNING]: { color: "text-extended-warning-brand-color", icon: "emergency_home" },
  [StatusType.ERROR]: { color: "text-sys-brand-error", icon: "error" }
};

const renderStatusIcon = (status: StatusType, statusMessage: string | undefined) => {
  const { color, icon } = STATUS_MAP[status] || { color: "text-sys-brand-error", icon: "error" };
  return statusMessage ? (
    <Tooltip
      variant="plain"
      supportingText={statusMessage}
      color="black"
      menuCorner="end-start"
      anchorCorner="start-start"
    >
      <Icon name={icon} className={color} />
    </Tooltip>
  ) : (
    <Icon name={icon} className={color} />
  );
};

export const getColumns = ({
  selectableRows,
  setIsSelectedOnly,
  setEditableRowIndex,
  tableData,
  rowSelected,
  handleEditRate
}: GetColumnsProps): ColumnDef<DuesRatesRow, any>[] => [
  {
    header: ({ table }) => {
      return (
        <div className="flex items-center">
          <div className="mr-4" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              name="select"
              checked={table.getIsAllRowsSelected() && selectableRows.length > 0}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={(checked) => {
                table.getToggleAllRowsSelectedHandler()({
                  target: { checked: table.getIsSomeRowsSelected() ? true : checked }
                });
                if (!checked) {
                  setIsSelectedOnly(false);
                }
                setEditableRowIndex(null);
              }}
            />
          </div>
          Product
        </div>
      );
    },
    id: "productName",
    accessorKey: "rate_2025",
    cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <div className="mr-4" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              name="select"
              disabled={!row.getCanSelect()}
              onChange={(checked) => {
                row.getToggleSelectedHandler()({ target: { checked } });
                setEditableRowIndex(null);
              }}
              checked={row.getIsSelected()}
            />
          </div>
          {row.original.productName}
        </div>
      );
    },
    meta: {
      className: getStickyColumnClasses(!!tableData?.length)
    },
    size: 500,
    enableSorting: true
  },
  {
    header: "Classification",
    accessorKey: "classification",
    enableSorting: true,
    cell: ({ row }) => {
      return (
        <Tooltip
          variant="plain"
          supportingText={row.original.classificationDescription}
          color="black"
          menuCorner="end-start"
          anchorCorner="start-start"
        >
          {row.original.classification}
        </Tooltip>
      );
    }
  },
  {
    header: "2025 Rate",
    accessorKey: "rate_2025",
    enableSorting: false,
    meta: {
      editable: rowSelected === 0,
      editableInputType: "currency",
      className: "flex items-center text-left px-0 py-2",
      editableClassName: "text-left",
      onEdit: handleEditRate,
      editableIcon: (cell: Cell<DuesRatesRow, unknown>) => {
        const { status, statusMessage } = cell.row.original;
        return status ? renderStatusIcon(status, statusMessage) : null;
      }
    }
  },
  {
    header: "2024 Rate",
    accessorKey: "rate_2024",
    enableSorting: false
  },
  {
    header: "2023 Rate",
    accessorKey: "rate_2023",
    enableSorting: false
  },
  {
    header: "Modified",
    accessorKey: "updatedAt",
    enableSorting: false
  },
  {
    header: "",
    accessorKey: "actions",
    enableSorting: false,
    cell: ({ row, table }) => {
      return (
        <div className="flex items-center justify-end w-8">
          {rowSelected === 0 && (
            <IconButton
              name="edit"
              className="!m-0 hidden group-hover:inline-flex"
              tabIndex={-1}
              onClick={() => {
                const meta = table.options.meta;
                const currentIndex = row.index;
                if (meta) {
                  if (meta.editableRowIndex !== currentIndex) {
                    meta?.setEditableRowIndex?.(currentIndex);
                  } else {
                    meta?.setEditableRowIndex?.(null);
                  }
                }
              }}
            />
          )}
        </div>
      );
    },
    meta: {
      className: "!py-0"
    },
    size: 10
  }
];
