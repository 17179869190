import theme from "./theme";
import classNames from "classnames";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorState, LexicalEditor } from "lexical";

interface RichTextEditorProps {
  placeholder?: string;
  hasOverlayButton?: boolean;
  onInitialState?: (editor: LexicalEditor) => void;
  onChange?: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void;
}

function Placeholder({ placeholder = "Description..." }: { placeholder?: string }) {
  return (
    <div className="editor-placeholder absolute top-3 left-3 text-body-medium text-sys-brand-on-surface-variant">
      {placeholder}
    </div>
  );
}

export default function RichTextEditor({
  placeholder,
  onInitialState,
  onChange,
  hasOverlayButton
}: RichTextEditorProps) {
  const editorConfig = {
    namespace: "Editor",
    theme: theme,
    editorState: (editor: LexicalEditor) => {
      onInitialState?.(editor);
    },
    onError: (error: Error) => {
      throw error;
    },
    nodes: [HeadingNode, ListNode, ListItemNode, LinkNode, AutoLinkNode]
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container border border-sys-brand-outline-variant h-full rounded">
        <ToolbarPlugin />
        <div className="editor-inner p-3 relative text-body-medium">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={classNames("editor-input min-h-[150px] max-h-[300px] overflow-y-auto !outline-none", {
                  "mb-16": hasOverlayButton
                })}
                data-testid="editor-input"
              />
            }
            placeholder={<Placeholder placeholder={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <LinkPlugin />
          {onChange && <OnChangePlugin onChange={onChange} />}
        </div>
      </div>
    </LexicalComposer>
  );
}
