import grpCardImage from "@/assets/images/grp-card-image.png";
import { StackedCardWithLink } from "@/components/withLink";
import useCapabilities, { DOMAIN } from "@/hooks/useCapabilities";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { pick, values } from "lodash";
import usePageTitle from "@/hooks/usePageTitle";

const GRPCard = () => (
  <StackedCardWithLink
    data-testid="card-with-link"
    link={{ to: "/benefit-programs/grp" }}
    className="w-[364px]"
    variant="elevated-subtle"
    image={grpCardImage}
    title="Golf Retirement Plus"
    subhead="Manage the Golf Retirement Plus benefit program, including updating contributions, allocations, quarterly statements, enrollments, and more."
  />
);

const cards = { [DOMAIN.BENEFIT_PROGRAMS]: GRPCard };

export default function BenefitProgramsPage() {
  usePageTitle("/benefit-programs", "Benefit Programs");
  const { capabilities } = useCapabilities();

  const permittedCards = values(
    pick(
      cards,
      capabilities.domains.map((domain: DOMAIN) => domain)
    )
  );
  return (
    <AppLayout>
      <PageHeading title="Benefit Programs" />
      <div className="flex flex-row mt-12">
        {permittedCards.map((Card, i) => (
          <Card key={i} />
        ))}
      </div>
    </AppLayout>
  );
}
