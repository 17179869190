import Tabs from "@/components/Tabs";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useNavigate } from "@/router";
import { useLocation } from "react-router-dom";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  return (
    <AppLayout>
      <PageHeading title="Billing" />
      <div className="mt-4 sm:mt-8">
        <Tabs
          tabItems={[
            {
              label: "Orders",
              to: "/billing/orders" as const
            },
            {
              label: "Payments",
              to: "/billing/payments" as const
            },
            {
              label: "Dues Management",
              to: "/billing/dues-management" as const
            }
          ].map(({ label, to }) => ({
            label,
            onClick: () => navigate(to),
            active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
          }))}
        />
      </div>
      <div className="flex flex-col">{children}</div>
    </AppLayout>
  );
}
