import { useState, useEffect } from "react";
import classNames from "classnames";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import "./DatePicker.css";

export interface DateSelectProps {
  value: DateValueType | string;
  onChange: (date: DateValueType | string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  label?: string;
  placeholder?: string;
  displayFormat?: string;
  applyButtonText?: string;
  cancelButtonText?: string;
  useSingleDate?: boolean;
  useMultiCalendar?: boolean;
  showFooter?: boolean;
  showShortcuts?: boolean;
  supportingText?: string;
  bgClassName?: string;
  minDate?: Date;
  maxDate?: Date;
  error?: boolean;
  errorMessage?: string;
  popoverDirection?: "up" | "down";
  setIsDateSelectorOpen?: (l: boolean) => void;
  disabled?: boolean;
  className?: string;
}

function parseDateValueFromString(value: string): DateValueType {
  const [startDate, endDate] = value.split(" - ");
  return { startDate, endDate };
}

function setDefaultPopoverDirection() {
  return window.innerHeight - (document.getElementById("dateSelector")?.getBoundingClientRect()?.bottom || 0) <
    window.innerHeight / 2
    ? "up"
    : "down";
}

export default function DateSelect({
  value,
  onChange,
  onFocus,
  onBlur,
  label = "",
  placeholder = " ",
  displayFormat = "MM/DD/YYYY",
  applyButtonText = "OK",
  cancelButtonText = "Cancel",
  useSingleDate = false,
  useMultiCalendar = false,
  showFooter = true,
  showShortcuts = false,
  supportingText,
  bgClassName,
  minDate,
  maxDate,
  error,
  errorMessage,
  popoverDirection,
  setIsDateSelectorOpen,
  disabled = false,
  className
}: DateSelectProps) {
  const [isFocused, setIsFocused] = useState(false);
  const dateValue: DateValueType = typeof value === "string" ? parseDateValueFromString(value) : value;
  const isMobileDisplay = isMobile(useWindowSize().width);
  useEffect(() => {
    setIsDateSelectorOpen?.(isFocused);
  }, [isFocused, setIsDateSelectorOpen]);
  return (
    <div
      id="dateSelector"
      data-testid="date-select"
      onFocus={() => {
        setIsFocused(true);
        onFocus?.();
      }}
      onBlur={() => {
        setIsFocused(false);
        onBlur?.();
      }}
      className={classNames("date-select relative w-full", className, {
        "filter-active": dateValue?.startDate && dateValue?.endDate
      })}
    >
      <Datepicker
        disabled={disabled}
        value={dateValue}
        onChange={onChange}
        separator="-"
        placeholder={placeholder}
        popoverDirection={popoverDirection ?? setDefaultPopoverDirection()}
        displayFormat={displayFormat}
        showFooter={showFooter}
        showShortcuts={showShortcuts}
        useRange={useMultiCalendar}
        asSingle={useSingleDate}
        inputClassName={classNames(
          "w-full h-[56px] pl-4 border text-sys-brand-on-surface placeholder-sys-brand-on-surface border-sys-brand-outline outline-sys-brand-outline bg-sys-brand-surface rounded-[4px] cursor-pointer",
          {
            "border-sys-brand-primary outline-sys-brand-primary": isFocused
          },
          {
            "!border-sys-brand-error  text-sys-brand-error": error
          },
          {
            "!bg-sys-brand-disabled  text-sys-brand-disabled outline-sys-brand-disabled opacity-50": disabled
          },
          bgClassName
        )}
        configs={{
          footer: {
            cancel: cancelButtonText,
            apply: applyButtonText
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        readOnly={isMobileDisplay}
      />
      {label && (
        <label
          className={classNames(
            "absolute px-1 -top-[8px] left-[12px] bg-white text-body-small text-sys-brand-on-surface",
            {
              "text-sys-brand-primary": isFocused
            },
            {
              "!text-sys-brand-error ": error
            },
            {
              "opacity-50": disabled
            },
            bgClassName
          )}
        >
          {label}
        </label>
      )}
      {!error && supportingText && (
        <div className="text-body-small text-sys-brand-on-surface ml-4 mt-1">{supportingText}</div>
      )}
      {error && <div className="text-body-small text-sys-brand-error ml-4 mt-1">{errorMessage}</div>}
    </div>
  );
}
