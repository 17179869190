import Dialog from "@/components/Dialog";
import TextField from "@/components/TextField";
import Button from "@/components/Button";
import Select from "@/components/Select";
import { RegistrantRow } from "../types";
import { useForm, Controller } from "react-hook-form";

interface UpdateStatusModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (status: string, note: string) => void;
  eventRegistration: RegistrantRow;
}

export default function UpdateStatusModal({ open, onClose, onSave, eventRegistration }: UpdateStatusModalProps) {
  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      status: eventRegistration.additionalStatus,
      note: eventRegistration.note || ""
    },
    mode: "onSubmit"
  });

  const handleSave = async () => {
    const result = await trigger();
    const formValues = getValues();

    if (result) {
      onSave(formValues.status, formValues.note);
      onClose();
    }
  };

  const options = [
    { value: "cheating", displayText: "Cheating" },
    { value: "disqualified", displayText: "Disqualified" },
    { value: "excused", displayText: "Excused" },
    { value: "no_card", displayText: "No Card" },
    { value: "no_show", displayText: "No Show" },
    { value: "poor_conduct", displayText: "Poor Conduct" },
    { value: "withdrawal", displayText: "Withdrawal" }
  ];

  return (
    <Dialog
      open={open}
      onClosed={onClose}
      headline={`Update Status`}
      className="max-w-md min-w-[600px]"
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => handleSave()}>Save</Button>
        </>
      }
    >
      <div className="flex flex-col gap-4">
        <Controller
          control={control}
          name="status"
          rules={{ required: "Status is required" }}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                label="Status"
                required
                value={value}
                options={options}
                onSelect={onChange}
                className="w-full"
                error={!!errors.status}
                errorText={errors.status?.message as string}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="note"
          render={({ field: { onChange, value } }) => (
            <TextField label="Note" type="textarea" rows={7} value={value} onChange={onChange} />
          )}
        />
      </div>
    </Dialog>
  );
}
