import { useNavigate } from "@/router";
import { Path } from "@/router";
import ActionCard from "@/components/ActionCard";
import TabLayout from "../_TabLayout";
import usePageTitle from "@/hooks/usePageTitle";
import { MaterialNames } from "@/components/material_symbol_names";
import { useFlags } from "@/hooks/useFeatureFlags";

export default function DuesManagementPage() {
  usePageTitle("/billing/dues-management", "Billing | Dues Management");
  const navigate = useNavigate();
  const flags = useFlags();

  const actionCards = [
    ...(flags["backoffice-dues-rates"]
      ? [
          {
            title: "Manage Products",
            description: "Add or update the products that are added to Dues orders, including pricing",
            iconName: "box",
            actionFn: () => navigate("/billing/dues-management/manage-products" as Path)
          }
        ]
      : []),
    {
      title: "Manage Suspensions and Terminations",
      description: "View, suspend, or terminate people who are eligible because of unpaid Dues",
      iconName: "group_off",
      actionFn: () => navigate("/billing/dues-management/suspensions-and-terminations" as Path)
    },
    {
      title: "View Reporting",
      description: "View reports of unbilled people and unpaid Dues to identify billing gaps or upcoming suspensions",
      iconName: "monitoring",
      actionFn: () => navigate("/billing/dues-management/reporting" as Path)
    }
  ];

  return (
    <TabLayout>
      <div className="pt-8 pb-3 justify-between tablet:flex">
        <div className="max-w-[1000px] pr-5">
          <h5 className="text-title-medium sm:ml-4">Manage Dues</h5>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-x-12 max-w-[1100px]">
        {actionCards.map((card) => (
          <div key={card.title}>
            <ActionCard
              actionFn={card.actionFn}
              iconName={card.iconName as MaterialNames}
              text={card.description}
              title={card.title}
            />
          </div>
        ))}
      </div>
    </TabLayout>
  );
}
