import rg4js from "raygun4js";
import { useEffect, useContext } from "react";
import { RaygunContext } from "@/providers/RaygunProvider";
import { useAuth0 } from "@auth0/auth0-react";

export default function useRaygun() {
  const { raygunBooted, raygunApiKey } = useContext(RaygunContext);

  const { user, isLoading, isAuthenticated } = useAuth0();
  const email = user?.email;
  if (!user && !isLoading && isAuthenticated) throw new Error("User not available");

  useEffect(() => {
    if (!raygunApiKey || !raygunBooted) return;

    if (email) {
      rg4js("setUser", {
        identifier: email,
        isAnonymous: false
      });
    } else {
      rg4js("setUser", {
        identifier: "",
        isAnonymous: true
      });
    }
  }, [email, raygunBooted, raygunApiKey]);
}
