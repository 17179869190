import { useEffect } from "react";
import { useForm } from "react-hook-form";
import MultiStepForm from "@/components/MultiStepForm";
import { EventSchema, FormValues } from "../_form/validation/schema";
import EventDetailsStep from "../_form/steps/EventDetailsStep";
import SessionDetailsStep from "../_form/steps/SessionDetailsStep";
import RegistrationDetailsStep from "../_form/steps/RegistrationDetailsStep";
import PricingStep from "../_form/steps/PricingStep";
import CustomFieldsStep from "../_form/steps/CustomFieldsStep";
import EventDescriptionStep from "../_form/steps/EventDescriptionStep";
import { toDateFormat } from "@/lib/dateHelpers";
import { getDefaultFormValues, getEarliestSession, toSessionDateTime } from "./utils";
import { zodResolver } from "@hookform/resolvers/zod";
import ModulesStep from "../_form/steps/ModulesStep";
import { useFlags } from "@/hooks/useFeatureFlags";

interface EventFormProps {
  initialValues?: Partial<FormValues>;
  onSubmit: (data: FormValues) => Promise<void>;
  isEditing?: boolean;
  hasRegistrations?: boolean;
}

export default function EventForm({
  initialValues,
  onSubmit,
  isEditing = false,
  hasRegistrations = false
}: EventFormProps) {
  const flags = useFlags();

  const form = useForm<FormValues>({
    defaultValues: {
      ...getDefaultFormValues(),
      ...initialValues
    },
    resolver: zodResolver(EventSchema)
  });

  const { getFieldState, watch, setValue } = form;

  const sessions = watch("sessions");
  const timezone = watch("timezone");
  const registrationOpenDate = watch("registrationOpenDate.startDate");
  const registrationCloseDate = watch("registrationCloseDate.startDate");

  const hasMaxAttendance = sessions.some(({ maxAttendance }) => !!maxAttendance);

  useEffect(() => {
    if (!hasMaxAttendance) {
      setValue("waitlistType", "disabled");
    }
  }, [hasMaxAttendance, setValue]);

  let steps = [
    {
      headline: "Event Details",
      supportingText: "Step 1",
      content: <EventDetailsStep hasRegistrations={hasRegistrations} />,
      requiredFields: [
        "eventName",
        "eventType",
        "section",
        "sectionId",
        "locationType",
        "facility",
        "facilityId",
        "address1",
        "city",
        "state",
        "zipcode"
      ]
    },
    {
      headline: "Session Details",
      supportingText: "Step 2",
      content: <SessionDetailsStep />,
      requiredFields: ["sessions"]
    },
    {
      headline: "Registration Options",
      supportingText: "Step 3",
      content: <RegistrationDetailsStep hasMaxAttendance={hasMaxAttendance} />,
      requiredFields: ["registrationOpenDate", "registrationCloseDate"]
    },
    {
      headline: "Pricing",
      supportingText: "Step 4",
      content: <PricingStep hasRegistrations={hasRegistrations} />,
      requiredFields: ["products"]
    },
    {
      headline: "Additional Information",
      supportingText: "Step 5",
      content: <CustomFieldsStep />
    },
    {
      headline: "Modules",
      supportingText: "Step 6",
      content: <ModulesStep />
    },
    {
      headline: "Event Description",
      supportingText: "Step 7",
      content: <EventDescriptionStep />
    }
  ];

  if (!flags["backoffice-events-pat"]) {
    steps = steps.filter((step) => step.headline !== "Pricing");
  }

  const earliestSession = getEarliestSession(sessions, timezone);
  const notifySessionsChanged = isEditing && hasRegistrations && getFieldState("sessions").isDirty;

  const submitDialogContent = (
    <div className="flex flex-col">
      <p className="text-body-medium pb-6">
        {isEditing
          ? "Are you sure you want to publish changes to this event?"
          : "Are you sure you want to publish this event and make it visible to potential registrants?"}
        {notifySessionsChanged && " Because you have made changes to session dates/times, recipients will be notified."}
      </p>
      <p className="text-body-medium">
        <span className="font-medium">Start Date:</span>{" "}
        {toSessionDateTime(earliestSession.date.startDate, earliestSession.startTime, timezone)}
      </p>
      <p className="text-body-medium">
        <span className="font-medium">Registration Open Date:</span>{" "}
        {toDateFormat(registrationOpenDate, "LLL dd, yyyy")}
      </p>
      <p className="text-body-medium">
        <span className="font-medium">Registration Close Date:</span>{" "}
        {toDateFormat(registrationCloseDate, "LLL dd, yyyy")}
      </p>
    </div>
  );

  return (
    <div data-testid="multi-step-form">
      <MultiStepForm
        form={form}
        isEditing={isEditing}
        modelName="Event"
        submitText="Publish Event"
        backLink={{ to: "/events", title: "Cancel" }}
        steps={steps}
        renderStepper={true}
        validationSchema={EventSchema}
        onSubmit={onSubmit}
        submitDialogContent={submitDialogContent}
      />
    </div>
  );
}
