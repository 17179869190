import { Controller, useFormContext } from "react-hook-form";
import Select from "@/components/Select";
import { ProductsFormValues } from "../types";

const COLLECTION_OPTIONS = [
  { value: "registration", displayText: "During Registration" },
  { value: "optional", displayText: "During Registration - Optional" },
  { value: "onsite", displayText: "Onsite" }
];

interface CollectionDropdownProps {
  index: number;
  disabled?: boolean;
}

const CollectionDropdown = ({ index, disabled }: CollectionDropdownProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<ProductsFormValues>();

  return (
    <Controller
      name={`products.${index}.collection`}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          label="Collection"
          options={COLLECTION_OPTIONS}
          className="w-full"
          value={value}
          disabled={disabled}
          onSelect={onChange}
          required
          data-testid={`collection-select-${index}`}
          error={!!errors.products?.[index]?.collection}
          errorText={errors.products?.[index]?.collection?.message as string}
        />
      )}
    />
  );
};

export default CollectionDropdown;
