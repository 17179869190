import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import { TableDetails } from "@/components/Table";
import { useFlags } from "@/hooks/useFeatureFlags";
import { Person, EducationCertification } from "@/types";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_EDUCATION_CERTIFICATION_HISTORY_QUERY } from "./queries";
import { toFormat } from "@/lib/dateHelpers";
import MetricGrid from "@/components/MetricGrid";
import { getStickyColumnClasses } from "@/lib/styleHelpers";

type PersonData = { person: Person };

const formatTableData = (list: EducationCertification[]) =>
  list.map((e: EducationCertification, id: number) => ({
    id: id.toString(),
    programDescription: `${e.programDescription} (${e.programName})`,
    curriculumVersion: e.curriculumVersion,
    careerPath: e.careerPath,
    startDate: toFormat(e.startDate),
    endDate: e.endDate && toFormat(e.endDate),
    expirationDate: e.subscription?.endDate && toFormat(e.subscription.endDate)
  }));

export default function LearningDetails() {
  const flags = useFlags();
  const { pgaId } = useParams();

  const { data, loading, error } = useQuery<PersonData>(GET_EDUCATION_CERTIFICATION_HISTORY_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  if (error) return <div>Error: {error.message}</div>;

  if (!flags["backoffice-person-profile-education-learning"]) {
    return null;
  }

  const personData = data?.person;
  const certificationHistory = personData?.educationCertificationHistory;
  const latestCertification = personData?.educationLatestCertification;
  const tableData = !!certificationHistory?.length ? formatTableData(certificationHistory) : [];

  const metrics = latestCertification
    ? [
        {
          label: "Certification Program Type",
          value: latestCertification.programName
        },
        {
          label: "Career Path",
          value: latestCertification.careerPath
        },
        {
          label: "Start Date",
          value: toFormat(latestCertification.startDate)
        },
        {
          label: "Completion Date",
          value: latestCertification.endDate && toFormat(latestCertification.endDate)
        },
        {
          label: "Expiration Date",
          value: latestCertification.subscription?.endDate && toFormat(latestCertification.subscription.endDate)
        }
      ]
    : [];

  return (
    <div>
      {!!metrics.length && (
        <div className="mt-4">
          <MetricGrid metrics={metrics} layout="expanded" />
        </div>
      )}
      <Table
        loading={loading}
        renderDetails={() => (
          <TableDetails heading="PGA Lifelong Learning" body="View official Lifelong Learning certification history" />
        )}
        data={tableData}
        columns={[
          {
            header: "Program",
            id: "programDescription",
            accessorKey: "programDescription",
            size: 250,
            enableSorting: false,
            meta: {
              className: getStickyColumnClasses(!!tableData?.length)
            }
          },
          {
            header: "Version",
            id: "curriculumVersion",
            accessorKey: "curriculumVersion",
            enableSorting: false
          },
          {
            header: "Career Path",
            id: "careerPath",
            accessorKey: "careerPath",
            enableSorting: false
          },
          {
            header: "Start Date",
            id: "startDate",
            accessorKey: "startDate",
            enableSorting: false
          },
          {
            header: "End Date",
            id: "endDate",
            accessorKey: "endDate",
            enableSorting: false
          },
          {
            header: "Expiration",
            id: "expirationDate",
            accessorKey: "expirationDate",
            enableSorting: false
          }
        ]}
        renderEmptyState={() => (
          <EmptyState
            title="No Programs"
            caption="This person has not started any lifelong learning programs"
            iconName="workspace_premium"
          />
        )}
        data-testid="learning-history-table"
      />
    </div>
  );
}
