import { useContext } from "react";
import { AmplitudeContext } from "@/providers/AmplitudeProvider/AmplitudeProvider";

const useAmplitude = () => {
  const amplitude = useContext(AmplitudeContext);

  const trackEvent = (eventName: string, eventProperties?: Record<string, any>) => {
    amplitude.track(eventName, eventProperties);
  };

  return { trackEvent };
};

export default useAmplitude;
