import { Facility, Section } from "@/types";
import { DateType } from "react-tailwindcss-datepicker";

export type User = {
  name: string;
  email: string;
};

export type Compensation = {
  baseMaximum: number;
  baseMinimum: number;
  totalAnticipated: number;
  currencyUnit: string;
};

export type EmployerContact = {
  name: string;
  email: string;
  title: string;
  phone: string;
};

export type JobPostCategory = {
  name: string;
  area: string;
  type: string;
};

export type JobPost = {
  id: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  facility: Facility;
  section: Section;
  currentCompensation: number;
  previousCompensation: number;
  owner: User;
  numberOfApplicants: number;
  priority: string;
  status: string;
  subject: string;
  name: string;
  agreedToTermsOfService: boolean;
  serviceLevel: string;
  compensation: Compensation;
  benefits: string;
  additionalIncome: string;
  pointOfContact: EmployerContact;
  essentialResponsibilities: string;
  category: JobPostCategory;
  overview: string;
  pgaOrgUrl: string;
  requiredEducation: string;
  requiredExperienceDescription: string;
  hideCompensationFromJobBoard: boolean;
  applicantGroup: string;
  applyVia: string;
  paySchedule: string;
  positionType: string;
  stage: string;
  optInToCareerConsultantAssistance: boolean;
  otherSections: string[];
  otherStates: string[];
  submissionDeadline: Date | string;
  additionalPgaEducation: string;
  externalJobUrl: string;
  stopCaseCloseNotifications: boolean;
  switchToConsultantAssistance: boolean;
};

export enum PostStage {
  DRAFT = "Draft",
  PENDING = "Pending",
  REVIEW = "Review",
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  REMOVED = "Removed"
}

// Convert filled to be closed_complete for query.
// Convert unfilled to be open or closed_incomplete.
export enum HiringStatus {
  FILLED = "Filled",
  INCOMPLETE = "Incomplete",
  IN_PROGRESS = "In Progress",
  EXPIRED = "Expired"
}

export interface JobsQueryVariables {
  searchTerm?: string;
  stage?: string[];
  status?: string[];
  serviceLevel?: string[];
  sectionId?: string;
  facilityId?: string;
  ownerId?: string;
  lowerDateRange?: DateType;
  upperDateRange?: DateType;
}
