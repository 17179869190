import PropTypes from "prop-types";
import Stepper from "@/components/Stepper";
import MobileStepDisplay from "@/components/MultiStepForm/MobileStepDisplay";
import { isMobile } from "@/assets/theme/sizes";

const SessionStepper = ({
  sessionDates,
  activeSession,
  onSessionChange
}: {
  sessionDates: Array<{ startsAt: string }>;
  activeSession: number;
  onSessionChange: (index: number) => void;
}) => {
  const handleStepClick = (index: number) => {
    onSessionChange(index);
  };

  const stepsList = sessionDates.map((session, index) => ({
    headline: new Intl.DateTimeFormat("en-US", { year: "numeric", month: "short", day: "numeric" }).format(
      new Date(session.startsAt)
    ),
    supportingText: `Session ${index + 1}`
  }));

  return isMobile() ? (
    stepsList.length > 0 ? (
      <MobileStepDisplay steps={stepsList} currentStep={activeSession} handleStepClick={handleStepClick} />
    ) : null
  ) : (
    <Stepper activeIndex={activeSession} onStepClick={handleStepClick} stepsList={stepsList} variant="status" />
  );
};

SessionStepper.propTypes = {
  sessionDates: PropTypes.array.isRequired
};

export default SessionStepper;
