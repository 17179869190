import { useState, useEffect, useRef } from "react";
import { LitRadio } from "./LitRadio";
import classNames from "classnames";

export interface RadioItemProps {
  headline: string;
  supportingText?: string;
  onChange: (value: boolean) => void;
  checked: boolean;
  name: string;
  disabled?: boolean;
}

export default function RadioItem({
  headline,
  supportingText,
  onChange,
  checked,
  disabled = false,
  name
}: RadioItemProps) {
  const [height, setHeight] = useState<number>(0);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setHeight(textRef.current.clientHeight);
    }
  }, [headline]);

  const hasThreeLines = height >= 56;
  const hasOneLine = height <= 24;
  const heightClass = hasThreeLines ? "items-start" : "items-center";
  return (
    <div className={classNames(`${heightClass} flex space-x-2 w-full`, { "h-[40px]": hasOneLine })}>
      <div className={classNames("flex flex-col justify-start", { "h-full": hasThreeLines })}>
        <LitRadio
          disabled={disabled}
          checked={checked}
          name={name}
          onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
        />
      </div>
      <div
        ref={textRef}
        className={classNames("flex flex-col items-start px-2.5 cursor-pointer")}
        onClick={() => !disabled && onChange(!checked)}
      >
        <div className="text-body-large text-sys-brand-on-surface word-break">{headline}</div>
        <div className="text-body-medium text-sys-brand-on-surface-variant">{supportingText}</div>
      </div>
    </div>
  );
}
