import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import Dialog from "@/components/Dialog";
import Divider from "@/components/Divider";
import TextField from "@/components/TextField";
import { centsToDollarsFormatted, currencyFormatter } from "@/lib/currencyHelpers";
import { sumBy, unionBy } from "lodash";
import { Control, Controller, FieldErrors, useForm, useFieldArray } from "react-hook-form";
import { NumberFormatBase } from "react-number-format";
import type { ItemFormValue, OrderFormValue } from "..";
import { differenceBy } from "lodash";
import { buildTableData } from "../../../_utils";
import { AddProduct } from "./AddProduct";
import IconButton from "@/components/IconButton";
import { FormProvider } from "react-hook-form";

const AllocationLineItem = ({
  index,
  item,
  name,
  control,
  errors,
  remove
}: {
  index: number;
  item: ItemFormValue;
  name: "lineItems" | "addons";
  control: Control<OrderFormValue, any>;
  errors: FieldErrors<OrderFormValue>;
  remove?: (index: number) => void;
}) => {
  const selectedControlName = `${name}.${index}.selected` as const;
  const allocatedControlName = `${name}.${index}.allocated` as const;

  return (
    <div className="flex justify-between items-center w-full py-3">
      <div className="flex items-center">
        <Controller
          render={({ field }) => {
            return (
              <Checkbox
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                name={selectedControlName}
              />
            );
          }}
          control={control}
          name={selectedControlName}
        />
        <div className="w-[410px]  ml-4">
          {item.isNew ? (
            <AddProduct index={index} />
          ) : (
            <>
              <div className="text-body-large text-sys-brand-on-surface text-nowrap">{item.name}</div>
              {name === "lineItems" && (
                <div className="text-body-medium text-sys-brand-on-surface-variant">
                  Balance: {centsToDollarsFormatted(item.balance)}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="w-[210px] flex" data-testid="allocation-amount">
        <Controller
          render={({ field }) => {
            return (
              <NumberFormatBase
                disabled={false}
                value={(field.value / 100).toFixed(2) || 0}
                format={currencyFormatter}
                customInput={TextField}
                error={name === "lineItems" && !!errors?.lineItems?.[index]?.allocated}
                errorText={name === "lineItems" ? errors?.lineItems?.[index]?.allocated?.message : undefined}
                onFocus={(e) => {
                  e.target.select();
                }}
                onValueChange={(values) => {
                  field.onChange(Math.round(values.floatValue || 0));
                }}
                leadingIcon="attach_money"
                label="Allocation Amount"
              />
            );
          }}
          control={control}
          name={allocatedControlName}
          rules={{
            max:
              name === "lineItems"
                ? {
                    value: item.balance,
                    message: "Must be less than or equal to Balance"
                  }
                : undefined
          }}
        />
        {item.isNew && <IconButton name="delete" onClick={() => remove?.(index)} />}
      </div>
    </div>
  );
};

export default function ApplyPaymentModal({
  onDismiss,
  onChange,
  order,
  paymentAmount,
  orderData
}: {
  onDismiss: () => void;
  onChange: (order: OrderFormValue) => void;
  order: OrderFormValue;
  paymentAmount?: number | undefined | null;
  orderData: OrderFormValue[];
}) {
  const form = useForm<OrderFormValue>({
    defaultValues: order,
    mode: "onChange"
  });
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = form;
  const lineItems = watch("lineItems");
  const addons = watch("addons");

  const filterBySelected = (items: ItemFormValue[]) => items.filter((item) => item.selected);
  const allocations = unionBy(lineItems, addons);

  const paymentAllocated = sumBy(filterBySelected(allocations), (item) => item.allocated || 0);
  const balance = sumBy(lineItems, (item) => item.balance || 0);
  const allocatedLineItems = sumBy(filterBySelected(lineItems), (item) => item.allocated || 0);

  const totalAllocated = paymentAllocated;
  const balanceAfterPayment = balance - allocatedLineItems;

  const otherOrders = differenceBy(orderData, [order], "id");
  const { totals } = buildTableData(otherOrders, paymentAmount);

  async function cancelModal() {
    onDismiss();
  }
  function continueModal(values: OrderFormValue) {
    onChange(values);
  }

  const actions = (
    <div className="flex flex-col">
      <div className="flex justify-end gap-1 py-1">
        <span className="text-label-large text-sys-brand-on-surface">Total Allocated</span>
        <div className="text-body-medium w-[80px] text-right">{centsToDollarsFormatted(totalAllocated)}</div>
      </div>
      <div className="flex justify-end gap-1 py-1">
        <span className="text-label-large text-sys-brand-on-surface">Balance After Payment</span>
        <div className="text-body-medium w-[80px] text-right">{centsToDollarsFormatted(balanceAfterPayment)}</div>
      </div>
      <div className="flex justify-end gap-1 pb-1 pt-3">
        <span className="text-body-medium text-sys-brand-on-surface">Amount Unallocated from Payment</span>
        <div className="text-body-medium w-[80px] text-right">
          {centsToDollarsFormatted((paymentAmount || 0) - (totals.totalAllocated + totalAllocated))}
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <Button variant="text" onClick={cancelModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(continueModal)}>Continue Editing</Button>
      </div>
    </div>
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addons"
  });
  return (
    <Dialog
      onClosed={onDismiss}
      open
      actions={actions}
      headline="Allocate Payment"
      className="min-w-[740px] h-fit max-h-[90vh]"
    >
      <FormProvider {...form}>
        <div>
          <p className="text-body-medium">
            Allocate the payment amount to the desired products. The add-ons associated with the original order have
            been defaulted below for convenience.
          </p>
          {lineItems?.map((item, index) => (
            <AllocationLineItem
              key={item.id}
              index={index}
              item={item}
              name="lineItems"
              control={control}
              errors={errors}
            />
          ))}
          <Divider className="my-6" />
          <h3 className="text-title-medium text-sys-brand-on-surface mt-6 mb-3">Add-Ons</h3>
          {fields?.map((item, index) => (
            <AllocationLineItem
              key={item.id}
              index={index}
              item={item}
              name="addons"
              control={control}
              errors={errors}
              remove={remove}
            />
          ))}
          <div className="mt-3">
            <Button
              variant="filledTonal"
              icon="add"
              onClick={() => {
                append({ id: "", name: "", selected: true, allocated: 0, isNew: true, balance: 0 });
              }}
            >
              Add Product
            </Button>
          </div>
          <div className="pt-6 pb-4">
            <Controller
              render={({ field }) => (
                <TextField
                  disabled={false}
                  type="text"
                  className="w-full"
                  value={field.value || ""}
                  label="Note"
                  onChangeText={field.onChange}
                />
              )}
              control={control}
              name="note"
            />
          </div>
        </div>
      </FormProvider>
    </Dialog>
  );
}
