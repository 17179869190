import { useState, ReactNode, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import Button from "@/components/Button";
import Stepper from "@/components/Stepper";
import IconButton from "../IconButton";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import Dialog from "@/components/Dialog";
import MobileStepDisplay from "./MobileStepDisplay";
import { LinkProps } from "@generouted/react-router/client";
import { Params, Path } from "@/router";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import { ZodSchema } from "zod";
import classNames from "classnames";

interface StepType {
  headline: string;
  supportingText: string;
}

interface Step extends StepType {
  content: ReactNode;
  requiredFields?: string[];
}

interface MultiStepFormProps {
  form: any;
  steps: Step[];
  onSubmit: (data: any) => void;
  submitDialogContent?: ReactNode;
  renderStepper?: boolean;
  backLink?: LinkProps<Path, Params> | (unknown & { title: string });
  submitText?: string;
  modelName: string;
  isEditing?: boolean;
  enableSubmitOnLastStep?: boolean;
  validationSchema?: ZodSchema;
  fullWidth?: boolean;
  objectHeading?: ReactNode;
  tag?: ReactNode;
}

interface StepsNavigationProps {
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  validateStep: (step: number) => Promise<boolean>;
}

function StepsNavigation({ steps, currentStep, setCurrentStep, validateStep }: StepsNavigationProps) {
  const handleNext = async () => {
    const isStepValid = await validateStep(currentStep);

    if (isStepValid) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex mt-8 h-10">
      {currentStep > 0 && (
        <Button icon="arrow_back_ios" variant="text" onClick={handlePrevious}>
          Previous
        </Button>
      )}
      {currentStep < steps.length - 1 && <Button onClick={handleNext}>Next</Button>}
    </div>
  );
}

export default function MultiStepForm({
  form,
  steps,
  onSubmit,
  validationSchema,
  renderStepper,
  backLink,
  submitText = "Publish",
  submitDialogContent,
  modelName,
  isEditing = false,
  enableSubmitOnLastStep = false,
  fullWidth = false,
  objectHeading,
  tag
}: MultiStepFormProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [actions, setActions] = useState<React.ReactNode>(null);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const { isDirty, isValid: isCurrentStepValid } = form.formState;

  const validateStep = async (currentStep: number) => {
    const currentStepRequiredFields = enableSubmitOnLastStep ? undefined : steps[currentStep].requiredFields;
    return await form.trigger(currentStepRequiredFields);
  };

  const handleFormValidation = async () => {
    const validationResult = await validationSchema?.safeParseAsync(form.getValues());
    return validationResult?.success;
  };

  const handleShowSubmitDialog = async () => {
    const isValid = (enableSubmitOnLastStep && (await form.trigger())) || (await handleFormValidation());
    if (isValid) {
      setShowSubmitDialog(true);
    }
  };

  const handleSubmit = () => {
    onSubmit(form.getValues());
    setShowSubmitDialog(false);
  };

  const getActionButton = (onClick: () => void) => (
    <Button variant="filled" onClick={onClick}>
      {submitText}
    </Button>
  );

  const isDialogable = !!submitDialogContent || !isEditing;

  const actionButton = getActionButton(isDialogable ? handleShowSubmitDialog : handleSubmit);

  useEffect(() => {
    const updateActions = async () => {
      const isLastStep = currentStep === steps.length - 1;
      if (isLastStep && enableSubmitOnLastStep) {
        setActions(actionButton);
        return;
      }
      if (isDirty && enableSubmitOnLastStep) {
        setActions(actionButton);
        return;
      }

      const isFormValid = await handleFormValidation();
      if (isFormValid) {
        setActions(actionButton);
      } else {
        setActions(null);
      }
    };

    updateActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, isCurrentStepValid, currentStep, submitText, steps.length, isDirty]);

  const handleCancel = () => {
    setShowCancelDialog(false);
    if (backLink && "to" in backLink) {
      window.location.href = backLink.to;
    }
  };

  const handleShowCancelDialog = () => {
    if (isDirty) {
      setShowCancelDialog(true);
    } else {
      handleCancel();
    }
  };

  const cancelButton = (
    <div className="flex items-center cursor-pointer" onClick={handleShowCancelDialog}>
      <IconButton name="arrow_back" data-testid="back" iconClassName="text-sys-brand-on-surface" />
      <span className="text-title-large">{backLink?.title || "Cancel"}</span>
    </div>
  );

  const windowSize = useWindowSize();
  const isMobileView = isMobile(windowSize.width);

  function renderFormSteps(isMobileView: boolean, steps: Step[], currentStep: number) {
    if (isMobileView) {
      return <MobileStepDisplay steps={steps} currentStep={currentStep} handleStepClick={handleStepClick} />;
    }

    return (
      <div className="flex flex-col w-1/6 items-center">
        <Stepper
          variant="status"
          stepsList={steps}
          activeIndex={currentStep}
          onStepClick={handleStepClick}
          className="pl-1.5"
        />
      </div>
    );
  }

  const handleStepClick = async (index: number) => {
    const isStepValid = await validateStep(currentStep);

    if (isStepValid) {
      setCurrentStep(index);
      window.scrollTo(0, 0);
    }
  };

  return (
    <SinglePageLayout cancelButton={cancelButton} actions={actions}>
      {objectHeading && (
        <div className={classNames("flex justify-center gap-3 mb-16", isMobileView ? "pt-8" : "pt-16")}>
          <div className="flex flex-col w-1/6 min-w-[300px]">{objectHeading}</div>
          <div className="w-8/12 flex justify-end items-start pt-3">{tag}</div>
        </div>
      )}

      <div
        className={classNames(
          "flex",
          isMobileView ? "flex-col" : "flex-row gap-3 justify-center pt-16",
          !fullWidth && isMobileView && "max-w-[760px]"
        )}
      >
        {renderStepper && renderFormSteps(isMobileView, steps, currentStep)}
        <div
          className={classNames(
            "flex flex-col",
            isMobileView ? "w-full" : "w-8/12",
            !fullWidth && !isMobileView && "max-w-[760px]"
          )}
        >
          <FormProvider {...form}>
            {steps[currentStep].content}
            {steps.length > 1 && (
              <StepsNavigation
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                validateStep={validateStep}
              />
            )}
          </FormProvider>
        </div>
      </div>

      {showSubmitDialog && (
        <Dialog
          open={showSubmitDialog}
          onClosed={() => setShowSubmitDialog(false)}
          headline={`Publish ${modelName}`}
          className="max-w-[423px] p-6 pr-2"
          actions={
            <>
              <Button variant="text" onClick={() => setShowSubmitDialog(false)}>
                Cancel
              </Button>
              <Button variant="filled" onClick={handleSubmit} testId="publish-button">
                Publish {modelName}
              </Button>
            </>
          }
        >
          {submitDialogContent || (
            <p>Are you sure you want to publish this {modelName.toLowerCase()} and make it visible?</p>
          )}
        </Dialog>
      )}

      {showCancelDialog && (
        <Dialog
          open={showCancelDialog}
          onClosed={() => setShowCancelDialog(false)}
          headline={`Discard Changes`}
          className="max-w-[423px] p-6 pr-2"
          actions={
            <>
              <span
                className="text-sys-brand-primary cursor-pointer flex items-center justify-center mr-4"
                onClick={() => setShowCancelDialog(false)}
              >
                Keep Editing
              </span>
              <Button variant="filled" onClick={handleCancel}>
                Discard Changes
              </Button>
            </>
          }
        >
          <p>Do you want to discard your changes to this {modelName.toLowerCase()}? This cannot be undone.</p>
        </Dialog>
      )}
    </SinglePageLayout>
  );
}
