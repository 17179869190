import { useState } from "react";
import { useQuery } from "@apollo/client";
import { PAYMENTS_QUERY } from "./queries";
import { Variables, PaymentRow } from "./types";
import { PaymentMethod } from "../types";
import { PaymentNode } from "./queries";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import { renderPaymentStatus, renderPaymentType, renderStatus, sourcesData, paymentStatusData } from "../_utils";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useNavigate } from "@/router";
import { Controller } from "react-hook-form";
import SearchInput from "@/components/SearchInput";
import { useSearch } from "@/hooks/useSearch";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import AvatarDetails from "@/components/AvatarDetails";
import usePagination from "@/hooks/usePagination";
import TabLayout from "../_TabLayout";
import Icon from "@/components/Icon";
import useActionMenu, { actionId } from "@/hooks/useActionMenu";
import IconButton from "@/components/IconButton";
import TableDetailsPanel from "@/components/TableDetailsPanel";
import MultiSelect from "@/components/MultiSelect";
import { reject, isEmpty, sumBy } from "lodash";
import { Path } from "@/router";
import Button from "@/components/Button";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";
import getActiveFilters from "@/lib/filterHelpers";
import ExpandedFilters from "@/components/ExpandedFilters";
import { useParams } from "@/hooks/useSearchParams";

const mapCommerceOrders = (data: PaymentNode[]) =>
  (data || []).map(({ updatedAt, balance, lineItems, ...payment }) => ({
    id: payment.id,
    updatedAt: formatDate(updatedAt) || "",
    type: payment.type,
    checkNumber: payment.checkNumber,
    payer: payment.payer,
    payorName: payment.type === PaymentMethod.FINANCIAL_ADJUSTMENT ? "Financial Adjustment" : payment.payer.name,
    orders: payment.orderPayments.length,
    amount: centsToDollarsFormatted(payment.amountCents),
    refund: centsToDollarsFormatted(payment.refundedAmountCents),
    net: centsToDollarsFormatted(payment.amountCents - payment.refundedAmountCents),
    status: payment.status,
    orderPayments: payment.orderPayments.map(({ order, ledgerEntries }) => ({
      id: order.id,
      pgaId: order.person.pgaId,
      stage: order.stage,
      paymentStatus: order.paymentStatus,
      person: order.person,
      description: order.description,
      lineItems: order.lineItems,
      addons: order.addons,
      netPayment: centsToDollarsFormatted(sumBy(ledgerEntries, "amountCents"))
    }))
  }));

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

export default function PaymentsPage() {
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const { params, addSearchParam, deleteSearchParams, searchParamsUrl } = useParams();
  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const { Menu, setMenu } = useActionMenu();

  const [selectedStatusValues, setSelectedStatusValues] = useState<string[]>(params.status || []);
  const [selectedSourceValues, setSelectedSourceValues] = useState<string[]>(params.type || []);

  const [variables, setVariables] = useState<Variables>(params);
  const { data, loading } = useQuery(PAYMENTS_QUERY, { variables: { ...variables, ...paginationVariables } });
  const { control } = useSearch("name", params?.query || "");

  const navigate = useNavigate();

  const pattern = "/billing/orders/payment";
  function onClickRow(row: Row<PaymentRow>) {
    navigate(`${pattern}/${row.original.id}?return_to=/billing/payments&${searchParamsUrl}` as Path);
  }

  const paymentData: PaymentRow[] = mapCommerceOrders(data?.commercePayments.nodes || []);

  const isMobileDisplay = isMobile(useWindowSize().width);

  const filters = {
    source: selectedSourceValues.length > 0,
    status: selectedStatusValues.length > 0
  };
  const { isActive, activeFilters } = getActiveFilters(filters, filters, true);
  const ClearAllButton = () => (
    <Button
      variant="text"
      onClick={() => {
        setVariables((prevValue) => ({
          query: prevValue.query
        }));
        setSelectedStatusValues([]);
        setSelectedSourceValues([]);
        deleteSearchParams(["status[]", "type[]"]);
      }}
    >
      Clear All
    </Button>
  );

  return (
    <TabLayout>
      <div className="pt-6 sm:pt-8 px-0 sm:px-4 pb-6 justify-between tablet:flex">
        <div className="max-w-[1000px] pr-5">
          <p className="text-body-medium">
            View all payments, including payment details and transaction information. Track the status of each payment,
            search for specific payments, or take action such as refunding a payment.
          </p>
        </div>
      </div>
      <Menu />
      <div className="w-full">
        <Table
          data={paymentData || []}
          loading={loading}
          onClickRow={onClickRow}
          renderHeader={() => (
            <div>
              <div className="flex md:items-center flex-col md:flex-row justify-between">
                <div className="flex sm:items-center gap-3 flex-row w-full">
                  <div className="w-full sm:max-w-[320px]">
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <SearchInput
                          placeholder="Search Payments"
                          query={field.value}
                          param="Payor, Transaction ID, Payment ID, Order Name, PGA ID"
                          onChange={(e) => {
                            field.onChange(e);
                            setVariables((prevValue) => ({
                              ...prevValue,
                              query: e
                            }));
                            resetPagination();
                            addSearchParam("query", e);
                          }}
                          onClear={() => deleteSearchParams("query")}
                        />
                      )}
                    />
                  </div>
                  {!isMobileDisplay && (
                    <>
                      <div>
                        <Controller
                          name="sources"
                          control={control}
                          render={() => (
                            <MultiSelect
                              label="Source"
                              selectedValues={selectedSourceValues}
                              options={sourcesData}
                              onSelect={(value, checked) => {
                                const status = checked
                                  ? [...selectedSourceValues, value]
                                  : selectedSourceValues.filter((v) => v !== value);
                                setSelectedSourceValues(status);
                                resetPagination();
                                setVariables((prevValue) => ({
                                  ...prevValue,
                                  type: reject(status, isEmpty)
                                }));
                                addSearchParam("type[]", status.join(","));
                              }}
                              onClear={() => {
                                setSelectedSourceValues([]);
                                setVariables((prevValue) => ({
                                  ...prevValue,
                                  type: []
                                }));
                                deleteSearchParams("type[]");
                              }}
                              className="w-full"
                            />
                          )}
                        />
                      </div>
                      <div>
                        <Controller
                          name="status"
                          control={control}
                          render={() => (
                            <MultiSelect
                              label="Status"
                              selectedValues={selectedStatusValues}
                              options={paymentStatusData}
                              onSelect={(value, checked) => {
                                const status = checked
                                  ? [...selectedStatusValues, value]
                                  : selectedStatusValues.filter((v) => v !== value);
                                resetPagination();
                                setSelectedStatusValues(reject(status, isEmpty));
                                setVariables((prevValue) => ({
                                  ...prevValue,
                                  status: reject(status, isEmpty)
                                }));
                                addSearchParam("status[]", reject(status, isEmpty).join(","));
                              }}
                              onClear={() => {
                                setSelectedStatusValues([]);
                                setVariables((prevValue) => ({
                                  ...prevValue,
                                  status: []
                                }));
                                deleteSearchParams("status[]");
                              }}
                              className="w-full"
                            />
                          )}
                        />
                      </div>
                    </>
                  )}
                  {isMobileDisplay && (
                    <ExpandedFilters
                      activeFilters={activeFilters}
                      items={[
                        {
                          component: (
                            <Controller
                              name="sources"
                              control={control}
                              render={() => (
                                <MultiSelect
                                  label="Source"
                                  selectedValues={selectedSourceValues}
                                  options={sourcesData}
                                  onSelect={(value, checked) => {
                                    const status = checked
                                      ? [...selectedSourceValues, value]
                                      : selectedSourceValues.filter((v) => v !== value);
                                    setSelectedSourceValues(reject(status, isEmpty));
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      type: reject(status, isEmpty)
                                    }));
                                    addSearchParam("type[]", reject(status, isEmpty).join(","));
                                  }}
                                  onClear={() => {
                                    setSelectedSourceValues([]);
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      type: []
                                    }));
                                    deleteSearchParams("type[]");
                                  }}
                                  className="w-full"
                                />
                              )}
                            />
                          )
                        },
                        {
                          component: (
                            <Controller
                              name="status"
                              control={control}
                              render={() => (
                                <MultiSelect
                                  label="Status"
                                  selectedValues={selectedStatusValues}
                                  options={paymentStatusData}
                                  onSelect={(value, checked) => {
                                    const status = checked
                                      ? [...selectedStatusValues, value]
                                      : selectedStatusValues.filter((v) => v !== value);
                                    setSelectedStatusValues(reject(status, isEmpty));
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      status: reject(status, isEmpty)
                                    }));
                                    addSearchParam("status[]", reject(status, isEmpty).join(","));
                                  }}
                                  onClear={() => {
                                    setSelectedStatusValues([]);
                                    setVariables((prevValue) => ({
                                      ...prevValue,
                                      status: []
                                    }));
                                    deleteSearchParams("status[]");
                                  }}
                                  className="w-full"
                                />
                              )}
                            />
                          )
                        },
                        {
                          component: <>{isActive && <ClearAllButton />}</>,
                          onlyMobile: true
                        }
                      ]}
                    />
                  )}
                  {isActive && (
                    <div className="hidden sm:block" data-testid="clear-all-button">
                      <ClearAllButton />
                    </div>
                  )}
                </div>
                <div className="hidden md:block">
                  {renderFooter(data?.commercePayments, {
                    totalCount: data?.commercePayments.totalCount || 0,
                    pageCount: paymentData.length,
                    variant: "compact"
                  })()}
                </div>
              </div>

              <div className="block mt-3 md:hidden">
                {renderFooter(data?.commercePayments, {
                  totalCount: data?.commercePayments.totalCount || 0,
                  pageCount: paymentData.length,
                  variant: "compact"
                })()}
              </div>
            </div>
          )}
          renderExpandedRow={({ row }) => (
            <TableDetailsPanel
              data={row.original.orderPayments}
              onClickRow={(row) => {
                navigate(`/billing/orders/${row.original.id}?return_to=/billing/payments` as Path);
              }}
              columns={[
                {
                  header: "Name",
                  id: "fullName",
                  size: 400,
                  cell: (cellProps) => {
                    const { person } = cellProps.row.original;
                    return (
                      <AvatarDetails
                        picture={person.profilePhoto}
                        title={`${person.firstName} ${person.lastName}`}
                        text={person.mainProgramType?.name || ""}
                      />
                    );
                  },
                  meta: {
                    className: getStickyColumnClasses(!!row.original.orderPayments?.length)
                  }
                },
                { accessorKey: "pgaId", header: "PGA ID", size: 150, enableSorting: false },
                { accessorKey: "description", header: "Description", size: 300, enableSorting: false },
                {
                  accessorKey: "products",
                  header: "Products",
                  size: 150,
                  enableSorting: false,
                  cell: ({ row }) => {
                    return (
                      <div className="flex items-center gap-2 py-4 cursor-pointer">
                        <span>{row.original.lineItems.length + row.original.addons.length}</span>
                      </div>
                    );
                  }
                },
                { accessorKey: "netPayment", header: "Net Payment", size: 300, enableSorting: false },
                {
                  accessorKey: "stage",
                  header: "Stage",
                  size: 300,
                  enableSorting: false,
                  cell: ({ row }) => renderStatus(row.original.stage, row.original.paymentStatus)
                }
              ]}
            />
          )}
          columns={[
            {
              header: "Payment Source",
              id: "paymentSource",
              cell: ({ row: { original } }) => {
                return (
                  <div>
                    <p className="text-body-medium text-sys-brand-on-surface mb-0">
                      {original.type === PaymentMethod.FINANCIAL_ADJUSTMENT ? (
                        original.payer.name
                      ) : (
                        <>
                          {renderPaymentType(original.type)}
                          {original.type === PaymentMethod.CHECK ? ` #${original.checkNumber}` : ""}
                        </>
                      )}
                    </p>
                  </div>
                );
              },
              meta: {
                className: getStickyColumnClasses(!!paymentData?.length)
              },
              size: 250
            },
            { accessorKey: "updatedAt", header: "Modified", enableSorting: false },
            { accessorKey: "payorName", header: "Payor", enableSorting: false, size: 250 },
            {
              accessorKey: "orders",
              header: "Orders",
              enableSorting: false,
              cell: ({ row }) => {
                return (
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    <span>{row.original.orderPayments.length}</span>
                    <Icon name={row.getIsExpanded() ? "expand_less" : "expand_more"} />
                  </div>
                );
              }
            },
            { accessorKey: "amount", header: "Amount", enableSorting: false },
            { accessorKey: "refund", header: "Refund", enableSorting: false },
            { accessorKey: "net", header: "Net", enableSorting: false },
            {
              header: "Stage",
              id: "stage",
              cell: ({ row: { original } }) => renderPaymentStatus(original.status),
              size: 100
            },
            {
              id: "actions",
              cell({ row, row: { id: rowId } }) {
                const items = [
                  {
                    label: "View Payment",
                    onClick: () => onClickRow(row)
                  }
                ];

                if (!items.length) return null;

                function onClick() {
                  setMenu({ rowId, items });
                }

                return (
                  <div
                    className="flex flex-row justify-end"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      id={actionId(rowId)}
                      onClick={onClick}
                      data-testid="actions"
                      title="actions"
                      name="more_vert"
                      iconClassName="font-extrabold"
                      className="!m-0 h-10"
                    />
                  </div>
                );
              },
              meta: {
                className: "!py-1"
              }
            }
          ]}
          renderEmptyState={() => (
            <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="receipt_long" />
          )}
          renderFooter={() => (
            <div>
              {renderFooter(data?.commercePayments, {
                totalCount: data?.commercePayments.totalCount || 0,
                pageCount: paymentData.length
              })()}
            </div>
          )}
        />
      </div>
    </TabLayout>
  );
}
