import Banner from "@/components/Banner";

export const PrivateInteractionBanner = () => {
  return (
    <Banner
      description="Note: This interaction is private. The contents of this interaction will be available in the notification email sent to all recipients."
      inline
    />
  );
};
