import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@/components/TextField";
import { NumberFormatBase } from "react-number-format";

export type AdjustRateModalProps = {
  onDismiss: () => void;
  handleBulkAdjustRate: (value: string) => void;
};

function percentageFormatter(value: string) {
  if (!value) return "";
  let percentage = value.length > 3 ? value.slice(0, 3) : value;

  return `${percentage}`;
}

export const AdjustRateModal = ({ onDismiss, handleBulkAdjustRate }: AdjustRateModalProps) => {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });
  const headline = `Adjust Rate by %`;
  const [errorContent] = useState("");

  async function adjustRate() {
    const value = getValues("percentageAdjustment");
    handleBulkAdjustRate(value);
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={handleSubmit(adjustRate)}>Save</Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline={headline} className="w-[312px]" displayOverflow>
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}

      <p>Adjust selected rates by the following percentage:</p>
      <div className="mt-6">
        <Controller
          name="percentAdjustment"
          control={control}
          rules={{
            required: "Percent Adjustmen is required",
            validate: (value) => {
              if (value < 0 || value > 100) {
                return "Percent Adjustmen must be between 0% and 100%";
              }
              return true;
            }
          }}
          render={({ field }) => (
            <NumberFormatBase
              disabled={false}
              value={field.value}
              format={percentageFormatter}
              customInput={TextField}
              error={!!errors?.percentAdjustment}
              errorText={errors?.percentAdjustment?.message?.toString()}
              onFocus={(e) => {
                e.target.select();
              }}
              onValueChange={(values) => {
                field.onChange(Math.round(values.floatValue || 0));
              }}
              trailingIcon="percent"
              label="Percent Adjustment"
              required
              className="w-full"
            />
          )}
        />
      </div>
    </Dialog>
  );
};
