import type { MaterialNames } from "../material_symbol_names";
import { LitIcon } from "./LitIcon";
interface Props {
  className?: string;
  name: MaterialNames;
  filled?: boolean;
  size?: number;
}
export default function Icon({ className, size = 24, name, filled = false }: Props) {
  const style = {
    fontFamily: "Material Symbols Rounded",
    fontSize: `${size}px`,
    width: `${size}px`,
    height: `${size}px`,
    fontVariationSettings: `'FILL' ${filled ? 1 : 0}`
  };

  return (
    <LitIcon style={style} className={className} data-testid={`icon-${name}`}>
      {name}
    </LitIcon>
  );
}
