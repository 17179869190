import Progress from "@/components/Progress";
import SegmentedButton from "@/components/SegmentedButton";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { useState } from "react";
import { Person, Facility, Event } from "./CreateGlobalInteractionSideSheet";
import { MultiPersonAdderManual } from "./MultiPersonAdderManual";
import { MultiPersonAdderFacility } from "./MultiPersonAdderFacility";
import { MultiPersonAdderEvent } from "./MultiPersonAdderEvent";

type argumentsType = {
  control: any;
  eventPeopleLoading: boolean;
  eventRegistrantsLoading: boolean;
  facilityPeopleLoading: boolean;
  gatheredEvents: Event[];
  gatheredFacilities: Facility[];
  ineligiblePerson: Person | undefined;
  ineligibleFacility: Facility | undefined;
  ineligibleEvent: Event | undefined;
  loading: boolean;
  maxPeopleReached: boolean;
  peopleArray: Person[];
  peopleVariables: { search?: string };
  setIneligiblePerson: (value: Person | undefined) => void;
  setIneligibleEvent: (value: Event | undefined) => void;
  setIneligibleFacility: (value: Facility | undefined) => void;
  setMaxPeopleReached: (value: boolean) => void;
  setPeoplePGAId: (value: string) => void;
  setCurrentEvent: (value: Event | undefined) => void;
  setPeopleVariables: (value: { search?: string }) => void;
  setSearchedForPerson: (value: boolean) => void;
  setVariables: (value: InteractionInput) => void;
  setCurrentFacility: (value: Facility | undefined) => void;
  variables: InteractionInput;
};

export const MAX_PEOPLE = 50;

export const MultiPersonAdder = ({
  control,
  eventPeopleLoading,
  eventRegistrantsLoading,
  facilityPeopleLoading,
  gatheredEvents,
  gatheredFacilities,
  ineligiblePerson,
  ineligibleEvent,
  ineligibleFacility,
  loading,
  maxPeopleReached,
  peopleArray,
  peopleVariables,
  setIneligiblePerson,
  setIneligibleEvent,
  setIneligibleFacility,
  setMaxPeopleReached,
  setPeoplePGAId,
  setCurrentEvent,
  setPeopleVariables,
  setSearchedForPerson,
  setVariables,
  setCurrentFacility,
  variables
}: argumentsType) => {
  const multiPersonAdderOptions = [
    { label: "Manual", value: "manual" },
    { label: "Facility", value: "facility" },
    { label: "Event", value: "event" }
  ];
  const [peopleGroup, setPeopleGroup] = useState(multiPersonAdderOptions[0].value);

  return (
    <div className="space-y-6">
      <div className="w-fit mx-auto">
        <SegmentedButton
          options={multiPersonAdderOptions}
          value={peopleGroup}
          onChange={(selection) => {
            selection && setPeopleGroup(selection);
          }}
          selectedColor="bg-sys-brand-secondary-container"
          textColor="text-sys-brand-on-secondary-container"
          backgroundColor="bg-sys-brand-surface-container-high"
          showCheckmark={true}
        />
      </div>
      {peopleGroup === "manual" && (
        <>
          {loading && (
            <div className="bg-gray-500 bg-opacity-30 w-full h-[110%] absolute z-50 left-0 -top-10">
              <div className="mx-auto my-auto w-fit mt-80">
                <Progress />
              </div>
            </div>
          )}
          {!loading && (
            <MultiPersonAdderManual
              peopleArray={peopleArray}
              control={control}
              setSearchedForPerson={setSearchedForPerson}
              variables={variables}
              setVariables={setVariables}
              peopleVariables={peopleVariables}
              setPeopleVariables={setPeopleVariables}
              setPeoplePGAId={setPeoplePGAId}
              maxPeopleReached={maxPeopleReached}
              setMaxPeopleReached={setMaxPeopleReached}
              setIneligiblePerson={setIneligiblePerson}
              ineligiblePerson={ineligiblePerson}
              gatheredFacilities={gatheredFacilities}
              gatheredEvents={gatheredEvents}
            />
          )}
        </>
      )}
      {peopleGroup === "facility" && (
        <>
          {facilityPeopleLoading && (
            <div className="bg-gray-500 bg-opacity-30 w-full h-[110%] absolute z-50 left-0 -top-10">
              <div className="mx-auto my-auto w-fit mt-80">
                <Progress />
              </div>
            </div>
          )}
          {!facilityPeopleLoading && (
            <MultiPersonAdderFacility
              peopleArray={peopleArray}
              control={control}
              setSearchedForFacility={setSearchedForPerson}
              variables={variables}
              setVariables={setVariables}
              peopleVariables={peopleVariables}
              setPeopleVariables={setPeopleVariables}
              maxPeopleReached={maxPeopleReached}
              setMaxPeopleReached={setMaxPeopleReached}
              setIneligibleFacility={setIneligibleFacility}
              ineligibleFacility={ineligibleFacility}
              setCurrentFacility={setCurrentFacility}
              gatheredFacilities={gatheredFacilities}
            />
          )}
        </>
      )}
      {peopleGroup === "event" && (
        <>
          {eventRegistrantsLoading ||
            (eventPeopleLoading && (
              <div className="bg-gray-500 bg-opacity-30 w-full h-[110%] absolute z-50 left-0 -top-10">
                <div className="mx-auto my-auto w-fit mt-80">
                  <Progress />
                </div>
              </div>
            ))}
          {!(eventRegistrantsLoading || eventPeopleLoading) && (
            <MultiPersonAdderEvent
              peopleArray={peopleArray}
              control={control}
              setSearchedForEvent={setSearchedForPerson}
              variables={variables}
              setVariables={setVariables}
              peopleVariables={peopleVariables}
              setPeopleVariables={setPeopleVariables}
              setCurrentEvent={setCurrentEvent}
              maxPeopleReached={maxPeopleReached}
              setMaxPeopleReached={setMaxPeopleReached}
              setIneligibleEvent={setIneligibleEvent}
              ineligibleEvent={ineligibleEvent}
              gatheredEvents={gatheredEvents}
            />
          )}
        </>
      )}
    </div>
  );
};
