import { useFlags } from "@/hooks/useFeatureFlags";
import AppLayout from "@/layouts/AppLayout";
import { Outlet, useParams } from "react-router-dom";
import { FacilityDetailsHeader } from "../_components/FacilityDetailsHeader";
import { getFacilityNavLinks } from "../_utils";
import TabLayout from "./_TabLayout";
import Banner from "@/components/Banner";
import { isDev } from "@/lib/urlUtils";

export default function FacilityDetailsLayout() {
  const { facilityId } = useParams();
  const flags = useFlags();

  if (!facilityId) {
    return null;
  }

  const navLinks = getFacilityNavLinks(facilityId, flags);

  const ccmsUrl = isDev()
    ? `https://ccms-pgastage.dev.pga.org/prod/cencustmast.master_pg?q_cust_id=${facilityId}`
    : `https://sms.pgalinks.com/prod/cencustmast.master_pg?q_cust_id=${facilityId}`;

  return (
    <AppLayout
      navLinks={navLinks}
      BannerComponent={
        <Banner
          description="While Facility pages are being finalized, please continue to use CCMS for the most up-to-date facility data."
          variant="warn"
          primaryAction={{ label: "View Facility in CCMS", link: ccmsUrl }}
        />
      }
      interior
      bottomBar
    >
      <FacilityDetailsHeader facilityId={facilityId} />
      <TabLayout>
        <Outlet />
      </TabLayout>
    </AppLayout>
  );
}
