import { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { type CellContext } from "@tanstack/react-table";
import { WaitlistEntries, WaitlistEntryNode, WaitlistEntry, Variables } from "./types";
import { WAITLIST_ENTRIES_QUERY } from "./queries";
import { toDateTimeFormat } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { onActionMenuItemClick } from "@/pages/events/_utils";
import usePagination from "@/hooks/usePagination";
import { useFlags } from "@/hooks/useFeatureFlags";
import useActionMenu, { actionId, MenuItem } from "@/hooks/useActionMenu";
import Table, { TableDetails } from "@/components/Table";
import EmptyState from "@/components/EmptyState";
import IconButton from "@/components/IconButton";
import AvatarDetails from "@/components/AvatarDetails";
import OverrideModal from "./_components/OverrideModal";
import MetricGrid from "@/components/MetricGrid";
import { EventOutletContext } from "../_layout";

export default function WaitlistPage() {
  const { eventSlug = "" } = useParams();
  const { Menu, setMenu } = useActionMenu();
  const { setErrorMessage, eventData } = useOutletContext<EventOutletContext>();
  const flags = useFlags();

  const { paginationVariables, renderFooter, resetPagination } = usePagination();
  const [variables, setVariables] = useState<Variables>({ eventSlug });
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);

  useEffect(() => {
    resetPagination();
    setVariables({
      eventSlug
    });
  }, [eventSlug, resetPagination]);

  const eventId = eventData?.id;
  const { hasWaitlist } = eventData || {};
  const showOverrideModal = !!(eventId && selectedPersonId);

  const { data, loading: queryLoading } = useQuery<WaitlistEntries>(WAITLIST_ENTRIES_QUERY, {
    variables: { ...variables, ...paginationVariables }
  });

  const waitlistEntriesData: WaitlistEntry[] = (data?.eventWaitlistEntries?.edges || []).map(
    ({ node: { age, createdAt, ...entry } }: WaitlistEntryNode) => ({
      ...entry,
      age,
      createdAt: createdAt ? toDateTimeFormat(createdAt, "LLL dd, yyyy h:mm a (ZZZZ)") : ""
    })
  );

  const columns = [
    {
      cell: (cellProps: CellContext<WaitlistEntry, unknown>) => {
        const { profilePhoto, entryName, programType = "" } = cellProps.row.original;
        return <AvatarDetails picture={profilePhoto} title={entryName} text={programType} />;
      },
      id: "name",
      header: "Name",
      size: 500,
      meta: {
        className: getStickyColumnClasses(!!waitlistEntriesData?.length)
      }
    },
    { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
    { accessorKey: "gender", header: "Gender", enableSorting: false },
    { accessorKey: "age", header: "Age", enableSorting: false },
    { accessorKey: "email", header: "Email", enableSorting: false },
    { accessorKey: "phone", header: "Phone", enableSorting: false },
    { accessorKey: "createdAt", header: "Signup Timestamp", enableSorting: false },
    {
      cell: (cellProps: CellContext<WaitlistEntry, unknown>) => {
        const items: MenuItem[] = [
          {
            label: "Send Registration Override",
            itemClassName: "w-[240px]",
            onClick: () => {
              setSelectedPersonId(cellProps.row.original.personId);
            }
          }
        ];

        return (
          items.length > 0 && (
            <div
              className="text-right flex flex-row justify-end"
              data-testid="menu-icon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <IconButton
                id={actionId(cellProps.row.id)}
                onClick={() => onActionMenuItemClick({ setMenu, rowId: cellProps.row.id, items })}
                data-testid="actions"
                title="actions"
                name="more_vert"
                iconClassName="font-extrabold"
                skipMargin
              />
            </div>
          )
        );
      },
      id: "actions",
      size: 100
    }
  ];

  return (
    <>
      {showOverrideModal && (
        <OverrideModal
          eventId={eventId}
          personId={selectedPersonId}
          onClosed={() => {
            setSelectedPersonId(null);
          }}
          onError={(message: string) => {
            setErrorMessage(message);
          }}
        />
      )}
      <div className="max-w-[1440px] mx-auto mt-8" data-testid="waitlist-page">
        <Menu />
        <MetricGrid
          metrics={
            hasWaitlist
              ? [
                  { label: "Type", value: "Notification Signup" },
                  { label: "Total On Waitlist", value: waitlistEntriesData.length.toString() }
                ]
              : [{ label: "Type", value: "Disabled Registration" }]
          }
          layout="stacked"
        />
        {hasWaitlist ? (
          <Table
            data={(flags["backoffice-events-waitlist"] && waitlistEntriesData) || []}
            loading={queryLoading}
            columns={columns}
            renderDetails={() => (
              <TableDetails body="View all people who have requested notifications about this event should there be an opening" />
            )}
            renderEmptyState={() => (
              <EmptyState
                title="No Waitlist Entries"
                caption="This event does not have any waitlist entries"
                iconName="person"
              />
            )}
            renderFooter={renderFooter(data?.eventWaitlistEntries, { pageCount: waitlistEntriesData.length })}
          />
        ) : (
          <EmptyState
            title="Waitlist Not Enabled"
            caption="This event disables registration when the attendance limit is reached."
            iconName="person"
            className="max-w-[600px]"
          />
        )}
      </div>
    </>
  );
}
