import { useState } from "react";
import { useQuery } from "@apollo/client";
import useCapabilities, { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { useNavigate } from "@/router";
import usePageTitle from "@/hooks/usePageTitle";
import usePagination from "@/hooks/usePagination";
import { ORDERS_QUERY } from "@/pages/billing/orders/queries";
import { Controller } from "react-hook-form";
import { OrderStage } from "@/pages/billing/types";
import { Variables } from "@/pages/billing/orders/types";
import { OrderRow } from "@/pages/billing/types";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { toFormat } from "@/lib/dateHelpers";
import getActiveFilters from "@/lib/filterHelpers";
import { renderStatus } from "@/pages/billing/_utils";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import TableDetailsPanel from "@/components/TableDetailsPanel";
import MultiSelect from "@/components/MultiSelect";
import EmptyState from "@/components/EmptyState";
import Table, { type Row } from "@/components/Table";
import { useParams } from "react-router-dom";
import CreateOrderModal from "@/pages/billing/orders/_components/CreateOrderModal";
import { useForm } from "react-hook-form";
import { Path } from "@/router";

const mapCommerceOrders = (data: any[]) =>
  (data || []).map(({ updatedAt, dueDate, balance, lineItems, ...order }) => ({
    ...order,
    personId: order.person.id,
    personName: `${order.person.firstName} ${order.person.lastName}`,
    updatedAt: formatDate(updatedAt),
    dueDate: formatDate(dueDate),
    balance: centsToDollarsFormatted(balance) || undefined,
    products: lineItems.length,
    lineItems: lineItems
  }));

const formatDate = (date?: string): string | undefined => (date && toFormat(date)) || undefined;

export default function OrdersPage() {
  usePageTitle("/billing/orders", "Billing");
  useRequireCapability({ domain: DOMAIN.COMMERCE, permission: PERMISSION.viewOrders });
  const { pgaId } = useParams();
  const { renderFooter, paginationVariables, resetPagination } = usePagination();
  const [selectedStatusValues, setSelectedStatusValues] = useState<string[]>([]);
  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState<boolean>(false);
  const { hasPermission } = useCapabilities();
  const defaultVariables = { query: pgaId };

  const [variables, setVariables] = useState<Variables>(defaultVariables);
  const { data, loading } = useQuery(ORDERS_QUERY, { variables: { ...variables, ...paginationVariables } });

  const navigate = useNavigate();

  const pattern = "/billing/orders";
  function onClickRow(row: Row<OrderRow>) {
    navigate(`${pattern}/${row.original.id}?return_to=/people/${pgaId}/billing/orders` as Path);
  }

  const { control } = useForm();
  const orderData: OrderRow[] = mapCommerceOrders(data?.commerceOrders.nodes || []);

  const filters = {
    status: selectedStatusValues.length > 0
  };
  const { isActive } = getActiveFilters(filters, filters, true);

  return (
    <>
      <div className="px-0 sm:px-4 pb-6 justify-between tablet:flex">
        <div className="max-w-[1000px] pr-5 min-h-[48px]">
          <div className="text-title-medium mb-1">Billing History</div>
          <p className="text-body-medium">View all orders, including order details and personal information.</p>
        </div>

        <div className="flex items-center gap-4 mt-4 tablet:mt-0">
          {hasPermission(PERMISSION.manageOrders) && (
            <Button variant="filled" icon="add" onClick={() => setIsCreateOrderModalOpen(true)}>
              Create Order
            </Button>
          )}
        </div>
      </div>
      <div className="w-full">
        <Table
          data={orderData || []}
          loading={loading}
          enableRowSelection={(row) => row.original.payable || row.original.stage === OrderStage.DRAFT}
          onClickRow={onClickRow}
          renderExpandedRow={({ row }) => (
            <TableDetailsPanel
              data={row.original.lineItems || []}
              columns={[
                {
                  accessorKey: "name",
                  header: "Product Name",
                  enableSorting: false,
                  size: 400,
                  cell: (cellProps) => {
                    return <span>{cellProps.row.original.name}</span>;
                  }
                },
                {
                  accessorKey: "priceCents",
                  header: "Price",
                  enableSorting: false,
                  meta: { className: "text-left pl-20", headerClassName: "pl-16" },
                  cell: (cellProps) => {
                    return <span>{centsToDollarsFormatted(cellProps.row.original.priceCents)}</span>;
                  }
                },
                {
                  accessorKey: "quantity",
                  header: "Quantity",
                  enableSorting: false,
                  meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" }
                },
                {
                  accessorKey: "totalPrice",
                  header: "Amount",
                  enableSorting: false,
                  meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" },
                  cell: (cellProps) => {
                    return <span>{centsToDollarsFormatted(cellProps.row.original.totalPrice)}</span>;
                  }
                }
              ]}
            />
          )}
          renderHeader={() => (
            <div>
              <div className="flex md:items-center flex-col md:flex-row justify-between">
                <div className="flex md:items-center flex-col gap-3 md:flex-row">
                  <div className="min-w-[210px]">
                    <Controller
                      name="status"
                      control={control}
                      render={() => (
                        <MultiSelect
                          label="Stage"
                          selectedValues={selectedStatusValues}
                          options={[
                            { value: "canceled", label: "Canceled" },
                            { value: "draft", label: "Draft" },
                            { value: "open", label: "Open" },
                            { value: "paid", label: "Paid" },
                            { value: "pending", label: "Pending" }
                          ]}
                          onSelect={(value, checked) => {
                            const status = checked
                              ? [...selectedStatusValues, value]
                              : selectedStatusValues.filter((v) => v !== value);
                            setSelectedStatusValues(status);
                            setVariables((prevValue) => ({
                              ...prevValue,
                              statuses: status
                            }));
                            resetPagination();
                          }}
                          onClear={() => {
                            setSelectedStatusValues([]);
                            setVariables((prevValue) => ({
                              ...prevValue,
                              statuses: []
                            }));
                          }}
                          className="w-full"
                        />
                      )}
                    />
                  </div>
                  {isActive && (
                    <div className="hidden sm:block" data-testid="clear-all-button">
                      <Button
                        variant="text"
                        onClick={() => {
                          setVariables(defaultVariables);
                          setSelectedStatusValues([]);
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mt-2 md:mt-0">
                  {renderFooter(data?.commerceOrders, {
                    totalCount: data?.commerceOrders.totalCount || 0,
                    pageCount: orderData.length,
                    variant: "compact"
                  })()}
                </div>
              </div>
            </div>
          )}
          columns={[
            { accessorKey: "description", header: "Description", enableSorting: false },
            {
              accessorKey: "products",
              header: "Products",
              enableSorting: false,
              cell: ({ row }) => {
                return (
                  <div
                    className="flex items-center gap-2 py-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    <span>{row.original.lineItems?.length}</span>
                    <Icon name={row.getIsExpanded() ? "expand_less" : "expand_more"} />
                  </div>
                );
              }
            },
            { accessorKey: "updatedAt", header: "Modified", enableSorting: false },
            { accessorKey: "dueDate", header: "Due Date", enableSorting: false },
            { accessorKey: "balance", header: "Balance", enableSorting: false },
            {
              header: "Stage",
              id: "stage",
              cell: ({ row: { original } }) => renderStatus(original.stage, original.paymentStatus),
              size: 100
            }
          ]}
          renderEmptyState={() => (
            <EmptyState title="No Orders" caption="There are no past or current orders." iconName="receipt_long" />
          )}
          renderFooter={() => (
            <div className={`justify-end flex flex-col md:flex-row md:items-center`}>
              {renderFooter(data?.commerceOrders, {
                totalCount: data?.commerceOrders.totalCount || 0,
                pageCount: orderData.length
              })()}
            </div>
          )}
        />
      </div>
      {isCreateOrderModalOpen && <CreateOrderModal onDismiss={() => setIsCreateOrderModalOpen(false)} pgaId={pgaId} />}
    </>
  );
}
