import ObjectHeading from "@/components/ObjectHeading";
import PageHeading, { Props as PageHeadingProps } from "@/layouts/AppLayout/PageHeading";
import { MaterialNames } from "@/components/material_symbol_names.ts";
import { Path } from "@/router";

interface ScreenHeadingProps<P extends Path> extends Pick<PageHeadingProps<P>, "backLink" | "action"> {
  icon?: MaterialNames;
  objectTitle: string;
  pageTitle: string;
  subText: string;
}

export default function ScreenHeading<P extends Path>({
  objectTitle,
  pageTitle,
  icon,
  subText,
  backLink,
  action
}: ScreenHeadingProps<P>) {
  return (
    <div className="pl-4">
      <PageHeading title={pageTitle} backLink={backLink} action={action} />
      <div className="mt-4 flex items-center" data-testid="page-header">
        <ObjectHeading title={objectTitle} subText={subText} iconName={icon} />
      </div>
    </div>
  );
}
