import { useEffect, useMemo } from "react";
import { PropsWithChildren } from "react";
import { LitNavigationDrawerModal } from "./LitNavigationDrawerModal";
import { sysColors } from "@/assets/theme/colors";

interface Props {
  isOpen: boolean;
  onChange(isOpen: boolean): void;
  "data-testid"?: string;
}

const customStyles = {
  "--md-navigation-drawer-modal-scrim-color": sysColors["sys-brand-scrim"],
  "--md-navigation-drawer-modal-scrim-opacity": 0.32
} as React.CSSProperties;

const randomNum = () => (Math.random() * 10000000).toFixed(0);

export default function NavigationDrawerModal({
  isOpen,
  "data-testid": testid,
  onChange,
  children
}: PropsWithChildren<Props>) {
  const anchorId = useMemo(() => `drawer-anchor-${randomNum()}`, []);

  const drawerElement = document.querySelector(`#${anchorId}`)?.querySelector("md-navigation-drawer-modal")?.shadowRoot;
  const drawerModalElement = drawerElement?.querySelector(".md3-navigation-drawer-modal") as HTMLElement;
  const drawerElementScrim = drawerElement?.querySelector(".md3-navigation-drawer-modal__scrim") as HTMLElement;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  useEffect(() => {
    if (drawerModalElement) {
      drawerModalElement.style.position = "fixed";
      drawerModalElement.style.left = "0";
    }
    if (drawerElementScrim) {
      drawerElementScrim.style.position = "fixed";
    }
  }, [drawerModalElement, drawerElementScrim]);
  return (
    <div id={anchorId}>
      <LitNavigationDrawerModal
        opened={isOpen}
        onChange={(e: any) => onChange(e.detail?.opened)}
        onClick={() => onChange(false)}
        className="relative z-drawer"
        style={customStyles}
        data-testid={testid}
      >
        {children}
      </LitNavigationDrawerModal>
    </div>
  );
}
