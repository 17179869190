import { gql } from "@apollo/client";

export const GET_EDUCATION_CERTIFICATION_HISTORY_QUERY = gql`
  query GetEducationCertificationHistory($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      educationLatestCertification {
        startDate
        endDate
        programName
        programDescription
        careerPath
        curriculumVersion
        subscription {
          startDate
          endDate
          productId
          expired
        }
      }
      educationCertificationHistory {
        startDate
        endDate
        programName
        programDescription
        careerPath
        curriculumVersion
        subscription {
          startDate
          endDate
          productId
          expired
        }
      }
    }
  }
`;

export const GET_PGM_PROGRAM_HISTORY_QUERY = gql`
  query GetPgmProgramHistory($pgaId: ID!) {
    person(pgaId: $pgaId) {
      id
      educationLatestPgmProgram {
        curriculumVersion
        startDate
        endDate
        level
        levelCode
        careerPath
        programType
        completed
        pgmProgramCompleted
        pgmProgramExpirationDate
      }
      educationPgmProgramHistory {
        curriculumVersion
        startDate
        endDate
        level
        levelCode
        careerPath
        programType
        completed
      }
    }
  }
`;

export const GET_PDR_HISTORY_QUERY = gql`
  query GetPdrHistory($pgaId: String!) {
    pdrHistory(pgaId: $pgaId) {
      activityCode
      category
      creditType
      creditsAccepted
      creditsAppliedFor
      description
      id
      startedOn
      submittedOn
    }
  }
`;
