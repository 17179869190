import DetailsCard from "@/components/DetailsCard";
import MultiSectionDetailsCard from "@/components/MultiSectionDetailsCard";
import { Insights, Person, ProgramTypeName } from "@/types";
import { MemberInsights } from "./MemberInsights";
import {
  addressOptions,
  emailOptions,
  phoneOptions,
  sectionOptions,
  committeesOptions,
  qualifyingLevelOptions,
  membershipOptions,
  employmentOptions,
  qualifyingLevelData,
  playingAbilityTestData,
  backgroundCheckData,
  educationLearningOptions
} from "./utils";
import MetricGrid from "@/components/MetricGrid";
import { calculateYearsOfMembership, toUTCFormat } from "@/lib/dateHelpers";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import classNames from "classnames";
import Table, { TableDetails } from "@/components/Table";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { formatPgmTableData } from "../../education/_utils";
import EmptyState from "@/components/EmptyState";
import ProgramStatus from "./ProgramStatus";
interface Props {
  person: Person;
  insights?: Insights;
}

function DetailsColumn({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col gap-y-10">{children}</div>;
}

function buildMetrics(person: Person) {
  const { membershipSince, latestProgram, duesBalance } = person;
  const programTypeName = latestProgram?.programType?.name;

  const metrics = [];

  if (programTypeName) {
    metrics.push({
      label: `${programTypeName} Since`,
      value: toUTCFormat(membershipSince || "")
    });
    metrics.push({
      label: `Total Years in ${programTypeName} Program`,
      value: calculateYearsOfMembership(membershipSince || "")
    });
  }

  const qualifyingLevel = qualifyingLevelData(person);
  if (qualifyingLevel) {
    metrics.push({
      label: "Qualifying Level Courses",
      value: qualifyingLevel.status
    });
  }

  const backgroundCheck = backgroundCheckData(person);
  if (backgroundCheck) {
    metrics.push({
      label: "Background Check",
      value: backgroundCheck.status
    });
  }

  const pat = playingAbilityTestData(person);
  if (pat) {
    metrics.push({
      label: "Playing Ability Test",
      value: pat.status
    });
  }

  if ([ProgramTypeName.Member, ProgramTypeName.Associate, ProgramTypeName.Affiliate].includes(programTypeName!)) {
    metrics.push({
      label: "Dues Balance",
      value: centsToDollarsFormatted(duesBalance || 0)
    });
  }

  return metrics;
}

export function OverviewDetails({ person, insights }: Props) {
  const { name: programTypeName } = person.latestProgram?.programType || {};
  const metrics = buildMetrics(person);
  const pgmProgramHistory = (programTypeName !== ProgramTypeName.Member && person.educationPgmProgramHistory) || [];
  const pgmTableData = formatPgmTableData(pgmProgramHistory);

  return (
    <div className="flex flex-col" data-testid="overview-details">
      {insights && <MemberInsights payload={{ insights }} />}
      {!!metrics.length && (
        <div className="mt-4">
          <MetricGrid metrics={metrics} layout="expanded" />
        </div>
      )}
      <div
        className={classNames("flex flex-col md:flex-row gap-x-10", {
          "mt-4": metrics.length,
          "mt-8": !metrics.length
        })}
      >
        <div className="basis-1/3 pb-10">
          <DetailsColumn>
            <MultiSectionDetailsCard
              sections={[
                {
                  heading: "Phone",
                  options: phoneOptions(person.phoneNumbers)
                },
                {
                  heading: "Email",
                  options: emailOptions(person.email)
                }
              ]}
            />
            <DetailsCard heading="Committees" options={committeesOptions(person)} skipRenderWithEmptyOptions />
            <DetailsCard
              heading="Qualifying Level"
              options={qualifyingLevelOptions(person)}
              skipRenderWithEmptyOptions
            />
          </DetailsColumn>
        </div>
        <div className="basis-2/3">
          <DetailsColumn>
            <MultiSectionDetailsCard
              sections={[
                {
                  heading: "Address",
                  options: addressOptions(person.addresses, person.contactAddressId)
                },
                {
                  heading: "Employment",
                  options: employmentOptions(person)
                }
              ]}
            />
            {person.primarySection && (
              <MultiSectionDetailsCard
                sections={[
                  {
                    heading: "Section",
                    options: sectionOptions(person)
                  },
                  {
                    heading: "Membership",
                    options: membershipOptions(person)
                  }
                ]}
              />
            )}
            <DetailsCard heading="PGA Learning" options={educationLearningOptions(person)} skipRenderWithEmptyOptions />
          </DetailsColumn>
        </div>
      </div>

      {pgmTableData.length > 0 && (
        <>
          <hr className="mt-8 w-full h-[1px] border-t border-sys-brand-outline-variant" />
          <Table
            loading={false}
            renderDetails={() => (
              <TableDetails
                heading="PGA Professional Golf Management (PGM) History"
                body={`Program Type: ${pgmProgramHistory[0].programType}`}
              />
            )}
            renderEmptyState={() => (
              <EmptyState
                title="No PGM History"
                caption="This person does not have any Professional Golf Management Program history"
                iconName="school"
              />
            )}
            data={pgmTableData}
            data-testid="program-history-table"
            columns={[
              {
                header: "Level",
                id: "level",
                accessorKey: "level",
                size: 400,
                enableSorting: false,
                meta: {
                  className: getStickyColumnClasses(!!pgmTableData?.length)
                },
                cell: ({ row }) => {
                  return (
                    <>
                      <div className="text-body-medium text-sys-brand-on-surface-variant">{row.original.level}</div>
                      <div className="text-body-small text-sys-brand-on-surface-variant mt-1">
                        {row.original.programDescription}
                      </div>
                    </>
                  );
                }
              },
              {
                header: "Version",
                id: "curriculumVersion",
                accessorKey: "curriculumVersion",
                enableSorting: false
              },
              {
                header: "Stage",
                id: "stage",
                accessorKey: "stage",
                enableSorting: false
              },
              {
                header: "Start Date",
                id: "startDate",
                accessorKey: "startDate",
                enableSorting: false
              },
              {
                header: "End Date",
                id: "endDate",
                accessorKey: "endDate",
                enableSorting: false
              },
              {
                header: "Program Status",
                id: "programStatus",
                accessorKey: "programStatus",
                enableSorting: false,
                cell: ({ row }) => <ProgramStatus completed={row.original.completed} />
              }
            ]}
          />
        </>
      )}
    </div>
  );
}
