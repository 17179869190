export function centsToDollars(amountInCents: number | null): number | null {
  return amountInCents != null ? +(amountInCents / 100).toFixed(2) : null;
}
export function centsToDollarsFormatted(amountInCents: number | null): string | null {
  if (typeof amountInCents !== "number") {
    return null;
  }
  const negative = amountInCents < 0;
  const dollars = centsToDollars(amountInCents);

  return dollars !== null
    ? `${negative ? "-" : ""}$` +
        Math.abs(dollars).toLocaleString("en-US", {
          minimumFractionDigits: 2
        })
    : null;
}

export function currencyFormatter(value: string) {
  if (!value) return "";
  const amount = ((Number(value) || 0) / 100).toFixed(2);

  return `${amount}`;
}

export function currencyFormatterWithPrefix(value: string) {
  if (!value) return "";
  const amount = ((Number(value) || 0) / 100).toFixed(2);

  return `$${amount}`;
}
