import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { GET_EVENT_TYPES } from "@/pages/events/queries";
import Select from "@/components/Select";
import { useFlags } from "@/hooks/useFeatureFlags";
import { EventType } from "@/pages/events/types";
import { TERMS_CONTENT, CUSTOM_FIELDS } from "../utils";

interface EventTypeQueryData {
  eventTypes: EventType[];
}

const EventTypesDropdown = ({ disabled }: { disabled: boolean }) => {
  const {
    control,
    clearErrors,
    trigger,
    setValue,
    formState: { errors }
  } = useFormContext();

  const { data: eventTypeData, loading } = useQuery<EventTypeQueryData>(GET_EVENT_TYPES);
  let eventTypeOptions =
    eventTypeData?.eventTypes.map((eventType: EventType) => ({ value: eventType.id, displayText: eventType.name })) ||
    [];

  const flags = useFlags();

  if (!flags["backoffice-events-pat"]) {
    eventTypeOptions = eventTypeOptions.filter((option) => option.displayText !== "PAT");
  }

  if (loading) {
    return (
      <Select
        data-testid="event-type-select"
        label="Event Type"
        options={[]}
        className="w-full"
        disabled={true}
        required
      />
    );
  }

  return (
    <Controller
      name="eventType"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          data-testid="event-type-select"
          label="Event Type"
          options={eventTypeOptions}
          className="w-full"
          value={value.id}
          disabled={disabled}
          onSelect={(value) => {
            const selectedEventType = eventTypeData?.eventTypes.find((eventType) => eventType.id === value);
            if (selectedEventType?.name === "PAT") {
              setValue("locationType", "onsite");
              setValue("registrationType", "all");
              setValue("customFields", Object.keys(CUSTOM_FIELDS));
              setValue("termsContent", TERMS_CONTENT);
            }
            setValue("products", [
              { productId: "", description: "", variantId: "", variantName: "", variantPrice: 0, collection: "" }
            ]);
            onChange({ id: value, name: selectedEventType?.name });
            if (value && errors.eventType) {
              clearErrors("eventType");
            }
            if (!value) {
              trigger("eventType");
            }
          }}
          required
          error={!!errors.eventType}
          errorText={errors.eventType?.message as string}
        />
      )}
    />
  );
};

export default EventTypesDropdown;
