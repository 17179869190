import { Query, KeysByValue } from "../_hooks/useQuickSearch";
import IconButton from "@/components/IconButton";
import TextField$ from "@/components/TextField";
import MultiSelect$ from "@/components/MultiSelect";
import { Control, useController, RegisterOptions } from "react-hook-form";
import { ComponentProps } from "react";
import { useQuery } from "@apollo/client";
import { QUICKSEARCH_OPTIONS_QUERY } from "../queries";
import { ProgramStatus, ProgramTypeName } from "@/types";
import { getFieldLabel } from "../_utils";
import { capitalize } from "lodash";

function MultiSelect({
  control,
  name,
  ...props
}: Omit<ComponentProps<typeof MultiSelect$>, "selectedValues" | "onSelect" | "onClear" | "label"> & {
  control: Control<Query>;
  name: KeysByValue<Query, string[]>;
}) {
  const {
    field: { value, onChange }
  } = useController({ control, name });

  function onSelect(selection: string, checked: boolean) {
    if (checked) onChange([...value, selection]);
    else onChange(value.filter((v) => v !== selection));
  }

  function onClear() {
    onChange([]);
  }

  return (
    <MultiSelect$ selectedValues={value} onSelect={onSelect} onClear={onClear} label={getFieldLabel(name)} {...props} />
  );
}

function TextField({
  control,
  name,
  rules,
  ...props
}: Omit<ComponentProps<typeof TextField$>, "label"> & {
  control: Control<Query>;
  rules?: RegisterOptions;
  name: KeysByValue<Query, string>;
}) {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ control, name, rules });

  return (
    <TextField$
      label={getFieldLabel(name)}
      {...{ value, onChange, error: !!error, errorText: error?.message }}
      {...props}
    />
  );
}

interface Props {
  reset(): void;
  control: Control<Query>;
}
const enumToOptions = (enumType: Record<string, string>) =>
  Object.keys(enumType).map((value) => ({ value: value.toUpperCase(), label: capitalize(value) }));

const programOptions = enumToOptions(ProgramTypeName);
const statusOptions = enumToOptions(ProgramStatus);

export default function QuickSearch({ reset, control }: Props) {
  const { data: sectionData } = useQuery(QUICKSEARCH_OPTIONS_QUERY, { fetchPolicy: "cache-first" });
  const sectionOptions = sectionData?.crmSections.nodes.map(({ id, name }) => ({ value: id, label: name })) ?? [];
  const classificationOptions =
    sectionData?.classifications.nodes.map(({ name }) => ({ value: name, label: name })) ?? [];

  return (
    <div className="relative">
      <IconButton name="restart_alt" title="Clear all fields" className="absolute -top-4 -right-5" onClick={reset} />

      <fieldset className="mt-8">
        <legend className="text-title-medium">Personal Details</legend>

        <TextField
          className="mt-3 w-full"
          name="pgaId"
          control={control}
          rules={{ pattern: { value: /^\d{8}$/, message: "Enter a valid PGA ID" } }}
        />

        <div className="flex justify-between gap-2">
          <TextField className="mt-6 w-full" name="firstName" control={control} />
          <TextField className="mt-6 w-full" name="informalName" control={control} />
        </div>

        <TextField className="mt-6 w-full" name="lastName" control={control} />
        <TextField className="mt-6 w-full" name="email" control={control} type="email" />
        <TextField className="mt-6 w-full" name="phone" control={control} />
      </fieldset>

      <fieldset className="mt-6">
        <legend className="text-title-medium">Contact Preference Address</legend>

        <TextField className="mt-6 w-full" name="contactPostalCode" control={control} />
      </fieldset>

      <fieldset className="mt-6">
        <legend className="text-title-medium">Membership Details</legend>

        <MultiSelect className="mt-3 w-full" options={sectionOptions} name="sectionIds" control={control} />
        <MultiSelect className="mt-6 w-full" options={programOptions} name="programTypes" control={control} />

        <MultiSelect
          className="mt-6 w-full"
          options={classificationOptions}
          name="memberClassifications"
          control={control}
        />

        <MultiSelect className="mt-6 w-full" options={statusOptions} name="statuses" control={control} />
      </fieldset>
    </div>
  );
}
