import { useEffect, useState } from "react";
import EmptyState from "@/components/EmptyState";
import Table, { TableDetails } from "@/components/Table";
import { toDateFormat } from "@/lib/dateHelpers";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { onActionMenuItemClick, renderJobStatus, renderPostStage } from "./_utils";
import useActionMenu, { actionId, MenuItem } from "@/hooks/useActionMenu";
import IconButton from "@/components/IconButton";
import SearchInput from "@/components/SearchInput";
import DateSelect from "@/components/DateSelect";
import { Controller } from "react-hook-form";
import { useSearch } from "@/hooks/useSearch";
import Button from "@/components/Button";
import { useNavigate } from "@/router";
import MultiSelect from "@/components/MultiSelect";
import { useFlags } from "@/hooks/useFeatureFlags";
import { useParams } from "@/hooks/useSearchParams";
import { ColumnSort } from "@tanstack/react-table";
import useCapabilities, { PERMISSION } from "@/hooks/useCapabilities";
import { JOBS_SEARCH_QUERY } from "./queries";
import { JobsQueryVariables } from "./types";
import { useQuery } from "@apollo/client";
import { DateType } from "react-tailwindcss-datepicker";

interface JobsTableColumnHeaders {
  title: boolean;
  facility: boolean;
  serviceLevel: boolean;
  employer: boolean;
  owner: boolean;
  postedOn: boolean;
  submissionDeadline: boolean;
  applicantCount: boolean;
  postingStage: boolean;
  jobStatus: boolean;
}

interface JobsTableFilterProps {
  postingStage: boolean;
  jobStatus: boolean;
  serviceLevel: boolean;
  postedDateRange: boolean;
}

interface JobsTableLayoutProps {
  tableHeading: string;
  tableDescription: string;
  columnHeaders: JobsTableColumnHeaders;
  filters: JobsTableFilterProps;
  defaultValues?: DefaultFilters;
  defaultSort?: { id: string; desc: boolean }[];
}

interface DefaultFilters {
  searchTerm?: string;
  stage?: string[];
  status?: string[];
  sectionId?: string;
  facilityId?: string;
  ownerId?: string;
  lowerDateRange?: DateType;
  upperDateRange?: DateType;
}

interface JobPostRow {
  id: string;
  title: string;
  facility: string;
  serviceLevel: string;
  employer: string;
  owner: string;
  createdAt: string;
  submissionDeadline: string;
  applicantCount: number;
  stage: string;
  status: string;
}

export default function JobsTableLayout({
  tableHeading,
  tableDescription,
  columnHeaders,
  filters,
  defaultValues,
  defaultSort
}: JobsTableLayoutProps) {
  const flags = useFlags();
  let { params, addSearchParam, deleteSearchParams } = useParams();

  if (params.dateRange) {
    params.dateRange = params.dateRange && {
      lowerDateRange: params.dateRange.split(",")[0],
      upperDateRange: params.dateRange.split(",")[1]
    };
  }

  const { dateRange, ...otherParams } = params;
  const defaultStages = ["Draft", "Active", "Pending"];

  const buildDefaultParams = () => {
    return {
      ...(defaultValues?.sectionId ? { sectionId: defaultValues?.sectionId } : {}),
      ...(defaultValues?.facilityId ? { facilityId: defaultValues?.facilityId } : {}),
      ...(!otherParams?.stage ? { stage: defaultStages } : {}),
      ...otherParams,
      ...(dateRange ? dateRange : {})
    };
  };

  const { hasPermission } = useCapabilities();
  const searchField = "searchTerm";
  const { searchValue, control, reset } = useSearch(searchField, params.searchTerm || "");
  const [variables, setVariables] = useState<JobsQueryVariables>(buildDefaultParams());

  const [selectedStageValues, setSelectedStageValues] = useState<string[]>(otherParams?.stage || defaultStages);
  const [selectedJobStatusValues, setSelectedJobStatusValues] = useState<string[]>([]);
  const [selectedServiceLevelValues, setSelectedServiceLevelValues] = useState<string[]>([]);

  const isStageDefault = (stages?: string[]) => {
    if (!stages) return true;
    if (stages.length !== defaultStages.length) return false;
    return stages.every((stage) => defaultStages.includes(stage));
  };

  const { data, loading, refetch } = useQuery(JOBS_SEARCH_QUERY, {
    variables: { input: { ...variables } },
    skip: Object.keys(variables).length === 0
  });

  const mapJobs = (data: any[]) =>
    (data || []).map(
      ({
        name,
        facility,
        serviceLevel,
        owner,
        pointOfContact,
        createdAt,
        submissionDeadline,
        numberOfApplicants,
        stage,
        status,
        ...jobPost
      }) => {
        return {
          ...jobPost,
          title: name,
          facility: facility?.name,
          serviceLevel: serviceLevel,
          employer: pointOfContact?.name,
          owner: owner?.name,
          createdAt: createdAt,
          submissionDeadline: submissionDeadline,
          applicantCount: numberOfApplicants,
          stage: stage,
          status: status
        };
      }
    );

  const jobPostData: JobPostRow[] = mapJobs(data?.jobPosts?.nodes || []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setVariables({
        ...variables,
        searchTerm: searchValue || undefined
      });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, setVariables]);

  const { postingStage, jobStatus, serviceLevel, postedDateRange } = filters;
  const {
    title,
    facility,
    serviceLevel: serviceLevelHeader,
    employer,
    owner,
    postedOn,
    submissionDeadline,
    applicantCount,
    postingStage: postingStageHeader,
    jobStatus: jobStatusHeader
  } = columnHeaders;

  const { Menu, setMenu } = useActionMenu();
  const navigate = useNavigate();

  // All on-click funtions for rows and action menu.
  function onClickRow(row: any) {
    console.log("Row Clicked!", row);
  }

  function onClickEdit(row: any) {
    console.log("Edit Job!", row);
  }

  function onClickDuplicate(row: any) {
    console.log("Duplicate Job!", row);
  }

  function onClickDelete(row: any) {
    console.log("Delete Job!", row);
  }

  function onClickManageJob(row: any) {
    console.log("Manage Job!", row);
  }

  // function onClickViewPublishedPage(row: any) {
  //   console.log("View Published Page!", row);
  // }

  // function onClickUnpublishJob(row: any) {
  //   console.log("Unpublish Job!", row);
  // }

  // function onClickCloseJob(row: any) {
  //   console.log("Close Job!", row);
  // }

  function onClickViewJobDetails(row: any) {
    console.log("View Job Details!", row);
  }

  const [orderBy, setOrderBy] = useState<ColumnSort[]>(defaultSort || []);

  const handleCreateEventClick = () => {
    navigate("/events/new");
  };

  const createEventButton = () => (
    <Button icon="add" onClick={handleCreateEventClick} testId="create-event-button">
      Create Job
    </Button>
  );

  let buttonComps: (() => JSX.Element)[] = [];

  if (!!flags["backoffice-create-job-post"] && hasPermission(PERMISSION.manageJobPosts)) {
    buttonComps.push(createEventButton);
  }

  const handleSort = (updaterOrValue: any) => {
    const sortState = typeof updaterOrValue === "function" ? updaterOrValue([]) : updaterOrValue;
    const { id, desc } = sortState[0] || {};
    setOrderBy([{ id, desc }]);
  };

  const { sectionId, facilityId, ...selectedFilters } = variables;
  const filterActive = Object.keys(selectedFilters).some((key) => {
    if (key === "stage") {
      return !isStageDefault((selectedFilters as JobsQueryVariables).stage);
    }
    return !!(selectedFilters as JobsQueryVariables)[key as keyof JobsQueryVariables];
  });

  const removeFilter = (obj: any, keyToRemove: any) => {
    const { [keyToRemove]: _, ...rest } = obj;
    return Object.assign({}, rest);
  };

  const clearAllFilters = () => {
    // Clear URL search params
    deleteSearchParams(["dateRange", "stage[]", "status[]", "serviceLevel[]", "searchTerm"]);

    // Reset all filter states
    setSelectedStageValues(defaultStages);
    setSelectedServiceLevelValues([]);
    setSelectedJobStatusValues([]);

    // Reset form values
    reset({
      dates: { startDate: null, endDate: null } as any,
      searchTerm: ""
    });

    // Reset variables to initial state
    const defaultParams = {
      ...(defaultValues?.sectionId ? { sectionId: defaultValues.sectionId } : {}),
      ...(defaultValues?.facilityId ? { facilityId: defaultValues.facilityId } : {}),
      stage: defaultStages
    };

    setVariables(defaultParams);
    refetch();
  };

  return (
    <>
      <Menu />
      <Table
        data={jobPostData}
        loading={loading}
        onClickRow={onClickRow}
        onSortingChange={handleSort}
        sortingState={orderBy}
        serverSideSorting={false}
        renderDetails={() => <TableDetails heading={tableHeading} body={tableDescription} ButtonComps={buttonComps} />}
        renderHeader={() => (
          <div className="flex flex-row justify-between items-center pr-4 md:pr-0">
            <div className="flex flex-col sm:flex-row justify-between gap-4 md:w-auto">
              <div className="w-full sm:max-w-[260px]">
                <Controller
                  name="searchTerm"
                  control={control}
                  render={({ field }) => (
                    <SearchInput
                      placeholder="Search Jobs"
                      query={field.value}
                      param={["Job Title", "Facility", "Employer"]}
                      onClear={() => {
                        deleteSearchParams("searchTerm");
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        addSearchParam("searchTerm", e);
                      }}
                    />
                  )}
                />
              </div>
              {postingStage && (
                <div>
                  <Controller
                    name="stage"
                    control={control}
                    render={() => (
                      <MultiSelect
                        label="Posting Stage"
                        selectedValues={selectedStageValues}
                        options={[
                          { value: "Draft", label: "Draft" },
                          { value: "Pending", label: "Pending" },
                          { value: "Review", label: "Review" },
                          { value: "Active", label: "Active" },
                          { value: "Inactive", label: "Inactive" },
                          { value: "Removed", label: "Removed" }
                        ]}
                        onSelect={(value, checked) => {
                          const stage = checked
                            ? [...selectedStageValues, value]
                            : selectedStageValues.filter((v) => v !== value);
                          const values = stage.length > 0 ? { ...variables, stage } : removeFilter(variables, "stage");
                          setVariables({ ...values });
                          setSelectedStageValues(stage);
                          addSearchParam("stage[]", stage.join(","));
                        }}
                        onClear={() => {
                          const { stage, ...rest } = variables;
                          setVariables({
                            ...rest
                          });
                          setSelectedStageValues([]);
                          deleteSearchParams("stage[]");
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {serviceLevel && (
                <div>
                  <Controller
                    name="serviceLevel"
                    control={control}
                    render={() => (
                      <MultiSelect
                        label="Service Level"
                        selectedValues={selectedServiceLevelValues}
                        options={[
                          { value: "Self Service", label: "Self Service" },
                          { value: "Free Service", label: "Free Service" },
                          { value: "Limited Service", label: "Limited Service" },
                          { value: "Full Service", label: "Full Service" }
                        ]}
                        onSelect={(value, checked) => {
                          const serviceLevel = checked
                            ? [...selectedServiceLevelValues, value]
                            : selectedServiceLevelValues.filter((v) => v !== value);
                          const values =
                            serviceLevel.length > 0
                              ? { ...variables, serviceLevel }
                              : removeFilter(variables, "serviceLevel");
                          setVariables({ ...values });
                          setSelectedServiceLevelValues(serviceLevel);
                          addSearchParam("serviceLevel[]", serviceLevel.join(","));
                        }}
                        onClear={() => {
                          const { serviceLevel, ...rest } = variables;
                          setVariables({
                            ...rest
                          });
                          setSelectedJobStatusValues([]);
                          deleteSearchParams("serviceLevel[]");
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {jobStatus && (
                <div>
                  <Controller
                    name="status"
                    control={control}
                    render={() => (
                      <MultiSelect
                        label="Job Status"
                        className="w-full tablet:w-auto tablet:max-w-[13rem]"
                        selectedValues={selectedJobStatusValues}
                        options={[
                          { value: "Filled", label: "Filled" },
                          { value: "Incomplete", label: "Incomplete" },
                          { value: "In Progress", label: "In Progress" },
                          { value: "Expired", label: "Expired" }
                        ]}
                        onSelect={(value, checked) => {
                          const status = checked
                            ? [...selectedJobStatusValues, value]
                            : selectedJobStatusValues.filter((v) => v !== value);
                          const values =
                            status.length > 0 ? { ...variables, status } : removeFilter(variables, "status");
                          setVariables({ ...values });
                          setSelectedJobStatusValues(status);
                          addSearchParam("status[]", status.join(","));
                        }}
                        onClear={() => {
                          const { status, ...rest } = variables;
                          setVariables({
                            ...rest
                          });
                          setSelectedJobStatusValues([]);
                          deleteSearchParams("status[]");
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {postedDateRange && (
                <div>
                  <Controller
                    name="dates"
                    control={control}
                    render={({ field }) => (
                      <DateSelect
                        label="Posted Date Range"
                        placeholder="Show All"
                        value={
                          params.dateRange
                            ? {
                                startDate: new Date(params.dateRange.lowerDateRange),
                                endDate: new Date(params.dateRange.upperDateRange)
                              }
                            : field.value
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          if (typeof e === "object" && e !== null) {
                            setVariables({
                              ...variables,
                              lowerDateRange: e.startDate,
                              upperDateRange: e.endDate
                            });
                            addSearchParam("dateRange", [e.startDate, e.endDate].join(","));
                          }
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {filterActive && (
                <div className="ml-1 flex items-center" data-testid="clear-all-button">
                  <Button variant="text" onClick={clearAllFilters}>
                    Clear All
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        columns={[
          ...(title
            ? [
                {
                  accessorKey: "title",
                  header: "Job Title",
                  enableSorting: true,
                  meta: {
                    className: getStickyColumnClasses(true)
                  }
                }
              ]
            : []),
          ...(facility ? [{ accessorKey: "facility", header: "Facility", enableSorting: true }] : []),
          ...(serviceLevelHeader
            ? [{ accessorKey: "serviceLevel", header: "Service Level", enableSorting: true }]
            : []),
          ...(employer ? [{ accessorKey: "employer", header: "Employer", enableSorting: true }] : []),
          ...(owner ? [{ accessorKey: "owner", header: "Owner", enableSorting: true }] : []),
          ...(postedOn
            ? [
                {
                  accessorKey: "createdAt",
                  header: "Posted On",
                  enableSorting: true,
                  cell({ row: { original } }: { row: { original: any } }) {
                    return <div>{toDateFormat(original.createdAt)}</div>;
                  }
                }
              ]
            : []),
          ...(submissionDeadline
            ? [
                {
                  accessorKey: "submissionDeadline",
                  header: "Deadline",
                  enableSorting: true,
                  cell({ row: { original } }: { row: { original: any } }) {
                    return <div>{toDateFormat(original.submissionDeadline)}</div>;
                  }
                }
              ]
            : []),
          ...(applicantCount ? [{ accessorKey: "applicantCount", header: "Applicants", enableSorting: true }] : []),
          ...(postingStageHeader
            ? [
                {
                  header: "Posting Stage",
                  cell({ row: { original } }: { row: { original: any } }) {
                    return <div>{renderPostStage(original.stage)}</div>;
                  }
                }
              ]
            : []),
          ...(jobStatusHeader
            ? [
                {
                  header: "Job Status",
                  cell: ({ row: { original } }: { row: { original: any } }) => {
                    return <div>{renderJobStatus(original.status)}</div>;
                  }
                }
              ]
            : []),
          {
            cell(cellProps) {
              const editAction = {
                label: "Edit Job",
                onClick: () => {
                  onClickEdit(cellProps.row);
                }
              };

              const duplicateAction = {
                label: "Duplicate Job",
                onClick: () => {
                  onClickDuplicate(cellProps.row);
                }
              };

              const deleteAction = {
                label: "Delete Job",
                onClick: () => {
                  onClickDelete(cellProps.row);
                }
              };

              const manageAction = {
                label: "Manage Job",
                onClick: () => {
                  onClickManageJob(cellProps.row);
                }
              };

              // const viewPublishedPageAction = {
              //   label: "View Published Page",
              //   iconName: "open_in_new" as const,
              //   trailingIcon: true,
              //   onClick: () => {
              //     onClickViewPublishedPage(cellProps.row);
              //   }
              // };

              // const unpublishAction = {
              //   label: "Unpublish Job",
              //   onClick: () => {
              //     onClickUnpublishJob(cellProps.row);
              //   }
              // };

              // const closeAction = {
              //   label: "Close Job",
              //   onClick: () => {
              //     onClickCloseJob(cellProps.row);
              //   }
              // };

              const viewJobDetailsAction = {
                label: "View Job Details",
                onClick: () => {
                  onClickViewJobDetails(cellProps.row);
                }
              };

              const items: MenuItem[] = [];

              switch (cellProps.row.original.stage) {
                case "Draft":
                  items.push(editAction, duplicateAction, "divider", deleteAction);
                  break;
                case "Pending":
                case "Active":
                  items.push(
                    manageAction
                    // Hidding this options until we have the functionality
                    // viewPublishedPageAction,
                    // unpublishAction,
                    // duplicateAction,
                    // "divider",
                    // closeAction,
                    // deleteAction
                  );
                  break;
                case "Closed":
                  items.push(viewJobDetailsAction, duplicateAction);
                  break;
              }

              return (
                items.length > 0 && (
                  <div
                    className="text-right flex flex-row justify-end"
                    data-testid="menu-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <IconButton
                      id={actionId(cellProps.row.id)}
                      onClick={() => onActionMenuItemClick({ setMenu, rowId: cellProps.row.id, items })}
                      data-testid="actions"
                      title="actions"
                      name="more_vert"
                      skipMargin
                    />
                  </div>
                )
              );
            },
            id: "actions",
            size: 100
          }
        ]}
        renderEmptyState={() => (
          <EmptyState title="No Results Found" caption="Try a different search or filter" iconName="business_center" />
        )}
      />
    </>
  );
}
