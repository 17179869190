import IconButton from "../IconButton";
import DrawerModal from "../SideSheetModal/DrawerModal";
import { isMobile } from "@/assets/theme/sizes";
import { useWindowSize } from "@/hooks/useWindowSize";

export interface ObjectDetailsPanelProps {
  closeDetailsPanel: () => void;
  children: React.ReactNode;
}

export default function ObjectDetailsPanel({
  isDetailsPanelOpen,
  setIsDetailsPanelOpen,
  closeDetailsPanel,
  children
}: {
  isDetailsPanelOpen: boolean;
  setIsDetailsPanelOpen: (value: boolean) => void;
  closeDetailsPanel: () => void;
  children: React.ReactNode;
}) {
  const isMobileDisplay = isMobile(useWindowSize().width);
  return isMobileDisplay ? (
    <DrawerModal zIndex={1000} isOpen={true} onChange={setIsDetailsPanelOpen}>
      {isDetailsPanelOpen ? (
        <>
          <div className="flex items-center self-stretch pl-6 pr-3 pt-4 pb-3">
            <div className="text-title-large text-sys-brand-on-surface-variant">Details</div>
            <IconButton name="close" ariaLabel="close" onClick={closeDetailsPanel} className={`ml-auto mr-1 !my-1`} />
          </div>
          <div className="pt-2 pb-12 overflow-y-auto">{children}</div>
        </>
      ) : null}
    </DrawerModal>
  ) : (
    <div className="min-w-[444px] rounded-2xl shadow-web-1 bg-sys-brand-surface mt-8 ml-4">
      <div className="flex items-center self-stretch pl-6 pr-3 pt-4 pb-3">
        <div className="text-title-large text-sys-brand-on-surface-variant">Details</div>
        <IconButton name="close" ariaLabel="close" onClick={closeDetailsPanel} className={`ml-auto mr-1 !my-1`} />
      </div>
      <div className="pt-2 pb-12">{children}</div>
    </div>
  );
}
