import { Interaction, InteractionInput } from "../types";
import { convertMode } from "../../_utils";

export function getInteractionTrackingData(
  variables: InteractionInput,
  interaction: Interaction,
  participants: any[],
  formattedSummary: string,
  interactionId: string,
  pgaId: string
) {
  const audience = participants.length > 1 ? "group" : "single";
  const currentMode = convertMode.toAPI(interaction?.mode || null);
  const variablesMode = variables.mode ? convertMode.toAPI(convertMode.fromAPI(variables.mode)) : currentMode;
  const messageContent = interaction?.lastMessage?.content;
  const currentActivityType = interaction?.categories?.[0] || null;
  const variablesActivityType = variables.activityType || currentActivityType;

  // Format dates to YYYY-MM-DD
  const formatDate = (date: string | Date | null) => {
    if (!date) return null;
    return new Date(date).toISOString().split("T")[0];
  };

  const currentDate = formatDate(interaction?.interactionDate);
  const newDate = formatDate(variables.interactionDate as string);

  return {
    // Original values are set to null if they match updated values
    // the filters equate to "if not null"
    originalMode: variablesMode === currentMode ? null : currentMode,
    originalDescription: !variables.description || messageContent === variables.description ? null : messageContent,
    originalActivityType: variablesActivityType === currentActivityType ? null : currentActivityType,
    originalSummary: interaction?.summary,
    originalPrivacyRestricted:
      interaction?.lastMessage?.isPrivate === variables.privacy_restricted ? null : interaction?.lastMessage?.isPrivate,
    originalInteractionDate: currentDate === newDate ? null : currentDate,
    audience,
    mode: variablesMode,
    description: variables.description,
    interactionDate: variables.interactionDate,
    activityType: variables.activityType || currentActivityType,
    summary: formattedSummary,
    privacyRestricted: variables.privacy_restricted || interaction?.lastMessage?.isPrivate,
    interactionId,
    pgaId
  };
}
