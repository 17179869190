export interface DuesRatesRow {
  id: string;
  productName: string;
  classification: string;
  classificationDescription: string;
  rate_2025: string | null;
  rate_2024: string | null;
  rate_2023: string | null;
  updatedAt: string | null;
  status?: StatusType;
  statusMessage?: string;
}

export enum StatusType {
  WARNING = "warning",
  ERROR = "error"
}

export type Variables = {
  kind: string;
  classification?: string[];
  search?: string;
};
