import ObjectHeading from "@/components/ObjectHeading";
import PageTitle from "@/components/PageTitle";
import { useQuery } from "@apollo/client";
import { PageHeading } from "@/layouts/AppLayout";
import { SectionData } from "../audit-log/types";
import { SECTION_DETAILS_HEADER_QUERY } from "../queries";
import { ProfileSkeleton } from "@/components/Skeleton";

type ObjectHeadingWrapper = {
  sectionId: string;
  ButtonComp?: () => JSX.Element;
  isDetailsViewDesktop?: boolean;
};

export const ObjectHeadingWrapper = ({ sectionId, ButtonComp, isDetailsViewDesktop }: ObjectHeadingWrapper) => {
  const { data, loading, error } = useQuery<SectionData>(SECTION_DETAILS_HEADER_QUERY, {
    variables: { sectionId }
  });

  if (loading) return <ProfileSkeleton />;
  if (error) return <div>Error: {error.message}</div>;

  const { section } = data!;

  return (
    <>
      <PageTitle
        pattern={`/sections/:sectionId/change-requests`}
        title={`Sections | Section Details | Change Requests | ${data?.section.name}`}
      />
      <ObjectHeading
        title={section.name}
        ButtonComp={ButtonComp}
        isDetailsView
        subText={`PGA ID: ${section.legacyId}`}
        isDetailsViewDesktop={isDetailsViewDesktop}
      />
    </>
  );
};

type Props = {
  sectionId: string;
  title?: string;
  ButtonComp?: () => JSX.Element;
};

export function SectionDetailsHeader({ sectionId, title, ButtonComp }: Props) {
  return (
    <div data-testid="person-details-header">
      <div className="sm:pb-6">
        <PageHeading
          title={title || "Details"}
          backLink={{ to: "/sections" }}
          action={{
            iconName: "quick_reference_all",
            title: "Audit Log",
            link: { to: "/sections/:sectionId/audit-log", params: { sectionId } }
          }}
        />
      </div>
      <ObjectHeadingWrapper sectionId={sectionId} ButtonComp={ButtonComp} />
    </div>
  );
}
