import { useOutletContext, useParams } from "react-router-dom";
import useActionMenu from "@/hooks/useActionMenu";
import AttendanceTable from "./_components/AttendanceTable";
import { EventOutletContext } from "../_layout";

export default function AttendancePage() {
  const { eventSlug = "" } = useParams();
  const { eventData } = useOutletContext<EventOutletContext>();
  const multiSession = eventData?.sessionCount && eventData.sessionCount > 1;

  const { Menu } = useActionMenu();

  return (
    <>
      <div className="max-w-[1440px] mx-auto my-8" data-testid="registrants-page">
        <Menu />
        <AttendanceTable eventSlug={eventSlug} multiSession={multiSession || false} />
      </div>
    </>
  );
}
