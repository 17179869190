import ValueClarityIcon from "@/assets/images/valueclarity-icon.svg";
import Tag from "@/components/Tag";
import { ExternalLink } from "@/components/withLink";

export default function InsightsCard({ message }: { message: string }) {
  return (
    <div
      className="bg-sys-brand-primary-container-bright rounded-xl flex items-center px-6 py-4"
      data-testid="member-insights"
    >
      <div>
        <div className="flex items-center mb-3">
          <img src={ValueClarityIcon} />
          <h3 className="sys-brand-on-surface ml-3 mr-2">Insights</h3>
          <Tag color="tertiary">BETA</Tag>
        </div>
        <div className="text-sys-brand-on-primary-container">
          {message}
          <ExternalLink
            href={`https://docs.google.com/forms/d/e/1FAIpQLSe21Dg7c0RZyg_AO1RdcaEYRfPMfZrPyVZZ9kirqLCayGpsoA/viewform?usp=pp_url&entry.2050300929=${window.location.href}&entry.470452171=${message}`}
          >
            <span className="text-sys-brand-primary ml-2">Is this helpful?</span>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}
