import React from "react";
import PageTitle from "@/components/PageTitle";
import { Outlet, useParams, useLocation } from "react-router-dom";
import type { Path, Params } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import SinglePageLayout from "@/layouts/SinglePageLayout";
import Banner from "@/components/Banner";
import { useState, useMemo, useEffect } from "react";
import ObjectHeading from "@/components/ObjectHeading";
import EventTabs from "../../_EventTabs";
import classNames from "classnames";
import { useQuery } from "@apollo/client";
import { EVENT_QUERY } from "../queries";
import { Event, EventWrapper } from "../types";
import { ReactNode } from "react";
import Button from "@/components/Button";
import { buildMyPgaUrl, openBrowser } from "@/lib/urlUtils";
import { useReturnTo } from "@/hooks/useSearchParams";
import { capitalize } from "lodash";
import { SlotIconName } from "@/components/Icon";
import CancelEventModal, { CancelEvent } from "./cancellations/_components/CancelEventModal";
import { ButtonVariant } from "@/components/Button/Button";

export interface EventOutletContext {
  setActions: (actions: ReactNode) => void;
  setErrorMessage: (message: string | null) => void;
  eventData: EventWrapper["event"] | undefined;
  eventLoading: boolean;
}

export default function EventPage<P extends Path>() {
  const { eventSlug = "" } = useParams();
  const returnTo = useReturnTo();
  const location = useLocation();

  const [backLink] = useState({ to: returnTo || "/events" } as LinkProps<P, Params>);
  const [actions, setActions] = useState<ReactNode>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [areButtonsVisible, setAreButtonsVisible] = useState(true);

  function onClickCancel(eventData: Event | undefined) {
    if (!eventData) {
      return;
    }
    setAreButtonsVisible(false);
    setIsCancelModalOpen(true);
  }

  const {
    data: eventDataResponse,
    loading,
    refetch
  } = useQuery<EventWrapper>(EVENT_QUERY, {
    variables: { slug: eventSlug }
  });

  const eventData = eventDataResponse?.event;

  const eventButtons = useMemo(() => {
    if (loading || !eventData) {
      return [];
    }
    return [
      {
        key: "cancel-event",
        label: "Cancel Event",
        icon: "delete",
        variant: "text" as ButtonVariant,
        onClick: () => onClickCancel(eventData),
        show: eventData?.status === "Active"
      },
      {
        key: "published-page",
        label: "View Published Page",
        icon: "open_in_new",
        variant: "outlined" as ButtonVariant,
        onClick: () => openBrowser(buildMyPgaUrl("member/events", eventSlug)),
        show: areButtonsVisible
      },
      {
        key: "pat-hub",
        label: "View PAT Hub",
        icon: "open_in_new",
        variant: "outlined" as ButtonVariant,
        onClick: () => openBrowser("https://sites.google.com/pgahq.com/pat/home"),
        show: areButtonsVisible && eventData?.eventType?.name === "PAT"
      }
    ];
  }, [loading, eventData, eventSlug, areButtonsVisible]);

  useEffect(() => {
    setActions(
      <>
        {eventButtons
          .filter((button) => button.show)
          .map((button) => (
            <Button
              key={button.key}
              variant={button.variant}
              icon={button.icon as SlotIconName}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
      </>
    );
  }, [eventButtons]);

  const contextValue = {
    setActions,
    setErrorMessage,
    eventData,
    eventLoading: loading
  };

  return (
    <SinglePageLayout
      backLink={backLink}
      actions={React.isValidElement(actions) ? actions.props.children : []}
      renderBanner={() => errorMessage && <Banner closable={true} description={errorMessage} variant="error" />}
    >
      <PageTitle
        pattern={location.pathname}
        title={`Events | Event Details | ${capitalize(location.pathname.split("/").pop())} | ${eventData?.title}`}
      />
      <div className="max-w-[1440px] mx-auto my-8" data-testid="registrants-page">
        {eventData && (
          <ObjectHeading title={eventData.title} subText={`Status: ${eventData.status}`} iconName="today" />
        )}
        <div className={classNames("my-8")}>
          <EventTabs eventData={eventData} />
        </div>
        <Outlet context={contextValue} />
      </div>
      {isCancelModalOpen && (
        <CancelEventModal
          event={eventData as CancelEvent}
          onClosed={() => {
            setIsCancelModalOpen(false);
            refetch();
            setAreButtonsVisible(true);
          }}
        />
      )}
    </SinglePageLayout>
  );
}
