import Tabs from "@/components/Tabs";
import AppLayout from "@/layouts/AppLayout";
import PageHeading from "@/layouts/AppLayout/PageHeading";
import { useNavigate } from "@/router";
import { useLocation } from "react-router-dom";
import { getDuesManagementNavLinks } from "../_utils";
import { useFlags } from "@/hooks/useFeatureFlags";

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const flags = useFlags();
  const navLinks = getDuesManagementNavLinks(flags);

  return (
    <AppLayout interior bottomBar navLinks={navLinks}>
      <PageHeading title="Suspensions and Terminations" backLink={{ to: "/billing/dues-management" }} />
      <Tabs
        className="my-8"
        tabItems={[
          {
            label: "Eligible for Suspension",
            to: "/billing/dues-management/suspensions-and-terminations/suspensions" as const
          },
          {
            label: "Eligible for Termination",
            to: "/billing/dues-management/suspensions-and-terminations/terminations" as const
          }
        ].map(({ label, to }) => ({
          label,
          onClick: () => navigate(to),
          active: (pathname.endsWith("/") ? pathname.slice(0, -1) : pathname) === to
        }))}
      />
      <div className="flex flex-col">{children}</div>
    </AppLayout>
  );
}
