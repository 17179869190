export interface Props {
  className?: string;
  variant: Variant;
  title: string;
  subhead?: string;
  image?: string;
}

const VARIANTS = {
  outlined:
    "bg-sys-brand-surface border hover:shadow-native-elevation-light-1 hover:bg-state-layers-brand-on-surface-opacity-0-08",
  "elevated-subtle":
    "bg-sys-brand-surface-container-lowest shadow-web-1 hover:shadow-web-2 hover:bg-sys-brand-surface-container-low"
};

type Variant = keyof typeof VARIANTS;

export default function StackedCard({ title, subhead, image, variant, className }: Props) {
  const variantClass = VARIANTS[variant];

  return (
    <div className={`flex flex-col rounded-xl overflow-hidden hover:cursor-pointer ${variantClass} ${className}`}>
      {!!image && <img src={image} className="h-30 w-full object-cover bg-sys-brand-surface-dim" />}
      <div className="flex flex-col p-4 gap-4">
        <div className="text-body-large">{title}</div>
        {!!subhead && <div className="text-body-small">{subhead}</div>}
      </div>
    </div>
  );
}
