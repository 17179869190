import Dialog from "@/components/Dialog";
import TextField from "@/components/TextField";
import Button from "@/components/Button";
import { Controller, useForm } from "react-hook-form";

interface EditDetailsModalProps {
  open: boolean;
  onClose: () => void;
  initialConditions: string;
  initialProblems: string;
  onSave: (conditions: string, problems: string) => void;
}

export default function EditDetailsModal({
  open,
  onClose,
  initialConditions,
  initialProblems,
  onSave
}: EditDetailsModalProps) {
  const {
    control,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    defaultValues: {
      conditions: initialConditions,
      problems: initialProblems
    }
  });
  const handleSave = async () => {
    const result = await trigger();
    const formValues = getValues();

    if (result) {
      onSave(formValues.conditions, formValues.problems);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClosed={onClose}
      headline="Edit Exam Details"
      className="max-w-md min-w-[423px]"
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </>
      }
    >
      <div className="flex flex-col gap-6">
        <Controller
          control={control}
          name="conditions"
          rules={{ required: "Course Conditions is required field" }}
          render={({ field }) => (
            <TextField
              label="Course Conditions"
              required
              type="textarea"
              rows={6}
              value={field.value}
              onChange={(e) => field.onChange((e.target as HTMLInputElement).value)}
              error={!!errors.conditions}
              errorText={errors.conditions?.message as string}
            />
          )}
        />
        <Controller
          control={control}
          name="problems"
          render={({ field }) => (
            <TextField
              label="Problems or Issues"
              type="textarea"
              rows={6}
              value={field.value}
              onChange={(e) => field.onChange((e.target as HTMLInputElement).value)}
            />
          )}
        />
      </div>
    </Dialog>
  );
}
