import { IconButtonWithLink } from "@/components/withLink";
import { Params, Path } from "@/router";
import { LinkProps } from "@generouted/react-router/client";
import classNames from "classnames";
import { MaterialNames } from "@/components/material_symbol_names.ts";

export interface AppBarActionType<P extends Path> {
  iconName: MaterialNames;
  link: LinkProps<P, Params>;
  title: string;
}

export type Props<P extends Path> = {
  title: string;
  backLink?: LinkProps<P, Params>;
  action?: AppBarActionType<P>;
};

/**
 * Called "Page Heading" in figma
 * @see https://www.figma.com/design/HJmPQurloNvKFxyfqvSV9Y/PGA-Product-Design-System?node-id=2737-4237
 */

export default function PageHeading<P extends Path>({ title, backLink, action }: Props<P>) {
  return (
    <div className="flex flex-col pb-2 items-start">
      <div className="flex items-center w-full justify-between">
        {!!backLink && (
          <IconButtonWithLink
            name="arrow_back"
            link={backLink}
            data-testid="back"
            className="my-3 -ml-2"
            skipMargin
            iconClassName="text-sys-brand-on-surface"
          />
        )}
        {!!action && <IconButtonWithLink name={action.iconName} link={action.link} title={action.title} />}
      </div>

      <h1 className={classNames("text-headline-small", { "mt-8": !backLink })} data-testid="nav-header">
        {title}
      </h1>
    </div>
  );
}
