import { gql } from "@apollo/client";

export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateCommerceOrder($input: CommerceUpdateOrderInput!) {
    commerceUpdateOrder(input: $input) {
      message
      success
    }
  }
`;
