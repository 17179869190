import { capitalize } from "lodash";
import Tag from "@/components/Tag";
import { TagColor } from "@/components/Tag/Tag";
import { EventRegistrationStatus, EventRegistrationAdditionalStatus } from "./types";

type StatusTag = { color: TagColor; label: string };

const STATUS_MAP: Record<EventRegistrationStatus, StatusTag> = {
  [EventRegistrationStatus.FAILED]: { color: "error", label: "Fail" },
  [EventRegistrationStatus.PASSED]: { color: "primary", label: "Pass" },
  [EventRegistrationStatus.QUALIFIED]: { color: "tertiaryDim", label: "Qualifying" }
};

export const renderEventRegistrationStatus = (status: EventRegistrationStatus | string) => {
  const data = STATUS_MAP[status as EventRegistrationStatus] || { label: capitalize(status), color: "tertiaryDim" };

  return (
    <Tag color={data?.color as TagColor} testId={data.label}>
      {data.label}
    </Tag>
  );
};

const ADDITIONAL_STATUS_MAP: Record<EventRegistrationAdditionalStatus, string> = {
  [EventRegistrationAdditionalStatus.CHEATING]: "Cheating",
  [EventRegistrationAdditionalStatus.DISQUALIFIED]: "Disqualified",
  [EventRegistrationAdditionalStatus.EXCUSED]: "Excused",
  [EventRegistrationAdditionalStatus.NO_CARD]: "No Card",
  [EventRegistrationAdditionalStatus.NO_SHOW]: "No Show",
  [EventRegistrationAdditionalStatus.POOR_CONDUCT]: "Poor Conduct",
  [EventRegistrationAdditionalStatus.WITHDRAWAL]: "Withdrawal"
};

export const renderEventRegistrationAdditionalStatus = (status: EventRegistrationAdditionalStatus | string) => {
  const label = ADDITIONAL_STATUS_MAP[status as EventRegistrationAdditionalStatus] || capitalize(status);

  return label;
};
