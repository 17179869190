import Icon from "@/components/Icon";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useEffect, useMemo, useRef, useState } from "react";
import Tooltip from "../Tooltip";
import { MaterialNames } from "../material_symbol_names";

const colors: { [key: string]: string } = {
  default: "[&_circle]:stroke-sys-brand-outline-variant [&_path]:stroke-sys-brand-outline-variant",
  active: "[&_circle]:fill-sys-brand-primary [&_circle]:stroke-sys-brand-primary [&_path]:stroke-sys-brand-primary",
  current:
    "[&_circle]:fill-sys-brand-primary-container [&_circle]:stroke-sys-brand-primary [&_path]:stroke-sys-brand-primary",
  outlined: "[&_circle]:stroke-sys-brand-secondary [&_path]:stroke-sys-brand-secondary",
  solid: "[&_circle]:fill-sys-brand-secondary [&_circle]:stroke-sys-brand-secondary [&_path]:stroke-sys-brand-secondary"
};

type Variant = "status" | "outlined" | "solid";

export type StepType = {
  children?: React.ReactNode;
  headline: string | JSX.Element;
  icon?: MaterialNames;
  supportingText: string | JSX.Element;
  stepStatus?: string;
  link?: any;
  note?: {
    title: string;
    supportingText: string;
    actions: {
      primary: JSX.Element;
      secondary?: JSX.Element;
    };
  };
};

type StepsListType = {
  step: StepType;
  isLastStep?: boolean;
  hasIcon: boolean;
  onClick: () => void;
};

const randomNum = () => (Math.random() * 10000000).toFixed(0);

const Step = ({ step, hasIcon, isLastStep, onClick, isFirstStep }: StepsListType & { isFirstStep: boolean }) => {
  const minHeight = 70;
  const { headline, supportingText, stepStatus = "default", note, icon } = step;
  const [height, setHeight] = useState<number>(minHeight - 20);
  const itemRef = useRef<HTMLDivElement>(null);
  const anchorId = useMemo(() => `mask-${randomNum()}`, []);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (itemRef.current) {
      const newHeight = itemRef.current.clientHeight - 20;
      setHeight(newHeight > 0 ? newHeight : minHeight - 20);
    }
  }, [headline, windowWidth]);

  return (
    <div
      className="cursor-pointer flex gap-4 group"
      ref={itemRef}
      style={{ minHeight: `${minHeight}px` }}
      onClick={onClick}
    >
      {hasIcon && <div className="w-6">{icon && <Icon name={icon} />}</div>}
      <div className="pt-1.5 flex flex-col items-center relative">
        <div className={`${colors[stepStatus]}`}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="5" strokeWidth="2" />
          </svg>
        </div>
        {!isLastStep && (
          <div
            className={`mt-5 absolute top-0 ${
              isFirstStep && stepStatus === "current" ? colors["default"] : colors[stepStatus]
            }`}
          >
            <svg width="2" height="303" viewBox="0 0 2 303" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id={anchorId} maskUnits="userSpaceOnUse" x="0" y="0" width="2" height={height}>
                <rect width="2" height={height} fill="#D9D9D9" />
              </mask>
              <g mask={`url(#${anchorId})`}>
                <path d="M1 302V1" strokeLinecap="round" strokeDasharray="4 4" />
              </g>
            </svg>
          </div>
        )}
      </div>

      <div className="mb-6">
        <div className="flex">
          <div className={`text-body-large mr-2 ${stepStatus === "current" ? "text-sys-brand-primary" : ""}`}>
            {headline}
          </div>
          {note ? (
            <div className="absolute left-full">
              <Tooltip title={note.title} supportingText={note.supportingText} actions={note.actions} variant="rich">
                <Icon name="sticky_note_2" className="text-sys-brand-on-surface-variant" />
              </Tooltip>
            </div>
          ) : null}
        </div>
        <div className="text-body-medium text-sys-brand-on-surface-variant">{supportingText}</div>
        {step.children && <div className="text-body-medium text-sys-brand-on-surface-variant">{step.children}</div>}
      </div>
    </div>
  );
};

export default function Stepper({
  className,
  variant,
  stepsList,
  activeIndex,
  onStepClick
}: {
  className?: string;
  variant: Variant;
  stepsList: StepType[];
  activeIndex?: number;
  onStepClick: (index: number) => void;
}) {
  const updatedStepsList = stepsList.map((item, index) => {
    if (variant === "outlined") {
      return { ...item, stepStatus: "outlined" };
    }

    if (variant === "solid") {
      return { ...item, stepStatus: "solid" };
    }

    let stepStatus;
    if (activeIndex !== undefined) {
      if (index < activeIndex) {
        stepStatus = "active";
      } else if (index === activeIndex) {
        stepStatus = "current";
      } else {
        stepStatus = "default";
      }
    } else {
      stepStatus = "default";
    }
    return { ...item, stepStatus };
  });

  const hasIcon = !!updatedStepsList.find((step) => step.icon);

  return (
    <ol className={className}>
      {updatedStepsList.map((step, i) => (
        <li key={i}>
          <Step
            hasIcon={hasIcon}
            step={step}
            isLastStep={i === updatedStepsList.length - 1}
            isFirstStep={i === 0}
            onClick={() => onStepClick(i)}
          />
        </li>
      ))}
    </ol>
  );
}
