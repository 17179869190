import classNames from "classnames";

interface ClockIconProps {
  className?: string;
  onClick?: () => void;
}

export default function ClockIcon({ className, onClick }: ClockIconProps) {
  return (
    <svg
      className={classNames("w-6 h-6 cursor-pointer", className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}
