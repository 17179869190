import { gql } from "@apollo/client";

export type Quarter = {
  id: string;
  displayName: string;
};

export type Sponsor = {
  id: string;
  legacyId: string;
  name: string;
};

export const CURRENT_QUARTER_QUERY = gql`
  query currentQuarterQuery {
    currentQuarter: grpCurrentQuarter {
      id
      displayName
    }
  }
`;

export const CURRENT_AND_PREVIOUS_QUARTERS_QUERY = gql`
  query currentAndPreviousQuartersQuery {
    grpQuarters(limit: 2) {
      id
      displayName
    }
    grpSponsors {
      id
      legacyId
      name
    }
  }
`;

export const CREATE_SPONSOR_CONTRIBUTION_MUTATION = gql`
  mutation createSponsorContribution($input: BackofficeGrpCreateSponsorContributionInput!) {
    grpCreateSponsorContribution(input: $input) {
      contributions {
        id
        amount
        memberNumber
        info
        memberName
        quarter {
          id
          displayName
        }
        sponsor {
          id
          legacyId
          name
        }
      }
      success
      message
    }
  }
`;
