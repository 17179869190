import Checkbox from "../Checkbox";
import { useRef, useState } from "react";
import classNames from "classnames";
import { LitMenu, LitMenuItem } from "../Menu/LitMenu";
import TextField from "../TextField";
import Divider from "../Divider";

interface MultiSelectOption {
  disabled?: boolean;
  value: string;
  label: string;
}

export interface MultiSelectProps {
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  supportingText?: string;
  options: MultiSelectOption[];
  selectedValues: string[];
  onSelect(v: string, checked: boolean): void;
  className?: string;
  onClear(): void;
}

export default function MultiSelect({
  label,
  required,
  disabled = false,
  error,
  errorText,
  supportingText,
  options,
  onClear,
  onSelect,
  className,
  selectedValues = []
}: MultiSelectProps) {
  const selectedLabels = options.filter(({ value }) => selectedValues.includes(value)).map(({ label }) => label);
  const pickedValues = selectedLabels.sort().join(", ");
  const displayText = pickedValues || "Show All";
  const [showOptions, setShowOptions] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const inputWidth = textFieldRef.current?.getBoundingClientRect()?.width;

  return (
    <div data-testid="multiselect" className={classNames("relative select-none", className)}>
      <div
        className="w-full"
        onClick={(e) => {
          e.stopPropagation();
          setShowOptions((showOptions) => !showOptions);
        }}
      >
        <TextField
          {...{ label, required, disabled, error, errorText, supportingText }}
          className="w-full"
          ref={textFieldRef}
          value={displayText}
          readOnly
          trailingIcon="arrow_drop_down"
        />
      </div>

      <LitMenu
        anchorCorner="end-start"
        noHorizontalFlip
        menuCorner="start-start"
        anchorElement={textFieldRef.current}
        open={showOptions}
        onClosed={() => {
          setShowOptions(false);
        }}
      >
        <LitMenuItem
          keepOpen
          style={{ minWidth: inputWidth }}
          onClick={() => {
            setShowOptions(false);
            onClear();
          }}
        >
          Show All
        </LitMenuItem>

        <Divider />

        {options.map(({ value, label, disabled = false }) => {
          const checked = selectedValues.includes(value);
          return (
            <LitMenuItem
              onClick={() => {
                onSelect(value, !checked);
              }}
              disabled={disabled}
              keepOpen={true}
              key={value}
              selected={checked}
              style={{ maxWidth: inputWidth }}
            >
              <label
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="flex items-center cursor-pointer"
              >
                <Checkbox onChange={() => {}} name={value} disabled={disabled} checked={checked} />
                <span className={classNames("ml-3 text-body-large", { "text-gray-400": disabled })}>{label}</span>
              </label>
            </LitMenuItem>
          );
        })}
      </LitMenu>
    </div>
  );
}
