import Button from "@/components/Button";
import { useRequireCapability } from "@/hooks/useCapabilities";
import { DOMAIN, PERMISSION } from "@/providers/AuthorizationProvider";
import { useState } from "react";

const TestErrors = () => {
  useRequireCapability({ domain: DOMAIN.PEOPLE, permission: PERMISSION.viewPeople });
  const [_, setCount] = useState(0);

  const throwNullMapError = () => {
    const data: any = null;
    data.map((d: any) => console.log(d));
  };

  const throwPromiseRejection = async () => {
    await Promise.reject(new Error("Intentional Promise Rejection"));
  };

  const throwSyntaxError = () => {
    // This will throw a syntax error when evaluated
    eval("this is not valid javascript;;;");
  };

  const throwTypeError = () => {
    const notAFunction: any = undefined;
    notAFunction();
  };

  const throwAsyncError = async () => {
    // Simulating an API call that fails
    await new Promise((_, reject) => {
      setTimeout(() => reject(new Error("Async Operation Failed")), 1000);
    });
  };

  const throwRenderError = () => {
    // Force a render with invalid state to trigger React error
    setCount(() => {
      throw new Error("React State Update Error");
    });
  };

  const errorList = [
    {
      name: "Null Map Error",
      fn: throwNullMapError,
      description:
        "Demonstrates what happens when trying to use array methods on null data. This commonly occurs when API responses are empty or undefined."
    },
    {
      name: "Promise Rejection",
      fn: throwPromiseRejection,
      description:
        "Shows how unhandled Promise rejections are captured. This typically happens during failed API calls or async operations."
    },
    {
      name: "Syntax Error",
      fn: throwSyntaxError,
      description:
        "Illustrates errors that occur when the code contains invalid JavaScript syntax. Often seen with parsing errors or invalid code execution."
    },
    {
      name: "Type Error",
      fn: throwTypeError,
      description:
        "Shows what happens when trying to use an undefined value as a function. Common when accessing methods on null or undefined objects."
    },
    {
      name: "Async Error",
      fn: throwAsyncError,
      description:
        "Demonstrates errors in asynchronous operations, such as failed API calls or timeout issues in network requests."
    },
    {
      name: "React Render Error",
      fn: throwRenderError,
      description:
        "Shows how React handles errors during the rendering process. This can occur when component state or props are invalid."
    }
  ];

  return (
    <div className="m-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {errorList.map(({ name, fn, description }, index) => (
          <div key={index} className="border rounded-lg p-4 space-y-3">
            <h3 className="font-semibold text-lg">{name}</h3>
            <p className="text-gray-600 text-sm">{description}</p>
            <Button variant="outlined" onClick={fn}>
              Trigger Error
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestErrors;
