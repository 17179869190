import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import { useQuery } from "@apollo/client";
import { GET_PDR_HISTORY_QUERY } from "./queries";
import { useParams } from "react-router-dom";
import { toFormat } from "@/lib/dateHelpers";
import { PDRHistory } from "@/types";
import { getStickyColumnClasses } from "@/lib/styleHelpers";

type PdrHistoryData = {
  pdrHistory: PDRHistory[];
};

const formatTableData = (data: PDRHistory[]) => {
  return data.map((item) => ({
    id: item.id,
    creditType: item.creditType,
    activityName: item.category,
    description: item.description,
    submittedOn: toFormat(item.submittedOn),
    activityDate: toFormat(item.startedOn),
    credits: item.creditsAppliedFor
  }));
};

export default function ProfessionalDevelopment() {
  const { pgaId } = useParams();
  const { data, loading } = useQuery<PdrHistoryData>(GET_PDR_HISTORY_QUERY, {
    variables: { pgaId }
  });

  const tableData = formatTableData(data?.pdrHistory || []);

  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className="pt-6 sm:pt-8 px-0 sm:px-4 pb-6">
          <p className="text-title-medium mb-1">Professional Development Requirement (PDR) History</p>
          <p className="text-body-medium">Cycle: 17 (Jun 16, 2022 - Jun 15, 2025)</p>
          <p className="text-body-medium">View PDR activity history including credit types and credits earned below.</p>
        </div>

        <div className="w-full">
          <Table
            data={tableData}
            loading={loading}
            enableRowSelection={false}
            onClickRow={() => {}}
            columns={[
              {
                header: "Activity Type",
                accessorKey: "activityName",
                enableSorting: false,
                size: 300
              },
              {
                header: "Title",
                accessorKey: "description",
                enableSorting: false
              },
              {
                header: "Submitted",
                accessorKey: "submittedOn",
                enableSorting: false
              },
              {
                header: "Activity Date",
                accessorKey: "activityDate",
                enableSorting: false
              },
              {
                header: "Requirement Type",
                accessorKey: "creditType",
                enableSorting: false,
                meta: {
                  className: getStickyColumnClasses(!!tableData?.length)
                }
              },
              {
                header: "Credits",
                accessorKey: "credits",
                enableSorting: false
              }
            ]}
            renderEmptyState={() => (
              <EmptyState
                title="No PDR History"
                caption="This person does not have any Professional Development Requirement history"
                iconName="school"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
