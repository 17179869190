import { toFormat } from "@/lib/dateHelpers";
import { EducationPgmProgram } from "@/types";

const isQualifyingLevel = (level: number) => level === 0;

export const formatPgmTableData = (list: EducationPgmProgram[]) =>
  list.map((e: EducationPgmProgram, id: number) => ({
    id: id.toString(),
    programDescription: e.levelCode,
    curriculumVersion: e.curriculumVersion,
    level: isQualifyingLevel(e.level)
      ? "Qualifying Level"
      : e.careerPath
      ? `Level ${e.level} ${e.careerPath}`
      : `Level ${e.level}`,
    stage: e.completed ? "Completed" : "Started",
    startDate: toFormat(e.startDate),
    endDate: e.endDate && toFormat(e.endDate),
    completed: e.completed
  }));
