import { PropsWithChildren, ReactNode, forwardRef, useEffect } from "react";
import LitDialog, { Ref } from "./LitDialog";
import { sysColors } from "@/assets/theme/colors";

export type { Ref };

export type Props = PropsWithChildren<{
  headline: ReactNode;
  open?: boolean;
  onClosed?(): void;
  actions: ReactNode;
  className?: string;
  displayOverflow?: Boolean;
}>;

const style = {
  "--md-dialog-container-color": sysColors["sys-brand-surface-container-low"]
} as React.CSSProperties;

const Dialog = forwardRef<Ref, Props>(
  ({ headline, actions, children, className, displayOverflow, open, onClosed, ...props }: Props, ref) => {
    useEffect(() => {
      if (open) {
        document.body.style.overflow = "hidden";
      }

      return () => {
        document.body.style.overflow = "visible";
      };
    }, [open]);

    // Temporary solution needed to override the overflow hidden on the dialog
    useEffect(() => {
      if (displayOverflow) {
        setTimeout(showOverflow, 100);
      }
    }, [displayOverflow]);

    function showOverflow() {
      const dialogElements = document.querySelectorAll("md-dialog");
      dialogElements.forEach((dialog) => {
        const dialogContainer = dialog.shadowRoot?.querySelector(".container") as HTMLElement;
        const scrollerElement = dialogContainer?.querySelector(".scroller") as HTMLElement;

        if (dialogContainer) {
          dialogContainer.style.overflow = "visible";
        }
        if (scrollerElement) {
          scrollerElement.style.overflow = "visible";
        }
      });
    }

    return (
      <LitDialog onClosed={onClosed} open={open} {...props} ref={ref} className={className} style={style}>
        <div slot="headline">{headline}</div>
        <div slot="content">{children}</div>
        <div slot="actions">{actions}</div>
      </LitDialog>
    );
  }
);

Dialog.displayName = "Dialog";

export default Dialog;
