import Autocomplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/Divider";
import { SOME_PEOPLE_QUERY } from "@/pages/people/queries";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Facility, Person, Event } from "./CreateGlobalInteractionSideSheet";
import { MAX_PEOPLE } from "./MultiPersonAdder";
import { InteractionInput } from "@/pages/people/[pgaId]/interactions/interactions/types";
import { MaxPeopleBanner } from "./components/MaxPeopleBanner";
import { UnavailablePersonBanner } from "./components/UnavailablePersonBanner";

type ManualType = {
  control: any;
  gatheredEvents: Event[];
  gatheredFacilities: Facility[];
  ineligiblePerson: Person | undefined;
  maxPeopleReached: boolean;
  peopleArray: Person[];
  peopleVariables: { search?: string };
  setIneligiblePerson: (value: Person | undefined) => void;
  setMaxPeopleReached: (value: boolean) => void;
  setPeoplePGAId: (value: string) => void;
  setPeopleVariables: (value: { search?: string }) => void;
  setSearchedForPerson: (value: boolean) => void;
  setVariables: (value: InteractionInput) => void;
  variables: InteractionInput;
};

export const MultiPersonAdderManual = ({
  control,
  ineligiblePerson,
  gatheredEvents,
  gatheredFacilities,
  maxPeopleReached,
  peopleArray,
  peopleVariables,
  setIneligiblePerson,
  setMaxPeopleReached,
  setPeoplePGAId,
  setPeopleVariables,
  setSearchedForPerson,
  setVariables,
  variables
}: ManualType) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const { data: personData, loading: peopleLoading } = useQuery(SOME_PEOPLE_QUERY, {
    variables: { input: peopleVariables },
    skip: Object.keys(peopleVariables).length === 0 || peopleVariables.search === ""
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setPeopleVariables({ search: debouncedSearchTerm });
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearchTerm, setPeopleVariables]);

  const selectedPeople = peopleArray.filter((person) => person.selected);

  useEffect(() => {
    setMaxPeopleReached(selectedPeople.length >= MAX_PEOPLE);
  }, [peopleArray, setMaxPeopleReached, selectedPeople]);

  return (
    <div className="space-y-6">
      <p>Search for and individually add multiple people</p>

      <Controller
        name="interactionManualPerson"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            autofocus={true}
            label="Person"
            required
            query={value}
            loadingResults={peopleLoading}
            data={personData?.people?.nodes || []}
            onChangeText={(string) => {
              onChange(string);
              setDebouncedSearchTerm(string);
              setIneligiblePerson(undefined);

              if (string === "") {
                setSearchedForPerson(false);
                setVariables({ ...variables, participantId: null });
              }
            }}
            reset={() => {
              onChange("");
              setPeopleVariables({ ...peopleVariables, search: "" });
              setSearchedForPerson(false);
              setIneligiblePerson(undefined);
            }}
            onSelect={(value) => {
              onChange("");
              setPeoplePGAId(value.pgaId);
              !value.pgaId && setIneligiblePerson(value);
              setSearchedForPerson(true);
            }}
            renderItem={(data: Person) => (
              <AvatarDetails
                title={`${data.firstName} ${data.lastName}`}
                text={data.primaryFacility?.name || ""}
                picture={data.profilePhoto}
                secondaryText={data.primarySectionAffiliation?.section?.name || ""}
              />
            )}
            error={fieldState.invalid}
            errorText={fieldState.error?.message}
          />
        )}
      />

      {ineligiblePerson && <UnavailablePersonBanner person={ineligiblePerson} />}
      {maxPeopleReached && <MaxPeopleBanner />}

      <div className="space-y-1">
        {peopleArray
          .sort((a, b) => b.index - a.index)
          .filter(
            (person) =>
              person.selected ||
              (!gatheredFacilities.some((facility) => facility.id === person.primaryFacility.id) &&
                !gatheredEvents.some((event) => event.id === person.eventId))
          )
          .map((person, index) => (
            <div key={index} className="space-y-1">
              <div className="flex">
                <AvatarDetails
                  picture={person.profilePhoto}
                  title={`${person.firstName} ${person.lastName}`}
                  text={person.facilityName}
                  secondaryText={person.sectionName}
                />
                <div className="ml-auto">
                  <Checkbox
                    name={`person-${index}-selected`}
                    disabled={maxPeopleReached && !person.selected}
                    checked={person.selected}
                    onChange={(p) => {
                      person.selected = p;
                      if (p) {
                        setVariables({
                          ...variables,
                          participantIds: [...(variables.participantIds || []), person.salesforceExternalId]
                        });
                      } else {
                        setVariables({
                          ...variables,
                          participantIds: (variables.participantIds || []).filter(
                            (id: string) => id !== person.salesforceExternalId
                          )
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <Divider />
            </div>
          ))}
      </div>
    </div>
  );
};
