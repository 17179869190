import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OPEN_ORDER_MUTATION } from "../queries";
import { OrderType } from "../types";
import { showSnackbar } from "@/lib/snackbarUtils";

export const OpenOrderModal = ({
  onDismiss,
  order,
  refetch
}: {
  onDismiss: () => void;
  order: OrderType;
  refetch: () => void;
}) => {
  const { control, watch } = useForm();
  const [openOrderMutation, { loading }] = useMutation(OPEN_ORDER_MUTATION);
  const headline = "Open Order";
  const [errorContent, setErrorContent] = useState<string>("");
  const defaultDueDate = new Date();
  const [sendNotification, setSendNotification] = useState(false);
  defaultDueDate.setDate(defaultDueDate.getDate() + 60);

  const orderDueDateRange = watch("orderDueDate", {
    startDate: defaultDueDate,
    endDate: defaultDueDate
  });

  const variables = {
    commerceOrderId: order.id,
    dueDate: orderDueDateRange.startDate,
    sendNotification
  };

  async function openCommerceOrder() {
    const { data } = await openOrderMutation({
      variables: { input: variables }
    });

    if (data.openCommerceOrder.success) {
      refetch();
      showSnackbar("Order Opened");
      if (sendNotification) {
        showSnackbar("Email Notification Sent");
      }
      onDismiss();
    } else {
      setErrorContent(data.openCommerceOrder.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={openCommerceOrder} disabled={loading || !orderDueDateRange.startDate}>
        Open Order
      </Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline={headline} className="w-[423px]" displayOverflow>
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      <p>Are you sure you want to open this order for payment collection? This cannot be undone.</p>

      <div className="mt-6">
        <input className="mt-4 ml-1 absolute"></input>

        <Controller
          name="orderDueDate"
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Order Due Date *"
              value={orderDueDateRange}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              showFooter={false}
              popoverDirection="up"
            />
          )}
        />

        <div className="mt-4">
          <Checkbox
            name="send-notification"
            label="Send email notification to payor"
            checked={sendNotification}
            onChange={() => {
              setSendNotification(!sendNotification);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
