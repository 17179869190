import Tag from "@/components/Tag";
import { ExternalLink } from "@/components/withLink";
import { PGAAssistantIcon } from "@/components/CustomSVGIcons/PGAAssistantIcon";
import { sysColors } from "@/assets/theme/colors";
import CardSkeleton from "@/components/Skeleton/CardSkeleton";

export interface AiCardProps {
  title: string;
  text: String;
  className?: string;
  loading?: boolean;
}

// Separate component for the feedback link
const FeedbackLink = ({ text }: { text: String }) => (
  <ExternalLink
    href={`https://docs.google.com/forms/d/e/1FAIpQLSc1yxNMVrq4NwBCSLVnIm6ILLziFQD0VR9T_3UqAEeCWWGNEQ/viewform?usp=pp_url&entry.2050300929=${window.location.href}&entry.470452171=${text}`}
  >
    <span className="text-sys-brand-primary ml-2">Is this helpful?</span>
  </ExternalLink>
);

// Separate component for the header
const CardHeader = ({ title, tagText }: { title: string; tagText: string }) => (
  <div className="flex items-center my-1">
    <PGAAssistantIcon size={28} fill={sysColors["sys-brand-on-primary-container"]} />
    <h3 className="sys-brand-on-surface ml-3 mr-2">{title}</h3>
    <Tag color="tertiary">{tagText}</Tag>
  </div>
);

// Separate component for the content
const CardContent = ({ text }: { text: String }) => (
  <div className="text-sys-brand-on-primary-container mt-2">
    {text}
    <FeedbackLink text={text} />
  </div>
);

export default function AiCard({ title, text, className = "", loading = false }: AiCardProps) {
  const baseClasses = [
    "w-full",
    "bg-sys-brand-primary-container-bright",
    "rounded-xl",
    "flex",
    "items-center",
    "px-6",
    "py-4",
    className
  ].join(" ");

  if (loading) {
    return (
      <div className={baseClasses} data-testid="ai-interaction-summary">
        <div className="w-full">
          <CardHeader title={title} tagText="BETA" />
          <div className="w-full">
            <CardSkeleton rows={2} width={100} />
          </div>
        </div>
      </div>
    );
  }
  if (!text) {
    return null;
  }

  return (
    <div className={baseClasses} data-testid="ai-interaction-summary">
      <div className="w-full">
        <CardHeader title={title} tagText="BETA" />
        <CardContent text={text} />
      </div>
    </div>
  );
}
