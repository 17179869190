import { FormEvent, useRef } from "react";
import classNames from "classnames";
import { LitOutlinedSelect, LitSelectOption, MdOutlinedSelect } from "./LitSelect";

type SelectOption = {
  disabled?: boolean;
  value: string;
  border?: boolean;
  displayText?: string;
};

type OptionListMaxHeight = "default" | "extended" | "none";

type SelectProps = {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  supportingText?: string;
  options: SelectOption[];
  onSelect?(v: string): void;
  value?: string;
  className?: string;
  "data-testid"?: string;
  optionListMaxHeight?: OptionListMaxHeight;
};

const optionListHeights: Record<Exclude<OptionListMaxHeight, "none">, string> = {
  default: "175px",
  extended: "225px"
};

export default function Select({
  label,
  required,
  disabled = false,
  error,
  errorText,
  supportingText,
  options,
  onSelect,
  className,
  value,
  "data-testid": testId = "select",
  optionListMaxHeight = "default"
}: SelectProps) {
  const ref = useRef<MdOutlinedSelect>(null);

  const optionsListElement = ref.current?.shadowRoot?.getElementById("listbox");

  if (optionsListElement && optionListMaxHeight !== "none") {
    const maxHeight = optionListHeights[optionListMaxHeight];
    Object.assign(optionsListElement.style, { maxHeight, overflowY: "auto" });
  }

  return (
    <LitOutlinedSelect
      onInput={(e: FormEvent<MdOutlinedSelect>) => {
        onSelect && onSelect((e.target as HTMLInputElement).value);
      }}
      data-testid={testId}
      label={required ? `${label} *` : label}
      disabled={disabled}
      error={error}
      errorText={errorText}
      supportingText={supportingText}
      className={className}
      value={value}
      ref={ref}
    >
      {options.map(({ disabled, value, border, displayText }: SelectOption, i: number) => (
        <LitSelectOption
          key={i}
          disabled={disabled}
          value={value}
          className={classNames({
            "border-b border-sys-brand-outline-variant": border
          })}
        >
          <div slot="headline">{displayText || value}</div>
        </LitSelectOption>
      ))}
    </LitOutlinedSelect>
  );
}
