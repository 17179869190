import { useState, useEffect, useRef, useMemo, ReactNode, FocusEvent, KeyboardEvent } from "react";
import TextField from "@/components/TextField";
import { LitMenu, LitMenuItem, MdMenu } from "@/components/Menu/LitMenu";
import Icon from "../Icon";
import type { MaterialNames } from "../material_symbol_names";
import Progress from "../Progress";

const randomNum = () => (Math.random() * 10000000).toFixed(0);

type AutocompleteProps<T> = {
  autofocus?: boolean;
  renderItem: (option: T) => JSX.Element;
  label: string;
  data: T[];
  query?: string;
  onChangeText: (value: string) => void;
  reset: () => void;
  onSelect: (value: T) => void;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  onMouseDown?: (e: MouseEvent) => void;
  error?: boolean;
  errorText?: string;
  required?: boolean;
  fluidWidth?: boolean;
  disabled?: boolean;
  variant?: "searchableDropdown" | "default";
  leadingIcon?: string;
  menuClassName?: string;
  loadingResults?: boolean;
};

export type MenuItem = {
  onClick?(): void;
  onMouseDown?: (e: any) => void;
  disabled?: boolean;
  children: ReactNode;
};

export default function Autocomplete<T>({
  autofocus,
  renderItem,
  label,
  data,
  query,
  onChangeText,
  reset,
  onSelect,
  onBlur,
  onMouseDown,
  error,
  errorText,
  required,
  fluidWidth,
  disabled,
  variant,
  leadingIcon = "search",
  menuClassName = "max-h-[175px]",
  loadingResults = false
}: AutocompleteProps<T>) {
  const [options, setOptions] = useState<T[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const menuRef = useRef<MdMenu>(null);
  const menuElement = menuRef.current?.shadowRoot?.querySelector(".menu") as HTMLElement;
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 100);
    }
  }, [autofocus]);

  const anchorId = useMemo(() => `autocomplete-anchor-${randomNum()}`, []);
  const anchorElement = document.getElementById(anchorId);

  const textFieldElement = document
    .getElementById(anchorId)
    ?.querySelector(".textfield")
    ?.shadowRoot?.querySelector(".input") as HTMLInputElement;

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (menuElement) {
      const anchorElementWidth = anchorElement?.getBoundingClientRect().width;
      menuElement.style.minWidth = `${anchorElementWidth}px`;
      if (!fluidWidth) {
        menuElement.style.right = "0px";
        menuElement.style.left = "0px";
      }
    }
    if (textFieldElement) {
      textFieldElement.style.maxWidth = "calc(100% - 50px)";
    }
  }, [menuElement, anchorElement, fluidWidth, textFieldElement]);

  useEffect(() => {
    if (menuElement) {
      menuElement.style.height = "auto";
    }
  }, [menuElement, query]);

  const items = options.map((option) => ({
    children: renderItem(option),
    onClick: () => onSelect(option),
    onMouseDown: (e: MouseEvent) => onMouseDown?.(e)
  }));

  const menuItems =
    items.length > 0
      ? items
      : [
          {
            children: loadingResults ? (
              <div className="mx-auto">
                <Progress />
              </div>
            ) : (
              <>No results found</>
            ),
            disabled: true
          }
        ];

  const setVisibility = (isOpen: boolean) => {
    if (isOpen) {
      menuRef.current?.close();
    } else {
      menuRef.current?.show();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === menuItems.length - 1 ? 0 : prevIndex + 1
      );
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0 ? menuItems.length - 1 : prevIndex - 1
      );
      e.preventDefault();
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      const selectedItem = menuItems[highlightedIndex];
      if ("onClick" in selectedItem && typeof selectedItem.onClick === "function") {
        selectedItem.onClick();
        setHighlightedIndex(null);
        setVisibility(true);
      }
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="relative">
        <div
          className="relative"
          id={anchorId}
          onClick={() => {
            if (variant === "searchableDropdown") {
              setVisibility(false);
            }
          }}
          onKeyDown={handleKeyDown}
        >
          <TextField
            disabled={disabled || false}
            label={label}
            variant="outlined"
            value={query}
            onChangeText={(e) => {
              onChangeText(e);
              setVisibility(false);
            }}
            onBlur={onBlur}
            className="w-full textfield"
            leadingIcon={leadingIcon as MaterialNames}
            trailingIcon={variant === "searchableDropdown" && !query ? "arrow_drop_down" : undefined}
            error={error}
            required={required}
            ref={textFieldRef}
          />
          {query && (
            <button
              onClick={() => {
                if (!disabled) {
                  reset();
                  textFieldElement?.focus();
                }
              }}
              className={`absolute h-[24px] w-[24px] right-5 top-1/2 transform -translate-y-1/2 ${
                disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
              }`}
              aria-label="Clear autocomplete"
            >
              <Icon name="cancel" />
            </button>
          )}
          {error && errorText && <div className="text-sys-brand-error text-body-small pt-1 pl-4">{errorText}</div>}
        </div>
        <LitMenu anchor={anchorId} ref={menuRef} quick className={`${menuClassName} overflow-y-auto absolute z-50`}>
          {menuItems.map(({ onClick, onMouseDown, disabled, children }: MenuItem, index) => (
            <div key={`menu-item-${randomNum()}`}>
              <LitMenuItem
                className={`w-full ${highlightedIndex === index ? "bg-gray-200" : ""}`}
                onClick={onClick}
                onMouseDown={onMouseDown}
                disabled={disabled}
              >
                <div className="flex flex-row items-center">{children}</div>
              </LitMenuItem>
            </div>
          ))}
        </LitMenu>
      </div>
    </>
  );
}
