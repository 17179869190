import { Link, Path } from "@/router";
import MetricCard from "./MetricCard";
import { MetricGridLayout, MetricCardType } from "./MetricCard.types";

interface MetricGridProps<P extends Path> {
  metrics: MetricCardType<P>[];
  layout?: MetricGridLayout;
}

const LAYOUT_CLASSES = {
  compact: "grid-cols-2 tablet:grid-cols-3 desktop:grid-cols-5",
  balanced: "grid-cols-2 tablet:grid-cols-3 desktop:grid-cols-6",
  expanded: "grid-cols-2 tablet:grid-cols-4 desktop:grid-cols-5",
  stacked: "grid-cols-2 tablet:grid-cols-3 desktop:grid-cols-4"
};

export default function MetricGrid<P extends Path>({ metrics, layout = "balanced" }: MetricGridProps<P>) {
  return (
    <div className={`grid gap-3 ${LAYOUT_CLASSES[layout]}`} data-testid="metrics">
      {metrics.map(({ link, ...props }, i) =>
        link ? (
          <Link key={i} {...link}>
            <MetricCard link={link} {...props} />
          </Link>
        ) : (
          <MetricCard key={i} {...props} />
        )
      )}
    </div>
  );
}
