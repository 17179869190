import { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import { MaterialNames } from "../material_symbol_names";
import classNames from "classnames";
import Avatar from "../Avatar";

export interface ListItemProps {
  iconName?: MaterialNames;
  picture?: string;
  overline?: string;
  headline?: string | JSX.Element | null;
  supportingText?: string | null;
  trailingSupportingText?: string | null;
  action?: () => void;
  actionIcon?: MaterialNames;
  renderActions?: () => JSX.Element;
  status?: () => JSX.Element;
}

export default function ListItem({
  iconName,
  overline,
  headline,
  supportingText,
  trailingSupportingText,
  action,
  actionIcon = "navigate_next",
  picture,
  renderActions,
  status
}: ListItemProps) {
  const [height, setHeight] = useState<number>(0);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setHeight(textRef.current.clientHeight);
    }
  }, [headline]);

  const hasThreeLines = height >= 56;
  const hasOneLine = height <= 24;
  const heightClass = hasThreeLines ? "items-start" : "items-center";

  return (
    <div className="flex justify-between my-2 w-full" data-testid={overline}>
      <div className={classNames(`${heightClass} flex space-x-2 w-full`, { "h-[40px]": hasOneLine })}>
        {iconName && !picture && (
          <div className={classNames("flex flex-col justify-start", { "h-full": hasThreeLines })}>
            <Icon name={iconName} size={24} className="flex mx-2 text-sys-brand-on-surface-variant" />
          </div>
        )}
        {picture && <Avatar picture={picture} className="w-10 h-10" />}
        <div ref={textRef} className={classNames("flex flex-col items-start")}>
          <div className="text-label-medium text-sys-brand-on-surface-variant">{overline}</div>
          <div
            className={classNames("text-body-large word-break text-sys-brand-on-surface", {
              "opacity-20": !headline
            })}
          >
            {headline || "--"}
          </div>
          <div className="text-body-small text-sys-brand-on-surface-variant whitespace-pre-line">{supportingText}</div>
          {status && <div className="mt-1">{status()}</div>}
        </div>
      </div>
      {trailingSupportingText && (
        <div className="text-sys-brand-on-surface text-label-small shrink-0">{trailingSupportingText}</div>
      )}
      {action && !renderActions && <Icon className="flex justify-center" name={actionIcon} />}
    </div>
  );
}
