export const isDev = () =>
  window.location.origin.includes(".dev.pga.com") || window.location.origin.includes("localhost");

const getTld = () => (isDev() ? "my.dev.pga.com" : "my.pga.com");

function buildMyPgaUrl(path: string, slug: string) {
  return `https://${getTld()}/${path}/${slug}`;
}

export { getTld, buildMyPgaUrl };

export const openBrowser = (url: string) => {
  window.open(url);
};

export const showDevBanner = import.meta.env.MODE === "dev";
