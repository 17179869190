import react from "react";
import { createComponent } from "@lit/react";
import { MdOutlinedTextField } from "@material/web/textfield/outlined-text-field";
import { MdFilledTextField } from "@material/web/textfield/filled-text-field";

const events = {
  onChange: "input"
};

export const LitOutlinedTextField = createComponent({
  tagName: "md-outlined-text-field",
  elementClass: MdOutlinedTextField,
  react,
  events
});

export const LitFilledTextField = createComponent({
  tagName: "md-filled-text-field",
  elementClass: MdFilledTextField,
  react,
  events
});
