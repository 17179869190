import { centsToDollarsFormatted, currencyFormatter } from "@/lib/currencyHelpers";
import TextField from "@/components/TextField";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox";
import { NumberFormatBase } from "react-number-format";
import Icon from "@/components/Icon";
import { FormValues } from "../index";

export default function RefundAmount() {
  const { control, watch } = useFormContext<FormValues>();
  const refundAllocations = watch("refundAllocations");

  return (
    <>
      <p className="text-body-medium text-sys-brand-on-surface">
        Set the refund amount per product. The refund amount cannot be more than the remaining net payment for each
        product.
      </p>
      <div className="flex py-3 items-center bg-sys-brand-secondary-container rounded-2xl mt-6 mb-4">
        <div className="px-4 ">
          <Icon name="info" />
        </div>
        <div className="px-6 border-l border-sys-brand-outline-variant">
          <h6 className="text-title-small text-sys-brand-on-seconday-container">Want to Make Product Changes?</h6>
          <p className="text-body-medium text-sys-brand-on-seconday-container">
            Issuing a refund on this payment will keep the order as-is. Changes to the order can be made by editing the
            order.
          </p>
        </div>
      </div>
      <div className="">
        {refundAllocations?.map(
          (item, index) =>
            item.defaulAmountCents !== 0 && (
              <div
                key={item.id}
                className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full py-2"
              >
                <div className="flex items-center">
                  <input type="hidden" name={`refundAllocations.${index}.id`} />
                  <Controller
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          name={`refunds.${index}.selected`}
                        />
                      );
                    }}
                    control={control}
                    name={`refundAllocations.${index}.selected`}
                  />
                  <div className="ml-4">
                    <div className="text-body-large text-sys-brand-on-surface text-nowrap">{item.name}</div>
                    <div className="text-body-medium text-sys-brand-on-surface-variant">
                      Net Payment {centsToDollarsFormatted(item.defaulAmountCents)}
                    </div>
                  </div>
                </div>
                <div className="w-[210px] mt-5 sm:mt-0 ml-8 sm:ml-0">
                  <Controller
                    render={({ field, fieldState }) => (
                      <NumberFormatBase
                        disabled={false}
                        value={field.value}
                        format={currencyFormatter}
                        customInput={TextField}
                        error={!!fieldState.error}
                        errorText={fieldState.error?.message}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onValueChange={(values) => {
                          field.onChange(Math.round(values.floatValue || 0));
                        }}
                        leadingIcon="attach_money"
                        label="Refund Amount"
                      />
                    )}
                    control={control}
                    name={`refundAllocations.${index}.amountCents`}
                    rules={{
                      max: {
                        value: item.defaulAmountCents,
                        message: "Must be less than Net Payment"
                      }
                    }}
                  />
                </div>
              </div>
            )
        )}
      </div>
    </>
  );
}
