import { useParams } from "@/router";
import { DOMAIN, PERMISSION, useRequireCapability } from "@/hooks/useCapabilities";
import { AuditLogTable, DateRangePicker } from "@/pages/sections/[sectionId]/audit-log/_components";
import { PERSON_AUDIT_LOG_QUERY, Variables } from "./queries";
import { useQuery } from "@apollo/client";
import { PropsWithChildren, useEffect } from "react";
import { DateTime } from "luxon";
import usePagination from "@/hooks/usePagination";
import { useSearchParams } from "react-router-dom";
import PageTitle from "@/components/PageTitle";
import { ObjectHeadingWrapper } from "../_components/PersonDetailsHeader";

const title = "Audit Log";

const path = "/people/:pgaId/audit-log";

function Header({
  children,
  variables,
  setVariables
}: PropsWithChildren<{
  variables: Pick<Variables, "endDateTime" | "startDateTime">;
  setVariables(variables: Variables): void;
}>) {
  return (
    <div className="flex flex-wrap justify-between items-center gap-3">
      <div className="w-[270px] sm:w-[300px] max-w-[300px] h-[56px]">
        <DateRangePicker variables={variables} setVariables={setVariables} />
      </div>
      <div className="w-full md:w-auto">{children}</div>
    </div>
  );
}

export default function AuditLog() {
  useRequireCapability({ domain: DOMAIN.PEOPLE, permission: PERMISSION.viewPeople });
  const { pgaId } = useParams(path);
  const defaultParams = { start: DateTime.now().minus({ days: 30 }).toISODate(), end: DateTime.now().toISODate() };
  const [params, setParams] = useSearchParams(defaultParams);

  function setVariables({ startDateTime, endDateTime }: Variables) {
    const toDate = (date: string) => DateTime.fromISO(date).toISODate() || date;
    setParams({ start: toDate(startDateTime), end: toDate(endDateTime) });
  }

  const startDateTime = params.get("start") || defaultParams.start;
  const endDateTime = params.get("end") || defaultParams.end;

  const { renderFooter, paginationVariables, resetPagination } = usePagination();

  useEffect(() => {
    resetPagination();
  }, [startDateTime, endDateTime, pgaId]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, loading } = useQuery(PERSON_AUDIT_LOG_QUERY, {
    variables: {
      pgaId,
      startDateTime,
      endDateTime,
      ...paginationVariables
    }
  });

  const { auditLog } = data?.person || {};

  return (
    <>
      <PageTitle pattern={`/people/:pgaId/audit-log`} title={`People | Audit Log | ${data?.person.displayName}`} />
      <h1 className="my-8 text-headline-small">{title}</h1>
      <ObjectHeadingWrapper pgaId={pgaId} />
      <div className="mt-8 mb-6 flex flex-col sm:flex-row items-center justify-between">
        <div className="flex-col sm:w-3/4 w-full">
          <p className="text-body-medium mt-1">
            A history of all data changes associated with this person can be found below.
          </p>
        </div>
      </div>
      <AuditLogTable
        loading={loading}
        data={auditLog?.nodes || []}
        columns={["description", "changedAt", "changeset"]}
        renderHeader={() => (
          <Header variables={{ startDateTime, endDateTime }} {...{ setVariables }}>
            {auditLog?.nodes.length ? renderFooter(auditLog, { variant: "compact" })() : null}
          </Header>
        )}
        renderFooter={renderFooter(auditLog)}
      />
    </>
  );
}
