import { useState, useEffect } from "react";
import useCapabilities, { PERMISSION } from "@/hooks/useCapabilities";
import Icon from "@/components/Icon";
import ObjectHeading from "@/components/ObjectHeading";
import Avatar from "@/components/Avatar";
import { renderStatus, renderPaymentStatus, paymentDate } from "../../_utils";
import { centsToDollarsFormatted } from "@/lib/currencyHelpers";
import { PaymentType, OrderPayment } from "../[orderId]/payment/[paymentId]/types";
import { toFormat } from "@/lib/dateHelpers";
import { renderPaymentType, getPayerOption } from "../../_utils";
import EmptyState from "@/components/EmptyState";
import Table from "@/components/Table";
import { getStickyColumnClasses } from "@/lib/styleHelpers";
import { isNull, sumBy, sortBy, capitalize } from "lodash";
import { MaterialNames } from "@/components/material_symbol_names";
import TableDetailsPanel from "@/components/TableDetailsPanel";
import DetailsCard from "@/components/DetailsCard";
import { useNavigate } from "@/router";
import Button from "@/components/Button";
import { PaymentStatus, PaymentMethod } from "@/pages/billing/types";
import RefundPaymentModal from "./RefundPaymentModal";
import IconButton from "@/components/IconButton";
import useActionMenu, { actionId } from "@/hooks/useActionMenu";
import Banner from "@/components/Banner";

export const PaymentWrapper = ({
  payment,
  setActions,
  refetch
}: {
  payment: PaymentType;
  setActions: (value: React.ReactNode) => void;
  refetch: () => void;
}) => {
  const { hasPermission } = useCapabilities();
  const { Menu, setMenu } = useActionMenu();
  const [openRefundPaymentModal, setOpenRefundPaymentModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderPayment | null>(null);
  const [openRefundWithCheckModal, setOpenRefundWithCheckModal] = useState(false);
  const [refundError, setRefundError] = useState("");
  const {
    paidAt,
    amountCents,
    refundedAmountCents,
    type,
    transactionId,
    transactionUrl,
    stripeAccount,
    orderPayments,
    payer,
    status
  } = payment;

  const orderDataArray = orderPayments?.map((orderPayment: OrderPayment) => {
    const { order } = orderPayment;
    return {
      ...order,
      id: order.id,
      dueDate: toFormat(order?.dueDate) || "",
      pgaId: order?.person?.pgaId,
      balance: centsToDollarsFormatted(Number(order?.balance)),
      balanceCents: order?.balance,
      totalPaid: centsToDollarsFormatted(sumBy(orderPayment.ledgerEntries, "amountCents")),
      totalPaidCents: sumBy(orderPayment.ledgerEntries, "amountCents"),
      ledgerEntries: orderPayment.ledgerEntries,
      lineItemsOriginal: order.lineItems,
      lineItems:
        orderPayment.ledgerEntries?.map((ledgerEntry) => {
          const { secondaryLineItem } = ledgerEntry;
          return [
            {
              ...ledgerEntry.lineItem,
              name:
                ledgerEntry.type === "refunded"
                  ? `${
                      ledgerEntry.amountCents === -ledgerEntry.lineItem.priceCents ? "Full" : "Partial"
                    } Refund - ${toFormat(ledgerEntry.createdAt)}`
                  : ledgerEntry.type === "transferred_to"
                  ? `Payment Transfer to ${secondaryLineItem.name} - ${toFormat(ledgerEntry.createdAt)}`
                  : ledgerEntry.lineItem.name,
              payment: centsToDollarsFormatted(ledgerEntry.amountCents),
              type: ledgerEntry.type,
              amountCents: ledgerEntry.amountCents,
              id: `${ledgerEntry.lineItem.id}-${Date.now()}`
            }
          ];
        }) || [],
      lineItemsTotal: sumBy(orderPayment.ledgerEntries, "amountCents")
    };
  });

  const orderData = orderDataArray.map((order) => {
    const { lineItems } = order;
    return {
      ...order,
      lineItems: sortBy(lineItems.flat(), (item) => [item.id, -item.amountCents])
    };
  });

  const payerOption = getPayerOption(payer.type);
  const navigate = useNavigate();

  function closeRefundModal() {
    setOpenRefundPaymentModal(false);
  }

  useEffect(() => {
    const renderActions = () => (
      <>
        {hasPermission(PERMISSION.managePayments) && status === PaymentStatus.PAID && refundError && (
          <Button
            variant="text"
            onClick={() => {
              setOpenRefundWithCheckModal(true);
            }}
          >
            Refund With Check
          </Button>
        )}
        {hasPermission(PERMISSION.managePayments) && status === PaymentStatus.PAID && (
          <Button
            variant="outlined"
            onClick={() => {
              setOpenRefundPaymentModal(true);
              setSelectedOrder(null);
            }}
          >
            Refund Full Payment
          </Button>
        )}
      </>
    );

    setActions(renderActions());
  }, [setActions, status, type, hasPermission, refundError]);

  return (
    <>
      <div className="-mx-12">{refundError && <Banner description={refundError} closable variant="error" />}</div>
      <Menu />
      <div className="max-w-[1440px] mx-auto mt-3 sm:mt-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
          <ObjectHeading title="Payment" iconName="attach_money" isDetailsView>
            <div
              className="flex flex-col sm:flex-row  flex-wrap text-title-medium text-sys-brand-on-surface gap-1 sm:gap-0  mt-1 sm:mt-0"
              style={{ marginLeft: "-16px" }}
            >
              {paidAt && <div className="px-4">Date: {paymentDate(type, paidAt)}</div>}
              {!isNull(amountCents) && (
                <div className="px-4 sm:border-l border-sys-brand-outline-variant">
                  Amount: {centsToDollarsFormatted(amountCents)}
                </div>
              )}
              {(status === PaymentStatus.REFUNDED || status === PaymentStatus.PARTIALLY_REFUNDED) && (
                <>
                  <div className="px-4 sm:border-l border-sys-brand-outline-variant">
                    Refund: {centsToDollarsFormatted(refundedAmountCents)}
                  </div>
                  <div className="px-4 sm:border-l border-sys-brand-outline-variant">
                    Net: {centsToDollarsFormatted(amountCents - refundedAmountCents)}
                  </div>
                </>
              )}
            </div>
          </ObjectHeading>
          <div className="mt-4 sm:mt-0">{renderPaymentStatus(payment.status)}</div>
        </div>
        {type && (
          <>
            <h2 className="text-title-medium text-sys-brand-on-surface mb-1">Payment Method</h2>
            <p className="text-body-medium text-sys-brand-on-surface mb-0">
              {type === PaymentMethod.FINANCIAL_ADJUSTMENT ? (
                payment.payer.name
              ) : (
                <>
                  {renderPaymentType(type)}
                  {type === PaymentMethod.CHECK ? ` #${payment.checkNumber}` : ""}
                </>
              )}
            </p>
          </>
        )}
        {transactionId && (
          <>
            <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Transaction Details</h2>
            <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 mb-4">
              <DetailsCard
                heading={
                  type === PaymentMethod.FINANCIAL_ADJUSTMENT
                    ? payment.payer.name
                    : `Collection via ${type === PaymentMethod.CHECK ? " Check" : "Stripe"}`
                }
                options={[
                  {
                    iconName: "receipt_long",
                    overline: "Transaction ID",
                    headline: transactionId,
                    ...(type === PaymentMethod.CARD || type === PaymentMethod.US_BANK_ACCOUNT
                      ? {
                          action: () => window.open(transactionUrl, "_blank"),
                          actionIcon: "open_in_new",
                          supportingText: `Account: ${capitalize(stripeAccount)}`
                        }
                      : {})
                  }
                ]}
              />
            </div>
          </>
        )}
        {type !== PaymentMethod.FINANCIAL_ADJUSTMENT && (
          <>
            <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Summary</h2>
            <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 mb-4">
              <DetailsCard
                heading="Paid By"
                options={[
                  {
                    iconName: payerOption.icon as MaterialNames,
                    overline: payerOption.label,
                    headline: payer.name
                  }
                ]}
              />

              {payer.email && (
                <DetailsCard
                  heading="Email Address"
                  options={[
                    {
                      iconName: "mail",
                      overline: "",
                      headline: payer.email
                    }
                  ]}
                />
              )}
            </div>
          </>
        )}
        <h2 className="text-title-medium text-sys-brand-on-surface mt-8 mb-3">Orders Included in Payment</h2>
        <Table
          data={orderData || []}
          loading={false}
          onClickRow={(row) => navigate(`/billing/orders/:orderId`, { params: { orderId: row.original.id } })}
          renderExpandedRow={({ row }) => (
            <TableDetailsPanel
              data={row.original.lineItems}
              columns={[
                {
                  accessorKey: "name",
                  header: "Product Name",
                  enableSorting: false,
                  size: 1200,
                  cell: (cellProps) => {
                    const { type } = cellProps.row.original;
                    return (
                      <span className={type === "refunded" || type === "transferred_to" ? "pl-3" : ""}>
                        {cellProps.row.original.name}
                      </span>
                    );
                  }
                },
                {
                  accessorKey: "payment",
                  header: "Payment",
                  enableSorting: false,
                  size: 240,
                  meta: { className: "text-right pr-20", headerClassName: "justify-end pr-16" }
                }
              ]}
              renderFooter={() => {
                const { totalPaid } = row.original;
                return (
                  <>
                    <div className="py-3">
                      <div className="flex justify-end">
                        <h5 className="text-label-large">Total</h5>
                        <div className="flex justify-end text-body-medium w-[100px] mr-[68px]">{totalPaid}</div>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          )}
          columns={[
            {
              header: "Name",
              id: "fullName",
              size: 200,
              cell: (cellProps) => {
                const { person } = cellProps.row.original;
                return (
                  <div className="flex items-center py-2">
                    <Avatar picture={person.profilePhoto} />
                    <div className="ml-2">
                      <span className="text-body-medium">
                        {person.firstName} {person.lastName}
                      </span>
                      <br />
                      <span className="text-body-small">{person.mainProgramType?.name}</span>
                    </div>
                  </div>
                );
              },
              meta: {
                className: getStickyColumnClasses(!!orderData?.length)
              }
            },
            { accessorKey: "pgaId", header: "PGA ID", enableSorting: false },
            { accessorKey: "description", header: "Description", enableSorting: false },
            {
              accessorKey: "products",
              header: "Products",
              enableSorting: false,
              cell: ({ row }) => {
                return (
                  <div
                    className="flex items-center gap-2 py-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      row.getToggleExpandedHandler()();
                    }}
                  >
                    <span>{row.original.lineItems.length}</span>
                    <Icon name={row.getIsExpanded() ? "expand_less" : "expand_more"} />
                  </div>
                );
              }
            },
            { accessorKey: "totalPaid", header: "Payment Amount", enableSorting: false },
            { accessorKey: "balance", header: "Balance Due", enableSorting: false },
            {
              header: "Status",
              id: "stage",
              cell: ({ row: { original } }) => renderStatus(original.stage, original.paymentStatus),
              size: 100
            },
            ...(hasPermission(PERMISSION.managePayments)
              ? [
                  {
                    id: "actions",
                    size: 10,
                    cell({ row: { id: rowId, original } }: { row: { id: string; original: any } }) {
                      const items = [
                        {
                          label: "Refund Partial Order Payment",
                          onClick() {
                            setOpenRefundPaymentModal(true);
                            setSelectedOrder(
                              orderDataArray.find((order) => order.id === original.id) as unknown as OrderPayment
                            );
                          }
                        }
                      ];

                      if (!items.length) return null;

                      function onClick() {
                        setMenu({ rowId, items });
                      }

                      return (
                        <div
                          className="flex flex-row justify-end"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          style={{ display: original.totalPaidCents > 0 ? "block" : "none" }}
                        >
                          <IconButton
                            id={actionId(rowId)}
                            onClick={onClick}
                            data-testid="actions"
                            title="actions"
                            name="more_vert"
                            iconClassName="font-extrabold"
                          />
                        </div>
                      );
                    }
                  }
                ]
              : [])
          ]}
          renderEmptyState={() => (
            <EmptyState title="No Orders Found" caption="No orders found for this payment." iconName="box" />
          )}
        />
        {openRefundPaymentModal && (
          <RefundPaymentModal
            payment={payment}
            onDismiss={closeRefundModal}
            refetch={refetch}
            setErrorMessage={setRefundError}
            order={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />
        )}
        {openRefundWithCheckModal && (
          <RefundPaymentModal
            payment={{ ...payment, bypass: true }}
            onDismiss={() => {
              setOpenRefundWithCheckModal(false);
            }}
            refetch={refetch}
            setErrorMessage={setRefundError}
            order={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />
        )}
      </div>
    </>
  );
};

export default PaymentWrapper;
