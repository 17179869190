import { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { EVENT_QUERY, UPDATE_EVENT_MUTATION } from "../../queries";
import { EventInformationSection } from "./_components/EventInformationSection";
import { PATSetupSection } from "./_components/PATSetupSection";
import RegistrantsTable from "./_components/RegistrantsTable";
import { showSnackbar } from "@/lib/snackbarUtils";
import { EventOutletContext } from "../_layout";

export interface EventState {
  title: string;
  status: string;
  patEventAttributes: {
    hostProfessional: {
      id: string;
      pgaId: string;
      displayName: string;
    };
    examiner: {
      id: string;
      pgaId: string;
      displayName: string;
    };
    courseConditions: string;
    problemsIssues: string;
    yardageMale: string;
    yardageMaleFiftyPlus: string;
    courseRatingMale: string;
    targetScoreMale: string;
    yardageFemale: string;
    yardageFemaleFiftyPlus: string;
    courseRatingFemale: string;
    targetScoreFemale: string;
  };
}

export default function ResultsPage() {
  const { eventSlug = "" } = useParams();

  const { eventData } = useOutletContext<EventOutletContext>();

  const [updateEvent] = useMutation(UPDATE_EVENT_MUTATION, {
    refetchQueries: [
      {
        query: EVENT_QUERY,
        variables: { slug: eventSlug }
      }
    ]
  });

  const handleEventUpdate = async (update: Partial<EventState>) => {
    try {
      const previousState = { ...eventState };
      const input: any = { id: eventData?.id };

      if (update.patEventAttributes) {
        const {
          yardageMale,
          courseRatingMale,
          yardageMaleFiftyPlus,
          yardageFemale,
          courseRatingFemale,
          yardageFemaleFiftyPlus,
          hostProfessional,
          examiner,
          courseConditions,
          problemsIssues
        } = update.patEventAttributes;

        const patEventAttributes: Record<string, any> = {};

        if (hostProfessional?.id) patEventAttributes.hostProfessionalId = hostProfessional.id;
        if (examiner?.id) patEventAttributes.examinerId = examiner.id;

        const modifiedFields = {
          yardageMale,
          courseRatingMale,
          yardageMaleFiftyPlus,
          yardageFemale,
          courseRatingFemale,
          yardageFemaleFiftyPlus,
          courseConditions,
          problemsIssues
        };

        Object.entries(modifiedFields).forEach(([key, value]) => {
          if (value !== undefined) {
            patEventAttributes[key] = value === "" ? null : value;
          }
        });

        if (Object.keys(patEventAttributes).length > 0) {
          input.patEventAttributes = patEventAttributes;
        }
      }

      const response = await updateEvent({
        variables: { input }
      });

      if (response.data?.updateEvent.success) {
        setEventState((prev) => ({
          ...prev,
          ...update
        }));
        showSnackbar("Event updated");
      } else {
        setEventState(previousState);
        showSnackbar("Failed to update event");
      }
    } catch (error) {
      console.error("Failed to update event state:", error);
    }
  };

  const [eventState, setEventState] = useState<EventState>({
    title: "",
    status: "",
    patEventAttributes: {
      hostProfessional: { id: "", pgaId: "", displayName: "" },
      examiner: { id: "", pgaId: "", displayName: "" },
      courseConditions: "",
      problemsIssues: "",
      yardageMale: "",
      courseRatingMale: "",
      yardageMaleFiftyPlus: "",
      yardageFemale: "",
      courseRatingFemale: "",
      yardageFemaleFiftyPlus: "",
      targetScoreMale: "",
      targetScoreFemale: ""
    }
  });

  useEffect(() => {
    const event = eventData;
    if (!event) return;

    setEventState({
      title: event.title || "",
      status: event.status || "",
      patEventAttributes: {
        hostProfessional: {
          id: event.patEventAttributes?.hostProfessional?.id || "",
          pgaId: event.patEventAttributes?.hostProfessional?.pgaId || "",
          displayName: event.patEventAttributes?.hostProfessional?.displayName || ""
        },
        examiner: {
          id: event.patEventAttributes?.examiner?.id || "",
          pgaId: event.patEventAttributes?.examiner?.pgaId || "",
          displayName: event.patEventAttributes?.examiner?.displayName || ""
        },
        courseConditions: event.patEventAttributes?.courseConditions || "",
        problemsIssues: event.patEventAttributes?.problemsIssues || "",
        yardageMale: event.patEventAttributes?.yardageMale || "",
        courseRatingMale: event.patEventAttributes?.courseRatingMale || "",
        yardageMaleFiftyPlus: event.patEventAttributes?.yardageMaleFiftyPlus || "",
        yardageFemale: event.patEventAttributes?.yardageFemale || "",
        courseRatingFemale: event.patEventAttributes?.courseRatingFemale || "",
        yardageFemaleFiftyPlus: event.patEventAttributes?.yardageFemaleFiftyPlus || "",
        targetScoreMale: event.patEventAttributes?.targetScoreMale || "",
        targetScoreFemale: event.patEventAttributes?.targetScoreFemale || ""
      }
    });
  }, [eventData]);

  return (
    <>
      <div className="max-w-[1440px] mx-auto my-8" data-testid="results-page">
        <EventInformationSection eventState={eventState} onStateUpdate={handleEventUpdate} />
        <PATSetupSection onUpdate={handleEventUpdate} currentState={eventState} />
        <RegistrantsTable eventSlug={eventSlug} />
      </div>
    </>
  );
}
