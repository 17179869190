import Button from "@/components/Button";
import Dialog from "@/components/Dialog";

export default function OrderCreationErrorDialog({
  onDismiss,
  templateName,
  errorReasons,
  openCreateOrderModal
}: {
  onDismiss: () => void;
  templateName: string;
  errorReasons: string[];
  openCreateOrderModal: () => void;
}) {
  function tryDifferentTemplate() {
    openCreateOrderModal();
  }

  const actions = (
    <div className="space-x-2">
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>
      <Button onClick={tryDifferentTemplate}>Try Different Order Template</Button>
    </div>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline="Order Cannot Be Created">
      <div className="max-w-[450px] space-y-6">
        <p>
          The order template &quot;{templateName}&quot; cannot be used for the selected payor for the following reason:
        </p>

        {errorReasons.map((errorReason, index) => (
          <p key={index}>{errorReason}</p>
        ))}
      </div>
    </Dialog>
  );
}
