import { PropsWithChildren, useEffect, useRef } from "react";
import { LitNavigationDrawerModal } from "./LitNavigationDrawerModal";
import { sysColors } from "@/assets/theme/colors";
import { useWindowSize } from "@/hooks/useWindowSize";
import { isMobile } from "@/assets/theme/sizes";

interface Props {
  isOpen: boolean;
  onChange(isOpen: boolean): void;
  "data-testid"?: string;
  zIndex: number;
}

export default function DrawerModal({
  zIndex,
  isOpen,
  "data-testid": testid,
  onChange,
  children
}: PropsWithChildren<Props>) {
  const windowSize = useWindowSize();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const customStyles = {
    "--md-navigation-drawer-modal-scrim-color": sysColors["sys-brand-scrim"],
    "--md-navigation-drawer-modal-scrim-opacity": 0.32,
    "--md-navigation-drawer-modal-container-shape": "16px 0 0 16px",
    "--md-navigation-drawer-modal-container-width": isMobile(windowSize.width) ? "100%" : "460px"
  } as React.CSSProperties;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  useEffect(() => {
    if (wrapperRef.current) {
      const shadowRoot = wrapperRef.current.querySelector("md-navigation-drawer-modal")?.shadowRoot;

      const drawerElement = shadowRoot?.querySelector(".md3-navigation-drawer-modal") as HTMLElement;
      const scrim = shadowRoot?.querySelector(".md3-navigation-drawer-modal__scrim") as HTMLElement;

      if (scrim) Object.assign(scrim.style, { zIndex, position: "fixed" });

      if (drawerElement) Object.assign(drawerElement.style, { zIndex: zIndex + 1, right: 0, position: "fixed" });
    }
  });

  return (
    <div ref={wrapperRef}>
      <LitNavigationDrawerModal
        opened={isOpen}
        onChange={(e: any) => onChange(e.detail?.opened)}
        style={customStyles}
        data-testid={testid}
      >
        {children}
      </LitNavigationDrawerModal>
    </div>
  );
}
