import { createContext, ReactNode, useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useAuth0 } from "@auth0/auth0-react";

const DataDogContext = createContext(null);

export default function DataDogProvider({ children }: { children: ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);

  const { user } = useAuth0();

  useEffect(() => {
    const applicationId = import.meta.env.VITE_DATADOG_APPLICATION_ID;
    const clientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
    const hasDatadogEnabled = applicationId && clientToken;

    if (!hasDatadogEnabled) {
      return;
    }

    if (!isInitialized) {
      datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        site: "datadoghq.com",
        service: "backoffice-frontend",
        env: process.env.NODE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
      datadogRum.startSessionReplayRecording();
      setIsInitialized(true);
    }

    if (user && datadogRum.getUser()?.id !== user.email) {
      datadogRum.setUser({
        id: user.email,
        name: user.name
      });
    }
  }, [isInitialized, user]);

  return <DataDogContext.Provider value={null}>{children}</DataDogContext.Provider>;
}
