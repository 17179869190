import Avatar from "@/components/Avatar";
import { MaterialNames } from "@/components/material_symbol_names";

const sizes = {
  small: 24,
  medium: 48,
  large: 70
};

interface AvatarsListProps {
  pictures: (string | null | undefined)[];
  size?: keyof typeof sizes;
  className?: string;
  iconClassName?: string;
  iconName?: MaterialNames;
}

const AvatarsList = (props: AvatarsListProps) => {
  const { pictures, size = "medium" } = props;
  const sizeData = sizes[size];

  return (
    <ul className="flex items-center">
      {pictures.map((picture, index) => (
        <li key={index} style={(index > 0 && { marginLeft: -sizeData / 2 }) || {}}>
          <Avatar picture={picture || undefined} {...props} />
        </li>
      ))}
    </ul>
  );
};

interface ConnectedPeopleProps {
  participants: (string | null)[];
}

export default function ConnectedPeople({ participants }: ConnectedPeopleProps) {
  const pictures = participants.filter((picture) => !!picture).slice(0, 5);

  if (!pictures.length) {
    pictures.push(null);
  }

  const participantsCount = participants.length;
  const picturesCount = pictures.length;

  if (participantsCount <= 1) {
    return null;
  }

  return (
    <div className="flex items-center gap-1 font-medium">
      <AvatarsList pictures={pictures} size="small" />
      {participantsCount > picturesCount && <span>+{participantsCount - picturesCount} more</span>}
    </div>
  );
}
