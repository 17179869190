import { useParams } from "react-router-dom";
import { ContactDetails } from "./_components/ContactDetails";
import { useQuery } from "@apollo/client";
import { PERSON_DETAILS_QUERY } from "./queries";
import { Person } from "@/types";
import { CardSkeleton } from "@/components/Skeleton";

type PersonData = { person: Person };

export default function PersonDetails() {
  const { pgaId } = useParams();

  const { data, error } = useQuery<PersonData>(PERSON_DETAILS_QUERY, {
    variables: { pgaId },
    fetchPolicy: "cache-first"
  });

  const { person } = data || {};

  if (error) return <div>Error: {error.message}</div>;

  if (!person) {
    return (
      <div className="flex mt-4 gap-4">
        <CardSkeleton maxWidth={60} />
        <CardSkeleton maxWidth={60} />
      </div>
    );
  }

  return (
    <div className="mt-4 sm:mt-8">
      <ContactDetails person={person} />
    </div>
  );
}
