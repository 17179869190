import { sysColors } from "@/assets/theme/colors";
import { LitCircularProgress, LitLinearProgress } from "./LitProgress";

const variants = {
  circular: LitCircularProgress,
  linear: LitLinearProgress
};

export type Variant = keyof typeof variants;

interface Props {
  variant?: Variant;
  size?: number;
  className?: string;
  color?: string;
}

export default function Progress({
  variant = "circular",
  size = 36,
  className,
  color = sysColors["sys-brand-primary"]
}: Props) {
  const ProgressComponent = variants[variant];

  const customStyles = {
    "--md-sys-color-primary": color,
    "--md-circular-progress-size": `${size}px`
  } as React.CSSProperties & { [key: string]: string };

  return (
    <ProgressComponent indeterminate style={customStyles} className={className} data-testid="progress-component" />
  );
}
