import classNames from "classnames";
import { PropsWithChildren } from "react";

interface Props {
  className?: string;
  isOpen?: boolean;
}

export default function NavigationRail({ children, className, isOpen = true }: PropsWithChildren<Props>) {
  return (
    <div
      className={classNames(`flex items-start justify-center h-full ${className}`, {
        "w-[360px]": isOpen,
        "w-20": !isOpen
      })}
    >
      {children}
    </div>
  );
}
