import { TypedDocumentNode, gql } from "@apollo/client";
import { ChangeSetting } from "./utils";
import { Section } from "@/types";
import { SECTION_FRAGMENT } from "../../queries";

export const SECTION_CHANGEFORM_SETTINGS_QUERY: TypedDocumentNode<{
  section: Section & {
    permissions: { manageChangeformSettings: boolean };
    changeformSettings: ChangeSetting[];
  };
}> = gql`
  query sectionChangeformSettings($sectionId: ID!) {
    section(id: $sectionId) {
      ...SectionFragment
      changeformSettings {
        id
        changeType
        approvalType
      }
    }
  }

  ${SECTION_FRAGMENT}
`;

export const SECTION_CHANGEFORM_SET_SETTING_MUTATION = gql`
  mutation setChangeformSetting($input: BackofficeUpdateChangeformSettingInput!) {
    updateChangeformSetting(input: $input) {
      setting {
        id
        approvalType
      }
    }
  }
`;
