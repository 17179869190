import RadioItem from "@/components/Radio/RadioItem";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@/components/TextField/TextField";
import AutoComplete from "@/components/Autocomplete";
import AvatarDetails from "@/components/AvatarDetails";
import { GET_PEOPLE } from "@/pages/events/queries";
import { useQuery } from "@apollo/client";
import { useState } from "react";

interface PeopleData {
  pgaId: string;
  displayName: string;
  profilePhoto: string;
  id: string;
}

const ModulesStep = () => {
  const { control, setValue, watch } = useFormContext();
  const eventType = watch("eventType");
  const [searchTerm, setSearchTerm] = useState("");

  const handleAttendanceToggle = (value: boolean) => {
    setValue("collectsAttendance", value);
  };

  const { data } = useQuery(GET_PEOPLE, {
    variables: {
      input: {
        search: searchTerm
      },
      first: 10
    },
    skip: searchTerm.length < 3
  });

  const mapPeople = (data: PeopleData[]) =>
    (data || []).map((person) => {
      return {
        name: person?.displayName || "",
        pgaId: person?.pgaId || "",
        profilePhoto: person?.profilePhoto || "",
        id: person?.id || ""
      };
    });

  const peopleOptions = mapPeople(data?.people?.nodes || []);

  const renderAutoComplete = (fieldName: string, label: string) => (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <AutoComplete
          label={label}
          data={peopleOptions}
          {...field}
          query={field.value?.name || ""}
          renderItem={(item) => <AvatarDetails picture={item.profilePhoto} title={item.name} text={item.pgaId} />}
          onChangeText={(text) => {
            field.onChange({ name: text, value: "" });
            setSearchTerm(text);
          }}
          reset={() => {
            field.onChange(null);
            setSearchTerm("");
          }}
          onSelect={(item) => {
            field.onChange(item);
            setSearchTerm("");
          }}
          menuClassName="max-h-[450px] max-w-[450px]"
        />
      )}
    />
  );

  return (
    <div className="flex flex-col gap-6 mb-0">
      <p className="text-headline-small">Setup additional modules that should be utilized for managing this event.</p>
      {eventType.name === "PAT" ? (
        <div className="flex flex-col gap-3">
          <p className="text-title-medium">Playing Ability Test Scoring Settings</p>
          <p className="text-body-medium">These items can be edited later in event settings.</p>
          <div className="flex flex-col gap-6">
            <div className="grid grid-cols-2 gap-2">
              <Controller
                control={control}
                name="patEventAttributes.yardageMale"
                render={({ field }) => <TextField type="number" label="Yardage - Male" placeholder="" {...field} />}
              />
              <Controller
                control={control}
                name="patEventAttributes.yardageMaleFiftyPlus"
                render={({ field }) => <TextField type="number" label="Yardage - Male 50+" placeholder="" {...field} />}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <Controller
                control={control}
                name="patEventAttributes.courseRatingMale"
                render={({ field }) => (
                  <TextField type="number" label="Course Rating - Male" placeholder="" {...field} />
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <Controller
                control={control}
                name="patEventAttributes.yardageFemale"
                render={({ field }) => <TextField type="number" label="Yardage - Female" placeholder="" {...field} />}
              />
              <Controller
                control={control}
                name="patEventAttributes.yardageFemaleFiftyPlus"
                render={({ field }) => (
                  <TextField type="number" label="Yardage - Female 50+" placeholder="" {...field} />
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <Controller
                control={control}
                name="patEventAttributes.courseRatingFemale"
                render={({ field }) => (
                  <TextField type="number" label="Course Rating - Female" placeholder="" {...field} />
                )}
              />
            </div>

            <div className="w-full">
              {renderAutoComplete("patEventAttributes.hostProfessional", "Host Professional")}
            </div>

            <div className="w-full">
              {renderAutoComplete("patEventAttributes.examiner", "Examiner")}
              <span className="text-body-small text-[#40484C] ml-4">
                PAT Examiner will be responsible for tracking attendance and scoring
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="text-title-medium mb-0">Attendance Module</p>

          <Controller
            control={control}
            name="collectsAttendance"
            data-testid="attendance-toggle"
            render={({ field }) => (
              <div className="flex flex-col">
                <div className="py-0">
                  <div className="mb-3 ml-7">
                    <RadioItem
                      name="attendance-no-tracking"
                      headline="No Tracking"
                      checked={!field.value}
                      onChange={() => handleAttendanceToggle(false)}
                    />
                  </div>
                </div>
                <div className="py-0">
                  <div className="mb-3 ml-7">
                    <RadioItem
                      name="attendance-tracking"
                      headline="Basic Attendance Tracking"
                      supportingText="Allow event hosts to track the attendance of each registrant per session"
                      checked={field.value}
                      onChange={() => handleAttendanceToggle(true)}
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
};

export default ModulesStep;
