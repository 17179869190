import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import DateSelect from "@/components/DateSelect";
import Dialog from "@/components/Dialog";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CHANGE_DUE_DATE_MUTATION } from "../queries";
import { OrderType } from "../types";
import { showSnackbar } from "@/lib/snackbarUtils";

export const ChangeDueDateModal = ({
  onDismiss,
  order,
  refetch
}: {
  onDismiss: () => void;
  order: OrderType;
  refetch: () => void;
}) => {
  const { control, watch } = useForm();
  const headline = "Change Due Date";
  const [changeDueDateMutation, { loading }] = useMutation(CHANGE_DUE_DATE_MUTATION);
  const [errorContent, setErrorContent] = useState("");
  const [sendNotification, setSendNotification] = useState(false);

  const orderDueDateRange = watch("orderDueDate", {
    startDate: order.dueDate,
    endDate: order.dueDate
  });

  const variables = {
    orderId: order.id,
    dueDate: orderDueDateRange.startDate,
    sendNotification: sendNotification
  };

  async function changeDueDate() {
    const { data } = await changeDueDateMutation({
      variables: { input: variables }
    });

    if (data.commerceChangeOrderDueDate.success) {
      refetch();
      showSnackbar("Due Date Changed");
      if (sendNotification) {
        showSnackbar("Email Notification Sent");
      }
      onDismiss();
    } else {
      setErrorContent(data.commerceChangeOrderDueDate.message);
    }
  }

  const actions = (
    <>
      <Button variant="text" onClick={onDismiss}>
        Cancel
      </Button>

      <Button onClick={changeDueDate} disabled={loading || !orderDueDateRange.startDate}>
        Change Due Date
      </Button>
    </>
  );

  return (
    <Dialog onClosed={onDismiss} open actions={actions} headline={headline} className="w-[423px]" displayOverflow>
      {errorContent ? <div className="text-red-500 font-bold text-center mb-4">{errorContent}</div> : null}
      <p>Are you sure you want to update this order&#39;s due date?</p>

      <div className="mt-6">
        <input className="mt-4 ml-1 absolute"></input>

        <Controller
          name="orderDueDate"
          control={control}
          render={({ field }) => (
            <DateSelect
              label="Order Due Date *"
              value={orderDueDateRange}
              onChange={(e) => {
                field.onChange(e);
              }}
              useSingleDate
              showFooter={false}
              popoverDirection="up"
              supportingText="MM/DD/YYYY"
            />
          )}
        />

        <div className="mt-4">
          <Checkbox
            name="send-notification"
            label="Send email notification to payor"
            checked={sendNotification}
            onChange={() => {
              setSendNotification(!sendNotification);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
