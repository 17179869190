import { forwardRef, useState } from "react";
import classNames from "classnames";
import Icon from "@/components/Icon";

interface SearchInputProps {
  label?: string;
  placeholder: string;
  query: string;
  param: string | string[];
  onChange: (value: string) => void;
  onClear?: () => void;
}

export default forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  { label, placeholder, query, param, onChange, onClear }: SearchInputProps,
  ref
) {
  const [isFocused, setIsFocused] = useState(false);
  const shouldShowParam = param && isFocused;

  const renderParams = () => {
    if (Array.isArray(param)) {
      return param.join(", ");
    }
    return param;
  };

  return (
    <>
      {label && <label className="block ml-1 mb-4 text-sys-brand-on-surface-variant">{label}</label>}
      <div className="relative w-full">
        <Icon
          name={shouldShowParam ? "arrow_back" : "search"}
          className={classNames("absolute left-5 top-1/2 transform -translate-y-1/2", {
            "cursor-pointer": shouldShowParam
          })}
        />
        <input
          name="search"
          type="text"
          ref={ref}
          value={query}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          className={classNames(
            "pl-14 pr-10 w-full h-[56px] placeholder-sys-brand-on-surface-variant bg-sys-brand-surface-container-high rounded-[28px] outline-none cursor-pointer",
            {
              "rounded-b-none": shouldShowParam
            }
          )}
          data-testid="search-input"
        />
        {shouldShowParam && (
          <div className="absolute z-20 w-full min-h-[56px] px-6 py-3 border-t border-sys-brand-outline bg-sys-brand-surface-container-high rounded-[28px] rounded-t-none whitespace-normal">
            <p className="w-full whitespace-normal text-title-medium">Find in: {renderParams()}</p>
          </div>
        )}
        {query && (
          <button
            onClick={() => {
              onChange("");
              onClear && onClear();
            }}
            className="absolute h-[24px] w-[24px] right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
            aria-label="Clear search query"
            data-testid="clear-search"
          >
            <Icon name="close" />
          </button>
        )}
      </div>
    </>
  );
});
