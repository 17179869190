import { TypedDocumentNode, gql } from "@apollo/client";
import { PageInfo } from "@/components/Pagination";
import { SECTION_FRAGMENT, SectionFragment } from "../queries";
import { PaginationVariables } from "@/components/Pagination";
import { PAGE_INFO_FRAGMENT } from "@/hooks/usePagination";

export enum ChangeRequestStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED"
}

interface Employment {
  effectiveDate: string;
  title: string;
}

interface FacilityChange {
  facility: { name: string };
  isPrimaryFacility: boolean;
}

interface AddUnlistedEmploymentChange extends Employment, FacilityChange {
  classification?: string;
  description: string;
  __typename: "BackofficeAddUnlistedEmploymentChange";
}

interface AddEmploymentChange extends Employment, FacilityChange {
  classification: string;
  description: string;
  __typename: "BackofficeAddEmploymentChange";
}

interface CurrentEmployment extends Employment {
  description: string | null;
  classification: string | null;
}

interface UpdateEmploymentChange extends Employment {
  classification: string;
  currentEmployment: CurrentEmployment;
  description: string;
  __typename: "BackofficeUpdateEmploymentChange";
}

interface UpdateUnlistedEmploymentChange extends Employment {
  classification?: string;
  currentEmployment: CurrentEmployment;
  description: string;
  __typename: "BackofficeUpdateUnlistedEmploymentChange";
}

export type ChangeRequestChange =
  | AddEmploymentChange
  | AddUnlistedEmploymentChange
  | UpdateEmploymentChange
  | UpdateUnlistedEmploymentChange;

type Person = {
  id: string;
  firstName: string;
  lastName: string;
};

export enum NoteVisibility {
  INTERNAL = "internal",
  EXTERNAL = "external"
}

export type Note = { author: Person; visibility: NoteVisibility; note: string; createdAt: string; id: string };

export interface Node {
  id: string;
  change: ChangeRequestChange;
  notes: Note[];
  person: { pgaId: string } & Person;
  status: ChangeRequestStatus;
  submittedAt: string;
}

export const PERSON_FRAGMENT = gql`
  fragment Person on BackofficePerson {
    id
    firstName
    lastName
  }
`;

export const CHANGE_REQUEST_FRAGMENT = gql`
  fragment ChangeRequest on BackofficeChangeRequest {
    id
    status
    notes {
      id
      author {
        ...Person
      }
      note
      createdAt
      visibility
    }
  }
  ${PERSON_FRAGMENT}
`;

export const CHANGE_REQUEST_CHANGE_FRAGMENT = gql`
  fragment Change on BackofficeChangeRequest {
    change {
      ... on BackofficeAddUnlistedEmploymentChange {
        ...Employment
        ...FacilityChange
        classification
        description
      }
      ... on BackofficeAddEmploymentChange {
        ...Employment
        ...FacilityChange
        classification
        description
      }
      ... on BackofficeUpdateEmploymentChange {
        ...Employment
        classification
        description
        currentEmployment {
          ...Employment
          classification
          description
        }
      }
      ... on BackofficeUpdateUnlistedEmploymentChange {
        ...Employment
        classification
        description
        currentEmployment {
          ...Employment
          classification
          description
        }
      }
    }
  }

  fragment FacilityChange on ChangeRequestEmploymentFacilityChange {
    isPrimaryFacility
    facility {
      id
      name
    }
  }

  fragment Employment on ChangeRequestEmployment {
    id
    title
    effectiveDate
  }
`;

type MutationStatus = { success: boolean; message: string };

export const APPROVE_CHANGE_REQUEST_MUTATION: TypedDocumentNode<
  { approveChangeRequest: MutationStatus },
  { id: string; message?: string }
> = gql`
  mutation ApproveChangeRequest($id: ID!, $message: String) {
    approveChangeRequest(id: $id, message: $message) {
      success
      message
      changeRequest {
        ...ChangeRequest
      }
    }
  }

  ${CHANGE_REQUEST_FRAGMENT}
`;

export const REJECT_CHANGE_REQUEST_MUTATION: TypedDocumentNode<
  { rejectChangeRequest: MutationStatus },
  { id: string; message?: string }
> = gql`
  mutation RejectChangeRequest($id: ID!, $message: String!) {
    rejectChangeRequest(id: $id, message: $message) {
      success
      message
      changeRequest {
        ...ChangeRequest
      }
    }
  }

  ${CHANGE_REQUEST_FRAGMENT}
`;

export const NOTE_CHANGE_REQUEST_MUTATION: TypedDocumentNode<
  { noteChangeRequest: MutationStatus },
  { id: string; message: string }
> = gql`
  mutation NoteChangeRequest($id: ID!, $message: String!) {
    noteChangeRequest(id: $id, message: $message) {
      success
      message
      changeRequest {
        ...ChangeRequest
      }
    }
  }

  ${CHANGE_REQUEST_FRAGMENT}
`;

export const UPDATE_CHANGE_REQUEST_MUTATION: TypedDocumentNode<
  { updateChangeRequest: MutationStatus },
  { id: string; classification?: string }
> = gql`
  mutation UpdateChangeRequest($id: ID!, $classification: String) {
    updateChangeRequest(id: $id, classification: $classification) {
      success
      message
      changeRequest {
        id
        ...Change
      }
    }
  }

  ${CHANGE_REQUEST_CHANGE_FRAGMENT}
`;

export const CHANGE_REQUESTS_QUERY: TypedDocumentNode<
  {
    section: SectionFragment["section"] & { changeRequests: { nodes: Node[]; pageInfo: PageInfo; totalCount: number } };
  },
  { sectionId: string; query?: string; stage?: ChangeRequestStatus } & PaginationVariables
> = gql`
  query SectionChangeRequests(
    $sectionId: ID!
    $query: String
    $stage: BackofficeChangeRequestStatus
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    section(id: $sectionId) {
      ...SectionFragment
      changeRequests(query: $query, stage: $stage, first: $first, after: $after, last: $last, before: $before) {
        nodes {
          ...ChangeRequest
          submittedAt
          ...Change
          person {
            pgaId
            ...Person
          }
        }
        ...PageInfo
      }
    }
  }

  ${SECTION_FRAGMENT}
  ${PERSON_FRAGMENT}
  ${CHANGE_REQUEST_FRAGMENT}
  ${CHANGE_REQUEST_CHANGE_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`;

export const JOB_CLASSIFICATIONS_QUERY: TypedDocumentNode<
  { person: { availableJobClassifications: string[] } },
  { personId: string }
> = gql`
  query JobClassifications($personId: ID!) {
    person(id: $personId) {
      id
      availableJobClassifications
    }
  }
`;
