import { useCallback, useState } from "react";
import { AuthorizationContext, DEFAULT_CAPABILITIES } from "./AuthorizationContext";
import { Capability, PERMISSION } from "./types";
import { useQuery } from "@apollo/client";
import { CAPABILITIES_QUERY } from "./queries";

export default function AuthorizationProvider({ children }: { children: React.ReactNode }) {
  const [state, setCapabilities] = useState<{ capabilities: Capability; loaded: boolean }>({
    capabilities: DEFAULT_CAPABILITIES,
    loaded: false
  });

  useQuery(CAPABILITIES_QUERY, {
    fetchPolicy: "cache-first",
    skip: state.loaded,
    onCompleted({ me: { capabilities } }) {
      setCapabilities({ loaded: true, capabilities });
    }
  });

  const hasPermission = useCallback(
    (permission: PERMISSION) => {
      return state.capabilities.permissions.includes(permission);
    },
    [state.capabilities]
  );

  return <AuthorizationContext.Provider value={{ hasPermission, ...state }}>{children}</AuthorizationContext.Provider>;
}
