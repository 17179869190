import ReactDOM from "react-dom/client";
import { Routes } from "@generouted/react-router";
import { RaygunProvider } from "@/providers/RaygunProvider";
import Providers from "@/providers/providers";

import "./styles";
import { DevBanner } from "./components/Banner";
import { showDevBanner } from "./lib/urlUtils";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RaygunProvider>
    <Providers>
      {showDevBanner && (
        <DevBanner
          description="Development Site"
          variant="info"
          primaryAction={{
            label: "Switch to Production",
            link: "https://backoffice.pga.com"
          }}
        />
      )}
      <Routes />
    </Providers>
  </RaygunProvider>
);
